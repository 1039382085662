import gql from 'graphql-tag';

export const CREATE_DESTINATION = gql`
  mutation($input: DestinationInput) {
    createDestination(input: $input) {
      destination {
        id
        publishDate
        isPublished
        # slugs
        en {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        fi {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        et {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        ru {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        lv {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        lt {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
      }
    }
  }
`;

export const UPDATE_DESTINATION = gql`
  mutation($input: DestinationInput) {
    updateDestination(input: $input) {
      destination {
        id
        publishDate
        isPublished
        # slugs
        en {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        fi {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        et {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        ru {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        lv {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        lt {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
      }
    }
  }
`;

export const REMOVE_DESTINATION = gql`
  mutation($id: ID!) {
    deleteDestination(id: $id) {
      success
    }
  }
`;
