import React, { useEffect, useContext } from 'react';
import { StoreContext } from '@lux/spa/store';
import ThanksLayoutSuccess from '../../thanks-layout-success.jsx';

const CorporateClient = ({ fileLoading, getTicketsFileUrl }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { totalPrice, currencyName, forAnalitics, GTM } = state.changeTickets;
  const { from, to, returnDate, promocode } = state.changeTickets.search;
  const { GTMLoaded } = state.app;

  useEffect(() => {
    getTicketsFileUrl();
  }, []);

  useEffect(() => {
    if (GTMLoaded && !GTM.thanksStep) {
      // dataLayer.push({
      //   tripType: returnDate ? 'return-trip' : 'one-way',
      //   departureStopId: from.BusStopId,
      //   destinationStopId: to.BusStopId,
      //   currencyCode: currencyName,
      //   route: `${from.CityName}-${to.CityName}`,
      //   price: totalPrice,
      //   promocode: promocode || null,
      //   departureDestination: `${from.CountryName}-${to.CountryName}`,
      //   stage: 'purchase',
      //   event: 'payment-success',
      //   ...(forAnalitics?.id
      //     ? {
      //         Id: forAnalitics.id,
      //         quantity: forAnalitics.quantity,
      //       }
      //     : {}),
      // });

      dispatch({
        type: 'UPDATE_CHANGE_TICKETS',
        payload: {
          GTM: {
            ...GTM,
            thanksStep: true,
          },
        },
      });
    }
  }, [GTMLoaded]);

  return (
    <ThanksLayoutSuccess
      type="corporate"
      fileLoading={fileLoading}
      getTicketsFileUrl={getTicketsFileUrl}
    />
  );
};

export default CorporateClient;
