import gql from 'graphql-tag';

export const BUS_STOPS = gql`
  query($ids: [Int], $lang: String, $busStopName: String) {
    busStops(ids: $ids, lang: $lang, busStopName: $busStopName) {
      BusStopId
      BusStopName
      BusStopCode
      BusStopExternalCode
      CountyName
      CountryName
      HasPriority
      IsAirportBusStop
      Address {
        Street
        ZipCode
        CityName
      }
      Coordinates {
        Latitude
        Longitude
      }
      # Comment
      # Platforms {
      #   Name
      #   Coordinates {
      #     Latitude
      #     Longitude
      #   }
      # }
    }
  }
`;
