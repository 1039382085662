import React, { useState, cloneElement, useReducer } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { ORDER_WIDGET } from '@lux/spa/apollo/queries';
import { UPDATE_ORDER_WIDGET } from '@lux/spa/apollo/mutations';
import { Button, Modal, ModalHeader, ModalContent, DragAndDrop } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import EditOrderWidgetItem from './edit-order-widget-item';
import s from './edit-order-widget.module.scss';

const sliderReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ITEM':
      return {
        ...state,
        slides: [
          ...state.slides,
          {
            ...action.payload,
          },
        ],
      };
    case 'UPDATE_ITEM':
      return {
        ...state,
        slides: state.slides.map(slide =>
          slide.id === action.payload.prevId
            ? {
                ...slide,
                ...action.payload,
              }
            : {
                ...slide,
              }
        ),
      };
    case 'REMOVE_ITEM':
      return {
        ...state,
        slides: state.slides.filter(
          slide => slide.id !== action.payload.removeId
        ),
      };
    case 'UPDATE_ITEMS':
      return {
        ...state,
        slides: action.payload,
      };
    default:
      throw new Error();
  }
};

const EditOrderWidget = ({
  t,
  i18n,
  isOpen,
  toggleModal,
  children,
  slider = {
    slides: [],
  },
  updatePage,
  showField,
  showSecondField,
  emptySlide,
  className,
  contentClassName,
  ...props
}) => {
  const { language } = i18n;
  const [updateWidget] = useMutation(UPDATE_ORDER_WIDGET);
  const initialSlider = {
    newSlide: emptySlide,
    slides: slider.slides,
  };

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, sliderDispatch] = useReducer(sliderReducer, initialSlider);

  const { newSlide, slides } = state;

  const [slide, setSlide] = useState(newSlide);
  const [isEditSlide, setIsEditSlide] = useState(false);

  const updateSlides = slides => {
    sliderDispatch({
      type: 'UPDATE_ITEMS',
      payload: slides,
    });
  };

  const toggleEditSlide = slide => {
    setIsEditSlide(!isEditSlide);
    setSlide(slide);
  };

  const handleSaveSlider = () => {
    const ids = slides.map(({ id }) => id);
    updateWidget({
      variables: {
        input: {
          lang: language,
          id: slider.id,
          origin: slider.origin,
          type: slider.type,
          ids: ids.join(),
        },
      },
      refetchQueries: [
        {
          query: ORDER_WIDGET,
          variables: {
            lang: language,
            origin: slider.origin,
          },
        },
      ],
    });
    toggleModal();
  };

  const removeSlide = removeId => {
    sliderDispatch({
      type: 'REMOVE_ITEM',
      payload: {
        removeId,
      },
    });
  };

  return (
    <Modal className={s.modal} {...{ isOpen, toggleModal }}>
      <ModalHeader text="Edit Slider" />
      <ModalContent className={cn(s.content, contentClassName)}>
        {isEditSlide ? (
          cloneElement(children, {
            item: {
              ...emptySlide,
              ...slide,
            },
            items: slides,
            widgetDispatch: sliderDispatch,
            toggleEditItem: toggleEditSlide,
            ...props,
          })
        ) : (
          <div className={s.editOrderWidget}>
            <div className={cn(s.items, !slides.length && s.empty)}>
              {slides.length ? (
                <DragAndDrop
                  updateItems={updateSlides}
                  itemProps={{
                    showField,
                    showSecondField,
                    toggleEditSlide,
                    removeSlide,
                  }}
                  ItemLayout={EditOrderWidgetItem}
                  items={slides}
                />
              ) : (
                <div className={s.emptyText}>{t('slides')}</div>
              )}
            </div>
            <div className={s.controls}>
              <Button
                text={`+ ${t('add slide')}`}
                className={s.addItem}
                onClick={() => toggleEditSlide(newSlide)}
              />
              <Button text="Save" onClick={handleSaveSlider} />
              <span onClick={toggleModal}>Cancel</span>
            </div>
          </div>
        )}
      </ModalContent>
    </Modal>
  );
};

export default withTranslation()(EditOrderWidget);
