import gql from 'graphql-tag';

export const SEND_SMS = gql`
  mutation($input: SendSmsInput!) {
    sendSms(input: $input) {
      # code {
      #   code
      # }
      errors {
        field
        messages
      }
    }
  }
`;
