function getTicketsDataFromStorage() {
  if (typeof window !== 'undefined') {
    return JSON.parse(localStorage.getItem('ctd'));
  }

  return {};
}

function saveTicketsDataToStorage(state) {
  if (typeof window !== 'undefined') {
    return localStorage.setItem('ctd', JSON.stringify(state));
  }

  return state;
}

const initialState = {
  isOpenBlind: false,
  activeBus: 1,
  activePassenger: 1,
  isOutbound: true,
  totalPrice: null,
  passengersCount: 1,
  prices: {},
  isPassengersInfoConfirmed: false,
  isBasketExrpired: false,
  isSomethingWrong: false,
  isSomethingWrongMessage: '',
  isSomethingWrongTitle: '',
  step: 0,
  isFormDisabled: true,
  passsengersInfo: {},
  // isCorporate: false,
  isSearchOutOfDate: false,
  currency: 'CURRENCY.RUB',
  currencyName: 'RUB',
  symbol: '₽',
  showCurrencies: true,
  inboundJourneyPriceClass: 'Regular',
  outboundJourneyPriceClass: 'Regular',
  currentStepDisabled: false,
  passengerPrices: [],
  passengersFee: 0,
  paymentSuccessful: false,
  basketTransfers: [],
  basketTransfersPrice: 0,
  hasTransfers: false,
  forAnalitics: {},
  GTM: {
    selectSeatsStep: false,
    passengerInfoStep: false,
    paymentStep: false,
    thanksStep: false,
  },
  affiliateId: '',
  transfers: {
    called: false,
    isOutbound: false,
    isInbound: false,
    inboundInformation: {},
    outboundInformation: {},
    InboundJourneys: [],
    OutboundJourneys: [],
    isOutboundOther: false,
    isInboundOther: false,
    outboundTransfer: {
      TransferId: '',
      PassengerName: '',
      PassengerPhone: '',
      Quantity: 1,
    },
    inboundTransfer: {
      TransferId: '',
      PassengerName: '',
      PassengerPhone: '',
      Quantity: 1,
    },
  },
  vouchers: {
    fullyСoversСost: false,
    totalVouchersValue: 0,
    showVoucherInput: false,
    approved: [],
  },
  payment: {
    status: '',
    type: '',
    objects: [],
  },
  search: {
    basketCode: '',
    basketId: '',
    departDate: '',
    from: {
      CityName: '',
      BusStopId: null,
      BusStopName: '',
      search: '',
    },
    to: {
      CityName: '',
      BusStopId: null,
      BusStopName: '',
      search: '',
    },
    isOutbound: true,
    passengers: 1,
    productCodes: [],
  },
  trip: {},
  stops: [],
  priceClass: '',
  journey: {},
  seatPlan: {
    Legs: [],
  },
  selectedSeats: {},
  isAllSeatsSeleted: false,
};

export const initialStateChangeTickets = {
  ...initialState,
  ...getTicketsDataFromStorage(),
};

saveTicketsDataToStorage(initialStateChangeTickets);

const changeTicketsReducer = (state, action) => {
  function updatedState(state, action) {
    switch (action.type) {
      case 'RESET_CHANGE_TICKETS':
        // const { currency, currencyName, symbol } = initialStateChangeTickets;
        const { currency, currencyName, symbol, search, trip } = state;
        return {
          ...initialState,
          search,
          currencyName,
          currency,
          symbol,
          trip,
          ...action.payload,
        };
      case 'UPDATE_CHANGE_TICKETS':
        return {
          ...state,
          ...action.payload,
        };

      case 'UPDATE_CHANGE_TICKETS_TRANSFERS':
        return {
          ...state,
          transfers: {
            ...state.transfers,
            ...action.payload,
          },
        };

      case 'UPDATE_CHANGE_TICKETS_CURRENCY':
        const newCurrency = action.payload.currencies.filter(
          item => item.CurrencyName === action.payload.currency
        )[0];

        return {
          ...state,
          currencyName: newCurrency.CurrencyName,
          currency: newCurrency.Currency,
          symbol: newCurrency.Symbol,
        };
      default:
        return state;
    }
  }

  saveTicketsDataToStorage(updatedState(state, action));

  return updatedState(state, action);
};

export default changeTicketsReducer;
