import gql from 'graphql-tag';
import { FRAGMENT_LEGS } from '../gql-fragments';

export const DELETE_PRODUCT_FROM_BASKET = gql`
  mutation($ProductId: String) {
    deleteProductFromBasket(ProductId: $ProductId) {
      success
    }
  }
`;

export const DELETE_PRODUCTS_FROM_BASKET = gql`
  mutation($ProductIds: [String]!) {
    deleteProductsFromBasket(ProductIds: $ProductIds) {
      success
    }
  }
`;

export const ADD_TRANSFER = gql`
  mutation($Transfers: [AddTransferInput]!) {
    addTransfer(Transfers: $Transfers) {
      error {
        ErrorCode
        ErrorReason
      }
      success
    }
  }
`;

export const TRANSFER_INFORNATION = gql`
  mutation($transferId: String!, $lang: String) {
    transferInformation(transferId: $transferId, lang: $lang) {
      error {
        ErrorCode
        ErrorReason
      }
      transfer {
        TransferAreaMap
        ServiceDescription
        ServiceProvider
        ServiceProviderPhoneNumber
        ServiceProviderLogo
      }
    }
  }
`;

export const AVAILABLE_TRANSFERS = gql`
  mutation($lang: String, $productIds: [String]!) {
    availableTransfers(lang: $lang, productIds: $productIds) {
      error {
        ErrorCode
        ErrorReason
      }
      transfers {
        OutboundJourneys {
          TransferId
          TransferTime
          Name
          BusStopName
          Price
          VatRate
          Currency
          IsTransferToDepartureStop
        }
        InboundJourneys {
          TransferId
          TransferTime
          Name
          BusStopName
          Price
          VatRate
          Currency
          IsTransferToDepartureStop
        }
      }
    }
  }
`;

export const PASSEENGERS_DATA_FIELDS = gql`
  mutation($lang: String, $productIds: [String]!) {
    passengersDataFields(lang: $lang, productIds: $productIds) {
      error {
        ErrorCode
        ErrorReason
      }
      dataFields {
        ProductId
        Passenger {
          Key
          KeyName
          DisplayType
          ValueClassificationGroup
          DropdownItems {
            Id
            Name
          }
        }
        EmergencyContact {
          Key
          KeyName
          DisplayType
          ValueClassificationGroup
          DropdownItems {
            Id
            Name
          }
        }
        LoyaltyProgramProviders {
          Id
          LocalizedName
        }
      }
    }
  }
`;

export const ZERO_PAYMENT = gql`
  mutation($Currency: String) {
    zeroPaymentPay(Currency: $Currency) {
      error {
        ErrorCode
        ErrorReason
      }
      success
      objects {
        IsOutbound
        DepartureTime
        DepartureTimeZone
        ArrivalTime
        ArrivalTimeZone
        DepartureStopName
        DepartureStopId
        DepartureCityName
        DepartureCityId
        DestinationStopName
        DestinationStopId
        DestinationCityName
        DestinationCityId
        ShoppingBasketCode
        ShoppingBasketId
        ActiveModificationSessionId
        ShoppingBasketExportCode
        IsCorporateClientContractPurchase
        Legs {
          OrderNumber
          DepartureTime
          DepartureTimeZone
          ArrivalTime
          ArrivalTimeZone
          PlannedDepartureTime
          PlannedDepartureTimeZone
          PlannedArrivalTime
          PlannedArrivalTimeZone
          DepartureStopName
          DepartureCityName
          DeparturePlatforms
          DestinationStopName
          DestinationCityName
          BrandName
          ServiceCode
          LineNumber
          TripName
          SubcontractorName
        }
        Passengers {
          ProductCode
          ExternalTicketNumber
          IsPartOfRoundTrip
          PassengerName
          PassengerCategory
          Tickets {
            CouponNumber
            LegOrderNumber
            Price
            Currency
            QrCode
          }
          Seats {
            BusNameTag
            LegOrderNumber
            SeatOrderNumber
            SeatClass
            SeatNumber
            SeatChangeStop
            SeatFloorNumber
            HasSeatBeenReleased
            Price
            Currency
          }
        }
      }
    }
  }
`;

export const BASKET_CONTENT = gql`
  mutation BasketContent {
    basketContent {
      error {
        ErrorCode
        ErrorReason
      }
      basketContent {
        ShoppingBasketId
        ShoppingBasketNumber
        IsCorporateClientContractPurchase
        Currency
        TotalPrice
        Passengers {
          ProductCode
          ProductId
          RegisteredTravelPassNumber
          PassengerName
          FareClass
          PriceClassName
          TotalPrice
          IsFreeModificationAllowed
          IsModificationAllowed
        }
        Services {
          Discount
          Description
          AddOnId
          Id
          Code
          Name
          Price
          Quantity
          VatRate
          VatAmount
        }
      }
    }
  }
`;

export const ZERO_PAYMENT_PAY = gql`
  mutation($Currency: String!) {
    sendTicketsToEmail(Currency: $Currency) {
      error {
        ErrorCode
        ErrorReason
      }
      success
      objects {
        IsOutbound
        DepartureTime
        DepartureTimeZone
        ArrivalTime
        ArrivalTimeZone
        DepartureStopName
        DepartureStopId
        DepartureCityName
        DepartureCityId
        DestinationStopName
        DestinationStopId
        DestinationCityName
        DestinationCityId
        ShoppingBasketCode
        ShoppingBasketId
        ActiveModificationSessionId
        ShoppingBasketExportCode
        IsCorporateClientContractPurchase
        Legs {
          OrderNumber
          DepartureTime
          DepartureTimeZone
          ArrivalTime
          ArrivalTimeZone
          PlannedDepartureTime
          PlannedDepartureTimeZone
          PlannedArrivalTime
          PlannedArrivalTimeZone
          DepartureStopName
          DepartureCityName
          DeparturePlatforms
          DestinationStopName
          DestinationCityName
          BrandName
          ServiceCode
          LineNumber
          TripName
          SubcontractorName
        }
        Passengers {
          ProductCode
          ExternalTicketNumber
          IsPartOfRoundTrip
          PassengerName
          PassengerCategory
          Tickets {
            CouponNumber
            LegOrderNumber
            Price
            Currency
            QrCode
          }
          Seats {
            BusNameTag
            LegOrderNumber
            SeatOrderNumber
            SeatClass
            SeatNumber
            SeatChangeStop
            SeatFloorNumber
            HasSeatBeenReleased
            Price
            Currency
          }
        }
      }
    }
  }
`;

export const SEND_TICKETS_TO_EMAIL = gql`
  mutation($email: String!, $lang: String, $basketId: String!) {
    sendTicketsToEmail(email: $email, lang: $lang, basketId: $basketId) {
      error {
        ErrorCode
        ErrorReason
      }
    }
  }
`;

export const DELETE_BASKET = gql`
  mutation DeleteBasket {
    deleteBasket {
      success
    }
  }
`;

export const RENEW_BASKET_EXPIRATION_TIME = gql`
  mutation RenewBasketExpirationTime {
    renewBasketExpirationTime {
      basket {
        ShoppingBasketId
        ShoppingBasketNumber
        ValidUntil
        ValidUntilInUtc
      }
      error {
        ErrorCode
        ErrorReason
      }
      success
    }
  }
`;

export const ADYEN_PAYMENT_DETAILS = gql`
  mutation($input: AdyenPaymentDetailsInput!) {
    adyenPaymentDetails(input: $input) {
      success
      message
      action
      error {
        ErrorCode
        ErrorReason
      }
      objects {
        IsOutbound
        DepartureTime
        DepartureTimeZone
        ArrivalTime
        ArrivalTimeZone
        DepartureStopName
        DepartureStopId
        DepartureCityName
        DepartureCityId
        DestinationStopName
        DestinationStopId
        DestinationCityName
        DestinationCityId
        ShoppingBasketCode
        ShoppingBasketId
        ActiveModificationSessionId
        ShoppingBasketExportCode
        IsCorporateClientContractPurchase
        Legs {
          OrderNumber
          DepartureTime
          DepartureTimeZone
          ArrivalTime
          ArrivalTimeZone
          PlannedDepartureTime
          PlannedDepartureTimeZone
          PlannedArrivalTime
          PlannedArrivalTimeZone
          DepartureStopName
          DepartureCityName
          DeparturePlatforms
          DestinationStopName
          DestinationCityName
          BrandName
          ServiceCode
          LineNumber
          TripName
          SubcontractorName
        }
        Passengers {
          ProductCode
          ExternalTicketNumber
          IsPartOfRoundTrip
          PassengerName
          PassengerCategory
          Tickets {
            CouponNumber
            LegOrderNumber
            Price
            Currency
            QrCode
          }
          Seats {
            BusNameTag
            LegOrderNumber
            SeatOrderNumber
            SeatClass
            SeatNumber
            SeatChangeStop
            SeatFloorNumber
            HasSeatBeenReleased
            Price
            Currency
          }
        }
      }
    }
  }
`;

export const ADYEN_PAYMENT = gql`
  mutation($input: AdyenPaymentInput!) {
    adyenPayment(input: $input) {
      success
      message
      error {
        ErrorCode
        ErrorReason
      }
      action
      details {
        key
        type
      }
      objects {
        IsOutbound
        DepartureTime
        DepartureTimeZone
        ArrivalTime
        ArrivalTimeZone
        DepartureStopName
        DepartureStopId
        DepartureCityName
        DepartureCityId
        DestinationStopName
        DestinationStopId
        DestinationCityName
        DestinationCityId
        ShoppingBasketCode
        ShoppingBasketId
        ActiveModificationSessionId
        ShoppingBasketExportCode
        IsCorporateClientContractPurchase
        Legs {
          OrderNumber
          DepartureTime
          DepartureTimeZone
          ArrivalTime
          ArrivalTimeZone
          PlannedDepartureTime
          PlannedDepartureTimeZone
          PlannedArrivalTime
          PlannedArrivalTimeZone
          DepartureStopName
          DepartureCityName
          DeparturePlatforms
          DestinationStopName
          DestinationCityName
          BrandName
          ServiceCode
          LineNumber
          TripName
          SubcontractorName
        }
        Passengers {
          ProductCode
          ExternalTicketNumber
          IsPartOfRoundTrip
          PassengerName
          PassengerCategory
          Tickets {
            CouponNumber
            LegOrderNumber
            Price
            Currency
            QrCode
          }
          Seats {
            BusNameTag
            LegOrderNumber
            SeatOrderNumber
            SeatClass
            SeatNumber
            SeatChangeStop
            SeatFloorNumber
            HasSeatBeenReleased
            Price
            Currency
          }
        }
      }
    }
  }
`;

export const CORPORATE_CLIENT_PAY = gql`
  mutation($input: CorporateClientPayInput) {
    corporateClientPay(input: $input) {
      error {
        ErrorCode
        ErrorReason
      }
      success
      objects {
        IsOutbound
        DepartureTime
        DepartureTimeZone
        ArrivalTime
        ArrivalTimeZone
        DepartureStopName
        DepartureStopId
        DepartureCityName
        DepartureCityId
        DestinationStopName
        DestinationStopId
        DestinationCityName
        DestinationCityId
        ShoppingBasketCode
        ShoppingBasketId
        ActiveModificationSessionId
        ShoppingBasketExportCode
        IsCorporateClientContractPurchase
        Legs {
          OrderNumber
          DepartureTime
          DepartureTimeZone
          ArrivalTime
          ArrivalTimeZone
          PlannedDepartureTime
          PlannedDepartureTimeZone
          PlannedArrivalTime
          PlannedArrivalTimeZone
          DepartureStopName
          DepartureCityName
          DeparturePlatforms
          DestinationStopName
          DestinationCityName
          BrandName
          ServiceCode
          LineNumber
          TripName
          SubcontractorName
        }
        Passengers {
          ProductCode
          ExternalTicketNumber
          IsPartOfRoundTrip
          PassengerName
          PassengerCategory
          Tickets {
            CouponNumber
            LegOrderNumber
            Price
            Currency
            QrCode
          }
          Seats {
            BusNameTag
            LegOrderNumber
            SeatOrderNumber
            SeatClass
            SeatNumber
            SeatChangeStop
            SeatFloorNumber
            HasSeatBeenReleased
            Price
            Currency
          }
        }
      }
    }
  }
`;

export const GET_AVAILABLE_PRICE_FOR_PRODUCTS = gql`
  mutation($input: GetAvailablePricesForProductsInput!) {
    getAvailablePricesForProducts(input: $input) {
      error {
        ErrorCode
        ErrorReason
      }
      success
      prices {
        ProductId
        OutboundLegs {
          OrderNumber
          Prices {
            BasicBonusSchemeId
            IsBusinessClass
            IsCampaign
            IsLoyaltyScheme
            Price
            PriceId
          }
        }
        InboundLegs {
          OrderNumber
          Prices {
            BasicBonusSchemeId
            IsBusinessClass
            IsCampaign
            IsLoyaltyScheme
            Price
            PriceId
          }
        }
      }
    }
  }
`;

export const UPDATE_PRODUCT_PRICE = gql`
  mutation($input: UpdateProductPricesInput) {
    updateProductPrices(input: $input) {
      error {
        ErrorCode
        ErrorReason
      }
      success
    }
  }
`;

export const CHANGE_SEAT_NUMBER = gql`
  mutation($input: ChangeSeatNumberInput) {
    changeSeatNumber(input: $input) {
      success
      error {
        ErrorCode
        ErrorReason
      }
    }
  }
`;

export const UPDATE_BUYER_INFO = gql`
  mutation($input: BuyerInfoInput) {
    updateBuyerInfo(input: $input) {
      error {
        ErrorCode
        ErrorReason
      }
      success
    }
  }
`;

export const UPDATE_CUSTOMER_INFO = gql`
  mutation($input: [UpdateCustomerInfoInput]!) {
    updateCustomerInfo(input: $input) {
      error {
        ErrorCode
        ErrorReason
      }
      success
    }
  }
`;

export const UPDATE_PASSENGERS_INFO = gql`
  mutation(
    $buyerInput: BuyerInfoInput
    $customerInput: [UpdateCustomerInfoInput]!
  ) {
    updateBuyerInfo(input: $buyerInput) {
      error {
        ErrorCode
        ErrorReason
      }
      success
    }
    updateCustomerInfo(input: $customerInput) {
      error {
        ErrorCode
        ErrorReason
      }
      success
    }
  }
`;

export const ADD_JOURNEY_TO_BASKET = gql`
  mutation(
    $OutboundJourneyId: String!
    $InboundJourneyId: String
    $PriceClass: String
    $InboundJourneyPriceClass: String
    $OutboundJourneyPriceClass: String
    $CampaignCode: String
    $lang: String
    $AffiliateId: String
  ) {
    addJourneyToBasket(
      OutboundJourneyId: $OutboundJourneyId
      InboundJourneyId: $InboundJourneyId
      PriceClass: $PriceClass
      InboundJourneyPriceClass: $InboundJourneyPriceClass
      OutboundJourneyPriceClass: $OutboundJourneyPriceClass
      CampaignCode: $CampaignCode
      lang: $lang
      AffiliateId: $AffiliateId
    ) {
      products {
        ShoppingBasketId
        TravelZone
        Currency
        TotalPrice
        HasTransfers
        Basket {
          ShoppingBasketId
          ShoppingBasketNumber
          ValidUntil
          ValidUntilInUtc
          CanRenew
        }
        BasketLevelAddons {
          ServiceName
          Id
          Quantity
          UnitPrice
          FinalPrice
          IsSMSService
        }
        TravelPass {
          Name
          Description
          UnitPrice
          Price
          VatRate
        }
        ParkingPasses {
          ProductId
          ProductCode
          ParkingLotName
          StartDate
          EndDate
          ParkingLotTimeZone
          Price
          VatRate
          PassengerProductId
        }
        Transfers {
          ProductId
          TransferName
          TransferStopName
          TransferDepartureTime
          TransferDepartureTimeZone
          Price
          VatRate
        }
        PaymentMethodFees {
          PaymentMethodId
          Price
          VatRate
        }
        BasketSalesFee {
          Price
          VatRate
        }
        OutboundJourneys {
          Products {
            ProductId
            ProductCode
            PassengerName
            RequestedFareClass
            AssignedFareClass
            TravelInsurance {
              Price
              VatRate
              Currency
              Name
            }
            ModificationTravelInsurance {
              Price
              VatRate
              Name
            }
            CancellationTravelInsurance {
              Price
              VatRate
              Name
            }
            TotalPrice
            SalesFee {
              Price
              VatRate
            }
            AddOnDetails {
              ServiceName
              Id
              Quantity
              UnitPrice
              FinalPrice
              JourneyLegOrderNumber
            }
          }
          Legs {
            DepartureStopName
            DestinationStopName
            OrderNumber
            SeatInfo {
              ProductId
              TicketNumber
              TripBusProfileId
              BusNameTag
              SeatFloorNumber
              SeatNumber
              SeatNumberLetter
              DepartureRouteStopName
              DestinationRouteStopName
              IsAutomaticallyAssigned
              IsBusinessClass
              SeatSelectionFee {
                Amount
                Currency
              }
              Prices {
                BasicBonusSchemeId
                IsBusinessClass
                IsCampaign
                IsLoyaltyScheme
                Price
                PriceId
              }
              Bonuses {
                FareClass
                FareClassName
                BonusSchemeId
                BonusSchemeName
              }
            }
            SeatPlan {
              IsOutbound
              OrderNumber
              Buses {
                TripBusProfileId
                Name
                AvailableSeatCount
                CanChooseSeatNumber
                RegistrationNumber
                SteeringWheelLocation
                Floors {
                  FloorNumber
                  SeatRows {
                    RowNumber
                    Seats {
                      ColumnNumber
                      SeatNumber
                      SeatNumberLetter
                      SeatClassCategory
                      SeatClassCategoryName
                      SeatClass
                      SeatClassName
                      SeatRank
                      IsWheelchairSeat
                      IsPetSeat
                      IsChildSeat
                      IsForwardSeating
                      HasElectricity
                      IsToilet
                      IsStairs
                      IsEmptyArea
                      IsAvailable
                      IsSoldLater
                      SeatPrice {
                        Amount
                        Currency
                      }
                      TicketPrice {
                        Amount
                        Currency
                      }
                      AdditionalSeat {
                        ChangeStopId
                        SeatNumber
                        SeatNumberLetter
                      }
                      ProductGuid
                    }
                  }
                }
              }
            }
          }
        }
        InboundJourneys {
          Products {
            ProductId
            ProductCode
            PassengerName
            RequestedFareClass
            AssignedFareClass
            TravelInsurance {
              Price
              VatRate
              Currency
              Name
            }
            ModificationTravelInsurance {
              Price
              VatRate
              Name
            }
            CancellationTravelInsurance {
              Price
              VatRate
              Name
            }
            TotalPrice
            SalesFee {
              Price
              VatRate
            }
            AddOnDetails {
              ServiceName
              Id
              Quantity
              UnitPrice
              FinalPrice
              JourneyLegOrderNumber
            }
          }
          Legs {
            DepartureStopName
            DestinationStopName
            OrderNumber
            SeatInfo {
              ProductId
              TicketNumber
              TripBusProfileId
              BusNameTag
              SeatFloorNumber
              SeatNumber
              SeatNumberLetter
              DepartureRouteStopName
              DestinationRouteStopName
              IsAutomaticallyAssigned
              IsBusinessClass
              SeatSelectionFee {
                Amount
                Currency
              }
              Prices {
                BasicBonusSchemeId
                IsBusinessClass
                IsCampaign
                IsLoyaltyScheme
                Price
                PriceId
              }
              Bonuses {
                FareClass
                FareClassName
                BonusSchemeId
                BonusSchemeName
              }
            }
            SeatPlan {
              IsOutbound
              OrderNumber
              Buses {
                TripBusProfileId
                Name
                AvailableSeatCount
                CanChooseSeatNumber
                RegistrationNumber
                SteeringWheelLocation
                Floors {
                  FloorNumber
                  SeatRows {
                    RowNumber
                    Seats {
                      ColumnNumber
                      SeatNumber
                      SeatNumberLetter
                      SeatClassCategory
                      SeatClassCategoryName
                      SeatClass
                      SeatClassName
                      SeatRank
                      IsWheelchairSeat
                      IsPetSeat
                      IsChildSeat
                      IsForwardSeating
                      HasElectricity
                      IsToilet
                      IsStairs
                      IsEmptyArea
                      IsAvailable
                      IsSoldLater
                      SeatPrice {
                        Amount
                        Currency
                      }
                      TicketPrice {
                        Amount
                        Currency
                      }
                      AdditionalSeat {
                        ChangeStopId
                        SeatNumber
                        SeatNumberLetter
                      }
                      ProductGuid
                    }
                  }
                }
              }
            }
          }
        }
      }
      success
      error {
        ErrorCode
        ErrorReason
      }
    }
  }
`;

export const NEO_PAY_DETAILS = gql`
  mutation($token: String!) {
    neoPayDetails(token: $token) {
      error {
        ErrorCode
        ErrorReason
      }
      objects {
        IsOutbound
        DepartureTime
        DepartureTimeZone
        ArrivalTime
        ArrivalTimeZone
        DepartureStopName
        DepartureStopId
        DepartureCityName
        DepartureCityId
        DestinationStopName
        DestinationStopId
        DestinationCityName
        DestinationCityId
        ShoppingBasketCode
        ShoppingBasketId
        ActiveModificationSessionId
        ShoppingBasketExportCode
        IsCorporateClientContractPurchase
        Legs {
          OrderNumber
          DepartureTime
          DepartureTimeZone
          ArrivalTime
          ArrivalTimeZone
          PlannedDepartureTime
          PlannedDepartureTimeZone
          PlannedArrivalTime
          PlannedArrivalTimeZone
          DepartureStopName
          DepartureCityName
          DeparturePlatforms
          DestinationStopName
          DestinationCityName
          BrandName
          ServiceCode
          LineNumber
          TripName
          SubcontractorName
        }
        Passengers {
          ProductCode
          ExternalTicketNumber
          IsPartOfRoundTrip
          PassengerName
          PassengerCategory
          Tickets {
            CouponNumber
            LegOrderNumber
            Price
            Currency
            QrCode
          }
          Seats {
            BusNameTag
            LegOrderNumber
            SeatOrderNumber
            SeatClass
            SeatNumber
            SeatChangeStop
            SeatFloorNumber
            HasSeatBeenReleased
            Price
            Currency
          }
        }
      }
    }
  }
`;

export const NEO_PAY_PAYMENT = gql`
  mutation NeoPayPaymentMutation {
    neoPayPayment {
      success
      error {
        ErrorCode
        ErrorReason
      }
      data
    }
  }
`;

export const BANK_LINK_PAYMENT = gql`
  mutation(
    $BankName: String!
    $FailRedirectUrl: String!
    $IsModification: Boolean
    $SuccessRedirectUrl: String!
    $lang: String
  ) {
    bankLinkPayment(
      BankName: $BankName
      FailRedirectUrl: $FailRedirectUrl
      IsModification: $IsModification
      SuccessRedirectUrl: $SuccessRedirectUrl
      lang: $lang
    ) {
      error {
        ErrorCode
        ErrorReason
      }
      payload {
        VK_SERVICE
        VK_VERSION
        VK_SND_ID
        VK_STAMP
        VK_AMOUNT
        VK_CURR
        VK_REF
        VK_MSG
        VK_RETURN
        VK_CANCEL
        VK_DATETIME
        VK_MAC
        VK_LANG
        VK_ENCODING
      }
      bankRequestUrl
    }
  }
`;

export const PAY_WITH_VOUCHER = gql`
  mutation($VoucherCode: String!) {
    payWithVoucher(VoucherCode: $VoucherCode) {
      basketPayed
      data {
        PaidAmount
        VoucherRemainingValue
        VoucherCurrency
        IsVoucherUsed
      }
      error {
        ErrorCode
        ErrorReason
      }
      objects {
        IsOutbound
        DepartureTime
        DepartureTimeZone
        ArrivalTime
        ArrivalTimeZone
        DepartureStopName
        DepartureStopId
        DepartureCityName
        DepartureCityId
        DestinationStopName
        DestinationStopId
        DestinationCityName
        DestinationCityId
        ShoppingBasketCode
        ShoppingBasketId
        ActiveModificationSessionId
        ShoppingBasketExportCode
        IsCorporateClientContractPurchase
        Legs {
          OrderNumber
          DepartureTime
          DepartureTimeZone
          ArrivalTime
          ArrivalTimeZone
          PlannedDepartureTime
          PlannedDepartureTimeZone
          PlannedArrivalTime
          PlannedArrivalTimeZone
          DepartureStopName
          DepartureCityName
          DeparturePlatforms
          DestinationStopName
          DestinationCityName
          BrandName
          ServiceCode
          LineNumber
          TripName
          SubcontractorName
        }
        Passengers {
          ProductCode
          ExternalTicketNumber
          IsPartOfRoundTrip
          PassengerName
          PassengerCategory
          Tickets {
            CouponNumber
            LegOrderNumber
            Price
            Currency
            QrCode
          }
          Seats {
            BusNameTag
            LegOrderNumber
            SeatOrderNumber
            SeatClass
            SeatNumber
            SeatChangeStop
            SeatFloorNumber
            HasSeatBeenReleased
            Price
            Currency
          }
        }
      }
    }
  }
`;

export const GET_JOURNEY_ADDONS = gql`
  mutation(
    $lang: String!
    $productIds: [String]!
    $currency: String!
    $addonId: Int
  ) {
    getJourneyAddons(
      lang: $lang
      productIds: $productIds
      currency: $currency
      addonId: $addonId
    ) {
      error {
        ErrorCode
        ErrorReason
      }
      addons {
        ResponseValidUntilUtc
        LegAddOns {
          ProductId
          IsOutbound
          LegOrderNo
          AddOns {
            SalesLimit
            UnitPriceOnNextLegs
            AddonGuid
            AddonId
            ServiceName
            UnitPrice
            CurrencyXc
            VatRate
            Description
          }
        }
      }
    }
  }
`;

export const ADD_ADDONS = gql`
  mutation($productId: String!, $addons: [AddAddonInput]!) {
    addAddon(productId: $productId, addons: $addons) {
      error {
        ErrorCode
        ErrorReason
      }
      success
    }
  }
`;

export const YOO_MONEY_PAYMENT = gql`
  mutation($RedirectUrl: String!, $Smart: Boolean) {
    yooMoneyPayment(RedirectUrl: $RedirectUrl, Smart: $Smart) {
      success
      error {
        ErrorCode
        ErrorReason
      }
      confirmationUrl
    }
  }
`;

export const YOO_MONEY_PAYMENT_DETAILS = gql`
  mutation($orderId: String!) {
    yooMoneyPaymentDetails(orderId: $orderId) {
      success
      error {
        ErrorCode
        ErrorReason
      }
      objects {
        IsOutbound
        DepartureTime
        DepartureTimeZone
        ArrivalTime
        ArrivalTimeZone
        DepartureStopName
        DepartureStopId
        DepartureCityName
        DepartureCityId
        DestinationStopName
        DestinationStopId
        DestinationCityName
        DestinationCityId
        ShoppingBasketCode
        ShoppingBasketId
        ActiveModificationSessionId
        ShoppingBasketExportCode
        IsCorporateClientContractPurchase
        Legs {
          OrderNumber
          DepartureTime
          DepartureTimeZone
          ArrivalTime
          ArrivalTimeZone
          PlannedDepartureTime
          PlannedDepartureTimeZone
          PlannedArrivalTime
          PlannedArrivalTimeZone
          DepartureStopName
          DepartureCityName
          DeparturePlatforms
          DestinationStopName
          DestinationCityName
          BrandName
          ServiceCode
          LineNumber
          TripName
          SubcontractorName
        }
        Passengers {
          ProductCode
          ExternalTicketNumber
          IsPartOfRoundTrip
          PassengerName
          PassengerCategory
          Tickets {
            CouponNumber
            LegOrderNumber
            Price
            Currency
            QrCode
          }
          Seats {
            BusNameTag
            LegOrderNumber
            SeatOrderNumber
            SeatClass
            SeatNumber
            SeatChangeStop
            SeatFloorNumber
            HasSeatBeenReleased
            Price
            Currency
          }
        }
      }
    }
  }
`;
