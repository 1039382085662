import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { PageLoading, Container, Button, Loading } from '@lux/ui';
import s from './thanks-layout.module.scss';

const ThanksContentPending = ({ className, t }) => {
  const text = t('tickets.thanks.text1');

  return (
    <div className={cn(s.thanksLayout, className)}>
      <Container className={s.container}>
        <div className={s.greyBlock} />
        <Container full={false}>
          <section>
            <h1>{t('thank you for choosing Lux Express')}</h1>
            {/* <div className={s.orderNumber}>
              {t('order number')}: <span>{orderNumber}</span>
            </div> */}
            <div className={s.content}>
              <img className={s.icon} src="/images/svg/pending.svg" alt="" />
              <h2>{t('we are preparing your tickets')}</h2>
              <p>
                {t(
                  'please wait, your tickets will be available for downloading in several seconds. When ready they will be automatically send to your email.'
                )}
              </p>
              <div className={s.controls}>
                <Button
                  className={s.button}
                  disabled
                  text={t('print tickets')}
                />
                <Button
                  className={s.button}
                  disabled
                  text={t('send tickets')}
                />
              </div>
              <Loading className={s.loading} />
            </div>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(ThanksContentPending);
