import React, { useState, useContext } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { StoreContext } from '@lux/spa/store';
import { Modal, ModalContent, Button } from '@lux/ui';
import s from './select-price-class.module.scss';

const SelectPriceClass = ({ t, i18n, isOpen, toggleModal, history }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { language } = i18n;
  const { symbol } = state.changeTickets;
  const { priceClasses } = state.changeTickets;
  const [priceClass, setPriceClass] = useState(priceClasses[0].PriceClassName);

  const regularPrice = priceClasses.find(
    item => item.PriceClassName === 'Regular'
  ).Price;

  const handleContinue = () => {
    dispatch({
      type: 'UPDATE_CHANGE_TICKETS',
      payload: {
        priceClass,
      },
    });

    history.push(`/${language}/change-tickets/seat-details/`);
  };

  return (
    <Modal className={s.modal} {...{ isOpen, toggleModal }}>
      <ModalContent>
        <h1>{t('confirm ticket type')}</h1>
        <div className={s.prices}>
          {priceClasses
            .sort((a, b) => a.Price - b.Price)
            .map(price => {
              return price.PriceClassName === 'Campaign' &&
                (price.Price === regularPrice || regularPrice === 0) ? null : (
                <div
                  className={cn(
                    s.priceClass,
                    price.PriceClassName === priceClass && s.selected
                  )}
                  onClick={() => setPriceClass(price.PriceClassName)}
                >
                  <div className={s.check} />
                  <span>{price.PriceClassName}</span>
                  <div className={s.price}>
                    {price.Price} {symbol}
                  </div>
                </div>
              );
            })}
        </div>
        <div className={s.controls}>
          <Button
            className={s.button}
            text={t('reserve seat')}
            onClick={handleContinue}
          />
          <div
            className={s.cancel}
            // onClick={loading ? null : toggleModal}
            onClick={toggleModal}
          >
            {t('cancel')}
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default withRouter(withTranslation()(SelectPriceClass));
