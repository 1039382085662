import React, { createContext, useState, useEffect } from 'react';

export const WidthContext = createContext(null);

export const WidthProvider = ({ children }) => {
  let context = {};
  if (isClient) {
    const MAX_MOBILE = 767;
    const MAX_TABLET = 1024;
    const MAX_LAPTOP = 1279;
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        setWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const isMob = MAX_MOBILE >= width;
    const isTab = MAX_MOBILE < width && width <= MAX_TABLET;
    const isTabUp = MAX_MOBILE < width;
    const isTabDown = MAX_TABLET >= width;
    const isLap = MAX_TABLET < width && width <= MAX_LAPTOP;
    const isLapUp = MAX_TABLET < width;
    const isLapDown = MAX_LAPTOP >= width;
    const isDesk = MAX_LAPTOP < width;

    context = {
      width,
      isMob,
      isTab,
      isTabUp,
      isTabDown,
      isLap,
      isLapUp,
      isLapDown,
      isDesk,
      MAX_MOBILE,
      MAX_TABLET,
      MAX_LAPTOP,
    };
  }

  return (
    <WidthContext.Provider value={isClient ? context : {}}>
      {children}
    </WidthContext.Provider>
  );
};
