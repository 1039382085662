import React, { useState } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Query } from 'react-apollo';
import { GET_ALL_NEWS, GET_ALL_NEWS_ADMIN } from '@lux/spa/apollo/queries';
import { Container, Divider, Button, Link, Loading } from '@lux/ui';
import { meta } from '@lux/spa/components';
import { localizedDate } from '@lux/spa/utils';
import i from '@lux/spa/styles/icons.module.scss';
import s from './blog.module.scss';

const NewsList = ({ t, newsList, onLoadMore, hasNextPage }) => {
  return (
    <>
      <h2 className={s.title}>{t('latest news')}</h2>
      {!!newsList?.length && (
        <>
          <div className={s.newsList}>
            {newsList.map(({ node }, key) => {
              const { slug } = node.data;
              const date = node.publishDate;
              const title = node.data.pageData.title.value;
              return (
                <Link key={key} to={`/news/${slug}/`} className={s.item}>
                  <div className={s.date}>
                    {localizedDate(date, 'dd MMMM yyyy')}
                    {!node.isPublished && (
                      <div className={!node.isPublished && s.unpublishedText}>
                        {t('unpublished')}
                      </div>
                    )}
                  </div>
                  <h5
                    className={cn(
                      s.itemTitle,
                      !node.isPublished && s.unpublished
                    )}
                  >
                    {title}
                  </h5>
                </Link>
              );
            })}
          </div>
          {hasNextPage && (
            <div className={s.showMore} onClick={onLoadMore}>
              {t('show more')}
              <i className={i.arrowRight_v2} />
            </div>
          )}
        </>
      )}
    </>
  );
};

const Blog = ({ className, region, isStaff, i18n, t }) => {
  const { language } = i18n;
  const [lastNews, setLastNews] = useState(null);
  let backgroundImage;
  if (lastNews) {
    backgroundImage = lastNews.data.pageData.banner.value
      ? `linear-gradient(90deg, #000000d9, transparent), url(${lastNews.data.pageData.banner.value})`
      : `linear-gradient(90deg, #000000d9, transparent)`;
  } else {
    // default background if no news
    backgroundImage = `linear-gradient(90deg, #000000d9, transparent)`;
  }

  const slug = 'blog';

  let seo = {
    et: {
      title: {
        value: 'Teated ja artiklid',
      },
      description: {
        value: 'Tutvu teadete ja artiklitega.',
      },
      socialTitle: {
        value: 'Teated ja artiklid',
      },
      socialDescription: {
        value: 'Tutvu teadete ja artiklitega.',
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value: '/images/thumbnails/blog/Lux_Express_news_and_stories.jpg',
      },
    },
    en: {
      title: {
        value: 'News and stories',
      },
      description: {
        value: 'See news and stories by Lux Express.',
      },
      socialTitle: {
        value: 'News and stories',
      },
      socialDescription: {
        value: 'See news and stories by Lux Express.',
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value: '/images/thumbnails/blog/Lux_Express_news_and_stories.jpg',
      },
    },
    lt: {
      title: {
        value: 'Lux Express naujienos ir pasakojimai',
      },
      description: {
        value: 'Lux Express naujienos ir pasakojimai.',
      },
      socialTitle: {
        value: 'Lux Express naujienos ir pasakojimai',
      },
      socialDescription: {
        value: 'Lux Express naujienos ir pasakojimai.',
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value: '/images/thumbnails/blog/Lux_Express_news_and_stories.jpg',
      },
    },
    lv: {
      title: {
        value: 'Jaunumi un raksti',
      },
      description: {
        value: 'Apskati Lux Express jaunumus un rakstus.',
      },
      socialTitle: {
        value: 'Jaunumi un raksti',
      },
      socialDescription: {
        value: 'Apskati Lux Express jaunumus un rakstus.',
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value: '/images/thumbnails/blog/Lux_Express_news_and_stories.jpg',
      },
    },
    ru: {
      title: {
        value: 'Новости и рассказы от Lux Express',
      },
      description: {
        value: 'Новости и рассказы от Lux Express.',
      },
      socialTitle: {
        value: 'Новости и рассказы от Lux Express',
      },
      socialDescription: {
        value: 'Новости и рассказы от Lux Express.',
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value: '/images/thumbnails/blog/Lux_Express_news_and_stories.jpg',
      },
    },
  };

  seo = seo[language];

  const slugs = {
    en: {
      value: slug,
    },
    et: {
      value: slug,
    },
    lv: {
      value: slug,
    },
    lt: {
      value: slug,
    },
    ru: {
      value: slug,
    },
  };

  return (
    <div className={cn(className, s.blog)}>
      <Helmet {...meta({ seo, lang: language, slugs })} />
      <Container
        className={s.bannerContainer}
        style={{
          backgroundImage,
        }}
      >
        <Container full={false} className={s.banner}>
          <section>
            {lastNews ? (
              <>
                <p className={s.date}>
                  {localizedDate(lastNews.publishDate, 'dd MMMM yyyy')}
                </p>
                <h1>{lastNews.data.pageData.title.value}</h1>
                <Button
                  to={`/news/${lastNews.data.slug}`}
                  text={t('read more')}
                />
              </>
            ) : null}
          </section>
        </Container>
      </Container>
      <Divider />
      <Container className={s.news}>
        <Container full={false} className={s.banner}>
          <section>
            <Query
              query={isStaff ? GET_ALL_NEWS_ADMIN : GET_ALL_NEWS}
              ssr={false}
              fetchPolicy={isStaff ? 'network-only' : 'cache-first'}
              variables={{
                lang: language,
                country: region,
                first: 6,
              }}
            >
              {({ data, loading, fetchMore, ...props }) => {
                if (loading) return <Loading />;
                const newsList = isStaff
                  ? data?.adminNewsList?.edges
                  : data?.newsList?.edges;
                const { hasNextPage, endCursor } = isStaff
                  ? data?.adminNewsList?.pageInfo
                  : data?.newsList?.pageInfo;

                if (newsList?.length) {
                  setLastNews(newsList[0]?.node);
                }

                return (
                  <NewsList
                    t={t}
                    hasNextPage={hasNextPage}
                    newsList={newsList || []}
                    onLoadMore={() => {
                      fetchMore({
                        query: isStaff ? GET_ALL_NEWS_ADMIN : GET_ALL_NEWS,
                        variables: {
                          lang: language,
                          country: region,
                          first: 6,
                          after: endCursor,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                          const newEdges = isStaff
                            ? fetchMoreResult?.adminNewsList?.edges
                            : fetchMoreResult?.newsList?.edges;
                          const pageInfo = isStaff
                            ? fetchMoreResult?.adminNewsList?.pageInfo
                            : fetchMoreResult?.newsList?.pageInfo;

                          return newEdges?.length
                            ? {
                                [isStaff ? 'adminNewsList' : 'newsList']: {
                                  edges: [
                                    ...previousResult[
                                      isStaff ? 'adminNewsList' : 'newsList'
                                    ].edges,
                                    ...newEdges,
                                  ],
                                  pageInfo,
                                },
                              }
                            : previousResult;
                        },
                      });
                    }}
                  />
                );
              }}
            </Query>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(Blog);
