import React from 'react';

import cn from 'classnames';
import s from './tabs.module.scss';

const TabContent = ({ className, children, active }) => {
  const classes = cn(s.content, active && s.active, className);
  return active && <div className={classes}>{children}</div>;
};

export default TabContent;
