import React, { useState, useEffect, useContext } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { StoreContext } from '@lux/spa/store';
import { SendTickets, Container, Button, Loading } from '@lux/ui';
import s from './thanks-layout.module.scss';

const ThanksContent = ({
  fileLoading,
  getTicketsFileUrl,
  className,
  t,
  type,
  instantSuccess = false,
  linkToPDFFile,
}) => {
  const [state, dispatch] = useContext(StoreContext);
  const {
    totalPrice,
    passengersCount,
    products = {},
    from = {},
    to = {},
  } = state.tickets;
  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';
  const { Basket = {} } = products;
  const { ShoppingBasketId = '' } = Basket;
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    dispatch({
      type: 'UPDATE_TICKETS',
      payload: {
        paymentSuccessful: true,
      },
    });

    exponea.track('purchase', {
      timestamp: Date.now(),
      payment_method: type,
      total_price: totalPrice,
      currency: 'RUB',
      count_passengers: passengersCount,
      Origin_stop_ID: from.BusStopId,
      Destination_stop_ID: to.BusStopId,
    });
  }, []);

  useEffect(() => {
    if (instantSuccess) {
      getTicketsFileUrl();
    }
  }, []);

  return (
    <div className={cn(s.thanksLayout, className)}>
      <SendTickets
        basketId={ShoppingBasketId}
        toggleModal={toggleModal}
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
      />
      <Container className={s.container}>
        <div className={s.greyBlock} />
        <Container full={false}>
          <section>
            <h1>{t('thank you for choosing Lux Express')}</h1>
            {/* <div className={s.orderNumber}>
              {t('order number')}: <span>{orderNumber}</span>
            </div> */}
            <div className={s.content}>
              <img className={s.icon} src="/images/svg/checked.svg" alt="" />
              <h2>{t('we have sent the tickets to your email')}</h2>
              <p>
                {t(
                  'if you want to dowload the tickets or send them to another email adress, click the buttons below.'
                )}
              </p>
              <div className={s.controls}>
                <Button
                  className={s.button}
                  onClick={() => {
                    window.open(linkToPDFFile, '_blank');
                  }}
                  // onClick={getTicketsFileUrl}
                  disabled={fileLoading}
                >
                  {fileLoading ? <Loading /> : t('print tickets')}
                </Button>
                <Button
                  className={s.button}
                  text={t('send tickets')}
                  onClick={toggleModal}
                />
              </div>
            </div>

            {/* <p>
            Download schedule and trip to your calendar:
            <Link to="/">iCal</Link>
            <Link to="/">Outlook</Link>
            <Link to="">Google</Link>
          </p> */}
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(ThanksContent);
