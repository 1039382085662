import React from 'react';
import cn from 'classnames';
import { Container } from '@lux/ui';
import s from './payment-corporate-client.module.scss';

const PaymentCorporateClient = ({ className }) => {
  return (
    <div className={cn(s.paymentCorporateClient, className)}>
      <Container>
        <Container full={false}>
          <section>
            <div>Payment Corporate Client page</div>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default PaymentCorporateClient;
