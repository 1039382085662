import React, { useContext, useState } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { Collapse } from 'react-collapse';
import { useQuery } from '@apollo/react-hooks';
import { Tooltip, LazyLoadImage } from '@lux/ui';
import { COMPANY_LOGO } from 'apollo/queries';
import i from '@lux/spa/styles/icons.module.scss';
import s from './StopsNew.module.scss';

const Stop = ({
  t,
  time = '',
  city = '',
  country = '',
  text = '',
  stopName = '',
  className,
  empty = false,
  transfer = false,
}) => {
  return (
    <div className={cn(s.stop, Boolean(text.length) && s.transfer, className)}>
      <div className={s.time}>
        {!empty && <div className={s.circle} />}
        {time}
      </div>
      <div className={s.destination}>
        <div className={s.stopName}>
          {!empty && <span>{stopName}</span>}
          {transfer && (
            <div className={s.transferText}>
              <div>{t('transfer')}</div>
              {Boolean(text.length) && (
                <Tooltip className={s.tooltip}>{text}</Tooltip>
              )}
            </div>
          )}
        </div>
        <div className={s.cityCountry}>
          {city}, {country}
        </div>
      </div>
    </div>
  );
};

const StopNew = ({
  t,
  from,
  to,
  fromTime = '',
  toTime = '',
  className,
  transfer = false,
  isLast,
  isFirst,
  interval,
  isMob,
  isIntermediat,
}) => {
  const transferTime =
    transfer &&
    `${interval.days ? ` ${interval.days}${t('short.day')}` : ''} ${
      interval.hours
    }${t('short.hour')} ${interval.minutes}${t('short.minute')}`;

  return (
    <div className={cn(s.stop, transfer && s.transfer, className)}>
      {transfer && (
        <div className={s.transferText}>
          <img src="/images/svg/passenger_v2.svg" alt="" />
          {t('transfer time')}: {transferTime}
        </div>
      )}
      <div className={s.destinations}>
        <div className={s.station}>
          <div className={cn(s.time, isFirst && s.endingStation)}>
            <div className={s.circle} />
            {fromTime}
          </div>
          <div className={cn(s.stopName, isFirst && s.endingStation)}>
            <span>{from}</span>
          </div>
        </div>
        <div className={s.station}>
          <div className={cn(s.time, isLast && s.endingStation)}>
            <div className={s.circle} />
            {toTime}
          </div>
          <div className={cn(s.stopName, isLast && s.endingStation)}>
            <span>{to}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const TransferStop = ({
  t,
  from,
  to,
  fromTime = '',
  toTime = '',
  className,
  transfer = false,
  isPartner,
  busEquipment,
  isLast,
  isFirst,
  interval,
  isMob,
  brandName,
  busCompanyName,
  isTripDdetails,
  intermediateStops,
  showAllStops,
  companyLogos = [],
  loadingLogos,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const equipmentTypes = {
    ['COFFEE_MACHINE']: '/images/svg/hot-drinks.svg',
    ['WC']: '/images/svg/wc.svg',
    ['MULTIMEDIA_DISPLAY']: '/images/svg/multimedia.svg',
    ['INTERNET']: '/images/svg/wi-fi.svg',
    ['A_C']: '/images/svg/climate-control.svg',
    ['220V']: '/images/svg/220v-outlet.svg',
    ['COLD_DRINKS_MACHINE']: '/images/svg/free-bottle-of-water.svg',
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const transferTime =
    transfer &&
    `${interval.days ? ` ${interval.days}${t('short.day')}` : ''} ${
      interval.hours
    }${t('short.hour')} ${interval.minutes}${t('short.minute')}`;

  const brandLogoLink =
    (!loadingLogos &&
      brandName &&
      companyLogos.find(
        company => company.name.toLowerCase() === brandName.toLowerCase()
      )?.file) ||
    '/images/svg/bus_v2.svg';
  return (
    <div
      className={cn(
        s.stop,
        transfer && s.transfer,
        isOpen && s.showEquipments,
        showAllStops && s.showAllStops,
        className
      )}
    >
      {transfer && (
        <div className={s.transferText}>
          <img src="/images/svg/passenger_v2.svg" alt="" />
          {t('transfer time')}: {transferTime}
        </div>
      )}
      {!showAllStops && isMob && (
        <div className={s.toggle} onClick={toggle}>
          <i className={i.arrowBottom} />
        </div>
      )}
      <div className={s.destinations}>
        <div className={s.station}>
          <div className={cn(s.time, isFirst && s.endingStation)}>
            <div className={s.circle} />
            {fromTime}
          </div>
          <div className={cn(s.stopName, isFirst && s.endingStation)}>
            <span>{from}</span>
          </div>
        </div>
        {showAllStops &&
          intermediateStops.map(stop => {
            return (
              <div className={s.station}>
                <div className={s.time}>
                  <div className={s.circle} />
                  {stop.time}
                </div>
                <div className={s.stopName}>
                  <span>{stop.stopName}</span>
                </div>
              </div>
            );
          })}
        <div className={s.station}>
          <div className={cn(s.time, isLast && s.endingStation)}>
            <div className={s.circle} />
            {toTime}
          </div>
          <div className={cn(s.stopName, isLast && s.endingStation)}>
            <span>{to}</span>
          </div>
        </div>
      </div>
      {!showAllStops && isMob && (
        <Collapse isOpened={isOpen}>
          <div className={s.busInformation}>
            <div className={s.title}>
              {busCompanyName || t('partner organization')}
            </div>
            {busEquipment.length ? (
              <div className={s.equipment}>
                {busEquipment.length &&
                  busEquipment.map((item, key) => {
                    const propKey = item.Code.replace(
                      'BUS_PROFILE_PROPERTY.',
                      ''
                    );
                    return (
                      <div
                        key={key}
                        className={s.img}
                        data-title-center
                        data-title={item.DisplayName}
                      >
                        <LazyLoadImage src={equipmentTypes[propKey]} alt="" />
                      </div>
                    );
                  })}
              </div>
            ) : (
              <p className={s.info}>
                {t(
                  'all services might not be provided in accordance with LUX Express standarts'
                )}
              </p>
            )}
          </div>
        </Collapse>
      )}
      {!showAllStops && (
        <div className={s.logo}>
          <LazyLoadImage
            src={hasError ? '/images/svg/bus_v2.svg' : brandLogoLink}
            alt={`luxexpress, ${brandName}`}
            onError={() => setHasError(true)}
          />
          {/* {isPartner ? (
            <LazyLoadImage
              src="/images/svg/bus_v2.svg"
              alt="luxexpress, partner bus"
            />
          ) : (
            <LazyLoadImage
              src="/images/svg/logo-grey.svg"
              alt="luxexpress bus"
            />
          )} */}
        </div>
      )}

      {!isMob && !showAllStops && (
        <div className={s.busInformation}>
          <div className={s.title}>
            {busCompanyName || t('partner organization')}
          </div>
          {busEquipment.length ? (
            <div className={s.equipment}>
              {busEquipment.length &&
                busEquipment.map((item, key) => {
                  const propKey = item.Code.replace(
                    'BUS_PROFILE_PROPERTY.',
                    ''
                  );
                  return (
                    <div
                      key={key}
                      className={s.img}
                      data-title-center
                      data-title={item.DisplayName}
                    >
                      <LazyLoadImage src={equipmentTypes[propKey]} alt="" />
                    </div>
                  );
                })}
            </div>
          ) : (
            <p className={s.info}>
              {t(
                'all services might not be provided in accordance with LUX Express standarts'
              )}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

const StopsNew = ({
  t,
  stops = [],
  likeMobile = false,
  className,
  showAllStops = false,
}) => {
  const { isMob } = useContext(WidthContext);

  const { data = {}, loading } = useQuery(COMPANY_LOGO);
  return (
    <div
      className={cn(
        s.stopsNew,
        (isMob || likeMobile) && s.likeMobile,
        className
      )}
    >
      {stops.map((stop, key) => (
        <TransferStop
          {...stop}
          t={t}
          isMob={isMob}
          isFirst={key === 0}
          isLast={key === stops.length - 1}
          key={stop?.city + stop?.country}
          showAllStops={showAllStops}
          companyLogos={data.companyLogo}
          loadingLogos={loading}
        />
      ))}
    </div>
  );
};

export default withTranslation()(StopsNew);
