export const emptyData = {
  lang: 'en',
  isPublished: true,
  countries: ['ee'],
  seo: {
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 80,
    },
    slug: {
      value: '',
      fieldName: 'slug',
      placeholder: 'Slug / URL',
      maxLength: 120,
    },
    description: {
      value: '',
      fieldName: 'description',
      placeholder: 'Page description',
      maxLength: 158,
    },
    socialTitle: {
      value: '',
      fieldName: 'socialTitle',
      placeholder: 'Social Title',
      maxLength: 160,
    },
    socialDescription: {
      value: '',
      fieldName: 'socialDescription',
      placeholder: 'Social description',
      maxLength: 160,
    },
    pageKeywords: {
      value: '',
      fieldName: 'pageKeywords',
      placeholder: 'Page keywords',
      maxLength: 40,
    },
    socialImage: '',
    articlePublishedTime: '',
    articleModifiedTime: '',
  },
  pageData: {
    banner: {
      value: '',
      fieldName: 'banner',
      placeholder: 'Banner',
      size: {
        height: 1100,
        width: 2880,
      },
    },
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 50,
    },
    subTitle: {
      value: '',
      fieldName: 'subTitle',
      placeholder: 'Page subtitle',
      maxLength: 200,
    },
    heading1: {
      value: '',
      fieldName: 'heading1',
      placeholder: 'Heading',
      maxLength: 100,
    },
    heading2: {
      value: '',
      fieldName: 'heading2',
      placeholder: 'Heading',
      maxLength: 100,
    },
    heading3: {
      value: '',
      fieldName: 'heading3',
      placeholder: 'Heading',
      maxLength: 100,
    },
    heading4: {
      value: '',
      fieldName: 'heading4',
      placeholder: 'Heading',
      maxLength: 100,
    },
    heading5: {
      value: '',
      fieldName: 'heading5',
      placeholder: 'Heading',
      maxLength: 100,
    },
    // heading6: {
    //   value: '',
    //   fieldName: 'heading6',
    //   placeholder: 'Heading',
    //   maxLength: 100
    // },
    text1: {
      value: '',
      fieldName: 'text1',
      placeholder: 'Text',
      maxLength: 300,
    },
    text2: {
      value: '',
      fieldName: 'text2',
      placeholder: 'Text',
      maxLength: 300,
    },
    // text3: {
    //   value: "",
    //   fieldName: "text3",
    //   placeholder: "Text",
    //   maxLength: 300
    // },
    editor1: '',
    editor1_1: '',
    editor2: '',
    editor2_1: '',
    loungeSeats: {
      fieldName: 'loungeSeats',
      slides: [],
    },
    regularSeats: {
      fieldName: 'regularSeats',
      slides: [],
    },
  },
};
export const defaultData = {
  lang: 'en',
  isPublished: true,
  countries: ['ee'],
  seo: {
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 80,
    },
    slug: {
      value: '',
      fieldName: 'slug',
      placeholder: 'Slug / URL',
      maxLength: 120,
    },
    description: {
      value: '',
      fieldName: 'description',
      placeholder: 'Page description',
      maxLength: 158,
    },
    socialTitle: {
      value: '',
      fieldName: 'socialTitle',
      placeholder: 'Social Title',
      maxLength: 160,
    },
    socialDescription: {
      value: '',
      fieldName: 'socialDescription',
      placeholder: 'Social description',
      maxLength: 160,
    },
    pageKeywords: {
      value: '',
      fieldName: 'pageKeywords',
      placeholder: 'Page keywords',
      maxLength: 40,
    },
    socialImage: '',
    articlePublishedTime: '',
    articleModifiedTime: '',
  },
  pageData: {
    banner: {
      value: '',
      fieldName: 'banner',
      placeholder: 'Banner',
      size: {
        height: 1100,
        width: 2880,
      },
    },
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 50,
    },
    subTitle: {
      value: '',
      fieldName: 'subTitle',
      placeholder: 'Page subtitle',
      maxLength: 200,
    },
    heading1: {
      value: '',
      fieldName: 'heading1',
      placeholder: 'Heading',
      maxLength: 100,
    },
    heading2: {
      value: '',
      fieldName: 'heading2',
      placeholder: 'Heading',
      maxLength: 100,
    },
    heading3: {
      value: '',
      fieldName: 'heading3',
      placeholder: 'Heading',
      maxLength: 100,
    },
    heading4: {
      value: '',
      fieldName: 'heading4',
      placeholder: 'Heading',
      maxLength: 100,
    },
    heading5: {
      value: '',
      fieldName: 'heading5',
      placeholder: 'Heading',
      maxLength: 100,
    },
    // heading6: {
    //   value: "",
    //   fieldName: "heading6",
    //   placeholder: "Heading",
    //   maxLength: 100
    // },
    text1: {
      value: '',
      fieldName: 'text1',
      placeholder: 'Text',
      maxLength: 300,
    },
    text2: {
      value: '',
      fieldName: 'text2',
      placeholder: 'Text',
      maxLength: 300,
    },
    // text3: {
    //   value: "",
    //   fieldName: "text3",
    //   placeholder: "Text",
    //   maxLength: 300
    // },
    editor1: '',
    editor1_1: '',
    editor2: '',
    editor2_1: '',
    loungeSeats: {
      fieldName: 'loungeSeats',
      slides: [],
    },
    regularSeats: {
      fieldName: 'regularSeats',
      slides: [],
    },
  },
};
