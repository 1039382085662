import React from 'react';
import cn from 'classnames';
import { Link } from '@lux/ui';

import s from './tags.module.scss';

const Tag = ({ className, text, to, ...props }) => {
  return to ? (
    <Link to={to} className={cn(s.tag, className)} {...props}>
      {text}
    </Link>
  ) : (
    <div className={cn(s.tag, className)} {...props}>
      {text}
    </div>
  );
};

export default Tag;
