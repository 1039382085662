import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/react-hooks';
import { StoreContext } from '@lux/spa/store';
import { NotFound } from '@lux/spa/routes';
import { TICKETS_FILE_URL } from '@lux/spa/apollo/queries';
import {
  Adyen,
  Banks,
  CorporateClient,
  ZeroPayment,
  BankLink,
  Yookassa,
} from './components';
import ThanksLayoutSuccess from './thanks-layout-success.jsx';

const Thanks = ({ i18n, className, history }) => {
  const [state, dispatch] = useContext(StoreContext);
  const [linkToPDFFile, setLinkToPDFFile] = useState('');
  const { language } = i18n;
  const { products = {}, payment } = state.tickets;
  const { ShoppingBasketId } = products;
  const urlParams = {};
  history.location.search
    .replace('?', '')
    .split('&')
    .forEach(param => {
      const key = param.split('=')[0];
      const value = param.split('=')[1];
      urlParams[key] = value;
    });

  const { shopperOrder = '', type = '' } = urlParams;

  const paymentType = type || payment.status;

  const [getTicketsFileUrl, { loading: fileLoading }] = useLazyQuery(
    TICKETS_FILE_URL,
    {
      variables: {
        lang: language,
        basketId: ShoppingBasketId,
      },
      fetchPolicy: 'network-only',

      onCompleted: data => {
        if (data?.ticketsFileUrl) {
          console.log('thanks  window.open');
          setLinkToPDFFile(data?.ticketsFileUrl);
          window.open(data?.ticketsFileUrl, '_blank');
        }
      },
    }
  );

  switch (paymentType) {
    case 'success':
      return (
        <ThanksLayoutSuccess
          instantSuccess
          linkToPDFFile={linkToPDFFile}
          type={payment.type}
          fileLoading={fileLoading}
          getTicketsFileUrl={getTicketsFileUrl}
        />
      );
    case 'zeroPayment':
      return (
        <ZeroPayment
          fileLoading={fileLoading}
          linkToPDFFile={linkToPDFFile}
          getTicketsFileUrl={getTicketsFileUrl}
          urlParams={urlParams}
        />
      );
    case 'banks':
      return (
        <Banks
          fileLoading={fileLoading}
          linkToPDFFile={linkToPDFFile}
          getTicketsFileUrl={getTicketsFileUrl}
          urlParams={urlParams}
        />
      );
    case 'bankLink':
      return (
        <BankLink
          fileLoading={fileLoading}
          linkToPDFFile={linkToPDFFile}
          getTicketsFileUrl={getTicketsFileUrl}
          urlParams={urlParams}
        />
      );
    case 'adyen':
      return (
        <Adyen
          fileLoading={fileLoading}
          linkToPDFFile={linkToPDFFile}
          getTicketsFileUrl={getTicketsFileUrl}
          urlParams={urlParams}
        />
      );
    case 'yookassa':
      return (
        <Yookassa
          fileLoading={fileLoading}
          linkToPDFFile={linkToPDFFile}
          getTicketsFileUrl={getTicketsFileUrl}
          urlParams={urlParams}
        />
      );
    case 'corporate-client':
      return (
        <CorporateClient
          fileLoading={fileLoading}
          linkToPDFFile={linkToPDFFile}
          getTicketsFileUrl={getTicketsFileUrl}
          urlParams={urlParams}
        />
      );
    default:
      return <NotFound />;
  }
};

export default withRouter(withTranslation()(Thanks));
