import React, { useEffect, useContext } from 'react';
import { StoreContext } from '@lux/spa/store';
import { createThanksSuccessAnaliticsData } from 'utils';
import ThanksLayoutSuccess from '../../thanks-layout-success.jsx';

const CorporateClient = ({ fileLoading, getTicketsFileUrl, linkToPDFFile }) => {
  const [state, dispatch] = useContext(StoreContext);

  const {
    from,
    to,
    returnDate,
    departDate,
    promocode,
    totalPrice,
    forAnalitics,
    GTM,
  } = state.tickets;
  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';
  const { GTMLoaded } = state.app;

  useEffect(() => {
    getTicketsFileUrl();
  }, []);

  useEffect(() => {
    if (GTMLoaded && !GTM.thanksStep) {
      dataLayer.push({
        ...createThanksSuccessAnaliticsData({
          returnDate,
          departDate,
          from,
          to,
          currencyName,
          totalPrice,
          promocode,
          objects: forAnalitics.objects,
        }),
      });

      dispatch({
        type: 'UPDATE_TICKETS',
        payload: {
          GTM: {
            ...GTM,
            thanksStep: true,
          },
        },
      });
    }
  }, [GTMLoaded]);

  return (
    <ThanksLayoutSuccess
      type="corporate"
      fileLoading={fileLoading}
      getTicketsFileUrl={getTicketsFileUrl}
      linkToPDFFile={linkToPDFFile}
    />
  );
};

export default CorporateClient;
