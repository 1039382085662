import gql from 'graphql-tag';

export const GET_FAQ_SECTIONS = gql`
  query(
    $lang: String
    $categoryId: BigInt
    $sortBy: String
    $sortOrder: String
  ) {
    sections(
      lang: $lang
      categoryId: $categoryId
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      sections {
        id
        name
        description
        locale
        categoryId
        sourceLocale
        url
        htmlUrl
        outdated
        parentSectionId
        position
        createdAt
        updatedAt
      }
      count
      nextPage
      page
      pageCount
      perPage
      previousPage
      sortBy
      sortOrder
    }
  }
`;

export const GET_FAQ_CATEGORIES = gql`
  query($lang: String, $sortBy: String, $sortOrder: String) {
    categories(lang: $lang, sortBy: $sortBy, sortOrder: $sortOrder) {
      categories {
        id
        name
        description
        locale
        sourceLocale
        url
        htmlUrl
        outdated
        position
        createdAt
        updatedAt
      }
      count
      nextPage
      page
      pageCount
      perPage
      previousPage
      sortBy
      sortOrder
    }
  }
`;

export const GET_FAQ_ARTICLES = gql`
  query(
    $lang: String
    $categoryId: BigInt
    $sectionId: BigInt
    $sortBy: String
    $sortOrder: String
  ) {
    articles(
      lang: $lang
      categoryId: $categoryId
      sectionId: $sectionId
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      articles {
        id
        url
        htmlUrl
        title
        body
        locale
        sourceLocale
        authorId
        commentsDisabled
        outdatedLocales
        outdated
        labelNames
        draft
        promoted
        position
        voteSum
        voteCount
        sectionId
        userSegmentId
        permissionGroupId
        createdAt
        editedAt
        updatedAt
      }
      count
      nextPage
      page
      pageCount
      perPage
      previousPage
      sortBy
      sortOrder
    }
  }
`;

export const GET_FAQ_ARTICLE = gql`
  query($lang: String, $id: BigInt!) {
    article(lang: $lang, id: $id) {
      id
      url
      htmlUrl
      title
      body
      locale
      sourceLocale
      authorId
      commentsDisabled
      outdatedLocales
      outdated
      labelNames
      draft
      promoted
      position
      voteSum
      voteCount
      sectionId
      userSegmentId
      permissionGroupId
      createdAt
      editedAt
      updatedAt
    }
  }
`;

export const FAQ_SEARCH = gql`
  query($searchString: String!) {
    zendeskSearch(searchString: $searchString) {
      results {
        id
        title
        section {
          id
          name
        }
      }
      count
      nextPage
      page
      pageCount
      perPage
      previousPage
    }
  }
`;
