import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, et, fi, lt, lv, ru } from '@lux/spa/translates';

const resources = {
  en: {
    translations: en,
  },
  // et: {
  //   translations: et,
  // },
  // fi: {
  //   translations: fi
  // },
  // lt: {
  //   translations: lt,
  // },
  // lv: {
  //   translations: lv,
  // },
  ru: {
    translations: ru,
  },
};

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources,

    fallbackLng: 'ru',
    // debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },

    react: {
      wait: true,
    },
  });

export const langs = Object.keys(resources);
export default i18n;
