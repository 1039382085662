import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import i from '@lux/spa/styles/icons.module.scss';
import s from './controls.module.scss';

const Controls = ({ t, handlePrev, handleNext, className, current, count }) => {
  const isDisabledPrev = current == 1;
  const isDisabledNext = current == count;
  return (
    <div className={cn(s.controls, className)}>
      <div
        onClick={isDisabledPrev ? () => {} : handlePrev}
        className={cn(s.button, s.prev)}
        disabled={isDisabledPrev}
      >
        <i className={i.arrowLeft} />
        {t('prev')}
      </div>
      <div className={s.count}>
        {current} / {count}
      </div>
      <div
        onClick={isDisabledNext ? () => {} : handleNext}
        className={cn(s.button, s.next)}
        disabled={isDisabledNext}
      >
        {t('next')}
        <i className={i.arrowRight} />
      </div>
    </div>
  );
};

export default withTranslation()(Controls);
