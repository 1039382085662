import React, { useState, cloneElement, useReducer } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Button, Modal, ModalHeader, ModalContent, DragAndDrop } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import EditSliderItem from './edit-slider-item';
import s from './edit-slider.module.scss';

const sliderReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_SLIDE':
      return {
        ...state,
        slides: [
          ...state.slides,
          {
            ...action.payload.slide,
            order: state.slides.length + 1,
          },
        ],
        newSlide: {
          ...state.newSlide,
          order: state.slides.length + 2,
        },
      };
    case 'UPDATE_SLIDE':
      return {
        ...state,
        slides: state.slides.map(slide =>
          slide.order == action.payload.slide.order
            ? {
                ...slide,
                ...action.payload.slide,
              }
            : {
                ...slide,
              }
        ),
        newSlide: {
          ...state.newSlide,
          order: state.slides.length + 1,
        },
      };
    case 'REMOVE_SLIDE':
      return {
        ...state,
        slides: state.slides
          .filter(slide => slide.order !== action.payload.slide.order)
          .map((slide, key) => ({
            ...slide,
            order: key + 1,
          })),
        newSlide: {
          ...state.newSlide,
          order: state.slides.length,
        },
      };
    case 'UPDATE_SLIDES':
      return {
        ...state,
        slides: action.payload.slides,
      };
    default:
      throw new Error();
  }
};

const EditSlider = ({
  t,
  isOpen,
  toggleModal,
  children,
  slider = {},
  updatePage,
  showField,
  emptySlide,
  className,
  contentClassName,
  ...props
}) => {
  const initialSlider = {
    newSlide: {
      ...emptySlide,
      order: slider.slides?.length + 1,
    },
    slides: slider.slides,
  };

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, sliderDispatch] = useReducer(sliderReducer, initialSlider);

  const { newSlide, slides } = state;

  const [slide, setSlide] = useState(newSlide);
  const [isEditSlide, setIsEditSlide] = useState(false);

  const updateSlides = slides => {
    sliderDispatch({
      type: 'UPDATE_SLIDES',
      payload: {
        slides,
      },
    });
  };

  const toggleEditSlide = slide => {
    setIsEditSlide(!isEditSlide);
    setSlide(slide);
  };

  const handleSaveSlider = () => {
    updatePage({
      [slider.fieldName]: {
        ...slider,
        slides,
      },
    });
    toggleModal();
  };

  const removeSlide = slide => {
    sliderDispatch({
      type: 'REMOVE_SLIDE',
      payload: {
        slide,
      },
    });
  };

  return (
    <Modal className={s.modal} {...{ isOpen, toggleModal }}>
      <ModalHeader text="Edit Slider" />
      <ModalContent className={cn(s.content, contentClassName)}>
        {isEditSlide ? (
          cloneElement(children, {
            slide: {
              ...emptySlide,
              ...slide,
            },
            slides,
            sliderDispatch,
            toggleEditSlide,
            ...props,
          })
        ) : (
          <div className={s.editSlider}>
            <div className={cn(s.slides, !slides.length && s.empty)}>
              {slides.length ? (
                <DragAndDrop
                  updateItems={updateSlides}
                  itemProps={{
                    showField,
                    toggleEditSlide,
                    removeSlide,
                  }}
                  ItemLayout={EditSliderItem}
                  items={slides}
                />
              ) : (
                <div className={s.emptyText}>{t('slides')}</div>
              )}
            </div>
            <div className={s.controls}>
              <Button
                text={`+ ${t('add slide')}`}
                className={s.addSlide}
                onClick={() => toggleEditSlide(newSlide)}
              />
              <Button text="Save" onClick={handleSaveSlider} />
              <span onClick={toggleModal}>Cancel</span>
            </div>
          </div>
        )}
      </ModalContent>
    </Modal>
  );
};

export default withTranslation()(EditSlider);
