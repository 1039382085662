import React from 'react';
import cn from 'classnames';

import s from './tags.module.scss';

const Tags = ({ className, children }) => {
  return <div className={cn(s.tags, className)}>{children}</div>;
};

export default Tags;
