/* eslint-disable */
import React, { useRef, useEffect, useState, useContext, memo } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Collapse } from 'react-collapse';
import { useLazyQuery } from '@apollo/react-hooks';
import QRCode from 'qrcode.react';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { StoreContext } from '@lux/spa/store';
import { Loading, LazyLoadImage, Link } from '@lux/ui';
import { localizedDate } from 'utils';
import { Stops } from '@lux/spa/components';
import { organizeStops } from 'utils';
import { JOURNEY_ROUTE } from '@lux/spa/apollo/queries';
import s from './touched-ticket.module.scss';
import i from '@lux/spa/styles/icons.module.scss';

const TouchedTicket = ({
  t,
  i18n,
  isCanceled = false,
  ticketLegs = [],
  className,
  availableBusinessClassSeats,
  campaignPrice,
  passenger = {},
  leg,
  ticket,
  seat,
  isPast,
}) => {
  const { language } = i18n;
  const { isMob } = useContext(WidthContext);
  const [state, dispatch] = useContext(StoreContext);
  const [showDetails, setShowDetails] = useState(false);

  const buttonGroupRef = useRef(null);

  const showTime = time => {
    return time
      .split('T')[1]
      .split(':')
      .slice(0, 2)
      .join(':');
  };

  const logoType = availableBusinessClassSeats ? 'logo-lounge' : 'logo-grey';

  // const seatNumber = ticketLegs[0].SeatInfo.SeatNumber;
  const { SeatNumber } = seat;
  const { PassengerName } = passenger;
  const { QrCode, CouponNumber } = ticket;
  const {
    DepartureStopName,
    DestinationStopName,
    DepartureTime,
    ArrivalTime,
    // Duration,
    // File,
  } = leg;

  // const durationDays = Duration.includes('.') && Duration.split('.')[0];

  // const duration = Duration.replace(`${durationDays}.`, '')
  //   .split(':')
  //   .slice(0, 2);

  let type = availableBusinessClassSeats ? 'lounge' : '';
  type = campaignPrice ? 'promo' : type;

  if (!campaignPrice) {
    type = availableBusinessClassSeats ? 'lounge' : '';
  }

  // const stops = organizeStops(ticketLegs, showTime) || [];

  // const [getJorney, { loading, data, called, refetch }] = useLazyQuery(
  //   JOURNEY_ROUTE,
  //   {
  //     fetchPolicy: 'network-only',
  //     variables: {
  //       journeyId,
  //       language,
  //       includeIntermediateStops: true,
  //     },
  //     onCompleted: data => {
  //       console.log('data', data);

  //       if (stops.length) {
  //         const legs = data?.journeyRoute?.Legs || [];
  //         const stops = organizeStops(legs, showTime);
  //       }
  //     },
  //   }
  // );

  // const availableEquipment = ticketLegs[0].BusEquipment;

  // const equipmentTypes = {
  //   'BUS_PROFILE_PROPERTY.COFFEE_MACHINE': '/images/svg/hot-drinks.svg',
  //   'BUS_PROFILE_PROPERTY.WC': '/images/svg/wc.svg',
  //   'BUS_PROFILE_PROPERTY.MULTIMEDIA_DISPLAY': '/images/svg/multimedia.svg',
  //   'BUS_PROFILE_PROPERTY.INTERNET': '/images/svg/wi-fi.svg',
  //   'BUS_PROFILE_PROPERTY.A_C': '/images/svg/climate-control.svg',
  //   'BUS_PROFILE_PROPERTY.220V': '/images/svg/220v-outlet.svg',
  // };

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const classes = cn(s.touchedTicket, type && s[type], className);

  // useEffect(() => {
  //   if (showDetails) {
  //     getJorney();
  //   }
  // }, [showDetails]);

  useEffect(() => {
    const button = buttonGroupRef?.current;
    if (button) {
      button.addEventListener('mousemove', e => {
        button.style.setProperty(
          'background-position',
          `${e.clientX - 60}px ${e.clientY - 60}px`
        );
      });

      return () => {
        button.removeEventListener('mousemove', e => {
          button.style.setProperty(
            'background-position',
            `${e.clientX - 60}px ${e.clientY - 60}px`
          );
        });
      };
    }
  }, []);

  // const departureDate = localizedDate(
  //   new Date(DepartureTime.split('T')[0]),
  //   'dd MMM, yyyy'
  // );
  const showDate = date =>
    localizedDate(new Date(date.split('T')[0]), 'EEE, dd/MM');

  return (
    <div className={classes}>
      {type && (
        <div className={s.label}>
          {type == 'promo' ? t('promo ticket') : t('lounge bus')}
        </div>
      )}
      {!isMob && (
        <>
          <div className={cn(s.decorativeСircle, s.top)}>
            <div className={s.circle} />
          </div>
          <div className={cn(s.decorativeСircle, s.bottom)}>
            <div className={s.circle} />
          </div>
        </>
      )}
      <div className={s.ticketContent}>
        <div className={s.main}>
          {isMob && (
            <>
              <div className={cn(s.decorativeСircle, s.top)}>
                <div className={s.circle} />
              </div>
              <div className={cn(s.decorativeСircle, s.bottom)}>
                <div className={s.circle} />
              </div>
            </>
          )}
          <div className={s.timing}>
            <div className={cn(s.stop, s.from)}>
              <div className={s.time}>{showTime(DepartureTime)}</div>
            </div>
            <div className={s.divider}>
              <div className={cn(s.circle, s.black)} />
              <div className={s.line} />
              <div className={s.circle} />
              <div className={s.line} />
            </div>
            <div className={s.logo}>
              <LazyLoadImage src={`/images/svg/${logoType}.svg`} alt="" />
            </div>
            <div className={cn(s.divider, s.reverse)}>
              <div className={cn(s.circle, s.black)} />
              <div className={s.line} />
              <div className={s.circle} />
              <div className={s.line} />
            </div>
            <div className={cn(s.stop, s.to)}>
              <div className={s.time}>{showTime(ArrivalTime)}</div>
            </div>
          </div>
          <div className={s.direction}>
            <div className={cn(s.stop, s.from)}>
              <div className={s.name}>{DepartureStopName}</div>
              <div className={s.date}>{showDate(DepartureTime)}</div>
            </div>
            {/* <div className={s.duration}>
              DURATION */}
            {/* {durationDays && `${durationDays}D `}
              {`${duration[0]}H ${duration[1]}M`} */}
            {/* </div> */}
            <div className={cn(s.stop, s.to)}>
              <div className={s.name}>{DestinationStopName}</div>
              <div className={s.date}>{showDate(ArrivalTime)}</div>
            </div>
          </div>
          <div className={s.info}>
            <div className={s.seatNumber}>
              <i className={i.seat} />
              {t('seat')} {SeatNumber}
            </div>
            {/* <div className={s.departureDate}>{departureDate}</div> */}
            <div
              onClick={toggleDetails}
              className={cn(s.showDetails, showDetails && s.show)}
            >
              {showDetails ? (
                <span>{t('hide details')}</span>
              ) : (
                <span>{t('details')}</span>
              )}
              <LazyLoadImage src="/images/svg/arrowBottom.svg" alt="" />
            </div>
          </div>
          {false && (
            <div className={s.notification}>
              <i className={i.warning} />
              Bus stop “Katariina”, “Vana-Pääsküla”, “Katariina”,
              “Vana-Pääsküla” is temporarily closed due to road works.
              Apologies!
            </div>
          )}
        </div>
        {isMob && (
          <Collapse isOpened={showDetails}>
            <div className={s.details}>
              {/* <h5>{t('stops')}</h5> */}
              {/* <Stops stops={stops} className={s.stops} /> */}
              <div className={s.features}>
                <div className={s.block}>
                  <h5>{t('rules')}</h5>
                  <p>{t('Sliding_rules')}</p>
                </div>
                {/* <div className={s.block}>
                  <h5>{t('bus information').capitalize()}</h5>
                  <div className={s.busInformation}>
                    {availableEquipment.map((item, key) => (
                      <div
                        key={key}
                        className={s.img}
                        data-title-center
                        data-title={item.DisplayName}
                      >
                        <LazyLoadImage src={equipmentTypes[item.Code]} alt="" />
                      </div>
                    ))}
                  </div>
                </div> */}
              </div>
            </div>
          </Collapse>
        )}
        <div className={s.controls}>
          <div className={s.divider} />
          {isMob && <QRCode value={QrCode} size={168} />}

          {!!PassengerName && (
            <div className={s.userName}>
              <div className={s.text}>{`${t('passenger')}:`}</div>
              <span>{PassengerName}</span>
            </div>
          )}

          {/* {!isCanceled && File && (
            <Link
              ref={buttonGroupRef}
              className={s.buttonGroup}
              to={File}
              target="__blank"
            >
              {t('print ticket')}
            </Link>
          )} */}

          {isCanceled && (
            <div className={s.buttonGroup} disabled>
              {t('canceled')}
            </div>
          )}

          <div
            className={s.ticketNumber}
            data-title={t('ticket number')}
            data-title-center
          >
            <span>{t('ticket.number.short')}:</span>
            {QrCode}
          </div>
        </div>
      </div>
      {!isMob && (
        <Collapse isOpened={showDetails}>
          <div className={s.details}>
            {/* <h5>{t('stops')}</h5> */}
            {/* <Stops stops={stops} className={s.stops} /> */}
            <div className={s.features}>
              <div className={s.block}>
                <h5>{t('rules')}</h5>
                <p>{t('Sliding_rules')}</p>
              </div>
              {/* <div className={s.block}>
                <h5>{t('bus information').capitalize()}</h5>
                <div className={s.busInformation}>
                  {availableEquipment.map((item, key) => (
                    <div
                      key={key}
                      className={s.img}
                      data-title-center
                      data-title={item.DisplayName}
                    >
                      <LazyLoadImage src={equipmentTypes[item.Code]} alt="" />
                    </div>
                  ))}
                </div>
              </div> */}
            </div>
          </div>
        </Collapse>
      )}
    </div>
  );
};

export default withRouter(withTranslation()(memo(TouchedTicket)));
