import React, { useState } from 'react';
import cn from 'classnames';
import * as uuid from 'uuid';
import { Tooltip } from '@lux/ui';
import s from './hidden-input.module.scss';

const HiddenInput = ({
  tooltipText = '',
  required = false,
  isError,
  errors,
  label,
  className,
  withoutMessage = false,
  errorWithoutMessage = false,
  ...props
}) => {
  const id = uuid.v4();
  const [type, setType] = useState('password');

  const toggleCode = () => {
    setType(type === 'text' ? 'password' : 'text');
  };

  const classes = cn(
    s.hiddenInput,
    type === 'text' && s.visible,
    (errors || errorWithoutMessage) && s.error,
    withoutMessage && s.withoutMessage,
    className
  );

  return (
    <div className={classes}>
      {label && (
        <label htmlFor={id}>
          {`${label}${required ? ' *' : ''}`}
          {/* <Tooltip centred={false} className={s.tooltip}>
            {tooltipText}
          </Tooltip> */}
        </label>
      )}
      <div className={s.input}>
        <input id={id} type={type} {...props} />
        <div className={s.eye} onClick={toggleCode}>
          <img src="/images/svg/eye-slash.svg" alt="" />
        </div>
      </div>
      {errors && <div className={s.message}>{errors}</div>}
    </div>
  );
};

export default HiddenInput;
