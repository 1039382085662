import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Link, Button, Divider } from '@lux/ui';
import s from './cookie-banner.module.scss';

const CookieBanner = ({ t, handleAllowCookie }) => (
  <div className={s.cookieBanner}>
    <Container className={s.container}>
      <Container full={false}>
        <section>
          <p>
            {t('cookie.banner.text')}
            <Link className={s.link} to="/privacy-policy/">
              {t('cookies policy')}
            </Link>
          </p>
          <Button text={t('allow')} onClick={handleAllowCookie} />
          <Divider absolute />
        </section>
      </Container>
    </Container>
  </div>
);

export default withTranslation()(CookieBanner);
