export const emptyData = {
  lang: 'en',
  isPublished: true,
  countries: ['ee', 'ru', 'fi', 'lv', 'lt'],
  seo: {
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 80,
    },
    slug: {
      value: 'about-us',
      fieldName: 'slug',
      placeholder: 'Slug / URL',
      maxLength: 120,
    },
    thumbnail: {
      value: '',
      fieldName: 'thumbnail',
      placeholder: 'Thumbnail',
      size: {
        height: 250,
        width: 250,
      },
    },
    description: {
      value: '',
      fieldName: 'description',
      placeholder: 'Page description',
      maxLength: 158,
    },
    socialTitle: {
      value: '',
      fieldName: 'socialTitle',
      placeholder: 'Social Title',
      maxLength: 160,
    },
    socialDescription: {
      value: '',
      fieldName: 'socialDescription',
      placeholder: 'Social description',
      maxLength: 160,
    },
    pageKeywords: {
      value: '',
      fieldName: 'pageKeywords',
      placeholder: 'Page keywords',
      maxLength: 40,
    },
    socialImage: '',
    articlePublishedTime: '',
    articleModifiedTime: '',
  },
  pageData: {
    banner: {
      value: '',
      fieldName: 'banner',
      placeholder: 'Banner',
      size: {
        height: 1100,
        width: 2880,
      },
    },
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 50,
    },
    subTitle: {
      value: '',
      fieldName: 'subTitle',
      placeholder: 'Page subtitle',
      maxLength: 200,
    },
    heading1: {
      value: '',
      fieldName: 'heading1',
      placeholder: 'Heading',
      maxLength: 100,
    },
    heading2: {
      value: '',
      fieldName: 'heading2',
      placeholder: 'Heading',
      maxLength: 100,
    },
    title1: {
      value: '',
      fieldName: 'title1',
      placeholder: 'Heading',
      maxLength: 100,
    },
    title2: {
      value: '',
      fieldName: 'title2',
      placeholder: 'Heading',
      maxLength: 100,
    },
    title3: {
      value: '',
      fieldName: 'title3',
      placeholder: 'Heading',
      maxLength: 100,
    },
    title4: {
      value: '',
      fieldName: 'title4',
      placeholder: 'Heading',
      maxLength: 100,
    },
    title5: {
      value: '',
      fieldName: 'title5',
      placeholder: 'Heading',
      maxLength: 100,
    },
    title6: {
      value: '',
      fieldName: 'title6',
      placeholder: 'Heading',
      maxLength: 100,
    },
    text1: {
      value: '',
      fieldName: 'text1',
      placeholder: 'Text',
      maxLength: 300,
    },
    text2: {
      value: '',
      fieldName: 'text2',
      placeholder: 'Text',
      maxLength: 300,
    },
    text3: {
      value: '',
      fieldName: 'text3',
      placeholder: 'Text',
      maxLength: 300,
    },
    text4: {
      value: '',
      fieldName: 'text4',
      placeholder: 'Text',
      maxLength: 300,
    },
    text5: {
      value: '',
      fieldName: 'text5',
      placeholder: 'Text',
      maxLength: 300,
    },
    text6: {
      value: '',
      fieldName: 'text6',
      placeholder: 'Text',
      maxLength: 300,
    },
    description: '',
    description_1: '',
    quoteText: {
      value: '',
      fieldName: 'quoteText',
      placeholder: 'Quote Text',
      maxLength: 300,
    },
    quoteAuthor: {
      value: '',
      fieldName: 'quoteAuthor',
      placeholder: 'Quote author',
      maxLength: 300,
    },
    slider: {
      slides: [],
      fieldName: 'slider',
    },
  },
};

export const defaultData = {
  lang: 'en',
  isPublished: true,
  countries: ['ee', 'ru', 'fi', 'lv', 'lt'],
  seo: {
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 80,
    },
    slug: {
      value: 'about-us',
      fieldName: 'slug',
      placeholder: 'Slug / URL',
      maxLength: 120,
    },
    description: {
      value: 'Page description',
      fieldName: 'description',
      placeholder: 'Page description',
      maxLength: 158,
    },
    socialTitle: {
      value: 'Social Title',
      fieldName: 'socialTitle',
      placeholder: 'Social Title',
      maxLength: 160,
    },
    socialDescription: {
      value: 'Social description',
      fieldName: 'socialDescription',
      placeholder: 'Social description',
      maxLength: 160,
    },
    pageKeywords: {
      value: 'Page keywords',
      fieldName: 'pageKeywords',
      placeholder: 'Page keywords',
      maxLength: 40,
    },
    thumbnail: {
      value: '',
      fieldName: 'thumbnail',
      placeholder: 'Thumbnail',
      size: {
        height: 250,
        width: 250,
      },
    },
    socialImage: '',
  },
  pageData: {
    banner: {
      value: '',
      fieldName: 'banner',
      placeholder: 'Banner',
      size: {
        height: 1100,
        width: 2880,
      },
    },
    title: {
      value: 'News title',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 100,
    },
    subTitle: {
      value: 'is a travel service company',
      fieldName: 'subTitle',
      placeholder: 'Page subtitle',
      maxLength: 200,
    },
    heading1: {
      value: 'Heading',
      fieldName: 'heading1',
      placeholder: 'Heading',
      maxLength: 100,
    },
    heading2: {
      value: 'Facts about LUX Express',
      fieldName: 'heading2',
      placeholder: 'Heading',
      maxLength: 100,
    },
    title1: {
      value: '91 buses',
      fieldName: 'title1',
      placeholder: 'Heading',
      maxLength: 100,
    },
    title2: {
      value: '59,127 km/day',
      fieldName: 'title2',
      placeholder: 'Heading',
      maxLength: 100,
    },
    title3: {
      value: '21,5M km/year',
      fieldName: 'title3',
      placeholder: 'Heading',
      maxLength: 100,
    },
    title4: {
      value: '800,000 clicks',
      fieldName: 'title4',
      placeholder: 'Heading',
      maxLength: 100,
    },
    title5: {
      value: '20,000 GB',
      fieldName: 'title5',
      placeholder: 'Heading',
      maxLength: 100,
    },
    title6: {
      value: '4,792 screens',
      fieldName: 'title6',
      placeholder: 'Heading',
      maxLength: 100,
    },
    text1: {
      value:
        'Currently has 91 buses, the average age of the buses is 3.1 years, the youngest bus is 70 days and the oldest 2,565 days old',
      fieldName: 'text1',
      placeholder: 'Text',
      maxLength: 300,
    },
    text2: {
      value:
        'The bases pass 59,127 km per day (for comparison: the equator is 40,075km), thus, the buses make one and a half circles and in two days 3 circles around the world',
      fieldName: 'text2',
      placeholder: 'Text',
      maxLength: 300,
    },
    text3: {
      value:
        'The buses drive 21,5 million km per year (ca 1.8 million km in month), meaning it would take us one week to get to the Moon',
      fieldName: 'text3',
      placeholder: 'Text',
      maxLength: 300,
    },
    text4: {
      value: 'Approximately 800,000 clicks are made on Lux TV in 3 months',
      fieldName: 'text4',
      placeholder: 'Text',
      maxLength: 300,
    },
    text5: {
      value:
        'Nearly 16,000 - 20,000 GB (16-20 TB) of internet is used on the buses monthly',
      fieldName: 'text5',
      placeholder: 'Text',
      maxLength: 300,
    },
    text6: {
      value: 'Buses have a 4,792 Lux TV screens',
      fieldName: 'text6',
      placeholder: 'Text',
      maxLength: 300,
    },
    description: '',
    description_1: '',
    quoteText: {
      value: '',
      fieldName: 'quoteText',
      placeholder: 'Quote text',
      maxLength: 300,
    },
    quoteAuthor: {
      value: '',
      fieldName: 'quoteAuthor',
      placeholder: 'Quote author',
      maxLength: 300,
    },
    slider: {
      slides: [],
      fieldName: 'slider',
    },
  },
};
