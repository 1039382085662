import React, { useContext } from 'react';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { Tooltip } from '@lux/ui';
import styles from './Stops.module.scss';

const Stop = ({
  t,
  time = '',
  city = '',
  country = '',
  text = '',
  address = '',
  className,
  empty = false,
  transfer = false,
}) => {
  return (
    <div
      className={cx(
        styles.stop,
        Boolean(text.length) && styles.transfer,
        className
      )}
    >
      <div className={styles.time}>
        {!empty && <div className={styles.circle} />}
        {time}
      </div>
      <div className={styles.destination}>
        <div className={styles.address}>
          {!empty && <span>{address}</span>}
          {transfer && (
            <div className={styles.transferText}>
              <div>{t('transfer')}</div>
              {Boolean(text.length) && (
                <Tooltip className={styles.tooltip}>{text}</Tooltip>
              )}
            </div>
          )}
        </div>
        <div className={styles.cityCountry}>
          {city}, {country}
        </div>
      </div>
    </div>
  );
};

const Stops = ({ t, stops = [], likeMobile = false, className }) => {
  const { isMob } = useContext(WidthContext);
  const isTwoColumns = !likeMobile && stops.length > 5 && !isMob;
  const inFirstColumn = Math.ceil(stops.length / 2);

  return (
    <div
      className={cx(
        styles.stops,
        isTwoColumns ? styles.twoColumns : styles.oneColumn,
        likeMobile && styles.likeMobile,
        className
      )}
    >
      {isTwoColumns && (
        <>
          <div className={styles.col}>
            {stops.slice(0, inFirstColumn).map(stop => (
              <Stop {...stop} t={t} key={stop?.city + stop?.country} />
            ))}
          </div>
          <div className={styles.col}>
            {stops.length % 2 !== 0 && <Stop empty t={t} />}
            {stops.slice(inFirstColumn).map(stop => (
              <Stop {...stop} t={t} key={stop?.city + stop?.country} />
            ))}
          </div>
        </>
      )}
      {!isTwoColumns &&
        stops.map(stop => (
          <Stop {...stop} t={t} key={stop?.city + stop?.country} />
        ))}
    </div>
  );
};

export default withTranslation()(Stops);
