import React, { Children, cloneElement } from 'react';

import cn from 'classnames';
import s from './tabs.module.scss';

const TabContainer = ({ children, active, className }) => {
  const childrens = Children.toArray(children);
  return (
    <div className={cn(s.container, className)}>
      {/* {childrens.filter((contentActiveTab, index) => (
        active == index ? contentActiveTab : null
      ))} */}

      {childrens.map((child, index) =>
        cloneElement(child, {
          active: active == index,
        })
      )}

      {/* {childrens.filter((СontentActiveTab, index) => (
        <СontentActiveTab key={index} className={active == index ? s.show : ''} />
      ))} */}
    </div>
  );
};

export default TabContainer;
