import React, { useState, useEffect, useContext } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { Route, Redirect, Switch } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { Formik } from 'formik';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Query } from 'react-apollo';
import * as Yup from 'yup';
import dateFormat from 'date-fns/format';
import { DEFAULT_LANG, validToUseExponea } from '@lux/spa/config';
import { StoreContext } from '@lux/spa/store';
import {
  ADYEN_PAYMENT,
  ADYEN_PAYMENT_DETAILS,
  ZERO_PAYMENT,
} from '@lux/spa/apollo/mutations';
import { ADYEN_PAYMWNTS_METHODS, NEO_PAY_BANKS } from '@lux/spa/apollo/queries';
import {
  Container,
  TicketsInput,
  Button,
  Link,
  CardInput,
  HiddenInput,
  Select,
  SelectOption,
  Notice,
  Loading,
  DiscardedBasket,
} from '@lux/ui';
import { TripDetails } from '@lux/spa/components';
import i from '@lux/spa/styles/icons.module.scss';
import {
  AdyenPayment,
  Banks,
  CorporateClient,
  SavedCards,
  YookassaPayment,
} from './components';
import s from './payment.module.scss';

const phoneNumberMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const Payment = ({ t, i18n, className, match, location, history }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { isTabDown } = useContext(WidthContext);
  const { language } = i18n;
  const [loaded, setLoaded] = useState(false);
  const {
    coupons = [],
    products = {},
    lastSearch,
    totalPrice,
    hasTransfers,
    from,
    to,
    promocode,
    returnDate,
    departDate,
    GTM,
    isPassengersInfoConfirmed,
  } = state.tickets;

  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';

  if (!isPassengersInfoConfirmed) {
    history.push(
      `${
        DEFAULT_LANG === language ? '' : `/${language}`
      }/tickets/passenger-details/`
    );

    return null;
  }

  const { GTMLoaded } = state.app;
  const { ShoppingBasketId = '' } = products;
  const [adyenPayment, { loading: adyenLoading }] = useMutation(ADYEN_PAYMENT);
  const [adyenPaymentDetails] = useMutation(ADYEN_PAYMENT_DETAILS);
  const [zeroPayment] = useMutation(ZERO_PAYMENT, {
    variables: {
      Currency: 'CURRENCY.RUB',
    },
    onError: () => {
      dispatch({
        type: 'UPDATE_TICKETS',
        payload: {
          isSomethingWrong: true,
        },
      });
    },
    onCompleted: data => {
      console.log('zeroPayment called');
      if (data.zeroPaymentPay.error) {
        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            isSomethingWrong: true,
          },
        });
      }
      if (data.zeroPaymentPay.success) {
        if (validToUseExponea()) {
          exponea.track('payment_selected', {
            timestamp: Date.now(),
            payment_method: 'zero_payment',
            total_price: totalPrice,
            currency: 'RUB',
            Origin_stop_ID: from.BusStopId,
            Destination_stop_ID: to.BusStopId,
          });
        }

        if (data?.zeroPaymentPay?.objects?.[0]) {
          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              forAnalitics: {
                objects: data?.zeroPaymentPay?.objects,
              },
            },
          });
        }

        history.push(
          language === DEFAULT_LANG
            ? `/tickets/thanks/?type=zeroPayment`
            : `/${language}/tickets/thanks/?type=zeroPayment`
        );
      }
    },
  });
  const { data } = useQuery(ADYEN_PAYMWNTS_METHODS);
  const paymentMethods = data?.adyenPaymentMethods;

  const [isOpenModal, setIsOpenModal] = useState(false);

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const tabsLink = [];

  const showNeoPay = currencyName.toLowerCase() === 'eur';

  if (showNeoPay) {
    tabsLink.push({
      link: '/banks/',
      text: t('banks'),
    });
  }

  tabsLink.push(
    ...[
      {
        link: '/credit-card/',
        text: t('credit card'),
      },
      {
        link: '/corporate-client/',
        text: t('corporate client'),
      },
    ]
  );

  useEffect(() => {
    if (!GTM.paymentStep) {
      if (GTMLoaded) {
        dataLayer.push({
          tripType: returnDate ? 'return-trip' : 'one-way',
          departureStopId: from?.BusStopId,
          destinationStopId: to?.BusStopId,
          currencyCode: currencyName,
          route: `${from?.CityName}-${to?.CityName}`,
          price: totalPrice,
          promocode: promocode || null,
          departureDestination: `${from?.CountryName}-${to?.CountryName}`,
          stage: 'payment',
          event: 'payment',
          departDate: dateFormat(new Date(departDate), 'yyyy-MM-dd'),
          ...(returnDate
            ? {
                returnDate: dateFormat(new Date(returnDate), 'yyyy-MM-dd'),
              }
            : {}),
        });

        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            GTM: {
              ...GTM,
              paymentStep: true,
            },
          },
        });
      }
    }
  }, [GTMLoaded]);

  useEffect(() => {
    localStorage.removeItem('params');
    localStorage.removeItem('details');
  }, []);

  return (
    <div className={cn(s.payment, className)}>
      <DiscardedBasket
        isOpen={isOpenModal}
        toggleModal={toggleModal}
        url={`/tickets/search${lastSearch}`}
      />
      <Container className={s.breadcrumbs}>
        <Container full={false}>
          <section>
            <div className={s.item} onClick={toggleModal}>
              {t('search')}
            </div>
            <Link to="/tickets/seat-details/" className={s.item}>
              <i className={i.arrowBottom} />
              1.&nbsp;<span>{t('seat selection')}</span>
            </Link>
            <Link to="/tickets/passenger-details/" className={s.item}>
              <i className={i.arrowBottom} />
              2.&nbsp;<span>{t('passangers details')}</span>
            </Link>
            {hasTransfers && (
              <Link to="/tickets/extras/" className={s.item}>
                <i className={i.arrowBottom} />
                3.&nbsp;<span>{t('extras')}</span>
              </Link>
            )}
            <div className={cn(s.item, s.active)}>
              <i className={i.arrowBottom} />
              {hasTransfers ? '4' : '3'}.&nbsp;
              <span>{t('payment details')}</span>
            </div>
          </section>
        </Container>
      </Container>
      {totalPrice === 0 ? (
        <Container className={s.paymentFlow}>
          <Container full={false}>
            <section>
              <div className={cn(s.zeroPayment)}>
                <div className={cn(s.zeroPaymentBlock)}>
                  <div className={s.header}>
                    <h3>{t('payment details')}</h3>
                  </div>
                  <div className={s.info}>
                    <div className={s.notice}>
                      <Notice
                        color="green"
                        text={t(
                          'you have a free ticket, to continue press “CONTINUE”'
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className={s.block}>
                  <Notice>
                    {`${t('by completing this booking I agree with')} `}
                    <Link className={s.link} to="/ticket-sales-rules/">
                      {t('Lux Express ticket selling terms')}
                    </Link>
                    .
                  </Notice>
                </div>
              </div>
              <div className={s.tripDetails}>
                <TripDetails
                  nextStep="thanks"
                  // onContinueClick={isPassengersInfoConfirmed ? null : handleSubmit}
                  onContinueClick={zeroPayment}
                  disabledNextStep={false}
                  // loading={loading}
                />
              </div>
            </section>
          </Container>
        </Container>
      ) : (
        <>
          <Route
            path={['*/tickets/payment', '*/tickets/payment/']}
            exact
            render={() => <Redirect to={`${match.path}/credit-card/`} />}
          />
          <Container className={s.paymentFlow}>
            <Container full={false}>
              <section>
                <div className={s.content}>
                  <div className={cn(s.tabs, s.activeOne)}>
                    <div className={s.tabPanel}>
                      <div className={s.line} />
                      <div className={cn(s.tab, s.active)}>
                        <span>{t('select payment method')}</span>
                      </div>
                      {/* {tabsLink.map(({ link, text }, key) => (
                        <Link
                          key={link}
                          to={`${match.path}${link}`}
                          onClick={() => setActiveTab(key + 1)}
                          className={cn(
                            s.tab,
                            activeTab === key + 1 && s.active
                          )}
                        >
                          <span>{t(text)}</span>
                        </Link>
                      ))} */}
                    </div>
                    <div className={s.tabsContainer}>
                      <Switch>
                        <div className={s.tabsContents}>
                          <div className={cn(s.tabsContent, s.creditCard)}>
                            <Route exact path={`${match.path}/credit-card/`}>
                              <YookassaPayment language={language} />
                            </Route>
                          </div>
                        </div>
                      </Switch>
                    </div>
                  </div>
                  <div className={s.block}>
                    <Notice>
                      {`${t('by completing this booking I agree with')} `}
                      <Link className={s.link} to="/ticket-sales-rules/">
                        {t('Lux Express ticket selling terms')}
                      </Link>
                      .
                    </Notice>
                  </div>
                </div>
                {isTabDown ? (
                  <TripDetails notRenderNextButton page="payment" />
                ) : (
                  <div className={s.tripDetails}>
                    <TripDetails notRenderNextButton page="payment" />
                  </div>
                )}
              </section>
            </Container>
          </Container>
        </>
      )}
    </div>
  );
};

export default withRouter(withTranslation()(Payment));
