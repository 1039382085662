import React, { useEffect, useState, useReducer } from 'react';
import cn from 'classnames';
import { Button, Input, Dropzone, Textarea } from '@lux/ui';
import { useMutation } from '@apollo/react-hooks';
import { UPLOAD_IMAGES } from '@lux/spa/apollo/mutations';
import s from './banner-item.module.scss';

const itemReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_ITEM':
      return {
        ...state,
        ...action.payload.field,
      };
    default:
      return state;
  }
};
const BannerItem = ({
  className,
  toggleEditItem,
  item = {},
  items,
  widgetDispatch,
  ...props
}) => {
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, itemDispatch] = useReducer(itemReducer, item);

  const [loadImages, setLoadImages] = useState([]);

  const {
    alt = {},
    img = {},
    imgVertical = {},
    title = {},
    subTitle = {},
    link = {},
    order,
    // isPublished,
  } = state;

  const isNew = order > items.length;
  const type = isNew ? 'ADD_ITEM' : 'UPDATE_ITEM';

  const updateItem = field => {
    itemDispatch({
      type: 'UPDATE_ITEM',
      payload: {
        field,
      },
    });
  };

  const [uploadImages, { data, loading, error }] = useMutation(UPLOAD_IMAGES);

  const removeImage = (e, isVertical) => {
    e?.stopPropagation();

    const newLoadImages = loadImages.filter(
      e => e !== (isVertical ? 'imgVertical' : 'img')
    );

    setLoadImages(newLoadImages);

    if (isVertical) {
      updateItem({
        [imgVertical.fieldName]: {
          ...imgVertical,
          value: '',
        },
      });
    } else {
      updateItem({
        [img.fieldName]: {
          ...img,
          value: '',
        },
      });
    }
  };

  const saveItem = () => {
    const isUpdateImg =
      loadImages.includes('img') && /^data:image/.test(img.value);
    const isUpdateImgVertical =
      loadImages.includes('imgVertical') &&
      /^data:image/.test(imgVertical.value);

    const input = [];

    if (isUpdateImg) {
      input.push({ file: img.value, name: img.name });
    }

    if (isUpdateImgVertical) {
      input.push({ file: imgVertical.value, name: imgVertical.name });
    }

    if (isUpdateImg || isUpdateImgVertical) {
      uploadImages({
        variables: {
          input,
        },
      });
    } else {
      widgetDispatch({
        type,
        payload: {
          item: state,
        },
      });
      toggleEditItem();
    }
  };

  useEffect(() => {
    if (data?.uploadImages?.success) {
      const images = {};
      if (loadImages.includes('img')) {
        images.img = {
          ...img,
          value: data?.uploadImages?.urls[0],
        };
      }

      if (loadImages.includes('imgVertical')) {
        images.imgVertical = {
          ...imgVertical,
          value: data?.uploadImages?.urls[loadImages.includes('img') ? 1 : 0],
        };
      }

      widgetDispatch({
        type,
        payload: {
          item: {
            ...state,
            ...images,
          },
        },
      });
      toggleEditItem();
    }
  }, [data?.uploadImages?.success]);

  return (
    <div className={cn(s.bannerItem, className)}>
      {/* <div className={s.switch}>
        <p>Published:</p>
        <Switch
          checked={isPublished === undefined || isPublished}
          onChange={() =>
            updateItem({
              isPublished: !isPublished,
            })
          }
        />
      </div> */}
      <Input
        className={s.input}
        label={title.placeholder}
        value={title.value}
        onChange={e =>
          updateItem({
            [title.fieldName]: {
              ...title,
              value: e.target.value,
            },
          })
        }
      />
      <Textarea
        className={s.input}
        label={subTitle.placeholder}
        value={subTitle.value}
        onChange={e =>
          updateItem({
            [subTitle.fieldName]: {
              ...subTitle,
              value: e.target.value,
            },
          })
        }
      >
        {subTitle.value}
      </Textarea>
      <Input
        className={s.input}
        label={alt.placeholder}
        value={alt.value}
        onChange={e =>
          updateItem({
            [alt.fieldName]: {
              ...alt,
              value: e.target.value,
            },
          })
        }
      />
      <Input
        className={s.input}
        label={link.placeholder}
        value={link.value}
        onChange={e =>
          updateItem({
            [link.fieldName]: {
              ...link,
              value: e.target.value,
            },
          })
        }
      />
      <Dropzone
        className={s.dropzone}
        onDrop={(file, height, width, base64, name) => {
          setLoadImages([...loadImages, 'img']);
          updateItem({
            [img.fieldName]: {
              ...img,
              name,
              value: base64,
            },
          });
        }}
        loading={loading}
        preview={img}
        removeImage={e => removeImage(e)}
      />
      <Dropzone
        className={s.dropzone}
        onDrop={(file, height, width, base64, name) => {
          setLoadImages([...loadImages, 'imgVertical']);
          updateItem({
            [imgVertical.fieldName]: {
              ...imgVertical,
              name,
              value: base64,
            },
          });
        }}
        loading={loading}
        preview={imgVertical}
        removeImage={e => removeImage(e, true)}
      />
      <div className={s.controls}>
        <Button text="Save" onClick={saveItem} disabled={loading} />
        <Button text="Cancel" onClick={toggleEditItem} disabled={loading} />
      </div>
    </div>
  );
};

export default BannerItem;
