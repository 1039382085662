import React from 'react';
import cn from 'classnames';

import s from './switch.module.scss';

const Switch = ({ className, checked, onChange, ...props }) => (
  <label className={cn(s.switch, className)} {...props}>
    <input type="checkbox" {...{ checked, onChange }} />
    <div className={s.checkmark} />
  </label>
);

export default Switch;
