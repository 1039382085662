import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Container, Link, Button, LazyLoadImage } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './footer.module.scss';

const Footer = ({ t, i18n }) => {
  const { language } = i18n;

  const setSubscribeLink = () => {
    switch (language) {
      case 'en':
        return 'http://eepurl.com/bTiv45';
      case 'et':
        return 'http://eepurl.com/bTvpCb';
      case 'lv':
        return 'http://eepurl.com/bTFRPL';
      case 'lt':
        return 'http://eepurl.com/bUcdiD';
      case 'ru':
        return 'http://eepurl.com/bTu3wP';
    }
  };

  const linkTripAdvisor =
    'https://www.tripadvisor.com/Attraction_Review-g274958-d8762954-Reviews-Lux_Express-Tallinn_Harju_County.html';
  return (
    <footer className={s.footer}>
      <Container>
        <Container full={false}>
          <section>
            <div className={s.col}>
              <div className={s.logo}>
                <LazyLoadImage src="/images/svg/logo-white.svg" alt="" />
              </div>
            </div>
            <div className={s.col}>
              <div className={s.row}>
                <div className={cn(s.info, s.routes)}>
                  <div className={s.title}>{t('information')}</div>
                  <div className={s.routes}>
                    <Link className={s.route} to="/faq/">
                      {t('guides and faq')}
                    </Link>
                    <Link className={s.route} to="/all-routes/">
                      {t('routes')}
                    </Link>
                    <Link className={s.route} to="/contacts/">
                      {t('contacts')}
                    </Link>
                    <Link className={s.route} to="/about-us/">
                      {t('about us')}
                    </Link>
                    {/* <Link className={s.route} to="/career/">{t('career')}</Link> */}
                    {/* <Link className={s.route} to="/ticket-refund/">{t('return tickets')}</Link> */}
                  </div>
                </div>
                <div className={cn(s.info, s.socials)}>
                  <div className={s.title}>{t('follow us')}</div>
                  <div className={s.socials}>
                    <Link to={t('vkontakte.url')} className={s.social}>
                      <i className={i.vk} />
                      <span>{t('vkontakte')}</span>
                    </Link>
                    <Link to={t('tripadviser.url')} className={s.social}>
                      <i className={i.tripadvisor} />
                      <span>{t('tripadvisor')}</span>
                    </Link>
                    <Link to={t('youtube.url')} className={s.social}>
                      <i className={i.youtube} />
                      <span>{t('youtube')}</span>
                    </Link>
                  </div>
                </div>
                <div className={cn(s.info, s.routes)}>
                  <div className={s.title}>{t('documents')}</div>
                  <div className={s.routes}>
                    <Link
                      to="/privacy-policy/"
                      className={s.route}
                      children={t('privacy policy')}
                    />
                    <Link
                      to="/ticket-sales-rules/"
                      className={s.route}
                      children={t('ticket sales rules')}
                    />
                    <Link
                      to="/customs-regulations/"
                      className={s.route}
                      children={t('customs regulations')}
                    />
                    <Link
                      to="/transportation-rules/"
                      className={s.route}
                      children={t('transportation rules')}
                    />
                  </div>
                </div>
                <div className={cn(s.info, s.routes)}>
                  <div className={s.title}>{t('Applications')}</div>
                  <div className={s.routes}>
                    <Link
                      to="https://apps.apple.com/ua/app/lux-express/id1516185295"
                      className={cn(s.route, s.app)}
                    >
                      <img src="/images/svg/AppStore.svg" alt="" />
                    </Link>
                    <Link
                      to="https://play.google.com/store/apps/details?id=com.luxexpress"
                      className={cn(s.route, s.app)}
                    >
                      <img src="/images/svg/GooglePlay.svg" alt="" />
                    </Link>
                  </div>
                </div>
                {/* <div className={cn(s.info, s.subscribe)}>
                  <div className={s.title}>{t('lux express newsletter')}</div>
                  <p>{t('footer.subscribe.info')}</p>
                  <Button text={t('subscribe')} to={setSubscribeLink()} />
                </div> */}
              </div>
              <div className={cn(s.row, s.last)}>
                <div className={s.services}>
                  <div className={s.service}>
                    <LazyLoadImage
                      src="/images/svg/master-card-logo.svg"
                      alt=""
                    />
                  </div>
                  <div className={s.service}>
                    <LazyLoadImage src="/images/svg/visa-logo.svg" alt="" />
                  </div>
                  <Link
                    to="https://itadviser.ee"
                    className={cn(s.service, s.madeBy)}
                  >
                    Made By ItAdviser
                  </Link>
                  {/* <div className={s.service}>
                    <LazyLoadImage
                      src="/images/svg/tripadvisor-logo-full.svg"
                      alt=""
                    />
                  </div> */}
                </div>
                {/* <div className={s.policy}>
                  <Link
                    to="/privacy-policy/"
                    className={s.policyLink}
                    children={t('privacy policy')}
                  />
                  <Link
                    to="/ticket-sales-rules/"
                    className={s.policyLink}
                    children={t('ticket sales rules')}
                  />
                  <Link
                    to="/customs-regulations/"
                    className={s.policyLink}
                    children={t('customs regulations')}
                  />
                  <Link
                    to="/transportation-rules/"
                    className={s.policyLink}
                    children={t('transportation rules')}
                  />
                </div> */}
              </div>
            </div>
          </section>
        </Container>
      </Container>
    </footer>
  );
};

export default withTranslation()(Footer);
