import React from 'react';
import { withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { Loading } from '@lux/ui';
import { DEFAULT_LANG } from '@lux/spa/config';
import { YOO_MONEY_PAYMENT } from '@lux/spa/apollo/mutations';

import s from './yookassa-payment.module.scss';

const YookassaPayment = ({ t, language }) => {
  const returnUrl = `${window.location.origin}${
    DEFAULT_LANG === language ? '' : `/${language}`
  }/tickets/thanks?type=yookassa`;

  const [yooMoneyPayment, { loading }] = useMutation(YOO_MONEY_PAYMENT, {
    variables: {
      RedirectUrl: returnUrl,
      Smart: true,
    },
    onCompleted: data => {
      if (data.yooMoneyPayment.success) {
        const url = data.yooMoneyPayment.confirmationUrl;
        window.location.href = url;
      }
    },
  });

  return (
    <div className={s.yooKassa}>
      {loading ? (
        <>
          <div className={s.text}>{t('redirecting to the payment page')}</div>
          <Loading />
        </>
      ) : (
        <div className={s.paymentOption} onClick={yooMoneyPayment}>
          <img src="/images/svg/yoo-money.svg" alt="" />
        </div>
      )}
    </div>
  );
};

export default withTranslation()(YookassaPayment);
