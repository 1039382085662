import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { BASKET_STATE } from '@lux/spa/apollo/queries';
import { useLazyQuery } from '@apollo/react-hooks';
import { StoreContext } from '@lux/spa/store';
import { createThanksSuccessAnaliticsData } from 'utils';
import ThanksLayoutSuccess from '../../thanks-layout-success.jsx';
import ThanksLayoutPending from '../../thanks-layout-pending.jsx';
import ThanksLayoutError from '../../thanks-layout-error.jsx';

const BankLink = ({
  i18n,
  history,
  fileLoading,
  getTicketsFileUrl,
  urlParams,
  linkToPDFFile,
}) => {
  const [state, dispatch] = useContext(StoreContext);

  const {
    from,
    to,
    returnDate,
    departDate,
    promocode,
    totalPrice,

    GTM,
  } = state.tickets;
  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';
  const { ShoppingBasketId = '' } = urlParams;
  const { GTMLoaded } = state.app;
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [basketState] = useLazyQuery(BASKET_STATE, {
    fetchPolicy: 'network-only',
    skip: !!ShoppingBasketId,
    variables: {
      basketId: ShoppingBasketId,
    },
    onError: () => {
      setIsError(true);
      setIsLoading(false);
    },
    onCompleted: data => {
      const error = data?.basketState.error;

      if (error) {
        setIsError(true);
        setIsLoading(false);
      } else {
        if (data?.basketState.State === 'payment_started') {
          basketState();
        } else if (data?.basketState.State === 'paid') {
          setIsError(false);
          setIsLoading(false);
          getTicketsFileUrl();
        } else {
          setIsError(true);
          setIsLoading(false);
        }

        if (GTMLoaded && !GTM.thanksStep) {
          dataLayer.push({
            ...createThanksSuccessAnaliticsData({
              returnDate,
              departDate,
              from,
              to,
              currencyName,
              totalPrice,
              promocode,
              objects: data?.basketState?.objects || [],
            }),
          });

          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              GTM: {
                ...GTM,
                thanksStep: true,
              },
            },
          });
        }
      }
    },
  });

  useEffect(() => {
    basketState();
  }, []);

  return isLoading ? (
    <ThanksLayoutPending />
  ) : isError ? (
    <ThanksLayoutError renderCancel />
  ) : (
    <ThanksLayoutSuccess
      type="card"
      linkToPDFFile={linkToPDFFile}
      fileLoading={fileLoading}
      getTicketsFileUrl={getTicketsFileUrl}
    />
  );
};

export default withRouter(withTranslation()(BankLink));
