import React from 'react';
import cn from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CHANGE_PASSWORD } from '@lux/spa/apollo/mutations';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { Container, HiddenInput } from '@lux/ui';
import { ProfileMenu } from '@lux/spa/components';
import s from './security.module.scss';

const Security = ({ t, className }) => {
  const [changePassword] = useMutation(CHANGE_PASSWORD);
  return (
    <div className={cn(className, s.security)}>
      <Container>
        <Container full={false}>
          <section>
            <h2>{t('my account')}</h2>
            <div className={s.block}>
              <ProfileMenu />
              <div className={s.content}>
                <Formik
                  validateOnMount
                  enableReinitialize
                  initialValues={{
                    password: '',
                    confirmPassword: '',
                  }}
                  validationSchema={Yup.object().shape({
                    password: Yup.string()
                      .required(
                        t('validation.error.require', {
                          field: t('password'),
                        })
                      )
                      .min(
                        6,
                        t('validation.error.min', {
                          field: t('password'),
                          min: 6,
                        })
                      ),
                    confirmPassword: Yup.string()
                      .required(
                        t('validation.error.require', {
                          field: t('confirm password'),
                        })
                      )
                      .oneOf(
                        [Yup.ref('password'), null],
                        t('passwords must match')
                      ),
                  })}
                >
                  {props => {
                    const {
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleBlur,
                      handleReset,
                      validateForm,
                    } = props;

                    return (
                      <>
                        <div className={s.header}>
                          <h5 className={s.title}>{t('security')}</h5>
                          <div
                            className={s.action}
                            onClick={() => {
                              if (!Object.keys(errors).length) {
                                changePassword({
                                  variables: {
                                    password: values.password,
                                  },
                                })
                                  .then(data => {
                                    if (data.data.changePassword.success) {
                                      toast.success(
                                        t(
                                          'the password was successfully updated'
                                        )
                                      );
                                    }
                                    handleReset();
                                    validateForm();
                                  })
                                  .catch(() => {
                                    toast.success(t('something wrong'));
                                  });
                              }
                            }}
                            disabled={Object.keys(errors).length}
                          >
                            {t('save')}
                          </div>
                        </div>

                        <div className={s.info}>
                          <form className={s.form}>
                            <div className={s.fields}>
                              <div className={s.field}>
                                <HiddenInput
                                  autoFocus
                                  name="password"
                                  placeholder="••••••"
                                  label={t('new password')}
                                  required
                                  value={values.password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  errors={touched.password && errors.password}
                                />
                              </div>
                              <div className={s.field}>
                                <HiddenInput
                                  name="confirmPassword"
                                  placeholder="••••••"
                                  label={t('repeat new password')}
                                  required
                                  value={values.confirmPassword}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  errors={
                                    touched.confirmPassword &&
                                    errors.confirmPassword
                                  }
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(Security);
