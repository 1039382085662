import React, { cloneElement, Children, Component } from 'react';

// import { number, bool } from 'prop-types'
import cn from 'classnames';
import s from './tabs.module.scss';

/* component */
class Tabs extends Component {
  // static propTypes = {
  //   active: number
  // }
  //
  static defaultProps = {
    active: 0,
  };

  state = {
    active: this.props.active,
  };

  changeActiveTab = activeTab => {
    this.setState({
      active: activeTab,
    });
  };

  render() {
    const { className, ...props } = this.props;
    const { active } = this.state;
    const childrens = Children.toArray(props.children);
    const classNames = cn(s.tabs, className);
    return (
      <div className={classNames}>
        {childrens.map(children =>
          cloneElement(children, {
            active,
            changeActiveTab: this.changeActiveTab,
          })
        )}
      </div>
    );
  }
}

export default Tabs;
