import gql from 'graphql-tag';

export const MODIFICATION_VERIFY_VOUCHER = gql`
  mutation($VoucherCode: String!) {
    modificationVerifyVoucher(VoucherCode: $VoucherCode) {
      error {
        ErrorCode
        ErrorReason
      }
      success
      voucherInfo {
        Code
        Type
        Currency
        InitialAmount
        Amount
        Price
        ValidUntil
        Pin
        CreatedOn
        IsValid
        error {
          ErrorCode
          ErrorReason
        }
      }
    }
  }
`;

export const MODIFICATION_VOUCHER_PAYMENT = gql`
  mutation ModificationVoucherPayment {
    modificationVoucherPayment {
      error {
        ErrorCode
        ErrorReason
      }
      basketPayed
      details {
        CurrencyOfModification
        ShoppingBasketId
        State
        ModificationCost
      }
      objects {
        IsOutbound
        DepartureTime
        DepartureTimeZone
        ArrivalTime
        ArrivalTimeZone
        DepartureStopName
        DepartureStopId
        DepartureCityName
        DepartureCityId
        DestinationStopName
        DestinationStopId
        DestinationCityName
        DestinationCityId
        ShoppingBasketCode
        ShoppingBasketId
        ActiveModificationSessionId
        ShoppingBasketExportCode
        IsCorporateClientContractPurchase
        Legs {
          OrderNumber
          DepartureTime
          DepartureTimeZone
          ArrivalTime
          ArrivalTimeZone
          PlannedDepartureTime
          PlannedDepartureTimeZone
          PlannedArrivalTime
          PlannedArrivalTimeZone
          DepartureStopName
          DepartureCityName
          DeparturePlatforms
          DestinationStopName
          DestinationCityName
          BrandName
          ServiceCode
          LineNumber
          TripName
          SubcontractorName
        }
        Passengers {
          ProductCode
          ExternalTicketNumber
          IsPartOfRoundTrip
          PassengerName
          PassengerCategory
          Tickets {
            CouponNumber
            LegOrderNumber
            Price
            Currency
            QrCode
          }
          Seats {
            BusNameTag
            LegOrderNumber
            SeatOrderNumber
            SeatClass
            SeatNumber
            SeatChangeStop
            SeatFloorNumber
            HasSeatBeenReleased
            Price
            Currency
          }
        }
      }
    }
  }
`;

export const MODIFICATION_SEAT_PLAN = gql`
  mutation ModificationSeatPlans {
    modificationSeatPlans {
      error {
        ErrorCode
        ErrorReason
      }
      success
      seatPlan {
        Legs {
          IsOutbound
          OrderNumber
          Buses {
            TripBusProfileId
            Name
            AvailableSeatCount
            CanChooseSeatNumber
            RegistrationNumber
            SteeringWheelLocation
            Floors {
              FloorNumber
              SeatRows {
                RowNumber
                Seats {
                  ColumnNumber
                  SeatNumber
                  SeatNumberLetter
                  SeatClassCategory
                  SeatClassCategoryName
                  SeatClass
                  SeatClassName
                  SeatRank
                  IsWheelchairSeat
                  IsPetSeat
                  IsChildSeat
                  IsForwardSeating
                  HasElectricity
                  IsToilet
                  IsStairs
                  IsEmptyArea
                  IsAvailable
                  IsSoldLater
                  SeatPrice {
                    Amount
                    Currency
                  }
                  TicketPrice {
                    Amount
                    Currency
                  }
                  AdditionalSeat {
                    ChangeStopId
                    SeatNumber
                    SeatNumberLetter
                  }
                  ProductGuid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const MODIFICATION_CANCEL = gql`
  mutation modificationCancel {
    modificationCancel {
      error {
        ErrorCode
        ErrorReason
      }
    }
  }
`;

export const MODIFICATION_NEO_PAY_PAYMENT = gql`
  mutation ModificationNeoPayPaymentMutation {
    modificationNeoPayPayment {
      success
      error {
        ErrorCode
        ErrorReason
      }
      data
    }
  }
`;

export const MODIFICATION_NEO_PAY_DETAILS = gql`
  mutation($token: String!) {
    modificationNeoPayDetails(token: $token) {
      error {
        ErrorCode
        ErrorReason
      }
      objects {
        IsOutbound
        DepartureTime
        DepartureTimeZone
        ArrivalTime
        ArrivalTimeZone
        DepartureStopName
        DepartureStopId
        DepartureCityName
        DepartureCityId
        DestinationStopName
        DestinationStopId
        DestinationCityName
        DestinationCityId
        ShoppingBasketCode
        ShoppingBasketId
        ActiveModificationSessionId
        ShoppingBasketExportCode
        IsCorporateClientContractPurchase
        Legs {
          OrderNumber
          DepartureTime
          DepartureTimeZone
          ArrivalTime
          ArrivalTimeZone
          PlannedDepartureTime
          PlannedDepartureTimeZone
          PlannedArrivalTime
          PlannedArrivalTimeZone
          DepartureStopName
          DepartureCityName
          DeparturePlatforms
          DestinationStopName
          DestinationCityName
          BrandName
          ServiceCode
          LineNumber
          TripName
          SubcontractorName
        }
        Passengers {
          ProductCode
          ExternalTicketNumber
          IsPartOfRoundTrip
          PassengerName
          PassengerCategory
          Tickets {
            CouponNumber
            LegOrderNumber
            Price
            Currency
            QrCode
          }
          Seats {
            BusNameTag
            LegOrderNumber
            SeatOrderNumber
            SeatClass
            SeatNumber
            SeatChangeStop
            SeatFloorNumber
            HasSeatBeenReleased
            Price
            Currency
          }
        }
      }
    }
  }
`;

export const MODIFICATION_ADYEN_PAYMENT = gql`
  mutation($input: AdyenPaymentInput!) {
    modificationAdyenPayment(input: $input) {
      success
      message
      error {
        ErrorCode
        ErrorReason
      }
      action
      details {
        key
        type
      }
      objects {
        IsOutbound
        DepartureTime
        DepartureTimeZone
        ArrivalTime
        ArrivalTimeZone
        DepartureStopName
        DepartureStopId
        DepartureCityName
        DepartureCityId
        DestinationStopName
        DestinationStopId
        DestinationCityName
        DestinationCityId
        ShoppingBasketCode
        ShoppingBasketId
        ActiveModificationSessionId
        ShoppingBasketExportCode
        IsCorporateClientContractPurchase
        Legs {
          OrderNumber
          DepartureTime
          DepartureTimeZone
          ArrivalTime
          ArrivalTimeZone
          PlannedDepartureTime
          PlannedDepartureTimeZone
          PlannedArrivalTime
          PlannedArrivalTimeZone
          DepartureStopName
          DepartureCityName
          DeparturePlatforms
          DestinationStopName
          DestinationCityName
          BrandName
          ServiceCode
          LineNumber
          TripName
          SubcontractorName
        }
        Passengers {
          ProductCode
          ExternalTicketNumber
          IsPartOfRoundTrip
          PassengerName
          PassengerCategory
          Tickets {
            CouponNumber
            LegOrderNumber
            Price
            Currency
            QrCode
          }
          Seats {
            BusNameTag
            LegOrderNumber
            SeatOrderNumber
            SeatClass
            SeatNumber
            SeatChangeStop
            SeatFloorNumber
            HasSeatBeenReleased
            Price
            Currency
          }
        }
      }
    }
  }
`;

export const MODIFICATION_ADYEN_PAYMENT_DETAILS = gql`
  mutation($input: AdyenPaymentDetailsInput!) {
    modificationAdyenPaymentDetails(input: $input) {
      success
      message
      action
      error {
        ErrorCode
        ErrorReason
      }
      objects {
        IsOutbound
        DepartureTime
        DepartureTimeZone
        ArrivalTime
        ArrivalTimeZone
        DepartureStopName
        DepartureStopId
        DepartureCityName
        DepartureCityId
        DestinationStopName
        DestinationStopId
        DestinationCityName
        DestinationCityId
        ShoppingBasketCode
        ShoppingBasketId
        ActiveModificationSessionId
        ShoppingBasketExportCode
        IsCorporateClientContractPurchase
        Legs {
          OrderNumber
          DepartureTime
          DepartureTimeZone
          ArrivalTime
          ArrivalTimeZone
          PlannedDepartureTime
          PlannedDepartureTimeZone
          PlannedArrivalTime
          PlannedArrivalTimeZone
          DepartureStopName
          DepartureCityName
          DeparturePlatforms
          DestinationStopName
          DestinationCityName
          BrandName
          ServiceCode
          LineNumber
          TripName
          SubcontractorName
        }
        Passengers {
          ProductCode
          ExternalTicketNumber
          IsPartOfRoundTrip
          PassengerName
          PassengerCategory
          Tickets {
            CouponNumber
            LegOrderNumber
            Price
            Currency
            QrCode
          }
          Seats {
            BusNameTag
            LegOrderNumber
            SeatOrderNumber
            SeatClass
            SeatNumber
            SeatChangeStop
            SeatFloorNumber
            HasSeatBeenReleased
            Price
            Currency
          }
        }
      }
    }
  }
`;

export const MODIFICATION_ZERO_PAYMENT = gql`
  mutation ModificationZeroPayment {
    modificationZeroPayment {
      error {
        ErrorCode
        ErrorReason
      }
      success
      objects {
        IsOutbound
        DepartureTime
        DepartureTimeZone
        ArrivalTime
        ArrivalTimeZone
        DepartureStopName
        DepartureStopId
        DepartureCityName
        DepartureCityId
        DestinationStopName
        DestinationStopId
        DestinationCityName
        DestinationCityId
        ShoppingBasketCode
        ShoppingBasketId
        ActiveModificationSessionId
        ShoppingBasketExportCode
        IsCorporateClientContractPurchase
        Legs {
          OrderNumber
          DepartureTime
          DepartureTimeZone
          ArrivalTime
          ArrivalTimeZone
          PlannedDepartureTime
          PlannedDepartureTimeZone
          PlannedArrivalTime
          PlannedArrivalTimeZone
          DepartureStopName
          DepartureCityName
          DeparturePlatforms
          DestinationStopName
          DestinationCityName
          BrandName
          ServiceCode
          LineNumber
          TripName
          SubcontractorName
        }
        Passengers {
          ProductCode
          ExternalTicketNumber
          IsPartOfRoundTrip
          PassengerName
          PassengerCategory
          Tickets {
            CouponNumber
            LegOrderNumber
            Price
            Currency
            QrCode
          }
          Seats {
            BusNameTag
            LegOrderNumber
            SeatOrderNumber
            SeatClass
            SeatNumber
            SeatChangeStop
            SeatFloorNumber
            HasSeatBeenReleased
            Price
            Currency
          }
        }
      }
    }
  }
`;

export const MODIFICATION_CORPORATE_CLIENT_PAY = gql`
  mutation($input: ModificationCorporateClientPayInput) {
    modificationCorporateClientPay(input: $input) {
      error {
        ErrorCode
        ErrorReason
      }
      success
      objects {
        IsOutbound
        DepartureTime
        DepartureTimeZone
        ArrivalTime
        ArrivalTimeZone
        DepartureStopName
        DepartureStopId
        DepartureCityName
        DepartureCityId
        DestinationStopName
        DestinationStopId
        DestinationCityName
        DestinationCityId
        ShoppingBasketCode
        ShoppingBasketId
        ActiveModificationSessionId
        ShoppingBasketExportCode
        IsCorporateClientContractPurchase
        Legs {
          OrderNumber
          DepartureTime
          DepartureTimeZone
          ArrivalTime
          ArrivalTimeZone
          PlannedDepartureTime
          PlannedDepartureTimeZone
          PlannedArrivalTime
          PlannedArrivalTimeZone
          DepartureStopName
          DepartureCityName
          DeparturePlatforms
          DestinationStopName
          DestinationCityName
          BrandName
          ServiceCode
          LineNumber
          TripName
          SubcontractorName
        }
        Passengers {
          ProductCode
          ExternalTicketNumber
          IsPartOfRoundTrip
          PassengerName
          PassengerCategory
          Tickets {
            CouponNumber
            LegOrderNumber
            Price
            Currency
            QrCode
          }
          Seats {
            BusNameTag
            LegOrderNumber
            SeatOrderNumber
            SeatClass
            SeatNumber
            SeatChangeStop
            SeatFloorNumber
            HasSeatBeenReleased
            Price
            Currency
          }
        }
      }
    }
  }
`;
export const MODIFICATION_CHANGE_SEAT = gql`
  mutation($input: [ModificationChangeSeatInput]!) {
    modificationChangeSeat(input: $input) {
      error {
        ErrorCode
        ErrorReason
      }
      prices {
        Prices {
          Id
          BonusSchemeName
          IsCampaignPrice
          IsCampaignTicketChangeable
          IsCampaignTicketRefundable
          FinalPrice
        }
        ProductCode
        Leg {
          IsOutbound
          OrderNumber
        }
      }
    }
  }
`;

export const MODIFICATION_JOURNEY_CONFIRM = gql`
  mutation(
    $IsOutbound: Boolean!
    $JourneyId: String!
    $PriceClass: String!
    $lang: String
  ) {
    modificationJourneyConfirm(
      IsOutbound: $IsOutbound
      JourneyId: $JourneyId
      PriceClass: $PriceClass
      lang: $lang
    ) {
      error {
        ErrorCode
        ErrorReason
      }
      success
      details {
        CurrencyOfModification
        ShoppingBasketId
        State
        ModificationCost
        Ways {
          IsOutbound
          DepartureStopName
          DepartureCityName
          PlannedDepartureDateTime
          PlannedDepartureDateTimeTimeZone
          ActualDepartureDateTime
          ActualDepartureDateTimeTimeZone
          DestinationStopName
          DestinationCityName
          PlannedArrivalDateTime
          PlannedArrivalDateTimeTimeZone
          ActualArrivalDateTime
          ActualArrivalDateTimeTimeZone
          Duration
          JourneyModificationCost
          ServiceModificationCost
          Legs {
            OrderNumber
            BusCompanyName
            SubcontractorName
            BrandName
            BusLineNumber
            ServiceCode
            DepartureStopName
            DepartureCityName
            DeparturePlatform
            PlannedDepartureDateTime
            PlannedDepartureDateTimeTimeZone
            ActualDepartureDateTime
            ActualDepartureDateTimeTimeZone
            DestinationStopName
            DestinationCityName
            DestinationPlatform
            PlannedArrivalDateTime
            PlannedArrivalDateTimeTimeZone
            ActualArrivalDateTime
            ActualArrivalDateTimeTimeZone
            Duration
          }
        }
        Passengers {
          ProductCode
          PassengerName
          PassengerCategory
        }
        PaymentMethodFee {
          Amount
          VatRate
        }
        SmsFee {
          Amount
          VatRate
        }
        ModificationFees {
          ProductCode
          Amount
          VatRate
        }
        ChangedSeats {
          ProductCode
          IsOutbound
          LegOrderNumber
          IsAutomaticallyAssigned
          SeatInfo {
            OrderNumber
            SeatNumber
            SeatNumberLetter
            SeatClassCategory
            SeatClassCategoryName
            SeatClass
            SeatClassName
            SeatPrice
          }
        }
      }
      seatPlan {
        Legs {
          IsOutbound
          OrderNumber
          Buses {
            TripBusProfileId
            Name
            AvailableSeatCount
            CanChooseSeatNumber
            RegistrationNumber
            SteeringWheelLocation
            Floors {
              FloorNumber
              SeatRows {
                RowNumber
                Seats {
                  ColumnNumber
                  SeatNumber
                  SeatNumberLetter
                  SeatClassCategory
                  SeatClassCategoryName
                  SeatClass
                  SeatClassName
                  SeatRank
                  IsWheelchairSeat
                  IsPetSeat
                  IsChildSeat
                  IsForwardSeating
                  HasElectricity
                  IsToilet
                  IsStairs
                  IsEmptyArea
                  IsAvailable
                  IsSoldLater
                  SeatPrice {
                    Amount
                    Currency
                  }
                  TicketPrice {
                    Amount
                    Currency
                  }
                  AdditionalSeat {
                    ChangeStopId
                    SeatNumber
                    SeatNumberLetter
                  }
                  ProductGuid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const MODIFICATION_SEARCH = gql`
  mutation(
    $BasketCode: String!
    $BasketId: String!
    $Currency: String!
    $CampaignCode: String
    $DepartureDate: String!
    $DestinationBusStopId: Int!
    $IsOutbound: Boolean!
    $OriginBusStopId: Int!
    $ProductCodes: [String]!
    $lang: String
  ) {
    modificationSearch(
      BasketCode: $BasketCode
      BasketId: $BasketId
      Currency: $Currency
      CampaignCode: $CampaignCode
      DepartureDate: $DepartureDate
      DestinationBusStopId: $DestinationBusStopId
      IsOutbound: $IsOutbound
      OriginBusStopId: $OriginBusStopId
      ProductCodes: $ProductCodes
      lang: $lang
    ) {
      error {
        ErrorCode
        ErrorReason
      }
      results {
        Fee
        ResponseValidUntilUtc
        Journeys {
          JourneyId
          IsModifiableJourney
          DepartureDateTimeTimeZone
          DepartureDateTime
          ArrivalDateTimeTimeZone
          ArrivalDateTime
          PlannedDepartureDateTime
          PlannedArrivalDateTime
          Duration
          OriginStopName
          DestinationStopName
          AvailableRegularSeats
          AvailableBusinessClassSeats
          AvailableCampaignSeats
          AvailableBusinessCampaignSeats
          IsForSale
          IsChangeable
          IsRefundable
          IsPetOnBus
          IsAllergicPassengerOnBus
          Legs {
            OrderNumber
            BusCompanyName
            BrandName
            BrandShortName
          }
          AddonsToRemove {
            Name
            Quantity
          }
          AddonsToKeep {
            Name
            Quantity
          }
          PriceClasses {
            PriceClassName
            Price
          }
        }
      }
      session {
        SessionId
        IsOutboundJourneyWayModifiable
        IsInboundJourneyWayModifiable
        Fee
        SessionExpiresAt
        SessionExpiresAtUtc
      }
    }
  }
`;

export const YOO_MONEY_MODIFICATION_PAYMENT = gql`
  mutation($RedirectUrl: String!, $Smart: Boolean) {
    yooMoneyModificationPayment(RedirectUrl: $RedirectUrl, Smart: $Smart) {
      success
      error {
        ErrorCode
        ErrorReason
      }
      confirmationUrl
    }
  }
`;

export const YOO_MONEY_MODIFICATION_PAYMENT_DETAILS = gql`
  mutation($orderId: String!) {
    yooMoneyModificationPaymentDetails(orderId: $orderId) {
      success
      error {
        ErrorCode
        ErrorReason
      }
      objects {
        IsOutbound
        DepartureTime
        DepartureTimeZone
        ArrivalTime
        ArrivalTimeZone
        DepartureStopName
        DepartureStopId
        DepartureCityName
        DepartureCityId
        DestinationStopName
        DestinationStopId
        DestinationCityName
        DestinationCityId
        ShoppingBasketCode
        ShoppingBasketId
        ActiveModificationSessionId
        ShoppingBasketExportCode
        IsCorporateClientContractPurchase
        Legs {
          OrderNumber
          DepartureTime
          DepartureTimeZone
          ArrivalTime
          ArrivalTimeZone
          PlannedDepartureTime
          PlannedDepartureTimeZone
          PlannedArrivalTime
          PlannedArrivalTimeZone
          DepartureStopName
          DepartureCityName
          DeparturePlatforms
          DestinationStopName
          DestinationCityName
          BrandName
          ServiceCode
          LineNumber
          TripName
          SubcontractorName
        }
        Passengers {
          ProductCode
          ExternalTicketNumber
          IsPartOfRoundTrip
          PassengerName
          PassengerCategory
          Tickets {
            CouponNumber
            LegOrderNumber
            Price
            Currency
            QrCode
          }
          Seats {
            BusNameTag
            LegOrderNumber
            SeatOrderNumber
            SeatClass
            SeatNumber
            SeatChangeStop
            SeatFloorNumber
            HasSeatBeenReleased
            Price
            Currency
          }
        }
      }
    }
  }
`;
