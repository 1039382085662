import React from 'react';
import cx from 'classnames';
import i from '@lux/spa/styles/icons.module.scss';
import styles from './notice.module.scss';

const Notice = ({ className, text, color = 'main', children, html = null }) => {
  const classes = cx(styles.notice, styles[color], className);
  return (
    <div className={classes}>
      <i className={i.infoCircle} />
      {html ? (
        <div
          className={styles.message}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      ) : (
        <div className={styles.message}>{text || children}</div>
      )}
    </div>
  );
};
export default Notice;
