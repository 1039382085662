import React from 'react';
import cn from 'classnames';
import { localizedDate } from 'utils';
import s from './title.module.scss';

const Title = ({
  className,
  selected,
  selectedTransfer,
  handleRequestTransfer,
  outbound,
  t,
}) => {
  return (
    <div
      className={cn(s.mainInfo, selected && s.selected)}
      onClick={() => handleRequestTransfer(outbound)}
    >
      <div className={s.check} />
      <div className={s.text}>
        {`${t('request transfer')}: `}
        <strong>
          {localizedDate(new Date(selectedTransfer.TransferTime), 'dd LLLL')}
          {` ${selectedTransfer.BusStopName}`}
        </strong>
      </div>
    </div>
  );
};

export default Title;
