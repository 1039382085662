import React, { useState, useContext } from 'react';
import cn from 'classnames';
import { StoreContext } from '@lux/spa/store';
import ContentEditable from 'react-contenteditable';
import i from '@lux/spa/styles/icons.module.scss';
import s from './text.module.scss';

const Text = ({
  onChange,
  field = {
    value: '',
    placeholder: '',
    fieldName: '',
    maxLength: 0,
  },
}) => {
  const [state] = useContext(StoreContext);
  const { app } = state;
  const { isEditMode } = app;

  // if (!field) return <Loading />

  const [isFocused, setIsFocused] = useState(false);

  const setFocusFalse = () => {
    setIsFocused(false);
  };

  const setFocusTrue = () => {
    setIsFocused(true);
  };

  const { placeholder, fieldName, maxLength } = field;

  // const value = field.value.replace(/&nbsp;/g, ' ').replace(/<br>/g, '').replace(/^\s+|\s+$/g, '');
  // const value = field.value.replace(/&nbsp;/g, ' ').replace(/<br>/g, '').replace(/^\s+/g, '')

  // const value = field.value.replace(/(&nbsp;|<([^>]+)>)/g, '').replace(/^\s+/g, '')
  const value = field.value
    .replace(/(<([^>]+)>)/g, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/^\s+/g, '');

  const pastePlainText = e => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    document.execCommand('insertHTML', false, text);
  };

  const classes = cn(
    s.tooltip,
    isFocused && s.focused,
    value.length > maxLength && s.warning
  );

  return isEditMode ? (
    <div className={s.text}>
      <div
        className={cn(
          s.tooltip,
          isFocused && s.focused,
          value.length > maxLength && s.warning
        )}
      >
        <i className={i.edit} />
        <div className={s.info}>
          <div>{placeholder}</div>
          <div>
            <span>{value.length}</span> / {maxLength}
          </div>
        </div>
      </div>
      <ContentEditable
        className={cn(s.contentEditable)}
        tagName="div"
        // placeholder={value.length ? '' : placeholder}
        placeholder={placeholder}
        onPaste={pastePlainText}
        html={value}
        disabled={false}
        // name={'articleTitle'}
        onFocus={setFocusTrue}
        onBlur={setFocusFalse}
        onChange={e => {
          onChange({
            [fieldName]: {
              ...field,
              value: e.target.value,
            },
          });
        }}
      />
    </div>
  ) : (
    value
  );
};

export default Text;
