/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { DEFAULT_LANG, validToUseExponea } from '@lux/spa/config';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import {
  ADD_JOURNEY_TO_BASKET,
  GET_AVAILABLE_PRICE_FOR_PRODUCTS,
  UPDATE_PRODUCT_PRICE,
  GET_JOURNEY_ADDONS,
  ADD_ADDONS,
} from '@lux/spa/apollo/mutations';
import dateFormat from 'date-fns/format';
import { BASKET_PRODUCT_GROUPS } from '@lux/spa/apollo/queries';
import { updatePrices, getCookieByName, updatePassengersPricesV2 } from 'utils';
import addMinutes from 'date-fns/addMinutes';
import { StoreContext } from '@lux/spa/store';
import { TripDetails } from '@lux/spa/components';
import { Bus, Transfer, SeatTypes } from './components';
import { Container, Loading, Notice, DiscardedBasket } from '@lux/ui';
import styles from './seat-details.module.scss';
import i from '@lux/spa/styles/icons.module.scss';

const SeatDetails = ({ t, i18n, className, history }) => {
  const { isMob, isTabDown } = useContext(WidthContext);
  const [state, dispatch] = useContext(StoreContext);
  const { tickets, app } = state;

  const { GTMLoaded } = app;
  const { language } = i18n;
  const {
    outbound,
    inbound,
    activeBus,
    isOutbound,
    products = {},
    isOpenDropDown,
    from = {},
    to = {},
    lastSearch,
    availablePrices = {},
    inboundJourneyPriceClass,
    outboundJourneyPriceClass,
    currentStepDisabled,
    promocode,
    totalPrice,
    returnDate,
    departDate,
    passengersCount,
    hasTransfers,
    affiliateId,
    GTM,
    bicycles,
  } = tickets;

  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';

  const { OutboundJourneys = {}, InboundJourneys = {} } = products;
  const activeTab = isOutbound ? 1 : 2;

  const [isOpenModal, setIsOpenModal] = useState(false);

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleNextStep = () => {
    if (validToUseExponea()) {
      exponea.track('seats_confirmed', {
        timestamp: Date.now(),
        count_passengers: passengersCount,
        total_price: totalPrice,
        currency: 'RUB',
        Origin_stop_ID: from.BusStopId,
        Destination_stop_ID: to.BusStopId,
      });
    }

    // if (GTMLoaded) {
    //   dataLayer.push({
    //     tripType: returnDate ? 'return-trip' : 'one-way',
    //     departureStopId: from.BusStopId,
    //     destinationStopId: to.BusStopId,
    //     currencyCode: currencyName,
    //     route: `${from.CityName}-${to.CityName}`,
    //     price: totalPrice,
    //     promocode: promocode || null,
    //     departureDestination: `${from.CountryName}-${to.CountryName}`,
    //     stage: 'departure',
    //   });
    // }

    history.push(
      language === DEFAULT_LANG
        ? `/tickets/passenger-details/`
        : `/${language}/tickets/passenger-details/`
    );
  };

  const changeTab = tab => {
    dispatch({
      type: 'UPDATE_TICKETS',
      payload: {
        activeBus: 1,
        activePassenger: 1,
        isOutbound: tab === 1,
      },
    });
  };

  let productIds = [];

  if (products?.OutboundJourneys?.[0]?.Products?.length) {
    productIds = products.OutboundJourneys[0].Products.map(
      item => item.ProductId
    );
  }

  const [getJourneyAddons, { called: addonsCalled }] = useMutation(
    GET_JOURNEY_ADDONS,
    {
      onCompleted: data => {
        const { error } = data.getJourneyAddons;

        if (error) {
        } else {
          let outbound = [];
          let inbound = [];

          data.getJourneyAddons.addons.LegAddOns.forEach(item => {
            if (item.IsOutbound) {
              outbound.push(item.AddOns?.[0]?.SalesLimit || 0);
            } else {
              inbound.push(item.AddOns?.[0]?.SalesLimit || 0);
            }
          });

          const maxOutboundBicycles = outbound.length
            ? Math.min(...outbound)
            : 0;

          const maxInboundBicycles = inbound.length ? Math.min(...inbound) : 0;

          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              hasTransfers:
                products.HasTransfers ||
                !!maxOutboundBicycles ||
                !!maxInboundBicycles,
              bicycles: {
                ...bicycles,
                info: data.getJourneyAddons.addons,
                outboundBicycle: {
                  ...bicycles.outboundBicycle,
                  maxBicycles: Math.min(
                    ...[maxOutboundBicycles, passengersCount]
                  ),
                },
                inboundBicycle: {
                  ...bicycles.inboundBicycle,
                  maxBicycles: Math.min(
                    ...[maxInboundBicycles, passengersCount]
                  ),
                },
              },
              hasOutboundBicycles: !!maxOutboundBicycles,
              hasInboundBicycles: !!maxInboundBicycles,
            },
          });
        }
      },
      onError: error => {
        console.log('error', error);
      },
    }
  );

  useEffect(() => {
    if (productIds.length && !addonsCalled) {
      getJourneyAddons({
        variables: {
          productIds,
          lang: language,
          currency,
          addonId: +ADDON_BICYCLE_ID,
        },
      });
    }
  }, [productIds, addonsCalled]);

  const [getBasketGroup, { loading: basketLoading, called }] = useLazyQuery(
    BASKET_PRODUCT_GROUPS,
    {
      fetchPolicy: 'network-only',
      variables: {
        lang: language,
      },
      onError: () => {
        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            isSomethingWrong: true,
          },
        });
      },
      onCompleted: data => {
        const { basketProductGroups = null } = data;
        if (!basketProductGroups) {
          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              isSomethingWrong: true,
            },
          });
          return;
        }

        const inboundPassengers = basketProductGroups.InboundJourneys;
        const outboundPassengers = basketProductGroups.OutboundJourneys;
        const { passengerPrices, passengersFee } = updatePassengersPricesV2(
          products,
          outboundPassengers,
          inboundPassengers
        );

        const { TotalPrice } = basketProductGroups;

        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            totalPrice: TotalPrice,
            passengerPrices,
            passengersFee,
          },
        });
      },
    }
  );

  const [
    updateProductPrices,
    { data: updateProduct = {}, loading: updatePriceLoading = true },
  ] = useMutation(UPDATE_PRODUCT_PRICE, {
    onError: () => {
      dispatch({
        type: 'UPDATE_TICKETS',
        payload: {
          isSomethingWrong: true,
        },
      });
    },
    onCompleted: data => {
      const error = data?.updateProductPrices?.error;

      if (error) {
        if (error.ErrorCode === 'basket_expired') {
          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              isBasketExrpired: true,
            },
          });
        } else {
          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              isSomethingWrong: true,
            },
          });
        }
        return;
      }

      if (data.updateProductPrices.success) {
        const updatedProducts = updatePrices(availablePrices, products);

        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            products: updatedProducts,
          },
        });

        getBasketGroup();
      }
    },
  });

  const [
    getAvailablePrices,
    { data = {}, loading: getAvailableLoading = true },
  ] = useMutation(GET_AVAILABLE_PRICE_FOR_PRODUCTS, {
    onError: () => {
      dispatch({
        type: 'UPDATE_TICKETS',
        payload: {
          isSomethingWrong: true,
        },
      });
    },
    onCompleted: data => {
      const error = data?.getAvailablePricesForProducts?.error;
      if (error) {
        if (error.ErrorCode === 'basket_expired') {
          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              isBasketExrpired: true,
            },
          });
        } else {
          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              isSomethingWrong: true,
            },
          });
        }
      }

      if (data.getAvailablePricesForProducts?.success) {
        const availablePrices = data.getAvailablePricesForProducts.prices;

        const Products = availablePrices.map(product => {
          return {
            ProductId: product.ProductId,
            OutboundJourney: product.OutboundLegs.map(item => {
              const IsBusinessClass = products.OutboundJourneys[0].Legs[
                item.OrderNumber - 1
              ].SeatInfo.filter(pass => product.ProductId === pass.ProductId)[0]
                ?.IsBusinessClass;

              let LegPriceId = item.Prices.filter(
                price =>
                  price.IsCampaign && price.IsBusinessClass === IsBusinessClass
              )[0]?.PriceId;

              if (!LegPriceId) {
                LegPriceId = item.Prices.filter(
                  price =>
                    !price.IsCampaign &&
                    price.IsBusinessClass === IsBusinessClass
                )[0]?.PriceId;
              }
              return {
                LegOrderNumber: item.OrderNumber,
                LegPriceId,
              };
            }),
            ...(products.InboundJourneys?.length
              ? {
                  InboundJourney: product.InboundLegs.map(item => {
                    const IsBusinessClass = products.InboundJourneys[0].Legs[
                      item.OrderNumber - 1
                    ].SeatInfo.filter(
                      pass => product.ProductId === pass.ProductId
                    )[0]?.IsBusinessClass;

                    let LegPriceId = item.Prices.filter(
                      price =>
                        price.IsCampaign &&
                        price.IsBusinessClass === IsBusinessClass
                    )[0]?.PriceId;

                    if (!LegPriceId) {
                      LegPriceId = item.Prices.filter(
                        price =>
                          !price.IsCampaign &&
                          price.IsBusinessClass === IsBusinessClass
                      )[0]?.PriceId;
                    }

                    return {
                      LegOrderNumber: item.OrderNumber,
                      LegPriceId,
                    };
                  }),
                }
              : {}),
          };
        });

        updateProductPrices({
          variables: {
            input: {
              Products,
            },
          },
        });

        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            availablePrices,
          },
        });
      }
    },
  });

  const [addJourney, { loading = true }] = useMutation(ADD_JOURNEY_TO_BASKET, {
    variables: {
      InboundJourneyId: inbound.id || '',
      OutboundJourneyId: outbound.id,
      InboundJourneyPriceClass: inboundJourneyPriceClass,
      OutboundJourneyPriceClass: outboundJourneyPriceClass,
      CampaignCode: promocode,
      lang: language,
      ...(getCookieByName('affiliateId')
        ? {
            AffiliateId: getCookieByName('affiliateId'),
          }
        : {}),
    },
    onError: () => {
      dispatch({
        type: 'UPDATE_TICKETS',
        payload: {
          isSomethingWrong: true,
        },
      });
    },
    onCompleted: data => {
      const error = data.addJourneyToBasket?.error;
      if (error) {
        switch (error.ErrorCode) {
          case 'datetime_mismatch': {
            dispatch({
              type: 'UPDATE_TICKETS',
              payload: {
                isSomethingWrong: true,
                isSomethingWrongTitle: t('datetime mismatch'),
              },
            });
          }

          case 'basket_expired': {
            dispatch({
              type: 'UPDATE_TICKETS',
              payload: {
                isBasketExrpired: true,
              },
            });
          }

          default: {
            dispatch({
              type: 'UPDATE_TICKETS',
              payload: {
                isSomethingWrong: true,
              },
            });
          }
        }

        return;
      }

      const products = data.addJourneyToBasket?.products;
      let basketValidUntil = products?.Basket?.ValidUntilInUtc;

      var currentDate = new Date();
      var timezoneOffsetInMinutes = currentDate.getTimezoneOffset();

      basketValidUntil = addMinutes(
        new Date(basketValidUntil),
        timezoneOffsetInMinutes * -1
      );

      let outboundLegs = products?.OutboundJourneys[0].Legs;

      outboundLegs =
        outboundLegs?.sort(
          (a, b) => a.SeatPlan.OrderNumber - b.SeatPlan.OrderNumber
        ) || [];

      let inboundLegs = [];

      if (tickets.inbound.id) {
        inboundLegs = products?.InboundJourneys[0].Legs;
        inboundLegs =
          inboundLegs?.sort(
            (a, b) => a.SeatPlan.OrderNumber - b.SeatPlan.OrderNumber
          ) || [];
      }

      const outboundPassengers = {};
      const inboundPassengers = {};

      outboundLegs.forEach((leg, key) => {
        const bus = `b${key + 1}`;
        outboundPassengers[bus] = {};

        leg.SeatInfo.map((usersInfo, key) => {
          outboundPassengers[bus][`p${key + 1}`] = {
            ...usersInfo,
            TripBusProfileId: leg.TripBusProfileId,
            bus: {
              ...leg.SeatPlan.Buses[0],
            },
          };
        });
      });

      if (tickets.inbound.id) {
        inboundLegs.forEach((leg, key) => {
          const bus = `b${key + 1}`;
          inboundPassengers[bus] = {};

          leg.SeatInfo.map((usersInfo, key) => {
            inboundPassengers[bus][`p${key + 1}`] = {
              ...usersInfo,
              TripBusProfileId: leg.TripBusProfileId,
              bus: {
                ...leg.SeatPlan.Buses[0],
              },
            };
          });
        });
      }

      dispatch({
        type: 'UPDATE_TICKETS',
        payload: {
          products,
          basketValidUntil,
          paymentSuccessful: false,
          hasTransfers: products.HasTransfers,
          outbound: {
            ...outbound,
            products: products.OutboundJourneys,
            passengers: outboundPassengers,
            legs: outboundLegs,
          },
          inbound: {
            ...inbound,
            products: products.InboundJourneys,
            passengers: inboundPassengers,
            legs: inboundLegs,
          },
        },
      });

      // exponea.track('trip_selected', {
      //   timestamp: Date.now(),
      //   departure_date: localizedDate(departDate, 'yyyy-MM-dd'),
      //   origin_stop: from.search,
      //   origin_stop_city: from.CityName,
      //   origin_stop_country: from.CountryName,
      //   destination_stop: to.search,
      //   destination_stop_city: to.CityName,
      //   destination_stop_country: to.CountryName,
      //   currency: currencyName,

      //   // TODO: check fields below
      //   departure_datetime: '',
      //   price: 10,
      //   bus_company: '',
      //   line_brand: '',
      //   seat_class: '',
      //   transfer_trip: '',
      // });

      // if (products.InboundJourneys[0]?.Legs || []) {
      //   exponea.track('trip_selected', {
      //     timestamp: Date.now(),
      //     departure_date: localizedDate(departDate, 'yyyy-MM-dd'),
      //     origin_stop: from.search,
      //     origin_stop_city: from.CityName,
      //     origin_stop_country: from.CountryName,
      //     destination_stop: to.search,
      //     destination_stop_city: to.CityName,
      //     destination_stop_country: to.CountryName,
      //     currency: currencyName,

      //     // TODO: check fields below
      //     departure_datetime: '',
      //     price: 10,
      //     bus_company: '',
      //     line_brand: '',
      //     seat_class: '',
      //     transfer_trip: '',
      //   });
      // }

      const Products = products.OutboundJourneys[0].Products.map(
        (product, idx) => {
          const legsOutbound = products.OutboundJourneys[0].Legs || [];
          const legsInbound = products.InboundJourneys[0]?.Legs || [];
          return {
            ProductId: product.ProductId,
            RequestedFareClass: product.RequestedFareClass,
            OutboundLegs: [
              ...legsOutbound.map(leg => {
                const BonusSchemeId = leg.SeatInfo[idx].Bonuses.filter(
                  bonus => bonus.FareClass === product.RequestedFareClass
                )?.[0]?.BonusSchemeId;
                return {
                  OrderNumber: leg.OrderNumber,
                  BonusSchemeId,
                };
              }),
            ],
            ...(legsInbound.length
              ? {
                  InboundLegs: [
                    ...legsInbound.map(leg => {
                      const BonusSchemeId = leg.SeatInfo[idx].Bonuses.filter(
                        bonus => bonus.FareClass === product.RequestedFareClass
                      )?.[0]?.BonusSchemeId;
                      return {
                        OrderNumber: leg.OrderNumber,
                        BonusSchemeId,
                      };
                    }),
                  ],
                }
              : {}),
          };
        }
      );

      getAvailablePrices({
        variables: {
          input: {
            Products,
            CampaignCode: promocode,
          },
        },
      });
    },
  });

  useEffect(() => {
    if (!products?.ShoppingBasketId && outbound.id) {
      addJourney();
    }
  }, []);

  useEffect(() => {
    if (!basketLoading && !GTM.selectSeatsStep && totalPrice) {
      if (GTMLoaded) {
        dataLayer.push({
          tripType: returnDate ? 'return-trip' : 'one-way',
          departureStopId: from.BusStopId,
          destinationStopId: to.BusStopId,
          currencyCode: currencyName,
          route: `${from.CityName}-${to.CityName}`,
          price: totalPrice,
          promocode: promocode || null,
          departureDestination: `${from.CountryName}-${to.CountryName}`,
          stage: 'departure',
          event: 'seat-select',
          departDate: dateFormat(new Date(departDate), 'yyyy-MM-dd'),
          ...(returnDate
            ? {
                returnDate: dateFormat(new Date(returnDate), 'yyyy-MM-dd'),
              }
            : {}),
        });

        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            GTM: {
              ...GTM,
              selectSeatsStep: true,
            },
          },
        });
      }
    }
  }, [basketLoading, totalPrice]);

  useEffect(() => {
    if (!products?.ShoppingBasketId && !outbound.id) {
      history.push(
        language === DEFAULT_LANG
          ? `/tickets/search${lastSearch}`
          : `/${language}/tickets/search${lastSearch}`
      );
    }
  }, [!products?.ShoppingBasketId, outbound.id]);

  const outboundLegs = OutboundJourneys?.[0]?.Legs.sort((a, b) => a - b) || [];
  const outboundProducts = OutboundJourneys?.[0]?.Products;
  const inboundLegs = InboundJourneys?.[0]?.Legs.sort((a, b) => a - b) || [];
  const inboundProducts = InboundJourneys?.[0]?.Products;

  let showLounge = isOutbound ? outboundLegs : inboundLegs;

  if (showLounge?.[activeBus - 1]?.SeatPlan?.Buses?.[0]?.Floors?.[0]) {
    showLounge = showLounge[
      activeBus - 1
    ]?.SeatPlan.Buses[0].Floors[0].SeatRows.some(row => {
      return row.Seats.some(seat => seat.SeatClass === 'SEAT_CLASS.A');
    });
  } else {
    showLounge = false;
  }

  useEffect(() => {
    const isAllValid = [...outboundLegs, ...inboundLegs]?.every(leg =>
      leg.SeatInfo?.every(passenger => !!passenger.SeatNumber)
    );

    dispatch({
      type: 'UPDATE_TICKETS',
      payload: {
        currentStepDisabled: !isAllValid,
      },
    });
  }, [outboundLegs.length, inboundLegs.length]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_TICKETS',
      payload: {
        currentStepDisabled:
          loading || updatePriceLoading || getAvailableLoading || basketLoading,
      },
    });
  }, [loading, updatePriceLoading, getAvailableLoading, basketLoading]);

  return (
    <div className={cx(styles.seatDetails, className)}>
      <DiscardedBasket
        isOpen={isOpenModal}
        toggleModal={toggleModal}
        url={`/tickets/search${lastSearch}`}
      />
      <Container className={styles.breadcrumbs}>
        <Container full={false}>
          <section>
            <div className={styles.item} onClick={toggleModal}>
              {t('search')}
            </div>
            <div className={cx(styles.item, styles.active)}>
              <i className={i.arrowBottom} />
              1.&nbsp;<span>{t('seat selection')}</span>
            </div>
            <div className={styles.item}>
              <i className={i.arrowBottom} />
              2.&nbsp;<span>{t('passengers details')}</span>
            </div>
            {hasTransfers && (
              <div className={styles.item}>
                <i className={i.arrowBottom} />
                3.&nbsp;<span>{t('extras')}</span>
              </div>
            )}
            <div className={styles.item}>
              <i className={i.arrowBottom} />
              {hasTransfers ? '4' : '3'}.&nbsp;
              <span>{t('payment details')}</span>
            </div>
            {/* <Link
              to="/tickets/seat-details/"
              className={cx(styles.item, styles.active)}
            >
              <i className={i.arrowBottom} />
              1.&nbsp;<span>{t('seat selection')}</span>
            </Link>
            <Link to="/tickets/passenger-details/" className={styles.item}>
              <i className={i.arrowBottom} />
              2.&nbsp;<span>{t('passangers details')}</span>
            </Link>
            <Link to="/tickets/payment/credit-card/" className={styles.item}>
              <i className={i.arrowBottom} />
              3.&nbsp;<span>{t('payment details')}</span>
            </Link> */}
          </section>
        </Container>
      </Container>
      <Container className={styles.passangers}>
        <Container full={false}>
          <section>
            <div
              className={cx(
                styles.tabs,
                // !inbound.selected && styles.one,
                activeTab === 1 && styles.activeOne,
                activeTab === 2 && styles.activeTwo,
                !!inboundLegs?.length && styles.two,
                isOpenDropDown && styles.openDropDown
              )}
            >
              <div className={styles.tabPanel}>
                <div className={styles.line} />
                <div
                  onClick={() => changeTab(1)}
                  className={cx(styles.tab, activeTab === 1 && styles.active)}
                >
                  <span>
                    {t(from.CityName)}, {t(from.CountryName)}
                  </span>
                  <i className={i.arrowRight_v3} />
                  <span>
                    {t(to.CityName)}, {t(to.CountryName)}
                  </span>
                </div>
                {tickets.inbound.selected && (
                  <div
                    onClick={() => changeTab(2)}
                    className={cx(styles.tab, activeTab === 2 && styles.active)}
                  >
                    <span>
                      {t(to.CityName)}, {t(to.CountryName)}
                    </span>
                    <i className={i.arrowRight_v3} />
                    <span>
                      {t(from.CityName)}, {t(from.CountryName)}
                    </span>
                  </div>
                )}
              </div>
              <div className={styles.tabsContainer}>
                <div className={styles.tabsContents}>
                  <div className={styles.tabsContent}>
                    <SeatTypes
                      showLounge={showLounge}
                      className={cx(styles.seatTypes, !isMob && styles.show)}
                    />
                    <div className={styles.info}>
                      {loading ? (
                        <Loading className={styles.loading} />
                      ) : (
                        <>
                          <div className={styles.autobus}>
                            {!isMob &&
                              !!outboundLegs?.length &&
                              outboundLegs
                                .sort((a, b) => a.OrderNumber - b.OrderNumber)
                                .map((leg, key) => {
                                  const {
                                    CanChooseSeatNumber,
                                  } = leg.SeatPlan.Buses[0];
                                  const isShowBus = key + 1 === activeBus;
                                  return (
                                    isShowBus &&
                                    (CanChooseSeatNumber ? (
                                      <Bus leg={leg} key={key} />
                                    ) : (
                                      <div className={styles.noSeatsBus}>
                                        <div className={styles.texts}>
                                          <div className={styles.title}>
                                            {t('seats selection')}
                                          </div>
                                          <div className={styles.subTitle}>
                                            {t(
                                              'seats selection is not available.'
                                            )}
                                          </div>
                                        </div>
                                        <img
                                          src="/images/svg/no-seats-bus.svg"
                                          alt=""
                                        />
                                      </div>
                                    ))
                                  );
                                })}
                          </div>
                          <div className={styles.ticketInfo}>
                            <Notice className={styles.notice}>
                              {t('tickets.notice1')}
                              <br />
                              {t('tickets.notice2')}
                            </Notice>
                            {!!outboundLegs?.length &&
                              outboundLegs
                                .sort((a, b) => a.OrderNumber - b.OrderNumber)
                                .map((leg, key) => {
                                  const {
                                    CanChooseSeatNumber,
                                  } = leg.SeatPlan.Buses[0];
                                  return (
                                    <Transfer
                                      canChooseSeatNumber={CanChooseSeatNumber}
                                      orderNumber={leg.OrderNumber}
                                      products={outboundProducts}
                                      legsCount={outboundLegs.length}
                                      isTransfer={outboundLegs.length > 1}
                                      leg={leg}
                                      key={key}
                                      active={key + 1 === activeBus}
                                    />
                                  );
                                })}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={styles.tabsContent}>
                    <SeatTypes
                      showLounge={showLounge}
                      className={cx(styles.seatTypes, !isMob && styles.show)}
                    />
                    <div className={styles.info}>
                      {loading ? (
                        <Loading className={styles.loading} />
                      ) : (
                        <>
                          <div className={styles.autobus}>
                            {!isMob &&
                              inboundLegs?.length &&
                              inboundLegs
                                .sort((a, b) => a.OrderNumber - b.OrderNumber)
                                .map((leg, key) => {
                                  const {
                                    CanChooseSeatNumber,
                                  } = leg.SeatPlan.Buses[0];
                                  const isShowBus = key + 1 === activeBus;
                                  return (
                                    isShowBus &&
                                    (CanChooseSeatNumber ? (
                                      <Bus leg={leg} key={key} />
                                    ) : (
                                      <div className={styles.noSeatsBus}>
                                        <div className={styles.texts}>
                                          <div className={styles.title}>
                                            {t('seats selection')}
                                          </div>
                                          <div className={styles.subTitle}>
                                            {t(
                                              'seats selection is not available.'
                                            )}
                                          </div>
                                        </div>
                                        <img
                                          src="/images/svg/no-seats-bus.svg"
                                          alt=""
                                        />
                                      </div>
                                    ))
                                  );
                                })}
                          </div>
                          <div className={styles.ticketInfo}>
                            <Notice className={styles.notice}>
                              {t('tickets.notice1')}
                              <br />
                              {t('tickets.notice2')}
                            </Notice>
                            {!!inboundLegs?.length &&
                              inboundLegs
                                .sort((a, b) => a.OrderNumber - b.OrderNumber)
                                .map((leg, key) => {
                                  const {
                                    CanChooseSeatNumber,
                                  } = leg.SeatPlan.Buses[0];
                                  return (
                                    <Transfer
                                      canChooseSeatNumber={CanChooseSeatNumber}
                                      orderNumber={leg.OrderNumber}
                                      products={inboundProducts}
                                      legsCount={inboundLegs.length}
                                      isTransfer={inboundLegs.length > 1}
                                      leg={leg}
                                      key={key}
                                      active={key + 1 === activeBus}
                                    />
                                  );
                                })}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isTabDown ? (
              <TripDetails
                loading={currentStepDisabled}
                onContinueClick={handleNextStep}
                disabledNextStep={currentStepDisabled}
              />
            ) : (
              <div className={styles.tripDetails}>
                <TripDetails
                  loading={currentStepDisabled}
                  onContinueClick={handleNextStep}
                  disabledNextStep={currentStepDisabled}
                />
              </div>
            )}
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withRouter(withTranslation()(SeatDetails));
