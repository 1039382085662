import AboutUs from './about-us';
import AllDestinations from './all-destinations';
import AllRoutes from './all-routes';
import Login from './login';
import Blog from './blog';
// import Career from './career'
import Contacts from './contacts';
import TransportationRules from './transportation-rules';
// import CreateArticle from './create-article'
// import CreateDestination from './create-destination'
// import CreateRoute from './create-route'
import CustomsRegulations from './customs-regulations';
import Destination, { CreateDestination } from './destination';
import Discounts from './discounts';
import Faq from './faq';
import FaqTopic from './faq-topic';
import FaqAnswer from './faq-answer';
import SearchFaq from './search';
import Home from './home';
// import Info from './info'
// import LostItem from './lost-item'
import {
  ManageBooking,
  Trips as PublicTrips,
  Refund as PublicRefund,
} from './manage-booking';
// import NeoPay from './neopay';
import News, { CreateNews } from './news';
import PrivacyPolicy from './privacy-policy';
import Promo, { CreatePromo } from './promo';
import Registration from './registration';
import ResetPassword from './reset-password';
import Route, { CreateRoute } from './route';
import TicketSalesRules from './ticket-sales-rules';
import {
  PassengerDetails,
  Payment,
  PaymentCard,
  PaymentCorporateClient,
  SeatDetails,
  SearchTickets,
  Thanks,
  Transfer,
} from './tickets';
import {
  PassengerDetails as ChangePassengerDetails,
  Payment as ChangePayment,
  PaymentCard as ChangePaymentCard,
  PaymentCorporateClient as ChangePaymentCorporateClient,
  SeatDetails as ChangeSeatDetails,
  SearchTickets as ChangeSearchTickets,
  Thanks as ChangeThanks,
  Transfer as ChangeTransfer,
} from './change-tickets';
import Seats from './seats';
import Profile, {
  PaymentMethods,
  PersonalInfo,
  Security,
  Trips,
  Settings,
  Refund,
  Vouchers,
  CustomerProgram,
} from './profile';
import Services from './services';
// import TicketRefund from './ticket-refund'
import VerifyCode from './verify-code';
import Tudengiralli from './tudengiralli';
// import NotFound from './not-found';

const routes = ({ isStaff, authorized }) => [
  ...(isStaff
    ? [
        {
          path: '/create-destination',
          component: CreateDestination,
          multi: true,
          pageName: 'destination',
        },
        {
          path: '/create-route',
          component: CreateRoute,
          multi: true,
          pageName: 'route',
        },
        {
          path: '/create-news',
          component: CreateNews,
          multi: true,
          pageName: 'news',
        },
        {
          path: '/create-promo',
          component: CreatePromo,
          multi: true,
          pageName: 'promo',
        },
      ]
    : []),
  ...(authorized
    ? [
        {
          path: '/profile',
          component: Profile,
          // pageName: 'profile',
        },
        {
          path: '/profile/customer-program',
          component: CustomerProgram,
          // pageName: 'profile',
        },
        {
          path: '/profile/security',
          component: Security,
          // pageName: 'profile',
        },
        {
          path: '/profile/trips',
          component: Trips,
          // pageName: 'tickets',
        },
        {
          path: '/profile/payment-methods',
          component: PaymentMethods,
          // pageName: 'payment-methods',
        },
        {
          path: '/profile/personal-info',
          component: PersonalInfo,
          // pageName: 'personal-info',
        },
        {
          path: '/profile/settings',
          component: Settings,
          // pageName: 'personal-info',
        },
        {
          path: '/profile/trips/refund',
          component: Refund,
          // pageName: 'personal-info',
        },
        {
          path: '/profile/vouchers',
          component: Vouchers,
          // pageName: 'personal-info',
        },
      ]
    : []),
  // {
  //   path: '/neopay',
  //   component: NeoPay,
  //   isStatic: true,
  //   pageName: 'neopay',
  // },
  {
    path: '/change-passw',
    component: ResetPassword,
    isStatic: true,
    pageName: 'change-passw',
  },
  {
    path: '/about-us',
    component: AboutUs,
    isStatic: true,
    pageName: 'about-us',
  },
  {
    path: '/tudengiralli',
    component: Tudengiralli,
    isStatic: true,
    pageName: 'tudengiralli',
  },
  {
    path: '/all-destinations',
    component: AllDestinations,
    isStatic: true,
  },
  {
    path: '/all-routes',
    component: AllRoutes,
    isStatic: true,
  },
  {
    path: '/registration',
    component: Registration,
    isStatic: true,
  },
  {
    path: '/login',
    component: Login,
    isStatic: true,
  },
  {
    path: '/blog',
    component: Blog,
    isStatic: true,
  },
  {
    path: '/manage-booking',
    component: ManageBooking,
    isStatic: true,
  },
  {
    path: '/manage-booking/trips',
    component: PublicTrips,
    isStatic: true,
  },
  {
    path: '/manage-booking/trips/refund',
    component: PublicRefund,
    isStatic: true,
  },
  // {
  //   path: '/career',
  //   component: Career,
  //   isStatic: true,
  // },
  {
    path: '/contacts',
    component: Contacts,
    isStatic: true,
  },
  {
    path: '/transportation-rules',
    component: TransportationRules,
    isStatic: true,
  },
  {
    path: '/customs-regulations',
    component: CustomsRegulations,
    isStatic: true,
  },
  {
    path: '/destinations/:destination',
    component: Destination,
    pageName: 'destination',
  },
  {
    path: '/discounts',
    component: Discounts,
    isStatic: true,
    pageName: 'discounts',
  },
  {
    path: '/faq',
    component: Faq,
    isStatic: true,
  },
  {
    path: '/faq/:topic',
    component: FaqTopic,
    isStatic: true,
  },
  {
    path: '/faq/:topic/:question',
    component: FaqAnswer,
    isStatic: true,
  },
  {
    path: '/search',
    component: SearchFaq,
    isStatic: true,
    pageName: 'search',
  },
  {
    path: '/',
    component: Home,
    isStatic: true,
    pageName: 'home',
  },
  // {
  //   path: '/info',
  //   component: Info,
  //   isStatic: true,
  //   pageName: 'info'
  // },
  // {
  //   path: '/lost-item',
  //   component: LostItem,
  // },
  {
    path: '/news/:news',
    component: News,
    pageName: 'news',
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    isStatic: true,
  },
  {
    path: '/promo/:promo',
    component: Promo,
    pageName: 'promo',
  },
  {
    path: '/routes/:route',
    component: Route,
    pageName: 'route',
  },
  {
    path: '/ticket-sales-rules',
    component: TicketSalesRules,
    isStatic: true,
  },
  {
    path: '/tickets/passenger-details',
    component: PassengerDetails,
  },
  {
    path: '/tickets/payment',
    component: Payment,
    exact: false,
  },
  {
    path: '/tickets/payment-card',
    component: PaymentCard,
  },
  {
    path: '/tickets/payment-corporate-client',
    component: PaymentCorporateClient,
  },
  {
    path: '/tickets/search',
    component: SearchTickets,
  },
  {
    path: '/tickets/seat-details',
    component: SeatDetails,
  },
  {
    path: '/tickets/extras',
    component: Transfer,
  },
  {
    path: '/tickets/thanks',
    component: Thanks,
  },
  {
    path: '/change-tickets/passenger-details',
    component: ChangePassengerDetails,
  },
  {
    path: '/change-tickets/payment',
    component: ChangePayment,
    exact: false,
  },
  {
    path: '/change-tickets/payment-card',
    component: ChangePaymentCard,
  },
  {
    path: '/change-tickets/payment-corporate-client',
    component: ChangePaymentCorporateClient,
  },
  {
    path: '/change-tickets/search',
    component: ChangeSearchTickets,
  },
  {
    path: '/change-tickets/seat-details',
    component: ChangeSeatDetails,
  },
  {
    path: '/change-tickets/transfer',
    component: ChangeTransfer,
  },
  {
    path: '/change-tickets/thanks',
    component: ChangeThanks,
  },
  {
    path: '/seats',
    component: Seats,
    isStatic: true,
    pageName: 'seats',
  },
  {
    path: '/services',
    component: Services,
  },
  // {
  //   path: '/ticket-refund',
  //   component: TicketRefund,
  // },
  {
    path: '/verify-code',
    component: VerifyCode,
  },
  // {
  //   path: '/*',
  //   component: NotFound,
  // }
];

export NotFound from './not-found';
export default routes;
