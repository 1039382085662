import React from 'react';
import i from '@lux/spa/styles/icons.module.scss';
import s from './edit-slider.module.scss';

const EditSliderItem = ({
  dragHandleProps,
  showField,
  item,
  toggleEditSlide,
  removeSlide,
  itemFef,
  ...props
}) => (
  <div className={s.slide} {...props} ref={itemFef}>
    <div className={s.dots} {...dragHandleProps}>
      <div className={s.dot} />
      <div className={s.dot} />
      <div className={s.dot} />
      <div className={s.dot} />
      <div className={s.dot} />
    </div>
    <div className={s.title}>
      {showField
        ? item?.[showField]?.value
        : item?.title?.value || item?.alt?.value}
    </div>
    <div className={s.panel}>
      <div className={s.control} onClick={() => toggleEditSlide(item)}>
        <i className={i.edit} />
      </div>
      <div className={s.control} onClick={() => removeSlide(item)}>
        <i className={i.basket} />
      </div>
    </div>
  </div>
);

export default EditSliderItem;
