import gql from 'graphql-tag';

export const VERIFY_CODE = gql`
  mutation($input: VerifyCodeInput!) {
    verifyCode(input: $input) {
      token
      clientMutationId
      profile {
        phone
        email
        avatar
        lastName
        firstName
        isStaff
        isActive
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const PROFILE_EXISTS = gql`
  mutation($email: String!) {
    profileExists(email: $email) {
      exists
      error {
        ErrorCode
        ErrorReason
      }
    }
  }
`;
