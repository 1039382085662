import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Query } from 'react-apollo';
import { meta } from '@lux/spa/components';
import { NotFound } from '@lux/spa/routes';
import {
  GET_STATIC_PAGE,
  GET_STATIC_PAGE_ADMIN,
} from '@lux/spa/apollo/queries';
import {
  CREATE_STATIC_PAGE,
  UPDATE_STATIC_PAGE,
} from '@lux/spa/apollo/mutations';
import {
  Container,
  Divider,
  Loading,
  Text,
  Editor,
  Button,
  PageLoading,
  UploadImages,
} from '@lux/ui';
import { emptyData, defaultData } from './constants';
import s from './discounts.module.scss';

const DiscountsContent = ({
  t,
  slugs,
  region,
  language,
  updatePage,
  content,
  lang,
  isEditMode,
  isStaff,
  className,
}) => {
  const [isBannerModal, setIsBannerModal] = useState(false);

  const toggleModal = () => {
    setIsBannerModal(!isBannerModal);
  };

  const { pageData = {}, seo = {} } = content;

  const { title, banner, discounts } = pageData;

  const backgroundImage = banner.value
    ? `linear-gradient(90deg, #000000d9, transparent), url(${banner.value})`
    : `linear-gradient(90deg, #000000d9, transparent)`;

  const [createStaticPage, { data, loading }] = useMutation(CREATE_STATIC_PAGE);

  const defaultLang = 'en';

  const handleCreateStaticPage = () => {
    createStaticPage({
      variables: {
        input: {
          isPublished: true,
          slug: 'taxi',
          country: 'ee',
          [defaultLang]: {
            ...emptyData,
          },
        },
      },
    });
  };

  return (
    <div className={cn(className, s.discounts)}>
      <Helmet {...meta({ seo, lang: language, slugs })} />
      {isBannerModal && (
        <UploadImages
          isOpen={isBannerModal}
          toggleModal={toggleModal}
          updatePage={updatePage}
          image={banner}
        />
      )}
      <Container
        className={s.bannerContainer}
        style={{
          backgroundImage,
        }}
      >
        <Container full={false}>
          <section className={s.banner}>
            {isEditMode && (
              <Button
                className={s.uploadBanner}
                text={banner.value ? t('edit photo') : t('upload photo')}
                onClick={toggleModal}
              />
            )}
            <h1>
              <Text onChange={updatePage} field={title} />
            </h1>
          </section>
        </Container>
      </Container>
      <Divider />
      <Container className={s.information}>
        <div className={s.greyBlock} />
        <Container full={false}>
          <section>
            <Editor
              placeholder={t('description')}
              html={discounts}
              onChange={(event, editor) => {
                const data = editor.getData();
                updatePage({
                  discounts: data,
                });
              }}
            />
          </section>
        </Container>
      </Container>
    </div>
  );
};

const Discounts = ({
  t,
  lang,
  match,
  i18n,
  isStaff,
  region,
  isEditMode,
  page = { en: defaultData },
  dispatch = () => {},
  ...props
}) => {
  const slug = 'discounts';
  const { language } = i18n;
  const QUERY = isStaff ? GET_STATIC_PAGE_ADMIN : GET_STATIC_PAGE;

  const variables = {
    slug,
    lang: language,
    country: region,
  };

  const { data, loading } = useQuery(QUERY, { variables });

  const updatePage = field => {
    dispatch({
      type: 'UPDATE_DATA',
      payload: {
        field,
      },
    });
  };

  useEffect(() => {
    if (isStaff) {
      dispatch({
        type: 'UPDATE',
        payload: {
          defaultData,
          emptyData,
          CREATE_PAGE: CREATE_STATIC_PAGE,
          UPDATE_PAGE: UPDATE_STATIC_PAGE,
          originalPage: data?.adminStaticPage,
          page: data?.adminStaticPage,
        },
      });
    }
  }, [data]);

  // useEffect(() => {
  //   dispatch({
  //     type: 'UPDATE',
  //     payload: {
  //       loading
  //     }
  //   })
  // }, [loading])

  useEffect(() => {
    const isExistLang = page?.[lang];

    if (isEditMode && !isExistLang) {
      dispatch({
        type: 'UPDATE_PAGE',
        payload: {
          [lang]: {
            ...emptyData,
            lang,
            countries: [region],
          },
        },
      });
    }
  }, [isEditMode]);

  // const content = {
  //   ...(isStaff ? {
  //     ...emptyData,
  //     ...page?.[lang]
  //   } : {
  //     ...
  //   })
  // } : {
  //   lang: language,
  //   slug,
  //   ...data.staticPage?.data
  // })
  // }

  const isExistLangForPage = !!page?.[lang];

  const content = isStaff
    ? {
        ...defaultData,
        ...page?.[lang],
      }
    : data?.staticPage?.data;

  const slugs = {
    en: {
      value: slug,
    },
    et: {
      value: slug,
    },
    lv: {
      value: slug,
    },
    lt: {
      value: slug,
    },
    ru: {
      value: slug,
    },
  };

  return loading ? (
    <PageLoading />
  ) : content || isStaff ? (
    <DiscountsContent
      slugs={slugs}
      content={{
        ...content,
        seo: {
          ...content.seo,
          slug: {
            ...content.seo.slug,
            value: slug,
          },
        },
        pageData: {
          ...defaultData.pageData,
          ...content.pageData,
        },
      }}
      {...{ updatePage, language, isEditMode, isStaff, region, t }}
      {...props}
    />
  ) : (
    <NotFound />
  );
};

export default withTranslation()(Discounts);
