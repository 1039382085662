import React, { useState, useContext, useEffect } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { StoreContext } from '@lux/spa/store';
import { MODIFICATION_INFO, CALCULATE_RETURN } from '@lux/spa/apollo/mutations';
import { useMutation } from '@apollo/react-hooks';
import { Button, TicketsInput, Notice, Link, Loading } from '@lux/ui';
import { Info } from '../components';
import s from '../refund.module.scss';

const Email = ({ t, i18n, goToStep, dataRefund, setDataRefund }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { isPublic } = state.tickets.refund;
  const { language } = i18n;
  const [isSecondCalculatedCall, setIsSecondCalculatedCall] = useState(false);

  const [calculatedReturn, { loading: loadingCalculatedReturn }] = useMutation(
    CALCULATE_RETURN,
    {
      onError: data => {
        console.error('Error: ', data);
      },
      onCompleted: data => {
        if (data.calculateReturn.error) {
          toast.error(data.calculateReturn.error.ErrorReason);
        } else {
          const { returnInfo } = data.calculateReturn;
          const paymentTypes = data.calculateReturn.returnInfo.PaymentDetails.map(
            item => item.PaymentType
          );

          const isOnlyOneWay =
            data.calculateReturn.returnInfo.RefundableProducts[0]
              .RefundingScopes.length === 1;
          setDataRefund(prev => {
            return {
              ...prev,
              ...(!isSecondCalculatedCall
                ? {
                    returnInfoDefault: returnInfo,
                  }
                : {
                    returnInfoVoucher: returnInfo,
                  }),
              isOnlyOneWay,
              selectedWay: returnInfo.RefundableProducts[0].RefundingScopes[0],
            };
          });

          if (
            !paymentTypes.includes('CLIENT_CONTRACT_CARD') &&
            !isSecondCalculatedCall
          ) {
            const { Passengers, ShoppingBasketId } = dataRefund.ways[0];

            const ProductCodes = [Passengers[0].ProductCode];

            setIsSecondCalculatedCall(true);

            calculatedReturn({
              variables: {
                ProductCodes,
                ShoppingBasketId,
                lang: language,
                refundPaymentMethod: 'Voucher',
              },
            });
            return;
          }

          if (
            data.calculateReturn.returnInfo.RefundableProducts[0]
              .RefundingScopes.length === 1
          ) {
            goToStep(4);
          } else {
            goToStep(3);
          }
        }
      },
    }
  );

  const [modificationInfo, { loading }] = useMutation(MODIFICATION_INFO, {
    onErorr: () => {
      toast.error(t('something wrong'));
    },
    onCompleted: data => {
      const { error } = data.modificationInfo;
      if (error) {
        toast.error(error.ErrorReason);
      } else {
        if (data.modificationInfo.ways.length) {
          const { Passengers } = data.modificationInfo.ways[0];

          const ProductCodes = [Passengers[0].ProductCode];

          const isOnlyOnePass = Passengers.length === 1;

          setDataRefund({
            ways: data.modificationInfo.ways,
            basketData: data.modificationInfo.basketData,
            isOnlyOnePass,
            ...(isOnlyOnePass
              ? {
                  selectedPassengersCodes: [Passengers[0]],
                }
              : {}),
          });

          if (isOnlyOnePass) {
            calculatedReturn({
              variables: {
                ProductCodes,
                ShoppingBasketId:
                  data.modificationInfo.ways[0].ShoppingBasketId,
                lang: language,
                refundPaymentMethod: 'Default',
              },
            });
          } else {
            goToStep(2);
          }

          return;
        }

        toast.error(t('something wrong'));
      }
    },
  });
  const { user, tickets } = state;
  const { email } = user;
  const { refund } = tickets;
  const { ticketNumber } = refund;

  useEffect(() => {
    if (refund.isPublic) {
      modificationInfo({
        variables: {
          Email: refund.email,
          TicketNumber: refund.ticketNumber,
          lang: language,
        },
      });
    }
  }, [refund.isPublic]);

  return (
    <div className={cn(s.step, s.email)}>
      <div className={s.content}>
        <h3>{t('refund tickets')}</h3>

        {!refund.isPublic ? (
          <>
            <p>
              {t(
                'please enter the email that was used to purchase these tickets'
              )}
            </p>
            <Formik
              validateOnMount
              enableReinitialize
              initialValues={{
                email: email || '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email(
                    t('validation.error.email', {
                      field: t('email'),
                    })
                  )
                  .required(
                    t('validation.error.require', {
                      field: t('email'),
                    })
                  ),
              })}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  setFieldError,
                } = props;
                return (
                  <form className={s.form}>
                    <div className={s.input}>
                      <TicketsInput
                        autoFocus
                        name="email"
                        label={t('email')}
                        placeholder={t('enter your email')}
                        type="text"
                        required
                        value={values.email}
                        onChange={e => {
                          e.target.value = e.target.value.trim();
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        errors={touched.email && errors.email}
                      />
                    </div>
                    <Notice
                      className={s.notice}
                      text={t(
                        'your email does not match the one you used to purchase this ticket.'
                      )}
                    />

                    <div className={s.controls}>
                      <Button
                        disabled={
                          loading ||
                          loadingCalculatedReturn ||
                          errors.email ||
                          errors.password
                        }
                        className={s.button}
                        text={
                          loading || loadingCalculatedReturn
                            ? t('loading')
                            : t('continue')
                        }
                        onClick={() => {
                          modificationInfo({
                            variables: {
                              Email: values.email,
                              TicketNumber: ticketNumber,
                              lang: language,
                            },
                          });
                        }}
                      />
                      <Link
                        to={
                          isPublic
                            ? '/manage-booking/trips/'
                            : '/profile/trips/'
                        }
                        className={s.cancel}
                        disabled={loading}
                      >
                        {t('cancel')}
                      </Link>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </>
        ) : (
          <Loading />
        )}
      </div>
      <Info dataRefund={dataRefund} />
    </div>
  );
};

export default withTranslation()(Email);
