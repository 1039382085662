import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import {
  // CREATE_NEWS,
  UPDATE_NEWS,
} from '@lux/spa/apollo/mutations';
import { GET_NEWS_ADMIN, GET_NEWS } from '@lux/spa/apollo/queries';
import { PageLoading } from '@lux/ui';
import { NotFound } from '@lux/spa/routes';
import NewsContent from './news-content';
import { emptyData, defaultData } from './constants';

const News = ({
  region,
  lang,
  match,
  i18n,
  isStaff,
  isEditMode,
  page = { en: emptyData },
  dispatch = () => {},
  ...props
}) => {
  const { news } = match.params;
  const { language } = i18n;
  const QUERY = isStaff ? GET_NEWS_ADMIN : GET_NEWS;

  // const [slugs, setSlugs] = useState({});

  const { data, loading = true, errors } = useQuery(QUERY, {
    errorPolicy: 'ignore',
    variables: {
      lang: language,
      slug: news,
    },
  });

  const updatePage = field => {
    dispatch({
      type: 'UPDATE_DATA',
      payload: {
        field,
      },
    });
  };

  useEffect(() => {
    if (isStaff) {
      dispatch({
        type: 'UPDATE',
        payload: {
          defaultData,
          emptyData,
          UPDATE_PAGE: UPDATE_NEWS,
          // CREATE_PAGE: CREATE_NEWS,
          originalPage: data?.adminNews,
          page: data?.adminNews,
        },
      });
    }
  }, [data]);

  const idNews = isStaff ? page?.id : data?.news?.id;

  const content = isStaff ? page?.[lang] : data?.news?.data;

  const type = 'news';
  let slugs = {};

  if (!isStaff) {
    slugs = data?.news?.slugs;
  } else if (data?.adminNews) {
    Object.entries(data?.adminNews).map(([key, value]) => {
      if (key.length == 2 && typeof value == 'object') {
        slugs[key] = value?.slug ? value.slug : null;
      }
    });
  }

  if (slugs && typeof localStorage !== 'undefined') {
    localStorage.setItem(
      'location',
      JSON.stringify({
        type,
        slugs,
      })
    );
  }

  return loading ? (
    <PageLoading />
  ) : content ? (
    <NewsContent
      slugs={slugs}
      type={type}
      content={{
        ...content,
        seo: {
          ...content.seo,
          slug: {
            ...content.seo.slug,
            value: `${type}/${content.seo.slug.value}`,
          },
        },
        pageData: {
          ...emptyData.pageData,
          ...content.pageData,
        },
      }}
      {...{
        idNews,
        updatePage,
        language,
        isEditMode,
        isStaff,
        region,
      }}
      {...props}
    />
  ) : (
    <NotFound />
  );
};

export default withTranslation()(News);
