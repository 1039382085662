import React from 'react';
import { withTranslation } from 'react-i18next';
import s from './faq-search.module.scss';

const FaqSearchNoMatch = ({ t, text }) => (
  <ul className={s.list}>
    <li className={s.item}>
      <b>{t('no matches for')}</b> "{text}"
    </li>
  </ul>
);

export default withTranslation()(FaqSearchNoMatch);
