import gql from 'graphql-tag';

export const CREATE_NEWS = gql`
  mutation($input: NewsInput) {
    createNews(input: $input) {
      news {
        id
        publishDate
        isPublished
        en {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        fi {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        et {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        ru {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        lv {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        lt {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
      }
    }
  }
`;

export const UPDATE_NEWS = gql`
  mutation($input: NewsInput) {
    updateNews(input: $input) {
      news {
        id
        publishDate
        isPublished
        en {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        fi {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        et {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        ru {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        lv {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        lt {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
      }
    }
  }
`;

export const REMOVE_NEWS = gql`
  mutation($id: ID!) {
    deleteNews(id: $id) {
      success
    }
  }
`;
