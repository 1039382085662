import gql from 'graphql-tag';

export const GET_STATIC_PAGE = gql`
  query($slug: String!, $country: String!, $lang: String) {
    staticPage(lang: $lang, country: $country, slug: $slug) {
      id
      isPublished
      country
      slug
      data {
        seo
        lang
        countries
        pageData
        isPublished
      }
    }
  }
`;

export const GET_STATIC_PAGE_ADMIN = gql`
  query($slug: String!, $country: String!, $lang: String) {
    adminStaticPage(lang: $lang, country: $country, slug: $slug) {
      id
      isPublished
      slug
      country
      en {
        seo
        lang
        countries
        pageData
        isPublished
      }
      et {
        seo
        lang
        countries
        pageData
        isPublished
      }
      ru {
        seo
        lang
        countries
        pageData
        isPublished
      }
      fi {
        seo
        lang
        countries
        pageData
        isPublished
      }
      lv {
        seo
        lang
        countries
        pageData
        isPublished
      }
      lt {
        seo
        lang
        countries
        pageData
        isPublished
      }
      pl {
        seo
        lang
        countries
        pageData
        isPublished
      }
    }
  }
`;
