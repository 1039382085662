import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { YOO_MONEY_PAYMENT_DETAILS } from '@lux/spa/apollo/mutations';
import { StoreContext } from '@lux/spa/store';
import { createThanksSuccessAnaliticsData } from 'utils';
import ThanksLayoutSuccess from '../../thanks-layout-success.jsx';
import ThanksLayoutPending from '../../thanks-layout-pending.jsx';
import ThanksLayoutError from '../../thanks-layout-error.jsx';

const Yookassa = ({
  getTicketsFileUrl,
  fileLoading,
  urlParams,
  linkToPDFFile,
}) => {
  const [adyenPaymentDetails, { loading = true }] = useMutation(
    YOO_MONEY_PAYMENT_DETAILS
  );
  const [isError, setIsError] = useState(false);

  const [state, dispatch] = useContext(StoreContext);

  const {
    from,
    to,
    returnDate,
    departDate,
    promocode,
    totalPrice,
    forAnalitics,
    GTM,
  } = state.tickets;
  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';
  const { GTMLoaded } = state.app;

  useEffect(() => {
    const { tickets } = state;
    const shopperOrder = tickets?.products?.Basket?.ShoppingBasketId;

    if (shopperOrder) {
      adyenPaymentDetails({
        variables: {
          orderId: shopperOrder,
        },
      })
        .then(data => {
          if (data?.data?.yooMoneyPaymentDetails?.error) {
            setIsError(true);
          }

          if (data?.data?.yooMoneyPaymentDetails?.success) {
            if (GTMLoaded && !GTM.thanksStep) {
              const analyticData = createThanksSuccessAnaliticsData({
                returnDate,
                departDate,
                from,
                to,
                currencyName,
                totalPrice,
                promocode,
                objects: data?.data?.yooMoneyPaymentDetails?.objects,
              });
              console.log('analytics data', analyticData);
              dataLayer.push(analyticData);

              dispatch({
                type: 'UPDATE_TICKETS',
                payload: {
                  GTM: {
                    ...GTM,
                    thanksStep: true,
                  },
                },
              });
            }

            getTicketsFileUrl();
          }
        })
        .catch(() => {
          setIsError(true);
        });
    }
  }, []);

  return loading ? (
    <ThanksLayoutPending />
  ) : isError ? (
    <ThanksLayoutError renderCancel />
  ) : (
    <ThanksLayoutSuccess
      type="card"
      linkToPDFFile={linkToPDFFile}
      fileLoading={fileLoading}
      getTicketsFileUrl={getTicketsFileUrl}
    />
  );
};

export default Yookassa;
