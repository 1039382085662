import React from 'react';
import cn from 'classnames';
import { Link } from '@lux/ui';
import s from './destination.module.scss';

const Destination = ({ className, destination, number, t }) => {
  const img = destination.data.seo.thumbnail?.value;
  const city = destination.city.name;
  const slug = destination.data.seo.slug.value;

  return (
    <Link
      to={`/destinations/${slug}/`}
      className={cn(s.destination, className)}
    >
      <div
        className={s.image}
        style={{
          backgroundImage: `url(${img})`,
        }}
      />
      <div className={s.nameDirections}>{t(city)}</div>
      <div>{t('discover')}</div>
    </Link>
  );
};

export default Destination;
