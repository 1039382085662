import gql from 'graphql-tag';

export const CREATE_STATIC_WIDGET = gql`
  mutation($input: StaticWidgetInput) {
    createStaticWidget(input: $input) {
      staticWidget {
        id
        name
        en {
          lang
          countries
          widgetData
        }
        et {
          lang
          countries
          widgetData
        }
        ru {
          lang
          countries
          widgetData
        }
        fi {
          lang
          countries
          widgetData
        }
        lt {
          lang
          countries
          widgetData
        }
        lv {
          lang
          countries
          widgetData
        }
      }
    }
  }
`;

export const UPDATE_STATIC_WIDGET = gql`
  mutation($input: StaticWidgetInput) {
    updateStaticWidget(input: $input) {
      staticWidget {
        id
        name
        en {
          lang
          countries
          widgetData
        }
        et {
          lang
          countries
          widgetData
        }
        ru {
          lang
          countries
          countries
          widgetData
        }
        fi {
          lang
          countries
          widgetData
        }
        lt {
          lang
          countries
          widgetData
        }
        lv {
          lang
          countries
          widgetData
        }
      }
    }
  }
`;

export const UPDATE_ORDER_WIDGET = gql`
  mutation($input: OrderWidgetFormMutationInput!) {
    orderWidget(input: $input) {
      errors {
        messages
        field
      }
      orderWidget {
        id
        country
        lang
        origin
        type
        ids
      }
    }
  }
`;
