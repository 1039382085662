import gql from 'graphql-tag';

export const CREATE_PROMO = gql`
  mutation($input: PromoPageInput) {
    createPromoPage(input: $input) {
      promoPage {
        id
        publishDate
        isPublished
        en {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        fi {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        et {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        ru {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        lv {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        lt {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
      }
    }
  }
`;

export const UPDATE_PROMO = gql`
  mutation($input: PromoPageInput) {
    updatePromoPage(input: $input) {
      promoPage {
        id
        publishDate
        isPublished
        en {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        fi {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        et {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        ru {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        lv {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        lt {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
      }
    }
  }
`;

export const REMOVE_PROMO = gql`
  mutation($id: ID!) {
    deletePromoPage(id: $id) {
      success
    }
  }
`;
