import React, { useRef, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Query } from 'react-apollo';
import { GET_SUGGESTIONS } from '@lux/spa/apollo/queries';
import { Modal, ModalHeader, ModalContent, Input, Loading } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './search-destination-mobile.module.scss';

const SearchDestinationMobile = ({
  t,
  isOpen,
  toggleModal,
  language,
  modalTitle,
  search = '',
  updateSearch,
  selectItem,
  popularDestinations = [],
  selectedStop,
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      // TODO: try pass ref to input
      inputRef.current.getElementsByTagName('input')[0].focus();
    }
  });

  return (
    <Modal className={s.modal} {...{ isOpen, toggleModal }}>
      <ModalHeader text={modalTitle} />
      <ModalContent withoutHeaderHeight>
        <div className={s.input} ref={inputRef}>
          <Input
            value={search}
            label={t('country, city or airport')}
            onChange={e => updateSearch(e)}
          />
        </div>
        <Query
          query={GET_SUGGESTIONS}
          variables={{
            cityName: search,
            lang: language,
          }}
          skip={search.length < 3}
        >
          {({ loading, error, data = { cities: [] } }) => {
            if (loading) {
              return (
                <div className={s.suggestions}>
                  <Loading />
                </div>
              );
            }

            return (
              <div className={s.suggestions}>
                {data.cities.length ? (
                  data.cities.map(({ CityId, CityName, BusStops }) => (
                    <>
                      <div className={s.townCountry} key={CityId}>
                        <i className={i.point} />
                        {CityName}
                      </div>
                      {BusStops.map(
                        ({ BusStopId, BusStopName, CountryName }) => {
                          const icon = BusStopName.includes('airplane')
                            ? 'airplane'
                            : 'bus';
                          return (
                            <div
                              key={BusStopId}
                              className={s.item}
                              onClick={() => {
                                selectItem(
                                  {
                                    BusStopName,
                                    BusStopId,
                                    CountryName,
                                    CityName,
                                  },
                                  data.cities
                                );
                                toggleModal();
                              }}
                            >
                              <i className={i[icon]} />
                              <span>{BusStopName}</span>
                            </div>
                          );
                        }
                      )}
                    </>
                  ))
                ) : (
                  <>
                    {search.length >= 3 && (
                      <>
                        <div className={s.townCountry}>
                          {t('search results')}
                        </div>
                        <span className={s.item}>
                          {t('no matches for')} «<b>{search}</b>»
                        </span>
                      </>
                    )}
                    <div className={s.townCountry}>
                      {t('popular destinations')}
                    </div>
                    {popularDestinations.map(({ CityId, CityName, BusStops }) =>
                      BusStops.map(
                        ({ BusStopId, BusStopName, CountryName }) => {
                          const icon = BusStopName.includes('airplane')
                            ? 'airplane'
                            : 'bus';
                          return (
                            selectedStop !== BusStopId && (
                              <div
                                key={BusStopId}
                                className={s.item}
                                onClick={() => {
                                  selectItem(
                                    {
                                      BusStopName,
                                      BusStopId,
                                      CountryName,
                                      CityName,
                                    },
                                    data.cities
                                  );
                                  toggleModal();
                                }}
                              >
                                <i className={i[icon]} />
                                <span>{BusStopName}</span>
                              </div>
                            )
                          );
                        }
                      )
                    )}
                  </>
                )}
              </div>
            );
          }}
        </Query>
      </ModalContent>
    </Modal>
  );
};

export default withTranslation()(SearchDestinationMobile);
