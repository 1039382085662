import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { SEND_SMS, VERIFY_CODE } from '@lux/spa/apollo/mutations';
import { Container, Divider, Input, Button, Loading } from '@lux/ui';
import s from './verify-code.module.scss';

const VerifyCode = ({ className }) => {
  const client = useApolloClient();
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');

  const [verifyCode, { data = {}, loading }] = useMutation(VERIFY_CODE);

  const token = data?.verifyCode?.token;

  const redirectTo = token ? '' : 'registration';

  const setAuth = () => {
    localStorage.setItem('token', token);
    // client.writeData({
    //   data: {
    //     auth: true
    //   }
    // })
  };

  useEffect(() => {
    const phone = localStorage.getItem('phone');
    const code = localStorage.getItem('code');
    setPhone(phone);
    setCode(code);
  }, []);

  return (
    <div className={cn(className, s.verifyCode)}>
      <Container>
        <Container full={false}>
          <section>
            <h2 className={s.title}>Verify code</h2>
            {/* <Mutation
              mutation={VERIFY_CODE}
              variables={{ input: { code, phone } }}
              >
              {(verifyCode, { data = {}, loading }) => {
                if (loading) return <Loading />
                const token = data?.verifyCode?.token

                if (token) {
              localStorage.setItem('token', token)
              client.writeData({
              data: {
              auth: true
              }
              })
            } */}

            {loading ? (
              <Loading />
            ) : token ? (
              <Redirect to={redirectTo} />
            ) : (
              <div className={s.form}>
                <h5>Please send this code: {code}</h5>
                <Input
                  value={code}
                  onChange={e => setCode(e.target.value)}
                  className={s.input}
                  type="number"
                  label="Code"
                />
                <Button
                  onClick={() =>
                    verifyCode({
                      variables: {
                        input: {
                          phone: localStorage.getItem('phone'),
                          code: localStorage.getItem('code'),
                        },
                      },
                    })
                  }
                  text="Send Code"
                />
              </div>
            )}
            {/* }}
            </Mutation> */}
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default VerifyCode;
