import React, { useContext, useState } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet-async';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { withTranslation } from 'react-i18next';
import { Container, Divider, Link, Loading, LazyLoadImage } from '@lux/ui';
import { Collapse } from 'react-collapse';
import { Search, meta } from '@lux/spa/components';
import { Query } from 'react-apollo';
import { GET_ROUTES_GROUPED } from '@lux/spa/apollo/queries';
import i from '@lux/spa/styles/icons.module.scss';
import s from './all-routes.module.scss';

const CollapseCountry = ({ t, country }) => {
  const { countryName, routes } = country;
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={s.country}>
      <h3 className={s.name} onClick={toggleOpen}>
        <div className={s.flag}>
          <LazyLoadImage
            src={`/images/svg/flags/flag-${countryName.toLowerCase()}.svg`}
            alt=""
          />
        </div>
        <div>{t(countryName)}</div>
        <i className={isOpen ? i.arrowTop : i.arrowBottom} />
      </h3>
      <Collapse isOpened={isOpen}>
        <div className={s.roadConnections}>
          {routes.map((route, key) => {
            const departure = route.departure.name;
            const arrival = route.arrival.name;
            const slug = route.data.seo.slug.value;
            return (
              <Link key={key} to={`/routes/${slug}/`} className={s.direction}>
                <div>{t(departure)}</div>
                <LazyLoadImage src="/images/svg/road-connection.svg" alt="" />
                <div>{t(arrival)}</div>
              </Link>
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};

const AllRoutes = ({ className, region, i18n, t }) => {
  const { language } = i18n;
  const { isMob } = useContext(WidthContext);

  const slug = 'all-routes';

  let seo = {
    et: {
      title: {
        value: 'Rahvusvaheliste bussiliinide sõiduplaanid ja bussipiletid',
      },
      description: {
        value:
          'Otsid kiiret ja mugavat bussi? Meie rahvusvahelised bussiliinid, soodsad bussipiletid ja mugav busside sõiduplaan tagavad meeldiva reisi. Vaata lähemalt.',
      },
      socialTitle: {
        value: 'Rahvusvaheliste bussiliinide sõiduplaanid ja bussipiletid',
      },
      socialDescription: {
        value:
          'Otsid kiiret ja mugavat bussi? Meie rahvusvahelised bussiliinid, soodsad bussipiletid ja mugav busside sõiduplaan tagavad meeldiva reisi. Vaata lähemalt.',
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value: '/images/thumbnails/all-routes/Lux_Express_all_routes_et.jpg',
      },
    },
    en: {
      title: {
        value: 'International routes and bus timetables',
      },
      description: {
        value:
          'Looking for a comfortable and safe travelling experience? Our international buses provide a journey with all comforts. See our main routes and get your online ticket today.',
      },
      socialTitle: {
        value: 'International routes and bus timetables',
      },
      socialDescription: {
        value:
          'Looking for a comfortable and safe travelling experience? Our international buses provide a journey with all comforts. See our main routes and get your online ticket today.',
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value: '/images/thumbnails/all-routes/Lux_Express_all_routes_en.jpg',
      },
    },
    lt: {
      title: {
        value: 'Tarptautiniai autobusų tvarkaraščiai, bilietai ir maršrutai',
      },
      description: {
        value:
          'Tarptautiniai autobusų tvarkaraščiai, bilietai ir maršrutai. Pirkti bilietus internetu!',
      },
      socialTitle: {
        value: 'Tarptautiniai autobusų tvarkaraščiai, bilietai ir maršrutai',
      },
      socialDescription: {
        value:
          'Tarptautiniai autobusų tvarkaraščiai, bilietai ir maršrutai. Pirkti bilietus internetu!',
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value: '/images/thumbnails/all-routes/Lux_Express_all_routes_lt.jpg',
      },
    },
    lv: {
      title: {
        value:
          'Starptautiskie un starppilsētu autobusi, autobusu biļetes un sarakts',
      },
      description: {
        value:
          'Starptautiskie un starppilsētu autobusi, autobusu biļetes un sarakts. Pirkt biļetes tiešsaistē.',
      },
      socialTitle: {
        value:
          'Starptautiskie un starppilsētu autobusi, autobusu biļetes un sarakts',
      },
      socialDescription: {
        value:
          'Starptautiskie un starppilsētu autobusi, autobusu biļetes un sarakts. Pirkt biļetes tiešsaistē.',
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value: '/images/thumbnails/all-routes/Lux_Express_all_routes_lv.jpg',
      },
    },
    ru: {
      title: {
        value: 'Международные автобусные рейсы по Европе',
      },
      description: {
        value:
          'Путешествуйте международными автобусами в Европу. Купите билет на автобус онлайн и путешествуйте в европейские города. Посмотрите наши маршруты.',
      },
      socialTitle: {
        value: 'Международные автобусные рейсы по Европе',
      },
      socialDescription: {
        value:
          'Путешествуйте международными автобусами в Европу. Купите билет на автобус онлайн и путешествуйте в европейские города. Посмотрите наши маршруты.',
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value: '/images/thumbnails/all-routes/Lux_Express_all_routes_ru.jpg',
      },
    },
  };

  seo = seo[language];

  const slugs = {
    en: {
      value: slug,
    },
    et: {
      value: slug,
    },
    lv: {
      value: slug,
    },
    lt: {
      value: slug,
    },
    ru: {
      value: slug,
    },
  };

  return (
    <div className={cn(className, s.allRoutes)}>
      <Helmet {...meta({ seo, lang: language, slugs })} />
      <Container className={s.bannerContainer}>
        <Container full={false}>
          <section className={s.banner}>
            <h1>{t('all routes')}</h1>
            <Search />
          </section>
        </Container>
      </Container>
      <Divider />
      <Container className={s.connections}>
        <Container full={false} className={cn(className, s.allRoutesContainer)}>
          <section>
            <div className={cn(s.countries, s.desktop)}>
              <Query
                query={GET_ROUTES_GROUPED}
                variables={{
                  lang: language,
                  country: region,
                  groupBy: 'departure',
                }}
                ssr={false}
              >
                {({ data = {}, loading }) => {
                  if (loading) return <Loading />;
                  let routes = data?.routesGrouped;
                  routes = routes.reduce((prev, current) => {
                    if (current.countryName === 'Russia') {
                      return [current, ...prev];
                    }
                    return [...prev, current];
                  }, []);

                  return (
                    !!routes?.length &&
                    routes.map(country => {
                      const { countryName, routes } = country;
                      const count = routes.length;
                      const IN_COLUMN = 5;
                      const blocks = Math.ceil(count / IN_COLUMN);
                      return Array(blocks)
                        .fill()
                        .map((block, key) => {
                          const directions =
                            count > IN_COLUMN * (key + 1)
                              ? IN_COLUMN
                              : count - IN_COLUMN * key;
                          const routes = country.routes.slice(
                            IN_COLUMN * key,
                            IN_COLUMN * key + directions
                          );
                          return (
                            <div
                              key={country + key}
                              className={cn(s.country, s.desktop)}
                            >
                              <h3 className={s.name}>
                                {key === 0 && (
                                  <>
                                    <div className={s.flag}>
                                      <LazyLoadImage
                                        src={`/images/svg/flags/flag-${countryName.toLowerCase()}.svg`}
                                        alt=""
                                      />
                                    </div>
                                    <div>{t(countryName)}</div>
                                  </>
                                )}
                              </h3>
                              <div className={s.roadConnections}>
                                {routes.map((route, key) => {
                                  const departure = route.departure.name;
                                  const arrival = route.arrival.name;
                                  const slug = route.data.seo.slug.value;
                                  return (
                                    <Link
                                      key={key}
                                      to={`/routes/${slug}/`}
                                      className={s.direction}
                                    >
                                      <div>{t(departure)}</div>
                                      <div>
                                        <LazyLoadImage src="/images/svg/road-connection.svg" />
                                        {t(arrival)}
                                      </div>
                                    </Link>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        });
                    })
                  );
                }}
              </Query>
            </div>
            <div className={cn(s.countries, s.mobile)}>
              <Query
                query={GET_ROUTES_GROUPED}
                variables={{ lang: language, country: region }}
              >
                {({ data = {}, loading }) => {
                  if (loading) return <Loading />;
                  let routes = data?.routesGrouped;
                  routes = routes.reduce((prev, current) => {
                    if (current.countryName === 'Russia') {
                      return [current, ...prev];
                    }
                    return [...prev, current];
                  }, []);
                  return (
                    !!routes?.length &&
                    routes.map((country, key) => (
                      <CollapseCountry {...{ country }} key={key} t={t} />
                    ))
                  );
                }}
              </Query>
            </div>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(AllRoutes);
