import gql from 'graphql-tag';

export const UPLOAD_IMAGES = gql`
  mutation($input: [FileUploadInput]) {
    uploadImages(input: $input) {
      success
      urls
    }
  }
`;
