import { DEFAULT_LANG } from '@lux/spa/config';

export const langCountryLocale = {
  en: 'en_US',
  ru: 'ru_RU',
  et: 'et_EE',
  lt: 'lt_LT',
  lv: 'lv_LV',
};

export const meta = ({
  seo = {},
  lang,
  slugs = [],
  type = '',
  canonicalPath = null,
}) => {
  const {
    title = {},
    description,
    pageKeywords,
    thumbnail,
    socialTitle,
    slug,
    socialDescription,
    articlePublishedTime,
    articleModifiedTime,
  } = seo;

  const siteUrl =
    isProd || isStage ? DOMAIN.replace('https://new.', 'https://') : DOMAIN;

  const fullUrl = `${siteUrl}${DEFAULT_LANG === lang ? '' : `/${lang}`}/${
    slug.value
  }`;

  let ogImage = '';

  if (thumbnail?.value.trim()) {
    ogImage = thumbnail.value.includes('https://')
      ? thumbnail.value
      : `${siteUrl}${thumbnail.value}`;
  } else {
    ogImage = `${DOMAIN}/images/default-thumbnail/thumbnail.jpg`;
  }

  // const ogImage = thumbnail?.value.includes('https://')
  //   ? thumbnail?.value
  //   : `${siteUrl}${thumbnail?.value}` ||
  //     `${DOMAIN}/images/default-thumbnail/thumbnail.jpg`;

  const ogLocales = Object.keys(slugs)
    .filter(s => !!slugs[s])
    .map(item => ({
      property: lang === item ? 'og:locale' : 'og:locale:alternate',
      content: langCountryLocale[item],
    }));

  let links = [];

  // const linksAlternate = Object.keys(slugs)
  //   .filter(s => !!slugs[s])
  //   .map(item => ({
  //     rel: 'alternate',
  //     hreflang: DEFAULT_LANG === item ? 'x-default' : item,
  //     href: `${siteUrl}${DEFAULT_LANG === item ? '' : `/${item}`}/${
  //       type ? `${type}/${slugs[item]}` : slug.value
  //     }`,
  //   }));

  const linksAlternate = [];

  Object.keys(slugs)
    .filter(s => !!slugs[s])
    .forEach(item => {
      if (DEFAULT_LANG === item) {
        linksAlternate.push(
          {
            rel: 'alternate',
            hreflang: 'x-default',
            href: `${siteUrl}/${type ? `${type}/${slugs[item]}` : slug.value}`,
          },
          {
            rel: 'alternate',
            hreflang: item,
            href: `${siteUrl}/${item}/${
              type ? `${type}/${slugs[item]}` : slug.value
            }`,
          }
        );
      } else {
        linksAlternate.push({
          rel: 'alternate',
          hreflang: item,
          href: `${siteUrl}/${item}/${
            type ? `${type}/${slugs[item]}` : slug.value
          }`,
        });
      }
    });

  const canonicalLink = {
    rel: 'canonical',
    href: `${fullUrl}`,
  };

  links = [...linksAlternate, canonicalLink];

  return {
    title: `${title?.value} - Lux Express`,
    link: links,
    meta: [
      { name: 'description', content: description.value },
      { name: 'keywords', content: pageKeywords.value },
      { property: 'og:title', content: socialTitle.value },
      { property: 'og:type', content: 'article' },
      ...ogLocales,
      {
        property: 'og:url',
        content: fullUrl,
      },
      {
        property: 'og:image',
        content: ogImage,
      },
      { property: 'og:image:type', content: 'image/jpeg' },
      { property: 'og:site_name', content: 'Luxexpress' },
      { property: 'og:description', content: socialDescription.value },
      { property: 'og:image:width', content: '1200' },
      { property: 'og:image:height', content: '1200' },

      { name: 'twitter:card', content: 'summary' },
      {
        name: 'twitter:site',
        content: siteUrl,
      },
      { name: 'twitter:creator', content: 'Luxexpress' },
      ...(articlePublishedTime
        ? [
            {
              name: 'article:published_time',
              content: articlePublishedTime,
            },
          ]
        : [{}]),
      ...(articleModifiedTime
        ? [
            {
              name: 'article:modified_time',
              content: articleModifiedTime,
            },
          ]
        : [{}]),
      {
        name: 'copyright',
        content: 'Luxexpress',
      },
      {
        name: 'language',
        content: lang,
      },

      // TODO: og:image:alt
    ],
  };
};
