import React, { forwardRef } from 'react';
import cn from 'classnames';
import * as uuid from 'uuid';
import PhoneInput from 'react-phone-number-input';
import i from '@lux/spa/styles/icons.module.scss';
import s from './phone-input.module.scss';

const PhoneNumberInput = forwardRef(
  ({ className, inputClassName, required, errors, label, ...props }, ref) => {
    const id = uuid.v4();
    return (
      <div className={cn(s.phoneNumberInput, errors && s.error, className)}>
        {label && (
          <label htmlFor={id}>{`${label}${required ? ' *' : ''}`}</label>
        )}
        <PhoneInput
          ref={ref}
          international
          countrySelectProps={{
            arrowComponent: props => {
              return <i className={cn(i.arrowBottom, s.arrow)} />;
            },
          }}
          // displayInitialValueAsLocalNumber
          className={cn(s.input, inputClassName)}
          defaultCountry="RU"
          id={id}
          {...props}
        />
        {errors && <div className={s.message}>{errors}</div>}
      </div>
    );
  }
);

export default PhoneNumberInput;
