export const emptyPeople = {
  alt: {
    value: '',
    fieldName: 'alt',
    placeholder: 'Alt',
    maxLength: 30,
  },
  img: {
    value: '',
    fieldName: 'img',
    placeholder: 'Slide',
    name: '',
    size: {
      height: 520,
      width: 520,
    },
  },
  firstName: {
    value: '',
    fieldName: 'firstName',
    placeholder: 'First name',
    maxLength: 100,
  },
  secondName: {
    value: '',
    fieldName: 'secondName',
    placeholder: 'Second name',
    maxLength: 100,
  },
  description: {
    value: '',
    fieldName: 'description',
    placeholder: 'Description',
    maxLength: 500,
  },
  quote: {
    value: '',
    fieldName: 'quote',
    placeholder: 'Quote',
    maxLength: 100,
  },
};
