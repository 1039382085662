import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import s from './modal.module.scss';

const ModalHeader = ({ t, className, text }) => {
  return (
    <header className={cn(s.header, className)}>
      <h2>{t(text).capitalize()}</h2>
    </header>
  );
};

export default withTranslation()(ModalHeader);
