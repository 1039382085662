import React, { useState } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useQuery } from '@apollo/react-hooks';
import { Route, Redirect } from 'react-router-dom';
import { GET_FAQ_ARTICLE } from '@lux/spa/apollo/queries';
import { hideFaqForLangs } from '@lux/spa/config';
import { NotFound } from '@lux/spa/routes';
import { Container, Divider, Link, PageLoading } from '@lux/ui';
import { addBlankAttrToHtml } from '@lux/spa/utils';
import { meta } from '@lux/spa/components';
import FaqSearch from '@lux/spa/routes/faq/components/faq-search';
import i from '@lux/spa/styles/icons.module.scss';
import s from './faq-answer.module.scss';

const FaqAnswer = ({ t, i18n, className, match, ...props }) => {
  const { topic, question } = match.params;

  const [copyText, setCopyText] = useState('');
  const { language } = i18n;

  const title = topic
    .split('-')
    .slice(1)
    .join();
  // const subTitle = question.split('-').slice(1).join()

  const questionId = question.split('-')[0];

  const capitalize = text => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const copyLink = () => {
    // TODO: check in safari
    setCopyText(window.location.href);
    // navigator.clipboard.writeText(window.location.href)
  };

  const { data = {}, loading } = useQuery(GET_FAQ_ARTICLE, {
    variables: {
      lang: language,
      id: +questionId,
    },
  });

  const { article } = data;

  const slug = `faq/${topic}/${question}`;

  const pageTitle = `Faq answer - ${topic.replace(
    /[^a-zA-Z ]+/g,
    ''
  )} - ${question.replace(/[^a-zA-Z ]+/g, '')}`;

  let seo = {
    et: {
      title: {
        value: pageTitle,
      },
      description: {
        value: pageTitle,
      },
      socialTitle: {
        value: pageTitle,
      },
      socialDescription: {
        value: pageTitle,
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value: '/images/thumbnails/faq/Lux_Express_faq.jpg',
      },
    },
    en: {
      title: {
        value: pageTitle,
      },
      description: {
        value: pageTitle,
      },
      socialTitle: {
        value: pageTitle,
      },
      socialDescription: {
        value: pageTitle,
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value: '/images/thumbnails/faq/Lux_Express_faq.jpg',
      },
    },
    lt: {
      title: {
        value: pageTitle,
      },
      description: {
        value: pageTitle,
      },
      socialTitle: {
        value: pageTitle,
      },
      socialDescription: {
        value: pageTitle,
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value: '/images/thumbnails/faq/Lux_Express_faq.jpg',
      },
    },
    lv: {
      title: {
        value: pageTitle,
      },
      description: {
        value: pageTitle,
      },
      socialTitle: {
        value: pageTitle,
      },
      socialDescription: {
        value: pageTitle,
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value: '/images/thumbnails/faq/Lux_Express_faq.jpg',
      },
    },
    ru: {
      title: {
        value: pageTitle,
      },
      description: {
        value: pageTitle,
      },
      socialTitle: {
        value: pageTitle,
      },
      socialDescription: {
        value: pageTitle,
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value: '/images/thumbnails/faq/Lux_Express_faq.jpg',
      },
    },
  };

  seo = seo[language];

  seo.articlePublishedTime = article?.createdAt || '';
  seo.articleModifiedTime = article?.editedAt || '';

  const slugs = {
    en: {
      value: slug,
    },
    et: {
      value: slug,
    },
    lv: {
      value: slug,
    },
    lt: {
      value: slug,
    },
    ru: {
      value: slug,
    },
  };

  return (
    <>
      <Route
        path={`/:lang([${hideFaqForLangs.join()}]*)`}
        render={({ location }) => {
          return <Redirect exact to={location.pathname.slice(0, 3)} />;
        }}
      />
      {loading ? (
        <PageLoading />
      ) : article ? (
        <div className={cn(className, s.faqAnswer)}>
          <Helmet {...meta({ seo, lang: language, slugs })} />
          <Container className={s.bannerContainer}>
            <Container full={false}>
              <section className={s.banner}>
                <h1>{t('how can we help you?')}</h1>
                <FaqSearch />
              </section>
            </Container>
          </Container>
          <Divider />
          <Container className={s.answer}>
            <div className={s.greyBlock} />
            <Container full={false}>
              <section>
                <Link to={`/faq/${topic}/`} className={s.back}>
                  <i className={cn(i.arrowLeft, s.icon)} />
                  {t('back')}
                </Link>
                <div>
                  <h2 className={s.title}>{article.title}</h2>
                  {article.body && (
                    <div
                      className={s.article}
                      dangerouslySetInnerHTML={{
                        __html: addBlankAttrToHtml(article.body),
                      }}
                    />
                  )}
                </div>
                <div className={s.links}>
                  <Link to="/" className={s.link}>
                    <i className={i.twitter} />
                  </Link>
                  <Link to="/" className={s.link}>
                    <i className={i.facebook} />
                  </Link>
                  <CopyToClipboard onCopy={copyLink} text={copyText}>
                    <Link to="/" className={s.link}>
                      <i className={i.link} />
                    </Link>
                  </CopyToClipboard>
                </div>
              </section>
            </Container>
          </Container>
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default withTranslation()(FaqAnswer);
