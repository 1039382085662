import React, { Component } from 'react';
import cn from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button } from '@lux/ui';
import s from './drag-and-drop.module.scss';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class DragAndDrop extends Component {
  state = {
    items: this.props.items,
    // lang: this.props.lang,
  };

  static getDerivedStateFromProps(props, state) {
    // if (props.items.length != state.items.length || props.lang != state.lang) {
    return {
      items: props.items,
    };
    // }
    // return null;
  }

  onDragEnd = result => {
    const { updateItems } = this.props;
    const { items: itemsList } = this.state;
    // dropped outside the list
    if (!result.destination) return;

    const items = reorder(
      itemsList,
      result.source.index,
      result.destination.index
    );

    updateItems(items);

    this.setState({
      items,
    });
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const { itemProps, ItemLayout } = this.props;
    const { items } = this.state;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              className={cn(s.list, snapshot.isDraggingOver && s.draggingOver)}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items.map((item, index) => {
                const key = item.order ? item.order : item.id;
                return (
                  <Draggable
                    key={key}
                    draggableId={`item-${key}`}
                    index={index}
                  >
                    {(provided, snapshot) => {
                      return (
                        <ItemLayout
                          item={item}
                          itemFef={provided.innerRef}
                          {...provided.draggableProps}
                          dragHandleProps={provided.dragHandleProps}
                          {...itemProps}
                        />
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default DragAndDrop;
