import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Query } from 'react-apollo';
import { toast } from 'react-toastify';
import { TRAVEL_ACCOUNT_VOUCHERS } from '@lux/spa/apollo/queries';
import { Container, Loading, Notice } from '@lux/ui';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ProfileMenu } from '@lux/spa/components';
import i from '@lux/spa/styles/icons.module.scss';
import s from './vouchers.module.scss';

const Vouchers = ({ t, className }) => {
  const onCopy = () => {
    toast.success(t('voucher code copied to clipboard'));
  };

  return (
    <div className={cn(className, s.paymentMethods)}>
      <Container>
        <Container full={false}>
          <section>
            <h2>{t('my account')}</h2>
            <div className={s.block}>
              <ProfileMenu />
              <div className={s.content}>
                <div className={s.header}>
                  <h5 className={s.title}>{t('vouchers')}</h5>
                </div>
                <div className={s.info}>
                  <Query
                    query={TRAVEL_ACCOUNT_VOUCHERS}
                    fetchPolicy="network-only"
                  >
                    {({ data, loading, error }) => {
                      if (loading) return <Loading />;

                      if (error) {
                        toast.error(t('something wrong'));
                      }

                      if (data?.travelAccountVouchers?.error) {
                        toast.error(
                          data?.travelAccountVouchers?.error?.ErrorReason
                        );
                      }

                      const voucherCurrency = data?.travelAccountVouchers?.Currency.split(
                        '.'
                      )[1];

                      const vouchers =
                        data?.travelAccountVouchers?.Voucher || [];
                      return vouchers.length ? (
                        <div className={s.vouchers}>
                          {vouchers.map(voucher => (
                            <CopyToClipboard
                              onCopy={onCopy}
                              text={voucher.Code}
                            >
                              <div className={s.voucher}>
                                <div className={s.currency}>
                                  {voucherCurrency}
                                </div>
                                <div className={s.code}>
                                  <div>{voucher.Code}</div>
                                  <i className={i.copy} />
                                </div>
                                <div className={s.dates}>
                                  <div className={s.createdOn}>
                                    {voucher.CreatedOn.split('T')[0].replace(
                                      /-/g,
                                      '.'
                                    )}
                                  </div>
                                  <img
                                    src="/images/svg/long-arrow.svg"
                                    alt=""
                                  />
                                  <div className={s.valid}>
                                    <div
                                      className={cn(
                                        s.validUntil,
                                        !voucher.ValidUntil && s.noExpiryDate
                                      )}
                                    >
                                      <i className={i.clock} />
                                      {voucher.ValidUntil
                                        ? voucher.ValidUntil.toString()
                                            .split('T')[0]
                                            .replace(/-/g, '.')
                                        : t('no expiry date')}
                                    </div>
                                  </div>
                                </div>

                                <div className={s.amount}>{voucher.Amount}</div>
                              </div>
                            </CopyToClipboard>
                          ))}
                          <Notice
                            className={s.notice}
                            text={t(
                              'vouchers are usually credited upon ticket refund. Use them to cover or reduce tickets price.'
                            )}
                          />
                        </div>
                      ) : (
                        <div className={s.noVouchers}>
                          <img src="/images/svg/no-vouchers.svg" alt="" />
                          <h3>{t('no vouchers')}</h3>
                          <p>
                            {t(
                              'vouchers are usually credited upon ticket refund.'
                            )}
                          </p>
                        </div>
                      );
                    }}
                  </Query>
                </div>
              </div>
            </div>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(Vouchers);
