import React, { useContext } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { RENEW_BASKET_EXPIRATION_TIME } from '@lux/spa/apollo/mutations';
import { StoreContext } from '@lux/spa/store';
import { Modal, ModalContent, Button, Loading } from '@lux/ui';
import s from './update-basket-time.module.scss';

const UpdateBasketTime = ({ t, isOpen, toggleModal }) => {
  const [state, dispatch] = useContext(StoreContext);
  const [updateBasket, { loading }] = useMutation(
    RENEW_BASKET_EXPIRATION_TIME,
    {
      onCompleted: data => {
        if (data?.renewBasketExpirationTime?.error) {
          const promise = new Promise(res => res());
          promise
            .then(() => {
              toggleModal();
            })
            .then(() => {
              dispatch({
                type: 'UPDATE_TICKETS',
                payload: {
                  isSomethingWrong: true,
                },
              });
            });
          return;
        }

        if (data?.renewBasketExpirationTime?.success) {
          const basketValidUntil =
            data?.renewBasketExpirationTime?.basket?.ValidUntil;

          if (basketValidUntil) {
            dispatch({
              type: 'UPDATE_TICKETS',
              payload: {
                basketValidUntil,
              },
            });
          }
          toggleModal();
          return;
        }

        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            isSomethingWrong: true,
          },
        });
      },
    }
  );

  const handleUpdateBasket = () => {
    updateBasket();
  };

  return (
    <Modal className={s.modal} {...{ isOpen, toggleModal }}>
      <ModalContent className={s.content}>
        <img src="/images/svg/tickets-not-found.svg" alt="" />
        <h1>{t('tickets.update.basket.time.title')}</h1>
        <p>{t('tickets.update.basket.time.text')}</p>
        <div className={s.controls}>
          <Button
            text={loading ? <Loading /> : t('prolong')}
            className={s.button}
            onClick={handleUpdateBasket}
            disabled={loading}
          />
          <div
            className={cn(s.button, s.cancel)}
            onClick={loading ? null : toggleModal}
          >
            {t('cancel')}
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default withTranslation()(UpdateBasketTime);
