import React from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { Route, Redirect } from 'react-router-dom';
import { GET_FAQ_ARTICLES } from '@lux/spa/apollo/queries';
import { hideFaqForLangs } from '@lux/spa/config';
import { Container, Divider, Button, Link, PageLoading } from '@lux/ui';
import { meta } from '@lux/spa/components';
import FaqSearch from '@lux/spa/routes/faq/components/faq-search';
import { NotFound } from '@lux/spa/routes';
import i from '@lux/spa/styles/icons.module.scss';
import s from './faq-topic.module.scss';

const FaqTopic = ({ t, i18n, className, match, ...props }) => {
  const { url } = match;
  const { topic } = match.params;
  const { language } = i18n;

  const title = topic
    .split('-')
    .slice(1)
    .join();
  const topicId = topic.split('-')[0];

  // const capitalize = text => {
  //   return text.charAt(0).toUpperCase() + text.slice(1);
  // };

  const { data = {}, loading } = useQuery(GET_FAQ_ARTICLES, {
    variables: {
      lang: language,
      sectionId: +topicId,
    },
  });

  const articles = data.articles?.articles || [];

  const slug = `faq/${topic}`;

  const pageTitle = topic.replace(/[^a-zA-Z ]+/g, '');

  let seo = {
    et: {
      title: {
        value: pageTitle,
      },
      description: {
        value: pageTitle,
      },
      socialTitle: {
        value: pageTitle,
      },
      socialDescription: {
        value: pageTitle,
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value: '/images/thumbnails/faq/Lux_Express_faq.jpg',
      },
    },
    en: {
      title: {
        value: `Faq questions - ${pageTitle}`,
      },
      description: {
        value: `Faq questions - ${pageTitle}`,
      },
      socialTitle: {
        value: `Faq questions - ${pageTitle}`,
      },
      socialDescription: {
        value: `Faq questions - ${pageTitle}`,
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value: '/images/thumbnails/faq/Lux_Express_faq.jpg',
      },
    },
    lt: {
      title: {
        value: pageTitle,
      },
      description: {
        value: pageTitle,
      },
      socialTitle: {
        value: pageTitle,
      },
      socialDescription: {
        value: pageTitle,
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value: '/images/thumbnails/faq/Lux_Express_faq.jpg',
      },
    },
    lv: {
      title: {
        value: pageTitle,
      },
      description: {
        value: pageTitle,
      },
      socialTitle: {
        value: pageTitle,
      },
      socialDescription: {
        value: pageTitle,
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value: '/images/thumbnails/faq/Lux_Express_faq.jpg',
      },
    },
    ru: {
      title: {
        value: `Часто задаваемые вопросы по теме ${pageTitle}`,
      },
      description: {
        value: `Часто задаваемые вопросы по теме ${pageTitle}`,
      },
      socialTitle: {
        value: `Часто задаваемые вопросы по теме ${pageTitle}`,
      },
      socialDescription: {
        value: `Часто задаваемые вопросы по теме ${pageTitle}`,
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value: '/images/thumbnails/faq/Lux_Express_faq.jpg',
      },
    },
  };

  seo = seo[language];

  // seo.articlePublishedTime = article?.createdAt || '';
  // seo.articleModifiedTime = article?.editedAt || '';

  const slugs = {
    en: {
      value: slug,
    },
    et: {
      value: slug,
    },
    lv: {
      value: slug,
    },
    lt: {
      value: slug,
    },
    ru: {
      value: slug,
    },
  };

  return (
    <>
      <Route
        path={`/:lang([${hideFaqForLangs.join()}]*)`}
        render={({ location }) => {
          return <Redirect exact to={location.pathname.slice(0, 3)} />;
        }}
      />
      {loading ? (
        <PageLoading />
      ) : articles.length ? (
        <div className={cn(className, s.faqTopic)}>
          <Helmet {...meta({ seo, lang: language, slugs })} />
          <Container className={s.bannerContainer}>
            <Container full={false}>
              <section className={s.banner}>
                <h1>{t('how can we help you?')}</h1>
                <FaqSearch />
              </section>
            </Container>
          </Container>
          <Divider />
          <Container className={s.questions}>
            <div className={s.greyBlock} />
            <Container full={false}>
              <section>
                <Link to="/faq/" className={s.back}>
                  <i className={cn(i.arrowLeft, s.icon)} />
                  {t('back')}
                </Link>
                <h2 className={s.title}>{title}</h2>
                <div className={s.topics}>
                  {articles.map(article => (
                    <Link
                      to={`${url}${article.id}-${article.title}/`}
                      key={article.id}
                      className={s.topic}
                    >
                      <h5 className={s.name}>
                        <span>{article.title}</span>
                        <i className={cn(i.arrowRight_v2, s.icon)} />
                      </h5>
                    </Link>
                  ))}
                </div>
              </section>
            </Container>
          </Container>
          <Container className={s.contactUs}>
            <div className={s.greyBlock} />
            <Container full={false}>
              <section>
                <h2 className={cn(s.title, s.titleSupport)}>
                  {t('contact customer support')}
                </h2>
                <p>
                  {t('faq.contact.text1')}
                  <br />
                  {t('faq.contact.text2')}
                </p>
                <Button text={t('contact us')} to="/contacts" />
              </section>
            </Container>
          </Container>
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default withTranslation()(FaqTopic);
