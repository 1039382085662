export const emptyData = {
  lang: 'en',
  isPublished: true,
  countries: ['ee', 'ru', 'fi', 'lv', 'lt'],
  seo: {
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 80,
    },
    slug: {
      value: '',
      fieldName: 'slug',
      placeholder: 'Slug / URL',
      maxLength: 120,
    },
    thumbnail: {
      value: '',
      fieldName: 'thumbnail',
      placeholder: 'Thumbnail',
      size: {
        height: 250,
        width: 250,
      },
    },
    description: {
      value: '',
      fieldName: 'description',
      placeholder: 'Page description',
      maxLength: 158,
    },
    socialTitle: {
      value: '',
      fieldName: 'socialTitle',
      placeholder: 'Social Title',
      maxLength: 160,
    },
    socialDescription: {
      value: '',
      fieldName: 'socialDescription',
      placeholder: 'Social description',
      maxLength: 160,
    },
    pageKeywords: {
      value: '',
      fieldName: 'pageKeywords',
      placeholder: 'Page keywords',
      maxLength: 40,
    },
    socialImage: '',
    articlePublishedTime: '',
    articleModifiedTime: '',
  },
  pageData: {
    banner: {
      value: '',
      fieldName: 'banner',
      placeholder: 'Banner',
      size: {
        height: 550,
        width: 1440,
      },
    },
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 50,
    },
    subTitle: {
      value: 'News title',
      fieldName: 'subTitle',
      placeholder: 'Page title',
      maxLength: 200,
    },
    heading1: {
      value: '',
      fieldName: 'heading1',
      placeholder: 'Heading',
      maxLength: 100,
    },
    text1: {
      value: '',
      fieldName: 'text1',
      placeholder: 'Text',
      maxLength: 100,
    },
    campaignCode: {
      value: '',
      fieldName: 'campaignCode',
      placeholder: 'Code',
      maxLength: 100,
    },
    salesPeriodFrom: new Date(),
    salesPeriodTo: new Date(),
    travelPeriodFrom: new Date(),
    travelPeriodTo: new Date(),
    discount: {
      value: '',
      fieldName: 'discount',
      placeholder: 'Discount',
      maxLength: 100,
    },
    campaignRules: '',
    routes: {
      fieldName: 'routes',
      slides: [],
    },
    // heading2: {
    //   value: "",
    //   fieldName: "heading2",
    //   placeholder: "Heading",
    //   maxLength: 100
    // },
    // text2: {
    //   value: "",
    //   fieldName: "text2",
    //   placeholder: "Heading",
    //   maxLength: 100
    // }
  },
};

export const defaultData = {
  lang: 'en',
  slug: 'test',
  isPublished: true,
  countries: ['ee', 'ru', 'fi', 'lv', 'lt'],
  seo: {
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 80,
    },
    slug: {
      value: '',
      fieldName: 'slug',
      placeholder: 'Slug / URL',
      maxLength: 120,
    },
    description: {
      value: 'Page description',
      fieldName: 'description',
      placeholder: 'Page description',
      maxLength: 158,
    },
    socialTitle: {
      value: 'Social Title',
      fieldName: 'socialTitle',
      placeholder: 'Social Title',
      maxLength: 160,
    },
    socialDescription: {
      value: 'Social description',
      fieldName: 'socialDescription',
      placeholder: 'Social description',
      maxLength: 160,
    },
    pageKeywords: {
      value: 'Page keywords',
      fieldName: 'pageKeywords',
      placeholder: 'Page keywords',
      maxLength: 40,
    },
    thumbnail: {
      value: '',
      fieldName: 'thumbnail',
      placeholder: 'Thumbnail',
      size: {
        height: 250,
        width: 250,
      },
    },
    socialImage: '',
    articlePublishedTime: '',
    articleModifiedTime: '',
  },
  pageData: {
    banner: {
      value: '',
      fieldName: 'banner',
      placeholder: 'Banner',
      size: {
        height: 550,
        width: 1440,
      },
    },
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 100,
    },
    subTitle: {
      value: '',
      fieldName: 'subTitle',
      placeholder: 'Page title',
      maxLength: 200,
    },
    heading1: {
      value: '',
      fieldName: 'heading1',
      placeholder: 'Heading',
      maxLength: 100,
    },
    text1: {
      value: '',
      fieldName: 'text1',
      placeholder: 'Text',
      maxLength: 100,
    },
    campaignCode: {
      value: '',
      fieldName: 'campaignCode',
      placeholder: 'Code',
      maxLength: 100,
    },
    salesPeriodFrom: new Date(),
    salesPeriodTo: new Date(),
    travelPeriodFrom: new Date(),
    travelPeriodTo: new Date(),
    discount: {
      value: '',
      fieldName: 'discount',
      placeholder: 'Discount',
      maxLength: 100,
    },
    campaignRules: '',
    routes: {
      fieldName: 'routes',
      slides: [],
    },
    // heading2: {
    //   value: "",
    //   fieldName: "heading2",
    //   placeholder: "Heading",
    //   maxLength: 100
    // },
    // text2: {
    //   value: "",
    //   fieldName: "text2",
    //   placeholder: "Heading",
    //   maxLength: 100
    // }
  },
};
