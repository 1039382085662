import React, { useState, useRef, useEffect, useContext } from 'react';
import cn from 'classnames';
import { StoreContext } from '@lux/spa/store';
import { Input } from '@lux/ui';
import s from './tooltip-input.module.scss';

const TooltipInput = ({
  target = null,
  className,
  onChange,
  style,
  contentClass,
  position = 'top',
  field,
}) => {
  const [state] = useContext(StoreContext);
  const { app } = state;
  const { isEditMode } = app;

  const [show, setShow] = useState(false);
  const toogleContent = () => {
    setShow(!show);
  };
  const classes = cn(s.tooltipInput, s[position], show && s.show, className);

  const contentRef = useRef(null);
  const tooltipRef = useRef(null);

  const handlePressEsc = ({ keyCode }) => {
    if (keyCode === 27 || keyCode === 13) {
      setShow(false);
    }
  };

  const hideContent = e => {
    if (
      !e.path.includes(contentRef.current) &&
      !e.path.includes(tooltipRef.current)
    ) {
      setShow(false);
    }
  };

  useEffect(() => {
    if (isClient && !('path' in Event.prototype)) {
      Object.defineProperty(Event.prototype, 'path', {
        get() {
          const path = [];
          let currentElem = this.target;
          while (currentElem) {
            path.push(currentElem);
            currentElem = currentElem.parentElement;
          }

          if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
            path.push(document);
          }

          if (path.indexOf(window) === -1) {
            path.push(window);
          }

          return path;
        },
      });
    }

    if (isClient) {
      if (show) {
        document.addEventListener('click', hideContent, false);
        document.addEventListener('keydown', handlePressEsc, false);

        return () => {
          document.removeEventListener('click', hideContent, false);
          document.removeEventListener('keydown', handlePressEsc, false);
        };
      }
    }
  }, [show]);

  return (
    <div className={classes} ref={tooltipRef} style={style}>
      <div
        className={s.target}
        onClick={isEditMode ? toogleContent : () => {}}
        children={target}
      />
      {isEditMode && (
        <div className={cn(s.content, contentClass)}>
          <div className={s.wrap}>
            <div className={s.triangle} />
          </div>
          <Input
            className={s.input}
            value={field.value}
            label={field.placeholder}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};

export default TooltipInput;
