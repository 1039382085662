import React, {
  useState,
  useReducer,
  useRef,
  useEffect,
  useContext,
} from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { Suggestions } from '@lux/spa/components';
import { Input, Button, UploadImages, SearchDestinationMobile } from '@lux/ui';
import s from './destination-fields.module.scss';

const routeFieldsReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE':
      return {
        ...state,
        ...action.payload,
      };
    case 'TOGGLE_SUGGESTIONS_MODAL':
      return {
        ...state,
        ...action.payload,
        isOpenSuggMob: !state.isOpenSuggMob,
      };
    case 'HIDE_SUGGESTIONS':
      return {
        ...state,
        isOpenSugg: false,
      };
    case 'SHOW_SUGGESTIONS':
      return {
        ...state,
        isOpenSugg: !!state.search,
      };
    case 'UPDATE_SEARCH':
      const { search } = action.payload;
      return {
        ...state,
        ...action.payload,
        isOpenSugg: !!search,
        search,
      };
    default:
      throw new Error();
  }
};

const DestinationFields = ({
  t,
  updateSeo,
  state: pageState,
  dispatch: pageDisaptch,
}) => {
  const { isMob } = useContext(WidthContext);

  const city = pageState.page?.city || {
    id: '',
    name: '',
  };

  const initialSearch = {
    isOpenSugg: false,
    isOpenSuggMob: false,
    search: city.name,
  };

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, dispatch] = useReducer(routeFieldsReducer, initialSearch);

  const [isThumbnail, setIsThumbnail] = useState(false);

  const toggleThumbnail = () => {
    setIsThumbnail(!isThumbnail);
  };

  const { page, lang } = pageState;

  const { thumbnail } = page[lang].seo;

  const { search, isOpenSugg, isOpenSuggMob } = state;

  const suggestionsRef = useRef(null);
  const fromRef = useRef(null);

  const updateSearch = e => {
    dispatch({
      type: 'UPDATE_SEARCH',
      payload: {
        search: e.target.value,
      },
    });
  };

  const toggleSuggModal = () => {
    dispatch({
      type: 'TOGGLE_SUGGESTIONS_MODAL',
    });
  };

  const showSuggestion = () => {
    dispatch({
      type: 'SHOW_SUGGESTIONS',
    });
  };

  const hideSuggestions = e => {
    if (
      !e.path.includes(suggestionsRef.current) &&
      !e.path.includes(fromRef.current)
    ) {
      dispatch({ type: 'HIDE_SUGGESTIONS' });
    }
  };

  const selectItem = search => {
    pageDisaptch({
      type: 'UPDATE_PAGE',
      payload: {
        city: {
          id: search.CityId,
          name: search.CityName,
        },
      },
    });
  };

  useEffect(() => {
    if (isClient && !('path' in Event.prototype)) {
      Object.defineProperty(Event.prototype, 'path', {
        get() {
          const path = [];
          let currentElem = this.target;
          while (currentElem) {
            path.push(currentElem);
            currentElem = currentElem.parentElement;
          }

          if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
            path.push(document);
          }

          if (path.indexOf(window) === -1) {
            path.push(window);
          }

          return path;
        },
      });
    }

    if (isClient) {
      if (isOpenSugg) {
        document.addEventListener('click', hideSuggestions, false);

        return () => {
          document.removeEventListener('click', hideSuggestions, false);
        };
      }
    }
  }, [isOpenSugg]);

  return (
    <div className={s.destinationFields}>
      <UploadImages
        isOpen={isThumbnail}
        toggleModal={toggleThumbnail}
        updatePage={updateSeo}
        // || {..} for destination with old thumbnail = '' now thumbnail is Object not String
        image={
          thumbnail || {
            value: '',
            fieldName: 'thumbnail',
            placeholder: 'Thumbnail',
            size: {
              height: 744,
              width: 744,
            },
          }
        }
      />
      <SearchDestinationMobile
        // onlyCity={true} // TODO: add login for this prop
        modalTitle={t('city')}
        search={search}
        isOpen={isOpenSuggMob}
        toggleModal={toggleSuggModal}
        {...{ selectItem, updateSearch }}
      />
      <div className={s.inputs}>
        <div className={s.input} ref={fromRef}>
          <Input
            label={t('city')}
            value={search}
            onClick={isMob ? () => toggleSuggModal() : () => showSuggestion()}
            onChange={e => updateSearch(e)}
          />
          {!isMob && isOpenSugg && (
            <Suggestions
              search={search}
              onlyCity
              {...{
                selectItem,
                suggestionsRef,
              }}
            />
          )}
        </div>
      </div>
      <div className={s.title}>{t('thumbnail')}</div>
      <div
        className={cn(s.thumbnail, thumbnail.value && s.load)}
        style={{
          backgroundImage: `url(${thumbnail.value})`,
        }}
      >
        <Button text={t('upload thumbnail')} onClick={toggleThumbnail} />
      </div>
    </div>
  );
};

export default withTranslation()(DestinationFields);
