import React, { useContext } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { StoreContext } from '@lux/spa/store';
import { Container, Radio, TicketsInput, HiddenInput } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './profile.module.scss';

const Profile = ({ t, className }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { user } = state;
  // const { avatar } = user;

  const avatar =
    'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQLO_mK0eaZPp8_eoD-mIpmm-IKQkWHDvrawQ&usqp=CAU';

  return (
    <div className={cn(className, s.profile)}>
      <Container>
        <Container full={false}>
          <section>
            <h2>My account</h2>
            <div className={s.block}>
              <aside className={s.menu}>
                <div className={cn(s.item, s.active)}>
                  <span>Tickets</span>
                  <i className={i.arrowRight_v2} />
                </div>
                <div className={s.item}>
                  <span>Personal info</span>
                  <i className={i.arrowRight_v2} />
                </div>
                <div className={s.item}>
                  <span>Payment methods</span>
                  <i className={i.arrowRight_v2} />
                </div>
                <div className={s.item}>
                  <span>Security</span>
                  <i className={i.arrowRight_v2} />
                </div>
              </aside>
              {false && (
                <div className={s.content}>
                  <div className={s.header}>
                    <h3 className={s.title}>Security</h3>
                    <div className={s.action}>Save</div>
                  </div>

                  <div className={cn(s.info, s.security)}>
                    <div className={s.fields}>
                      <div className={s.field}>
                        <HiddenInput
                          autoFocus
                          name="password"
                          placeholder="••••••"
                          label={t('new password')}
                          required
                          // value={values.password}
                          // onChange={handleChange}
                          // onBlur={handleBlur}
                          // errors={touched.password && errors.password}
                        />
                      </div>
                      <div className={s.field}>
                        <HiddenInput
                          name="confirmPassword"
                          placeholder="••••••"
                          label={t('repeat new password')}
                          required
                          // value={values.confirmPassword}
                          // onChange={handleChange}
                          // onBlur={handleBlur}
                          // errors={
                          //   touched.confirmPassword && errors.confirmPassword
                          // }
                        />
                      </div>
                    </div>
                  </div>

                  {false && (
                    <>
                      <div className={s.header}>
                        <h3 className={s.title}>Personal info</h3>
                        <div className={s.action}>Save</div>
                      </div>

                      <div className={cn(s.info, s.personalInfo)}>
                        <div className={s.photo}>
                          <div className={cn(s.avatar)}>
                            {avatar ? (
                              <div
                                className={s.cap}
                                style={{
                                  backgroundImage: `url(${avatar})`,
                                }}
                              />
                            ) : (
                              <img src="/images/svg/camera.svg" alt="" />
                            )}
                          </div>
                        </div>
                        <div className={s.fields}>
                          <div className={cn(s.field, s.radios)}>
                            <Radio
                              className={s.radio}
                              name="gender"
                              value="Male"
                              // checked={values.gender === 'SEX.MALE'}
                              // onChange={() =>
                              //   setFieldValue('gender', 'SEX.MALE')
                              // }
                            />
                            <Radio
                              className={s.radio}
                              name="gender"
                              value="Female"
                              // checked={values.gender === 'SEX.FEMALE'}
                              // onChange={() =>
                              //   setFieldValue('gender', 'SEX.FEMALE')
                              // }
                            />
                          </div>
                          <div className={s.field}>
                            <TicketsInput
                              autoFocus
                              name="firstName"
                              type="text"
                              required
                              label={t('first name')}
                              // value={values.firstName}
                              // onChange={handleChange}
                              // onBlur={handleBlur}
                              // errors={touched.firstName && errors.firstName}
                            />
                          </div>
                          <div className={s.field}>
                            <TicketsInput
                              name="lastName"
                              type="text"
                              label={t('last name')}
                              required
                              // value={values.lastName}
                              // onChange={handleChange}
                              // onBlur={handleBlur}
                              // errors={touched.lastName && errors.lastName}
                            />
                          </div>
                          <div className={s.field}>
                            <TicketsInput
                              name="email"
                              label={t('email')}
                              type="text"
                              required
                              // value={values.email}
                              // onChange={handleChange}
                              // onBlur={handleBlur}
                              // errors={touched.email && errors.email}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(Profile);
