import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import {
  CREATE_DESTINATION,
  UPDATE_DESTINATION,
} from '@lux/spa/apollo/mutations';
import {
  GET_DESTINATION_ADMIN,
  GET_DESTINATION,
} from '@lux/spa/apollo/queries';
import { Loading } from '@lux/ui';
import { NotFound } from '@lux/spa/routes';
import { useQuery } from '@apollo/react-hooks';
import { emptyData, defaultData } from './constants';
import DestinationContent from './destination-content';

const CreateDestination = ({
  region,
  lang = 'en',
  match,
  i18n,
  isStaff,
  isEditMode,
  page = { en: defaultData },
  dispatch = () => {},
  ...props
}) => {
  const { destination } = match.params;
  const { language } = i18n;

  const updatePage = field => {
    dispatch({
      type: 'UPDATE_DATA',
      payload: {
        field,
      },
    });
  };

  useEffect(() => {
    if (isStaff) {
      dispatch({
        type: 'UPDATE',
        payload: {
          defaultData,
          emptyData,
          // UPDATE_PAGE: UPDATE_DESTINATION,
          CREATE_PAGE: CREATE_DESTINATION,
          page: {
            isPublished: false,
            [language]: {
              ...defaultData,
              countries: [region],
            },
          },
        },
      });
    }
  }, []);

  const content = page?.[lang];

  return content ? (
    <DestinationContent
      {...{ updatePage, content, language, isEditMode }}
      {...props}
    />
  ) : (
    <NotFound />
  );
};

export default withTranslation()(CreateDestination);
