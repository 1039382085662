import gql from 'graphql-tag';

export const GET_ALL_ROUTES_ADMIN = gql`
  query($lang: String!, $country: String!) {
    adminRoutes(lang: $lang, country: $country) {
      id
      departure {
        id
        name
      }
      arrival {
        id
        name
      }
      isPublished
      data {
        seo
        lang
        slug
        countries
        pageData
        isPublished
      }
    }
  }
`;

export const GET_ALL_ROUTES = gql`
  query($lang: String!, $country: String!) {
    routes(lang: $lang, country: $country) {
      id
      departure {
        id
        name
      }
      arrival {
        id
        name
      }
      isPublished
      data {
        seo
        lang
        slug
        countries
        pageData
        isPublished
      }
    }
  }
`;

export const GET_ROUTES_GROUPED = gql`
  query($lang: String!, $country: String!, $groupBy: String) {
    routesGrouped(lang: $lang, country: $country, groupBy: $groupBy) {
      countryId
      countryName
      routes {
        id
        departure {
          id
          name
        }
        arrival {
          id
          name
        }
        data {
          lang
          slug
          seo
          pageData
        }
      }
    }
  }
`;
