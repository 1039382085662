import React from 'react';
import cn from 'classnames';
import * as uuid from 'uuid';
import i from '@lux/spa/styles/icons.module.scss';
import s from './select.module.scss';

const Select = ({
  className,
  required,
  errors,
  label,
  withoutMessage,
  ...props
}) => {
  const id = uuid.v4();
  const classes = cn(
    s.selectWrap,
    errors && s.error,
    withoutMessage && s.withoutMessage,
    className
  );
  return (
    <div className={classes}>
      {label && <label htmlFor={id}>{`${label}${required ? ' *' : ''}`}</label>}
      <div className={s.select}>
        <i className={i.arrowBottom} />
        <select id={id} {...props} />
      </div>
      {errors && !withoutMessage && <div className={s.message}>{errors}</div>}
    </div>
  );
};

export default Select;
