import React, { useRef, useEffect, useState, useContext, memo } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Collapse } from 'react-collapse';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { StoreContext } from '@lux/spa/store';
import { toast } from 'react-toastify';
import { LazyLoadImage } from '@lux/ui';
import { localizedDate } from 'utils';
import { TICKETS_FILE_URL } from '@lux/spa/apollo/queries';
import { MODIFICATION_CANCEL } from '@lux/spa/apollo/mutations';
import i from '@lux/spa/styles/icons.module.scss';
import s from './trip.module.scss';

const Trip = ({
  t,
  i18n,
  history,
  isCanceled = false,
  className,
  passengers,
  arrivalTime,
  departureTime,
  departureStopName,
  destinationStopName,
  shoppingBasketCode,
  shoppingBasketId,
  destinationStopId,
  departureStopId,
  departureCityName,
  destinationCityName,
  arrivalTimeZone,
  departureTimeZone,
  legs,
  toggleModal,
  showTickets,
  setShowTickets,
  setTicketsData,
  trip,
  isPast,
  isPublic,
  isOpenModalInfo,
  toggleModalInfo,
  isModificationAllowed,
}) => {
  const { language } = i18n;
  const { isMob } = useContext(WidthContext);
  const [state, dispatch] = useContext(StoreContext);
  const [showDetails, setShowDetails] = useState(false);

  const { refund } = state.tickets;

  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';

  const buttonGroupRef = useRef(null);

  const showTime = time => {
    return time
      .split('T')[1]
      .split(':')
      .slice(0, 2)
      .join(':');
  };

  const [modificationCancel] = useMutation(MODIFICATION_CANCEL);

  const [getTicketsFileUrl, { loading: fileLoading }] = useLazyQuery(
    TICKETS_FILE_URL,
    {
      variables: {
        basketId: shoppingBasketId,
        lang: language,
      },
      fetchPolicy: 'network-only',

      onCompleted: data => {
        if (data?.ticketsFileUrl) {
          window.open(data?.ticketsFileUrl, '_blank');
        }
      },
    }
  );

  const logoType = 'logo-grey';

  // const durationDays = Duration.includes('.') && Duration.split('.')[0];

  // const duration = Duration.replace(`${durationDays}.`, '')
  //   .split(':')
  //   .slice(0, 2);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const classes = cn(s.trip, className);

  useEffect(() => {
    const button = buttonGroupRef?.current;
    if (button) {
      button.addEventListener('mousemove', e => {
        button.style.setProperty(
          'background-position',
          `${e.clientX - 60}px ${e.clientY - 60}px`
        );
      });

      return () => {
        button.removeEventListener('mousemove', e => {
          button.style.setProperty(
            'background-position',
            `${e.clientX - 60}px ${e.clientY - 60}px`
          );
        });
      };
    }
  }, []);

  const showDate = data =>
    localizedDate(new Date(data.split('T')[0]), 'EEE, dd/MM');

  const goToRefunPage = () => {
    dispatch({
      type: 'UPDATE_TICKETS',
      payload: {
        refund: {
          ...refund,
          ticketNumber: passengers[0].ProductCode,
          shoppingBasketCode,
          isPublic,
          shoppingBasketId,
        },
      },
    });

    if (isPublic) {
      history.push(`/${language}/manage-booking/trips/refund/`);
    } else {
      history.push(`/${language}/profile/trips/refund/`);
    }
  };

  const changeTickets = () => {
    if (isModificationAllowed) {
      dispatch({
        type: 'UPDATE_CHANGE_TICKETS',
        payload: {
          trip,
          currency,
          currencyName,
          symbol,
          search: {
            basketCode: shoppingBasketCode,
            basketId: shoppingBasketId,
            departDate: localizedDate(departureTime, 'yyyy-MM-dd'),
            passengers: passengers.length,
            isOutbound: trip.IsOutbound,
            productCodes: trip.Passengers.map(item => item.ProductCode),
            from: {
              // CountryName: departureTimeZone.split('/')[1],
              CityName: departureCityName,
              BusStopId: departureStopId,
              BusStopName: departureStopName,
              search: departureStopName,
            },
            to: {
              // CountryName: arrivalTimeZone.split('/')[1],
              CityName: destinationCityName,
              BusStopId: destinationStopId,
              BusStopName: destinationStopName,
              search: destinationStopName,
            },
          },
        },
      });

      const showModalFor = [
        'Tallin',
        'Tartu',
        'Riga',
        'Saint-Petersburg',
        'Warsaw',
      ];

      if (showModalFor.includes(destinationCityName)) {
        toggleModalInfo();
      } else {
        history.push(`/${language}/change-tickets/search/`);
      }
    } else {
      toast.error(
        t(
          'the selected ticket is economy class ticket. Economy class tickets cannot be changed'
        ),
        {
          autoClose: 10000,
        }
      );
    }
  };

  return (
    <div className={classes}>
      {!isMob && (
        <>
          <div className={cn(s.decorativeСircle, s.top)}>
            <div className={s.circle} />
          </div>
          <div className={cn(s.decorativeСircle, s.bottom)}>
            <div className={s.circle} />
          </div>
        </>
      )}
      <div className={s.tripContent}>
        <div className={s.main}>
          {isMob && (
            <>
              <div className={cn(s.decorativeСircle, s.top)}>
                <div className={s.circle} />
              </div>
              <div className={cn(s.decorativeСircle, s.bottom)}>
                <div className={s.circle} />
              </div>
            </>
          )}
          <div className={s.timing}>
            <div className={cn(s.stop, s.from)}>
              <div className={s.time}>{showTime(departureTime)}</div>
            </div>
            <div className={s.divider}>
              <div className={cn(s.circle, s.black)} />
              <div className={s.line} />
              <div className={s.circle} />
              <div className={s.line} />
            </div>
            <div className={s.logo}>
              <LazyLoadImage src={`/images/svg/${logoType}.svg`} alt="" />
            </div>
            <div className={cn(s.divider, s.reverse)}>
              <div className={cn(s.circle, s.black)} />
              <div className={s.line} />
              <div className={s.circle} />
              <div className={s.line} />
            </div>
            <div className={cn(s.stop, s.to)}>
              <div className={s.time}>{showTime(arrivalTime)}</div>
            </div>
          </div>
          <div className={s.direction}>
            <div className={cn(s.stop, s.from)}>
              <div className={s.name}>{departureStopName}</div>
              <div className={s.date}>{showDate(departureTime)}</div>
            </div>
            <div className={s.duration}>
              <div className={s.direct}>
                {legs > 1
                  ? `(${t('transfers')}: ${legs})`
                  : `(${t('direction')})`}
              </div>
              {/* {durationDays && `${durationDays}D `}
              {`${duration[0]}H ${duration[1]}M`} */}
            </div>
            <div className={cn(s.stop, s.to)}>
              <div className={s.name}>{destinationStopName}</div>
              <div className={s.date}>{showDate(arrivalTime)}</div>
            </div>
          </div>
        </div>
        {/* {isMob && (
          <Collapse isOpened={showDetails}>
            <div className={s.details}>
              <div className={s.features}>
                <div className={s.block}>
                  <h5>{t('rules')}</h5>
                  <p>{t('Sliding_rules')}</p>
                </div>
              </div>
            </div>
          </Collapse>
        )} */}
        <div className={s.controls}>
          <div
            ref={buttonGroupRef}
            className={s.buttonGroup}
            onClick={() => {
              setShowTickets(prev => !prev);
              setTicketsData({
                ...trip,
                isPast,
              });
            }}
          >
            {t('to tickets')}
          </div>
          <div className={s.info}>
            {isMob && (
              <div className={s.duration}>
                <div className={s.direct}>
                  {legs > 1
                    ? `(${t('transfers')}: ${legs})`
                    : `(${t('direction')})`}
                </div>
              </div>
            )}
            <div
              onClick={toggleDetails}
              className={cn(s.showDetails, showDetails && s.show)}
            >
              {showDetails ? (
                <span>{t('hide details')}</span>
              ) : (
                <span>{t('details')}</span>
              )}
              <LazyLoadImage src="/images/svg/arrowBottom.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* {!isMob && ( */}
      <Collapse isOpened={showDetails}>
        <div className={s.details}>
          <div className={s.features}>
            <div className={s.block}>
              <h5>{t('rules')}</h5>
              <p>{t('Sliding_rules')}</p>
            </div>
          </div>
        </div>
      </Collapse>
      {/* )} */}
      <div className={s.footer}>
        <div className={s.divider} />
        <div className={cn(s.info, s.top)}>
          <div className={cn(s.item, s.pass)}>
            <i className={i.passenger} />
            {passengers.length}
          </div>
          <div
            className={cn(s.item, s.bn)}
            data-title={t('booking number')}
            data-title-center
          >
            <span>{t('booking.number.short')}:</span>
            {shoppingBasketCode}
          </div>
        </div>
        <div className={cn(s.info, s.bot)}>
          {!isPast && (
            <div className={cn(s.item, s.action)} onClick={goToRefunPage}>
              <img src="/images/svg/ticket-refund.svg" alt="" />
              {t('refund')}
            </div>
          )}
          {!isPast && (
            <div className={cn(s.item, s.action)} onClick={changeTickets}>
              <img src="/images/svg/ticket-change.svg" alt="" />
              {t('change')}
            </div>
          )}
          <div className={cn(s.item, s.action)} onClick={getTicketsFileUrl}>
            <img src="/images/svg/ticket-download.svg" alt="" />
            {t('download')}
          </div>
          <div
            className={cn(s.item, s.action)}
            onClick={() => {
              setTicketsData(trip.ShoppingBasketId);
              toggleModal();
            }}
          >
            <img src="/images/svg/ticket-email.svg" alt="" />
            {t('send')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(withTranslation()(memo(Trip)));
