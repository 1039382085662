import gql from 'graphql-tag';

export const FORGOT_PASSWORD = gql`
  mutation($email: String!, $lang: String) {
    forgotPassword(email: $email, lang: $lang) {
      success
      error {
        ErrorCode
        ErrorReason
      }
    }
  }
`;

export const SIGN_UP = gql`
  mutation($input: SignUpInput!) {
    signUp(input: $input) {
      profile {
        phone
        email
        avatar
        lastName
        firstName
        isStaff
        isActive
      }
      token
      errors {
        field
        messages
      }
    }
  }
`;

export const SEND_EMAIL = gql`
  mutation($input: SendEmailInput!) {
    sendEmail(input: $input) {
      emailVerificationCode {
        id
        email
        code
      }
      errors {
        field
        messages
      }
      clientMutationId
    }
  }
`;

export const VERIFY_EMAIL_CODE = gql`
  mutation($input: VerifyEmailCodeInput!) {
    verifyEmailCode(input: $input) {
      emailVerificationCode {
        id
        email
        code
      }
      errors {
        field
        messages
      }
      clientMutationId
    }
  }
`;

export const SIGN_IN = gql`
  mutation($password: String!, $email: String!) {
    signIn(password: $password, email: $email) {
      profile {
        phone
        email
        avatar
        lastName
        firstName
        isStaff
        isActive
        language
        currency
      }
      token
      error {
        ErrorCode
        ErrorReason
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation($password: String!) {
    changePassword(password: $password) {
      success
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation($input: UpdateProfileInput) {
    updateProfile(input: $input) {
      profile {
        id
        phone
        email
        firstName
        lastName
        gender
        avatar
        originalAvatar
        language
        isStaff
        isActive
        currency
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const UPLOAD_AVATAR = gql`
  mutation($file: String!) {
    uploadAvatar(file: $file) {
      url
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation($newPassword: String!, $resetHash: String!) {
    resetPassword(newPassword: $newPassword, resetHash: $resetHash) {
      success
      error {
        ErrorCode
        ErrorReason
      }
    }
  }
`;
