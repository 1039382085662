import React, { useRef, useEffect, useState } from 'react';
import cn from 'classnames';

import { SliderSlides, SliderControls } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './slider.module.scss';

const Slider = ({ withPadding, className, slidesCount, render = () => {} }) => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const scrollerRef = useRef(null);
  const buttonPrevRef = useRef(null);
  const buttonNextRef = useRef(null);

  const easingOutQuint = (x, t, b, c, d) =>
    c * ((t = t / d - 1) * t * t * t * t + 1) + b;

  function smoothScrollPolyfill(node, key, target) {
    const startTime = Date.now();
    const offset = node[key];
    const gap = target - offset;
    const DURATION = 500;
    let interrupt = false;

    const step = () => {
      const elapsed = Date.now() - startTime;
      const percentage = elapsed / DURATION;

      if (interrupt) return;

      if (percentage > 1) {
        cleanup();
        return;
      }

      node[key] = easingOutQuint(0, elapsed, offset, gap, DURATION);
      requestAnimationFrame(step);
    };

    const cancel = () => {
      interrupt = true;
      cleanup();
    };

    const cleanup = () => {
      node.removeEventListener('wheel', cancel);
      node.removeEventListener('touchstart', cancel);
    };

    node.addEventListener('wheel', cancel, { passive: true });
    node.addEventListener('touchstart', cancel, { passive: true });

    step();

    return cancel;
  }

  function testSupportsSmoothScroll() {
    let supports = false;
    try {
      const div = document.createElement('div');
      div.scrollTo({
        top: 0,
        get behavior() {
          supports = true;
          return 'smooth';
        },
      });
    } catch (err) {} // Edge throws an error
    return supports;
  }

  const hasNativeSmoothScroll = testSupportsSmoothScroll();

  function smoothScroll(node, topOrLeft, horizontal) {
    if (hasNativeSmoothScroll) {
      return node.scrollTo({
        [horizontal ? 'left' : 'top']: topOrLeft,
        behavior: 'smooth',
      });
    }
    return smoothScrollPolyfill(
      node,
      horizontal ? 'scrollLeft' : 'scrollTop',
      topOrLeft
    );
  }

  function debounce(func, ms) {
    let timeout;
    return () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func();
      }, ms);
    };
  }

  useEffect(() => {
    const scroller = scrollerRef.current;
    const buttonNext = buttonNextRef.current;
    const buttonPrev = buttonPrevRef.current;

    buttonNext?.addEventListener('click', e => {
      const index = Math.round(
        (scroller.scrollLeft / scroller.scrollWidth) * slidesCount
      );
      const scrollLeft = Math.floor(
        (scroller.scrollWidth / slidesCount) * (index + 1)
      );
      smoothScroll(scroller, scrollLeft, true);
    });

    buttonPrev?.addEventListener('click', e => {
      const index = Math.round(
        (scroller.scrollLeft / scroller.scrollWidth) * slidesCount
      );
      const scrollLeft = Math.floor(
        (scroller.scrollWidth / slidesCount) * (index - 1)
      );
      smoothScroll(scroller, scrollLeft, true);
    });

    scroller?.addEventListener('scroll', () => {
      const index = Math.round(
        (scroller.scrollLeft / scroller.scrollWidth) * slidesCount
      );
      setCurrentSlide(index + 1);
    });
  }, []);

  const classes = cn(s.slider, withPadding && s.withPadding, className);

  return (
    <div className={classes}>
      {render({
        slidesCount,
        currentSlide: currentSlide - 1,
        scrollerRef,
        buttonPrevRef,
        buttonNextRef,
      })}
    </div>
  );
};

export default Slider;
