import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Button,
  Text,
  PageLoading,
  Container,
  Divider,
  Editor,
  Slider,
  SliderSlides,
  SliderControls,
  EditSlider,
  UploadImages,
  LazyLoadImage,
} from '@lux/ui';
import { meta, EmptySlider } from '@lux/spa/components';
import { NotFound } from '@lux/spa/routes';
import {
  GET_STATIC_PAGE,
  GET_STATIC_PAGE_ADMIN,
} from '@lux/spa/apollo/queries';
import {
  CREATE_STATIC_PAGE,
  UPDATE_STATIC_PAGE,
} from '@lux/spa/apollo/mutations';
import i from '@lux/spa/styles/icons.module.scss';
import { AboutLux, emptyAboutLux } from './components';
import { emptyData, defaultData } from './constants';
import s from './about-us.module.scss';

const AboutUsContent = ({
  t,
  slugs,
  region,
  language,
  updatePage,
  content,
  lang,
  isEditMode,
  isStaff,
  className,
}) => {
  const [isBannerModal, setIsBannerModal] = useState(false);
  const [isSlider, setIsSlider] = useState(false);

  const toggleModal = () => {
    setIsBannerModal(!isBannerModal);
  };

  const toggleSlider = () => {
    setIsSlider(!isSlider);
  };

  const { pageData = {}, seo = {} } = content;

  const {
    title,
    subTitle,
    banner,
    heading1,
    heading2,
    title1,
    title2,
    title3,
    title4,
    title5,
    title6,
    text1,
    text2,
    text3,
    text4,
    text5,
    text6,
    description,
    description_1: description1,
    quoteText,
    quoteAuthor,
    slider,
  } = pageData;

  const backgroundImage = banner.value
    ? `linear-gradient(90deg, #000000d9, transparent), url(${banner.value})`
    : `linear-gradient(90deg, #000000d9, transparent)`;

  const [createStaticPage, { data, loading }] = useMutation(CREATE_STATIC_PAGE);

  // NOTE: for create Static Page
  // 1. set one country in constants.js for this page => emptyData.countries: ['ee']
  // 2. set same country in field country
  // 3. set slug

  const defaultLang = 'en';

  const handleCreateStaticPage = () => {
    createStaticPage({
      variables: {
        input: {
          isPublished: true,
          slug: 'about-us',
          country: 'ee',
          [defaultLang]: {
            ...emptyData,
          },
        },
      },
    });
  };

  return (
    <div className={cn(className, s.aboutUs)}>
      <Helmet {...meta({ seo, lang: language, slugs })} />
      {isBannerModal && (
        <UploadImages
          isOpen={isBannerModal}
          toggleModal={toggleModal}
          updatePage={updatePage}
          image={banner}
        />
      )}
      {isSlider && (
        <EditSlider
          emptySlide={emptyAboutLux}
          slider={slider}
          isOpen={isSlider}
          updatePage={updatePage}
          toggleModal={toggleSlider}
        >
          <AboutLux />
        </EditSlider>
      )}
      <Container
        className={s.bannerContainer}
        style={{
          backgroundImage,
        }}
      >
        <Container full={false}>
          <section className={s.banner}>
            {isEditMode && (
              <Button
                className={s.uploadBanner}
                text={banner.value ? t('edit photo') : t('upload photo')}
                onClick={toggleModal}
              />
            )}
            {/* <Button text="Create page" onClick={handleCreateStaticPage} /> */}
            <h1>
              <Text onChange={updatePage} field={title} />
            </h1>
            <h1 data-v2>
              <Text onChange={updatePage} field={subTitle} />
            </h1>
          </section>
        </Container>
      </Container>
      <Divider />
      <Container className={s.description}>
        <div className={s.greyBlock} />
        <Container full={false} className={s.descriptionContainer}>
          <section>
            <h2 className={s.title}>
              <Text onChange={updatePage} field={heading1} />
            </h2>
            <div className={s.texts}>
              <div className={s.block}>
                {(isEditMode || description) && (
                  <Editor
                    className={s.text}
                    placeholder={t('description')}
                    html={description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      updatePage({
                        description: data,
                      });
                    }}
                  />
                )}
                {(isEditMode || description1) && (
                  <Editor
                    className={s.text}
                    placeholder={t('description')}
                    html={description1}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      updatePage({
                        description_1: data,
                      });
                    }}
                  />
                )}
              </div>
              {(isEditMode || quoteText.value) && (
                <div className={s.quote}>
                  <i className={i.quote} />
                  <h4>
                    <Text onChange={updatePage} field={quoteText} />
                  </h4>
                  <div className={s.signature}>
                    <div clasaName={s.line} />
                    <Text onChange={updatePage} field={quoteAuthor} />
                  </div>
                </div>
              )}
            </div>
            {slider.slides.length ? (
              <Slider
                className={s.slider}
                slidesCount={slider.slides.length}
                render={sliderProps => (
                  <>
                    {isEditMode && (
                      <Button
                        className={s.editSlider}
                        text={t('edit slider')}
                        onClick={toggleSlider}
                      />
                    )}
                    <SliderSlides {...sliderProps} className={s.slides}>
                      {slider.slides.map((slide, key) => (
                        <div key={key} className={s.slide}>
                          <LazyLoadImage
                            src={slide.img.value}
                            alt={slide.alt.value}
                          />
                        </div>
                      ))}
                    </SliderSlides>
                    <SliderControls className={s.controls} {...sliderProps} />
                  </>
                )}
              />
            ) : isEditMode ? (
              <EmptySlider toggleEditSlider={toggleSlider} />
            ) : null}
          </section>
        </Container>
      </Container>
      <Container className={s.facts}>
        <div className={s.greyBlock} />
        <Container full={false}>
          <section>
            <h2 className={s.title}>
              <Text onChange={updatePage} field={heading2} />
            </h2>
            <div className={s.facts}>
              <div className={s.fact}>
                <h3 className={s.name}>
                  <Text onChange={updatePage} field={title1} />
                </h3>
                <p className={s.description}>
                  <Text onChange={updatePage} field={text1} />
                </p>
              </div>
              <div className={s.fact}>
                <h3 className={s.name}>
                  <Text onChange={updatePage} field={title2} />
                </h3>
                <p className={s.description}>
                  <Text onChange={updatePage} field={text2} />
                </p>
              </div>
              <div className={s.fact}>
                <h3 className={s.name}>
                  <Text onChange={updatePage} field={title3} />
                </h3>
                <p className={s.description}>
                  <Text onChange={updatePage} field={text3} />
                </p>
              </div>
              <div className={s.fact}>
                <h3 className={s.name}>
                  <Text onChange={updatePage} field={title4} />
                </h3>
                <p className={s.description}>
                  <Text onChange={updatePage} field={text4} />
                </p>
              </div>
              <div className={s.fact}>
                <h3 className={s.name}>
                  <Text onChange={updatePage} field={title5} />
                </h3>
                <p className={s.description}>
                  <Text onChange={updatePage} field={text5} />
                </p>
              </div>
              <div className={s.fact}>
                <h3 className={s.name}>
                  <Text onChange={updatePage} field={title6} />
                </h3>
                <p className={s.description}>
                  <Text onChange={updatePage} field={text6} />
                </p>
              </div>
            </div>
          </section>
        </Container>
      </Container>
      {/* <Container className={s.partners}>
        <Container full={false}>
          <section>
            <div className={s.logo}>
              <LazyLoadImage src="/images/about-us/logos/mg.svg" alt=""/>
            </div>
            <div className={s.logo}>
              <LazyLoadImage src="/images/about-us/logos/timeless.svg" alt="" />
            </div>
            <div className={s.logo}>
              <LazyLoadImage src="/images/about-us/logos/lux.svg" alt="" />
            </div>
            <div className={s.logo}>
              <LazyLoadImage
                src="/images/about-us/logos/luxcharter.svg"
                alt=""
              />
            </div>
            <div className={s.logo}>
              <LazyLoadImage
                src="/images/about-us/logos/le-bus-service.svg"
                alt=""
              />
            </div>
            <div className={s.logo}>
              <LazyLoadImage src="/images/about-us/logos/busland.svg" alt="" />
            </div>
            <div className={s.logo}>
              <LazyLoadImage src="/images/about-us/logos/t-pilet.svg" alt="" />
            </div>
            <div className={s.logo}>
              <LazyLoadImage
                src="/images/about-us/logos/tallina-bussijaam.svg"
                alt=""
              />
            </div>
            <div className={s.logo}>
              <LazyLoadImage src="/images/about-us/logos/sebe.svg" alt="" />
            </div>
            <div className={s.logo}>
              <LazyLoadImage src="/images/about-us/logos/turnit.svg" alt="" />
            </div>
            <div className={s.logo}>
              <LazyLoadImage src="/images/about-us/logos/milrem.svg" alt="" />
            </div>
          </section>
        </Container>
      </Container> */}
    </div>
  );
};

const AboutUs = ({
  t,
  lang,
  match,
  i18n,
  isStaff,
  region,
  isEditMode,
  page = { en: defaultData },
  dispatch = () => {},
  ...props
}) => {
  const slug = 'about-us';
  const { language } = i18n;
  const QUERY = isStaff ? GET_STATIC_PAGE_ADMIN : GET_STATIC_PAGE;

  const variables = {
    slug,
    lang: language,
    country: region,
  };

  const { data, loading } = useQuery(QUERY, { variables });

  const updatePage = field => {
    dispatch({
      type: 'UPDATE_DATA',
      payload: {
        field,
      },
    });
  };

  useEffect(() => {
    if (isStaff) {
      dispatch({
        type: 'UPDATE',
        payload: {
          defaultData,
          emptyData,
          CREATE_PAGE: CREATE_STATIC_PAGE,
          UPDATE_PAGE: UPDATE_STATIC_PAGE,
          originalPage: data?.adminStaticPage,
          page: data?.adminStaticPage,
        },
      });
    }
  }, [data]);

  // useEffect(() => {
  //   dispatch({
  //     type: 'UPDATE',
  //     payload: {
  //       loading
  //     }
  //   })
  // }, [loading])

  useEffect(() => {
    const isExistLang = page?.[lang];

    if (isEditMode && !isExistLang) {
      dispatch({
        type: 'UPDATE_PAGE',
        payload: {
          [lang]: {
            ...emptyData,
            lang,
            countries: [region],
          },
        },
      });
    }
  }, [isEditMode]);

  // const content = {
  //   ...(isStaff ? {
  //     ...emptyData,
  //     ...page?.[lang]
  //   } : {
  //     ...
  //   })
  // } : {
  //   lang: language,
  //   slug,
  //   ...data.staticPage?.data
  // })
  // }

  const isExistLangForPage = !!page?.[lang];

  const content = isStaff
    ? {
        ...defaultData,
        ...page?.[lang],
      }
    : data?.staticPage?.data;

  // useEffect(() => {
  //   if (isExistLangForPage && isStaff) {
  //     toast.warn(`there is no translation in ${lang} for ${countries[region]}`)
  //   }
  // }, [isExistLangForPage])

  const slugs = {
    en: {
      value: slug,
    },
    et: {
      value: slug,
    },
    lv: {
      value: slug,
    },
    lt: {
      value: slug,
    },
    ru: {
      value: slug,
    },
  };

  return loading ? (
    <PageLoading />
  ) : content || isStaff ? (
    <AboutUsContent
      slugs={slugs}
      content={{
        ...content,
        seo: {
          ...content.seo,
          slug: {
            ...content.seo.slug,
            value: slug,
          },
        },
        pageData: {
          ...defaultData.pageData,
          ...content.pageData,
        },
      }}
      {...{ updatePage, language, isEditMode, isStaff, region, t }}
      {...props}
    />
  ) : (
    <NotFound />
  );
};

export default withTranslation()(AboutUs);
