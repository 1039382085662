import React, { useContext } from 'react';
import cn from 'classnames';
import { WidthContext } from '@lux/spa/contexts/width-context';
import i from '@lux/spa/styles/icons.module.scss';
import s from './tooltip.module.scss';

const Tooltip = ({
  title,
  style,
  className,
  children,
  contentClass,
  position = 'top',
  centred = true,
}) => {
  const { isMob } = useContext(WidthContext);
  const classes = cn(
    s.tooltip,
    s[position],
    isMob && centred && s.center,
    className
  );
  return (
    <div className={classes} style={style}>
      <div className={s.target}>{title || <i className={i.infoCircle} />}</div>
      <div className={cn(s.content, contentClass)}>
        <div className={s.wrap}>
          <div className={s.triangle} />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Tooltip;
