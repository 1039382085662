import React, { useEffect, useState, useReducer } from 'react';
import cn from 'classnames';

import { Button, Input, Dropzone, Loading, Textarea } from '@lux/ui';
import { useMutation } from '@apollo/react-hooks';
import { UPLOAD_IMAGES } from '@lux/spa/apollo/mutations';
import s from './lounge-slide.module.scss';

const slideReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_SLIDE':
      return {
        ...state,
        ...action.payload.field,
      };
  }
};

const LoungeSlide = ({
  className,
  toggleEditSlide,
  slide = {},
  slides,
  sliderDispatch,
  ...props
}) => {
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, slideDispatch] = useReducer(slideReducer, slide);

  const { alt = {}, img = {}, title = {}, subTitle = {}, order } = state;

  const isNew = order > slides.length;
  const type = isNew ? 'ADD_SLIDE' : 'UPDATE_SLIDE';

  const updateSlide = field => {
    slideDispatch({
      type: 'UPDATE_SLIDE',
      payload: {
        field,
      },
    });
  };

  const [uploadImages, { data, loading, error }] = useMutation(UPLOAD_IMAGES);

  const removeImage = e => {
    e?.stopPropagation();

    updateSlide({
      [img.fieldName]: {
        ...img,
        value: '',
      },
    });
  };

  const saveSlide = () => {
    if (img.value && /^data:image/.test(img.value)) {
      uploadImages({
        variables: {
          input: [
            {
              file: img.value,
              name: img.name,
            },
          ],
        },
      });
    } else {
      sliderDispatch({
        type,
        payload: {
          slide: state,
        },
      });
      toggleEditSlide();
    }
  };

  useEffect(() => {
    if (data?.uploadImages?.success) {
      sliderDispatch({
        type,
        payload: {
          slide: {
            ...state,
            img: {
              ...img,
              value: data?.uploadImages?.urls[0],
            },
          },
        },
      });
      toggleEditSlide();
    }
  }, [data?.uploadImages?.success]);

  return (
    <div className={cn(s.loungeSlide, className)}>
      <Input
        className={s.input}
        label={title.placeholder}
        value={title.value}
        onChange={e =>
          updateSlide({
            [title.fieldName]: {
              ...title,
              value: e.target.value,
            },
          })
        }
      />
      <Textarea
        className={s.input}
        label={subTitle.placeholder}
        value={subTitle.value}
        onChange={e =>
          updateSlide({
            [subTitle.fieldName]: {
              ...subTitle,
              value: e.target.value,
            },
          })
        }
      >
        {subTitle.value}
      </Textarea>
      <Input
        className={s.input}
        label={alt.placeholder}
        value={alt.value}
        onChange={e =>
          updateSlide({
            [alt.fieldName]: {
              ...alt,
              value: e.target.value,
            },
          })
        }
      />
      <Dropzone
        className={s.dropzone}
        onDrop={(file, height, width, base64, name) => {
          updateSlide({
            [img.fieldName]: {
              ...img,
              name,
              value: base64,
            },
          });
        }}
        loading={loading}
        preview={img}
        removeImage={removeImage}
      />
      <div className={s.controls}>
        <Button text="Save" onClick={saveSlide} />
        <Button text="Cancel" onClick={toggleEditSlide} />
      </div>
    </div>
  );
};

export default LoungeSlide;
