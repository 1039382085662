import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Query } from 'react-apollo';
import { Loading, Button, Notice } from '@lux/ui';
import { DEFAULT_LANG } from '@lux/spa/config';
import { GET_PAYMENT_METHODS } from '@lux/spa/apollo/queries';
import i from '@lux/spa/styles/icons.module.scss';
import s from './saved-cards.module.scss';

const Card = ({
  id,
  name,
  lastFour,
  t,
  className,
  selected,
  cardNumber,
  selectCard,
}) => {
  // const [isConfirmRemove, setIsConfirmRemove] = useState(false);
  // const toggleConfirmRemove = () => {
  //   setIsConfirmRemove(!isConfirmRemove);
  // };
  // const [removeCard] = useMutation(REMOVE_PAYMENT_METHOD);

  return (
    <div className={s.card} onClick={() => selectCard(cardNumber)}>
      <div className={s.catdTitle}>
        <div className={cn(s.check, selected && s.checked)} />
        {/* <div className={s.logo}>logo</div> */}
        <div className={s.name}>{name}</div>
        <div className={s.lastFour}>••••{lastFour}</div>
      </div>
    </div>
  );
};

const SavedCards = ({
  t,
  history,
  className,
  adyenPayment,
  language,
  shoppingBasketId,
  adyenLoading,
}) => {
  const [selectedCard, setSelectedCard] = useState(1);
  const [hasStatus, setHasStatus] = useState({
    status: null,
    text: '',
  });

  const selectCard = number => {
    setSelectedCard(number);
  };

  return (
    <div className={cn(s.savedCards, className)}>
      <h3>Saved cards</h3>
      {adyenLoading ? (
        <Loading />
      ) : hasStatus.status ? (
        !hasStatus.status === 'error' ? (
          <div className={cn(s.status, s.error)}>
            <i className={cn(i.close)} />
            {hasStatus.text}
          </div>
        ) : (
          <div className={cn(s.status, s.success)}>
            <img src="/images/svg/checked.svg" alt="" />
            {hasStatus.text}
          </div>
        )
      ) : (
        <Query query={GET_PAYMENT_METHODS}>
          {({ data, loading, error }) => {
            if (loading) return <Loading />;
            // const userPaymentsMethods = data?.userPaymentsMethods || [];
            const userPaymentsMethods =
              [
                ...data?.userPaymentsMethods,
                // ...data?.userPaymentsMethods,
                // ...data?.userPaymentsMethods,
                // ...data?.userPaymentsMethods,
                // ...data?.userPaymentsMethods,
                // ...data?.userPaymentsMethods,
              ] || [];
            return userPaymentsMethods.length ? (
              <>
                {userPaymentsMethods.map((card, key) => (
                  <Card
                    {...card}
                    selectCard={selectCard}
                    selected={selectedCard === key + 1}
                    cardNumber={key + 1}
                    key={key}
                  />
                ))}
                <Button
                  text={t('pay')}
                  onClick={() =>
                    adyenPayment({
                      variables: {
                        input: {
                          storedPaymentMethodId:
                            userPaymentsMethods[selectedCard - 1].id,
                          channel: 'Web',
                          returnUrl: `${window.location.origin}/tickets/thanks?type=adyen`,
                        },
                      },
                    }).then(data => {
                      const success = data?.data?.adyenPayment?.success;
                      const error = data?.data?.adyenPayment?.error;

                      if (error) {
                        // setError(error.ErrorReason);
                        console.log('error', error);
                        setHasStatus({
                          status: 'error',
                          text: error.ErrorReason,
                        });

                        setTimeout(() => {
                          setHasStatus({
                            status: null,
                            text: '',
                          });
                        }, 20000);

                        return;
                      }

                      if (success) {
                        if (data.data.adyenPayment.action) {
                          const { action = {} } = data.data.adyenPayment;
                          switch (action?.type) {
                            case 'redirect':
                              localStorage.setItem(
                                'details',
                                JSON.stringify(data.data.adyenPayment.details)
                              );
                              const url = `${action.url}?${Object.keys(
                                action.data
                              )
                                .map(key => `${key}=${action.data[key]}`)
                                .join('&')}`;
                              window.location.href = url;
                              break;
                            case 'qrCode':
                              console.log('qrCode action');
                              break;
                            case 'voucher':
                              console.log('voucher action');
                              break;
                            case 'await':
                              console.log('await action');
                              break;
                            case 'sdk':
                              console.log('sdk action');
                              break;
                            case 'threeDS2Fingerprint':
                              console.log('threeDS2Fingerprint action');
                              // adyenPaymentDetails({
                              //   variables: {
                              //     input: {
                              //       orderId: shoppingBasketId,
                              //       details: {},
                              //     },
                              //   },
                              //   onCompleted: data => {
                              //     console.log('adyenPaymentDetails data', data);
                              //   },
                              // });
                              break;
                            case 'threeDS2Challenge':
                              console.log('threeDS2Challenge action');
                              break;
                            default:
                              console.log('default');
                              break;
                          }
                        } else {
                          setHasStatus({
                            status: 'success',
                            text: 'success',
                          });

                          setTimeout(() => {
                            setHasStatus({
                              status: null,
                              text: '',
                            });
                          }, 2000);
                          // setTimeout(() => {
                          //   history.push(
                          //     language === DEFAULT_LANG
                          //       ? `/tickets/thanks?type=adyen&shopperOrder=${shoppingBasketId}`
                          //       : `/${language}/tickets/thanks?type=adyen&shopperOrder=${shoppingBasketId}`
                          //   );
                          // }, 2000);
                        }
                      }
                    })
                  }
                />
              </>
            ) : (
              <div className={s.noCards}>
                <h2>No saved cards</h2>
                <p>
                  The card will be added automatically after you make a purchase
                </p>
              </div>
            );
          }}
        </Query>
      )}
    </div>
  );
};

export default SavedCards;
