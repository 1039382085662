import React, { useContext, useEffect } from 'react';
import cn from 'classnames';
import { Formik } from 'formik';
import { withRouter } from 'react-router';
import { Trans, withTranslation } from 'react-i18next';
import { MODIFICATION_INFO } from '@lux/spa/apollo/mutations';
import { StoreContext } from '@lux/spa/store';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { Container, Link, Button, TicketsInput } from '@lux/ui';
import s from './manage-booking.module.scss';

const ManageBooking = ({ t, i18n, className, history }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { language } = i18n;
  const { email = '' } = state.user;
  const [modificationInfo, { loading }] = useMutation(MODIFICATION_INFO);

  useEffect(() => {
    dispatch({
      type: 'RESET_REFUND',
    });
  }, []);
  return (
    <div className={cn(className, s.manageBooking)}>
      <Container>
        <Container full={false}>
          <section>
            <Formik
              validateOnMount
              enableReinitialize
              initialValues={{
                email,
                ticketNumber: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email(
                    t('validation.error.email', {
                      field: t('email'),
                    })
                  )
                  .required(
                    t('validation.error.require', {
                      field: t('email'),
                    })
                  ),
                ticketNumber: Yup.string().required(
                  t('validation.error.require', {
                    field: t('ticket number'),
                  })
                ),
              })}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  setFieldError,
                } = props;
                return (
                  <form className={s.form}>
                    <h1 className={s.title}>{t('manage booking')}</h1>
                    <div className={s.content}>
                      <p>
                        {t(
                          'please enter the information in the fields below to find and manage your booking.'
                        )}
                      </p>
                      <div className={s.input}>
                        <TicketsInput
                          autoFocus
                          name="email"
                          label={t('email')}
                          type="text"
                          required
                          value={values.email}
                          onChange={e => {
                            e.target.value = e.target.value.trim();
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          errors={touched.email && errors.email}
                        />
                      </div>
                      <div className={s.input}>
                        <TicketsInput
                          autoFocus
                          name="ticketNumber"
                          label={t('ticket number')}
                          placeholder={t('ticket number')}
                          type="text"
                          required
                          value={values.ticketNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errors={touched.ticketNumber && errors.ticketNumber}
                        />
                      </div>
                      <Button
                        disabled={
                          loading || errors.email || errors.ticketNumber
                        }
                        className={s.button}
                        text={loading ? t('loading') : t('submit')}
                        onClick={() => {
                          modificationInfo({
                            variables: {
                              Email: values.email,
                              TicketNumber: values.ticketNumber,
                              lang: language,
                            },
                          })
                            .then(data => {
                              const { error } = data.data.modificationInfo;
                              if (error) {
                                if (
                                  error.ErrorCode === 'ticket_not_found' ||
                                  error.ErrorCode === 'invalid_email'
                                ) {
                                  toast.error(
                                    t('manage.booking.form.error.notice'),
                                    {
                                      autoClose: 20000,
                                    }
                                  );
                                } else {
                                  toast.error(error.ErrorReason);
                                }
                              } else {
                                dispatch({
                                  type: 'UPDATE_TICKETS',
                                  payload: {
                                    refund: {
                                      email: values.email,
                                      ticketNumber: values.ticketNumber,
                                      modificationInfo:
                                        data.data.modificationInfo,
                                    },
                                  },
                                });
                                history.push(
                                  `/${language}/manage-booking/trips/`
                                );
                              }
                            })
                            .catch(() => {
                              toast.error('Something wrong!');
                            });
                        }}
                      />
                      <p>
                        <Trans i18nKey="manage.booking.text.v2">
                          NB! You can request a refund/voucher in the previously
                          previously unexchanged tickets to future dates If your
                          If your ticket has been changed before, please
                          <Link className={s.colorRed} to="/contacts/">
                            {` ${t('contact us')}`}
                          </Link>
                        </Trans>
                      </p>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withRouter(withTranslation()(ManageBooking));
