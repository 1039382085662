export const initialStateUser = {
  name: '',
  phoneNumber: '',
  firstName: '',
  lastName: '',
  email: '',
  gender: '',
  language: 'ru',
  currency: 'CURRENCY.RUB',
  // currencyName: 'EUR',
  // currencySymbol: '€',
};

const userReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_USER':
      return {
        ...state,
        ...action.payload,
      };

    case 'LOG_OUT':
      return initialStateUser;
    default:
      return state;
  }
};

export default userReducer;
