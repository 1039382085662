import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { Formik } from 'formik';
import { withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { isMobile } from 'react-device-detect';
import { RESET_PASSWORD } from '@lux/spa/apollo/mutations';
import { DEFAULT_LANG } from '@lux/spa/config';
import { Container, HiddenInput, Button } from '@lux/ui';
import s from './reset-password.module.scss';

const ResetPassword = ({ t, i18n, history, className, location }) => {
  const { language } = i18n;

  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const [success, setSucces] = useState(false);
  const [error, setError] = useState(false);
  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onError: () => {
      setSucces(false);
      setError(true);
    },
    onCompleted: data => {
      if (data.resetPassword.success) {
        setError(false);
        setSucces(true);
      } else {
        setError(true);
        setSucces(false);
      }
    },
  });

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
        setSucces(false);
      }, 6000);
    }

    if (success) {
      setTimeout(() => {
        history.push(
          `${DEFAULT_LANG === language ? '' : `/${language}`}/login/`
        );
      }, 6000);
    }
  }, [error, success]);

  return (
    <div className={cn(className, s.resetPassword)}>
      <Container>
        <Container full={false}>
          <section className={(success || error) && s.status}>
            {success || error ? (
              <>
                {error && (
                  <>
                    <img src="/images/svg/reset-password-error.svg" alt="" />
                    <h5>{t('error')}!</h5>
                    <p>{t('message from beckend.')}</p>
                    <p>{t('click “back” to try one more time.')}</p>
                    <Button
                      className={s.button}
                      text={t('back')}
                      onClick={() => {
                        setError(false);
                        setSucces(false);
                      }}
                    />
                    <span>
                      {t('you will be automatically redirected in sec.', {
                        count: 6,
                      })}
                    </span>
                  </>
                )}
                {success && (
                  <>
                    <img src="/images/svg/reset-password-success.svg" alt="" />
                    <h5>{t('password is updated!')}</h5>
                    <p>
                      {t(
                        'your password has been successfully updated. click continue to go to the log in page.'
                      )}
                    </p>
                    <Button
                      className={s.button}
                      text={t('continue')}
                      to={`${
                        DEFAULT_LANG === language ? '' : `/${language}`
                      }/login`}
                    />
                    <span>
                      {t('you will be automatically redirected in sec.', {
                        count: 6,
                      })}
                    </span>
                  </>
                )}
              </>
            ) : (
              <>
                <div className={s.image}>
                  <img src="/images/svg/reset-password.svg" alt="" />
                </div>
                <Formik
                  validateOnMount
                  enableReinitialize
                  initialValues={{
                    password: '',
                    confirmPassword: '',
                  }}
                  validationSchema={Yup.object().shape({
                    password: Yup.string()
                      .required(
                        t('validation.error.require', {
                          field: t('password'),
                        })
                      )
                      .min(
                        6,
                        t('validation.error.min', {
                          field: t('password'),
                          min: 6,
                        })
                      ),
                    confirmPassword: Yup.string()
                      .required(
                        t('validation.error.require', {
                          field: t('confirm password'),
                        })
                      )
                      .oneOf(
                        [Yup.ref('password'), null],
                        t('passwords must match')
                      ),
                  })}
                >
                  {props => {
                    const {
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                    } = props;

                    return (
                      <form className={s.form}>
                        <h2 className={s.title}>{t('reset password')}</h2>
                        <h5>{t('come up with a password')}</h5>
                        <p className={s.text}>
                          {t('password must be at least characters', {
                            count: 6,
                          })}
                        </p>
                        <div className={s.input}>
                          <HiddenInput
                            autoFocus
                            name="password"
                            placeholder="••••••"
                            label={t('password')}
                            required
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errors={touched.password && errors.password}
                          />
                        </div>
                        <div className={s.input}>
                          <HiddenInput
                            name="confirmPassword"
                            placeholder="••••••"
                            label={t('confirm password')}
                            required
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errors={
                              touched.confirmPassword && errors.confirmPassword
                            }
                          />
                        </div>
                        <div className={s.controls}>
                          <Button
                            disabled={errors.password || errors.confirmPassword}
                            className={s.button}
                            text={t('continue')}
                            onClick={() => {
                              resetPassword({
                                variables: {
                                  newPassword: values.password,
                                  resetHash: token,
                                },
                              });
                            }}
                          />
                          {isMobile && (
                            <Button
                              className={s.button}
                              text={t('in app')}
                              to={`luxexpress://?type=reset-password&token=${token}`}
                            />
                          )}
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </>
            )}
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withRouter(withTranslation()(ResetPassword));
