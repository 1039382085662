import React, { useState, useContext } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { Divider, Container, EditWidget } from '@lux/ui';
import { Features, Banner } from '@lux/spa/components';
import s from './traveling.module.scss';

const Traveling = ({ t, home }) => {
  const { isLap } = useContext(WidthContext);
  return (
    <Container className={cn(s.traveling, home && s.home)}>
      <div className={s.greyBlock} />
      <Container full={false}>
        <section>
          <h2 className={s.title}>{t('why passengers prefer lux express')}</h2>
          <div className={s.info}>
            <div className={s.articles}>
              <article>
                <h5>{t('wiget.traveling.title1')}</h5>
                <p>{t('wiget.traveling.description1')}</p>
              </article>
              <article>
                <h5>{t('wiget.traveling.title2')}</h5>
                <p>{t('wiget.traveling.description2')}</p>
              </article>
              <article>
                <h5>{t('wiget.traveling.title3')}</h5>
                <p>{t('wiget.traveling.description3')}</p>
              </article>
              <article>
                <h5>{t('wiget.traveling.title4')}</h5>
                <p>{t('wiget.traveling.description4')}</p>
              </article>
            </div>
            <Banner className={s.banner} horizontal={!isLap} />
          </div>
          <Features className={s.features} />
        </section>
      </Container>
    </Container>
  );
};

export default withTranslation()(Traveling);
