import React, { forwardRef } from 'react';
import cn from 'classnames';
import * as uuid from 'uuid';
import MaskedInput from 'react-text-mask';
import s from './card-input.module.scss';

const CardInput = forwardRef(
  ({
    required = false,
    isError,
    errors,
    label,
    className,
    withoutMessage = false,
    errorWithoutMessage = false,
    ...props
  }) => {
    const id = uuid.v4();
    const classes = cn(
      s.cardInput,
      (errors || errorWithoutMessage) && s.error,
      withoutMessage && s.withoutMessage,
      className
    );
    return (
      <div className={classes}>
        {label && (
          <label htmlFor={id}>{`${label}${required ? ' *' : ''}`}</label>
        )}
        <div className={s.input}>
          <MaskedInput {...props} id={id} />
          <div className={s.cards}>
            <div className={s.card}>
              <img src="/images/svg/visa-card.svg" alt="" />
            </div>
            <div className={s.card}>
              <img src="/images/svg/master-card.svg" alt="" />
            </div>
          </div>
        </div>
        {errors && <div className={s.message}>{errors}</div>}
      </div>
    );
  }
);

export default CardInput;
