import gql from 'graphql-tag';

export const GET_ALL_STORIES = gql`
  query(
    $lang: String!
    $country: String!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    stories(
      lang: $lang
      country: $country
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          publishDate
          isPublished
          data {
            seo
            lang
            slug
            countries
            pageData
          }
        }
        cursor
      }
      count
    }
  }
`;

export const GET_ALL_STORIES_ADMIN = gql`
  query(
    $lang: String!
    $country: String!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    adminStories(
      lang: $lang
      country: $country
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          publishDate
          isPublished
          data {
            seo
            lang
            slug
            countries
            pageData
          }
        }
        cursor
      }
      count
    }
  }
`;

// export const GET_DESTINATIONS_GROUPED = gql`
//   query ($lang: String!, $country: String!) {
//     destinationsGrouped(lang: $lang, country: $country) {
//       countryId
//     	countryName
//       destinations {
//         id
//         data {
//           seo
//           pageData
//         }
//       }
//     }
//   }
// `
