import gql from 'graphql-tag';

export const GET_ALL_DESTINATIONS = gql`
  query($lang: String!, $country: String!) {
    destinations(lang: $lang, country: $country) {
      id
      publishDate
      isPublished
      city {
        id
        name
      }
      # slugs
      data {
        seo
        lang
        slug
        countries
        pageData
        # isPublished
      }
    }
  }
`;

export const GET_ALL_DESTINATIONS_ADMIN = gql`
  query($lang: String!, $country: String!) {
    adminDestinations(lang: $lang, country: $country) {
      id
      publishDate
      isPublished
      city {
        id
        name
      }
      # slugs
      data {
        seo
        lang
        slug
        countries
        pageData
        # isPublished
      }
    }
  }
`;

export const GET_DESTINATIONS_GROUPED = gql`
  query($lang: String!, $country: String!) {
    destinationsGrouped(lang: $lang, country: $country) {
      id
      countryId
      countryName
      destinations {
        id
        city {
          id
          name
        }
        data {
          seo
          pageData
        }
      }
    }
  }
`;
