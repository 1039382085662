import gql from 'graphql-tag';

export const MODIFICATION_INFO = gql`
  mutation($Email: String!, $TicketNumber: String!, $lang: String) {
    modificationInfo(Email: $Email, TicketNumber: $TicketNumber, lang: $lang) {
      error {
        ErrorCode
        ErrorReason
      }
      ways {
        IsOutbound
        DepartureTime
        DepartureTimeZone
        ArrivalTime
        ArrivalTimeZone
        DepartureStopName
        DepartureStopId
        DepartureCityName
        DepartureCityId
        DestinationStopName
        DestinationStopId
        DestinationCityName
        DestinationCityId
        ShoppingBasketCode
        ShoppingBasketId
        ActiveModificationSessionId
        ShoppingBasketExportCode
        IsCorporateClientContractPurchase
        Legs {
          OrderNumber
          DepartureTime
          DepartureTimeZone
          ArrivalTime
          ArrivalTimeZone
          PlannedDepartureTime
          PlannedDepartureTimeZone
          PlannedArrivalTime
          PlannedArrivalTimeZone
          DepartureStopName
          DepartureCityName
          DeparturePlatforms
          DestinationStopName
          DestinationCityName
          BrandName
          ServiceCode
          LineNumber
          TripName
          SubcontractorName
        }
        Passengers {
          ProductCode
          ExternalTicketNumber
          IsPartOfRoundTrip
          PassengerName
          PassengerCategory
          IsModificationAllowed
          Tickets {
            CouponNumber
            LegOrderNumber
            Price
            Currency
            QrCode
          }
          Seats {
            BusNameTag
            LegOrderNumber
            SeatOrderNumber
            SeatClass
            SeatNumber
            SeatChangeStop
            SeatFloorNumber
            HasSeatBeenReleased
            Price
            Currency
          }
        }
        BasketAddons {
          AddonName
          Description
          Price
          Currency
          Quantity
        }
      }
      basketData {
        Code
        Guid
        Products {
          Code
          Guid
          Status
        }
      }
    }
  }
`;

export const CALCULATE_RETURN = gql`
  mutation(
    $ProductCodes: [String]!
    $ShoppingBasketId: String!
    $lang: String
    $refundPaymentMethod: String
  ) {
    calculateReturn(
      ProductCodes: $ProductCodes
      ShoppingBasketId: $ShoppingBasketId
      lang: $lang
      refundPaymentMethod: $refundPaymentMethod
    ) {
      error {
        ErrorCode
        ErrorReason
      }
      returnInfo {
        Id
        ReturningAllowed
        RefundPaymentMethod
        PaymentDetails {
          ReferenceNumber
          PaidAmount
          Currency
          PaymentMethodIdentificator
          PaymentType
        }
        RefundableProducts {
          ProductCode
          ExternalTicketNumber
          RefundingScopes {
            RefundingScopeXc
            RefundingScope
            TotalPrice
            ServiceFee
            RefundableAmount
            RefundAmountWithServiceFee
          }
        }
      }
    }
  }
`;

export const COMMIT_RETURN = gql`
  mutation(
    $Id: String!
    $RefundableProducts: [RefundableProductsInput]!
    $ShoppingBasketId: String
  ) {
    commitReturn(
      Id: $Id
      RefundableProducts: $RefundableProducts
      ShoppingBasketId: $ShoppingBasketId
    ) {
      error {
        ErrorCode
        ErrorReason
      }
      voucherFileUrl
      returnInfo {
        ReturnedAmount
        Currency
        RefundPaymentMethod
        VoucherCode
        Payments {
          ReturnedAmount
          Currency
          RefundPaymentMethod
          VoucherCode
          IsRefundToUsedVoucher
        }
      }
    }
  }
`;

export const SEND_VOUCHER_TO_EMAIL = gql`
  mutation($Email: String!, $VoucherCode: String!, $lang: String) {
    sendVoucherToEmail(Email: $Email, VoucherCode: $VoucherCode, lang: $lang) {
      error {
        ErrorCode
        ErrorReason
      }
    }
  }
`;
