import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Link, Container, PageLoading } from '@lux/ui';
import s from './not-found.module.scss';

const NotFound = ({ t, className, profileLoading }) => {
  return profileLoading ? (
    <PageLoading />
  ) : (
    <div className={cn(className, s.notFound)}>
      <Container>
        <Container full={false}>
          <section>
            <h2 className={s.title}>{t('page not found')}</h2>
            <h5>{t('useful links')}:</h5>
            <div className={s.links}>
              <Link className={s.link} to="/" children={t('home')} />
              <Link className={s.link} to="/" children={t('routes')} />
              <Link className={s.link} to="/" children={t('faq')} />
              <Link className={s.link} to="/" children={t('contacts')} />
            </div>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(NotFound);
