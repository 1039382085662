function getTicketsDataFromStorage() {
  if (typeof window !== 'undefined') {
    return JSON.parse(localStorage.getItem('ticketsData'));
  }

  return {};
}

function saveTicketsDataToStorage(state) {
  if (typeof window !== 'undefined') {
    return localStorage.setItem('ticketsData', JSON.stringify(state));
  }

  return state;
}

const initialState = {
  isOpenBlind: false,
  activeBus: 1,
  activePassenger: 1,
  isOutbound: true,
  totalPrice: null,
  passengersCount: 1,
  prices: {},
  inbound: {
    selected: false,
  },
  outbound: {
    selected: false,
  },
  isPassengersInfoConfirmed: false,
  isBasketExrpired: false,
  isSomethingWrong: false,
  isSomethingWrongMessage: '',
  isSomethingWrongTitle: '',
  step: 0,
  isFormDisabled: true,
  passsengersInfo: {},
  // isCorporate: false,
  isSearchOutOfDate: false,
  lastSearch: '',
  currency: 'CURRENCY.RUB',
  currencyName: 'RUB',
  symbol: '₽',
  showCurrencies: true,
  inboundJourneyPriceClass: 'Regular',
  outboundJourneyPriceClass: 'Regular',
  currentStepDisabled: false,
  passengerPrices: [],
  passengersFee: 0,
  paymentSuccessful: false,
  basketTransfers: [],
  basketTransfersPrice: 0,
  hasTransfers: false,
  hasOutboundBicycles: false,
  hasInboundBicycles: false,
  forAnalitics: {},
  GTM: {
    selectSeatsStep: false,
    passengerInfoStep: false,
    paymentStep: false,
    thanksStep: false,
  },
  affiliateId: '',
  transfers: {
    called: false,
    isOutbound: false,
    isInbound: false,
    inboundInformation: {},
    outboundInformation: {},
    InboundJourneys: [],
    OutboundJourneys: [],
    isOutboundOther: false,
    isInboundOther: false,
    outboundTransfer: {
      TransferId: '',
      PassengerName: '',
      PassengerPhone: '',
      Quantity: 1,
    },
    inboundTransfer: {
      TransferId: '',
      PassengerName: '',
      PassengerPhone: '',
      Quantity: 1,
    },
  },
  bicycles: {
    info: {},
    called: false,
    isOutbound: false,
    isInbound: false,
    outboundBicycle: {
      Quantity: 1,
      reserved: 0,
    },
    inboundBicycle: {
      Quantity: 1,
      reserved: 0,
    },
  },
  refund: {
    ticketNumber: '',
    modificationInfo: null,
    isPublic: true,
  },
  vouchers: {
    fullyСoversСost: false,
    totalVouchersValue: 0,
    showVoucherInput: false,
    approved: [],
  },
  payment: {
    status: '',
    type: '',
    objects: [],
  },
  // variables for logic partners buses
  isPartnerBusNotice: false,
  selectedJourneyId: '',
  confirmPartnerBusNotice: false,
};

export const initialStateTickets = {
  ...initialState,
  ...getTicketsDataFromStorage(),
};

saveTicketsDataToStorage(initialStateTickets);

const ticketsReducer = (state, action) => {
  function updatedState(state, action) {
    switch (action.type) {
      case 'RESET_TICKETS':
        // const { currency, currencyName, symbol } = initialStateTickets;
        const { currency, currencyName, symbol, refund, changeTickets } = state;
        return {
          ...initialState,
          ...action.payload,
          currencyName,
          currency,
          symbol,
          refund,
          changeTickets,
        };
      case 'RESET_REFUND': {
        return {
          ...state,
          refund: {
            ticketNumber: '',
            modificationInfo: null,
            isPublic: true,
          },
        };
      }
      case 'UPDATE_TICKETS':
        return {
          ...state,
          ...action.payload,
        };

      case 'UPDATE_TRANSFERS':
        return {
          ...state,
          transfers: {
            ...state.transfers,
            ...action.payload,
          },
        };

      case 'UPDATE_BICYCLES':
        return {
          ...state,
          bicycles: {
            ...state.bicycles,
            ...action.payload,
          },
        };

      case 'UPDATE_CURRENCY':
        const newCurrency = action.payload.currencies.filter(
          item => item.CurrencyName === action.payload.currency
        )[0];

        return {
          ...state,
          currencyName: newCurrency.CurrencyName,
          currency: newCurrency.Currency,
          symbol: newCurrency.Symbol,
        };
      default:
        return state;
    }
  }

  saveTicketsDataToStorage(updatedState(state, action));

  return updatedState(state, action);
};

export default ticketsReducer;
