import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Modal, ModalContent, Button, TicketsInput } from '@lux/ui';
import { SEND_VOUCHER_TO_EMAIL } from '@lux/spa/apollo/mutations';
import s from './send-voucher.module.scss';

const SendVoucher = ({ t, i18n, isOpen, toggleModal, voucherCode }) => {
  const [success, setSuccess] = useState(false);
  const { language } = i18n;

  const [sendVoucherToMail, { loading }] = useMutation(SEND_VOUCHER_TO_EMAIL);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
        toggleModal();
      }, 3000);
    }
  }, [success]);

  return (
    <Modal className={s.modal} {...{ isOpen, toggleModal }}>
      <ModalContent>
        <div className={s.contols}>
          {success ? (
            <div className={s.success}>
              <img src="/images/svg/send-email-success.svg" alt="" />
              <h1>{t('success')}</h1>
              <h5>{t('the tickets has been sent to your email')}</h5>
              <div>{t('popup will be closed in 3 sec.')}</div>
            </div>
          ) : (
            <>
              <h1>{t('send tickets')}</h1>
              <p>{t('enter the mail you would like to send tickets to')}</p>
              <Formik
                validateOnMount
                enableReinitialize
                initialValues={{
                  email: '',
                  password: '',
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email(
                      t('validation.error.email', {
                        field: t('email'),
                      })
                    )
                    .required(
                      t('validation.error.require', {
                        field: t('email'),
                      })
                    ),
                })}
              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                  } = props;

                  return (
                    <form className={s.form}>
                      <div className={s.input}>
                        <TicketsInput
                          autoFocus
                          name="email"
                          label={t('email')}
                          placeholder={t('enter your email')}
                          type="text"
                          required
                          value={values.email}
                          onChange={e => {
                            e.target.value = e.target.value.trim();
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          errors={touched.email && errors.email}
                        />
                      </div>
                      <Button
                        disabled={loading || errors.email}
                        className={s.button}
                        text={t('send')}
                        onClick={() => {
                          sendVoucherToMail({
                            variables: {
                              Email: values.email,
                              lang: language,
                              VoucherCode: voucherCode,
                            },
                          })
                            .then(data => {
                              if (!data.data.sendVoucherToEmail.error) {
                                setSuccess(true);
                              } else {
                                toast.error(t('something wrong'));
                              }
                            })
                            .catch(() => {
                              toast.error(t('something wrong'));
                            });
                        }}
                      />
                    </form>
                  );
                }}
              </Formik>
            </>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
};

export default withTranslation()(SendVoucher);
