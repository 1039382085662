import gql from 'graphql-tag';

export const GET_WIDGET_ADMIN = gql`
  query($lang: String!, $name: String!, $country: String) {
    adminStaticWidget(lang: $lang, name: $name, country: $country) {
      id
      name
      en {
        lang
        countries
        widgetData
      }
      et {
        lang
        countries
        widgetData
      }
      lv {
        lang
        countries
        widgetData
      }
      lt {
        lang
        countries
        widgetData
      }
      ru {
        lang
        countries
        widgetData
      }
    }
  }
`;

export const GET_WIDGET = gql`
  query($lang: String!, $name: String!, $country: String) {
    staticWidget(lang: $lang, name: $name, country: $country) {
      id
      name
      data {
        lang
        countries
        widgetData
      }
    }
  }
`;

export const ORDER_WIDGET = gql`
  query($lang: String!, $origin: String!) {
    orderWidget(lang: $lang, origin: $origin) {
      id
      routes {
        id
        departure {
          name
        }
        arrival {
          name
        }
        data {
          slug
          seo
        }
      }
      destinations {
        id
        city {
          name
        }
        data {
          slug
          seo
        }
      }
    }
  }
`;
