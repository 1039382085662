import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { withTranslation } from 'react-i18next';
import addDays from 'date-fns/addDays';
import { localizedDate } from '@lux/spa/utils';
import { SearchInput } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './date-picker.module.scss';

const DatePicker = ({
  t,
  i18n,
  placeholder = '',
  value,
  onDayChange,
  label,
  format = '',
  posRight = false,
  clean = false,
  ...dateProps
}) => {
  const { language } = i18n;
  const FORMAT = format || 'dd-MM-yyyy';

  const refDayPicker = useRef(null);
  const refOverlay = useRef(null);

  const handleClickOutside = e => {
    if (refOverlay.current && !refOverlay.current.contains(e.target)) {
      refDayPicker.current.hideDayPicker();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const Navbar = ({
    nextMonth,
    previousMonth,
    onPreviousClick,
    onNextClick,
    className,
    localeUtils,
    month,
  }) => {
    return (
      <div className={cn(className, s.navBar)}>
        <i
          onClick={() => onPreviousClick()}
          className={cn(i.arrowLeft, s.arrow, s.left)}
        />
        <div className={s.title}>
          {localizedDate(month, 'LLLL yyyy').toLowerCase()}
        </div>
        <i
          onClick={() => onNextClick()}
          className={cn(i.arrowRight, s.arrow, s.right)}
        />
      </div>
    );
  };

  const Weekday = ({ weekday, className, localeUtils, locale }) => {
    const dayOfWeek = localizedDate(
      addDays(new Date(2020, 6, 16), weekday + 3),
      'EEEE'
    );

    return (
      <div className={cn(className, s.weekdayName)} title={dayOfWeek}>
        {dayOfWeek.slice(0, 1)}
      </div>
    );
  };

  const OverlayComponent = ({ children, classNames, ...props }) => {
    return (
      <div
        ref={refOverlay}
        className={cn(
          classNames.overlayWrapper,
          s.overlayWrapper,
          posRight && s.right
        )}
        {...props}
        style={{ position: 'absolute' }}
      >
        <div className={cn(classNames.overlay, s.overlay)}>
          <div className={s.wrap}>
            <div className={s.triangle} />
          </div>
          {children}
        </div>
      </div>
    );
  };

  return clean ? (
    <DayPickerInput
      style={{ width: '104px', position: 'relative' }}
      overlayComponent={OverlayComponent}
      formatDate={localizedDate}
      locale={language}
      format={FORMAT}
      placeholder={placeholder || ' '}
      ref={refDayPicker}
      dayPickerProps={{
        locale: language,
        firstDayOfWeek: 1,
        weekdayElement: Weekday,
        showOutsideDays: true,
        navbarElement: Navbar,
        ...dateProps,
      }}
      value={value || ' '}
      onDayChange={onDayChange}
    />
  ) : (
    <DayPickerInput
      style={{ width: '100%', position: 'relative' }}
      overlayComponent={OverlayComponent}
      formatDate={localizedDate}
      format={FORMAT}
      locale={language}
      placeholder={placeholder || ' '}
      ref={refDayPicker}
      dayPickerProps={{
        locale: language,
        firstDayOfWeek: 1,
        weekdayElement: Weekday,
        showOutsideDays: true,
        navbarElement: Navbar,
        ...dateProps,
      }}
      component={props => (
        <SearchInput
          {...props}
          label={label}
          icon="calendar"
          readOnly="readonly"
        />
      )}
      value={value || ''}
      onDayChange={onDayChange}
    />
  );
};

export default withTranslation()(DatePicker);
