import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Input, Button } from '@lux/ui';
import { useQuery } from '@apollo/react-hooks';
import { FAQ_SEARCH } from '@lux/spa/apollo/queries';
import FaqSearchResults from '@lux/spa/routes/faq/components/faq-search-results';
import FaqSearchNoMatch from '@lux/spa/routes/faq/components/faq-search-no-match';
import s from './faq-search.module.scss';

const FaqSearch = ({ t, i18n }) => {
  const { language } = i18n;
  const [inputValue, setInputValue] = useState('');
  const history = useHistory();
  const { data = {} } = useQuery(FAQ_SEARCH, {
    skip: !inputValue.length,
    variables: {
      searchString: inputValue,
    },
  });
  const dataTemp = data.zendeskSearch ? data.zendeskSearch : [];
  const searchData = dataTemp.results ? dataTemp.results : [];

  const escapeRegExp = (str = '') =>
    str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');

  const getHighlightedText = (text, higlight) => {
    const patt = new RegExp(`(${escapeRegExp(higlight)})`, 'i');
    const parts = String(text).split(patt);

    return parts.map((part, i) => {
      const isMatch = part.toLowerCase() === higlight.toLowerCase();
      return isMatch ? `<b>${part}</b>` : part;
    });
  };

  const searchDataConverted = searchData.map(item => {
    const { section } = item;

    const title = getHighlightedText(item.title, inputValue).join('');

    return {
      id: item.id,
      title,
      originalTitle: item.title,
      sectionId: section ? section.id : null,
      sectionName: section ? section.name : '',
    };
  });
  const submitSearchHandler = e => {
    e.preventDefault();
    if (inputValue !== '') {
      history.push(`/${language}/search?phrase=${inputValue}`);
    }
  };
  return (
    <form
      className={s.search}
      autoComplete="off"
      method="POST"
      noValidate
      onSubmit={submitSearchHandler}
    >
      <div className={s.wrapper}>
        <Input
          label={t('type keywords')}
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />
        {searchData.length > 0 && inputValue.length > 0 ? (
          <div className={s.results}>
            <FaqSearchResults data={searchDataConverted} />
          </div>
        ) : (
          <div>
            {inputValue.length > 0 ? (
              <div className={s.results}>
                <FaqSearchNoMatch text={inputValue} />
              </div>
            ) : (
              <div />
            )}
          </div>
        )}
      </div>
      <Button
        type="submit"
        text={t('search')}
        size="l"
        className={s.button}
        disabled={inputValue === ''}
      />
    </form>
  );
};

export default withTranslation()(FaqSearch);
