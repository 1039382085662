export const emptyData = {
  lang: 'en',
  isPublished: true,
  countries: ['ee'],
  seo: {
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 80,
    },
    slug: {
      value: '',
      fieldName: 'slug',
      placeholder: 'Slug / URL',
      maxLength: 120,
    },
    thumbnail: {
      value: '',
      fieldName: 'thumbnail',
      placeholder: '250 x 250',
      size: {
        height: 0,
        width: 0,
      },
    },
    description: {
      value: '',
      fieldName: 'description',
      placeholder: 'Page description',
      maxLength: 158,
    },
    socialTitle: {
      value: '',
      fieldName: 'socialTitle',
      placeholder: 'Social Title',
      maxLength: 160,
    },
    socialDescription: {
      value: '',
      fieldName: 'socialDescription',
      placeholder: 'Social description',
      maxLength: 160,
    },
    pageKeywords: {
      value: '',
      fieldName: 'pageKeywords',
      placeholder: 'Page keywords',
      maxLength: 40,
    },
    socialImage: '',
    articlePublishedTime: '',
    articleModifiedTime: '',
  },
  pageData: {
    banner: {
      value: '',
      fieldName: 'banner',
      placeholder: '2880 x 1100',
      size: {
        height: 0,
        width: 0,
      },
    },
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 50,
    },
    discounts: '',
  },
};

export const defaultData = {
  lang: 'en',
  isPublished: true,
  countries: ['ee'],
  seo: {
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 80,
    },
    slug: {
      value: '',
      fieldName: 'slug',
      placeholder: 'Slug / URL',
      maxLength: 120,
    },
    description: {
      value: 'Page description',
      fieldName: 'description',
      placeholder: 'Page description',
      maxLength: 158,
    },
    socialTitle: {
      value: 'Social Title',
      fieldName: 'socialTitle',
      placeholder: 'Social Title',
      maxLength: 160,
    },
    socialDescription: {
      value: 'Social description',
      fieldName: 'socialDescription',
      placeholder: 'Social description',
      maxLength: 160,
    },
    pageKeywords: {
      value: 'Page keywords',
      fieldName: 'pageKeywords',
      placeholder: 'Page keywords',
      maxLength: 40,
    },
    thumbnail: {
      value: '',
      fieldName: 'thumbnail',
      placeholder: '250 x 250',
      size: {
        height: 0,
        width: 0,
      },
    },
    socialImage: '',
  },
  pageData: {
    banner: {
      value: '',
      fieldName: 'banner',
      placeholder: '2880 x 1100',
      size: {
        height: 0,
        width: 0,
      },
    },
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 50,
    },
    discounts: '',
  },
};
