import React, { useState, useContext, memo, useEffect } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { withTranslation } from 'react-i18next';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { StoreContext } from '@lux/spa/store';
import { DEFAULT_LANG } from '@lux/spa/config';
import { Container, Link, DiscardedBasket } from '@lux/ui';
import { updatePassengersPricesV2 } from 'utils';
import {
  AVAILABLE_TRANSFERS,
  ADD_TRANSFER,
  DELETE_PRODUCTS_FROM_BASKET,
  ADD_ADDONS,
} from '@lux/spa/apollo/mutations';
import { BASKET_PRODUCT_GROUPS } from '@lux/spa/apollo/queries';
import { TripDetails } from '@lux/spa/components';

import i from '@lux/spa/styles/icons.module.scss';
import s from './transfer.module.scss';
import { Bicycles, Transfers } from './components';

const Transfer = ({ t, i18n, className, history }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { isTabDown } = useContext(WidthContext);
  const { language } = i18n;
  const { tickets } = state;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOutboundErrors, setOutboundErrors] = useState(false);
  const [isInboundErrors, setInboundErrors] = useState(false);

  const [transfersDone, setTransfersDone] = useState(false);
  const [bicyclesDone, setBicyclesDone] = useState(false);

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const {
    products = {},
    lastSearch,
    transfers,
    basketTransfers,
    hasTransfers,
    bicycles,
    hasOutboundBicycles,
    hasInboundBicycles,
    isPassengersInfoConfirmed,
  } = tickets;

  if (!isPassengersInfoConfirmed) {
    history.push(
      `${
        DEFAULT_LANG === language ? '' : `/${language}`
      }/tickets/passenger-details/`
    );
    return null;
  }

  const {
    isOutbound,
    isInbound,
    called,
    OutboundJourneys = [],
    InboundJourneys = [],
    outboundTransfer = {},
    inboundTransfer = {},
  } = transfers;

  const { maxOutboundBicycles, maxInboundBicycles } = bicycles;

  const inboundTransfers = InboundJourneys;
  const outboundTransfers = OutboundJourneys;

  let productIds = [];

  if (products?.OutboundJourneys?.[0]?.Products?.length) {
    productIds = products.OutboundJourneys[0].Products.map(
      item => item.ProductId
    );
  }

  const [addTransfer, { loading: addTransferLoading }] = useMutation(
    ADD_TRANSFER
  );

  const [addAddons] = useMutation(ADD_ADDONS, {
    onCompleted: data => {
      if (data.addAddon.success) {
        setBicyclesDone(true);
        dispatch({
          type: 'UPDATE_BICYCLES',
          payload: {
            ...(isOutbound
              ? {
                  outboundBicycle: {
                    ...bicycles.outboundBicycle,
                    reserved: bicycles.outboundBicycle.Quantity,
                  },
                }
              : {}),
            ...(isInbound
              ? {
                  inboundBicycle: {
                    ...bicycles.inboundBicycle,
                    reserved: bicycles.inboundBicycle.Quantity,
                  },
                }
              : {}),
          },
        });
      }

      if (data.addAddon.error) {
        toast.error(data.addAddon.error.ErrorReason);
      }
    },
  });

  const [removeTransfers, { loading: removeTransfersLoading }] = useMutation(
    DELETE_PRODUCTS_FROM_BASKET
  );

  const [
    getAvailableTransfers,
    { loading: transfersLoading = true },
  ] = useMutation(AVAILABLE_TRANSFERS, {
    variables: {
      lang: language,
      productIds,
    },
    onError: () => {
      dispatch({
        type: 'UPDATE_TICKETS',
        payload: {
          isSomethingWrong: true,
        },
      });
    },
    onCompleted: data => {
      if (data.availableTransfers.error) {
        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            isSomethingWrong: true,
          },
        });
      } else {
        if (
          !data.availableTransfers.transfers.InboundJourneys.length &&
          !data.availableTransfers.transfers.OutboundJourneys.length &&
          !hasOutboundBicycles &&
          !hasInboundBicycles
        ) {
          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              hasTransfers: false,
            },
          });
          return history.push(
            language === DEFAULT_LANG
              ? `/tickets/payment/`
              : `/${language}/tickets/payment/`
          );
        }

        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            transfers: {
              ...transfers,
              ...data.availableTransfers.transfers,
              called: true,
            },
          },
        });
      }
    },
  });

  const [getBasketGroup, { loading: basketLoading }] = useLazyQuery(
    BASKET_PRODUCT_GROUPS,
    {
      fetchPolicy: 'network-only',
      variables: {
        lang: language,
      },
      onError: () => {
        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            isSomethingWrong: true,
          },
        });
      },
      onCompleted: data => {
        const { basketProductGroups = null } = data;
        if (!basketProductGroups) {
          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              isSomethingWrong: true,
            },
          });
          return;
        }

        const inboundPassengers = basketProductGroups.InboundJourneys;
        const outboundPassengers = basketProductGroups.OutboundJourneys;
        const basketTransfers = basketProductGroups.Transfers;
        const { passengerPrices, passengersFee } = updatePassengersPricesV2(
          products,
          outboundPassengers,
          inboundPassengers
        );

        let basketTransfersPrice = 0;

        if (basketTransfers.length) {
          basketTransfersPrice = basketTransfers.reduce(
            (sum, transfer) => sum + transfer.Price,
            0
          );
        }

        const { TotalPrice } = basketProductGroups;

        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            totalPrice: TotalPrice,
            passengersFee,
            passengerPrices,
            basketTransfers,
            basketTransfersPrice,
          },
        });

        setTransfersDone(true);
      },
    }
  );

  const handleContinue = () => {
    if (bicycles.isOutbound || bicycles.isInbound) {
      if (
        bicycles.outboundBicycle.Quantity !==
          bicycles.outboundBicycle.reserved ||
        bicycles.inboundBicycle.Quantity !== bicycles.inboundBicycle.reserved
      ) {
        let outbound = [];
        let inbound = [];

        let productId = '';

        bicycles.info.LegAddOns.forEach(item => {
          productId = item.ProductId;

          if (item.IsOutbound) {
            outbound.push(item);
          } else {
            inbound.push(item);
          }
        });

        outbound = [
          ...new Map(outbound.map(item => [item.LegOrderNo, item])).values(),
        ];

        inbound = [
          ...new Map(inbound.map(item => [item.LegOrderNo, item])).values(),
        ];

        const addons = [
          ...(bicycles.isOutbound
            ? outbound
                .sort((a, b) => a.LegOrderNo - b.LegOrderNo)
                // .slice(0, bicycles.outboundBicycle.Quantity)
                .map(item => {
                  return {
                    LegOrderNumber: item.LegOrderNo,
                    JourneyDirection: 'Outbound',
                    AddonId: item.AddOns[0].AddonGuid,
                    Quantity: bicycles.outboundBicycle.Quantity,
                  };
                })
            : outbound?.[0]?.AddOns?.[0]?.AddonGuid
            ? outbound
                .sort((a, b) => a.LegOrderNo - b.LegOrderNo)
                .map(item => ({
                  LegOrderNumber: item.LegOrderNo,
                  JourneyDirection: 'Outbound',
                  AddonId: item.AddOns[0].AddonGuid,
                  Quantity: 0,
                }))
            : []),
          ...(bicycles.isInbound
            ? inbound
                .sort((a, b) => a.LegOrderNo - b.LegOrderNo)
                .map(item => ({
                  LegOrderNumber: item.LegOrderNo,
                  JourneyDirection: 'Inbound',
                  AddonId: item.AddOns[0].AddonGuid,
                  Quantity: bicycles.inboundBicycle.Quantity,
                }))
            : inbound?.[0]?.AddOns?.[0]?.AddonGuid
            ? inbound
                .sort((a, b) => a.LegOrderNo - b.LegOrderNo)
                .map(item => ({
                  LegOrderNumber: item.LegOrderNo,
                  JourneyDirection: 'Inbound',
                  AddonId: item.AddOns[0].AddonGuid,
                  Quantity: 0,
                }))
            : []),
        ];

        addAddons({
          variables: {
            addons,
            productId,
          },
        });
      }
    } else if (
      bicycles.outboundBicycle.reserved ||
      bicycles.inboundBicycle.reserved
    ) {
      const outbound = [];
      const inbound = [];
      let productId = '';

      bicycles.info.LegAddOns.forEach(item => {
        productId = item.ProductId;

        if (item.IsOutbound) {
          outbound.push(item);
        } else {
          inbound.push(item);
        }
      });

      const addons = [
        ...(bicycles.outboundBicycle.reserved
          ? outbound
              .sort((a, b) => a.LegOrderNo - b.LegOrderNo)
              .slice(0, bicycles.outboundBicycle.Quantity)
              .map(item => {
                return {
                  LegOrderNumber: item.LegOrderNo,
                  JourneyDirection: 'Outbound',
                  AddonId: item.AddOns[0].AddonGuid,
                  Quantity: bicycles.outboundBicycle.Quantity,
                };
              })
          : []),
        ...(bicycles.inboundBicycle.reserved
          ? inbound.map(item => ({
              LegOrderNumber: item.LegOrderNo,
              JourneyDirection: 'Inbound',
              AddonId: item.AddOns[0].AddonGuid,
              Quantity: 0,
            }))
          : []),
      ];

      addAddons({
        variables: {
          addons,
          productId,
        },
      });
    } else {
      setBicyclesDone(true);
    }

    const Transfers = [];

    if (isOutbound) {
      Transfers.push(outboundTransfer);
    }

    if (isInbound) {
      Transfers.push(inboundTransfer);
    }

    const transfetsToRemove = basketTransfers.map(t => t.ProductId);

    if (transfetsToRemove.length) {
      removeTransfers({
        variables: {
          ProductIds: transfetsToRemove,
        },
      })
        .then(data => {
          if (data.data.deleteProductsFromBasket.success) {
            if (Transfers.length) {
              addTransfer({
                variables: {
                  Transfers,
                },
              })
                .then(data => {
                  if (data.data.addTransfer.error) {
                    toast.error(data.data.addTransfer.error.ErrorReason);
                  }

                  if (data.data.addTransfer.success) {
                    getBasketGroup();
                  }
                })
                .catch(() => {
                  dispatch({
                    type: 'UPDATE_TICKETS',
                    payload: {
                      isSomethingWrong: true,
                    },
                  });
                });
            } else {
              getBasketGroup();
            }
          } else {
            dispatch({
              type: 'UPDATE_TICKETS',
              payload: {
                isSomethingWrong: true,
              },
            });
          }
        })
        .catch(() => {
          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              isSomethingWrong: true,
            },
          });
        });
    } else if (Transfers.length) {
      addTransfer({
        variables: {
          Transfers,
        },
      })
        .then(data => {
          if (data.data.addTransfer.error) {
            dispatch({
              type: 'UPDATE_TICKETS',
              payload: {
                isSomethingWrong: true,
              },
            });
          }

          if (data.data.addTransfer.success) {
            getBasketGroup();
          }
        })
        .catch(() => {
          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              isSomethingWrong: true,
            },
          });
        });
    } else {
      setTransfersDone(true);
    }
  };

  let totalTransferPrice = null;

  if (isOutbound && outboundTransfers) {
    const price = outboundTransfers.filter(
      t => t.TransferId === outboundTransfer.TransferId
    )[0].Price;

    totalTransferPrice += price * outboundTransfer.Quantity;
  }

  if (isInbound && inboundTransfers) {
    const price = inboundTransfers.filter(
      t => t.TransferId === inboundTransfer.TransferId
    )[0].Price;

    totalTransferPrice += price * inboundTransfer.Quantity;
  }

  useEffect(() => {
    if (!called) {
      getAvailableTransfers();
    }
  }, []);

  useEffect(() => {
    if (transfersDone && bicyclesDone) {
      setBicyclesDone(false);
      setTransfersDone(false);
      history.push(
        language === DEFAULT_LANG
          ? `/tickets/payment/`
          : `/${language}/tickets/payment/`
      );
    }
  }, [transfersDone, bicyclesDone]);

  return (
    <div className={cn(s.transfer, className)}>
      <DiscardedBasket
        isOpen={isOpenModal}
        toggleModal={toggleModal}
        url={`/tickets/search${lastSearch}`}
      />
      <Container className={s.breadcrumbs}>
        <Container full={false}>
          <section>
            <div className={s.item} onClick={toggleModal}>
              {t('search')}
            </div>
            <Link to="/tickets/seat-details/" className={s.item}>
              <i className={i.arrowBottom} />
              1.&nbsp;<span>{t('seat selection')}</span>
            </Link>
            <Link to="/tickets/passenger-details/" className={s.item}>
              <i className={i.arrowBottom} />
              2.&nbsp;<span>{t('passengers details')}</span>
            </Link>
            {hasTransfers && (
              <div className={cn(s.item, s.active)}>
                <i className={i.arrowBottom} />
                3.&nbsp;<span>{t('extras')}</span>
              </div>
            )}
            <div className={s.item}>
              <i className={i.arrowBottom} />
              {hasTransfers ? '4' : '3'}.&nbsp;
              <span>{t('payment details')}</span>
            </div>
          </section>
        </Container>
      </Container>
      <Container className={s.content}>
        <Container full={false}>
          <section>
            <div className={s.options}>
              <Transfers transfersLoading={transfersLoading} />

              <Bicycles className={s.bicycles} />
            </div>

            {isTabDown ? (
              <TripDetails
                nextStep="payment"
                onContinueClick={handleContinue}
                totalTransferPrice={totalTransferPrice}
                page="transfer"
                disabledNextStep={
                  addTransferLoading ||
                  // loadingTransferInformation ||
                  transfersLoading ||
                  basketLoading ||
                  removeTransfersLoading ||
                  (isOutbound && isOutboundErrors) ||
                  (isInbound && isInboundErrors)
                }
                loading={
                  addTransferLoading ||
                  // loadingTransferInformation ||
                  transfersLoading ||
                  basketLoading ||
                  removeTransfersLoading
                }
              />
            ) : (
              <div className={s.tripDetails}>
                <TripDetails
                  nextStep="payment"
                  onContinueClick={handleContinue}
                  totalTransferPrice={totalTransferPrice}
                  page="transfer"
                  disabledNextStep={
                    addTransferLoading ||
                    // loadingTransferInformation ||
                    transfersLoading ||
                    basketLoading ||
                    removeTransfersLoading ||
                    (isOutbound && isOutboundErrors) ||
                    (isInbound && isInboundErrors)
                  }
                  loading={
                    addTransferLoading ||
                    // loadingTransferInformation ||
                    transfersLoading ||
                    basketLoading ||
                    removeTransfersLoading
                  }
                />
              </div>
            )}
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withRouter(withTranslation()(memo(Transfer)));
