import React from 'react';
import cx from 'classnames';
import i from '@lux/spa/styles/icons.module.scss';
import s from './search-input.module.scss';

const SearchInput = ({
  icon,
  label,
  className,
  type = 'text',
  size = 'l',
  value = '',
  placeholder = '',
  onFocus = () => {},
  ...props
}) => {
  // const [inputValue, setInputValue] = useState(value)
  // const onInput = e => {
  //   setInputValue(e.target.value)
  // }

  // useEffect(() => {
  //   setInputValue(value)
  // }, [value])

  const classes = cx(
    s.searchInput,
    className,
    s[size],
    icon && s.withIcon,
    value && s.hasValue,
    placeholder && s.withPlaceholder
    // inputValue && s.hasValue
  );

  return (
    <div className={classes} data-title={props['data-title']}>
      <input
        {...props}
        value={value}
        type={type}
        placeholder={placeholder}
        onFocus={onFocus}
      />
      {label && <label>{label}</label>}
      {icon && <i className={i[icon]} />}
    </div>
  );
};

export default SearchInput;
