import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { DEFAULT_LANG } from '@lux/spa/config';
import { DELETE_BASKET } from '@lux/spa/apollo/mutations';
import { StoreContext } from '@lux/spa/store';
import { Modal, ModalContent, Button, Loading } from '@lux/ui';
import s from './basket-expired.module.scss';

const BasketExpired = ({
  t,
  i18n,
  history,
  isOpen,
  isBasketExrpired,
  toggleModal,
}) => {
  const [state, dispatch] = useContext(StoreContext);
  const {
    lastSearch,
    isSomethingWrongMessage,
    isSomethingWrongTitle,
  } = state.tickets;
  const { language } = i18n;

  const [deleteBasket, { loading }] = useMutation(DELETE_BASKET, {
    onCompleted: data => {
      // if (data?.deleteBasket?.success) {
      toggleModal();
      // }
    },
  });

  const handleContinue = () => {
    deleteBasket();
    history.push(
      `${
        DEFAULT_LANG === language ? '' : `/${language}`
      }/tickets/search${lastSearch}`
    );
  };

  return (
    <Modal className={s.modal} {...{ isOpen, toggleModal }}>
      <ModalContent>
        <div className={s.contols}>
          <img src="/images/svg/tickets-not-found.svg" alt="" />
          <h1>
            {isSomethingWrongTitle ||
              t(isBasketExrpired ? 'basket expired' : 'something wrong')}
          </h1>
          {isSomethingWrongMessage && <p>{isSomethingWrongMessage}</p>}

          <Button
            disabled={loading}
            className={s.button}
            text={loading ? <Loading /> : t('search again')}
            onClick={handleContinue}
          />
          {/* <Button
            text={t('search again')}
            onClick={handleSearchAgain}
            to={{
              pathname:
                language === DEFAULT_LANG
                  ? '/tickets/search'
                  : `/${language}/tickets/search`,
              search: lastSearch,
            }}
          /> */}
        </div>
      </ModalContent>
    </Modal>
  );
};

export default withRouter(withTranslation()(BasketExpired));
