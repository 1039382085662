import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useQuery } from '@apollo/react-hooks';
import { DEFAULT_LANG, validToUseExponea } from '@lux/spa/config';
import { Button, Loading, Notice } from '@lux/ui';
import { GET_PAYMENT_METHODS } from '@lux/spa/apollo/queries';
import i from '@lux/spa/styles/icons.module.scss';
import s from './adyen-payment-method.module.scss';
import { et, lt, lv } from './translations';

const Card = ({
  id,
  name = '',
  logo,
  lastFour = null,
  t,
  className,
  selected,
  cardNumber,
  selectCard = () => {},
}) => {
  let cardLogo = '';

  switch (name.toLowerCase()) {
    case 'mastercard':
      cardLogo = 'mastercard';
      break;
    case 'visa':
      cardLogo = 'visa';
      break;
    default:
      cardLogo = 'unknown-card';
  }
  return (
    <div className={s.card} onClick={() => selectCard(cardNumber)}>
      <div className={s.catdTitle}>
        <div className={cn(s.check, selected && s.checked)} />
        <div className={s.logo}>
          <img src={`/images/svg/${cardLogo}.svg`} alt="" />
        </div>
        <div className={s.name}>{name}</div>
        {lastFour && <div className={s.lastFour}>{`••••  ${lastFour}`}</div>}
      </div>
    </div>
  );
};

const AdyenDropin = ({
  t,
  adyenPayment,
  language,
  history,
  paymentMethods,
  adyenPaymentDetails,
  shoppingBasketId,
  loaded,
  setLoaded,
  setHasStatus,
  totalPrice,
  from = {},
  to = {},
}) => {
  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';
  const paymentRef = useRef(null);

  const locale = {
    en: 'en_US',
    et: 'et_EE',
    lv: 'lv_LV',
    lt: 'lt_LT',
    ru: 'ru_RU',
  };

  const translations = {
    et,
    lt,
    lv,
  };

  const initAdyenCheckout = () => {
    const configuration = {
      // merchantAccount: 'ItAdviserLTDECOM',
      merchantAccount: 'TpiletLuxExpress',
      locale: locale[language],
      ...(['et', 'lt', 'lv'].includes(language)
        ? {
            translations: {
              [locale[language]]: translations[language],
            },
          }
        : {}),
      environment: isProd || isStage ? 'live' : 'test',
      // environment: 'live',
      // returnUrl: window.location.href,
      // originKey: ADYEN_PUBLIC_KEY,
      clientKey: ADYEN_PUBLIC_KEY,
      // originKey:
      // '10001|BC8BEC867F7D1D51987F061A0C8E14F146D6338B2515B1905AAB07C709FE2B87AD35B4B7F00BE745264BD13574790C55AA588364692CCFA317C90020B213D50DC634E5102ED92B4ABC6FF0F7E5C11B68E39EE476E493AF8A4608694F896F3208BD54490EB56892259A371DF5373CEA8809694C62205B6E89321DC2FEC2E62F68EB817E73E411F513564A16B622F5DB3D895C14BF0305074242BB66A44F0BE3F1DA2ED0C11DEE93420E28F22EC830A3750770927737CF9D4B5AD879F7C800252B84C20E6D8FD118A2F95C334F7250BBC0EB94AEDCBB87C645F733674FA5730AAAA8846B445BA95CA044ECFD9050B170CA5022125B15F7B36FA66EB30F8429A143',
      paymentMethodsResponse: paymentMethods,
      // onAdditionalDetails: (state, dropin) => {
      //   conole.log('onAdditionalDetails');
      // conole.log('state', state);
      // conole.log('dropin', dropin);
      //   // Your function calling your server to make a `/payments/details` request
      //   // makeDetailsCall(state.data)
      //   //   .then(response => {
      //   //     if (response.action) {
      //   //       // Drop-in handles the action object from the /payments response
      //   //       dropin.handleAction(response.action);
      //   //     } else {
      //   //       // Your function to show the final result to the shopper
      //   //       showFinalResult(response);
      //   //     }
      //   //   })
      //   //   .catch(error => {
      //   //     throw Error(error);
      //   //   });
      // },
      // returnUrl: window.location.href + '',
      paymentMethodsConfiguration: {
        card: {
          // Example optional configuration for Cards
          hasHolderName: true,
          holderNameRequired: true,
          enableStoreDetails: false,
          hideCVC: false, // Change this to true to hide the CVC field for stored cards
          name: t('credit or debit card'),
          brands: ['mc', 'visa', 'amex'],
          handleUnsupportedCard: false,
        },
      },
    };

    // You can add AdyenCheckout to your list of globals and then delete the window reference:
    // const checkout = new AdyenCheckout(configuration);

    const checkout = new window.AdyenCheckout(configuration);
    // const checkout = new AdyenCheckout(configuration);

    if (paymentRef.current) {
      checkout
        .create('dropin', {
          onSubmit: (state, dropin) => {
            if (state.isValid) {
              dropin.setStatus(t('loading'));
              if (validToUseExponea()) {
                exponea.track('payment_selected', {
                  timestamp: Date.now(),
                  payment_method: 'card',
                  total_price: totalPrice,
                  currency: 'RUB',
                  Origin_stop_ID: from.BusStopId,
                  Destination_stop_ID: to.BusStopId,
                });
              }

              adyenPayment({
                variables: {
                  input: {
                    ...state.data.paymentMethod,
                    channel: 'Web',
                    // returnUrl: window.location.href,
                    // returnUrl: window.location.origin,
                    returnUrl: `${window.location.origin}${
                      DEFAULT_LANG === language ? '' : `/${language}`
                    }/tickets/thanks?type=adyen`,
                  },
                },
              })
                .then(data => {
                  const error = data?.data?.adyenPayment?.error;
                  if (error) {
                    if (error.ErrorCode === 'basket_not_fount') {
                      // basket_not_fount
                    }

                    setHasStatus({
                      status: 'error',
                      text: error.ErrorReason,
                    });

                    setTimeout(() => {
                      setHasStatus({
                        status: null,
                        text: '',
                      });
                    }, 2000);

                    return;
                    // dropin.setStatus('error', {
                    //   message: error.ErrorReason,
                    // });
                    // setTimeout(() => {
                    //   dropin.setStatus('ready');
                    // }, 2000);
                    // return;
                  }
                  if (data.data.adyenPayment.success) {
                    if (data.data.adyenPayment.action) {
                      const { action = {} } = data.data.adyenPayment;
                      switch (action?.type) {
                        case 'redirect':
                          localStorage.setItem(
                            'details',
                            JSON.stringify(data.data.adyenPayment.details)
                          );
                          const url = `${action.url}?${Object.keys(action.data)
                            .map(key => `${key}=${action.data[key]}`)
                            .join('&')}`;
                          window.location.href = url;
                          break;
                        case 'qrCode':
                          console.log('qrCode action');
                          break;
                        case 'voucher':
                          console.log('voucher action');
                          break;
                        case 'await':
                          console.log('await action');
                          break;
                        case 'sdk':
                          console.log('sdk action');
                          break;
                        case 'threeDS2Fingerprint':
                          console.log('threeDS2Fingerprint action');
                          // adyenPaymentDetails({
                          //   variables: {
                          //     input: {
                          //       orderId: shoppingBasketId,
                          //       details: {},
                          //     },
                          //   },
                          //   onCompleted: data => {
                          //     console.log('adyenPaymentDetails data', data);
                          //   },
                          // });
                          break;
                        case 'threeDS2Challenge':
                          console.log('threeDS2Challenge action');
                          break;
                        default:
                          console.log('default');
                          break;
                      }
                    } else {
                      // dropin.setStatus('success', {
                      //   message: `${t('payment successful')}!`,
                      // }); // TODO: add to translates
                      setHasStatus({
                        status: 'success',
                        text: 'payment successful',
                      });

                      setTimeout(() => {
                        history.push(
                          language === DEFAULT_LANG
                            ? `/tickets/thanks?type=adyen&shopperOrder=${shoppingBasketId}`
                            : `/${language}/tickets/thanks?type=adyen&shopperOrder=${shoppingBasketId}`
                        );
                      }, 2000);
                    }
                  }
                })
                .catch(error => {
                  setHasStatus({
                    status: 'error',
                    text: 'something wrong',
                  });

                  setTimeout(() => {
                    setHasStatus({
                      status: null,
                      text: '',
                    });
                  }, 2000);
                });
            }
          },
          // onAdditionalDetails: (state, dropin) => {
          //   conole.log('onAdditionalDetails');
          //   conole.log('state', state);
          // },
        })
        .mount(paymentRef.current);
    }

    // If you need to refer to the dropin externaly, you can save this inside a variable:
    // const dropin = checkout.create...
  };

  useEffect(() => {
    if (!loaded) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      // link.href = `https://checkoutshopper-${
      //   isProd || isStage ? 'live' : 'test'
      // }.adyen.com/checkoutshopper/sdk/3.17.1/adyen.css`;

      link.href = `https://checkoutshopper-${
        isProd || isStage ? 'live' : 'test'
      }.adyen.com/checkoutshopper/sdk/3.18.2/adyen.css`;

      document.head.appendChild(link);

      const script = document.createElement('script');
      // script.src = `https://checkoutshopper-${
      //   isProd || isStage ? 'live' : 'test'
      // }.adyen.com/checkoutshopper/sdk/3.17.1/adyen.js`;

      script.src = `https://checkoutshopper-${
        isProd || isStage ? 'live' : 'test'
      }.adyen.com/checkoutshopper/sdk/3.18.2/adyen.js`;
      script.async = true;

      script.onload = initAdyenCheckout;
      setLoaded(true);

      // Wait until the script is loaded before initiating AdyenCheckout
      document.body.appendChild(script);
    } else {
      initAdyenCheckout();
    }
  }, []);

  return <div className={s.adyen} ref={paymentRef} />;
};

// export default AdyenDropin;

const AdyenPayment = ({
  t,
  dispatch,
  adyenPayment,
  language,
  history,
  paymentMethods,
  adyenPaymentDetails,
  shoppingBasketId,
  loaded,
  setLoaded,
  adyenLoading,
  totalPrice,
  from,
  to,
}) => {
  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';
  const [selectedCard, setSelectedCard] = useState(1);
  const [hasStatus, setHasStatus] = useState({
    status: null,
    text: '',
  });

  const { data, loading } = useQuery(GET_PAYMENT_METHODS);

  const selectCard = number => {
    setSelectedCard(number);
  };

  const userPaymentsMethods = data?.userPaymentsMethods || [];

  return (
    <div className={s.adyenPayment}>
      {adyenLoading ? (
        <Loading />
      ) : hasStatus.status ? (
        hasStatus.status === 'error' ? (
          <div className={cn(s.status, s.error)}>
            <img src="/images/svg/payment-failed.svg" alt="" />
            <h2 className={s.statusTitle}>{t('payment failed')}!</h2>
            <div className={s.text}>{t(hasStatus.text)}</div>
          </div>
        ) : (
          <div className={cn(s.status, s.success)}>
            <img src="/images/svg/payment-sucessful.svg" alt="" />

            <h2 className={s.statusTitle}>{t('payment successful')}!</h2>
            <div className={s.text}>
              {t('you will be automatically redirected in sec.', {
                count: 3,
              })}
            </div>
          </div>
        )
      ) : userPaymentsMethods.length ? (
        <>
          <div className={s.savedCards}>
            {userPaymentsMethods.map((card, key) => (
              <Card
                {...card}
                selectCard={selectCard}
                selected={selectedCard === key + 1}
                cardNumber={key + 1}
                key={key}
              />
            ))}
          </div>

          {selectedCard === 0 ? (
            <>
              <Card
                name={t('new card')}
                selectCard={selectCard}
                selected
                cardNumber={0}
              />
              <div className={s.adyenForm}>
                <AdyenDropin
                  t={t}
                  paymentMethods={paymentMethods}
                  adyenPayment={adyenPayment}
                  adyenPaymentDetails={adyenPaymentDetails}
                  shoppingBasketId={shoppingBasketId}
                  language={language}
                  history={history}
                  loaded={loaded}
                  setLoaded={setLoaded}
                  setHasStatus={setHasStatus}
                  totalPrice={totalPrice}
                  currencyName={currencyName}
                  from={from}
                  to={to}
                />
              </div>
            </>
          ) : (
            <>
              <div className={s.addNewCard} onClick={() => setSelectedCard(0)}>
                + {t('add new payment method')}
              </div>
              <Button
                className={s.pay}
                onClick={() =>
                  adyenPayment({
                    variables: {
                      input: {
                        storedPaymentMethodId:
                          userPaymentsMethods[selectedCard - 1].id,
                        channel: 'Web',
                        returnUrl: `${window.location.origin}/tickets/thanks?type=adyen`,
                      },
                    },
                  })
                    .then(data => {
                      const success = data?.data?.adyenPayment?.success;
                      const error = data?.data?.adyenPayment?.error;

                      if (error) {
                        setHasStatus({
                          status: 'error',
                          text: error.ErrorReason,
                        });

                        setTimeout(() => {
                          setHasStatus({
                            status: null,
                            text: '',
                          });
                        }, 2000);

                        return;
                      }

                      if (success) {
                        if (data.data.adyenPayment.action) {
                          const { action = {} } = data.data.adyenPayment;
                          switch (action?.type) {
                            case 'redirect':
                              localStorage.setItem(
                                'details',
                                JSON.stringify(data.data.adyenPayment.details)
                              );
                              const url = `${action.url}?${Object.keys(
                                action.data
                              )
                                .map(key => `${key}=${action.data[key]}`)
                                .join('&')}`;
                              window.location.href = url;
                              break;
                            case 'qrCode':
                              console.log('qrCode action');
                              break;
                            case 'voucher':
                              console.log('voucher action');
                              break;
                            case 'await':
                              console.log('await action');
                              break;
                            case 'sdk':
                              console.log('sdk action');
                              break;
                            case 'threeDS2Fingerprint':
                              console.log('threeDS2Fingerprint action');
                              // adyenPaymentDetails({
                              //   variables: {
                              //     input: {
                              //       orderId: shoppingBasketId,
                              //       details: {},
                              //     },
                              //   },
                              //   onCompleted: data => {
                              //     console.log('adyenPaymentDetails data', data);
                              //   },
                              // });
                              break;
                            case 'threeDS2Challenge':
                              console.log('threeDS2Challenge action');
                              break;
                            default:
                              console.log('default');
                              break;
                          }
                        } else {
                          setHasStatus({
                            status: 'success',
                            text: 'payment successful',
                          });

                          // setTimeout(() => {
                          //   setHasStatus({
                          //     status: null,
                          //     text: '',
                          //   });
                          // }, 2000);

                          if (data?.data?.adyenPayment?.objects?.[0]) {
                            dispatch({
                              type: 'UPDATE_TICKETS',
                              payload: {
                                forAnalitics: {
                                  objects: data?.data?.adyenPayment?.objects,
                                },
                              },
                            });
                          }

                          setTimeout(() => {
                            history.push(
                              language === DEFAULT_LANG
                                ? `/tickets/thanks?type=adyen&shopperOrder=${shoppingBasketId}`
                                : `/${language}/tickets/thanks?type=adyen&shopperOrder=${shoppingBasketId}`
                            );
                          }, 2000);
                        }
                      }
                    })
                    .catch(error => {
                      setHasStatus({
                        status: 'error',
                        text: 'something wrong',
                      });

                      setTimeout(() => {
                        setHasStatus({
                          status: null,
                          text: '',
                        });
                      }, 2000);
                    })
                }
              >
                <i className={i.lock} />
                {t('pay')}
              </Button>
            </>
          )}
        </>
      ) : adyenLoading ? (
        <Loading />
      ) : (
        <AdyenDropin
          t={t}
          setHasStatus={setHasStatus}
          paymentMethods={paymentMethods}
          adyenPayment={adyenPayment}
          adyenPaymentDetails={adyenPaymentDetails}
          shoppingBasketId={shoppingBasketId}
          language={language}
          history={history}
          loaded={loaded}
          setLoaded={setLoaded}
          totalPrice={totalPrice}
          currencyName={currencyName}
        />
      )}
      {/* <Notice
        className={s.notice}
        text={t(
          'by completing this booking, I confirm that I have read and agree to Lux Express Privacy Policy and Ticket sales rules'
        )}
      /> */}
      {/* <div className={s.notice}>
        <i className={i.infoCircle} />
        <span> */}
      {/* {t('confirmation.on.booking.completing', {
            privacyPolicyLink: (
              <Link className={s.link} to="/privacy-policy/">
                {t('privacy policy')}
              </Link>
            ),
            ticketSalesRules: (
              <Link className={s.link} to="/ticket-sales-rules/">
                {t('ticket sales rules')}
              </Link>
            ),
          })} */}

      {/* By completing this booking, I confirm that I have read and agree to Lux Express{' '}
          <Link className={s.link} to="/privacy-policy/">
            Privacy Policy
          </Link>{' '}
          and{' '}
          <Link className={s.link} to="/ticket-sales-rules/">
            Ticket sales rules
          </Link> */}
      {/* </span>
      </div> */}
    </div>
  );
};

export default AdyenPayment;
