import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { default as UploadFile } from 'react-dropzone';
import cn from 'classnames';

import { LazyLoadImage } from '@lux/ui';

import i from '@lux/spa/styles/icons.module.scss';
import s from './dropzone.module.scss';

const Dropzone = ({
  t,
  className,
  children,
  onDrop,
  loading,
  preview,
  removeImage,
  placeholder,
  ...props
}) => {
  const handleOnDrop = acceptedFiles => {
    acceptedFiles.map(file => {
      file.preview = URL.createObjectURL(file);
      const i = new Image();

      i.onload = () => {
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
          // const {name, requirements, path} = toast
          const { name, size, preview } = file;
          const { width, height } = i;
          const base64 = reader.result;

          onDrop(preview, width, height, base64, name);
          // let errors = []

          // TODO: need validation
          // if (name != newFileName) {
          //   errors.push('Check the correct file name!')
          // }
          //
          // if (size > 3000) {
          //   errors.push('Check the correct image size!')
          // }

          // if (requirements.width != width) {
          //   errors.push('Check the correct image width!')
          // }
          //
          // if (requirements.height != height) {
          //   errors.push('Check the correct image height!')
          // }
        };
      };

      i.src = file.preview;
    });
  };
  return (
    <UploadFile onDrop={handleOnDrop} accept="image/png, image/jpeg" {...props}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          {...getRootProps()}
          className={cn(
            s.dropzone,
            className,
            isDragActive ? s.gragEnter : s.dragLeave
          )}
        >
          <input {...getInputProps()} />
          {/* {children} */}
          {loading ? (
            <div className={s.loading}>
              <LazyLoadImage src="/images/svg/upoading.svg" alt="" />
              <div>{t('uploading')}</div>
            </div>
          ) : preview.value ? (
            <div
              className={s.withImage}
              style={{ backgroundImage: `url(${preview.value})` }}
            >
              <div className={s.placeholder}>
                {`${t(preview?.placeholder)}`}
                {!!preview?.size?.width &&
                  !!preview?.size?.height &&
                  ` ${preview?.size?.width}x${preview?.size?.height}`}
              </div>
              <i className={cn(i.close, s.remove)} onClick={removeImage} />
              {/* <div className={s.info}>
                  Width: {width}px, height: {height}px
              </div> */}
            </div>
          ) : (
            <div className={s.empty}>
              <div className={s.action}>
                <LazyLoadImage src="/images/svg/empty-dropzone.svg" alt="" />
              </div>
              <div className={s.size}>
                {`${t(preview?.placeholder)}`}
                {!!preview?.size?.width &&
                  !!preview?.size?.height &&
                  ` ${preview?.size?.width}x${preview?.size?.height}`}
              </div>
              <div>{t('drop files or click here to upload')}</div>
            </div>
          )}
          {/* {file ? (
            <div className={s.text}>
              <span>click</span>
              or
              <span>drop images here</span>
            </div>
            ) : (
            <div className={s.withFile}>
              <LazyLoadImage src={file} alt="" />
            </div>
            )
            // {file ? (
            //   <div className={s.text}>
            //     <span>click</span>
            //     or
            //     <span>drop images here</span>
            //   </div>
            // ) : (
            //   <div className={s.withFile}>
            //     <LazyLoadImage src={file} alt="" />
            //   </div>
            }
            </div>
            )}
            </UploadFile>
            );
            };

            class Dropzone1 extends Component {
            onDrop = acceptedFiles => {
            const { onDrop, ...props } = this.props;

            acceptedFiles.map(file => {
            file.preview = URL.createObjectURL(file);
            const i = new Image();

            i.onload = () => {
            let reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = () => {
            // const {updateState, toast} = this.props
            // const {name, requirements, path} = toast
            const { name, size, preview } = file;
            const { width, height } = i;
            const base64 = reader.result;

            onDrop(preview, width, height, base64);
            // let errors = []

            // TODO: need validation
            // if (name != newFileName) {
            //   errors.push('Check the correct file name!')
            // }
            //
            // if (size > 3000) {
            //   errors.push('Check the correct image size!')
            // }

            // if (requirements.width != width) {
            //   errors.push('Check the correct image width!')
            // }
            //
            // if (requirements.height != height) {
            //   errors.push('Check the correct image height!')
            // }
            };
            };

            i.src = file.preview;
            });
            };

            render() {
            const {
            className,
            classDragLeave,
            classDragEnter,
            children,
            onDrop,
            ...props
            } = this.props;
            return (
            <UploadFile
            onDrop={this.onDrop}
            accept="image/png, image/jpeg"
            {...props}
            >
            {({ getRootProps, getInputProps, isDragActive }) => (
            <div
            {...getRootProps()}
            className={cn(
              s.dropzone,
              className,
              isDragActive ? classDragEnter : classDragLeave
            )}
            >
            <input {...getInputProps()} />
            {children}
            {/* {file ? (
              <div className={s.text}>
            <span>click</span>
            or
            <span>drop images here</span>
              </div>
              ) : (
              <div className={s.withFile}>
            <LazyLoadImage src={file} alt="" />
              </div>
          )} */}
        </div>
      )}
    </UploadFile>
  );
};

export default withTranslation()(Dropzone);
