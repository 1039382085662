import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { I18nextProvider } from 'react-i18next';
import { createBrowserHistory } from 'history';
import i18n from '@lux/spa/i18n';
import StoreProvider from '@lux/spa/store';
import { HelmetProvider } from 'react-helmet-async';
import { WidthProvider } from '@lux/spa/contexts/width-context';
import { getCookie } from 'utils';
import client from '@lux/spa/apollo';
import { App } from '@lux/spa/components';
import '@lux/spa/styles/index.scss';

// Instantiate required constructor fields
String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const history = createBrowserHistory();

history.listen(_ => {
  document.body.scrollTo({ top: 0, behavior: 'smooth' });
});

const { lang = 'ru', params = {} } = window.__APOLLO_STATE__ || {};

i18n.changeLanguage(lang);

// remove post data in localStorage
localStorage.removeItem('params');

// save post data from to localStorage if data exist
if (Object.keys(params).length) {
  localStorage.setItem('params', JSON.stringify(params));
}

// set the most preferred language for user
document.getElementById('state')?.remove();

delete window.__APOLLO_STATE__;

const container = document.getElementById('root');

// exponea.initialize({
//   token: EXPONEA_TOKEN,
// });

ReactDOM.hydrate(
  <StoreProvider>
    <WidthProvider>
      <ApolloProvider client={client}>
        <I18nextProvider>
          <Router history={history}>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </Router>
        </I18nextProvider>
      </ApolloProvider>
    </WidthProvider>
  </StoreProvider>,
  container
);
