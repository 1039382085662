import React, { useState, useContext } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { Query } from 'react-apollo';
import { WidthContext } from '@lux/spa/contexts/width-context';
import {
  GET_ALL_DESTINATIONS,
  GET_ALL_DESTINATIONS_ADMIN,
  ORDER_WIDGET,
} from '@lux/spa/apollo/queries';
import { Search, EmptySlider, meta } from '@lux/spa/components';
import { localizedDate } from '@lux/spa/utils';
import {
  Container,
  Divider,
  Slider,
  SliderSlides,
  SliderControls,
  Editor,
  Button,
  Switch,
  Loading,
  Tabs,
  Tab,
  TabsPanel,
  TabContent,
  TabContainer,
  Tags,
  Tag,
  Text,
  showEvents,
  LazyLoadImage,
  UploadImages,
  EditSlider,
} from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import { EditSlide, EditEvent, emptySlide, emptyEvent } from './components';
import s from './destination.module.scss';

const DestinationContent = ({
  t,
  slugs,
  type,
  isEditMode,
  isStaff,
  content,
  language,
  className,
  updatePage,
}) => {
  const { isMob } = useContext(WidthContext);
  const [isImagesModalOpen, setIsImagesModalOpen] = useState(false);
  const [isEditSlider, setIsEditSlider] = useState(false);
  const [isEditEvents, setIsEditEvents] = useState(false);

  const toggleModal = () => {
    setIsImagesModalOpen(!isImagesModalOpen);
  };

  const toggleEditSlider = () => {
    setIsEditSlider(!isEditSlider);
  };

  const toggleEditEvents = () => {
    setIsEditEvents(!isEditEvents);
  };

  const { pageData = {}, seo = {}, countries } = content;

  const {
    title,
    subTitle,
    heading1,
    heading2,
    heading3,
    text1,
    text2,
    text3,
    text4,
    banner,
    slider = {
      fieldName: 'slider',
      slides: [],
    },
    events = {
      fieldName: 'events',
      slides: [],
    },
    showEvents,
  } = pageData;

  const backgroundImage = banner.value
    ? `linear-gradient(90deg, #000000d9, transparent), url(${banner.value})`
    : `linear-gradient(90deg, #000000d9, transparent)`;

  return (
    <div className={cn(className, s.destination, isEditMode && s.editMode)}>
      <Helmet {...meta({ seo, lang: language, countries, slugs, type })} />
      {isImagesModalOpen && (
        <UploadImages
          isOpen={isImagesModalOpen}
          toggleModal={toggleModal}
          updatePage={updatePage}
          image={banner}
        />
      )}
      {isEditSlider && (
        <EditSlider
          emptySlide={emptySlide}
          slider={slider}
          isOpen={isEditSlider}
          updatePage={updatePage}
          toggleModal={toggleEditSlider}
        >
          <EditSlide />
        </EditSlider>
      )}
      {isEditEvents && (
        <EditSlider
          emptySlide={emptyEvent}
          slider={events}
          isOpen={isEditEvents}
          updatePage={updatePage}
          toggleModal={toggleEditEvents}
        >
          <EditEvent />
        </EditSlider>
      )}

      <Container className={s.bannerContainer} style={{ backgroundImage }}>
        <Container full={false}>
          <section className={s.banner}>
            <h1>
              <Text onChange={updatePage} field={title} />
            </h1>
            <p>
              <Text onChange={updatePage} field={subTitle} />
            </p>
            {isEditMode && (
              <Button
                className={s.uploadBanner}
                text={banner.value ? t('edit photo') : t('upload photo')}
                onClick={toggleModal}
              />
            )}
            <Search />
          </section>
        </Container>
      </Container>
      <Divider />
      <Container className={s.descriptions}>
        <div className={s.greyBlock} />
        <Container full={false}>
          <section>
            <h2 className={s.title}>
              <Text onChange={updatePage} field={heading1} />
            </h2>
            <div className={s.texts}>
              <h4>
                <Text onChange={updatePage} field={text1} />
              </h4>
              {(isEditMode || text2.value) && (
                <Editor
                  className={s.text}
                  placeholder={t('text')}
                  html={text2.value}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    updatePage({
                      text2: {
                        ...text2,
                        value: data,
                      },
                    });
                  }}
                />
              )}
              {(isEditMode || text3.value) && (
                <Editor
                  className={s.text}
                  placeholder={t('text')}
                  html={text3.value}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    updatePage({
                      text3: {
                        ...text3,
                        value: data,
                      },
                    });
                  }}
                />
              )}
            </div>
            <div className={s.sliderContainer}>
              {slider.slides.length ? (
                <Slider
                  className={s.slider}
                  slidesCount={slider.slides.length}
                  render={sliderProps => (
                    <>
                      {isEditMode && (
                        <Button
                          className={s.button}
                          text={t('edit slider')}
                          onClick={toggleEditSlider}
                        />
                      )}
                      <SliderSlides {...sliderProps} className={s.slides}>
                        {slider.slides.map((slide = {}, key) => (
                          <div key={key} className={s.slide}>
                            <LazyLoadImage
                              src={slide.img.value}
                              alt={slide.alt.value}
                            />
                          </div>
                        ))}
                      </SliderSlides>
                      <SliderControls {...sliderProps} />
                    </>
                  )}
                />
              ) : isEditMode ? (
                <EmptySlider toggleEditSlider={toggleEditSlider} />
              ) : null}
            </div>
          </section>
        </Container>
      </Container>
      {(isEditMode || showEvents) && (
        <Container className={s.events}>
          <Container full={false}>
            <section>
              {isEditMode && (
                <div className={s.visibility}>
                  <span>{t('visibility')}:</span>
                  <Switch
                    className={s.switch}
                    checked={showEvents}
                    onChange={() =>
                      updatePage({
                        showEvents: !showEvents,
                      })
                    }
                  />
                </div>
              )}
              <h2 className={s.title}>
                <Text onChange={updatePage} field={heading2} />
              </h2>
              <p className={s.subTitle}>
                <Text onChange={updatePage} field={text4} />
              </p>
              {events.slides.length ? (
                <Slider
                  className={s.slider}
                  slidesCount={events.slides.length}
                  render={sliderProps => {
                    const i = sliderProps.currentSlide;
                    let date = events.slides[i].date.value;
                    date = localizedDate(date, 'dd MMMM yyyy');
                    const link = events.slides[i]?.link?.value;
                    return (
                      <>
                        {isEditMode && (
                          <Button
                            className={s.editSlider}
                            text={t('edit events')}
                            onClick={toggleEditEvents}
                          />
                        )}
                        <div className={s.greyBlock} />
                        <div className={s.info}>
                          <div className={s.date}>{date}</div>
                          <div className={s.texts}>
                            <h3 className={s.eventName}>
                              {events.slides[i].title.value}
                            </h3>
                            <p>{events.slides[i].subTitle.value}</p>
                          </div>
                          {!!link && (
                            <Button
                              target="_blank"
                              to={link}
                              className={s.button}
                              text={t('check tickets')}
                            />
                          )}
                          {!isMob && (
                            <SliderControls
                              className={s.controls}
                              {...sliderProps}
                            />
                          )}
                        </div>
                        <SliderSlides {...sliderProps} className={s.slides}>
                          {events.slides.map((event, key) => (
                            <div key={key} className={s.slide}>
                              <LazyLoadImage
                                src={event.img.value}
                                alt={event.alt.value}
                              />
                            </div>
                          ))}
                        </SliderSlides>
                        {isMob && (
                          <SliderControls
                            className={s.controls}
                            {...sliderProps}
                          />
                        )}
                      </>
                    );
                  }}
                />
              ) : isEditMode ? (
                <EmptySlider toggleEditSlider={toggleEditEvents} />
              ) : null}
            </section>
          </Container>
        </Container>
      )}
      <Container className={s.places}>
        <Container full={false}>
          <section>
            {false && (
              <>
                <h2 className={s.title}>
                  <Text onChange={updatePage} field={heading3} />
                </h2>
                <Tabs className={s.tabs}>
                  <TabsPanel>
                    <Tab text={t('things to do')} />
                    <Tab text={t('restaurants')} />
                    <Tab text={t('tours')} />
                    <Tab text={t('hotels')} />
                  </TabsPanel>
                  <TabContainer>
                    {Array(4)
                      .fill()
                      .map((item, key) => (
                        <TabContent key={key}>
                          <div className={s.header}>
                            <div>{t('pick date')}</div>
                            <div className={s.panel}>
                              <i className={i.arrowLeft} />
                              <div className={s.date}>11 october</div>
                              <i className={i.arrowRight} />
                            </div>
                          </div>
                          <div className={s.places}>
                            {Array(8)
                              .fill()
                              .map((item, key) => {
                                return (
                                  <div className={s.place} key={key}>
                                    <div className={s.photo}>
                                      <LazyLoadImage
                                        src={`/images/destination/events/${key +
                                          1}.png`}
                                        alt=""
                                      />
                                    </div>
                                    <div className={s.name}>
                                      Riga Town Hall Square
                                    </div>
                                    <div className={s.subTitle}>
                                      TripAdvisor Traveler Raiting
                                    </div>
                                    <div className={s.raiting}>
                                      <LazyLoadImage
                                        src="/images/svg/tripadvisor-logo.svg"
                                        alt=""
                                      />
                                      {Array(5)
                                        .fill()
                                        .map((item, key) => (
                                          <div
                                            className={cn(
                                              s.circle,
                                              key < 3 && s.fill
                                            )}
                                            key={key}
                                          />
                                        ))}
                                    </div>
                                    <div className={s.reviews}>
                                      based on {54} reviews
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          <Button text={t('show more')} className={s.button} />
                        </TabContent>
                      ))}
                  </TabContainer>
                </Tabs>
              </>
            )}
            <h3>{t('other popular destinations')}</h3>
            <Tags>
              <Query
                query={ORDER_WIDGET}
                variables={{
                  lang: language,
                  origin: 'popular-destinations',
                }}
              >
                {({ data = {}, loading }) => {
                  if (loading) return <Loading />;
                  const widget = data?.orderWidget;
                  const destinations = widget?.destinations;
                  return (
                    !!destinations?.length &&
                    destinations.map((destination, key) => {
                      const city = destination.city?.name;
                      const { slug } = destination.data;
                      return (
                        seo.slug.value.toLowerCase() !== slug.toLowerCase() && (
                          <Tag
                            to={`/destinations/${slug}/`}
                            text={t(city)}
                            key={key}
                          />
                        )
                      );
                    })
                  );
                }}
              </Query>
            </Tags>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(DestinationContent);
