import React from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { addBlankAttrToHtml } from '@lux/spa/utils';
import { GET_FAQ_ARTICLE } from '@lux/spa/apollo/queries';
import { Container, PageLoading } from '@lux/ui';
import { NotFound } from '@lux/spa/routes';
import { meta } from '@lux/spa/components';
import s from './privacy-policy.module.scss';

const PrivacyPolicy = ({ t, i18n, className }) => {
  const { language } = i18n;
  const { data = {}, loading } = useQuery(GET_FAQ_ARTICLE, {
    variables: {
      lang: language,
      id: 360013514799,
    },
  });

  const { article } = data;

  const slug = 'privacy-policy';

  let seo = {
    et: {
      title: {
        value: 'Privaatsuspoliitika',
      },
      description: {
        value: 'Privaatsuspoliitika',
      },
      socialTitle: {
        value: 'Privaatsuspoliitika',
      },
      socialDescription: {
        value: 'Privaatsuspoliitika',
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value:
          '/images/thumbnails/privacy-policy/Lux_Express_privacy_policy.jpg',
      },
    },
    en: {
      title: {
        value: 'Confientiality policy',
      },
      description: {
        value: 'Confientiality policy',
      },
      socialTitle: {
        value: 'Confientiality policy',
      },
      socialDescription: {
        value: 'Confientiality policy',
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value:
          '/images/thumbnails/privacy-policy/Lux_Express_privacy_policy.jpg',
      },
    },
    lt: {
      title: {
        value: 'Privatumo politika',
      },
      description: {
        value: 'Privatumo politika',
      },
      socialTitle: {
        value: 'Privatumo politika',
      },
      socialDescription: {
        value: 'Privatumo politika',
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value:
          '/images/thumbnails/privacy-policy/Lux_Express_privacy_policy.jpg',
      },
    },
    lv: {
      title: {
        value: 'Privātuma politika.',
      },
      description: {
        value: 'Privātuma politika',
      },
      socialTitle: {
        value: 'Privātuma politika.',
      },
      socialDescription: {
        value: 'Privātuma politika',
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value:
          '/images/thumbnails/privacy-policy/Lux_Express_privacy_policy.jpg',
      },
    },
    ru: {
      title: {
        value: 'Политика конфиденциальности',
      },
      description: {
        value: 'Политика конфиденциальности',
      },
      socialTitle: {
        value: 'Политика конфиденциальности',
      },
      socialDescription: {
        value: 'Политика конфиденциальности',
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value:
          '/images/thumbnails/privacy-policy/Lux_Express_privacy_policy.jpg',
      },
    },
  };

  seo = seo[language];

  seo.articlePublishedTime = article?.createdAt || '';
  seo.articleModifiedTime = article?.editedAt || '';

  const slugs = {
    en: {
      value: slug,
    },
    et: {
      value: slug,
    },
    lv: {
      value: slug,
    },
    lt: {
      value: slug,
    },
    ru: {
      value: slug,
    },
  };

  return loading ? (
    <PageLoading />
  ) : article ? (
    <div className={cn(className, s.privacyPolicy)}>
      <Helmet {...meta({ seo, lang: language, slugs })} />
      <Container>
        <div className={s.greyBlock} />
        <Container full={false}>
          <section>
            <h1 className={s.title}>{article.title}</h1>
            {article.body && (
              <div
                className={s.article}
                dangerouslySetInnerHTML={{
                  __html: addBlankAttrToHtml(article.body),
                }}
              />
            )}
          </section>
        </Container>
      </Container>
    </div>
  ) : (
    <NotFound />
  );
};

export default withTranslation()(PrivacyPolicy);
