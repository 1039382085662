import React from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { Container, Button, Link, PageLoading } from '@lux/ui';
import { FAQ_SEARCH } from '@lux/spa/apollo/queries';
import SearchResultsList from '@lux/spa/routes/search/components/search-results-list';
import i from '@lux/spa/styles/icons.module.scss';
import s from './search.module.scss';

const SearchFaq = ({ t, i18n, match }) => {
  const { topic } = match.params;
  const location = useLocation();
  const { search } = location;
  const phrase = search.split('=') || '';
  const searchPhrase = phrase.length > 0 ? phrase[1] : '';
  const { data = {}, loading } = useQuery(FAQ_SEARCH, {
    variables: {
      searchString: searchPhrase,
      skip: searchPhrase === '',
    },
  });
  const dataTemp = data.zendeskSearch ? data.zendeskSearch : [];
  const searchData = dataTemp.results ? dataTemp.results : [];
  const searchDataConverted = searchData.map(item => {
    const { section } = item;
    return {
      id: item.id,
      title: item.title,
      sectionId: section ? section.id : null,
      sectionName: section ? section.name : '',
    };
  });
  return loading ? (
    <PageLoading />
  ) : searchDataConverted.length > 0 ? (
    <div className={s.search}>
      <Container className={s.questions}>
        <Container full={false}>
          <Link to={`/faq/${topic}/`} className={s.back}>
            <i className={cn(i.arrowLeft, s.icon)} />
            {t('back')}
          </Link>
        </Container>
        <Container full={false}>
          <div className={s.row}>
            <div className={s.left_part}>
              <h1 className={s.title}>{t('search results')}</h1>
              <div className={s.matches}>
                {searchDataConverted.length} {t('matches for')} "{searchPhrase}"
              </div>
              <SearchResultsList data={searchDataConverted} count={8} />
            </div>
            <div className={s.right_part}>
              <h2 className={s.titleSupport}>{t('contact support')}</h2>
              <div className={s.titleSupportDescription}>
                {t('faq.contact.text1')}
                <br />
                {t('faq.contact.text2')}
              </div>
              <Button text={t('contact us')} to="/contacts" />
              <div className={s.greyBlock} />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  ) : (
    <div className={s.search}>
      <Container className={s.questions}>
        <Container full={false}>
          <Link to={`/faq/${topic}/`} className={s.back}>
            <i className={cn(i.arrowLeft, s.icon)} />
            {t('back')}
          </Link>
        </Container>
        <Container full={false}>
          <div className={s.row}>
            <div className={s.left_part}>
              <h1 className={s.title}>{t('result not found')}</h1>
              <div className={s.matches}>
                {t('no matches for')} "{searchPhrase}"
              </div>
              <div className={s.description}>
                {t('sorry, no results were found. please try again')}
              </div>
            </div>
            <div className={s.right_part}>
              <h2 className={s.titleSupport}>{t('contact support')}</h2>
              <div className={s.titleSupportDescription}>
                {t('faq.contact.text1')}
                <br />
                {t('faq.contact.text2')}
              </div>
              <Button text={t('contact us')} to="/contacts" />
              <div className={s.greyBlock} />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(SearchFaq);
