export const emptyData = {
  lang: 'en',
  isPublished: true,
  countries: ['ee', 'ru', 'fi', 'lv', 'lt'],
  seo: {
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 80,
    },
    slug: {
      value: '',
      fieldName: 'slug',
      placeholder: 'Slug / URL',
      maxLength: 120,
    },
    thumbnail: {
      value: '',
      fieldName: 'thumbnail',
      placeholder: 'Thumbnail',
      size: {
        height: 420,
        width: 740,
      },
    },
    description: {
      value: '',
      fieldName: 'description',
      placeholder: 'Page description',
      maxLength: 158,
    },
    socialTitle: {
      value: '',
      fieldName: 'socialTitle',
      placeholder: 'Social Title',
      maxLength: 160,
    },
    socialDescription: {
      value: '',
      fieldName: 'socialDescription',
      placeholder: 'Social description',
      maxLength: 160,
    },
    pageKeywords: {
      value: '',
      fieldName: 'pageKeywords',
      placeholder: 'Page keywords',
      maxLength: 40,
    },
    socialImage: '',
    articlePublishedTime: '',
    articleModifiedTime: '',
  },
  pageData: {
    banner: {
      value: '',
      fieldName: 'banner',
      placeholder: 'Banner',
      size: {
        height: 1100,
        width: 2880,
      },
    },
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 50,
    },
    subTitle: {
      value: '',
      fieldName: 'subTitle',
      placeholder: 'Page subtitle',
      maxLength: 200,
    },
    heading1: {
      value: '',
      fieldName: 'heading1',
      placeholder: 'Heading',
      maxLength: 100,
    },
    twitterLink: '',
    facebookLink: '',
    news: '',
  },
};

export const defaultData = {
  lang: 'en',
  slug: 'test',
  isPublished: true,
  countries: ['ee', 'ru', 'fi', 'lv', 'lt'],
  seo: {
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 80,
    },
    slug: {
      value: '',
      fieldName: 'slug',
      placeholder: 'Slug / URL',
      maxLength: 120,
    },
    description: {
      value: 'Page description',
      fieldName: 'description',
      placeholder: 'Page description',
      maxLength: 158,
    },
    socialTitle: {
      value: 'Social Title',
      fieldName: 'socialTitle',
      placeholder: 'Social Title',
      maxLength: 160,
    },
    socialDescription: {
      value: 'Social description',
      fieldName: 'socialDescription',
      placeholder: 'Social description',
      maxLength: 160,
    },
    pageKeywords: {
      value: 'Page keywords',
      fieldName: 'pageKeywords',
      placeholder: 'Page keywords',
      maxLength: 40,
    },
    thumbnail: {
      value: '',
      fieldName: 'thumbnail',
      placeholder: 'Thumbnail',
      size: {
        height: 420,
        width: 740,
      },
    },
    socialImage: '',
  },
  pageData: {
    banner: {
      value: '',
      fieldName: 'banner',
      placeholder: 'Banner',
      size: {
        height: 1100,
        width: 2880,
      },
    },
    title: {
      value: 'News title',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 100,
    },
    subTitle: {
      value: '5 min',
      fieldName: 'subTitle',
      placeholder: 'Page subtitle',
      maxLength: 200,
    },
    heading1: {
      value: 'Heading',
      fieldName: 'heading1',
      placeholder: 'Heading',
      maxLength: 100,
    },
    twitterLink: '',
    facebookLink: '',
    news: '',
  },
};
