import React, { useState, useContext, useRef, memo, useEffect } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { StoreContext } from '@lux/spa/store';
import {
  Container,
  Link,
  DiscardedBasket,
  Button,
  Divider,
  Login,
  Loading,
} from '@lux/ui';
import { DEFAULT_LANG } from '@lux/spa/config';
import PhoneNumber from 'awesome-phonenumber';
import {
  UPDATE_BUYER_INFO,
  UPDATE_PASSENGERS_INFO,
  PASSEENGERS_DATA_FIELDS,
} from '@lux/spa/apollo/mutations';
import { BASKET_PRODUCT_GROUPS } from '@lux/spa/apollo/queries';
import { updatePassengersPricesV2, regExpForNames } from 'utils';
import { TripDetails } from '@lux/spa/components';
import i from '@lux/spa/styles/icons.module.scss';
import s from './passenger-details.module.scss';
import { PassengerForm } from './components';

const PassengerDetails = ({ t, i18n, className, history }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { isTabDown } = useContext(WidthContext);
  const { language } = i18n;
  const formRef = useRef(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const { user, tickets, app } = state;
  const { GTMLoaded } = app;
  const [formSettings, setFormSettings] = useState({
    initValues: {},
    scheme: {},
    requiredFields: [],
    fields: [],
    formFieldDropdownItems: {},
  });

  const { email, firstName, lastName, phone = '', gender } = user;
  const [redirectUrl, setRedirectUrl] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const toggleLoginModal = () => {
    setIsOpenLoginModal(!isOpenLoginModal);
  };

  const {
    products = {},
    isFormDisabled,
    isPassengersInfoConfirmed,
    passsengersInfo,
    passengersCount,
    lastSearch,
    hasTransfers,
    returnDate,
    from = {},
    to = {},
    promocode,
    totalPrice,
    GTM,
  } = tickets;

  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';

  const legs = [
    ...(products.OutboundJourneys?.[0].Legs || []),
    ...(products.InboundJourneys?.[0]?.Legs || []),
  ];

  const productIds = products.OutboundJourneys?.[0]?.Products.map(
    product => product.ProductId
  );

  const [getPassengersDataFields, { loading: fieldsLoading }] = useMutation(
    PASSEENGERS_DATA_FIELDS,
    {
      variables: {
        lang: language,
        productIds,
      },
      onError: () => {
        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            isSomethingWrong: true,
          },
        });
      },
      onCompleted: data => {
        const { error } = data.passengersDataFields;

        if (error) {
          if (error.ErrorCode === 'basket_expired') {
            dispatch({
              type: 'UPDATE_TICKETS',
              payload: {
                isBasketExrpired: true,
              },
            });
          } else {
            dispatch({
              type: 'UPDATE_TICKETS',
              payload: {
                isSomethingWrong: true,
              },
            });
          }

          return;
        }

        const passengersFields = data.passengersDataFields.dataFields;

        let scheme = {};
        let initValues = {};
        const formFields = [];
        const formRequiredFields = [];
        const formFieldDropdownItems = {};
        const formFieldKeyNames = {};

        passengersFields.forEach((pass, key) => {
          const requiredFields = [];
          const fields = pass.Passenger.map(field => field.Key);

          pass.Passenger.forEach(field => {
            formFields.push(`${field.Key}${key + 1}`);
            formFieldKeyNames[field.Key] = field.KeyName;

            if (field.DropdownItems) {
              formFieldDropdownItems[`${field.Key}${key + 1}`] =
                field.DropdownItems;
            }
          });

          pass.Passenger.forEach(field => {
            if (field.DisplayType === 'FIELD_DISPLAY_TYPE.MANDATORY') {
              requiredFields.push(field.Key);
              formRequiredFields.push(`${field.Key}${key + 1}`);
            }
          });

          const areaCode = new PhoneNumber(phone).getCountryCode();
          const countryCode = new PhoneNumber(phone).getRegionCode();

          scheme = {
            ...scheme,
            ...(fields.includes('PassengerFirstName')
              ? {
                  ...(requiredFields.includes('PassengerFirstName')
                    ? {
                        [`PassengerFirstName${key + 1}`]: Yup.string()
                          .required(
                            t('validation.error.require', {
                              field: t('first name'),
                            })
                          )
                          .matches(
                            regExpForNames,
                            t('validation.error.names', {
                              field: t('first name'),
                            })
                          ),
                      }
                    : {
                        [`PassengerFirstName${key + 1}`]: Yup.string().matches(
                          regExpForNames,
                          t('validation.error.names', {
                            field: t('first name'),
                          })
                        ),
                      }),
                }
              : {}),
            ...(fields.includes('PassengerLastName')
              ? {
                  ...(requiredFields.includes('PassengerLastName')
                    ? {
                        [`PassengerLastName${key + 1}`]: Yup.string()
                          .required(
                            t('validation.error.require', {
                              field: t('last name'),
                            })
                          )
                          .matches(
                            regExpForNames,
                            t('validation.error.names', {
                              field: t('last name'),
                            })
                          ),
                      }
                    : {
                        [`PassengerLastName${key + 1}`]: Yup.string().matches(
                          regExpForNames,
                          t('validation.error.names', {
                            field: t('last name'),
                          })
                        ),
                      }),
                }
              : {}),
            ...(fields.includes('PassengerFatherName')
              ? {
                  ...(requiredFields.includes('PassengerFatherName')
                    ? {
                        [`PassengerFatherName${key + 1}`]: Yup.string()
                          .required(
                            t('validation.error.require', {
                              field: t('fathers name'),
                            })
                          )
                          .matches(
                            regExpForNames,
                            t('validation.error.names', {
                              field: t('fathers name'),
                            })
                          ),
                      }
                    : {
                        [`PassengerFatherName${key + 1}`]: Yup.string().matches(
                          regExpForNames,
                          t('validation.error.names', {
                            field: t('fathers name'),
                          })
                        ),
                      }),
                }
              : {}),
            // ...(fields.includes('PhoneAreaCode')
            //   ? {
            //       ...(requiredFields.includes('PhoneAreaCode')
            //         ? {
            //             [`PhoneAreaCode${key + 1}`]: Yup.string()
            //               .required(
            //                 t('validation.error.require', {
            //                   field: t('phone area code'),
            //                 })
            //               )
            //               .matches(
            //                 regExpForNames,
            //                 t('validation.error.names', {
            //                   field: t('phone area code'),
            //                 })
            //               ),
            //           }
            //         : {
            //             [`PhoneAreaCode${key + 1}`]: Yup.string().matches(
            //               regExpForNames,
            //               t('validation.error.names', {
            //                 field: t('phone area code'),
            //               })
            //             ),
            //           }),
            //     }
            //   : {}),
            ...(fields.includes('LoyaltyCardNumber')
              ? {
                  ...(requiredFields.includes('LoyaltyCardNumber')
                    ? {
                        [`LoyaltyCardNumber${key + 1}`]: Yup.string()
                          .required(
                            t('validation.error.require', {
                              field: t('loyalty card number'),
                            })
                          )
                          .matches(
                            regExpForNames,
                            t('validation.error.names', {
                              field: t('loyalty card number'),
                            })
                          ),
                      }
                    : {
                        [`LoyaltyCardNumber${key + 1}`]: Yup.string().matches(
                          regExpForNames,
                          t('validation.error.names', {
                            field: t('loyalty card number'),
                          })
                        ),
                      }),
                }
              : {}),
            ...(fields.includes('Email')
              ? {
                  ...(requiredFields.includes('Email')
                    ? {
                        [`Email${key + 1}`]: Yup.string()
                          .email(
                            t('validation.error.email', {
                              field: t('email'),
                            })
                          )
                          .required(
                            t('validation.error.require', {
                              field: t('email'),
                            })
                          ),
                      }
                    : {
                        [`Email${key + 1}`]: Yup.string().email(
                          t('validation.error.email', {
                            field: t('email'),
                          })
                        ),
                      }),
                }
              : {}),
            ...(fields.includes('PhoneNumber')
              ? {
                  ...(requiredFields.includes('PhoneNumber')
                    ? {
                        [`PhoneNumber${key + 1}`]: Yup.string()
                          .max(
                            20,
                            t('validation.error.max', {
                              field: t('phone number'),
                              max: 20,
                            })
                          )
                          .min(
                            10,
                            t('validation.error.min', {
                              field: t('phone number'),
                              min: 10,
                            })
                          )
                          .required(
                            t('validation.error.require', {
                              field: t('phone number'),
                            })
                          ),
                      }
                    : {
                        [`PhoneNumber${key + 1}`]: Yup.string()
                          .max(
                            20,
                            t('validation.error.max', {
                              field: t('phone number'),
                              max: 20,
                            })
                          )
                          .min(
                            10,
                            t('validation.error.min', {
                              field: t('phone number'),
                              min: 10,
                            })
                          ),
                      }),
                }
              : {}),
            ...(fields.includes('Gender')
              ? {
                  ...(requiredFields.includes('Gender')
                    ? {
                        [`Gender${key + 1}`]: Yup.string().required(
                          t('validation.error.require', {
                            field: t('gender'),
                          })
                        ),
                      }
                    : {}),
                }
              : {}),
            ...(fields.includes('DocumentNumber')
              ? {
                  ...(requiredFields.includes('DocumentNumber')
                    ? {
                        [`DocumentNumber${key + 1}`]: Yup.string().required(
                          t('validation.error.require', {
                            field: t('document number'),
                          })
                        ),
                      }
                    : {}),
                }
              : {}),
            ...(fields.includes('DocumentType')
              ? {
                  ...(requiredFields.includes('DocumentType')
                    ? {
                        [`DocumentType${key + 1}`]: Yup.string().required(
                          t('validation.error.require', {
                            field: t('document type'),
                          })
                        ),
                      }
                    : {}),
                }
              : {}),
            ...(fields.includes('DateOfBirth')
              ? {
                  ...(requiredFields.includes('DateOfBirth')
                    ? {
                        [`Day${key + 1}`]: Yup.string()
                          .min(
                            1,
                            t('validation.error.min', {
                              field: t('day'),
                              min: 1,
                            })
                          )
                          .max(
                            2,
                            t('validation.error.max', {
                              field: t('day'),
                              min: 2,
                            })
                          )
                          .required(
                            t('validation.error.require', {
                              field: t('day'),
                            })
                          ),
                        [`Month${key + 1}`]: Yup.string()
                          .min(
                            1,
                            t('validation.error.min', {
                              field: t('month'),
                              min: 1,
                            })
                          )
                          .max(
                            2,
                            t('validation.error.max', {
                              field: t('month'),
                              min: 2,
                            })
                          )
                          .required(
                            t('validation.error.require', {
                              field: t('month'),
                            })
                          ),
                        [`Year${key + 1}`]: Yup.string()
                          .length(
                            4,
                            t('validation.error.length', {
                              field: t('year'),
                              length: 4,
                            })
                          )
                          .required(
                            t('validation.error.require', {
                              field: t('year'),
                            })
                          ),
                      }
                    : {
                        [`Day${key + 1}`]: Yup.string()
                          .min(
                            1,
                            t('validation.error.min', {
                              field: t('day'),
                              min: 1,
                            })
                          )
                          .max(
                            2,
                            t('validation.error.max', {
                              field: t('day'),
                              min: 2,
                            })
                          ),
                        [`Month${key + 1}`]: Yup.string()
                          .min(
                            1,
                            t('validation.error.min', {
                              field: t('month'),
                              min: 1,
                            })
                          )
                          .max(
                            2,
                            t('validation.error.max', {
                              field: t('month'),
                              min: 2,
                            })
                          ),
                        [`Year${key + 1}`]: Yup.string().length(
                          4,
                          t('validation.error.length', {
                            field: t('year'),
                            length: 4,
                          })
                        ),
                      }),
                }
              : {}),
            ...(fields.includes('CitizenshipId')
              ? {
                  ...(requiredFields.includes('CitizenshipId')
                    ? {
                        [`CitizenshipId${key + 1}`]: Yup.string().required(
                          t('validation.error.require', {
                            field: t('citizenship'),
                          })
                        ),
                      }
                    : {}),
                }
              : {}),
            ...(fields.includes('LoyaltyCardNumber')
              ? {
                  ...(requiredFields.includes('LoyaltyCardNumber')
                    ? {
                        [`LoyaltyCardNumber${key + 1}`]: Yup.string().matches(
                          regExpForNames,
                          t('validation.error.names', {
                            field: t('loyalty card number'),
                          })
                        ),
                      }
                    : {}),
                }
              : {}),
          };

          initValues = {
            ...initValues,
            ...(fields.includes('PassengerFirstName')
              ? {
                  [`PassengerFirstName${key + 1}`]:
                    key === 0 ? firstName || '' : '',
                }
              : {}),

            ...(fields.includes('PassengerLastName')
              ? {
                  [`PassengerLastName${key + 1}`]:
                    key === 0 ? lastName || '' : '',
                }
              : {}),
            ...(fields.includes('PassengerFatherName')
              ? {
                  [`PassengerFatherName${key + 1}`]: '',
                }
              : {}),
            ...(fields.includes('Email')
              ? {
                  [`Email${key + 1}`]: key === 0 ? email || '' : '',
                }
              : {}),
            ...(fields.includes('PhoneNumber')
              ? {
                  [`PhoneNumber${key + 1}`]: key === 0 ? phone || '' : '',
                }
              : {}),

            ...(fields.includes('Gender')
              ? {
                  [`Gender${key + 1}`]: gender || 'SEX.MALE',
                }
              : {}),
            ...(fields.includes('CitizenshipId')
              ? {
                  [`CitizenshipId${key + 1}`]: '',
                }
              : {}),
            ...(fields.includes('DocumentNumber')
              ? {
                  [`DocumentNumber${key + 1}`]: '',
                }
              : {}),
            ...(fields.includes('DocumentType')
              ? {
                  [`DocumentType${key + 1}`]: '',
                }
              : {}),
            ...(fields.includes('DateOfBirth')
              ? {
                  [`Day${key + 1}`]: '',
                  [`Month${key + 1}`]: '',
                  [`Year${key + 1}`]: '',
                }
              : {}),
            ...(fields.includes('LoyaltyCardNumber')
              ? {
                  [`LoyaltyCardNumber${key + 1}`]: '',
                }
              : {}),
            ...passsengersInfo,
          };
        });

        setFormSettings({
          initValues,
          scheme,
          fields: formFields,
          requiredFields: formRequiredFields,
          formFieldDropdownItems,
          formFieldKeyNames,
        });
      },
    }
  );

  useEffect(() => {
    getPassengersDataFields();
  }, [language]);

  const [getBasketGroup, { loading: basketLoading }] = useLazyQuery(
    BASKET_PRODUCT_GROUPS,
    {
      fetchPolicy: 'network-only',
      variables: {
        lang: language,
      },
      onError: () => {
        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            isSomethingWrong: true,
          },
        });
      },
      onCompleted: data => {
        const { basketProductGroups = null } = data;
        if (!basketProductGroups) {
          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              isSomethingWrong: true,
            },
          });
          return;
        }

        const inboundPassengers = basketProductGroups.InboundJourneys;
        const outboundPassengers = basketProductGroups.OutboundJourneys;
        const { passengerPrices, passengersFee } = updatePassengersPricesV2(
          products,
          outboundPassengers,
          inboundPassengers
        );

        const { TotalPrice } = basketProductGroups;

        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            totalPrice: TotalPrice,
            passengerPrices,
            passengersFee,
          },
        });

        if (hasTransfers) {
          history.push(
            language === DEFAULT_LANG
              ? `/tickets/extras/`
              : `/${language}/tickets/extras/`
          );
        } else {
          history.push(
            language === DEFAULT_LANG
              ? `/tickets/payment/`
              : `/${language}/tickets/payment/`
          );
        }
      },
    }
  );

  const [sendBuyerInfo] = useMutation(UPDATE_BUYER_INFO);
  const [updatePassengerInfo, { loading }] = useMutation(
    UPDATE_PASSENGERS_INFO,
    {
      onCompleted: data => {
        const isError = Object.keys(data).some(key => {
          if (data[key].error) {
            if (data[key].error.ErrorCode === 'basket_expired') {
              dispatch({
                type: 'UPDATE_TICKETS',
                payload: {
                  isBasketExrpired: true,
                },
              });
            } else if (data[key].error.ErrorCode === 'missing_request') {
              dispatch({
                type: 'UPDATE_TICKETS',
                payload: {
                  isSomethingWrong: true,
                },
              });
            } else {
              setError(data[key].error.ErrorReason);
            }
            return true;
          }
          return false;
        });

        if (!isError) {
          // getBasket();

          // exponea.track('passengers_confirmed', {
          //   timestamp: Date.now(),
          //   passengers_confirmed: passengersCount,
          //   Origin_stop_ID: from.BusStopId,
          //   Destination_stop_ID: to.BusStopId,
          // });

          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              isPassengersInfoConfirmed: true,
            },
          });

          getBasketGroup();
        }
      },
    }
  );
  const passengers = products?.OutboundJourneys?.[0]?.Products || [];
  // const isInternational = TravelZone === 'International';

  const handleSubmit = () => {
    if (formRef.current) {
      const { values } = formRef.current;

      dispatch({
        type: 'UPDATE_TICKETS',
        payload: {
          passsengersInfo: values,
        },
      });

      // if (GTMLoaded) {
      //   dataLayer.push({
      //     tripType: returnDate ? 'return-trip' : 'one-way',
      //     departureStopId: from.BusStopId,
      //     destinationStopId: to.BusStopId,
      //     currencyCode: currencyName,
      //     route: `${from.CityName}-${to.CityName}`,
      //     price: totalPrice,
      //     promocode: promocode || null,
      //     departureDestination: `${from.CountryName}-${to.CountryName}`,
      //     stage: 'passengerDetails',
      //   });
      // }

      updatePassengerInfo({
        variables: {
          buyerInput: {
            FirstName: values.PassengerFirstName1,
            LastName: values.PassengerLastName1,
            Gender: values.Gender1,
            Email: values.Email1,
            PhoneNumber: values.PhoneNumber1,
            ProductId: passengers[0].ProductId,
          },
          customerInput: passengers.map((passenger, key) => {
            const passengerFields = formSettings.fields.filter(i =>
              i.endsWith(key + 1)
            );

            const data = {
              ProductId: passenger.ProductId,
            };

            passengerFields.forEach(f => {
              if (
                f === `PhoneAreaCode${key + 1}` &&
                passengerFields.includes(`PhoneNumber${key + 1}`) &&
                formSettings.requiredFields.includes(`PhoneNumber${key + 1}`)
              ) {
                data[f.replace(/[0-9]/g, '')] =
                  `${new PhoneNumber(
                    values[`PhoneNumber${key + 1}`]
                  ).getCountryCode()}` || '';
              } else if (
                f === `CitizenshipId${key + 1}` &&
                passengerFields.includes(`CitizenshipId${key + 1}`) &&
                formSettings.requiredFields.includes(`CitizenshipId${key + 1}`)
              ) {
                data[f.replace(/[0-9]/g, '')] = +values[f];
              } else {
                data[f.replace(/[0-9]/g, '')] = values[f];
              }
            });

            return data;
          }),
        },
      });
    }
  };

  useEffect(() => {
    if (!GTM.passengerInfoStep) {
      if (GTMLoaded) {
        // dataLayer.push({
        //   tripType: returnDate ? 'return-trip' : 'one-way',
        //   departureStopId: from.BusStopId,
        //   destinationStopId: to.BusStopId,
        //   currencyCode: currencyName,
        //   route: `${from.CityName}-${to.CityName}`,
        //   price: totalPrice,
        //   promocode: promocode || null,
        //   departureDestination: `${from.CountryName}-${to.CountryName}`,
        //   stage: 'passengerDetails',
        //   event: 'passenger-details',
        // });

        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            GTM: {
              ...GTM,
              passengerInfoStep: true,
            },
          },
        });
      }
    }
  }, [GTMLoaded]);

  return (
    <div className={cn(s.passengerDetails, className)}>
      <DiscardedBasket
        isOpen={isOpenModal}
        toggleModal={toggleModal}
        url={redirectUrl}
      />
      <Login isOpen={isOpenLoginModal} toggleModal={toggleLoginModal} />
      <Container className={s.breadcrumbs}>
        <Container full={false}>
          <section>
            <div
              className={s.item}
              onClick={() => {
                setRedirectUrl(`/tickets/search${lastSearch}`);
                toggleModal();
              }}
            >
              {t('search')}
            </div>
            <Link to="/tickets/seat-details/" className={s.item}>
              <i className={i.arrowBottom} />
              1.&nbsp;<span>{t('seat selection')}</span>
            </Link>
            <div className={cn(s.item, s.active)}>
              <i className={i.arrowBottom} />
              2.&nbsp;<span>{t('passengers details')}</span>
            </div>
            {hasTransfers && (
              <div className={s.item}>
                <i className={i.arrowBottom} />
                3.&nbsp;<span>{t('taxi transfer')}</span>
              </div>
            )}

            <div className={s.item}>
              <i className={i.arrowBottom} />
              {hasTransfers ? '4' : '3'}.&nbsp;
              <span>{t('payment details')}</span>
            </div>
          </section>
        </Container>
      </Container>
      <Container className={s.content}>
        <Container full={false}>
          <section>
            <div className={s.forms}>
              {fieldsLoading ? (
                <Loading />
              ) : (
                <Formik
                  innerRef={formRef}
                  validateOnMount
                  enableReinitialize
                  initialValues={formSettings.initValues}
                  validationSchema={Yup.object().shape(formSettings.scheme)}
                >
                  {props => {
                    const {
                      values,
                      touched,
                      errors,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                    } = props;
                    const isTochedForm = !!Object.keys(touched).length;
                    const isValidForm = !Object.keys(errors).length;
                    const isDisabled = !isValidForm || isSubmitting;
                    const isDateOfBirthError =
                      (touched.day && errors.day) ||
                      (touched.month && errors.month) ||
                      (touched.year && errors.year);

                    useEffect(() => {
                      dispatch({
                        type: 'UPDATE_TICKETS',
                        payload: {
                          isFormDisabled: isDisabled,
                        },
                      });
                    }, [isDisabled]);

                    const sortedPassengers = passengers.reduce(
                      (arr, current) => {
                        if (
                          current.RequestedFareClass ===
                          'BONUS_SCHEME_GROUP.CHILDREN'
                        ) {
                          arr.push(current);
                        } else {
                          arr.unshift(current);
                        }

                        return arr;
                      },
                      []
                    );

                    return sortedPassengers.map((passenger, key) => (
                      <PassengerForm
                        isPassengersInfoConfirmed={isPassengersInfoConfirmed}
                        key={passenger.ProductId}
                        number={key + 1}
                        error={error}
                        passenger={passenger}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        errors={errors}
                        values={values}
                        success={success}
                        isDateOfBirthError={isDateOfBirthError}
                        // isInternational={isInternational}
                        legs={legs}
                        formSettings={formSettings}
                      />
                    ));
                  }}
                </Formik>
              )}
            </div>
            {isTabDown ? (
              <TripDetails
                nextStep="transfer"
                onContinueClick={handleSubmit}
                disabledNextStep={isFormDisabled}
                loading={loading || basketLoading}
              />
            ) : (
              <div className={s.tripDetails}>
                {!email && (
                  <div className={s.block}>
                    <Divider absolute height={3} vertical />
                    <div className={s.register}>
                      <h1>{t('login')}</h1>
                      <p>
                        {t('log in to prefill forms and manage your bookings.')}
                      </p>
                      <div className={s.controls}>
                        <Button
                          className={s.button}
                          text={t('login')}
                          onClick={toggleLoginModal}
                        />
                        {/* <div
                          className={s.link}
                          onClick={() => {
                            setRedirectUrl('/registration');
                            toggleModal();
                          }}
                          children={t('register')}
                        /> */}
                      </div>
                    </div>
                    <img src="/images/svg/login-banner.svg" alt="" />
                  </div>
                )}
                <TripDetails
                  nextStep="transfer"
                  onContinueClick={handleSubmit}
                  disabledNextStep={isFormDisabled}
                  loading={loading || basketLoading}
                />
              </div>
            )}
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(memo(PassengerDetails));
