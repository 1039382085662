export const emptyData = {
  lang: 'en',
  slug: '',
  isPublished: true,
  countries: ['ee', 'ru', 'fi', 'lv', 'lt'],
  seo: {
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 80,
    },
    slug: {
      value: '',
      fieldName: 'slug',
      placeholder: 'Slug / URL',
      maxLength: 120,
    },
    thumbnail: {
      value: '',
      fieldName: 'thumbnail',
      placeholder: 'Thumbnail',
      size: {
        height: 300,
        width: 520,
      },
    },
    description: {
      value: '',
      fieldName: 'description',
      placeholder: 'Page description',
      maxLength: 158,
    },
    socialTitle: {
      value: '',
      fieldName: 'socialTitle',
      placeholder: 'Social Title',
      maxLength: 160,
    },
    socialDescription: {
      value: '',
      fieldName: 'socialDescription',
      placeholder: 'Social description',
      maxLength: 160,
    },
    pageKeywords: {
      value: '',
      fieldName: 'pageKeywords',
      placeholder: 'Page keywords',
      maxLength: 40,
    },
    socialImage: '',
    articlePublishedTime: '',
    articleModifiedTime: '',
  },
  pageData: {
    showEvents: true,
    events: {
      slides: [],
      fieldName: 'events',
    },
    slider: {
      slides: [],
      fieldName: 'slider',
    },
    banner: {
      value: '',
      fieldName: 'banner',
      placeholder: 'Banner',
      size: {
        height: 1100,
        width: 2880,
      },
    },
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 50,
    },
    subTitle: {
      value: '',
      fieldName: 'subTitle',
      placeholder: 'Page subtitle',
      maxLength: 200,
    },
    heading1: {
      value: '',
      fieldName: 'heading1',
      placeholder: 'Heading',
      maxLength: 100,
    },
    text1: {
      value: '',
      fieldName: 'text1',
      placeholder: 'Text',
      maxLength: 300,
    },
    text2: {
      value: '',
      fieldName: 'text2',
      placeholder: 'Text',
      maxLength: 300,
    },
    text3: {
      value: '',
      fieldName: 'text3',
      placeholder: 'Text',
      maxLength: 300,
    },
    heading2: {
      value: '',
      fieldName: 'heading2',
      placeholder: 'Heading',
      maxLength: 100,
    },
    text4: {
      value: '',
      fieldName: 'text4',
      placeholder: 'Text',
      maxLength: 300,
    },
    heading3: {
      value: '',
      fieldName: 'heading3',
      placeholder: 'Heading',
      maxLength: 100,
    },
  },
};

export const defaultData = {
  lang: 'en',
  slug: 'test',
  isPublished: true,
  countries: ['ee', 'ru', 'fi', 'lv', 'lt'],
  seo: {
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 80,
    },
    slug: {
      value: '',
      fieldName: 'slug',
      placeholder: 'Slug / URL',
      maxLength: 120,
    },
    thumbnail: {
      value: '',
      fieldName: 'thumbnail',
      placeholder: 'Thumbnail',
      size: {
        height: 300,
        width: 520,
      },
    },
    description: {
      value: 'Page description',
      fieldName: 'description',
      placeholder: 'Page description',
      maxLength: 158,
    },
    socialTitle: {
      value: 'Social Title',
      fieldName: 'socialTitle',
      placeholder: 'Social Title',
      maxLength: 160,
    },
    socialDescription: {
      value: 'Social description',
      fieldName: 'socialDescription',
      placeholder: 'Social description',
      maxLength: 160,
    },
    pageKeywords: {
      value: 'Page keywords',
      fieldName: 'pageKeywords',
      placeholder: 'Page keywords',
      maxLength: 40,
    },
    socialImage: '',
  },
  pageData: {
    showEvents: true,
    events: {
      slides: [],
      fieldName: 'events',
    },
    slider: {
      slides: [],
      fieldName: 'slider',
    },
    banner: {
      value: '',
      fieldName: 'banner',
      placeholder: 'Banner',
      size: {
        height: 1100,
        width: 2880,
      },
    },
    title: {
      value: 'Destination page sub title',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 50,
    },
    subTitle: {
      value:
        'The pearl of the Baltics is just a few hours away. Relax comfortably in a Lux Express bus and be amazed by what Riga has to offer.',
      fieldName: 'subTitle',
      placeholder: 'Page subtitle',
      maxLength: 200,
    },
    heading1: {
      value: 'Riga pearl of the Baltics',
      fieldName: 'heading1',
      placeholder: 'Heading',
      maxLength: 100,
    },
    text1: {
      value: 'Of Latvia’s 2.350.000 inhabitants approx. 750.000 live in Riga.',
      fieldName: 'text1',
      placeholder: 'Text',
      maxLength: 100,
    },
    text2: {
      value:
        'Of Latvia’s 2.350.000 inhabitants approx. 750.000 live in Riga. The city is located on both banks of the river Daugava which separates the city into two parts, Vecriga (Old Riga) and Pardaugava. Four bridges, one only for railway traffic, connect the banks.',
      fieldName: 'text2',
      placeholder: 'Text',
      maxLength: 100,
    },
    text3: {
      value:
        'Riga is close to the Gulf of Riga at the Baltic Sea and the harbour is an important part of the citys development. Once the river was an important as a strategically location called the Amber Way where the Nordic Vikings started their travels east).',
      fieldName: 'text3',
      placeholder: 'Text',
      maxLength: 100,
    },
    heading2: {
      value: 'Our favorite events in Riga',
      fieldName: 'heading2',
      placeholder: 'Heading',
      maxLength: 100,
    },
    text4: {
      value:
        'Browse through some of the best collections in Riga hand picked by people who know the city best.',
      fieldName: 'text4',
      placeholder: 'Text',
      maxLength: 100,
    },
    heading3: {
      value: 'Discover wonderfull Riga',
      fieldName: 'heading3',
      placeholder: 'Heading',
      maxLength: 100,
    },
  },
};
