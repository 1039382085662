import React, { useEffect, useState, useContext } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { StoreContext } from '@lux/spa/store';
import { Button } from '@lux/ui';
import { Info } from '../components';
import s from '../refund.module.scss';

const ChooseTrip = ({
  t,
  i18n,
  nextStep,
  prevStep,
  goToStep,
  dataRefund,
  setDataRefund,
  history,
}) => {
  const [state, dispatch] = useContext(StoreContext);
  const { language } = i18n;
  const { isPublic } = state.tickets.refund;
  const { returnInfoDefault, isOnlyOnePass, ways } = dataRefund;
  const { RefundableProducts } = returnInfoDefault;
  const { RefundingScopes } = RefundableProducts[0];
  const [selectedWays, setSelectedWays] = useState(
    RefundingScopes.map((_, key) => {
      return key === 0;
    })
  );

  const updateWays = number => {
    setSelectedWays(prev => {
      const newArray = prev.map(i => false);
      newArray[number] = true;

      let selectedWay = '';

      RefundingScopes.forEach((item, key) => {
        if (newArray[key]) {
          selectedWay = item;
        }
      });

      setDataRefund(prev => {
        return {
          ...prev,
          selectedWay,
        };
      });

      return [...newArray];
    });
  };

  useEffect(() => {
    let selectedWay = '';

    RefundingScopes.forEach((item, key) => {
      if (selectedWays[key]) {
        selectedWay = item;
      }
    });

    setDataRefund(prev => {
      return {
        ...prev,
        selectedWay,
      };
    });
  }, []);

  return (
    <div className={cn(s.step, s.chooseTrip)}>
      <div className={s.content}>
        <h3>{t('refund tickets')}</h3>
        <p>{t('please, choose trip to refund')}</p>
        <form className={s.form}>
          <div className={s.ways}>
            {RefundingScopes.map((scope, key) => {
              return (
                <div
                  className={cn(s.way, selectedWays[key] && s.selected)}
                  onClick={() => updateWays(key)}
                >
                  <div className={s.check} />
                  {scope.RefundingScopeXc === 'RETURNING_SCOPE.WHOLE_PRODUCT' &&
                    t('entire ticket')}
                  {scope.RefundingScopeXc ===
                    'RETURNING_SCOPE.OUTBOUND_JOURNEY_WAY' &&
                    (ways[0].IsOutbound
                      ? `${ways[0].DepartureStopName} - ${ways[0].DestinationStopName}`
                      : `${ways[1].DepartureStopName} - ${ways[1].DestinationStopName}`)}
                  {scope.RefundingScopeXc ===
                    'RETURNING_SCOPE.INBOUND_JOURNEY_WAY' &&
                    (ways[0].IsOutbound
                      ? `${ways[1].DepartureStopName} - ${ways[1].DestinationStopName}`
                      : `${ways[0].DepartureStopName} - ${ways[0].DestinationStopName}`)}
                </div>
              );
            })}
          </div>

          <div className={s.controls}>
            <Button
              disabled={!RefundingScopes.length}
              className={s.button}
              text={t('continue')}
              onClick={nextStep}
            />
            <div
              className={s.cancel}
              onClick={
                isPublic
                  ? () => history.push(`/${language}/manage-booking/trips/`)
                  : isOnlyOnePass
                  ? () => goToStep(1)
                  : prevStep
              }
            >
              {t('cancel')}
            </div>
          </div>
        </form>
      </div>
      <Info dataRefund={dataRefund} />
    </div>
  );
};

export default withRouter(withTranslation()(ChooseTrip));
