import React, { useState, useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withRouter } from 'react-router';
import { Trans, withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { StoreContext } from '@lux/spa/store';
import { DEFAULT_LANG, validToUseExponea } from '@lux/spa/config';
import { CORPORATE_CLIENT_PAY } from '@lux/spa/apollo/mutations';
import { TicketsInput, Button, HiddenInput, Notice } from '@lux/ui';
import s from './corporate-client.module.scss';

const CorporeteClient = ({ t, i18n, history }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { language } = i18n;
  const { tickets = {} } = state;
  const { products = {}, totalPrice, from = {}, to = {} } = tickets;
  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';
  const { ShoppingBasketId = '' } = products;
  const [clientError, setClientError] = useState('');
  const [corporateClient] = useMutation(CORPORATE_CLIENT_PAY);

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{
        userName: '',
        password: '',
      }}
      validationSchema={Yup.object().shape({
        userName: Yup.string().required(
          t('validation.error.require', {
            field: t('user name'),
          })
        ),
        password: Yup.string()
          .required(
            t('validation.error.require', {
              field: t('password'),
            })
          )
          .min(
            6,
            t('validation.error.min', {
              field: t('password'),
              min: 6,
            })
          ),
      })}
      onSubmit={async (values, { setFieldError }) => {
        const { userName, password } = values;
        corporateClient({
          variables: {
            input: {
              Username: userName,
              Password: password,
              Currency: 'CURRENCY.RUB',
            },
          },
        })
          .then(data => {
            const error = data?.data?.corporateClientPay?.error;
            const success = data?.data?.corporateClientPay?.success;

            if (error) {
              if (error.ErrorCode === 'wrong_password') {
                setFieldError('password', error.ErrorReason);
                return;
              }

              if (error.ErrorCode === 'basket_expired') {
                dispatch({
                  type: 'UPDATE_TICKETS',
                  payload: {
                    isBasketExrpired: true,
                  },
                });
                return;
              }

              setClientError(error.ErrorReason);
            }

            if (success) {
              if (validToUseExponea()) {
                exponea.track('payment_selected', {
                  timestamp: Date.now(),
                  payment_method: 'corporate',
                  total_price: totalPrice,
                  currency: 'RUB',
                  Origin_stop_ID: from.BusStopId,
                  Destination_stop_ID: to.BusStopId,
                });
              }

              const promise = new Promise(res => res());

              promise
                .then(() => {
                  if (data?.data?.corporateClientPay?.objects?.[0]) {
                    dispatch({
                      type: 'UPDATE_TICKETS',
                      payload: {
                        forAnalitics: {
                          objects: data?.data?.corporateClientPay?.objects,
                        },
                      },
                    });
                  }
                })
                .then(() => {
                  history.push(
                    language === DEFAULT_LANG
                      ? `/tickets/thanks?type=corporate-client&shopperOrder=${ShoppingBasketId}`
                      : `/${language}/tickets/thanks?type=corporate-client&shopperOrder=${ShoppingBasketId}`
                  );
                });
            }
          })
          .catch(() => {
            dispatch({
              type: 'UPDATE_TICKETS',
              payload: {
                isSomethingWrong: true,
              },
            });
          });
      }}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          setFieldError,
        } = props;
        const isTochedForm = !!Object.keys(touched).length;
        const isValidForm = !Object.keys(errors).length;
        return (
          <>
            <div className={s.corporateClient}>
              <form className={s.form}>
                {clientError && <Notice color="red" text={clientError} />}
                <TicketsInput
                  name="userName"
                  placeholder={t('enter username')}
                  type="text"
                  label={t('username')}
                  required
                  value={values.userName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={touched.userName && errors.userName}
                />
                <HiddenInput
                  tooltipText={t(
                    'by completing this booking, I confirm that I have read and agree to LuxExpress Terms and Privacy Policy'
                  )}
                  name="password"
                  placeholder="••••••"
                  label={t('password')}
                  required
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={touched.password && errors.password}
                />
                <Button
                  // type="submit"
                  disabled={!(isTochedForm && isValidForm) || isSubmitting}
                  text={t('submit')}
                  onClick={() => {
                    corporateClient({
                      variables: {
                        input: {
                          Username: values.userName,
                          Password: values.password,
                          Currency: 'CURRENCY.RUB',
                        },
                      },
                    }).then(data => {
                      const error = data?.data?.corporateClientPay?.error;
                      const success = data?.data?.corporateClientPay?.success;

                      if (error) {
                        if (error.ErrorCode === 'wrong_password') {
                          setFieldError('password', error.ErrorReason);
                          return;
                        }

                        if (error.ErrorCode === 'basket_expired') {
                          dispatch({
                            type: 'UPDATE_TICKETS',
                            payload: {
                              isBasketExrpired: true,
                            },
                          });
                          return;
                        }

                        setClientError(error.ErrorReason);
                      }

                      if (success) {
                        if (data?.data?.corporateClientPay?.objects?.[0]) {
                          dispatch({
                            type: 'UPDATE_TICKETS',
                            payload: {
                              forAnalitics: {
                                objects:
                                  data?.data?.corporateClientPay?.objects,
                              },
                            },
                          });
                        }

                        history.push(
                          language === DEFAULT_LANG
                            ? `/tickets/thanks?type=corporate-client&shopperOrder=${ShoppingBasketId}`
                            : `/${language}/tickets/thanks?type=corporate-client&shopperOrder=${ShoppingBasketId}`
                        );
                      }
                    });
                  }}
                />
              </form>
              <Notice className={s.notice}>
                <Trans i18nKey="if you want to become a corporate client, please contact us">
                  if you want to become a corporate client, please
                  <a href="mailto:ariklient@luxexpress.eu">contact us</a>
                </Trans>
                <br />
                <br />
                {t(
                  'corporate clients must enter their credentials upon payment. You do not need log in here.'
                )}
              </Notice>

              {/* <Notice
                className={s.notice}
                text={t(
                  'by completing this booking, I confirm that I have read and agree to LuxExpress Terms and Privacy Policy'
                )}
              /> */}
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default withRouter(withTranslation()(CorporeteClient));
