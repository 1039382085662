import React, { useState, useContext, memo, useEffect, createRef } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import { DEFAULT_LANG } from '@lux/spa/config';
import { Collapse } from 'react-collapse';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { StoreContext } from '@lux/spa/store';
import {
  Container,
  Link,
  DiscardedBasket,
  Notice,
  Loading,
  Select,
  SelectOption,
  Dropdown,
  DropdownOption,
  ShowImage,
  TicketsInput,
  PhoneInput,
} from '@lux/ui';
import { localizedDate, updatePassengersPricesV2 } from 'utils';
import {
  AVAILABLE_TRANSFERS,
  TRANSFER_INFORNATION,
  ADD_TRANSFER,
  DELETE_PRODUCTS_FROM_BASKET,
} from '@lux/spa/apollo/mutations';
import { BASKET_PRODUCT_GROUPS } from '@lux/spa/apollo/queries';
import { TripDetails } from '@lux/spa/components';
import i from '@lux/spa/styles/icons.module.scss';
import s from './transfer.module.scss';
import { Title } from './components';

const CustomDropdown = ({
  t,
  transfers,
  selectedTransferId,
  symbol,
  price,
  onChangeZone,
  isOutbound,
}) => {
  const transfer =
    transfers.filter(
      transfer => transfer.TransferId === selectedTransferId
    )[0] || transfers?.[0];
  return (
    <Dropdown
      classNameTarget={s.mainTarget}
      Target={({ onClick, isOpen, ...props }) => {
        return (
          <div {...props} onClick={onClick}>
            <div className={cn(s.target, isOpen && s.open)}>
              <div className={s.info}>
                <div className={s.transferName}>
                  <div className={s.transferNameText}>{transfer.Name}</div>
                  <i className={cn(i.arrowBottom, s.arrow)} />
                </div>
                {/* <div className={s.description}>Harbour included</div> */}
              </div>
              <div className={s.price}>{`${transfer.Price} ${symbol}`}</div>
            </div>
          </div>
        );
      }}
    >
      {transfers.map((transfer, key) => {
        return (
          <DropdownOption
            className={s.dropdownOption}
            callback={() => onChangeZone(transfer.TransferId, isOutbound)}
            key={key}
          >
            <div className={s.info}>
              <div className={s.transferName}>
                <div className={s.transferNameText}>{transfer.Name}</div>
              </div>
              {/* <div className={s.description}>Harbour included</div> */}
            </div>
            <div className={s.price}>{`${transfer.Price} ${symbol}`}</div>
          </DropdownOption>
        );
      })}
    </Dropdown>
  );
};

const Transfer = ({ t, i18n, className, history }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { isTabDown } = useContext(WidthContext);
  const { language } = i18n;
  const { tickets } = state;
  const [shownImage, setShownImage] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenImage, setIsOpenImage] = useState(false);
  const [isOutboundErrors, setOutboundErrors] = useState(false);
  const [isInboundErrors, setInboundErrors] = useState(false);
  const [persons, setPersons] = useState([]);

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const toggleImage = () => {
    setIsOpenImage(!isOpenImage);
  };

  const setImage = image => {
    setShownImage(image);
    toggleImage();
  };

  const {
    products = {},
    lastSearch,
    transfers,
    symbol,
    basketTransfers,
    hasTransfers,
  } = tickets;

  const {
    isOutbound,
    isInbound,
    called,
    OutboundJourneys = [],
    InboundJourneys = [],
    outboundInformation = {},
    inboundInformation = {},
    outboundTransfer = {},
    inboundTransfer = {},
    isOutboundOther,
    isInboundOther,
  } = transfers;

  const phoneNumberInput = createRef();

  const inboundTransfers = InboundJourneys;
  const outboundTransfers = OutboundJourneys;

  let productIds = [];

  if (products?.OutboundJourneys?.[0]?.Products?.length) {
    productIds = products.OutboundJourneys[0].Products.map(
      item => item.ProductId
    );
  }

  const personFields = [
    ...new Set(
      Object.keys(tickets.passsengersInfo).map(item => item.replace(/\d+/g, ''))
    ),
  ];

  const selectedOutboundTransfer =
    outboundTransfers.filter(
      transfer => transfer.TransferId === outboundTransfer.TransferId
    )[0] || outboundTransfers?.[0];
  const selectedInboundTransfer =
    inboundTransfers.filter(
      transfer => transfer.TransferId === inboundTransfers.TransferId
    )[0] || inboundTransfers?.[0];

  const [addTransfer, { loading: addTransferLoading }] = useMutation(
    ADD_TRANSFER
  );

  const [removeTransfers, { loading: removeTransfersLoading }] = useMutation(
    DELETE_PRODUCTS_FROM_BASKET
  );

  const [
    getTransferInformation,
    { loading: loadingTransferInformation },
  ] = useMutation(TRANSFER_INFORNATION, {
    onError: () => {
      dispatch({
        type: 'UPDATE_TICKETS',
        payload: {
          isSomethingWrong: true,
        },
      });
    },
    onCompleted: data => {
      if (data.transferInformation.error) {
        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            isSomethingWrong: true,
          },
        });
      }
    },
  });

  const [
    getAvailableTransfers,
    { loading: transfersLoading = true },
  ] = useMutation(AVAILABLE_TRANSFERS, {
    variables: {
      lang: language,
      productIds,
    },
    onError: () => {
      dispatch({
        type: 'UPDATE_TICKETS',
        payload: {
          isSomethingWrong: true,
        },
      });
    },
    onCompleted: data => {
      if (data.availableTransfers.error) {
        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            isSomethingWrong: true,
          },
        });
      } else {
        if (
          !data.availableTransfers.transfers.InboundJourneys.length &&
          !data.availableTransfers.transfers.OutboundJourneys.length
        ) {
          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              hasTransfers: false,
            },
          });
          return history.push(
            language === DEFAULT_LANG
              ? `/tickets/payment/`
              : `/${language}/tickets/payment/`
          );
        }

        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            transfers: {
              ...transfers,
              ...data.availableTransfers.transfers,
              called: true,
            },
          },
        });
      }
    },
  });

  const [getBasketGroup, { loading: basketLoading }] = useLazyQuery(
    BASKET_PRODUCT_GROUPS,
    {
      fetchPolicy: 'network-only',
      variables: {
        lang: language,
      },
      onError: () => {
        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            isSomethingWrong: true,
          },
        });
      },
      onCompleted: data => {
        const { basketProductGroups = null } = data;
        if (!basketProductGroups) {
          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              isSomethingWrong: true,
            },
          });
          return;
        }

        const inboundPassengers = basketProductGroups.InboundJourneys;
        const outboundPassengers = basketProductGroups.OutboundJourneys;
        const basketTransfers = basketProductGroups.Transfers;
        const { passengerPrices, passengersFee } = updatePassengersPricesV2(
          products,
          outboundPassengers,
          inboundPassengers
        );

        let basketTransfersPrice = 0;

        if (basketTransfers.length) {
          basketTransfersPrice = basketTransfers.reduce(
            (sum, transfer) => sum + transfer.Price,
            0
          );
        }

        const { TotalPrice } = basketProductGroups;

        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            totalPrice: TotalPrice,
            passengersFee,
            passengerPrices,
            basketTransfers,
            basketTransfersPrice,
          },
        });

        history.push(
          language === DEFAULT_LANG
            ? `/tickets/payment/`
            : `/${language}/tickets/payment/`
        );
      },
    }
  );

  const getOutboundTransferInformation = (TransferId = '') => {
    getTransferInformation({
      variables: {
        lang: language,
        transferId: TransferId || outboundTransfers[0].TransferId,
      },
    })
      .then(data => {
        const { transfer = {} } = data.data.transferInformation;
        dispatch({
          type: 'UPDATE_TRANSFERS',
          payload: {
            outboundInformation: {
              ...transfer,
              // ServiceDescription: transfer.ServiceDescription,
              // ServiceDescription: transfer.ServiceDescription.replace(
              //   /(\r\n|\n|\r|\t)/gm,
              //   ''
              // )
              //   .replace(/(<([^>]+)>)/gi, '')
              //   .trim(),
            },
          },
        });
      })
      .catch(() => {
        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            isSomethingWrong: true,
          },
        });
      });
  };

  const getInboundTransferInformation = (TransferId = '') => {
    getTransferInformation({
      variables: {
        lang: language,
        transferId: TransferId || inboundTransfers[0].TransferId,
      },
    })
      .then(data => {
        const { transfer } = data.data.transferInformation;
        dispatch({
          type: 'UPDATE_TRANSFERS',
          payload: {
            inboundInformation: {
              ...transfer,
              // ServiceDescription: transfer.ServiceDescription,
              // ServiceDescription: transfer.ServiceDescription.replace(
              //   /(<([^>]+)>)/gi,
              //   ''
              // )
              //   .replace(/(\r\n|\n|\r|\t)/gm, '')
              //   .trim(),
            },
          },
        });
      })
      .catch(() => {
        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            isSomethingWrong: true,
          },
        });
      });
  };

  const handleRequestTransfer = (outbound = true) => {
    dispatch({
      type: 'UPDATE_TRANSFERS',
      payload: {
        ...(outbound
          ? {
              isOutbound: !isOutbound,
              ...(!isOutbound
                ? {
                    outboundTransfer: {
                      ...outboundTransfer,
                      PassengerName: `${persons[0].PassengerFirstName} ${persons[0].PassengerLastName}`,
                      PassengerPhone: persons[0].PhoneNumber,
                      TransferId: outboundTransfers[0].TransferId,
                    },
                  }
                : {}),
            }
          : {
              isInbound: !isInbound,
              ...(!isInbound
                ? {
                    inboundTransfer: {
                      ...inboundTransfer,
                      PassengerName: `${persons[0].PassengerFirstName} ${persons[0].PassengerLastName}`,
                      PassengerPhone: persons[0].PhoneNumber,
                      TransferId: inboundTransfers[0].TransferId,
                    },
                  }
                : {}),
            }),
      },
    });
  };

  const updateCount = (type, direction) => {
    const { Quantity } = transfers[direction];

    dispatch({
      type: 'UPDATE_TRANSFERS',
      payload: {
        [direction]: {
          ...(direction === 'outboundTransfer'
            ? outboundTransfer
            : inboundTransfer),
          Quantity:
            type === 'plus' ? Quantity + 1 : Quantity <= 1 ? 1 : Quantity - 1,
        },
      },
    });
  };

  const onChangeZone = (TransferId, isOutbound) => {
    if (isOutbound) {
      getOutboundTransferInformation(TransferId);
    } else {
      getInboundTransferInformation(TransferId);
    }

    dispatch({
      type: 'UPDATE_TRANSFERS',
      payload: {
        [isOutbound ? 'outboundTransfer' : 'inboundTransfer']: {
          ...(isOutbound ? outboundTransfer : inboundTransfer),
          TransferId,
        },
      },
    });
  };

  const selectOtherPerson = direction => {
    dispatch({
      type: 'UPDATE_TRANSFERS',
      payload: {
        ...(direction === 'outboundTransfer'
          ? {
              isOutboundOther: true,
            }
          : {
              isInboundOther: true,
            }),
        [direction]: {
          ...(direction === 'outboundTransfer'
            ? outboundTransfer
            : inboundTransfer),
          PassengerName: '',
          PassengerPhone: '',
        },
      },
    });
  };

  const selectPerson = (PassengerPhone, direction) => {
    const person =
      persons.filter(person => person.PhoneNumber === PassengerPhone)[0] ||
      persons.filter(person => !!person.PassengerPhone)[0];

    dispatch({
      type: 'UPDATE_TRANSFERS',
      payload: {
        ...(direction === 'outboundTransfer'
          ? {
              isOutboundOther: false,
            }
          : {
              isInboundOther: false,
            }),
        [direction]: {
          ...(direction === 'outboundTransfer'
            ? outboundTransfer
            : inboundTransfer),
          PassengerName: `${person.PassengerFirstName} ${person.PassengerLastName}`,
          PassengerPhone: person.PhoneNumber,
        },
      },
    });
  };

  const handleContinue = () => {
    const Transfers = [];

    if (isOutbound) {
      Transfers.push(outboundTransfer);
    }

    if (isInbound) {
      Transfers.push(inboundTransfer);
    }

    const transfetsToRemove = basketTransfers.map(t => t.ProductId);

    if (transfetsToRemove.length) {
      removeTransfers({
        variables: {
          ProductIds: transfetsToRemove,
        },
      })
        .then(data => {
          if (data.data.deleteProductsFromBasket.success) {
            if (Transfers.length) {
              addTransfer({
                variables: {
                  Transfers,
                },
                // onCompleted: data => {
                //   console.log('onCompleted addTransfer', data);
                // },
              })
                .then(data => {
                  if (data.data.addTransfer.error) {
                    dispatch({
                      type: 'UPDATE_TICKETS',
                      payload: {
                        isSomethingWrong: true,
                      },
                    });
                  }

                  if (data.data.addTransfer.success) {
                    getBasketGroup();
                  }
                })
                .catch(() => {
                  dispatch({
                    type: 'UPDATE_TICKETS',
                    payload: {
                      isSomethingWrong: true,
                    },
                  });
                });
            } else {
              getBasketGroup();
            }
          } else {
            dispatch({
              type: 'UPDATE_TICKETS',
              payload: {
                isSomethingWrong: true,
              },
            });
          }
        })
        .catch(() => {
          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              isSomethingWrong: true,
            },
          });
        });
    } else if (Transfers.length) {
      addTransfer({
        variables: {
          Transfers,
        },
      })
        .then(data => {
          if (data.data.addTransfer.error) {
            dispatch({
              type: 'UPDATE_TICKETS',
              payload: {
                isSomethingWrong: true,
              },
            });
          }

          if (data.data.addTransfer.success) {
            getBasketGroup();
          }
        })
        .catch(() => {
          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              isSomethingWrong: true,
            },
          });
        });
    } else {
      history.push(
        language === DEFAULT_LANG
          ? `/tickets/payment/`
          : `/${language}/tickets/payment/`
      );
    }
  };

  useEffect(() => {
    products?.OutboundJourneys?.[0]?.Products?.map((_, key) => {
      const newPerson = {};
      const hasPhone = tickets.passsengersInfo[`PhoneNumber${key + 1}`];

      if (hasPhone) {
        personFields.forEach(field => {
          newPerson[field] = tickets.passsengersInfo[`${field}${key + 1}`];
        });

        persons.push(newPerson);
      }
    });

    setPersons(persons);
  }, []);

  useEffect(() => {
    if (isOutbound) {
      getOutboundTransferInformation(outboundTransfer.TransferId);
    }
  }, [isOutbound]);

  useEffect(() => {
    if (isInbound) {
      getInboundTransferInformation(inboundTransfer.TransferId);
    }
  }, [isInbound]);

  useEffect(() => {
    if (!called) {
      getAvailableTransfers();
    }
  }, []);

  let totalTransferPrice = null;

  if (isOutbound && outboundTransfers) {
    const price = outboundTransfers.filter(
      t => t.TransferId === outboundTransfer.TransferId
    )[0].Price;

    totalTransferPrice += price * outboundTransfer.Quantity;
  }

  if (isInbound && inboundTransfers) {
    const price = inboundTransfers.filter(
      t => t.TransferId === inboundTransfer.TransferId
    )[0].Price;

    totalTransferPrice += price * inboundTransfer.Quantity;
  }

  return (
    <div className={cn(s.transfer, className)}>
      <DiscardedBasket
        isOpen={isOpenModal}
        toggleModal={toggleModal}
        url={`/tickets/search${lastSearch}`}
      />
      <ShowImage
        isOpen={isOpenImage}
        toggleModal={toggleImage}
        imageUrl={shownImage}
      />
      <Container className={s.breadcrumbs}>
        <Container full={false}>
          <section>
            <div className={s.item} onClick={toggleModal}>
              {t('search')}
            </div>
            <Link to="/tickets/seat-details/" className={s.item}>
              <i className={i.arrowBottom} />
              1.&nbsp;<span>{t('seat selection')}</span>
            </Link>
            <Link to="/tickets/passenger-details/" className={s.item}>
              <i className={i.arrowBottom} />
              2.&nbsp;<span>{t('passengers details')}</span>
            </Link>
            {hasTransfers && (
              <div className={cn(s.item, s.active)}>
                <i className={i.arrowBottom} />
                3.&nbsp;<span>{t('taxi transfer')}</span>
              </div>
            )}
            <div className={s.item}>
              <i className={i.arrowBottom} />
              {hasTransfers ? '4' : '3'}.&nbsp;
              <span>{t('payment details')}</span>
            </div>
          </section>
        </Container>
      </Container>
      <Container className={s.content}>
        <Container full={false}>
          <section>
            <div className={s.transfers}>
              {transfersLoading ? (
                <Loading />
              ) : outboundTransfers.length ? (
                <div className={s.transfersBlock}>
                  <h3>
                    <span>{t('taxi transfer')}</span>: {t('outbound trip')}
                  </h3>
                  <div>
                    <Title
                      handleRequestTransfer={handleRequestTransfer}
                      selected={isOutbound}
                      outbound
                      selectedTransfer={selectedOutboundTransfer}
                      t={t}
                    />
                  </div>

                  {/* <div
                    className={cn(s.mainInfo, isOutbound && s.selected)}
                    onClick={() => handleRequestTransfer()}
                  >
                    <div className={s.check} />
                    <div className={s.text}>
                      {`${t('request transfer')}: `}
                      <strong>
                        {localizedDate(
                          new Date(selectedOutboundTransfer.TransferTime),
                          'dd LLLL'
                        )}
                        {` ${selectedOutboundTransfer.BusStopName}`}
                      </strong>
                    </div>
                  </div> */}
                  <Collapse isOpened={isOutbound}>
                    <div className={s.mainPassenger}>
                      {isOutboundOther ? (
                        <>
                          <div className={cn(s.row, s.isOther)}>
                            <div className={s.field}>
                              <Select
                                label={t('contact person')}
                                withoutMessage
                                value="other"
                                className={s.select}
                                onChange={e => {
                                  if (e.target.value === 'other') {
                                    selectOtherPerson('outboundTransfer');
                                  } else {
                                    selectPerson(
                                      e.target.value,
                                      'outboundTransfer'
                                    );
                                  }
                                }}
                              >
                                <SelectOption value="other" text={t('other')} />
                                {persons.map(item => {
                                  return (
                                    <SelectOption
                                      value={item.PhoneNumber}
                                      text={`${item.PassengerFirstName} ${item.PassengerFirstName}`}
                                    />
                                  );
                                })}
                              </Select>
                            </div>
                            <div className={s.field}>
                              <Notice
                                className={s.notice}
                                text={t(
                                  'Please enter contact person name and phone number below'
                                )}
                              />
                            </div>
                          </div>
                          <Formik
                            validateOnMount
                            enableReinitialize
                            initialValues={{
                              passengerName: '',
                              phoneNumber: '',
                            }}
                            validationSchema={Yup.object().shape({
                              passengerName: Yup.string().required(
                                t('validation.error.require', {
                                  field: t('email'),
                                })
                              ),
                              phoneNumber: Yup.string()
                                .max(
                                  20,
                                  t('validation.error.max', {
                                    field: t('phone number'),
                                    max: 20,
                                  })
                                )
                                .min(
                                  10,
                                  t('validation.error.min', {
                                    field: t('phone number'),
                                    min: 10,
                                  })
                                )
                                .required(
                                  t('validation.error.require', {
                                    field: t('phone number'),
                                  })
                                ),
                            })}
                          >
                            {props => {
                              const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                              } = props;
                              useEffect(() => {
                                setOutboundErrors(!!Object.keys(errors).length);
                              }, [Object.keys(errors).length]);

                              return (
                                <form className={cn(s.row, s.isOther)}>
                                  <div className={s.field}>
                                    <TicketsInput
                                      autoFocus
                                      name="passengerName"
                                      label={t('contact person name')}
                                      type="text"
                                      required
                                      value={values.passengerName}
                                      onChange={e => {
                                        dispatch({
                                          type: 'UPDATE_TRANSFERS',
                                          payload: {
                                            outboundTransfer: {
                                              ...outboundTransfer,
                                              PassengerName: e.target.value,
                                            },
                                          },
                                        });
                                        handleChange(e);
                                      }}
                                      onBlur={handleBlur}
                                      errors={
                                        touched.passengerName &&
                                        errors.passengerName
                                      }
                                    />
                                  </div>
                                  <div className={s.field}>
                                    <PhoneInput
                                      autoFocus
                                      ref={phoneNumberInput}
                                      inputClassName={s.phone}
                                      name="phoneNumber"
                                      label={t('contact phone number')}
                                      placeholder={t('enter phone number')}
                                      value={values.phoneNumber}
                                      required
                                      onChange={e => {
                                        dispatch({
                                          type: 'UPDATE_TRANSFERS',
                                          payload: {
                                            outboundTransfer: {
                                              ...outboundTransfer,
                                              PassengerPhone: e || '',
                                            },
                                          },
                                        });
                                        setFieldValue('phoneNumber', e);
                                      }}
                                      onBlur={handleBlur}
                                      errors={
                                        touched.phoneNumber &&
                                        errors.phoneNumber
                                      }
                                    />
                                  </div>
                                </form>
                              );
                            }}
                          </Formik>
                        </>
                      ) : (
                        <div className={s.row}>
                          <div className={s.field}>
                            <Select
                              label={t('contact person')}
                              withoutMessage
                              className={s.select}
                              value={outboundTransfer.PassengerName}
                              onChange={e => {
                                if (e.target.value === 'other') {
                                  selectOtherPerson('outboundTransfer');
                                } else {
                                  selectPerson(
                                    e.target.value,
                                    'outboundTransfer'
                                  );
                                }
                              }}
                            >
                              {persons.map(item => {
                                return (
                                  <SelectOption
                                    value={item.PhoneNumber}
                                    text={`${item.PassengerFirstName} ${item.PassengerLastName}`}
                                  />
                                );
                              })}
                              <SelectOption value="other" text={t('other')} />
                            </Select>
                          </div>
                          <Formik
                            validateOnMount
                            enableReinitialize
                            initialValues={{
                              phoneNumber: outboundTransfer.PassengerPhone,
                            }}
                            validationSchema={Yup.object().shape({
                              phoneNumber: Yup.string()
                                .max(
                                  20,
                                  t('validation.error.max', {
                                    field: t('phone number'),
                                    max: 20,
                                  })
                                )
                                .min(
                                  10,
                                  t('validation.error.min', {
                                    field: t('phone number'),
                                    min: 10,
                                  })
                                )
                                .required(
                                  t('validation.error.require', {
                                    field: t('phone number'),
                                  })
                                ),
                            })}
                          >
                            {props => {
                              const {
                                values,
                                touched,
                                errors,
                                handleBlur,
                                setFieldValue,
                              } = props;

                              useEffect(() => {
                                setOutboundErrors(!!Object.keys(errors).length);
                              }, [Object.keys(errors).length]);
                              return (
                                <form className={s.field}>
                                  <PhoneInput
                                    autoFocus
                                    ref={phoneNumberInput}
                                    inputClassName={s.phone}
                                    name="phoneNumber"
                                    label={t('contact phone number')}
                                    placeholder={t('enter phone number')}
                                    value={values.phoneNumber}
                                    required
                                    onChange={e => {
                                      dispatch({
                                        type: 'UPDATE_TRANSFERS',
                                        payload: {
                                          outboundTransfer: {
                                            ...outboundTransfer,
                                            PassengerPhone: e || '',
                                          },
                                        },
                                      });
                                      setFieldValue('phoneNumber', e);
                                    }}
                                    onBlur={handleBlur}
                                    errors={
                                      touched.phoneNumber && errors.phoneNumber
                                    }
                                  />
                                </form>
                              );
                            }}
                          </Formik>
                        </div>
                      )}
                    </div>
                    <div className={s.transferInfo}>
                      <div className={s.block}>
                        <div className={s.dropdownWrap}>
                          <div className={s.label}>{t('choose zone')}</div>
                          <CustomDropdown
                            t={t}
                            symbol={symbol}
                            isOutbound
                            onChangeZone={onChangeZone}
                            transfers={outboundTransfers}
                            transfer={outboundTransfer}
                            selectedTransferId={outboundTransfer.TransferId}
                          />
                        </div>
                        <Notice
                          className={s.notice}
                          html={outboundInformation.ServiceDescription}
                        />
                      </div>
                      <div className={s.block}>
                        <div className={s.map}>
                          <img
                            onClick={() =>
                              setImage(outboundInformation.TransferAreaMap)
                            }
                            src={outboundInformation.TransferAreaMap}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className={s.fields}>
                      <div className={s.countOfPassengers}>
                        <div className={s.counter}>
                          <label htmlFor="">{t('transfer cars count')}</label>
                          <div className={s.controls}>
                            <div
                              className={cn(
                                s.minus,
                                outboundTransfer.Quantity <= 1 && s.disabled
                              )}
                              onClick={() =>
                                outboundTransfer.Quantity > 1
                                  ? updateCount('minus', 'outboundTransfer')
                                  : null
                              }
                            >
                              —
                            </div>
                            <div className={s.count}>
                              {outboundTransfer.Quantity}
                            </div>
                            <div
                              className={s.plus}
                              onClick={() =>
                                updateCount('plus', 'outboundTransfer')
                              }
                            >
                              {`+ ${t('add one more transfer')}`}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <Notice
                        className={s.notice}
                        text={t('one taxi can carry up to 4 passengers')}
                      /> */}
                    </div>
                  </Collapse>
                </div>
              ) : null}
              {transfersLoading ? null : inboundTransfers.length ? (
                <div className={s.transfersBlock}>
                  <h3>
                    <span>{t('taxi transfer')}</span>: {t('inbound trip')}
                  </h3>
                  <Title
                    handleRequestTransfer={handleRequestTransfer}
                    selected={isInbound}
                    outbound={false}
                    selectedTransfer={selectedInboundTransfer}
                    t={t}
                  />
                  {/* <div
                    className={cn(s.mainInfo, isInbound && s.selected)}
                    onClick={() => handleRequestTransfer(false)}
                  >
                    <div className={s.check} />
                    <div className={s.text}>
                      {`${t('request transfer')}: `}
                      <strong>
                        {localizedDate(
                          new Date(selectedInboundTransfer.TransferTime),
                          'dd LLLL'
                        )}
                        {` ${selectedInboundTransfer.BusStopName}`}
                      </strong>
                    </div>
                  </div> */}
                  <Collapse isOpened={isInbound}>
                    <div className={s.mainPassenger}>
                      {isInboundOther ? (
                        <>
                          <div className={cn(s.row, s.isOther)}>
                            <div className={s.field}>
                              <Select
                                label={t('contact person')}
                                withoutMessage
                                className={s.select}
                                value="other"
                                onChange={e => {
                                  if (e.target.value === 'other') {
                                    selectOtherPerson('inboundTransfer');
                                  } else {
                                    selectPerson(
                                      e.target.value,
                                      'inboundTransfer'
                                    );
                                  }
                                }}
                              >
                                <SelectOption value="other" text={t('other')} />
                                {persons.map(item => {
                                  return (
                                    <SelectOption
                                      value={item.PhoneNumber}
                                      text={`${item.PassengerFirstName} ${item.PassengerLastName}`}
                                    />
                                  );
                                })}
                              </Select>
                            </div>
                            <div className={s.field}>
                              <Notice
                                className={s.notice}
                                text={t(
                                  'please enter contact person name and phone number below'
                                )}
                              />
                            </div>
                          </div>
                          <Formik
                            validateOnMount
                            enableReinitialize
                            initialValues={{
                              passengerName: '',
                              phoneNumber: '',
                            }}
                            validationSchema={Yup.object().shape({
                              passengerName: Yup.string().required(
                                t('validation.error.require', {
                                  field: t('email'),
                                })
                              ),
                              phoneNumber: Yup.string()
                                .max(
                                  20,
                                  t('validation.error.max', {
                                    field: t('phone number'),
                                    max: 20,
                                  })
                                )
                                .min(
                                  10,
                                  t('validation.error.min', {
                                    field: t('phone number'),
                                    min: 10,
                                  })
                                )
                                .required(
                                  t('validation.error.require', {
                                    field: t('phone number'),
                                  })
                                ),
                            })}
                          >
                            {props => {
                              const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                              } = props;
                              useEffect(() => {
                                setInboundErrors(!!Object.keys(errors).length);
                              }, [Object.keys(errors).length]);

                              return (
                                <form className={cn(s.row, s.isOther)}>
                                  <div className={s.field}>
                                    <TicketsInput
                                      autoFocus
                                      name="passengerName"
                                      label={t('contact person name')}
                                      type="text"
                                      required
                                      value={values.passengerName}
                                      onChange={e => {
                                        dispatch({
                                          type: 'UPDATE_TRANSFERS',
                                          payload: {
                                            inboundTransfer: {
                                              ...inboundTransfer,
                                              PassengerName: e.target.value,
                                            },
                                          },
                                        });
                                        handleChange(e);
                                      }}
                                      onBlur={handleBlur}
                                      errors={
                                        touched.passengerName &&
                                        errors.passengerName
                                      }
                                    />
                                  </div>
                                  <div className={s.field}>
                                    <PhoneInput
                                      autoFocus
                                      ref={phoneNumberInput}
                                      inputClassName={s.phone}
                                      name="phoneNumber"
                                      label={t('contact phone number')}
                                      placeholder={t('enter phone number')}
                                      value={values.phoneNumber}
                                      required
                                      onChange={e => {
                                        dispatch({
                                          type: 'UPDATE_TRANSFERS',
                                          payload: {
                                            inboundTransfer: {
                                              ...inboundTransfer,
                                              PassengerPhone: e || '',
                                            },
                                          },
                                        });
                                        setFieldValue('phoneNumber', e);
                                      }}
                                      onBlur={handleBlur}
                                      errors={
                                        touched.phoneNumber &&
                                        errors.phoneNumber
                                      }
                                    />
                                  </div>
                                </form>
                              );
                            }}
                          </Formik>
                        </>
                      ) : (
                        <div className={s.row}>
                          <div className={s.field}>
                            <Select
                              label={t('contact person')}
                              withoutMessage
                              className={s.select}
                              value={inboundTransfer.PassengerName}
                              onChange={e => {
                                if (e.target.value === 'other') {
                                  selectOtherPerson('inboundTransfer');
                                } else {
                                  selectPerson(
                                    e.target.value,
                                    'inboundTransfer'
                                  );
                                }
                              }}
                            >
                              {persons.map(item => {
                                return (
                                  <SelectOption
                                    value={item.PhoneNumber}
                                    text={`${item.PassengerFirstName} ${item.PassengerLastName}`}
                                  />
                                );
                              })}
                              <SelectOption value="other" text={t('other')} />
                            </Select>
                          </div>
                          <Formik
                            validateOnMount
                            enableReinitialize
                            initialValues={{
                              phoneNumber: inboundTransfer.PassengerPhone,
                            }}
                            validationSchema={Yup.object().shape({
                              phoneNumber: Yup.string()
                                .max(
                                  20,
                                  t('validation.error.max', {
                                    field: t('phone number'),
                                    max: 20,
                                  })
                                )
                                .min(
                                  10,
                                  t('validation.error.min', {
                                    field: t('phone number'),
                                    min: 10,
                                  })
                                )
                                .required(
                                  t('validation.error.require', {
                                    field: t('phone number'),
                                  })
                                ),
                            })}
                          >
                            {props => {
                              const {
                                values,
                                touched,
                                errors,
                                handleBlur,
                                setFieldValue,
                              } = props;

                              useEffect(() => {
                                setInboundErrors(!!Object.keys(errors).length);
                              }, [Object.keys(errors).length]);
                              return (
                                <form className={s.field}>
                                  <PhoneInput
                                    autoFocus
                                    ref={phoneNumberInput}
                                    inputClassName={s.phone}
                                    name="phoneNumber"
                                    label={t('contact phone number')}
                                    placeholder={t('enter phone number')}
                                    value={values.phoneNumber}
                                    required
                                    onChange={e => {
                                      dispatch({
                                        type: 'UPDATE_TRANSFERS',
                                        payload: {
                                          inboundTransfer: {
                                            ...inboundTransfer,
                                            PassengerPhone: e || '',
                                          },
                                        },
                                      });
                                      setFieldValue('phoneNumber', e);
                                    }}
                                    onBlur={handleBlur}
                                    errors={
                                      touched.phoneNumber && errors.phoneNumber
                                    }
                                  />
                                </form>
                              );
                            }}
                          </Formik>
                        </div>
                      )}
                    </div>
                    <div className={s.transferInfo}>
                      <div className={s.block}>
                        <div className={s.dropdownWrap}>
                          <div className={s.label}>{t('choose zone')}</div>
                          <CustomDropdown
                            t={t}
                            symbol={symbol}
                            isOutbound={false}
                            onChangeZone={onChangeZone}
                            transfers={inboundTransfers}
                            transfer={inboundTransfer}
                            selectedTransferId={inboundTransfer.TransferId}
                          />
                        </div>
                        <Notice
                          className={s.notice}
                          html={inboundInformation.ServiceDescription}
                        />
                      </div>
                      <div className={s.block}>
                        <div className={s.map}>
                          <img
                            onClick={() =>
                              setImage(inboundInformation.TransferAreaMap)
                            }
                            src={inboundInformation.TransferAreaMap}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className={s.fields}>
                      <div className={s.countOfPassengers}>
                        <div className={s.counter}>
                          <label htmlFor="">{t('transfer cars count')}</label>
                          <div className={s.controls}>
                            <div
                              className={cn(
                                s.minus,
                                inboundTransfer.Quantity <= 1 && s.disabled
                              )}
                              onClick={() =>
                                inboundTransfer.Quantity > 1
                                  ? updateCount('minus', 'inboundTransfer')
                                  : null
                              }
                            >
                              —
                            </div>
                            <div className={s.count}>
                              {inboundTransfer.Quantity}
                            </div>
                            <div
                              className={s.plus}
                              onClick={() =>
                                updateCount('plus', 'inboundTransfer')
                              }
                            >
                              {`+ ${t('add one more transfer')}`}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <Notice
                        className={s.notice}
                        text={t('one taxi can carry up to 4 passengers')}
                      /> */}
                    </div>
                  </Collapse>
                </div>
              ) : null}
              {/* {transfersLoading
                ? null
                : (outboundTransfers.length || inboundTransfers.length) && (
                    <div
                      className={cn(s.transfersBlock, s.transfersBlockNotice)}
                    >
                      <div>
                        <Notice text={t('available.transfers.notification')} />
                      </div>
                    </div>
                  )} */}
            </div>
            {isTabDown ? (
              <TripDetails
                nextStep="payment"
                onContinueClick={handleContinue}
                totalTransferPrice={totalTransferPrice}
                page="transfer"
                disabledNextStep={
                  addTransferLoading ||
                  loadingTransferInformation ||
                  transfersLoading ||
                  basketLoading ||
                  removeTransfersLoading ||
                  (isOutbound && isOutboundErrors) ||
                  (isInbound && isInboundErrors)
                }
                loading={
                  addTransferLoading ||
                  loadingTransferInformation ||
                  transfersLoading ||
                  basketLoading ||
                  removeTransfersLoading
                }
              />
            ) : (
              <div className={s.tripDetails}>
                <TripDetails
                  nextStep="payment"
                  onContinueClick={handleContinue}
                  totalTransferPrice={totalTransferPrice}
                  page="transfer"
                  disabledNextStep={
                    addTransferLoading ||
                    loadingTransferInformation ||
                    transfersLoading ||
                    basketLoading ||
                    removeTransfersLoading ||
                    (isOutbound && isOutboundErrors) ||
                    (isInbound && isInboundErrors)
                  }
                  loading={
                    addTransferLoading ||
                    loadingTransferInformation ||
                    transfersLoading ||
                    basketLoading ||
                    removeTransfersLoading
                  }
                />
              </div>
            )}
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withRouter(withTranslation()(memo(Transfer)));
