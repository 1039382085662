import React, { useEffect, cloneElement, useReducer, useContext } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { ControlPanel } from '@lux/spa/components';
import { Mutation } from 'react-apollo';
import { useMutation } from '@apollo/react-hooks';
import {
  REMOVE_ROUTE,
  REMOVE_PROMO,
  REMOVE_DESTINATION,
  REMOVE_NEWS,
} from '@lux/spa/apollo/mutations';
import { StoreContext } from '@lux/spa/store';
import { Container, Switch, Loading, Link, Button } from '@lux/ui';
import i18n from '@lux/spa/i18n';
import { DEFAULT_LANG } from '@lux/spa/config';
import i from '@lux/spa/styles/icons.module.scss';
import s from './edit-page.module.scss';

const pageReducer = (state, action) => {
  const { lang } = state;

  switch (action.type) {
    case 'UPDATE':
      return {
        ...state,
        ...action.payload,
      };

    case 'UPDATE_PAGE':
      return {
        ...state,
        page: {
          ...state.page,
          ...action.payload,
        },
      };

    case 'UPDATE_STOPS':
      return {
        ...state,
        page: {
          ...state.page,
          [lang]: {
            ...state.page[lang],
            pageData: {
              ...state.page[lang].pageData,
              stops: action.payload.data,
            },
          },
        },
      };

    case 'UPDATE_LANG':
      return {
        ...state,
        page: {
          ...state.page,
          [lang]: {
            ...(state.page?.[lang]
              ? {
                  ...state.page[lang],
                }
              : {}),
            ...action.payload,
            lang,
          },
        },
      };

    case 'UPDATE_SEO':
      return {
        ...state,
        page: {
          ...state.page,
          [lang]: {
            ...state.page[lang],
            seo: {
              ...state.page[lang].seo,
              ...action.payload,
            },
          },
        },
      };

    case 'UPDATE_DATA':
      return {
        ...state,
        page: {
          ...state.page,
          [lang]: {
            ...state.page[lang],
            pageData: {
              ...state.page[lang]?.pageData,
              ...action.payload.field,
            },
          },
        },
      };

    case 'UPDATE_COUNTRIES':
      const { countries } = state.page[lang];
      const { country } = action.payload;

      return {
        ...state,
        page: {
          ...state.page,
          [lang]: {
            ...state.page[lang],
            ...(countries.includes(country)
              ? { countries: [...countries.filter(e => e !== country)] }
              : { countries: [...countries, country] }),
          },
        },
      };

    default: {
      return state;
    }
  }
};

const initialPage = {
  lang: i18n.language,
  isCustomSlug: false,
  UPDATE_PAGE: null,
  CREATE_PAGE: null,
  defaultData: null,
  emptyData: null,
  loading: false,
  copyFrom: 'empty',
  page: undefined,
  hasError: false,
  errors: [],
  hasUpdates: false,
  originalPage: null,
};

const EditPage = ({
  t,
  i18n,
  children,
  region,
  multi,
  pageName,
  isStatic,
  history,
}) => {
  const [mainState, mainDispatch] = useContext(StoreContext);
  const { language } = i18n;

  const { app } = mainState;

  const { isEditMode, isShowSeo, copyFrom, activeLang } = app;

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state = {}, dispatch] = useReducer(pageReducer, initialPage);

  const {
    UPDATE_PAGE,
    CREATE_PAGE,
    page,
    lang,
    hasError,
    hasUpdates,
    originalPage,
  } = state;

  const originSlug = originalPage?.[lang]?.slug;

  const [removeNews] = useMutation(REMOVE_NEWS, {
    onError: () => {
      toast.error(t('something went wrong'));
    },
    onCompleted: data => {
      if (data.deleteNews.success) {
        toast.success(t('the page has been successfully deleted'));
        history.push(`/${language}/blog/`);
      } else {
        toast.error(t('something went wrong'));
      }
    },
  });

  const [removePromo] = useMutation(REMOVE_PROMO, {
    onError: () => {
      toast.error(t('something went wrong'));
    },
    onCompleted: data => {
      if (data.deletePromoPage.success) {
        toast.success(t('the page has been successfully deleted'));
        history.push(`/${language}/`);
      } else {
        toast.error(t('something went wrong'));
      }
    },
  });

  const [removeRoute] = useMutation(REMOVE_ROUTE, {
    onError: () => {
      toast.error(t('something went wrong'));
    },
    onCompleted: data => {
      if (data.deleteRoute.success) {
        toast.success(t('the page has been successfully deleted'));
        history.push(`/${language}/routes/`);
      } else {
        toast.error(t('something went wrong'));
      }
    },
  });

  const [removeDestination] = useMutation(REMOVE_DESTINATION, {
    onError: () => {
      toast.error(t('something went wrong'));
    },
    onCompleted: data => {
      if (data.deleteDestination.success) {
        toast.success(t('the page has been successfully deleted'));
        history.push(`/${language}/destinations/`);
      } else {
        toast.error(t('something went wrong'));
      }
    },
  });

  const toogleEtidMode = () => {
    mainDispatch({
      type: 'UPDATE_APP',
      payload: {
        isEditMode: !isEditMode,
      },
    });
  };

  const toogleSeo = () => {
    mainDispatch({
      type: 'UPDATE_APP',
      payload: {
        isShowSeo: !isShowSeo,
      },
    });
  };

  const toggleIsPublished = () => {
    dispatch({
      type: 'UPDATE_PAGE',
      payload: {
        isPublished: !page.isPublished,
      },
    });
  };

  const updatePage = () => {
    dispatch({
      type: 'UPDATE',
      payload: {
        page: originalPage,
      },
    });
  };

  const cancelChanges = () => {
    dispatch({
      type: 'UPDATE',
      payload: {
        page: originalPage,
      },
    });
  };

  const removePage = () => {
    const { id } = state.page;

    switch (pageName) {
      case 'route':
        removeRoute({
          variables: {
            id: +id,
          },
        });
        break;
      case 'destination':
        removeDestination({
          variables: {
            id: +id,
          },
        });
        break;
      case 'news':
        removeNews({
          variables: {
            id: +id,
          },
        });
        break;
      case 'promo':
        removePromo({
          variables: {
            id: +id,
          },
        });
        break;
      default:
    }
  };

  useEffect(() => {
    if (page && originalPage) {
      const originalPageSorted = Object.entries(originalPage)
        .sort((a, b) => {
          if (a[0] < b[0]) return -1;
          if (a[0] > b[0]) return 1;
          return 0;
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );

      const pageSorted = Object.entries(page)
        .sort((a, b) => {
          if (a[0] < b[0]) return -1;
          if (a[0] > b[0]) return 1;
          return 0;
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );

      const hasUpdates =
        JSON.stringify(originalPageSorted) !== JSON.stringify(pageSorted);

      dispatch({
        type: 'UPDATE',
        payload: {
          hasUpdates,
        },
      });
    }
  }, [page, originalPage]);

  useEffect(() => {
    if (i18n.language !== lang) {
      dispatch({
        type: 'UPDATE',
        payload: {
          lang: i18n.language,
        },
      });
    }
  }, [i18n.language]);

  return (
    <div className={s.editPage}>
      <Container>
        <Container full={false}>
          {/* {isShowSeo && */}
          {page && (
            <ControlPanel
              loading={state.loading}
              className={cn(s.controlPanel, isEditMode && isShowSeo && s.show)}
              originSlug={originSlug}
              lang={lang}
              state={state}
              pageName={pageName}
              dispatch={dispatch}
              multi={multi}
              region={region}
              isStatic={isStatic}
            />
          )}
          <div className={s.mainControl}>
            <div className={cn(s.item, s.create)}>
              <i className={i.plus_v2} />
              <span>{t('add page')}</span>
              <div className={s.wrapList}>
                <div className={s.list}>
                  <div className={s.wrap}>
                    <div className={s.triangle} />
                  </div>
                  <Link
                    className={s.link}
                    target="__blank"
                    to="/create-destination/"
                  >
                    <span>{t('destination')}</span>
                  </Link>
                  <Link className={s.link} target="__blank" to="/create-route/">
                    <span>{t('route')}</span>
                  </Link>
                  <Link className={s.link} target="__blank" to="/create-news/">
                    <span>{t('news')}</span>
                  </Link>
                  <Link className={s.link} target="__blank" to="/create-promo/">
                    <span>{t('promo')}</span>
                  </Link>
                </div>
              </div>
            </div>
            {isEditMode && (
              <>
                <div className={s.item}>
                  <span>{t('published')}:</span>
                  <Switch
                    onChange={toggleIsPublished}
                    checked={page?.isPublished}
                  />
                </div>
                <div className={s.item}>
                  <span>{t('show seo')}:</span>
                  <Switch onChange={toogleSeo} checked={isShowSeo} />
                </div>
                {multi && CREATE_PAGE && (
                  <Mutation
                    mutation={CREATE_PAGE}
                    variables={{
                      input: {
                        ...state.page,
                      },
                    }}
                  >
                    {(createPage, { loading, called, data }) => {
                      if (loading) return <Loading />;

                      if (called) {
                        const slug = state.page?.[lang]?.slug;
                        const langUrl = lang === DEFAULT_LANG ? '' : lang;

                        if (slug) {
                          const redirect = () => {
                            switch (pageName) {
                              case 'route':
                                return `/${langUrl}/routes/${slug}`;
                              case 'destination':
                                return `/${langUrl}/destinations/${slug}`;
                              case 'news':
                                return `/${langUrl}/news/${slug}`;
                              case 'promo':
                                return `/${langUrl}/promo/${slug}`;
                              default:
                            }
                          };

                          return <Redirect to={redirect()} />;
                        }
                      }
                      return (
                        <div className={s.item}>
                          <Button
                            text={t('create')}
                            disabled={hasError}
                            onClick={() => {
                              if (state.page.id) {
                                delete state.page.id;
                              }
                              createPage({
                                variables: {
                                  input: {
                                    ...state.page,
                                  },
                                },
                              });
                            }}
                          />
                        </div>
                      );
                    }}
                  </Mutation>
                )}
                {!multi && UPDATE_PAGE && (
                  <Mutation
                    mutation={UPDATE_PAGE}
                    variables={{
                      input: {
                        ...state.page,
                      },
                    }}
                  >
                    {(updatePage, { data, loading, error, called }) => {
                      if (loading) return <Loading />;

                      return (
                        <>
                          <div className={cn(s.item, s.update)}>
                            <Button
                              className={s.button}
                              disabled={!hasUpdates || hasError}
                              text={t('update')}
                              onClick={() => {
                                updatePage().then(data => {
                                  const pageData =
                                    data?.data?.updateStaticPage?.staticPage ||
                                    data?.data?.updateRoute?.route ||
                                    data?.data?.updateDestination
                                      ?.destination ||
                                    data?.data?.updateNews?.news;
                                  // add promo page check structure and variables name

                                  if (pageData) {
                                    dispatch({
                                      type: 'UPDATE',
                                      payload: {
                                        originalPage: {
                                          ...page,
                                          ...pageData,
                                        },
                                      },
                                    });
                                  }
                                });
                              }}
                            />
                          </div>

                          <div className={cn(s.item, s.cancel)}>
                            <Button
                              className={s.button}
                              disabled={!hasUpdates}
                              text={t('cancel')}
                              onClick={cancelChanges}
                            />
                          </div>
                        </>
                      );
                    }}
                  </Mutation>
                )}
                {!(isStatic || CREATE_PAGE) && (
                  <div className={cn(s.item, s.cancel)}>
                    <Button
                      className={s.button}
                      text={t('remove')}
                      onClick={removePage}
                    />
                  </div>
                )}
              </>
            )}
            <div className={s.item}>
              <span>{t('edit mode')}:</span>
              <Switch onChange={toogleEtidMode} checked={isEditMode} />
            </div>
          </div>
        </Container>
      </Container>
      {cloneElement(children, {
        isEditMode,
        lang: lang || i18n.language,
        page,
        dispatch,
        region,
      })}
    </div>
  );
};

export default withRouter(withTranslation()(EditPage));
