import React from 'react';
import { withTranslation } from 'react-i18next';
import i from '@lux/spa/styles/icons.module.scss';
import s from './edit-order-widget.module.scss';

const EditOrderWidgetItem = ({
  t,
  dragHandleProps,
  showField,
  showSecondField,
  item,
  toggleEditSlide,
  removeSlide,
  itemFef,
  ...props
}) => {
  return (
    <div className={s.item} {...props} ref={itemFef}>
      <div className={s.dots} {...dragHandleProps}>
        <div className={s.dot} />
        <div className={s.dot} />
        <div className={s.dot} />
        <div className={s.dot} />
        <div className={s.dot} />
      </div>
      <div className={s.title}>
        {(showField && t(item?.[showField]?.name)) || item.id}
        {showSecondField && ` - ${t(item?.[showSecondField]?.name)}`}
      </div>
      <div className={s.panel}>
        <div
          className={s.control}
          onClick={() =>
            toggleEditSlide({
              ...item,
              isNew: false,
            })
          }
        >
          <i className={i.edit} />
        </div>
        <div className={s.control} onClick={() => removeSlide(item.id)}>
          <i className={i.basket} />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(EditOrderWidgetItem);
