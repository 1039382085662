import React, { useState } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import DayPicker from 'react-day-picker';
import { localizedDate } from '@lux/spa/utils';
import dateFormat from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import addMonths from 'date-fns/addMonths';
import getMonth from 'date-fns/getMonth';

import { Tooltip, Controls } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './calendar.module.scss';

const prices = [
  // {
  //   isRed: true,
  //   date: new Date(2020, 4, 19),
  //   price: '20 USD',
  //   text: 'Lorem ipsum dolor sit amet.'
  // },
  // {
  //   isRed: false,
  //   date: new Date(2020, 4, 22),
  //   price: '20 USD',
  //   text: 'Lorem ipsum dolor sit amet.'
  // },
  // {
  //   isRed: true,
  //   date: new Date(2020, 4, 27),
  //   price: '15 EUR',
  //   text: 'Lorem ipsum dolor sit amet.'
  // },
  // {
  //   isRed: true,
  //   date: new Date(2020, 4, 30),
  //   price: '12 UAH'
  // },
  // {
  //   isRed: true,
  //   date: new Date(2020, 4, 31),
  //   price: '10 USD',
  //   text: 'Lorem ipsum dolor sit amet.'
  // },
];

const Calendar = ({ t, i18n, className, date, setDate, prices = [] }) => {
  const { language } = i18n;

  const [selectedDays, setSelectedDays] = useState(date);

  const handleDayClick = (day, modifiers = {}) => {
    // setDate(modifiers.selected ? undefined : day)
    // if (modifiers.disabled) {
    //   setDate(day)
    // }
    if (!modifiers.disabled) {
      setDate(day);

      setSelectedDays(day);
    }
  };

  const Weekday = ({ date, weekday, className, localeUtils, locale }) => {
    const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
    return (
      <div className={cn(className)} title={weekdayName}>
        {t(weekdayName.toLowerCase()).slice(0, 3)}
      </div>
    );
  };

  const Navbar = ({
    month,
    nextMonth,
    previousMonth,
    onPreviousClick,
    onNextClick,
    className,
    localeUtils,
    ...props
  }) => {
    const currentMonth = getMonth(new Date());
    const selectedMonth = getMonth(month);
    // const months = localeUtils.getMonths();
    // const prev = months[previousMonth.getMonth()];
    // const next = months[nextMonth.getMonth()];

    return (
      <div className={cn(className, s.navBar)}>
        <h3 className={s.title}>
          {`${t(localizedDate(month, 'MMMM yyyy').toLowerCase())}`}
        </h3>
        <Controls
          handlePrev={() => onPreviousClick()}
          handleNext={() => onNextClick()}
          className={s.controls}
          current={selectedMonth + 1 - currentMonth}
          count={3}
        />
      </div>
    );
  };

  const Day = (day, modifiers) => {
    const data = day => {
      if (!prices.length) return false;

      const data = prices.filter(d => isSameDay(d.date, day));

      return data;
    };

    return (
      <div className={s.day} {...modifiers}>
        <div className={s.date}>{dateFormat(day, 'dd')}</div>
        {!!data(day).length && (
          <div className={cn(s.info, data(day)[0].isRed && s.red)}>
            <h5 className={s.price}>{data(day)[0].price}</h5>
            {data(day)[0].text && (
              <Tooltip>
                <p>{data(day)[0].text}</p>
              </Tooltip>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <DayPicker
      // classNames={{
      //   container: s.calendar,
      //   weekdaysRow: s.weekdaysRow
      // }}
      locale={language}
      className={cn(s.calendar, className)}
      renderDay={Day}
      navbarElement={Navbar}
      weekdayElement={<Weekday date={date} />}
      showOutsideDays
      firstDayOfWeek={1}
      disabledDays={[
        {
          before: new Date(),
          after: addMonths(date, 3),
        },
      ]}
      // onDayClick={setDate}
      onDayClick={handleDayClick}
      selectedDays={selectedDays}
    />
  );
};

export default withTranslation()(Calendar);
