import React, { useState } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';

import { Button, UploadImages } from '@lux/ui';
import s from './news-fields.module.scss';

const NewsFields = ({
  t,
  updateSeo,
  state: pageState,
  dispatch: pageDisaptch,
}) => {
  const [isThumbnail, setIsThumbnail] = useState(false);

  const toggleThumbnail = () => {
    setIsThumbnail(!isThumbnail);
  };

  const { page, lang } = pageState;

  const { thumbnail } = page[lang].seo;

  return (
    <div className={s.newsFields}>
      <UploadImages
        isOpen={isThumbnail}
        toggleModal={toggleThumbnail}
        updatePage={updateSeo}
        image={thumbnail}
      />
      <div className={s.title}>{t('thumbnail')}</div>
      <div
        className={cn(s.thumbnail, thumbnail.value && s.load)}
        style={{
          backgroundImage: `url(${thumbnail.value})`,
        }}
      >
        <Button text={t('upload thumbnail')} onClick={toggleThumbnail} />
      </div>
    </div>
  );
};

export default withTranslation()(NewsFields);
