// import { CounterMutations } from './counter';

export const Mutation = {};
// export { UPDATE_COUNTER } from './counter';
export {
  MODIFICATION_SEARCH,
  MODIFICATION_JOURNEY_CONFIRM,
  MODIFICATION_CHANGE_SEAT,
  MODIFICATION_NEO_PAY_PAYMENT,
  MODIFICATION_NEO_PAY_DETAILS,
  MODIFICATION_ADYEN_PAYMENT,
  MODIFICATION_ADYEN_PAYMENT_DETAILS,
  MODIFICATION_ZERO_PAYMENT,
  MODIFICATION_CORPORATE_CLIENT_PAY,
  MODIFICATION_CANCEL,
  MODIFICATION_SEAT_PLAN,
  MODIFICATION_VOUCHER_PAYMENT,
  MODIFICATION_VERIFY_VOUCHER,
  YOO_MONEY_MODIFICATION_PAYMENT,
  YOO_MONEY_MODIFICATION_PAYMENT_DETAILS,
} from './change-tickets';
export {
  CREATE_DESTINATION,
  REMOVE_DESTINATION,
  UPDATE_DESTINATION,
} from './destination';
export { CREATE_NEWS, UPDATE_NEWS, REMOVE_NEWS } from './news';
export { REMOVE_PAYMENT_METHOD } from './profile';
export { CREATE_PROMO, UPDATE_PROMO, REMOVE_PROMO } from './promo';
export { CREATE_ROUTE, UPDATE_ROUTE, REMOVE_ROUTE } from './route';
export { SEND_SMS } from './send-sms';
export {
  SIGN_UP,
  SIGN_IN,
  CHANGE_PASSWORD,
  RESET_PASSWORD,
  UPDATE_PROFILE,
  UPLOAD_AVATAR,
  VERIFY_EMAIL_CODE,
  SEND_EMAIL,
  FORGOT_PASSWORD,
} from './sign-up';
export {
  CREATE_STATIC_PAGE,
  UPDATE_STATIC_PAGE,
  REMOVE_STAIC_PAGE,
} from './static-page';
export {
  MODIFICATION_INFO,
  CALCULATE_RETURN,
  COMMIT_RETURN,
  SEND_VOUCHER_TO_EMAIL,
} from './refund';
export {
  ADD_JOURNEY_TO_BASKET,
  CHANGE_SEAT_NUMBER,
  GET_AVAILABLE_PRICE_FOR_PRODUCTS,
  UPDATE_PRODUCT_PRICE,
  UPDATE_BUYER_INFO,
  UPDATE_CUSTOMER_INFO,
  CORPORATE_CLIENT_PAY,
  ADYEN_PAYMENT,
  ADYEN_PAYMENT_DETAILS,
  RENEW_BASKET_EXPIRATION_TIME,
  DELETE_BASKET,
  SEND_TICKETS_TO_EMAIL,
  UPDATE_PASSENGERS_INFO,
  NEO_PAY_DETAILS,
  NEO_PAY_PAYMENT,
  ZERO_PAYMENT_PAY,
  BASKET_CONTENT,
  AVAILABLE_TRANSFERS,
  ZERO_PAYMENT,
  TRANSFER_INFORNATION,
  ADD_TRANSFER,
  DELETE_PRODUCT_FROM_BASKET,
  PASSEENGERS_DATA_FIELDS,
  DELETE_PRODUCTS_FROM_BASKET,
  BANK_LINK_PAYMENT,
  PAY_WITH_VOUCHER,
  GET_JOURNEY_ADDONS,
  ADD_ADDONS,
  YOO_MONEY_PAYMENT,
  YOO_MONEY_PAYMENT_DETAILS,
} from './tickets';
export { UPLOAD_IMAGES } from './upload-images';
export { VERIFY_CODE, PROFILE_EXISTS } from './verify-code';
export {
  CREATE_STATIC_WIDGET,
  UPDATE_STATIC_WIDGET,
  UPDATE_ORDER_WIDGET,
} from './widget';
