import React, { useContext } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { StoreContext } from '@lux/spa/store';
import s from './info.module.scss';

const Info = ({ t, dataRefund }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { refund } = state.tickets;
  const { shoppingBasketCode } = refund;
  return (
    <div className={s.info}>
      <div className={cn(s.decorativeСircle, s.top)}>
        <div className={s.circle} />
      </div>
      <div className={cn(s.decorativeСircle, s.bottom)}>
        <div className={s.circle} />
      </div>
      <div className={s.divider} />
      <div
        className={cn(s.item, s.bn)}
        data-title={t('booking number')}
        data-title-center
      >
        <span>{t('booking.number.short')}:</span>
        {shoppingBasketCode}
      </div>
      {dataRefund?.selectedPassengersCodes?.length ? (
        dataRefund.selectedPassengersCodes.map(item => (
          <div className={s.data}>
            <span>{t('passenger')}:</span>
            <div className={s.item}>{item.PassengerName}</div>
          </div>
        ))
      ) : (
        <div className={s.data}>
          <span>{t('passengers')}:</span>
          <div className={s.item}>- - -</div>
        </div>
      )}
      {/* {dataRefund?.selectedWay?.RefundingScope ? (
        <div className={s.data}>
          <span>{t('trip type')}:</span>
          <div className={s.item}>{dataRefund.selectedWay.RefundingScope}</div>
        </div>
      ) : (
        <div className={s.data}>
          <span>{t('trip type')}:</span>
          <div className={s.item}>- - -</div>
        </div>
      )} */}
    </div>
  );
};

export default withTranslation()(Info);
