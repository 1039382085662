import React from 'react';
import cn from 'classnames';

import s from './loading.module.scss';

const Loading = ({ className }) => {
  return (
    <div className={cn(s.loading, className)}>
      <div className={s.container}>
        <div className={cn(s.bubble, s.bubble1)} />
        <div className={cn(s.bubble, s.bubble2)} />
        <div className={cn(s.bubble, s.bubble3)} />
      </div>
    </div>
  );
};

export default Loading;
