import React from 'react';
import s from './trips.module.scss';

const Trips = () => {
  return (
    <div className={s.trips}>
      <div className={s.header}>header</div>
      <div>Trips</div>
    </div>
  );
};

export default Trips;
