import React, { cloneElement, Children } from 'react';
import cn from 'classnames';
import { Divider } from '@lux/ui';
import s from './slider.module.scss';

const SliderSlides = ({
  children,
  className,
  snapAlign = 'start',
  currentSlide,
  scrollerRef,
  withDivider,
  dividerProps,
}) => {
  const childrens = Children.toArray(children);
  return (
    <div className={s.wrap}>
      <div
        ref={scrollerRef}
        className={cn(s.slides, className)}
        data-current-slide={currentSlide}
      >
        {childrens.map(({ props, ...child }) =>
          cloneElement(
            { props, ...child },
            {
              style: {
                ...props.style,
                scrollSnapAlign: snapAlign,
              },
            }
          )
        )}
      </div>
      {withDivider && <Divider {...dividerProps} />}
    </div>
  );
};

export default SliderSlides;
