import React, { Fragment, useState } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Modal,
  ModalHeader,
  ModalContent,
  Tabs,
  TabsPanel,
  Tab,
  Button,
  TabContent,
  TabContainer,
  Input,
  Textarea,
} from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './position-details.module.scss';

const PositionDetails = ({ t, isOpen, toggleModal }) => {
  const [isShowForm, setIsShowForm] = useState(false);

  const toogleForm = () => {
    setIsShowForm(!isShowForm);
  };

  const handleApply = () => {
    toogleForm();
    toggleModal();
  };

  return (
    <Modal className={s.modal} {...{ isOpen, toggleModal }}>
      <ModalHeader
        text={
          isShowForm ? 'Apply from " Charter Bus Driver"' : 'Charter Bus Driver'
        }
      />
      <ModalContent>
        {isShowForm ? (
          <form action="">
            <div className={s.inputs}>
              <Input
                className={s.input}
                label={`${t('enter your name')}*`}
                required
              />
              <Input
                className={s.input}
                label={t('enter your email')}
                type="email"
                required
              />
              <Textarea className={s.textarea} label={t('message')} />
            </div>
            <div className={s.attachments}>
              <i className={i.clip} />
              {t('add atachment')}
            </div>
            <Button text={t('apply')} onClick={handleApply} />
          </form>
        ) : (
          <Tabs className={s.tabs}>
            <TabsPanel>
              <Tab text={t('requirements')} />
              <Tab text={t('responsibilities')} />
              <Tab text={t('additional skills')} />
            </TabsPanel>
            <TabContainer>
              {Array(3)
                .fill()
                .map((item, key) => (
                  <TabContent className={s.tabContent} key={key}>
                    <p>
                      At First Student, our Bus Drivers are an integral part of
                      the communities they serve. They are committed to safety,
                      customer service and have genuine, caring attitudes for
                      our customers. We are your friends, family, and neighbors!
                    </p>
                    <h5>Requirements of a School Bus Driver:</h5>
                    <div className={s.list}>
                      <div className={s.item}>
                        <div className={s.circle} />
                        Good verbal communication skills
                      </div>
                      <div className={s.item}>
                        <div className={s.circle} />
                        At least 21 years old
                      </div>
                      <div className={s.item}>
                        <div className={s.circle} />
                        Valid driver’s license for at least 3 years
                      </div>
                      <div className={s.item}>
                        <div className={s.circle} />
                        Be subject to a background check, drug screen & physical
                      </div>
                    </div>
                    <Button
                      onClick={toogleForm}
                      className={s.button}
                      text={t('apply for job')}
                    />
                  </TabContent>
                ))}
            </TabContainer>
          </Tabs>
        )}
      </ModalContent>
    </Modal>
  );
};

export default withTranslation()(PositionDetails);
