import React, { useContext, useRef, useEffect } from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router';
import compareAsc from 'date-fns/compareAsc';
import { withTranslation } from 'react-i18next';
import isValid from 'date-fns/isValid';
import addDays from 'date-fns/addDays';
import dateFormat from 'date-fns/format';
import { StoreContext } from '@lux/spa/store';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { DEFAULT_LANG } from '@lux/spa/config';
import {
  SearchInput,
  Button,
  DatePicker,
  SearchDestinationMobile,
  SearchPassengersMobile,
} from '@lux/ui';
import { localizedDate, getCookieByName } from 'utils';
import { BUS_STOPS, POPULAR_DESTINATIONS } from '@lux/spa/apollo/queries';
import { useLazyQuery } from '@apollo/react-hooks';
import i from '@lux/spa/styles/icons.module.scss';
import { Passengers, Suggestions } from './components';
import s from './search.module.scss';

const Search = ({
  t,
  i18n,
  className,
  location,
  history,
  match,
  ButtonSearch,
  inputsClassName,
}) => {
  const [state, dispatch] = useContext(StoreContext);
  const { search } = location;
  const { isMob } = useContext(WidthContext);
  const {
    promocode,
    departDate,
    returnDate,
    from,
    to,
    field,
    passengers,
    isOpenSugg,
    isOpenPass,
    isOpenSuggMob,
    isOpenPassMob,
    passengersCount,
    isSearchValid,
  } = state.search;
  // const { currencyName } = state.tickets;
  const currencyName = 'RUB';
  const { GTMLoaded } = state.app;
  const suggestionsRef = useRef(null);
  const passengersRef = useRef(null);
  const passInputRef = useRef(null);
  const fromRef = useRef(null);
  const toRef = useRef(null);
  const { language } = i18n;
  const isDefault = language === DEFAULT_LANG;

  const [getStops, { data, loading }] = useLazyQuery(BUS_STOPS, {
    fetchPolicy: 'cache-first',
    // fetchPolicy: 'network-only',
  });

  const searchUrl = () => {
    const dates = returnDate
      ? `departDate=${localizedDate(
          departDate,
          'yyyy-MM-dd'
        )}&returnDate=${localizedDate(returnDate, 'yyyy-MM-dd')}`
      : `departDate=${localizedDate(departDate, 'yyyy-MM-dd')}`;

    const passengersCounts = Object.entries(passengers)
      .filter(([key, value]) => value)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const affiliateId =
      document.cookie.includes('affiliateId') && getCookieByName('affiliateId');
    let searchUrl = '';

    if (affiliateId) {
      searchUrl = `?${dates}&currency=${currencyName}&promocode=${promocode}&fromBusStopId=${from.BusStopId}&toBusStopId=${to.BusStopId}&lang=${language}&${passengersCounts}&affiliateId=${affiliateId}`;
    } else {
      searchUrl = `?${dates}&currency=${currencyName}&promocode=${promocode}&fromBusStopId=${from.BusStopId}&toBusStopId=${to.BusStopId}&lang=${language}&${passengersCounts}`;
    }

    return searchUrl;
  };

  const params = {};

  search
    .replace('?', '')
    .split('&')
    .forEach(param => {
      const key = param.split('=')[0];
      const value = param.split('=')[1];
      params[key] = value;
    });

  const { affiliateId = '' } = params;

  const [getPopularDestinations, { data: popularDestinations }] = useLazyQuery(
    POPULAR_DESTINATIONS,
    {
      variables: {
        lang: language,
      },
    }
  );

  const swapDirections = () => {
    dispatch({
      type: 'SWAP_DIRECTIONS',
    });
  };

  const updateDate = date => {
    const isDeparture = Object.keys(date)[0] === 'departDate';
    const isResetReturnDate =
      isDeparture && compareAsc(date.departDate, returnDate) === 1;

    dispatch({
      type: 'UPDATE_SEARCH',
      payload: {
        ...(isResetReturnDate
          ? {
              ...date,
              returnDate: '',
            }
          : date),
      },
    });
  };

  const selectItem = search => {
    dispatch({
      type: 'UPDATE_SEARCH',
      payload: {
        [field]: {
          search: search.BusStopName,
          BusStopId: search.BusStopId,
          CityName: search.CityName,
          CountryName: search.CountryName,
        },
        isOpenSugg: false,
      },
    });
  };

  const toggleSuggModal = field => {
    dispatch({
      type: 'UPDATE_SEARCH',
      payload: {
        // INFO: in some case field = event object and need if below
        ...(field === 'to' || field === 'from' ? { field } : {}),
        isOpenSuggMob: !isOpenSuggMob,
      },
    });
  };

  const showSuggestion = field => {
    dispatch({
      type: 'UPDATE_SEARCH',
      payload: {
        isOpenSugg: true,
        isOpenPass: false,
        field,
      },
    });
  };

  const hideSuggestions = e => {
    e.stopPropagation();
    if (
      !e.path.includes(suggestionsRef.current) &&
      !e.path.includes(toRef.current) &&
      !e.path.includes(fromRef.current)
    ) {
      dispatch({
        type: 'UPDATE_SEARCH',
        payload: {
          isOpenSugg: false,
        },
      });
    }
  };

  const togglePassModal = () => {
    dispatch({
      type: 'UPDATE_SEARCH',
      payload: {
        isOpenPassMob: !isOpenPassMob,
      },
    });
  };

  const updatePassengers = (operation, type) => {
    dispatch({
      type: 'UPDATE_PASSENGERS',
      payload: {
        operation,
        type,
      },
    });
  };

  const showPassengers = () => {
    dispatch({
      type: 'UPDATE_SEARCH',
      payload: {
        isOpenPass: true,
        isOpenSugg: false,
      },
    });
  };

  const hidePassengers = e => {
    e.stopPropagation();
    if (
      !e.path.includes(passengersRef.current) &&
      !e.path.includes(passInputRef.current)
    ) {
      dispatch({
        type: 'UPDATE_SEARCH',
        payload: {
          isOpenPass: false,
        },
      });
    }
  };

  const updateSearch = e => {
    dispatch({
      type: 'UPDATE_SEARCH',
      payload: {
        [state.search.field]: {
          ...state.search[state.search.field],
          search: e.target.value,
          BusStopId: '',
        },
      },
    });
  };

  const pushAnaliticsToGTM = (fromToData = null) => {
    if (GTMLoaded) {
      if (fromToData) {
        const { from, to } = fromToData;
        dataLayer.push({
          tripType: returnDate ? 'return-trip' : 'one-way',
          departureStopId: from.BusStopId,
          destinationStopId: to.BusStopId,
          currencyCode: currencyName,
          route: `${from.CityName}-${to.CityName}`,
          promocode: promocode || null,
          departureDestination: `${from.CountryName}-${to.CountryName}`,
          stage: 'search',
          event: 'search',
          departDate: dateFormat(new Date(departDate), 'yyyy-MM-dd'),
          ...(returnDate
            ? {
                returnDate: dateFormat(new Date(returnDate), 'yyyy-MM-dd'),
              }
            : {}),
        });
      } else {
        dataLayer.push({
          tripType: returnDate ? 'return-trip' : 'one-way',
          departureStopId: from.BusStopId,
          destinationStopId: to.BusStopId,
          currencyCode: currencyName,
          route: `${from.CityName}-${to.CityName}`,
          promocode: promocode || null,
          departureDestination: `${from.CountryName}-${to.CountryName}`,
          stage: 'search',
          event: 'search',
          departDate: dateFormat(new Date(departDate), 'yyyy-MM-dd'),
          ...(returnDate
            ? {
                returnDate: dateFormat(new Date(returnDate), 'yyyy-MM-dd'),
              }
            : {}),
        });
      }
    }
  };

  const handleSearch = () => {
    pushAnaliticsToGTM();

    const url = isDefault
      ? `/tickets/search${searchUrl()}`
      : `/${language}/tickets/search${searchUrl()}`;
    history.push(url);

    dispatch({
      type: 'RESET_TICKETS',
    });

    updateLastSearch();
  };

  const updateLastSearch = () => {
    dispatch({
      type: 'UPDATE_SEARCH',
      payload: {
        isValidUrl: true,
        isOpenPass: false,
      },
    });
  };

  useEffect(() => {
    getPopularDestinations();
  }, []);

  useEffect(() => {
    //  TODO: move to common
    if (!('path' in Event.prototype)) {
      Object.defineProperty(Event.prototype, 'path', {
        get() {
          const path = [];
          let currentElem = this.target;
          while (currentElem) {
            path.push(currentElem);
            currentElem = currentElem.parentElement;
          }

          if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
            path.push(document);
          }

          if (path.indexOf(window) === -1) {
            path.push(window);
          }

          return path;
        },
      });
    }

    if (isOpenSugg) {
      document.addEventListener('click', hideSuggestions, false);

      return () => {
        document.removeEventListener('click', hideSuggestions, false);
      };
    }

    if (isOpenPass) {
      document.addEventListener('click', hidePassengers, false);

      return () => {
        document.removeEventListener('click', hidePassengers, false);
      };
    }
  }, [isOpenSugg, isOpenPass]);

  useEffect(() => {
    const params = {};

    search
      .replace('?', '')
      .split('&')
      .forEach(param => {
        const key = param.split('=')[0];
        const value = param.split('=')[1];
        params[key] = value;
      });

    const {
      promocode = '',
      passengersCount,
      fromBusStopId = '',
      toBusStopId = '',
      // child = 0,
      // youth = 0,
      // senior = 0,
      passengers = 1,
      affiliateId = '',
    } = params;

    if (affiliateId || getCookieByName('affiliateId')) {
      const maxAgeCookie = 3600 * 24 * 30; // ~ 30 days
      if (affiliateId) {
        document.cookie = `affiliateId=${affiliateId};path=/;max-age=${maxAgeCookie};`;
      } else {
        document.cookie = `affiliateId=${getCookieByName(
          'affiliateId'
        )};path=/;max-age=${maxAgeCookie};`;
      }
    }

    if (search !== searchUrl()) {
      const isSearchValid =
        !!toBusStopId && !!fromBusStopId && !!params.departDate;

      let departDate = new Date();

      if (isValid(new Date(params.departDate?.replace(/-/g, '/')))) {
        departDate = new Date(params.departDate.replace(/-/g, '/'));
      } else {
        switch (params.departDate) {
          case 'today':
            departDate = new Date();
            break;

          case 'tomorrow':
            departDate = addDays(new Date(), 1);
            break;

          default:
        }
      }

      const returnDate =
        params.returnDate &&
        isValid(new Date(params.returnDate?.replace(/-/g, '/')))
          ? new Date(params.returnDate.replace(/-/g, '/'))
          : '';

      dispatch({
        type: 'UPDATE_SEARCH',
        payload: {
          isValidUrl: isSearchValid,
          promocode,
          isSearchValid,
          departDate,
          returnDate,
          // passengersCount: +passengersCount || 1,
          // passengersCount: +child + +youth + +senior + +adult,
          passengersCount: +passengers,
          passengers: {
            // passengers: +passengersCount || 1,
            passengers: +passengers,
            // child: +child,
            // youth: +youth,
            // senior: +senior,
          },
          from: {
            BusStopId: fromBusStopId || from.BusStopId,
          },
          to: {
            BusStopId: toBusStopId || to.BusStopId,
          },
        },
      });
    }
  }, []);

  // useEffect(() => {
  //   if (!currenciesLoading) {
  //     const params = {};
  //     search
  //       .replace('?', '')
  //       .split('&')
  //       .forEach(param => {
  //         const key = param.split('=')[0];
  //         const value = param.split('=')[1];
  //         params[key] = value;
  //       });

  //     const { currency = '' } = params;

  //     if (currency) {
  //       dispatch({
  //         type: 'UPDATE_CURRENCY',
  //         payload: {
  //           currencies,
  //           currency: currency.toUpperCase(),
  //         },
  //       });
  //     }
  //   }
  // }, [currenciesLoading]);

  useEffect(() => {
    if (
      getCookieByName('affiliateId') &&
      match.url.includes('/tickets/search')
    ) {
      if (!affiliateId) {
        const promise = new Promise(res => res());

        promise
          .then(() => {
            if (from.BusStopId && to.BusStopId) {
              getStops({
                variables: {
                  ids: [+from.BusStopId, +to.BusStopId],
                  lang: language,
                },
              });
            }
          })
          .then(() => {
            history.replace(
              DEFAULT_LANG === language
                ? `/tickets/search${searchUrl()}`
                : `/${language}/tickets/search${searchUrl()}`
            );
          });
      }
    }
  }, [language]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_SEARCH',
      payload: {
        isSearchValid: !!from.BusStopId && !!to.BusStopId && !!departDate,
      },
    });

    if (!from.search && !to.search && from.BusStopId && to.BusStopId) {
      getStops({
        variables: {
          ids: [+from.BusStopId, +to.BusStopId],
          lang: language,
        },
      });
    }
  }, [departDate, from.BusStopId, to.BusStopId, affiliateId]);

  useEffect(() => {
    if (data?.busStops?.[0] && data?.busStops?.[1]) {
      const fromToData = {
        from: {
          ...from,
          CountryName: data.busStops[0].CountryName,
          CityName: data.busStops[0].Address.CityName,
          search: data.busStops[0].BusStopName,
        },
        to: {
          ...to,
          CountryName: data.busStops[1].CountryName,
          CityName: data.busStops[1].Address.CityName,
          search: data.busStops[1].BusStopName,
        },
      };
      dispatch({
        type: 'UPDATE_SEARCH',
        payload: {
          ...fromToData,
        },
      });
      pushAnaliticsToGTM(fromToData);
    }
  }, [data]);

  return (
    <div className={cx(s.search, className)}>
      {isMob && (
        <SearchDestinationMobile
          search={
            field === 'to' ? to.search : field === 'from' ? from.search : ''
          }
          selectedStop={field === 'to' ? from.BusStopId : to.BusStopId}
          isOpen={isOpenSuggMob}
          toggleModal={toggleSuggModal}
          modalTitle={field}
          selectItem={selectItem}
          updateSearch={updateSearch}
          language={language}
          popularDestinations={popularDestinations?.popularDestinations}
        />
      )}
      {isMob && (
        <SearchPassengersMobile
          passengersCount={passengersCount}
          isOpen={isOpenPassMob}
          toggleModal={togglePassModal}
          {...{ passengers, updatePassengers }}
        />
      )}
      <div className={cx(s.inputs, inputsClassName)}>
        <div className={cx(s.input, s.direction)} ref={fromRef}>
          <SearchInput
            data-title={
              !isMob && isOpenSugg && field === 'from' ? '' : from.search
            }
            onFocus={() => {
              dispatch({
                type: 'UPDATE_SEARCH',
                payload: {
                  isOpenSugg: true,
                },
              });
            }}
            value={from.search}
            label={t('from')}
            placeholder={t('try riga')}
            onChange={updateSearch}
            onClick={
              isMob
                ? () => toggleSuggModal('from')
                : () => showSuggestion('from')
            }
          />
          {!isMob && isOpenSugg && field === 'from' && (
            <Suggestions
              selectedStop={to.BusStopId}
              suggestionsRef={suggestionsRef}
              selectItem={selectItem}
              search={from.search}
              language={language}
              popularDestinations={popularDestinations?.popularDestinations}
            />
          )}
          <div className={s.swap} onClick={swapDirections}>
            <i className={i.exchange} />
          </div>
        </div>
        <div className={cx(s.input, s.direction)} ref={toRef}>
          <SearchInput
            data-title={!isMob && isOpenSugg && field === 'to' ? '' : to.search}
            value={to.search}
            onFocus={() => {
              dispatch({
                type: 'UPDATE_SEARCH',
                payload: {
                  isOpenSugg: true,
                },
              });
            }}
            label={t('to')}
            placeholder={t('try tartu')}
            onChange={updateSearch}
            onClick={
              isMob ? () => toggleSuggModal('to') : () => showSuggestion('to')
            }
          />
          {!isMob && isOpenSugg && field === 'to' && (
            <Suggestions
              selectedStop={from.BusStopId}
              suggestionsRef={suggestionsRef}
              selectItem={selectItem}
              search={to.search}
              language={language}
              popularDestinations={popularDestinations?.popularDestinations}
            />
          )}
        </div>
        <div
          className={cx(s.input, s.datePicker)}
          // data-title={departDate ? localizedDate(departDate, 'dd-MM-yyyy') : ''}
        >
          <DatePicker
            value={departDate}
            placeholder={t('pick the date')}
            label={t('depart')}
            format="d MMMM, eee"
            onDayChange={departDate => updateDate({ departDate })}
            selectedDays={[departDate]}
            disabledDays={[
              {
                before: new Date(),
              },
            ]}
          />
        </div>
        <div
          className={cx(s.input, s.datePicker)}
          // data-title={returnDate ? localizedDate(returnDate, 'dd-MM-yyyy') : ''}
        >
          <DatePicker
            value={returnDate}
            placeholder={t('pick the date')}
            label={t('return')}
            format="d MMMM, eee"
            posRight
            onDayChange={returnDate => updateDate({ returnDate })}
            disabledDays={[
              {
                before: departDate || new Date(),
              },
            ]}
            selectedDays={[returnDate]}
          />
          {returnDate && (
            <div
              className={s.removeDate}
              onClick={() => updateDate({ returnDate: '' })}
            >
              <i className={i.close} />
            </div>
          )}
        </div>
        <div className={cx(s.input, s.passengers)} ref={passInputRef}>
          <SearchInput
            // data-title={
            //   !isMob && isOpenPass
            //     ? ''
            //     : `${passengersCount} ${t('passengers')}`
            // }
            label={t('passengers')}
            icon="passenger"
            readOnly="readonly"
            placeholder={t('add')}
            value={passengersCount.toString()}
            onClick={isMob ? togglePassModal : showPassengers}
          />
          {!isMob && isOpenPass && (
            <Passengers
              passengersCount={passengersCount}
              updatePassengers={updatePassengers}
              passengers={passengers}
              passRef={passengersRef}
            />
          )}
        </div>
        <div className={cx(s.input, s.promocode)} data-title={promocode}>
          <SearchInput
            value={promocode}
            label={t('promocode')}
            placeholder={t('enter code')}
            onChange={e =>
              dispatch({
                type: 'UPDATE_SEARCH',
                payload: {
                  promocode: e.target.value,
                },
              })
            }
          />
        </div>
      </div>
      {ButtonSearch ? (
        <ButtonSearch
          searchUrl={searchUrl}
          disabled={!isSearchValid}
          pushAnaliticsToGTM={pushAnaliticsToGTM}
        />
      ) : (
        <Button
          disabled={!isSearchValid}
          // to={`/tickets/search?${searchUrl()}`}
          // className={s.search}
          text={t('search')}
          size="l"
          onClick={isSearchValid ? handleSearch : () => {}}
        />
      )}
    </div>
  );
};

export default withRouter(withTranslation()(Search));
