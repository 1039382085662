import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { CREATE_ROUTE } from '@lux/spa/apollo/mutations';
import { NotFound } from '@lux/spa/routes';
import { emptyData, defaultData } from './constants';
import RouteContent from './route-content-new';

const CreateRoute = ({
  region,
  lang,
  match,
  i18n,
  isStaff,
  isEditMode,
  page = { en: defaultData },
  dispatch = () => {},
  ...props
}) => {
  const { route } = match.params;
  const { language } = i18n;

  const updatePage = field => {
    dispatch({
      type: 'UPDATE_DATA',
      payload: {
        field,
      },
    });
  };

  useEffect(() => {
    if (isStaff) {
      dispatch({
        type: 'UPDATE',
        payload: {
          defaultData,
          emptyData,
          // UPDATE_PAGE: UPDATE_DESTINATION,
          CREATE_PAGE: CREATE_ROUTE,
          page: {
            isPublished: false,
            [language]: {
              ...defaultData,
              countries: [region],
            },
          },
        },
      });
    }
  }, []);

  const content = page?.[lang];

  return content ? (
    <RouteContent
      {...{ updatePage, content, language, isEditMode, region }}
      {...props}
    />
  ) : (
    <NotFound />
  );
};

export default withTranslation()(CreateRoute);
