import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { StoreContext } from '@lux/spa/store';
import { Modal, ModalContent, Button } from '@lux/ui';
import s from './partner-bus-notice.module.scss';

const PartnerBusNotice = ({ t, isOpen, toggleModal }) => {
  const [state, dispatch] = useContext(StoreContext);

  const handleConfirm = () => {
    dispatch({
      type: 'UPDATE_TICKETS',
      payload: {
        confirmPartnerBusNotice: true,
      },
    });
    toggleModal();
  };

  return (
    <Modal
      className={s.modal}
      {...{
        isOpen,
        toggleModal,
      }}
    >
      <ModalContent className={s.content}>
        <div className={s.controls}>
          <img src="/images/svg/bus_v3.svg" alt="" />
          <h1>{t('please note')}</h1>
          <p>{t('warning.on.non.lux.services')}</p>
          <Button
            className={s.button}
            text={t('confirm')}
            onClick={handleConfirm}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default withRouter(withTranslation()(PartnerBusNotice));
