import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalContent, Editor } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './campaign-rules.module.scss';

const CampaignRules = ({
  t,
  isOpen,
  toggleModal,
  campaignRules,
  updatePage,
}) => {
  return (
    <Modal className={s.modal} {...{ isOpen, toggleModal }}>
      <ModalHeader text={t('campaign rules')} />
      <ModalContent>
        <Editor
          placeholder={t('campaign rules')}
          html={campaignRules}
          onChange={(event, editor) => {
            const data = editor.getData();
            updatePage({
              campaignRules: data,
            });
          }}
        />
        {/* <div className={s.infos}>
          <div className={s.info}>
            <h5>01</h5>
            <p>
          Special offer applies when making purchase online at luxexpress.eu or at authorized partners.
            </p>
          </div>
          <div className={s.info}>
            <h5>02</h5>
            <p>
          Tickets are non-refundable.
            </p>
          </div>
          <div className={s.info}>
            <h5>03</h5>
            <p>
          Tickets are changeable; in such case price difference between original and new ticket must be paid.
            </p>
          </div>
          <div className={s.info}>
            <h5>04</h5>
            <p>
          Tickets purchased with special price cannot be changed for other discounted tickets.
            </p>
          </div>
          <div className={s.info}>
            <h5>05</h5>
            <p>
          Special fare does not apply for seats in Lux Express Lounge zone.
            </p>
          </div>
          <div className={s.info}>
            <h5>06</h5>
            <p>
          Special fare applies only to limited number of seats per departure.
            </p>
          </div>
          </div>
          <p>
          <i className={i.warning} /> Special offer does not apply for:
          </p>
          <div className={s.list}>
          <div className={s.item}>
            <div className={s.circle} />
            Riga - Tallin 24.02.20, 10.04.20 - 13.04.20;
          </div>
          <div className={s.item}>
            <div className={s.circle} />
            Tallin - Riga 22.02.20, 07.03.2020, 10.04.20 - 13.04.20;
          </div>
          <div className={s.item}>
            <div className={s.circle} />
            Riga - Tallin 24.02.20, 10.04.20 - 13.04.20;
          </div>
        </div> */}
      </ModalContent>
    </Modal>
  );
};

export default withTranslation()(CampaignRules);
