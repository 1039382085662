import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Query } from 'react-apollo';
import { localizedDate, addBlankAttrToHtml } from '@lux/spa/utils';
import { meta } from '@lux/spa/components';
import {
  Container,
  Button,
  Divider,
  Editor,
  Link,
  Loading,
  Text,
  UploadImages,
} from '@lux/ui';
import { GET_ALL_NEWS, GET_ALL_NEWS_ADMIN } from '@lux/spa/apollo/queries';
import i from '@lux/spa/styles/icons.module.scss';
import s from './news.module.scss';

const NewsList = ({ t, newsList, idNews }) => {
  const filteredNews = newsList.filter(({ node }) => idNews != node.id);
  return (
    !!filteredNews.length && (
      <aside>
        <h3>{t('more news')}</h3>
        <div className={s.news}>
          {filteredNews.slice(0, 3).map(({ node }, key) => {
            const slug = node.data.seo.slug.value;
            const date = node.publishDate;
            const title = node.data.pageData.title.value;
            const description = node.data.seo.description.value;
            return (
              <Link key={key} to={`/news/${slug}/`} className={s.item}>
                <div className={s.date}>
                  {localizedDate(date, 'dd MMMM yyyy')}
                </div>
                <h5>{title}</h5>
                <p>{description}</p>
              </Link>
            );
          })}
        </div>
        <Link className={s.showLatest} to="/blog/">
          {t('show latest news')}
          <i className={i.arrowRight_v2} />
        </Link>
      </aside>
    )
  );
};

const NewsContent = ({
  t,
  slugs,
  type,
  region,
  idNews,
  isEditMode,
  isStaff,
  content,
  language,
  className,
  updatePage,
}) => {
  const [isBannerImg, setIsBannerImg] = useState(false);

  const facebookShareButton = useRef(null);
  const twitterShareButton = useRef(null);

  const toggleModal = () => {
    setIsBannerImg(!isBannerImg);
  };

  const { pageData = {}, seo = {}, countries } = content;

  const {
    title,
    subTitle,
    banner,
    heading1,
    twitterLink,
    facebookLink,
    news,
  } = pageData;

  const backgroundImage = banner.value
    ? `linear-gradient(90deg, #000000d9, transparent), url(${banner.value})`
    : `linear-gradient(90deg, #000000d9, transparent)`;

  const [copyLink, setCopyLink] = useState({
    link: '',
    show: false,
  });

  const onCopy = () => {
    setCopyLink(prev => ({ ...prev, show: true }));
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCopyLink(prev => ({ ...prev, link: window.location.href }));
    }
  }, []);

  useEffect(() => {
    if (facebookShareButton.current) {
      facebookShareButton.current.addEventListener('click', function() {
        const url = window.location.href;
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${url}`,
          'facebook',
          'width=800,height=600'
        );
        return false;
      });
    }

    if (twitterShareButton.current) {
      twitterShareButton.current.addEventListener('click', function() {
        const hashTags = `luxexpres,estonia`;
        const url = window.location.href;
        const text = 'Lux Express - the most comfortable seat on highways';

        window.open(
          // `https://twitter.com/share?url=${url}&text=${text}&hashtags=${hashTags}`,
          `https://twitter.com/share?url=${url}`,
          'twitter',
          'width=800,height=600'
        );
        return false;
      });
    }
  }, []);

  return (
    <div className={cn(className, s.news)}>
      <Helmet {...meta({ seo, lang: language, countries, slugs, type })} />
      {isBannerImg && (
        <UploadImages
          isOpen={isBannerImg}
          toggleModal={toggleModal}
          updatePage={updatePage}
          image={banner}
        />
      )}
      <Container className={s.bannerContainer} style={{ backgroundImage }}>
        <Container full={false}>
          <section className={s.banner}>
            {isEditMode && (
              <Button
                className={s.uploadBanner}
                text={banner.value ? t('edit photo') : t('upload photo')}
                onClick={toggleModal}
              />
            )}
            <h1>
              <Text onChange={updatePage} field={title} />
            </h1>
            <p>
              <Text onChange={updatePage} field={subTitle} />
            </p>
          </section>
        </Container>
      </Container>
      <Divider />
      <Container className={s.news}>
        <Container full={false}>
          <section>
            <article>
              <div className={s.texts}>
                <h4 className={s.title}>
                  <Text onChange={updatePage} field={heading1} />
                </h4>
                <Editor
                  html={isEditMode ? news : addBlankAttrToHtml(news)}
                  placeholder={t('news text')}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    updatePage({
                      news: data,
                    });
                  }}
                />
                {/* <p>
                  "The ways of sending and receiving parcels are becoming more flexible and closer to the customer," explained Cargobus CEO Airika Aruksaar.
                  </p>
                  <LazyLoadImage src="/images/article/content-image-1.png" alt=""/>
                  <p>
                  For example, Cleveron has entered the market with custom-built parcel machines that can be used to order newspapers, food or even electronics. "In our view, this direction eliminates the formation of large parcel maschine areas around shopping centers",  said Aruksaar.
                  </p>
                  <p>
                  “Cargobus thinks that connecting to the network of parcel machines of different companies is the first step to closer to a situation where every landlord has a their personal one”, added Arukssaar.
                  </p>
                  <div className={s.quote}>
                  <i className={i.quote} />
                  <h4>
                    Cargobus thinks that connecting to the network of parcel machines of different companies is the first step to closer to a situation where every landlord has a their personal one
                  </h4>
                  <div className={s.author}>
                    <div className={s.line} />
                    Arukssaar
                  </div>
                  </div>
                  <p>
                  “Cargobus thinks that connecting to the network of parcel machines of different companies is the first step to closer to a situation where every landlord has a their personal one”, added Arukssaar.
                </p> */}
              </div>
              <Query
                query={isStaff ? GET_ALL_NEWS_ADMIN : GET_ALL_NEWS}
                variables={{
                  lang: language,
                  country: region,
                  first: 4,
                }}
              >
                {({ data, loading }) => {
                  if (loading) return <Loading />;
                  const newsList = isStaff
                    ? data?.adminNewsList?.edges
                    : data?.newsList?.edges;
                  return (
                    <NewsList t={t} idNews={idNews} newsList={newsList || []} />
                  );
                }}
              </Query>
            </article>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(NewsContent);
