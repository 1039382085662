import React, { useState, useEffect, memo, useContext } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import { StoreContext } from '@lux/spa/store';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_WIDGET, GET_WIDGET_ADMIN } from '@lux/spa/apollo/queries';
import { UPDATE_STATIC_WIDGET } from '@lux/spa/apollo/mutations';
import {
  Divider,
  Button,
  LazyLoadImage,
  Link,
  EditWidget,
  Loading,
} from '@lux/ui';
import { BannerItem, emptyBannerItem } from './components';
import s from './banner.module.scss';

function isPropsAreEqual(prevMovie, nextMovie, propsList) {
  return propsList.every(prop => prevMovie[prop] === nextMovie[prop]);
}

const Banner = ({ t, i18n, className, horizontal = true }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { user, app } = state;
  const { isStaff } = user;
  const { isEditMode } = app;
  const { language } = i18n;
  const [randomBanner, setRandomBanner] = useState(false);

  const QUERY = isStaff ? GET_WIDGET_ADMIN : GET_WIDGET;

  const { data } = useQuery(QUERY, {
    variables: {
      lang: language,
      region: 'ee',
      name: 'banner',
    },
  });

  const [updateBanner, { data: newData, loading, called }] = useMutation(
    UPDATE_STATIC_WIDGET
  );

  const [isWidget, setIsWidget] = useState(false);

  const toggleWidget = () => {
    setIsWidget(!isWidget);
  };

  const widget = isStaff ? data?.adminStaticWidget : data?.staticWidget;

  const items = isStaff
    ? widget?.[language]?.widgetData?.items || []
    : widget?.data?.widgetData?.items || [];

  useEffect(() => {
    const filteredItems = items.filter(
      i => i.isPublished === undefined || i.isPublished
    );

    if (filteredItems.length) {
      setRandomBanner(
        filteredItems[Math.floor(Math.random() * filteredItems.length)]
      );
    }
  }, [items]);

  // const [createWidget] = useMutation(CREATE_STATIC_WIDGET)
  //
  // const createWidgetButton = () => {
  //   createWidget({
  //     variables: {
  //       input: {
  //         name: 'routes',
  //         en: {
  //           lang: 'en',
  //           countries: ['ee'],
  //           widgetData: {
  //             items: []
  //           }
  //         }
  //       }
  //     }
  //   })
  // }

  const Banner = isEditMode ? 'div' : Link;

  return (
    <>
      {isWidget && (
        <EditWidget
          isOpen={isWidget}
          toggleModal={toggleWidget}
          widget={widget}
          emptyItem={emptyBannerItem}
          updateWidget={updateBanner}
        >
          <BannerItem />
        </EditWidget>
      )}
      {randomBanner && items.length ? (
        <Banner
          to={randomBanner.link.value || '/'}
          className={cn(s.banner, !horizontal && s.vertical, className)}
        >
          {isEditMode && (
            <Button
              className={s.editBanner}
              text={loading ? <Loading /> : t('edit banner')}
              onClick={toggleWidget}
              disabled={loading}
            />
          )}
          <div
            className={s.image}
            style={{
              backgroundImage: `url(${
                horizontal
                  ? randomBanner.img.value
                  : randomBanner.imgVertical.value
              })`,
            }}
          />
          <div className={s.logo}>
            <LazyLoadImage src="/images/svg/logo-white.svg" alt="" />
          </div>
          <div className={s.description}>
            <div className={s.title}>{randomBanner.title.value}</div>
            <div className={s.text}>{randomBanner.subTitle.value}</div>
            {/* <Button
                text={t('create widget')}
                onClick={createWidgetButton}
              /> */}
          </div>
          <Divider height={3} absolute />
        </Banner>
      ) : isEditMode ? (
        <div className={cn(s.banner, s.empty, className)}>
          <div className={s.text}>{t('banner')}</div>
          <Button text={t('add banner')} onClick={toggleWidget} />
        </div>
      ) : null}
    </>
  );
};

export default withTranslation()(memo(Banner));
// export default withTranslation()(
//   memo(Banner, (prevMovie, nextMovie) =>
//     isPropsAreEqual(prevMovie, nextMovie, ['horizontal'])
//   )
// );
