export const emptyRoute = {
  slug: {
    value: '',
    fieldName: 'slug',
    placeholder: 'Slug',
    maxLength: 100,
  },
  title: {
    value: '',
    fieldName: 'title',
    placeholder: 'Title',
    maxLength: 100,
  },
};
