import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalContent, Editor } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './editor-field.module.scss';

const EditorField = ({
  t,
  isOpen,
  toggleModal,
  title,
  field,
  fieldName,
  updatePage,
}) => {
  return (
    <Modal className={s.modal} {...{ isOpen, toggleModal }}>
      <ModalHeader text={t(title)} />
      <ModalContent>
        <Editor
          placeholder=""
          html={field}
          onChange={(event, editor) => {
            const data = editor.getData();
            updatePage({
              [fieldName]: data,
            });
          }}
        />
      </ModalContent>
    </Modal>
  );
};

export default withTranslation()(EditorField);
