import React, { useState, useContext } from 'react';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import { Collapse } from 'react-collapse';
import { useMutation } from '@apollo/react-hooks';
import { CHANGE_SEAT_NUMBER } from '@lux/spa/apollo/mutations';
import { StoreContext } from '@lux/spa/store';
import { Button } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import Passenger from '../passenger';
import styles from './transfer.module.scss';

const Transfer = ({
  t,
  i18n,
  leg,
  legsCount,
  orderNumber,
  isTransfer,
  active,
  products,
  canChooseSeatNumber,
}) => {
  const { language } = i18n;
  const [state, dispatch] = useContext(StoreContext);
  const { tickets } = state;

  const { currency } = tickets;

  const { isMob } = useContext(StoreContext);

  const [updateSeat, { data }] = useMutation(CHANGE_SEAT_NUMBER, {
    onCompleted: data => {
      if (data?.changeSeatNumber?.error) {
        console.error(error);
        return;
      }

      if (data?.changeSeatNumber?.success) {
        console.log('Seat has been success changed');
      }
    },
  });

  const { activeBus, inbound, outbound, isOutbound } = state.tickets;

  const direction = isOutbound ? outbound : inbound;

  const [isOpen, setIsOpen] = useState(active);

  const toggleOpen = orderNumber => {
    if (orderNumber === activeBus) {
      setIsOpen(!isOpen);
    } else {
      dispatch({
        type: 'UPDATE_TICKETS',
        payload: {
          activeBus: orderNumber,
          activePassenger: 1,
        },
      });
    }
  };

  const handleSave = orderNumber => {
    const nextBus =
      legsCount >= orderNumber + 1 ? orderNumber + 1 : orderNumber;

    dispatch({
      type: 'UPDATE_TICKETS',
      payload: {
        activeBus: nextBus,
        ...(legsCount !== orderNumber
          ? {
              activePassenger: 1,
            }
          : {}),
      },
    });

    const updateData = {};

    const bus = Object.values(direction.passengers)[activeBus - 1];

    const SeatInfo = Object.values(bus).map(p => {
      updateData.TripBusProfileId = p.bus.TripBusProfileId;
      return {
        TicketNumber: p.TicketNumber, // String!
        // SeatNumber: p.SeatNumber, // Int
        // SeatNumberLetter: null,
        // SeatFloorNumber: p.SeatFloorNumber, // Int
        // LoyaltyLevelName: '12', // String
      };
    });

    updateData.SeatInfo = SeatInfo;

    updateSeat({
      variables: {
        input: {
          TripBusProfiles: [updateData],
          CurrencyXc: currency,
        },
      },
    });
  };

  const classes = cx(
    styles.fromTo,
    isTransfer && styles.transfer,
    active && styles.open
  );
  return (
    <div className={classes}>
      <div className={styles.heading} onClick={() => toggleOpen(orderNumber)}>
        <div>{leg.DepartureStopName}</div>
        <i className={i.arrowRight_v3} />
        <div>{leg.DestinationStopName}</div>
        {/* <i className={cx(i.edit, styles.edit)} /> */}
      </div>
      {isTransfer ? (
        <Collapse isOpened={active}>
          <div className={styles.selectSeats}>
            {leg.SeatInfo.map((seat, key) => (
              <Passenger
                seat={seat}
                number={key + 1}
                key={key}
                leg={leg}
                legsCount={legsCount}
                orderNumber={orderNumber}
                product={products[key]}
                language={language}
                canChooseSeatNumber={canChooseSeatNumber}
              />
            ))}
            {/* <Button
              className={styles.button}
              text="save"
              onClick={() => handleSave(orderNumber)}
            /> */}
          </div>
        </Collapse>
      ) : (
        <div className={styles.selectSeats}>
          {leg.SeatInfo.map((seat, key) => (
            <Passenger
              seat={seat}
              number={key + 1}
              key={key}
              leg={leg}
              legsCount={legsCount}
              orderNumber={orderNumber}
              product={products[key]}
              language={language}
              canChooseSeatNumber={canChooseSeatNumber}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(Transfer);
