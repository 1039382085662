import React from 'react';
import cn from 'classnames';
import * as uuid from 'uuid';
import { Tooltip } from '@lux/ui';
import s from './tickets-input.module.scss';

const TicketsInput = ({
  required = false,
  isError,
  errors,
  label,
  className,
  withoutMessage = false,
  errorWithoutMessage = false,
  inputRef,
  tooltipText,
  ...props
}) => {
  const id = uuid.v4();
  const classes = cn(
    s.ticketsInput,
    (errors || errorWithoutMessage) && s.error,
    withoutMessage && s.withoutMessage,
    className
  );
  return (
    <div className={classes}>
      {label && (
        <label htmlFor={id}>
          {`${label}${required ? ' *' : ''}`}
          {tooltipText && (
            <Tooltip className={s.tooltip}>{tooltipText}</Tooltip>
          )}
        </label>
      )}
      <input id={id} type="text" {...props} ref={inputRef} />
      {errors && <div className={s.message}>{errors}</div>}
    </div>
  );
};

export default TicketsInput;
