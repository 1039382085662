import React, { useState, memo } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Collapse } from 'react-collapse';
import i from '@lux/spa/styles/icons.module.scss';
import { Notice } from '@lux/ui';
import CoreForm from './core-form.jsx';
import s from '../../passenger-details.module.scss';

const PassengerForm = ({
  t,
  className,
  passenger,
  number,
  error,
  success,
  isPassengersInfoConfirmed,
  legs,
  ...props
}) => {
  const [isOpenForm, setIsOpenForm] = useState(true);
  const { info = {}, FareClassName = '', RequestedFareClass = '' } = passenger;
  const { firstName = '', lastName = '', customerInfoConfirmed } = info;
  const fullName = `${firstName} ${lastName}`;

  const togglePassengerInfo = () => {
    setIsOpenForm(!isOpenForm);
  };
  let fareClassNameText = '';

  switch (RequestedFareClass) {
    case 'BONUS_SCHEME_GROUP.ADULT':
      fareClassNameText = 'adult';
      break;
    case 'BONUS_SCHEME_GROUP.CHILDREN':
      fareClassNameText = 'child';
      break;
    case 'BONUS_SCHEME_GROUP.THE_YOUNG':
      fareClassNameText = 'youth';
      break;
    case 'BONUS_SCHEME_GROUP.THE_ELDERLY':
      fareClassNameText = 'senior';
      break;
    default: {
      fareClassNameText = 'adult';
    }
  }

  return (
    <div className={cn(s.formBlock, className)}>
      {number === 1 ? (
        <>
          <div className={s.header}>
            <h3>
              {/* {t('enter contact information')} */}
              {t('enter passenger details', {
                number,
              })}
              {isPassengersInfoConfirmed && <i className={i.success} />}
            </h3>
          </div>
          {error && <Notice className={s.notice} text={error} color="red" />}
        </>
      ) : (
        <div
          className={cn(s.header, isOpenForm ? s.open : s.close)}
          onClick={togglePassengerInfo}
        >
          <div className={s.toggle}>
            <h3>
              {t('enter passenger details', {
                number,
              })}
              {isPassengersInfoConfirmed && <i className={i.success} />}
            </h3>
            <div className={s.passengerInfo}>
              {fullName.length > 3 && (
                <div className={s.passengerName}>{fullName}</div>
              )}
              <div className={s.passengerAge}>{t(fareClassNameText)}</div>
            </div>
          </div>
          <i className={i.arrowBottom} />
        </div>
      )}
      {number === 1 ? (
        <CoreForm {...props} number={number} legs={legs} />
      ) : (
        <Collapse isOpened={isOpenForm}>
          <CoreForm {...props} number={number} t={t} legs={legs} />
        </Collapse>
      )}
    </div>
  );
};

export default withTranslation()(memo(PassengerForm));
