export const emptySlide = {
  alt: {
    value: '',
    fieldName: 'alt',
    placeholder: 'Alt',
    maxLength: 30,
  },
  img: {
    value: '',
    fieldName: 'img',
    placeholder: 'Slide',
    name: '',
    size: {
      height: 720,
      width: 1080,
    },
  },
};
