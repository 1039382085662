import gql from 'graphql-tag';

export const GET_NEWS_ADMIN = gql`
  query($lang: String!, $slug: String!, $country: String) {
    adminNews(lang: $lang, slug: $slug, country: $country) {
      id
      isPublished
      publishDate
      # slugs
      en {
        seo
        lang
        slug
        countries
        pageData
        isPublished
      }
      fi {
        seo
        lang
        slug
        countries
        pageData
        isPublished
      }
      et {
        seo
        lang
        slug
        countries
        pageData
        isPublished
      }
      ru {
        seo
        lang
        slug
        countries
        pageData
        isPublished
      }
      lv {
        seo
        lang
        slug
        countries
        pageData
        isPublished
      }
      lt {
        seo
        lang
        slug
        countries
        pageData
        isPublished
      }
    }
  }
`;

export const GET_NEWS = gql`
  query($lang: String!, $slug: String!, $country: String) {
    news(lang: $lang, slug: $slug, country: $country) {
      id
      isPublished
      publishDate
      slugs
      data {
        seo
        lang
        slug
        countries
        pageData
        isPublished
      }
    }
  }
`;
