import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { StoreContext } from '@lux/spa/store';
import { CALCULATE_RETURN } from '@lux/spa/apollo/mutations';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { Button } from '@lux/ui';
import { Info } from '../components';
import s from '../refund.module.scss';

const ChoosePassengers = ({
  t,
  i18n,
  prevStep,
  goToStep,
  dataRefund,
  setDataRefund,
  history,
}) => {
  const [state, dispatch] = useContext(StoreContext);

  const { isPublic } = state.tickets.refund;
  const { language } = i18n;
  const { Passengers } = dataRefund.ways[0];
  const [isSecondCalculatedCall, setIsSecondCalculatedCall] = useState(false);

  const [selectedPassengers, setSelectedPassengers] = useState(
    Passengers.map((_, key) => key === 0)
  );

  const [calculatedReturn, { loading }] = useMutation(CALCULATE_RETURN, {
    onError: data => {
      console.error('Error: ', data);
    },
    onCompleted: data => {
      if (data.calculateReturn.error) {
        toast.error(data.calculateReturn.error.ErrorReason);
      } else {
        const { returnInfo } = data.calculateReturn;
        const paymentTypes = returnInfo.PaymentDetails.map(
          item => item.PaymentType
        );

        setDataRefund(prev => {
          return {
            ...prev,
            ...(!isSecondCalculatedCall
              ? {
                  returnInfoDefault: returnInfo,
                }
              : {
                  returnInfoVoucher: returnInfo,
                }),
            selectedWay: returnInfo.RefundableProducts[0].RefundingScopes[0],
          };
        });

        if (
          !paymentTypes.includes('CLIENT_CONTRACT_CARD') &&
          !isSecondCalculatedCall
        ) {
          const { Passengers, ShoppingBasketId } = dataRefund.ways[0];

          const ProductCodes = [];

          Passengers.forEach((item, key) => {
            if (selectedPassengers[key]) {
              ProductCodes.push(item.ProductCode);
            }
          });

          setIsSecondCalculatedCall(true);

          calculatedReturn({
            variables: {
              ProductCodes,
              ShoppingBasketId,
              lang: language,
              refundPaymentMethod: 'Voucher',
            },
          });
          return;
        }

        if (returnInfo.RefundableProducts[0].RefundingScopes.length === 1) {
          goToStep(4);
        } else {
          goToStep(3);
        }
      }
    },
  });

  const updatePassengers = number => {
    setSelectedPassengers(prev => {
      prev[number] = !prev[number];

      const selectedPassengersCodes = [];

      Passengers.forEach((item, key) => {
        if (prev[key]) {
          selectedPassengersCodes.push(item);
        }
      });

      setDataRefund(prev => {
        return {
          ...prev,
          selectedPassengersCodes,
        };
      });

      return [...prev];
    });
  };

  useEffect(() => {
    const selectedPassengersCodes = [];

    Passengers.forEach((item, key) => {
      if (selectedPassengers[key]) {
        selectedPassengersCodes.push(item);
      }
    });

    setDataRefund(prev => {
      return {
        ...prev,
        selectedPassengersCodes,
      };
    });
  }, []);

  return (
    <div className={cn(s.step, s.choosePassengers)}>
      <div className={s.content}>
        <h3>{t('refund tickets')}</h3>
        <p>{t('please, choose passanger to continue')}</p>
        <form className={s.form}>
          <div className={s.passengers}>
            {Passengers.map((pass, key) => {
              return (
                <div
                  key={pass.ProductCode}
                  className={cn(
                    s.passenger,
                    selectedPassengers[key] && s.selected
                  )}
                  onClick={() => updatePassengers(key)}
                >
                  <div className={s.check} />
                  {pass.PassengerName}
                </div>
              );
            })}
          </div>

          <div className={s.controls}>
            <Button
              disabled={loading || selectedPassengers.every(i => !i)}
              className={s.button}
              text={loading ? t('loading') : t('continue')}
              onClick={() => {
                const ProductCodes = [];
                selectedPassengers.forEach((isSelect, key) => {
                  if (isSelect) {
                    ProductCodes.push(Passengers[key].ProductCode);
                  }
                });

                calculatedReturn({
                  variables: {
                    ProductCodes,
                    ShoppingBasketId: dataRefund.ways[0].ShoppingBasketId,
                    lang: language,
                    refundPaymentMethod: 'Default',
                  },
                });
              }}
            />
            <div
              className={s.cancel}
              disabled={loading}
              onClick={
                isPublic
                  ? () => history.push(`/${language}/manage-booking/trips/`)
                  : prevStep
              }
            >
              {t('cancel')}
            </div>
          </div>
        </form>
      </div>
      <Info dataRefund={dataRefund} />
    </div>
  );
};

export default withRouter(withTranslation()(ChoosePassengers));
