import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import s from './label.module.scss';

const Label = ({
  t,
  field: { value = '', placeholder = '', fieldName = '', maxLength = 0 } = {},
}) => (
  <div className={cn(s.label, value.length > maxLength && s.error)}>
    <span className={s.labelTitle}>{`${t(placeholder.toLowerCase())}: `}</span>
    <span className={s.amount}>{value.length}</span>
    <span className={s.maxAmount}>{` / ${maxLength}`}</span>
  </div>
);

export default withTranslation()(Label);
