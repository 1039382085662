export const emptyLounge = {
  alt: {
    value: '',
    fieldName: 'alt',
    placeholder: 'Alt',
    maxLength: 30,
  },
  img: {
    value: '',
    fieldName: 'img',
    placeholder: 'Slide',
    name: '',
    size: {
      height: 720,
      width: 1140,
    },
  },
  title: {
    value: '',
    fieldName: 'title',
    placeholder: 'Title',
    maxLength: 100,
  },
  subTitle: {
    value: '',
    fieldName: 'subTitle',
    placeholder: 'Sub title',
    maxLength: 500,
  },
  quote: {
    value: '',
    fieldName: 'quote',
    placeholder: 'Quote',
    maxLength: 100,
  },
};
