import React, { useState, useContext, useEffect } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { useQuery } from '@apollo/react-hooks';
import {
  Container,
  Divider,
  PageLoading,
  Button,
  Link,
  Text,
  Tabs,
  Tab,
  Editor,
  TabsPanel,
  TabContent,
  TabContainer,
  UploadImages,
  Tooltip,
  TooltipInput,
  EditorField,
  LazyLoadImage,
} from '@lux/ui';
import { countries } from '@lux/spa/config';
import { meta } from '@lux/spa/components';
import { NotFound } from '@lux/spa/routes';
import {
  GET_STATIC_PAGE,
  GET_STATIC_PAGE_ADMIN,
} from '@lux/spa/apollo/queries';
import { UPDATE_STATIC_PAGE } from '@lux/spa/apollo/mutations';
import i from '@lux/spa/styles/icons.module.scss';
import { emptyData, defaultData } from './constants-new';
import s from './contacts.module.scss';

const ContactsContent = ({
  t,
  slugs,
  region,
  language,
  updatePage,
  content,
  lang,
  isEditMode,
  isStaff,
  className,
}) => {
  const { isLapUp } = useContext(WidthContext);
  const [isBannerModal, setIsBannerModal] = useState(false);
  const [isTooltipModal, setIsTooltipModal] = useState(false);

  const toggleModal = () => {
    setIsBannerModal(!isBannerModal);
  };

  const toggleTooltipModal = () => {
    setIsTooltipModal(!isTooltipModal);
  };

  const { pageData = {}, seo = {} } = content;

  const { banner, title } = pageData;

  const backgroundImage = banner.value
    ? `linear-gradient(90deg, #000000d9, transparent), url(${banner.value})`
    : `linear-gradient(90deg, #000000d9, transparent)`;

  const links = [
    {
      text: t('booking, payment'),
      order: 4,
    },
    {
      text: t('change, refund'),
      order: 1,
    },
    {
      text: t('campaigns, vouchers'),
      order: 5,
    },
    {
      text: `${t('travelling with')}...`,
      order: 6,
    },
    {
      text: t('lost your belongings?'),
      order: 2,
    },
    {
      text: t('general feedback'),
      order: 3,
    },
  ];

  return (
    <div className={cn(className, s.contacts)}>
      <Helmet {...meta({ seo, lang: language, slugs })} />
      {isBannerModal && (
        <UploadImages
          isOpen={isBannerModal}
          toggleModal={toggleModal}
          updatePage={updatePage}
          image={banner}
        />
      )}
      <Container
        className={s.bannerContainer}
        style={{
          backgroundImage,
        }}
      >
        <Container full={false}>
          <section className={s.banner}>
            {isEditMode && (
              <Button
                className={s.uploadBanner}
                text={banner.value ? t('edit photo') : t('upload photo')}
                onClick={toggleModal}
              />
            )}
            <h1>
              <Text onChange={updatePage} field={title} />
            </h1>
          </section>
        </Container>
      </Container>
      <Divider />
      <Container className={s.information}>
        <Container full={false}>
          <section>
            <Tabs className={s.tabs}>
              <TabsPanel>
                <Tab className={s.tab} text={t('Russia')} />
              </TabsPanel>
              <TabContainer>
                {Object.entries({ ru: 'Russia' }).map(([code, country]) => (
                  <TabContent key={country}>
                    <h2 className={s.title}>
                      <Text
                        onChange={updatePage}
                        field={pageData[`${code}_heading`]}
                      />
                    </h2>
                    <h5 className={s.subTitle}>
                      <Text
                        onChange={updatePage}
                        field={pageData[`${code}_text1`]}
                      />
                    </h5>
                    <div className={s.links}>
                      {links.map(({ order, text }, key) => {
                        const link = pageData[`${code}_link${key + 1}`].value;
                        return (
                          (isEditMode || link) && (
                            <TooltipInput
                              // contentClass={s.contentClass}
                              className={s.contentClass}
                              style={{ order }}
                              onChange={e =>
                                updatePage({
                                  [pageData[`${code}_link${key + 1}`]
                                    .fieldName]: {
                                    ...pageData[`${code}_link${key + 1}`],
                                    value: e.target.value,
                                  },
                                })
                              }
                              field={pageData[`${code}_link${key + 1}`]}
                              target={
                                isEditMode ? (
                                  <div className={s.item}>
                                    <LazyLoadImage
                                      src={`/images/svg/contacts-${key +
                                        1}.svg`}
                                      alt=""
                                    />
                                    <h5>{text}</h5>
                                  </div>
                                ) : (
                                  <Link to={link} className={s.item}>
                                    <LazyLoadImage
                                      src={`/images/svg/contacts-${key +
                                        1}.svg`}
                                      alt=""
                                    />
                                    <h5>{text}</h5>
                                  </Link>
                                )
                              }
                            />
                          )
                        );
                      })}
                    </div>
                    <div className={s.info}>
                      {(isEditMode || pageData[`${code}_phone`].value) && (
                        <div className={s.block}>
                          <div className={s.key}>
                            {t('info phone')}
                            {isLapUp &&
                              (pageData[`${code}EditorTooltip`] ||
                                isEditMode) &&
                              (isEditMode ? (
                                <>
                                  <EditorField
                                    title={t('Edit tooltip')}
                                    updatePage={updatePage}
                                    field={pageData[`${code}EditorTooltip`]}
                                    fieldName={`${code}EditorTooltip`}
                                    toggleModal={toggleTooltipModal}
                                    isOpen={isTooltipModal}
                                  />
                                  <i
                                    className={cn(i.infoCircle, s.infoCircle)}
                                    onClick={toggleTooltipModal}
                                  />
                                </>
                              ) : (
                                <Tooltip
                                  className={s.tooltip}
                                  contentClass={s.tooltipContent}
                                >
                                  <Editor
                                    className={s.editorTooltip}
                                    // placeholder={t("description")}
                                    html={pageData[`${code}EditorTooltip`]}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      updatePage({
                                        [`${code}EditorTooltip`]: data,
                                      });
                                    }}
                                  />
                                </Tooltip>
                              ))}
                          </div>
                          <h5 className={s.value}>
                            <Text
                              onChange={updatePage}
                              field={pageData[`${code}_phone`]}
                            />
                          </h5>
                          {!isLapUp && pageData[`${code}EditorTooltip`] && (
                            <Editor
                              className={s.editorTooltip}
                              placeholder={t('description')}
                              html={pageData[`${code}EditorTooltip`]}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                updatePage({
                                  [`${code}EditorTooltip`]: data,
                                });
                              }}
                            />
                          )}
                        </div>
                      )}
                      {(isEditMode || pageData[`${code}_email`].value) && (
                        <div className={s.block}>
                          <div className={s.key}>{t('email')}</div>
                          <h5 className={s.value}>
                            <Text
                              onChange={updatePage}
                              field={pageData[`${code}_email`]}
                            />
                          </h5>
                        </div>
                      )}
                    </div>
                    <address>
                      <Editor
                        placeholder={t('description')}
                        html={pageData[`${code}_editor`]}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          updatePage({
                            [`${code}_editor`]: data,
                          });
                        }}
                      />
                    </address>
                  </TabContent>
                ))}
              </TabContainer>
            </Tabs>
          </section>
        </Container>
      </Container>
    </div>
  );
};

const Contacts = ({
  t,
  lang,
  match,
  i18n,
  isStaff,
  region,
  isEditMode,
  page = { en: defaultData },
  dispatch = () => {},
  ...props
}) => {
  const slug = 'contacts';
  const { language } = i18n;
  const QUERY = isStaff ? GET_STATIC_PAGE_ADMIN : GET_STATIC_PAGE;

  const variables = {
    slug,
    ...(isStaff
      ? {}
      : {
          lang: language,
        }),
    country: region,
  };

  const { data, loading } = useQuery(QUERY, { variables });

  const updatePage = field => {
    dispatch({
      type: 'UPDATE_DATA',
      payload: {
        field,
      },
    });
  };

  useEffect(() => {
    if (isStaff) {
      dispatch({
        type: 'UPDATE',
        payload: {
          defaultData,
          emptyData,
          // CREATE_PAGE: CREATE_STATIC_PAGE,
          UPDATE_PAGE: UPDATE_STATIC_PAGE,
          originalPage: data?.adminStaticPage,
          page: data?.adminStaticPage,
        },
      });
    }
  }, [data]);

  useEffect(() => {
    const isExistLang = page?.[lang];

    if (isEditMode && !isExistLang) {
      dispatch({
        type: 'UPDATE_PAGE',
        payload: {
          [lang]: {
            ...emptyData,
            lang,
            countries: [region],
          },
        },
      });
    }
  }, [isEditMode]);

  const isExistLangForPage = !!page?.[lang];

  const content = isStaff
    ? {
        ...defaultData,
        ...page?.[lang],
      }
    : data?.staticPage?.data;

  const slugs = {
    en: {
      value: slug,
    },
    et: {
      value: slug,
    },
    lv: {
      value: slug,
    },
    lt: {
      value: slug,
    },
    ru: {
      value: slug,
    },
  };

  return loading ? (
    <PageLoading />
  ) : content || isStaff ? (
    <ContactsContent
      slugs={slugs}
      content={{
        ...content,
        seo: {
          ...content.seo,
          slug: {
            ...content.seo.slug,
            value: slug,
          },
        },
        pageData: {
          ...defaultData.pageData,
          ...content.pageData,
        },
      }}
      {...{ updatePage, language, isEditMode, isStaff, region, t }}
      {...props}
    />
  ) : (
    <NotFound />
  );
};

export default withTranslation()(Contacts);
