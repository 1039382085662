import gql from 'graphql-tag';

export const GET_LOYALTY_INFO = gql`
  query loyaltyInfo {
    loyaltyInfo {
      email
      isFrequentTraveller
      expiryDate
      tripCount
      freeTicketTripsNeeded
    }
  }
`;
