import React, { useEffect } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { slugify } from 'transliteration';
import { langs } from '@lux/spa/i18n';
import { Query } from 'react-apollo';
import { IS_SLUG_UNIQUE, GET_SUGGESTIONS } from '@lux/spa/apollo/queries';
import { countries } from '@lux/spa/config';
import { useQuery } from '@apollo/react-hooks';
import {
  Button,
  Input,
  Switch,
  Select,
  LazyLoadImage,
  SelectOption,
  Loading,
} from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import Label from './label';
import CustomFields from './custom-fields';
import s from './control-panel.module.scss';

const Suggestions = ({ t, search, suggestionsRef, selectCity }) => (
  <Query
    query={GET_SUGGESTIONS}
    variables={{ cityName: search }}
    skip={search.lenght < 3}
  >
    {({ loading, error, data = { cities: [] }, refetch }) => {
      if (loading)
        return (
          <div className={s.suggestions} ref={suggestionsRef}>
            <Loading className={s.loading} />
          </div>
        );
      return (
        <div className={s.suggestions} ref={suggestionsRef}>
          <div className={s.wrap}>
            <div className={s.triangle} />
          </div>
          {data.cities.length ? (
            data.cities.map(({ CityId, CityName }) => (
              <div
                className={s.townCountry}
                key={CityId}
                onClick={() => selectCity({ name: CityName, id: CityId })}
              >
                <i className={i.point} />
                {CityName}
              </div>
            ))
          ) : (
            <span>
              {t('no matches for')} «<b>{search}</b>»
            </span>
          )}
        </div>
      );
    }}
  </Query>
);

const Field = ({
  t,
  children,
  lang,
  pageName,
  disabled,
  field = {},
  updateSeo,
  toggleCustonSlug,
  slug = false,
  isCustomSlug = false,
  dispatch,
  originSlug,
}) => {
  const { value = '', placeholder = '', fieldName = '', maxLength = 0 } = field;

  const {
    called = false,
    data: { isUniqueSlug = true } = {},
    loading,
  } = useQuery(IS_SLUG_UNIQUE, {
    variables: {
      objectType: pageName != 'promo' ? pageName : 'promo_page',
      lang,
      slug: value,
    },
    skip: !value || !slug || originSlug == value,
  });

  useEffect(() => {
    if (slug) {
      dispatch({
        type: 'UPDATE',
        payload: {
          hasError: loading || !value || !isUniqueSlug,
        },
      });
    }
  }, [value, loading, isUniqueSlug]);

  return slug ? (
    <div className={cn(s.field, s.slug)}>
      <div className={s.inputWithSwitcher}>
        <Input
          label={<Label t={t} field={field} />}
          disabled={!isCustomSlug || disabled}
          value={value}
          className={s.input}
          onChange={e => {
            updateSeo({
              [fieldName]: {
                ...field,
                value: e.target.value.replace(' ', '-'),
              },
            });
          }}
        />
        <Switch
          className={s.switch}
          checked={isCustomSlug}
          onChange={toggleCustonSlug}
        />
        {originSlug != value &&
          (value ? (
            <p className={cn(s.message, !isUniqueSlug && s.error)}>
              {loading ? (
                <Loading />
              ) : value && isUniqueSlug ? (
                t('this slug is unique')
              ) : (
                t('this slug is not unique')
              )}
            </p>
          ) : (
            <p className={cn(s.message, !value && s.error)}>
              {t('slug is empty')}
            </p>
          ))}
      </div>
      <div className={s.explanation}>{t('Based on the page title')}</div>
    </div>
  ) : (
    <div className={s.field}>
      {children}
      <Input
        label={<Label t={t} field={field} />}
        value={value}
        disabled={disabled}
        className={s.input}
        onChange={e =>
          updateSeo({
            [fieldName]: {
              ...field,
              value: e.target.value,
            },
          })
        }
      />
      {/* <div className={s.explanation}>
        Swith off to remove from all public resources
      </div> */}
    </div>
  );
};

const ControlPanel = ({
  t,
  i18n,
  lang,
  loading,
  region: currentRegion,
  multi,
  isStatic,
  pageName,
  className,
  state,
  dispatch,
  originSlug,
}) => {
  const { language } = i18n;

  const { copyFrom, emptyData, defaultData, isCustomSlug } = state;

  const isDataExist = !!state.page;

  const page = isDataExist
    ? {
        ...state.page,
      }
    : {
        en: {
          ...emptyData,
        },
      };

  // if (!isDataExist) {
  //   dispatch({
  //     type: 'UPDATE_PAGE',
  //     payload: {
  //       ...page
  //     }
  //   })
  // }

  const isPublished = isDataExist
    ? page?.[lang]?.isPublished
    : emptyData?.isPublished;

  const {
    title = '',
    slug,
    description,
    socialTitle,
    socialDescription,
    pageKeywords,
    thumbnail,
    socialImage,
  } = page?.[lang]?.seo || emptyData?.seo;

  const existingsTranslates = Object.keys(page).filter(
    lang => langs.includes(lang) && Object.keys(page[lang] || {})?.length !== 0
  );

  const updatePublished = () => {
    dispatch({
      type: 'UPDATE_LANG',
      payload: {
        isPublished: !isPublished,
      },
    });
  };

  const updateSeo = field => {
    if (isStatic) {
      dispatch({
        type: 'UPDATE_SEO',
        payload: {
          ...field,
        },
      });
    } else {
      const isTitle = Object.keys(field)[0] === 'title';
      const isSlug = Object.keys(field)[0] === 'slug';

      dispatch({
        type: 'UPDATE_SEO',
        payload: {
          ...(isTitle && !isCustomSlug
            ? {
                slug: {
                  ...slug,
                  value: slugify(field.title.value),
                },
                ...field,
              }
            : {
                ...field,
              }),
        },
      });

      if (isSlug) {
        dispatch({
          type: 'UPDATE_LANG',
          payload: {
            slug: slugify(field.slug.value),
          },
        });
      }

      if (isTitle && !isCustomSlug) {
        dispatch({
          type: 'UPDATE',
          payload: {},
        });
        dispatch({
          type: 'UPDATE_LANG',
          payload: {
            slug: slugify(field.title.value),
          },
        });
      }
    }
  };

  const toggleCustonSlug = () => {
    dispatch({
      type: 'UPDATE',
      payload: {
        isCustomSlug: !isCustomSlug,
      },
    });
  };

  const changeTab = lang => {
    const isLangExist = !!state.page?.[lang];
    // i18n.changeLanguage(lang)

    dispatch({
      type: 'UPDATE',
      payload: {
        lang,
      },
    });

    if (!isLangExist) {
      dispatch({
        type: 'UPDATE_LANG',
        payload: {
          ...(isStatic
            ? {
                ...emptyData,
                countries: [currentRegion],
              }
            : {
                ...emptyData,
              }),
        },
      });
    }
  };

  const updateCountries = country => {
    dispatch({
      type: 'UPDATE_COUNTRIES',
      payload: {
        lang: 'en',
        country,
      },
    });
  };

  const selectCopyFrom = copyFrom => {
    dispatch({
      type: 'UPDATE',
      payload: {
        copyFrom,
      },
    });
  };

  const applyCopyFrom = () => {
    const data =
      copyFrom === 'empty'
        ? emptyData
        : copyFrom === 'default'
        ? defaultData
        : state.page[copyFrom];

    dispatch({
      type: 'UPDATE_LANG',
      payload: {
        ...data,
      },
    });
  };

  useEffect(() => {
    if (lang !== language) {
      changeTab(language);
    }
  }, [language]);

  const availableCountries = page?.[lang]?.countries || [];

  return (
    <div className={cn(s.controlPanel, className)}>
      {/* {pageName == 'route' && (
        <SearchDestinationMobile
          search={field == 'to' ? to.search : field == 'from' ? from.search : ''}
          isOpen={isOpenSuggMob}
          toggleModal={toggleSuggModal}
          modalTitle={field}
          {...{ selectItem, updateSearch }}
        />
      )} */}
      {/* {isStatic && (
        <div className={s.panel}>
          {Object.keys(countries).map(country => (
        <Link
        to={`/${lang}-${country}/`}
        key={country}
        className={cn(s.tab, currentRegion == country && s.active)}
        children={countries[country]}
        />
          ))}
        </div>
      )} */}
      <div className={s.tabs}>
        <div className={s.panel}>
          {langs.map(l => (
            <div
              key={l}
              className={cn(s.tab, l === lang && s.active)}
              onClick={() => changeTab(l)}
            >
              {l}
              <span className={s.check}>
                {existingsTranslates.includes(l) && (
                  <LazyLoadImage src="/images/svg/checked.svg" alt="" />
                )}
              </span>
            </div>
          ))}
        </div>
        {loading ? (
          <div>
            <h5>Panel loading..</h5>
            <Loading />
          </div>
        ) : (
          <div className={s.tabContent}>
            <fieldset>
              <div className={cn(s.field, s.template)}>
                <div className={s.title}>{t('template')}</div>
                <Select
                  withoutMessage
                  value={copyFrom}
                  className={s.select}
                  onChange={e => selectCopyFrom(e.target.value)}
                >
                  <SelectOption value="empty" text={t('empty')} />
                  <SelectOption value="default" text={t('default')} />
                  {existingsTranslates.map(
                    item =>
                      item != lang && (
                        <SelectOption key={item} value={item} text={item} />
                      )
                  )}
                </Select>
                <Button
                  className={s.button}
                  onClick={() => applyCopyFrom(lang)}
                  children={t('prefill')}
                />
                {/* <div className={s.explanation}>
                  Swith off to remove from all public resources
                </div> */}
              </div>
            </fieldset>
            <fieldset>
              <legend>{t('settings')}</legend>
              <div className={s.switches}>
                <div className={s.switchItem}>
                  <span>{t('published')}</span>
                  <Switch checked={isPublished} onChange={updatePublished} />
                </div>
              </div>
            </fieldset>
            {false && (
              <fieldset>
                <legend>{t('cms.country.availability')}</legend>
                <div className={s.switches}>
                  {Object.keys(countries).map(country => {
                    return (
                      <div className={s.switchItem} key={country}>
                        <span>{countries[country]}</span>
                        <Switch
                          checked={!!availableCountries.includes(country)}
                          onChange={() => updateCountries(country)}
                        />
                      </div>
                    );
                  })}
                </div>
              </fieldset>
            )}
            {!isStatic && (
              <fieldset>
                <legend>{`${pageName} ${t('fields')}`}</legend>
                <CustomFields
                  pageName={pageName}
                  dispatch={dispatch}
                  state={state}
                  updateSeo={updateSeo}
                  existingsTranslates={existingsTranslates}
                />
              </fieldset>
            )}
            <fieldset>
              <legend>{t('seo')}</legend>
              {/* {!isStatic && ( */}
              <Field
                t={t}
                updateSeo={updateSeo}
                field={title}
                dispatch={dispatch}
              />
              {/* )} */}
              {/* {!isStatic && (
                pageName == 'destination' && (
                  <SearchField
                t={t}
                updateSeo={updateSeo}
                field={title}
                  />
                )
              )} */}
              {!isStatic && (
                <Field
                  t={t}
                  field={slug}
                  slug
                  lang={lang}
                  originSlug={originSlug}
                  dispatch={dispatch}
                  pageName={pageName}
                  updateSeo={updateSeo}
                  isCustomSlug={isCustomSlug}
                  toggleCustonSlug={toggleCustonSlug}
                />
              )}
              <Field
                t={t}
                updateSeo={updateSeo}
                field={description}
                dispatch={dispatch}
              />
              <Field
                t={t}
                updateSeo={updateSeo}
                field={socialTitle}
                dispatch={dispatch}
              />
              <Field
                t={t}
                updateSeo={updateSeo}
                field={socialDescription}
                dispatch={dispatch}
              />
              <Field
                t={t}
                updateSeo={updateSeo}
                field={pageKeywords}
                dispatch={dispatch}
              />
            </fieldset>
            {/* <fieldset>
              <legend>Images</legend>
              <div className={s.images}>
                <div className={cn(s.field, s.dropzone)}>
              <div className={s.title}>
              Thumbnail
              <div className={s.info}>
              390x300
              </div>
              </div>
              <Dropzone
              className={cn(s.dropzone, thumbnail && s.withImage)}
              onDrop={(file, height, width) => {
              updateAtricle(lang, { thumbnail: file })
              }}
              >
              {thumbnail ? (
              <div
              className={s.withImage}
              style={{ backgroundImage: `url(${thumbnail})` }}
              >
              <div className={s.action}>
              <LazyLoadImage src="/images/svg/upload.svg" alt="" />
              </div>
              <div
              className={s.action}
              onClick={e => {
              e.stopPropagation();
              updateAtricle(lang, { thumbnail: '' })
              }}>
              <LazyLoadImage src="/images/svg/basket.svg" alt="" />
              </div>
              </div>
              ) : (
              <div className={s.empty} style={{ backgroundImage: `url(/images/svg/empty-dropzone.svg)` }}>
              <div className={s.action}>
              <LazyLoadImage src="/images/svg/upload.svg" alt="" />
              </div>
              </div>
              )}
              </Dropzone>
                </div>
                <div className={cn(s.field, s.dropzone)}>
              <div className={s.title}>
              Social Image
              <div className={s.info}>
              1200x1200
              </div>
              </div>
              <Dropzone
              className={cn(s.dropzone, socialImage && s.withImage)}
              onDrop={file => updateAtricle(lang, { socialImage: file })}
              >
              {socialImage ? (
              <div
              className={s.withImage}
              style={{ backgroundImage: `url(${socialImage})` }}
              >
              <div className={s.action}>
              <LazyLoadImage src="/images/svg/upload.svg" alt="" />
              </div>
              <div
              className={s.action}
              onClick={e => {
              e.stopPropagation();
              updateAtricle(lang, { socialImage: '' })
              }}>
              <LazyLoadImage src="/images/svg/basket.svg" alt="" />
              </div>
              </div>
              ) : (
              <div
              className={s.empty}
              style={{
              backgroundImage: `url(/images/svg/empty-dropzone.svg)`
              }}
              >
              <div className={s.action}>
              <LazyLoadImage src="/images/svg/upload.svg" alt="" />
              </div>
              </div>
              )}
              </Dropzone>
                </div>
              </div>
            </fieldset> */}
            {/* </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(ControlPanel);
