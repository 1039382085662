import React, { Children, cloneElement } from 'react';
import cx from 'classnames';
import styles from './dropdown.module.scss';

const DropdownItem = ({
  className,
  children,
  disabled = false,
  onClick = () => {},
  callback = () => {},
  isOpen,
}) => {
  const childrens = Children.toArray(children);
  return (
    <div
      onClick={() => {
        if (!disabled) {
          onClick();
          callback();
        }
      }}
      disabled={disabled}
      className={cx(styles.option, className)}
    >
      {/* {children} */}
      {childrens.map(child =>
        cloneElement(child, {
          isOpen,
        })
      )}
    </div>
  );
};

export default DropdownItem;
