import React, { useState, useEffect, useContext, useRef } from 'react';
import cn from 'classnames';
import { Query } from 'react-apollo';
import differenceInDays from 'date-fns/differenceInDays';
import isValid from 'date-fns/isValid';
import { withTranslation, Trans } from 'react-i18next';
import { StoreContext } from '@lux/spa/store';
import { Container, Notice, Divider, Loading } from '@lux/ui';
import { GET_LOYALTY_INFO } from '@lux/spa/apollo/queries';
import { ProfileMenu } from '@lux/spa/components';
import s from './customer-program.module.scss';

const ProgressBar = ({
  size,
  text,
  progress,
  max = 100,
  strokeWidth,
  circleOneStroke,
  circleTwoStroke,
  className,
}) => {
  const [offset, setOffset] = useState(0);
  const circleRef = useRef(null);

  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    const progressOffset =
      progress >= max ? 0 : ((max - progress) / max) * circumference;
    setOffset(progressOffset);

    circleRef.current.style = 'transition: stroke-dashoffset 850ms ease-in-out';
  }, [setOffset, progress, circumference, offset]);

  return (
    <div className={s.circle}>
      <div className={s.text}>{text}</div>
      <svg className={cn(s.svg, className)} width={size} height={size}>
        <circle
          className={s.svgCircleBg}
          stroke={circleOneStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className={s.svgCircle}
          ref={circleRef}
          stroke={circleTwoStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
      </svg>
    </div>
  );
};

const CustomerProgram = ({ t, className }) => {
  const [state] = useContext(StoreContext);
  const { user } = state;
  const { firstName } = user;
  const MAX_TICKETS_THIS_YEAR = 30;
  const TICKETS_TRIPS_NEEDED = 10;

  return (
    <div className={cn(className, s.customerProgram)}>
      <Container>
        <Container full={false}>
          <section>
            <h2>{t('customer program')}</h2>
            <div className={s.block}>
              <ProfileMenu />
              <div className={s.content}>
                <Query query={GET_LOYALTY_INFO}>
                  {({ data, loading, error }) => {
                    if (loading)
                      return (
                        <div className={s.loading}>
                          <Loading />
                        </div>
                      );

                    const {
                      email,
                      expiryDate,
                      freeTicketTripsNeeded,
                      isFrequentTraveller,
                      tripCount,
                    } = data?.loyaltyInfo;

                    const days =
                      expiryDate && isValid(new Date(expiryDate))
                        ? differenceInDays(new Date(expiryDate), new Date())
                        : '';

                    return (
                      <>
                        <div className={s.data}>
                          <div className={s.dataBlock}>
                            <ProgressBar
                              className={s.progressBar}
                              strokeWidth={2}
                              size={50}
                              circleTwoStroke="#e70707"
                              circleOneStroke="#f0f0f1"
                              max={MAX_TICKETS_THIS_YEAR}
                              progress={tripCount}
                              text={tripCount}
                            />
                            <div className={s.info}>
                              <div className={s.title}>{t('trips taken')}</div>
                              <div className={s.subTitle}>
                                {t('during the current year')}
                              </div>
                            </div>
                          </div>
                          <div className={s.dataBlock}>
                            <ProgressBar
                              className={s.progressBar}
                              strokeWidth={2}
                              size={50}
                              circleTwoStroke="#e70707"
                              circleOneStroke="#f0f0f1"
                              max={TICKETS_TRIPS_NEEDED}
                              progress={
                                freeTicketTripsNeeded > TICKETS_TRIPS_NEEDED
                                  ? 0
                                  : TICKETS_TRIPS_NEEDED - freeTicketTripsNeeded
                              }
                              text={freeTicketTripsNeeded}
                            />
                            <div className={s.info}>
                              <div className={s.title}>
                                {t('tickets to be colected')}
                              </div>
                              <div className={s.subTitle}>
                                {t('to be eligible for free ticket')}
                              </div>
                            </div>
                          </div>
                        </div>
                        {isFrequentTraveller && (
                          <div className={s.details}>
                            <Divider absolute vertical height={4} />
                            <div className={s.left}>
                              <div className={s.head}>
                                <span>{firstName}</span>
                                {', '}
                                {t('you are Lux Express Frequent Traveler')}
                              </div>
                              {days && (
                                <div className={s.info}>
                                  <div className={s.days}>{days}</div>
                                  <div className={s.title}>
                                    {t('days left to renewal')}
                                  </div>
                                  <div className={s.subTitle}>
                                    {t('frequent Traveler Status')}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className={s.right}>
                              <div className={s.item}>
                                <div className={s.img}>
                                  <img
                                    className={s.check}
                                    src="/images/svg/check.svg"
                                    alt=""
                                  />
                                  <img src="/images/svg/ticket.svg" alt="" />
                                </div>
                                <div className={s.info}>
                                  <div className={s.title}>
                                    {t('count discount', {
                                      count: '20%',
                                    })}
                                  </div>
                                  <div className={s.subTitle}>
                                    {t('from Comfort class tickets')}
                                  </div>
                                </div>
                              </div>
                              <div className={s.item}>
                                <div className={s.img}>
                                  <img
                                    className={s.check}
                                    src="/images/svg/check.svg"
                                    alt=""
                                  />
                                  <img src="/images/svg/ticket.svg" alt="" />
                                </div>

                                <div className={s.info}>
                                  <div className={s.title}>
                                    {t('count discount', {
                                      count: '10%',
                                    })}
                                  </div>
                                  <div className={s.subTitle}>
                                    {t('from Standard class tickets')}
                                  </div>
                                </div>
                              </div>
                              <div className={s.item}>
                                <div className={s.img}>
                                  <img
                                    className={s.check}
                                    src="/images/svg/check.svg"
                                    alt=""
                                  />
                                  <img
                                    src="/images/svg/free-bottle-of-water.svg"
                                    alt=""
                                  />
                                </div>

                                <div className={s.info}>
                                  <div className={s.title}>
                                    {t('bottle of water')}
                                  </div>
                                  <div className={s.subTitle}>
                                    {t(
                                      'complimentary for you in all of our trips'
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {freeTicketTripsNeeded === 0 && (
                          <div className={s.notification}>
                            <div className={s.label}>
                              {t('trips collected', {
                                count: 10,
                              })}
                            </div>
                            <div className={s.title}>
                              {t(
                                'congratulations! You are eligible for 1 free ticket'
                              )}
                            </div>
                            <div className={s.description}>
                              {t('customer.program.notification', {
                                email,
                              })}
                            </div>
                          </div>
                        )}
                        <div className={s.notes}>
                          <Notice
                            className={s.notice}
                            text={t('customer.program.info1')}
                          />
                          <Notice
                            className={s.notice}
                            text={t('customer.program.info2')}
                          />
                        </div>
                      </>
                    );
                  }}
                </Query>
              </div>
            </div>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(CustomerProgram);
