import gql from 'graphql-tag';

export const MODIFICATION_PAYABLE_AMOUNT = gql`
  query ModificationPayableAmountType {
    modificationPayableAmount {
      ShoppingBasketId
      CurrencyOfModification
      ModificationCost
      PaymentProcessId
      PayableAmount
      Payments {
        PaymentId
        PaymentType
        PaidAmount
        VoucherCode
      }
    }
  }
`;

export const MODIFICATION_DETAILS = gql`
  query ModificationDetailsType {
    modificationDetails {
      CurrencyOfModification
      ShoppingBasketId
      State
      ModificationCost
      ChangedSeats {
        ProductCode
        IsOutbound
        LegOrderNumber
        IsAutomaticallyAssigned
        SeatInfo {
          OrderNumber
          SeatNumber
          SeatNumberLetter
          SeatClassCategory
          SeatClassCategoryName
          SeatClass
          SeatClassName
          SeatPrice
        }
      }
    }
  }
`;
