import React from 'react';
import RouteFields from './route-fields';
import DestinationFields from './destination-fields';
import NewsFields from './news-fields';
import PromoFields from './promo-fields';
// import StaticFields from './static-fields'

const CustomFields = ({ className, pageName, ...props }) => {
  const Fields = () => {
    switch (pageName) {
      case 'route':
        return <RouteFields {...props} />;
      case 'destination':
        return <DestinationFields {...props} />;
      case 'news':
        return <NewsFields {...props} />;
      case 'promo':
        return <PromoFields {...props} />;
      default:
        // return <StaticFields />
        return null;
    }
  };

  return <Fields />;
};

export default CustomFields;
