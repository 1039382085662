import gql from 'graphql-tag';

export const GET_ROUTE_ADMIN = gql`
  query($lang: String!, $slug: String!, $country: String) {
    adminRoute(lang: $lang, slug: $slug, country: $country) {
      id
      isPublished
      departure {
        id
        name
      }
      arrival {
        id
        name
      }
      # slugs
      en {
        seo
        lang
        slug
        countries
        pageData
        isPublished
      }
      fi {
        seo
        lang
        slug
        countries
        pageData
        isPublished
      }
      et {
        seo
        lang
        slug
        countries
        pageData
        isPublished
      }
      ru {
        seo
        lang
        slug
        countries
        pageData
        isPublished
      }
      lv {
        seo
        lang
        slug
        countries
        pageData
        isPublished
      }
      lt {
        seo
        lang
        slug
        countries
        pageData
        isPublished
      }
    }
  }
`;

export const GET_ROUTE = gql`
  query($lang: String!, $slug: String!, $country: String) {
    route(lang: $lang, slug: $slug, country: $country) {
      id
      slugs
      departure {
        id
        name
      }
      arrival {
        id
        name
      }
      data {
        seo
        lang
        slug
        countries
        pageData
        isPublished
      }
    }
  }
`;
