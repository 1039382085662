import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import getTime from 'date-fns/getTime';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import { withTranslation } from 'react-i18next';
import s from './countdown.module.scss';

const Countdown = ({
  t,
  className,
  timestamp = 0,
  dateTo = new Date().toISOString().slice(0, 10),
  seconds = true,
  minutes = true,
  hours = true,
  days = true,
  callBack = () => {},
}) => {
  const calculateTimeLeft = () => {
    // let difference =
    //   (timestamp || getTime(new Date(dateTo?.replace(/-/g, '/')))) -
    //   getTime(new Date());

    const difference = differenceInMilliseconds(
      timestamp || dateTo,
      new Date()
    );

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        ...(days
          ? {
              days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            }
          : {}),
        ...(hours
          ? {
              hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            }
          : {}),
        ...(minutes
          ? {
              minutes: Math.floor((difference / 1000 / 60) % 60),
            }
          : {}),
        ...(seconds
          ? {
              seconds: Math.floor((difference / 1000) % 60),
            }
          : {}),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const intervals = Object.keys(timeLeft);

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  useEffect(() => {
    if (!intervals?.length) callBack();
  }, [intervals?.length]);

  // const timerComponents = [];

  // Object.keys(timeLeft).forEach(interval => {
  //   if (!timeLeft[interval]) return
  //   timerComponents.push(interval);
  // });

  return (
    <div className={cn(s.countdown, className)}>
      {!!intervals.length && (
        <>
          <div className={s.numbers}>
            {intervals.map(interval => (
              <div className={s[interval]} key={interval}>
                {timeLeft[interval]}
              </div>
            ))}
          </div>
          <div className={s.text}>
            {intervals.map(interval => (
              <div className={s[interval]} key={interval}>
                {t(interval)}
                {/* {timeLeft[interval] > 1 ? (
                  interval
                  ) : (
                  interval.slice(0, -1)
                )} */}
              </div>
            ))}
          </div>
        </>
      )}
      {/* <span>{t("Time's up!")}</span> */}
    </div>
  );
};

export default withTranslation()(Countdown);
