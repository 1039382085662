import React from 'react';
import { TouchedTicket } from '@lux/spa/components';
import { Divider } from '@lux/ui';
import { localizedDate } from 'utils';
import i from '@lux/spa/styles/icons.module.scss';
import s from './tickets.module.scss';

const Tickets = ({
  Legs,
  Passengers,
  setShowTickets,
  DepartureCityName,
  DestinationCityName,
  DepartureTime,
  isPast,
}) => {
  const passengers = Passengers || [];
  const legs = Legs || [];
  return (
    <div className={s.tickets}>
      <div className={s.header}>
        <Divider absolute height={2} />
        <h3 className={s.title}>
          <i
            className={i.arrowRight_v2}
            onClick={() => setShowTickets(prev => !prev)}
          />
          {`${DepartureCityName} - ${DestinationCityName}`}
        </h3>
        <div className={s.subTitle}>
          {localizedDate(new Date(DepartureTime.split('T')[0]), 'dd LLLL yyyy')}
        </div>
      </div>
      <div className={s.tickets}>
        {passengers.map(passenger => {
          const tickets = passenger.Tickets || [];
          return tickets.reverse().map((ticket, key) => {
            return (
              <TouchedTicket
                key={ticket.CouponNumber}
                className={s.touchedTicket}
                ticketLegs={legs}
                availableBusinessClassSeats={null}
                passenger={passenger}
                seat={passenger.Seats[key]}
                leg={legs[key]}
                campaignPrice={false}
                ticket={ticket}
                isPast={isPast}
              />
            );
          });
        })}
      </div>
    </div>
  );
};

export default Tickets;
