import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import i from '@lux/spa/styles/icons.module.scss';
import s from './input.module.scss';

const Input = ({
  icon,
  label,
  className,
  type = 'text',
  size = 'l',
  value = '',
  placeholder = '',
  ...props
}) => {
  // const [inputValue, setInputValue] = useState(value)
  // const onInput = e => {
  //   setInputValue(e.target.value)
  // }

  // useEffect(() => {
  //   setInputValue(value)
  // }, [value])

  const classes = cn(
    s.input,
    className,
    s[size],
    icon && s.withIcon,
    value && s.hasValue
    // inputValue && s.hasValue
  );

  return (
    <div className={classes}>
      <input {...props} value={value} type={type} />
      {label && <label>{label}</label>}
      {icon && <i className={i[icon]} />}
    </div>
  );
};

export default Input;
