export const initialStateSearch = {
  promocode: '',
  approvedPromo: false,
  passengers: {
    // child: 0,
    // youth: 0,
    // senior: 0,
    passengers: 1,
  },
  passengersCount: 1,
  departDate: new Date(),
  returnDate: '',
  from: {
    search: '',
    BusStopId: '',
  },
  to: {
    search: '',
    BusStopId: '',
  },
  search: '',
  field: '',
  isOpenSugg: false,
  isOpenPass: false,
  isOpenSuggMob: false,
  isOpenPassMob: false,
  isSearchValid: false,
  isValidUrl: false,
};

const searchReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_SEARCH':
      return {
        ...state,
        ...action.payload,
      };
    case 'SWAP_DIRECTIONS':
      return {
        ...state,
        to: state.from,
        from: state.to,
      };
    case 'UPDATE_PASSENGERS':
      const { operation, type } = action.payload;

      const newState = {
        ...state,
      };

      const { passengers } = newState;

      if (
        operation === '-' &&
        state.passengers[type] > 0 &&
        state.passengersCount > 1
      ) {
        passengers[type] -= 1;
        newState.passengersCount -= 1;
      }

      if (operation === '+') {
        passengers[type] += 1;
        newState.passengersCount += 1;
      }

      return newState;
    default:
      return state;
  }
};

export default searchReducer;
