import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { getCookie, removeCookie } from 'utils';
import { Mutation } from './mutations';
import { Query } from './queries';

const cache = new InMemoryCache({
  addTypename: false,
}).restore(window.__APOLLO_STATE__);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getCookie('token') || localStorage.getItem('token');
  const msi = getCookie('msi') || localStorage.getItem('msi');
  // const token = localStorage.getItem('token');
  // const msi = localStorage.getItem('msi');

  const ticketsData = JSON.parse(localStorage.getItem('ticketsData'));
  // return the headers to the context so httpLink can read them
  const basketId = ticketsData?.products?.ShoppingBasketId;

  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
      ...(basketId
        ? {
            'shopping-basket-id': basketId,
          }
        : {}),
      ...(msi
        ? {
            'modification-session-id': msi,
          }
        : {}),
    },
  };
});

const httpLink = createHttpLink({
  uri: `${DOMAIN}/graphql`,
});

const logoutLink = onError(
  ({ graphQLErrors, operation, forward, ...props }) => {
    const { networkError = {} } = props;
    const { statusCode = '' } = networkError;

    if (statusCode === 401) {
      localStorage.removeItem('token');
      removeCookie('token');

      // error code is set to UNAUTHENTICATED
      // when AuthenticationError thrown in resolver

      // modify the operation context with a new token
      const oldHeaders = operation.getContext().headers;
      operation.setContext({
        headers: {
          ...oldHeaders,
          authorization: '',
        },
      });

      // retry the request, returning the new observable

      // const url = new URL(window.location.href);
      // const isParamLoagout = url.searchParams.get('logout');

      // if (isParamLoagout !== 'true') {
      //   url.searchParams.set('logout', true);
      //   window.location.href = url.href;
      // }

      return forward(operation);
    }
  }
);

const client = new ApolloClient({
  link: logoutLink.concat(authLink.concat(httpLink)),
  cache,
  connectToDevTools: true,
  resolvers: {
    Query,
    Mutation,
  },
  ssrMode: false,
});

export default client;
