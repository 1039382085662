import gql from 'graphql-tag';

export const GET_PROFILE = gql`
  query ProfileType {
    profile {
      id
      gender
      phone
      email
      avatar
      lastName
      firstName
      isStaff
      isActive
      lastLogin
      dateJoined
      language
      currency
    }
  }
`;

export const GET_PAYMENT_METHODS = gql`
  query StoredPaymentMethodsType {
    userPaymentsMethods {
      brand
      expiryMonth
      expiryYear
      holderName
      id
      lastFour
      name
      type
    }
  }
`;
