import React, { useState, useContext } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Container, SendTickets, TaxiTransferWarning } from '@lux/ui';
import { StoreContext } from '@lux/spa/store';
import { Trip } from '@lux/spa/components';
import { NotFound } from '@lux/spa/routes';
import { Tickets } from './components';
import s from './trips.module.scss';

const PublicTrips = ({ t, className }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { modificationInfo, ticketNumber, email } = state.tickets.refund;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showTickets, setShowTickets] = useState(false);
  const [ticketsData, setTicketsData] = useState(null);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const toggleModalInfo = () => {
    setIsOpenModalInfo(!isOpenModalInfo);
  };

  return modificationInfo ? (
    <div className={cn(className, s.trips)}>
      <SendTickets
        basketId={ticketsData}
        toggleModal={toggleModal}
        isOpen={isOpenModal}
        closeModal={() => setIsOpen(false)}
      />
      {isOpenModalInfo && (
        <TaxiTransferWarning
          toggleModal={toggleModalInfo}
          isOpen={isOpenModalInfo}
        />
      )}
      <Container>
        <Container full={false}>
          <section>
            <h2>{t('manage booking')}</h2>
            <div className={s.block}>
              <div className={s.content}>
                {showTickets ? (
                  <Tickets {...ticketsData} setShowTickets={setShowTickets} />
                ) : (
                  modificationInfo.ways.map((trip, key) => {
                    const legs = trip.Legs || [];

                    return (
                      <Trip
                        isModificationAllowed={
                          trip.Passengers[0].IsModificationAllowed
                        }
                        isOpenModalInfo={isOpenModalInfo}
                        toggleModalInfo={toggleModalInfo}
                        key={key}
                        isPublic
                        // isCanceled={false}
                        isPast={false}
                        className={s.touchedTicket}
                        arrivalTime={trip.ArrivalTime}
                        departureTime={trip.DepartureTime}
                        departureStopName={trip.DepartureStopName}
                        destinationStopName={trip.DestinationStopName}
                        destinationStopId={trip.DestinationStopId}
                        departureStopId={trip.DepartureStopId}
                        legs={legs.length}
                        departureCityName={trip.DepartureCityName}
                        arrivalTimeZone={trip.ArrivalTimeZone}
                        departureTimeZone={trip.DepartureTimeZone}
                        destinationCityName={trip.DestinationCityName}
                        shoppingBasketCode={trip.ShoppingBasketCode}
                        passengers={trip.Passengers}
                        shoppingBasketId={trip.ShoppingBasketId}
                        toggleModal={toggleModal}
                        showTickets={showTickets}
                        trip={trip}
                        setShowTickets={setShowTickets}
                        setTicketsData={setTicketsData}
                      />
                    );
                  })
                )}
              </div>
              <aside>
                <header>
                  <h5>{t('booking information')}</h5>
                </header>
                <div className={s.info}>
                  <div className={s.data}>
                    <span>{t('Email')}:</span>
                    <div className={s.item}>{email}</div>
                  </div>
                  <div className={s.data}>
                    <span>{t('booking number')}:</span>
                    <div className={s.item}>{ticketNumber}</div>
                  </div>
                </div>
              </aside>
            </div>
          </section>
        </Container>
      </Container>
    </div>
  ) : (
    <NotFound />
  );
};

export default withTranslation()(PublicTrips);
