import React, { useContext } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { StoreContext } from '@lux/spa/store';
import { DEFAULT_LANG } from '@lux/spa/config';
import { PAY_WITH_VOUCHER } from '@lux/spa/apollo/mutations';
import { Modal, ModalContent, Button, Link, Notice } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './redeem-voucher.module.scss';

const RedeemVoucher = ({
  t,
  i18n,
  history,
  isOpen,
  toggleModal,
  voucherCode,
  voucherValue,
  toggleVoucher,
  setVoucher,
}) => {
  const { language } = i18n;
  const [state, dispatch] = useContext(StoreContext);
  const { vouchers, totalPrice, symbol, payment } = state.tickets;

  const [payWithVoucher, { loading }] = useMutation(PAY_WITH_VOUCHER, {
    variables: {
      VoucherCode: voucherCode,
    },
    onError: () => toast.error(t('something wrong')),
    onCompleted: data => {
      const { error } = data.payWithVoucher;

      if (error) {
        toast.error(t('something wrong'));
      } else {
        const { PaidAmount } = data.payWithVoucher.data;

        if (data.payWithVoucher.basketPayed) {
          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              vouchers: {
                ...vouchers,
                showVoucherInput: false,
                fullyСoversСost:
                  totalPrice <= vouchers.totalVouchersValue + PaidAmount,
                totalVouchersValue: vouchers.totalVouchersValue + PaidAmount,
                approved: [
                  ...vouchers.approved,
                  {
                    code: voucherCode,
                    value: PaidAmount,
                  },
                ],
              },
              payment: {
                ...payment,
                status: 'success',
                type: 'voucher',
                objects: data?.payWithVoucher?.objects,
              },
            },
          });
          setVoucher('');
          toggleModal();
          history.push(
            `/${DEFAULT_LANG === language ? '' : `${language}/`}tickets/thanks/`
          );
        } else {
          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              vouchers: {
                ...vouchers,
                showVoucherInput: false,
                fullyСoversСost:
                  totalPrice <= vouchers.totalVouchersValue + PaidAmount,
                totalVouchersValue: vouchers.totalVouchersValue + PaidAmount,
                approved: [
                  ...vouchers.approved,
                  {
                    code: voucherCode,
                    value: PaidAmount,
                  },
                ],
              },
            },
          });
          setVoucher('');
          toggleModal();
        }
      }
    },
  });

  const leftToPay = (
    totalPrice -
    vouchers.totalVouchersValue -
    voucherValue
  ).toFixed(2);
  const isFullyСoversСost = leftToPay <= 0;

  return (
    <Modal className={s.modal} {...{ isOpen, toggleModal }}>
      <ModalContent className={s.content}>
        <h2>{t('redeem by voucher')}</h2>
        <div className={s.priceBlock}>
          <div className={s.priceLine}>
            {t('tickets price')}
            <div className={s.price}>
              {totalPrice - vouchers.totalVouchersValue} {symbol}
            </div>
          </div>
          <div className={s.priceLine}>
            {t('voucher')}
            <div className={s.price}>
              -
              {isFullyСoversСost
                ? totalPrice - vouchers.totalVouchersValue
                : voucherValue}
              {` ${symbol}`}
            </div>
          </div>
          <div className={cn(s.priceLine, s.total)}>
            {t('total price')}
            <div className={s.price}>
              {isFullyСoversСost ? 0 : leftToPay} {symbol}
            </div>
          </div>
        </div>
        <Notice className={s.notice}>
          {`${t('by completing this booking I agree with')} `}
          <Link className={s.link} to="/ticket-sales-rules/">
            {t('Lux Express ticket selling terms')}
          </Link>
          .
        </Notice>
        <div className={s.controls}>
          <Button
            className={s.button}
            disabled={loading}
            text={
              loading
                ? t('loading')
                : isFullyСoversСost
                ? t('pay by voucher')
                : t('approve redeeming')
            }
            onClick={payWithVoucher}
          />
          <div className={s.cancel} onClick={toggleModal}>
            {t('cancel')}
          </div>
        </div>
      </ModalContent>
      <div className={s.footer}>
        {isFullyСoversСost ? (
          <div className={s.notice}>
            <i className={i.ticketAlt} />
            <div className={s.text}>
              {t('voucher.redeeming.full.cost', {
                voucherCode,
                symbol,
                voucherValue: (
                  voucherValue -
                  (totalPrice - vouchers.totalVouchersValue)
                ).toFixed(2),
              })}
            </div>
          </div>
        ) : (
          <Notice>
            {t('voucher.redeeming', {
              voucherCode,
              symbol,
              voucherValue: (
                totalPrice -
                vouchers.totalVouchersValue -
                voucherValue
              ).toFixed(2),
            })}
          </Notice>
        )}
      </div>
    </Modal>
  );
};

export default withRouter(withTranslation()(RedeemVoucher));
