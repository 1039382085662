import React, { useContext, memo } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Collapse } from 'react-collapse';
import { StoreContext } from '@lux/spa/store';
import { localizedDate } from 'utils';
import { Notice, Loading } from '@lux/ui';
// import s from './bicycles.module.scss';
import s from '../../transfer.module.scss';

const Bicycles = ({ t, i18n, className, bicycleLoading }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { language } = i18n;
  const { tickets } = state;

  const {
    bicycles,
    hasOutboundBicycles,
    hasInboundBicycles,
    from,
    to,
    returnDate,
    departDate,
  } = tickets;

  const {
    isOutbound,
    isInbound,
    called,
    outboundBicycle = {},
    inboundBicycle = {},
    maxOutboundBicycles,
    maxInboundBicycles,
    info,
  } = bicycles;

  const updateBicycesCount = (type, direction) => {
    const { Quantity } = bicycles[direction];

    dispatch({
      type: 'UPDATE_BICYCLES',
      payload: {
        [direction]: {
          ...(direction === 'outboundBicycle'
            ? outboundBicycle
            : inboundBicycle),
          Quantity:
            type === 'plus' ? Quantity + 1 : Quantity <= 1 ? 1 : Quantity - 1,
        },
      },
    });
  };

  const handleRequestBicycle = (outbound = true) => {
    dispatch({
      type: 'UPDATE_BICYCLES',
      payload: {
        ...(outbound
          ? {
              isOutbound: !isOutbound,
            }
          : {
              isInbound: !isInbound,
            }),
      },
    });
  };

  return (
    <>
      {bicycleLoading ? (
        <Loading />
      ) : hasOutboundBicycles ? (
        <div className={s.transfersBlock}>
          <h3>
            <span>{t('bicycle ticket')}</span>: {t('outbound trip')} (
            {t(from.CityName)} {t(to.CityName)})
          </h3>
          <div
            className={cn(s.mainInfo, isOutbound && s.selected)}
            onClick={() => handleRequestBicycle(true)}
          >
            <div className={s.check} />
            <div className={s.text}>
              {`${t('reserve free bicycle ticket')}: `}
              <strong>
                {`${localizedDate(departDate, 'dd MMM')}, ${t(
                  from.CityName
                )} - ${t(to.CityName)}`}
              </strong>
            </div>
          </div>

          <Collapse isOpened={isOutbound}>
            <div className={s.row}>
              <div className={s.fields}>
                <div className={s.counter}>
                  <label htmlFor="">{t('number of bicycles')}</label>
                  <div className={s.controls}>
                    <div
                      className={cn(
                        s.minus,
                        outboundBicycle.Quantity <= 1 && s.disabled
                      )}
                      onClick={() =>
                        outboundBicycle.Quantity > 1
                          ? updateBicycesCount('minus', 'outboundBicycle')
                          : null
                      }
                    >
                      —
                    </div>
                    <div className={s.count}>{outboundBicycle.Quantity}</div>
                    <div
                      {...(outboundBicycle.Quantity >=
                      outboundBicycle.maxBicycles
                        ? {
                            'data-title': t(
                              'due to baggage room limitation it is not possible to take more bicycles on that depature'
                            ),
                            'data-title-top': true,
                          }
                        : {})}
                      className={cn(
                        s.plus,
                        outboundBicycle.Quantity >=
                          outboundBicycle.maxBicycles && s.disabled
                      )}
                      onClick={() =>
                        outboundBicycle.Quantity < outboundBicycle.maxBicycles
                          ? updateBicycesCount('plus', 'outboundBicycle')
                          : () => {}
                      }
                    >
                      {`+ ${t('add one more bicycle')}`}
                    </div>
                  </div>
                </div>
              </div>
              <Notice className={s.notice}>
                {t('bicycles.read.carefully')}
              </Notice>
            </div>
          </Collapse>
        </div>
      ) : null}
      {bicycleLoading ? null : hasInboundBicycles ? (
        <div className={s.transfersBlock}>
          <h3>
            <span>{t('bicycle ticket')}</span>: {t('inbound trip')} (
            {t(to.CityName)} {t(from.CityName)})
          </h3>
          <div
            className={cn(s.mainInfo, isInbound && s.selected)}
            onClick={() => handleRequestBicycle(false)}
          >
            <div className={s.check} />
            <div className={s.text}>
              {`${t('reserve free bicycle ticket')}: `}
              <strong>
                {`${localizedDate(returnDate, 'dd MMM')}, ${t(
                  to.CityName
                )} - ${t(from.CityName)}`}
              </strong>
            </div>
          </div>
          <Collapse isOpened={isInbound}>
            <div className={s.row}>
              <div className={s.fields}>
                <div className={s.counter}>
                  <label htmlFor="">{t('number of bicycles')}</label>
                  <div className={s.controls}>
                    <div
                      className={cn(
                        s.minus,
                        inboundBicycle.Quantity <= 1 && s.disabled
                      )}
                      onClick={() =>
                        inboundBicycle.Quantity > 1
                          ? updateBicycesCount('minus', 'inboundBicycle')
                          : null
                      }
                    >
                      —
                    </div>
                    <div className={s.count}>{inboundBicycle.Quantity}</div>
                    <div
                      {...(inboundBicycle.Quantity >= inboundBicycle.maxBicycles
                        ? {
                            'data-title': t(
                              'due to baggage room limitation it is not possible to take more bicycles on that depature'
                            ),
                            'data-title-top': true,
                          }
                        : {})}
                      className={cn(
                        s.plus,
                        inboundBicycle.Quantity >= inboundBicycle.maxBicycles &&
                          s.disabled
                      )}
                      onClick={() =>
                        inboundBicycle.Quantity < inboundBicycle.maxBicycles
                          ? updateBicycesCount('plus', 'inboundBicycle')
                          : () => {}
                      }
                    >
                      {`+ ${t('add one more bicycle')}`}
                    </div>
                  </div>
                </div>
              </div>
              <Notice className={s.notice}>
                {t('bicycles.read.carefully')}
              </Notice>
            </div>
          </Collapse>
        </div>
      ) : null}
    </>
  );
};

export default withTranslation()(memo(Bicycles));
