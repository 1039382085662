// export { GET_AUTH } from './auth';
export { BUS_STOPS } from './bus-stops';
export { IS_SLUG_UNIQUE, GET_CURRENCIES } from './common';
// export { GET_STATE } from './state';
// export { GET_COUNTER } from './counter';
export { GET_DESTINATION, GET_DESTINATION_ADMIN } from './destination';
export {
  MODIFICATION_DETAILS,
  MODIFICATION_PAYABLE_AMOUNT,
} from './change-tickets';
export {
  GET_ALL_DESTINATIONS,
  GET_ALL_DESTINATIONS_ADMIN,
  GET_DESTINATIONS_GROUPED,
} from './destinations';
export {
  GET_FAQ_ARTICLE,
  GET_FAQ_ARTICLES,
  GET_FAQ_CATEGORIES,
  GET_FAQ_SECTIONS,
  FAQ_SEARCH,
} from './faq';
export { GET_ROUTE, GET_ROUTE_ADMIN } from './route';
export { COMPANY_LOGO } from './company-logo';
export {
  GET_ALL_ROUTES,
  GET_ALL_ROUTES_ADMIN,
  GET_ROUTES_GROUPED,
} from './routes';
export { SEARCH, POPULAR_DESTINATIONS } from './search';
export { JOURNEY_ROUTE } from './journey-route';
export { GET_PROFILE, GET_PAYMENT_METHODS } from './profile';
export { GET_PROMO, GET_PROMO_ADMIN } from './promo';
export { GET_ALL_PROMO, GET_ALL_PROMO_ADMIN } from './promos';
export { GET_NEWS, GET_NEWS_ADMIN } from './news';
export { GET_ALL_NEWS, GET_ALL_NEWS_ADMIN } from './news-list';
export { GET_STATIC_PAGE, GET_STATIC_PAGE_ADMIN } from './static-page';
export { GET_ALL_STORIES, GET_ALL_STORIES_ADMIN } from './stories';
export { GET_SUGGESTIONS } from './suggestions';
export {
  DOCUMENT_TYPES,
  FARE_CLASSES,
  PASSENGERS_TYPES,
  ADYEN_PAYMWNTS_METHODS,
  BASKET,
  TICKETS_FILE_URL,
  CITIZENSHIPS,
  SEAT_PLAN,
  JOURNEYS_HISTORY,
  NEO_PAY_BANKS,
  BASKET_PRODUCT_GROUPS,
  BASKET_STATE,
  VERIFY_VOUCHER_BASKET,
  TRAVEL_ACCOUNT_VOUCHERS,
  YOO_MONEY_PAYMENT,
} from './tickets';
export { GET_WIDGET_ADMIN, GET_WIDGET, ORDER_WIDGET } from './widget';
export { GET_LOYALTY_INFO } from './loyalty-info';

export const Query = {
  // ...SomeQueries
};
