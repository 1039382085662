export const emptyBannerItem = {
  alt: {
    value: '',
    fieldName: 'alt',
    placeholder: 'Alt',
    maxLength: 50,
  },
  img: {
    value: '',
    fieldName: 'img',
    placeholder: 'Horizontal',
    name: '',
    size: {
      height: 670,
      width: 1200,
    },
  },
  imgVertical: {
    value: '',
    fieldName: 'imgVertical',
    placeholder: 'Vertical',
    name: '',
    size: {
      height: 1200,
      width: 760,
    },
  },
  title: {
    value: '',
    fieldName: 'title',
    placeholder: 'Title',
    maxLength: 100,
  },
  subTitle: {
    value: '',
    fieldName: 'subTitle',
    placeholder: 'Sub title',
    maxLength: 500,
  },
  link: {
    value: '',
    fieldName: 'link',
    placeholder: 'Link',
    maxLength: 100,
  },
  isPublished: true,
};
