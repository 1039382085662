/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import dateFormat from 'date-fns/format';
import {
  Container,
  Link,
  Button,
  LazyLoadImage,
  Loading,
  ResultsOutOfDate,
  PartnerBusNotice,
} from '@lux/ui';
import { StoreContext } from '@lux/spa/store';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import isAfter from 'date-fns/isAfter';
import { localizedDate, dateFormatForExponea } from 'utils';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { Ticket, Search, Banner, TripDetails } from '@lux/spa/components';
import { SEARCH } from '@lux/spa/apollo/queries';
import { DELETE_BASKET } from '@lux/spa/apollo/mutations';
import { validToUseExponea } from '@lux/spa/config';
import i from '@lux/spa/styles/icons.module.scss';
import { TicketsNotFound, TicketsNoResultsYet } from './components';
import s from './search.module.scss';

const SearchTickets = ({ i18n, t, className, history, match }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { isLapUp, isMob, isTabDown } = useContext(WidthContext);
  const { language } = i18n;
  const {
    isOpenBlind,
    inbound,
    outbound,
    isSearchOutOfDate,
    isPartnerBusNotice,
    promcode,
  } = state.tickets;

  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';
  const {
    from,
    to,
    isSearchValid,
    returnDate = '',
    departDate = '',
    passengersCount,
    isValidUrl,
    lastSearch,
    passengers,
    promocode,
  } = state.search;

  const toggleNoticePartnerBusModal = () => {
    dispatch({
      type: 'UPDATE_TICKETS',
      payload: {
        isPartnerBusNotice: false,
      },
    });
  };

  const toggleModal = () => {
    dispatch({
      type: 'UPDATE_TICKETS',
      payload: {
        isSearchOutOfDate: false,
      },
    });
  };

  const toggleOpenBlind = () => {
    dispatch({
      type: 'UPDATE_TICKETS',
      payload: {
        isOpenBlind: !isOpenBlind,
      },
    });
  };

  const fareClasses = [];

  Object.keys(passengers).forEach(item => {
    if (passengers[item]) {
      switch (item) {
        case 'passengers':
          fareClasses.push({
            Id: 'BONUS_SCHEME_GROUP.ADULT',
            Count: passengers[item],
          });
          break;
        // case 'child':
        //   fareClasses.push({
        //     Id: 'BONUS_SCHEME_GROUP.CHILDREN',
        //     Count: passengers[item],
        //   });
        //   break;
        // case 'youth':
        //   fareClasses.push({
        //     Id: 'BONUS_SCHEME_GROUP.THE_YOUNG',
        //     Count: passengers[item],
        //   });
        //   break;
        // case 'senior':
        //   fareClasses.push({
        //     Id: 'BONUS_SCHEME_GROUP.THE_ELDERLY',
        //     Count: passengers[item],
        //   });
        //   break;
        default:
      }
    }
  });

  const variables = {
    departureDate: localizedDate(departDate, 'yyyy-MM-dd'),
    originBusStopId: +from.BusStopId,
    destinationBusStopId: +to.BusStopId,
    currency: 'CURRENCY.RUB',
    lang: language,
    fareClasses,
    promoCode: promocode,
    isPartOfRoundtrip: Boolean(returnDate),
    onlyActive: false,
  };

  // console.log('validToUseExponea', validToUseExponea());

  const [getTickets, { loading, data, called }] = useLazyQuery(SEARCH, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (validToUseExponea()) {
        exponea.track('search', {
          timestamp: Date.now(),
          journey_origin_stop_name: from.search,
          Origin_stop_ID: from.BusStopId,
          journey_destination_stop_name: to.search,
          Destination_stop_ID: to.BusStopId,
          departure_date: dateFormatForExponea(departDate),
          departure_date_timestamp: +dateFormatForExponea(departDate, 'T'),
          ...(returnDate
            ? {
              return_trip: 'yes',
              return_date: dateFormatForExponea(returnDate),
              return_date_timestamp: +dateFormatForExponea(returnDate, 'T'),
            }
            : {
              return_trip: 'no',
            }),
          count_passengers: passengersCount,
          campaign_code: promocode,
          // return_journey
          source: 'web',
        });
      }
    },
  });
  const [deleteBasket] = useMutation(DELETE_BASKET);

  const resetTickets = () => {
    deleteBasket();
    dispatch({
      type: 'RESET_TICKETS',
    });
  };

  const updateUrl = searchUrl => {
    history.replace(`${match.path}${searchUrl()}`);

    dispatch({
      type: 'UPDATE_TICKETS',
      payload: {
        outbound: {
          ...outbound,
          selected: false,
        },
        inbound: {
          ...inbound,
          selected: false,
        },
      },
    });
  };

  const isSoldOut = value => value === 'NOT_FOR_SALE_REASON.SOLD_OUT';

  let tickets = data?.search || [];
  tickets = tickets.filter(
    t => t.IsForSale || isSoldOut(t?.NotForSaleReasonType)
  );

  if (returnDate && outbound.selected) {
    tickets = tickets.filter(ticket =>
      isAfter(
        new Date(ticket.DepartureDateTime),
        new Date(outbound.arrivalDateTime)
      )
    );
  }

  useEffect(() => {
    if (returnDate && outbound.selected) {
      getTickets({
        fetchPolicy: 'network-only',
        variables: {
          ...variables,
          departureDate: localizedDate(returnDate, 'yyyy-MM-dd'),
          originBusStopId: to.BusStopId,
          destinationBusStopId: from.BusStopId,
        },
      });
    } else {
      // getTickets();
    }
  }, [outbound.selected]);

  useEffect(() => {
    if (isSearchValid && !called && isValidUrl) {
      resetTickets({
        currency,
        currencyName,
        symbol,
      });
      getTickets({
        variables: {
          ...variables,
        },
      });
    }
  }, [called, isValidUrl, isSearchValid]);

  let directTickets = [];
  let transfersTickets = [];

  tickets.some((ticket, key) => {

    const twoOrMoreLegs = ticket.Legs.length >= 2;

    if (twoOrMoreLegs) {
      transfersTickets = tickets.slice(key);

      return twoOrMoreLegs;
    }

    directTickets.push(ticket);
  })


  useEffect(() => {
    if (isSearchValid && isValidUrl) {
      resetTickets();
      getTickets({ variables });
    }
  }, [currency]);

  // useEffect(() => {
  //   setIsOpen(isSearchOutOfDate);
  // }, [isSearchOutOfDate]);

  return (
    <div className={cx(s.searchTickets, className)}>
      <ResultsOutOfDate
        isOpen={isSearchOutOfDate}
        toggleModal={toggleModal}
        getTickets={getTickets}
        variables={variables}
      />
      <PartnerBusNotice
        isOpen={isPartnerBusNotice}
        toggleModal={toggleNoticePartnerBusModal}
      />
      <Container className={cx(s.blind, isOpenBlind && s.open)}>
        <Container className={s.container} full={false}>
          <section>
            <div className={s.header}>
              <Link to="/">
                <LazyLoadImage src="/images/svg/logo-grey.svg" alt="" />
              </Link>
              <div>
                <i className={cx(i.close, s.close)} onClick={toggleOpenBlind} />
              </div>
            </div>
            {!isLapUp && (
              <Search
                className={s.searchForm}
                inputsClassName={s.inputs}
                ButtonSearch={({ pushAnaliticsToGTM, searchUrl, ...props }) => (
                  <Button
                    {...props}
                    className={s.searchButton}
                    onClick={() => {
                      pushAnaliticsToGTM();
                      resetTickets();
                      updateUrl(searchUrl);
                      getTickets({ variables });
                      toggleOpenBlind();
                    }}
                    text={t('search')}
                  />
                )}
              />
            )}
          </section>
        </Container>
      </Container>
      <Container className={s.searchSection}>
        <Container full={false}>
          <section>
            <Search
              className={s.searchForm}
              ButtonSearch={({ pushAnaliticsToGTM, searchUrl, ...props }) => (
                <Button
                  {...props}
                  className={s.searchButton}
                  onClick={() => {
                    pushAnaliticsToGTM();
                    resetTickets();
                    updateUrl(searchUrl);
                    getTickets({ variables });
                  }}
                  text={t('search')}
                />
              )}
            />
            <div className={s.searchPanel}>
              <div className={s.searchInfo}>
                <div className={s.directions}>
                  <div>{from.CityName}</div>
                  <span>{'>'}</span>
                  <div>{to.CityName}</div>
                </div>
                {departDate && (
                  <div className={s.result}>
                    {departDate && localizedDate(departDate, 'd LLL, eee')}
                    {returnDate &&
                      ` - ${localizedDate(returnDate, 'd LLL, eee')}`}
                    {`, ${passengersCount} `}
                    {passengersCount > 1 ? t('adults') : t('adult')}
                  </div>
                )}
              </div>
              <div className={s.showSearch} onClick={toggleOpenBlind}>
                <i className={i.search} />
              </div>
            </div>
          </section>
        </Container>
      </Container>
      <Container className={s.tickets}>
        <Container full={false}>
          <section className={s.ticketsSection}>
            <h1 className={s.title}>
              {t(`select ${outbound.selected ? 'inbound' : 'outbound'} ticket`)}
            </h1>
            {/* <div className={s.results}>
              {loading ? (
                <span>{t('searching')}...</span>
              ) : (
                <>
                  <span className={s.count}>{tickets.length}</span>
                  <span className={s.text}>{t('trips found')}</span>
                </>
              )}
            </div> */}
            <div className={s.content}>
              <div className={s.ticketsList}>
                {loading ? (
                  <Loading />
                ) : tickets.length ? (
                  <>
                    {!!directTickets.length && (
                      <>
                        <div className={s.ticketsHeader}>
                          <div className={s.count}>
                            {directTickets.length}
                          </div>
                          <div className={s.ticketsTitle}>
                            {t('direct routes')}
                          </div>
                        </div>
                        {directTickets.map((ticket, key) => {
                          return (
                            <Ticket
                              id={key}
                              isSoldOut={isSoldOut(ticket?.NotForSaleReasonType)}
                              notifications={ticket.Notifications}
                              passengers={ticket.Legs[0].Passengers.length}
                              arrivalDateTimeTimeZone={ticket.ArrivalDateTimeTimeZone}
                              departureDateTimeTimeZone={
                                ticket.DepartureDateTimeTimeZone
                              }
                              key={ticket.JourneyId}
                              ticketLegs={ticket.Legs}
                              isMob={isMob}
                              ticket={ticket}
                              transfers={ticket.Legs.length - 1}
                              journeyId={ticket.JourneyId}
                              campaignPrice={ticket.CampaignPrice}
                              businessCampaignPrice={ticket.BusinessCampaignPrice}
                              originStopName={ticket.OriginStopName}
                              destinationStopName={ticket.DestinationStopName}
                              availableRegularSeats={ticket.AvailableRegularSeats}
                              availableBusinessClassSeats={
                                ticket.AvailableBusinessClassSeats
                              }
                              regularPrice={ticket.RegularPrice}
                              businessClassPrice={ticket.BusinessClassPrice}
                              durationTravel={ticket.Duration}
                              departureDateTime={ticket.DepartureDateTime}
                              arrivalDateTime={ticket.ArrivalDateTime}
                              availableCampaignSeats={ticket.AvailableCampaignSeats}
                              availableBusinessCampaignSeats={
                                ticket.availableBusinessCampaignSeats
                              }
                            />
                          );
                        })}
                      </>
                    )}
                    {!!transfersTickets.length && (
                      <>
                        <div className={s.ticketsHeader}>
                          <div className={s.count}>
                            {transfersTickets.length}
                          </div>
                          <div className={s.ticketsTitle}>{t('routes with transfers')}</div>
                        </div>
                        {transfersTickets.map((ticket, key) => {
                          return (
                            <Ticket
                              isSoldOut={isSoldOut(ticket?.NotForSaleReasonType)}
                              notifications={ticket.Notifications}
                              passengers={ticket.Legs[0].Passengers.length}
                              arrivalDateTimeTimeZone={ticket.ArrivalDateTimeTimeZone}
                              departureDateTimeTimeZone={
                                ticket.DepartureDateTimeTimeZone
                              }
                              key={ticket.JourneyId}
                              id={key}
                              ticketLegs={ticket.Legs}
                              isMob={isMob}
                              ticket={ticket}
                              transfers={ticket.Legs.length - 1}
                              journeyId={ticket.JourneyId}
                              campaignPrice={ticket.CampaignPrice}
                              businessCampaignPrice={ticket.BusinessCampaignPrice}
                              originStopName={ticket.OriginStopName}
                              destinationStopName={ticket.DestinationStopName}
                              availableRegularSeats={ticket.AvailableRegularSeats}
                              availableBusinessClassSeats={
                                ticket.AvailableBusinessClassSeats
                              }
                              regularPrice={ticket.RegularPrice}
                              businessClassPrice={ticket.BusinessClassPrice}
                              durationTravel={ticket.Duration}
                              departureDateTime={ticket.DepartureDateTime}
                              arrivalDateTime={ticket.ArrivalDateTime}
                              availableCampaignSeats={ticket.AvailableCampaignSeats}
                              availableBusinessCampaignSeats={
                                ticket.availableBusinessCampaignSeats
                              }
                            />
                          );
                        })}
                      </>
                    )}
                  </>
                ) : called ? (
                  <TicketsNotFound from={from} to={to} />
                ) : (
                  <TicketsNoResultsYet from={from} to={to} />
                )}
              </div>
              {outbound.selected ? (
                isTabDown ? (
                  <TripDetails
                    isSearch
                    notRenderNextButton
                    getTickets={getTickets}
                    variables={variables}
                  />
                ) : (
                  <div className={s.tripDetails}>
                    <TripDetails
                      isSearch
                      notRenderNextButton
                      getTickets={getTickets}
                      variables={variables}
                    />
                  </div>
                )
              ) : isLapUp ? (
                <Banner horizontal={false} className={s.banner} />
              ) : null}
            </div>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withRouter(withTranslation()(SearchTickets));
