import React, { useContext } from 'react';
import cn from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UPDATE_PROFILE, UPLOAD_AVATAR } from '@lux/spa/apollo/mutations';
import { useMutation } from '@apollo/react-hooks';
import { StoreContext } from '@lux/spa/store';
import { regExpForNames } from 'utils';
import { Container, Radio, TicketsInput, PhoneInput } from '@lux/ui';
import { ProfileMenu } from '@lux/spa/components';
import { validToUseExponea } from '@lux/spa/config';
import s from './personal-info.module.scss';

const PersonalInfo = ({ t, className }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { user } = state;
  const {
    avatar,
    email,
    firstName,
    lastName,
    gender = 'SEX.MALE',
    phone,
  } = user;
  const [updateProfile] = useMutation(UPDATE_PROFILE);
  const [uploadAvatar, { error }] = useMutation(UPLOAD_AVATAR);

  return (
    <div className={cn(className, s.personalInfo)}>
      <Container>
        <Container full={false}>
          <section>
            <h2>{t('my account')}</h2>
            <div className={s.block}>
              <ProfileMenu />
              <div className={s.content}>
                <Formik
                  validateOnMount
                  // enableReinitialize
                  initialValues={{
                    email,
                    firstName,
                    lastName,
                    gender,
                    avatar,
                    phone,
                  }}
                  validationSchema={Yup.object().shape({
                    // gender: Yup.string().required(
                    //   t('validation.error.require', {
                    //     field: t('gender'),
                    //   })
                    // ),
                    email: Yup.string().email(
                      t('validation.error.email', {
                        field: t('email'),
                      })
                    ),
                    // .required(
                    //   t('validation.error.require', {
                    //     field: t('email'),
                    //   })
                    // ),
                    firstName: Yup.string()
                      .required(
                        t('validation.error.require', {
                          field: t('first name'),
                        })
                      )
                      .matches(
                        regExpForNames,
                        t('validation.error.names', {
                          field: t('first name'),
                        })
                      ),
                    lastName: Yup.string()
                      .required(
                        t('validation.error.require', {
                          field: t('last name'),
                        })
                      )
                      .matches(
                        regExpForNames,
                        t('validation.error.names', {
                          field: t('last name'),
                        })
                      ),
                    phone: Yup.string()
                      .max(
                        20,
                        t('validation.error.max', {
                          field: t('phone number'),
                          max: 20,
                        })
                      )
                      .min(
                        10,
                        t('validation.error.min', {
                          field: t('phone number'),
                          min: 10,
                        })
                      )
                      .required(
                        t('validation.error.require', {
                          field: t('phone number'),
                        })
                      ),
                  })}
                >
                  {props => {
                    const {
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleBlur,
                      handleReset,
                      validateForm,
                      setFieldValue,
                      setFieldError,
                      initialValues,
                    } = props;

                    const hasNewChange =
                      Object.entries(initialValues).toString() !==
                      Object.entries(values).toString();

                    return (
                      <>
                        <div className={s.header}>
                          <h5 className={s.title}>{t('personal info')}</h5>
                          <div
                            className={s.action}
                            onClick={() => {
                              if (!Object.keys(errors).length) {
                                updateProfile({
                                  variables: {
                                    input: {
                                      firstName: values.firstName,
                                      lastName: values.lastName,
                                      email: values.email,
                                      gender: values.gender,
                                      avatar: values.avatar,
                                      phone: values.phone,
                                    },
                                  },
                                })
                                  .then(data => {
                                    if (
                                      !data?.data?.updateProfile?.errors?.length
                                    ) {
                                      const {
                                        profile,
                                      } = data.data.updateProfile;

                                      if (validToUseExponea()) {
                                        exponea.update({
                                          first_name: profile.firstName,
                                          last_name: profile.lastName,
                                          phone_number: profile.phone,
                                        });
                                      }

                                      dispatch({
                                        type: 'UPDATE_USER',
                                        payload: {
                                          ...profile,
                                        },
                                      });

                                      toast.success(
                                        t('profile was successfully updated')
                                      );

                                      validateForm();
                                    } else {
                                      toast.error(t('something wrong'));
                                    }
                                  })
                                  .catch(() => {
                                    toast.error(t('something wrong'));
                                  });
                              }
                            }}
                            disabled={
                              Object.keys(errors).length || !hasNewChange
                            }
                          >
                            {t('save')}
                          </div>
                        </div>
                        <div className={s.info}>
                          <div className={s.photo}>
                            <label>
                              <div className={cn(s.avatar)}>
                                {values.avatar ? (
                                  <div
                                    className={s.cap}
                                    style={{
                                      backgroundImage: `url(${values.avatar})`,
                                    }}
                                  />
                                ) : (
                                  <img src="/images/svg/camera.svg" alt="" />
                                )}
                                <input
                                  style={{ display: 'none' }}
                                  type="file"
                                  accept="image/jpeg, image/png"
                                  onChange={e => {
                                    const reader = new FileReader();
                                    const file = e.target.files[0];
                                    reader.readAsDataURL(file);
                                    reader.onload = () => {
                                      // cb(reader.result);
                                      const size = file.size / 1024 / 1024;
                                      const maxFileSize = 5;

                                      if (size <= maxFileSize) {
                                        uploadAvatar({
                                          variables: {
                                            file: reader.result,
                                          },
                                        }).then(data => {
                                          const url =
                                            data?.data?.uploadAvatar?.url;

                                          if (url) {
                                            setFieldValue('avatar', url);
                                          }
                                        });
                                      } else {
                                        setFieldError(
                                          'avatar',
                                          'Maximum file size is 5 mb'
                                        );
                                      }
                                    };

                                    reader.onerror = error => {
                                      console.log('Error: ', error);
                                    };
                                  }}
                                />
                              </div>
                            </label>
                            {touched.firstName && errors.avatar && (
                              <div className={s.error}>{errors.avatar}</div>
                            )}
                          </div>
                          <div className={s.fields}>
                            <div className={cn(s.field, s.email)}>
                              <label>{t('email')}</label>
                              <div className={s.email}>
                                <div className={s.text}>{email}</div>
                              </div>
                            </div>
                            <div className={cn(s.field, s.radios)}>
                              <Radio
                                className={s.radio}
                                name="gender"
                                value={t('male')}
                                checked={values.gender === 'SEX.MALE'}
                                onChange={() =>
                                  setFieldValue('gender', 'SEX.MALE')
                                }
                              />
                              <Radio
                                className={s.radio}
                                name="gender"
                                value={t('female')}
                                checked={values.gender === 'SEX.FEMALE'}
                                onChange={() =>
                                  setFieldValue('gender', 'SEX.FEMALE')
                                }
                              />
                            </div>
                            <div className={s.field}>
                              <TicketsInput
                                autoFocus
                                name="firstName"
                                type="text"
                                required
                                label={t('first name')}
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errors={touched.firstName && errors.firstName}
                              />
                            </div>
                            <div className={s.field}>
                              <TicketsInput
                                name="lastName"
                                type="text"
                                label={t('last name')}
                                required
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errors={touched.lastName && errors.lastName}
                              />
                            </div>
                            <div className={s.field}>
                              <PhoneInput
                                autoFocus
                                inputClassName={s.phone}
                                name="phone"
                                label={t('phone number')}
                                placeholder={t('enter phone number')}
                                value={values.phone}
                                required
                                onChange={e => {
                                  setFieldValue('phone', e);
                                }}
                                onBlur={handleBlur}
                                errors={touched.phone && errors.phone}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(PersonalInfo);
