import React, { createContext, useReducer, useMemo } from 'react';
import {
  initialStateApp,
  initialStateSearch,
  initialStateTickets,
  initialStateChangeTickets,
  initialStateUser,
  appReducer,
  searchReducer,
  ticketsReducer,
  changeTicketsReducer,
  userReducer,
} from './reducers';

export const StoreContext = createContext();

const initialState = {
  app: initialStateApp,
  search: initialStateSearch,
  tickets: initialStateTickets,
  changeTickets: initialStateChangeTickets,
  user: initialStateUser,
};

const rootReducer = combineReducers({
  // someVar: reduceReducers(firtReducer, secondReducer),  // TODO implement for use in a future
  app: appReducer,
  search: searchReducer,
  tickets: ticketsReducer,
  changeTickets: changeTicketsReducer,
  user: userReducer,
});

const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  const store = useMemo(() => [state, dispatch], [state]);
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

// helpers
function combineReducers(slices) {
  return (state, action) =>
    Object.keys(slices).reduce(
      (acc, prop) => ({
        ...acc,
        [prop]: slices[prop](acc[prop], action),
      }),
      state
    );
}

// TODO implement for use in a future
// function reduceReducers(...reducers) {
//   return (state, action) =>
//     reducers.reduce((acc, nextReducer) => nextReducer(acc, action), state);
// }

export default StoreProvider;
