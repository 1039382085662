import React, { useContext } from 'react';
import cn from 'classnames';
import { UPDATE_PROFILE } from '@lux/spa/apollo/mutations';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { StoreContext } from '@lux/spa/store';
import { Container, Select, SelectOption } from '@lux/ui';
import { langs } from '@lux/spa/i18n';
import { validToUseExponea } from '@lux/spa/config';
import { ProfileMenu } from '@lux/spa/components';
import s from './settings.module.scss';

const Settings = ({ t, className }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { user, app } = state;
  // const { currencies } = app;
  const [updateProfile] = useMutation(UPDATE_PROFILE);
  return (
    <div className={cn(className, s.settings)}>
      <Container>
        <Container full={false}>
          <section>
            <h2>{t('my account')}</h2>
            <div className={s.block}>
              <ProfileMenu />
              <div className={s.content}>
                <div className={s.header}>
                  <h5 className={s.title}>{t('settings')}</h5>
                </div>
                <div className={s.info}>
                  {/* <div className={s.block}>
                    <div className={s.title}>{t('set default currency')}</div>
                    <div className={s.subTitle}>
                      {t(
                        'it will be selected by the default when buying tickets'
                      )}
                    </div>
                    <div className={s.field}>
                      <Select
                        value={user.currency}
                        withoutMessage
                        onChange={e =>
                          updateProfile({
                            variables: {
                              input: {
                                currency: e.target.value,
                              },
                            },
                          })
                            .then(data => {
                              if (!data?.data?.updateProfile?.errors?.length) {
                                const { profile } = data.data.updateProfile;

                                dispatch({
                                  type: 'UPDATE_USER',
                                  payload: {
                                    ...profile,
                                  },
                                });
                                toast.success(
                                  t('profile was successfully updated')
                                );
                              } else {
                                toast.error(t('something wrong'));
                              }
                            })
                            .catch(() => {
                              toast.error(t('something wrong'));
                            })
                        }
                      >
                        {currencies.map(item => (
                          <SelectOption
                            key={item.Currency}
                            value={item.Currency}
                            text={item.CurrencyName}
                          />
                        ))}
                      </Select>
                    </div>
                  </div> */}
                  <div className={s.block}>
                    <div className={s.title}>{t('set default language')}</div>
                    <div className={s.field}>
                      <Select
                        value={user.language}
                        withoutMessage
                        onChange={e =>
                          updateProfile({
                            variables: {
                              input: {
                                language: e.target.value,
                              },
                            },
                          })
                            .then(data => {
                              if (!data?.data?.updateProfile?.errors?.length) {
                                const { profile } = data.data.updateProfile;

                                if (validToUseExponea()) {
                                  exponea.update({
                                    language: profile.language,
                                  });
                                }

                                dispatch({
                                  type: 'UPDATE_USER',
                                  payload: {
                                    ...profile,
                                  },
                                });

                                toast.success(
                                  t('profile was successfully updated')
                                );
                              } else {
                                toast.error(t('something wrong'));
                              }
                            })
                            .catch(() => {
                              toast.error(t('something wrong'));
                            })
                        }
                      >
                        {langs.map(lang => (
                          <SelectOption
                            key={lang}
                            value={lang}
                            text={lang.toUpperCase()}
                          />
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(Settings);
