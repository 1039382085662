import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import i from '@lux/spa/styles/icons.module.scss';
import s from './textarea.module.scss';

const Textarea = ({ icon, label, className, value = '', ...props }) => {
  const [inputValue, setInputValue] = useState(value);
  const onInput = e => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const classes = cn(
    s.textarea,
    className,
    icon && s.withIcon,
    inputValue && s.hasValue
  );

  return (
    <div className={classes}>
      <textarea
        name=""
        id=""
        rows="5"
        {...props}
        value={inputValue}
        onInput={onInput}
      >
        {inputValue}
      </textarea>
      {label && <label>{label}</label>}
      {icon && <i className={i[icon]} />}
    </div>
  );
};

export default Textarea;
