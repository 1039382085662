import React from 'react';
import { withTranslation } from 'react-i18next';
import cn from 'classnames';
import { NavLink } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './profile-menu.module.scss';

const ProfileMenu = ({ t, className }) => {
  return (
    <aside className={cn(s.menu, className)}>
      <NavLink
        to="/profile/customer-program/"
        className={s.item}
        activeClassName={s.active}
      >
        <span>{t('customer program')}</span>
        <i className={i.arrowRight_v2} />
      </NavLink>
      <NavLink
        to="/profile/trips/"
        className={s.item}
        activeClassName={s.active}
      >
        <span>{t('trips')}</span>
        <i className={i.arrowRight_v2} />
      </NavLink>
      <NavLink
        to="/profile/personal-info/"
        className={s.item}
        activeClassName={s.active}
      >
        <span>{t('personal info')}</span>
        <i className={i.arrowRight_v2} />
      </NavLink>
      <NavLink
        to="/profile/payment-methods/"
        className={s.item}
        activeClassName={s.active}
      >
        <span>{t('payment methods')}</span>
        <i className={i.arrowRight_v2} />
      </NavLink>
      <NavLink
        to="/profile/vouchers/"
        className={s.item}
        activeClassName={s.active}
      >
        <span>{t('vouchers')}</span>
        <i className={i.arrowRight_v2} />
      </NavLink>
      <NavLink
        to="/profile/security/"
        className={s.item}
        activeClassName={s.active}
      >
        <span>{t('security')}</span>
        <i className={i.arrowRight_v2} />
      </NavLink>
      <NavLink
        to="/profile/settings/"
        className={s.item}
        activeClassName={s.active}
      >
        <span>{t('settings')}</span>
        <i className={i.arrowRight_v2} />
      </NavLink>
    </aside>
  );
};

export default withTranslation()(ProfileMenu);
