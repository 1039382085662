import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { Link } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './button.module.scss';

const Button = ({
  iconLeft,
  iconRight,
  icon,
  to,
  flat,
  text,
  color,
  size = 'm',
  children,
  disabled,
  className,
  type = 'button',
  ...props
}) => {
  const classes = cn(
    s.button,
    to && s.link,
    icon && s.icon,
    size && s[size],
    flat && s.flat,
    s[color],
    className
  );

  const buttonRef = useRef(null);

  useEffect(() => {
    const button = buttonRef?.current;
    if (button) {
      button.addEventListener('mousemove', e => {
        button.style.setProperty(
          'background-position',
          `${e.clientX - 60}px ${e.clientY - 60}px`
        );
      });

      return () => {
        button.removeEventListener('mousemove', e => {
          button.style.setProperty(
            'background-position',
            `${e.clientX - 60}px ${e.clientY - 60}px`
          );
        });
      };
    }
  }, []);

  return to ? (
    <Link
      to={to}
      linkRef={buttonRef}
      className={classes}
      disabled={disabled}
      {...props}
    >
      {iconLeft && <i className={cn(i[`icon${iconLeft}`], s.iconLeft)} />}
      {icon ? <i className={cn(i[`icon${icon}`], s.icon)} /> : text || children}
      {iconRight && <i className={cn(i[`icon${iconRight}`], s.iconRight)} />}
    </Link>
  ) : (
    <button
      type={type}
      ref={buttonRef}
      className={classes}
      disabled={disabled}
      {...props}
    >
      {iconLeft && <i className={cn(i[`icon${iconLeft}`], s.iconLeft)} />}
      {icon ? <i className={cn(i[`icon${icon}`], s.icon)} /> : text || children}
      {iconRight && <i className={cn(i[`icon${iconRight}`], s.iconRight)} />}
    </button>
  );
};

export default Button;
