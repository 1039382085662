import React, { useState, useEffect, useContext } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Header, Footer, EditPage, CookieBanner } from '@lux/spa/components';
import { Divider, ErrorBoundary, Login } from '@lux/ui';
import { useQuery } from '@apollo/react-hooks';
import TagManager from 'react-gtm-module';
import GoogleAnalytics from 'react-ga4';
import { GET_PROFILE } from '@lux/spa/apollo/queries';
import { StoreContext } from '@lux/spa/store';
import { getCookie } from 'utils';
import {
  DEFAULT_LANG,
  DEFAULT_REGION,
  validToUseExponea,
} from '@lux/spa/config';
import routes, { NotFound } from '@lux/spa/routes';
import s from './app.module.scss';

const App = ({ i18n, location, history }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { user, app } = state;

  const { isStaff, phone, email } = user;
  const { mainAlert } = app;

  const { language } = i18n;

  const { hash, pathname, search } = location;

  const availableLangs = ['ru', 'en'];

  let langFromUrl = pathname.match(/\/((\w{2})\/?$|(\w{2})\/)/);

  langFromUrl = langFromUrl ? langFromUrl[0].split('/').join('') : 'ru';

  const isHomePage = location.pathname === '/';
  // const langFromCookie = getCookie('lang');
  const tokenFromCookie = getCookie('token');

  const isInitialLang = availableLangs.includes(langFromUrl);

  const [lang, setLang] = useState((isInitialLang && langFromUrl) || language);

  const [showLoginModal, setShowLoginModal] = useState(false);

  const toggleLoginModal = () => {
    setShowLoginModal(!showLoginModal);
  };

  const isDefaultLang = lang === DEFAULT_LANG;

  const onChangeLang = (lang, isIgnoreCookie) => {
    if (!isIgnoreCookie) {
      const maxAgeCookie = 2147483647; // 2147483647 ~ 68 years
      document.cookie = `lang=${lang};path=/;max-age=${maxAgeCookie};`;
    }

    setLang(lang);

    const fullPathString = pathname + search + hash;

    const fullPath = fullPathString.match(/\/((\w{2})\/?$|(\w{2})\/)/);

    const fullPathWihtoutLang = fullPath
      ? fullPathString.replace(fullPath[0], '/')
      : fullPathString;

    const redirectUrl =
      lang === DEFAULT_LANG
        ? fullPathWihtoutLang
        : `/${lang}${fullPathWihtoutLang}`;

    history.push(redirectUrl);
  };

  const { data, loading: profileLoading } = useQuery(GET_PROFILE, {
    // skip: !(typeof localStorage !== 'undefined' && 'token' in localStorage),
    skip: !tokenFromCookie,
    onCompleted: data => {
      if (!data?.errors?.length) {
        const isSetLangFromProfile = /\/([a-zA-Z]{2}\/?)?$/.test(pathname);

        // if (
        //   data?.profile?.language &&
        //   isSetLangFromProfile
        //   // !langFromCookie
        // ) {
        //   onChangeLang(data.profile.language);
        // }

        dispatch({
          type: 'UPDATE_USER',
          payload: {
            ...(data?.profile || {}),
          },
        });
      }
    },
  });

  useEffect(() => {
    if (validToUseExponea() && email) {
      exponea.identify({ email });
    }
  }, [email]);

  useEffect(() => {
    if (isStaff) {
      const script = document.createElement('script');

      script.src = '/ckfinder/ckfinder.js';
      script.async = true;

      document.body.appendChild(script);
    }
  }, [isStaff]);

  const fullPath = pathname + search + hash;

  useEffect(() => {
    i18n.changeLanguage(lang);

    document.documentElement.lang = lang;
    const { type = '', slugs = {} } =
      JSON.parse(localStorage.getItem('location')) || {};

    i18n.changeLanguage(lang).then(() => {
      if (fullPath.includes(`/${type}/`) && slugs[lang]) {
        const currentSlug = fullPath
          .split('/')
          .filter(s => !!s)
          .pop();

        if (Object.values(slugs).includes(currentSlug)) {
          if (isDefaultLang) {
            history.push(`/${type}/${slugs[lang]}/`);
          } else {
            history.push(`/${lang}/${type}/${slugs[lang]}/`);
          }
        }
      }
    });
  }, [lang]);

  useEffect(() => {
    const isTicketsUi =
      pathname.startsWith('/tickets') ||
      pathname.startsWith(`/${lang}/tickets`) ||
      pathname.startsWith('/change-tickets') ||
      pathname.startsWith(`/${lang}/change-tickets`);
    if (isTicketsUi) {
      document.body.classList.add('tickets-ui');
      dispatch({
        type: 'UPDATE_APP',
        payload: {
          ticketsUi: true,
        },
      });
    } else {
      document.body.classList.remove('tickets-ui');
      dispatch({
        type: 'UPDATE_APP',
        payload: {
          ticketsUi: false,
        },
      });
    }

    if (
      !isHomePage &&
      !pathname.endsWith('/') &&
      !location.search.includes('?')
    ) {
      history.push(`${pathname}/`);
    }

    if (/\/{2,}$/.test(pathname)) {
      history.push(pathname.replace(/\/{1,}$/g, '/'));
    }
  }, [pathname]);

  // GDPR cookie logic start

  const isCookiesAllow =
    document.cookie.includes('cookieallow=true') || isStaff;
  const [isShowCookieBanner, setIsShowCookieBanner] = useState(!isCookiesAllow);

  useEffect(() => {
    if ((isProd || isDev) && (isCookiesAllow || isStaff)) {
      // const tagManagerArgs = {
      //   gtmId: GTM_ID,
      // };

      const promise = new Promise(res => res());

      promise
        .then(() => {
          // TagManager.initialize(tagManagerArgs);
          GoogleAnalytics.initialize(GTM_ID);
        })
        .then(() => {
          dispatch({
            type: 'UPDATE_APP',
            payload: {
              GTMLoaded: true,
            },
          });
        });
    }
  }, [isShowCookieBanner]);

  const handleAllowCookie = () => {
    const maxAgeCookie = 2147483647; // 2147483647 ~ 68 years
    document.cookie = `cookieallow=true;path=/;max-age=${maxAgeCookie};`;
    setIsShowCookieBanner(false);
  };

  // GDPR cookie logic end

  // const changeStorage = e => {
  //   if (e.key === 'token') {
  //     localStorage.removeItem('token');

  //     dispatch({
  //       type: 'LOG_OUT',
  //     });

  //     history.push(`/${DEFAULT_LANG === lang ? '' : lang}`);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('storage', e => changeStorage(e));

  //   return () => {
  //     window.removeEventListener('storage', e => changeStorage(e));
  //   };
  // }, []);

  const params = {};

  location.search
    .replace('?', '')
    .split('&')
    .forEach(param => {
      const key = param.split('=')[0];
      const value = param.split('=')[1];
      params[key] = value;
    });

  useEffect(() => {
    if (params.modal === 'login') {
      dispatch({
        type: 'UPDATE_APP',
        payload: {
          isOpenLoginModal: true,
        },
      });
    }
  }, [params.modal]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     dispatch({
  //       type: 'UPDATE_APP',
  //       payload: {
  //         mainAlert: {
  //           enabled: true,
  //           text:
  //             "Latvian and Lithuanian governments require all passengers to fill in online form. After completing the form QR code is sent to passenger's e-mail.",
  //         },
  //       },
  //     });
  //   }, 1000);
  // }, []);

  return (
    <div className={s.wrapper}>
      {showLoginModal && (
        <Login isOpen={showLoginModal} toggleModal={toggleLoginModal} />
      )}
      {isClient && !isCookiesAllow && (
        <CookieBanner handleAllowCookie={handleAllowCookie} />
      )}
      {/* {mainAlert.enabled && (
        <OperationalNotifications text={mainAlert.text} link="/" />
      )} */}
      <Header
        profileLoading={profileLoading}
        onChangeLang={onChangeLang}
        region={DEFAULT_REGION}
        hasNotifications={mainAlert.enabled}
      />
      <main className={s.main}>
        <ErrorBoundary>
          <Switch>
            <Route
              path="/:lang(ru*)"
              render={({ location }) => (
                <Redirect to={location.pathname.slice(3)} />
              )}
            />
            {routes({ isStaff, authorized: !!phone }).map(
              (
                {
                  component: Component,
                  path,
                  exact = true,
                  multi = false,
                  isStatic = false,
                  pageName,
                },
                key
              ) => {
                return isStaff ? (
                  <Route
                    key={key}
                    exact={exact}
                    path={isDefaultLang ? `${path}` : `/${lang}${path}`}
                    render={props => (
                      <EditPage
                        isStatic={isStatic}
                        lang={lang}
                        multi={multi}
                        pageName={pageName}
                        region={DEFAULT_REGION}
                        children={
                          <Component
                            {...props}
                            region={DEFAULT_REGION}
                            isStaff
                            className={cn(
                              s.route,
                              mainAlert.enabled && s.withAlert
                            )}
                          />
                        }
                      />
                    )}
                  />
                ) : (
                  <Route
                    key={key}
                    exact={exact}
                    path={isDefaultLang ? `${path}` : `/${lang}${path}`}
                    render={props => (
                      <Component
                        {...props}
                        region={DEFAULT_REGION}
                        isStaff={false}
                        className={cn(
                          s.route,
                          mainAlert.enabled && s.withAlert
                        )}
                      />
                    )}
                  />
                );
              }
            )}
            <Route
              path="*"
              render={props => (
                <NotFound
                  {...props}
                  profileLoading={profileLoading}
                  className={cn(s.route, mainAlert.enabled && s.withAlert)}
                />
              )}
            />
          </Switch>
        </ErrorBoundary>
      </main>
      <Footer />
      <Divider />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default withRouter(withTranslation()(App));
