import React, { useContext } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet-async';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { withTranslation } from 'react-i18next';
import { Search, meta } from '@lux/spa/components';
import { Query } from 'react-apollo';
import { GET_DESTINATIONS_GROUPED } from '@lux/spa/apollo/queries';
import { Container, Divider, LazyLoadImage, Loading } from '@lux/ui';
import Destination from './components';
import s from './all-destinations.module.scss';

const Country = ({ country, isMob, t }) => {
  const { countryName, destinations } = country;
  const count = country.destinations.length;
  // const [isOpen, setIsOpen] = useState(false)
  //
  // const toggleOpen = () => (
  //   setIsOpen(!isOpen)
  // )

  // const renderCount = isMob ? count > 4 ? 4 : count : count

  return (
    <div className={s.country}>
      <h3 className={s.name}>
        <div className={s.flag}>
          <LazyLoadImage
            src={`/images/svg/flags/flag-${countryName.toLowerCase()}.svg`}
            alt=""
          />
        </div>
        <div>{t(countryName)}</div>
      </h3>
      <div className={s.directions}>
        {destinations.map((destination, key) => (
          <Destination
            destination={destination}
            number={key + 1}
            t={t}
            key={key}
          />
        ))}
        {/* {isMob && isOpen && (
          Array(count - 4).fill().map((item, key) => (
            <Destination country={country} number={key + 1} key={key} />
          ))
        )} */}
      </div>
      {/* {isMob && count > 4 && (
        <Button
          flat={true}
          className={s.button}
          text={`${isOpen ? t('Show less') : t('Show more')}`}
          onClick={toggleOpen}
        />
      )} */}
    </div>
  );
};

const AllDestinations = ({ t, className, region, i18n }) => {
  const { language } = i18n;
  const { isMob } = useContext(WidthContext);

  const slug = 'all-destinations';

  let seo = {
    et: {
      title: {
        value: 'Avasta Euroopat! Tutvu Lux Expressi sihtkohtadega',
      },
      description: {
        value: 'Reisi mugavate bussidega Euroopa linnadesse.',
      },
      socialTitle: {
        value: 'Avasta Euroopat! Tutvu Lux Expressi sihtkohtadega',
      },
      socialDescription: {
        value: 'Reisi mugavate bussidega Euroopa linnadesse.',
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value:
          '/images/thumbnails/all-destinations/Lux_Express_all_destinations_et.jpg',
      },
    },
    en: {
      title: {
        value: 'Discover the best travel destinations in Europe',
      },
      description: {
        value:
          'Discover top European travel destinations. Travel by comfortable buses between cities in Europe.',
      },
      socialTitle: {
        value: 'Discover the best travel destinations in Europe',
      },
      socialDescription: {
        value:
          'Discover top European travel destinations. Travel by comfortable buses between cities in Europe.',
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value:
          '/images/thumbnails/all-destinations/Lux_Express_all_destinations_en.jpg',
      },
    },
    lt: {
      title: {
        value: 'Visos kryptys',
      },
      description: {
        value:
          'Keliaukite tarptautiniais autobusais į Europą ir atraskite Europos miestus. Nusipirkite bilietą internetu.',
      },
      socialTitle: {
        value: 'Visos kryptys',
      },
      socialDescription: {
        value:
          'Keliaukite tarptautiniais autobusais į Europą ir atraskite Europos miestus. Nusipirkite bilietą internetu.',
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value:
          '/images/thumbnails/all-destinations/Lux_Express_all_destinations_lt.jpg',
      },
    },
    lv: {
      title: {
        value: 'Ceļojumi ar autobusu uz mūsu galamērķiem',
      },
      description: {
        value:
          'Mūsu galamērķi. Ceļo uz Eiropu ar Lux Express ērtajiem autobusiem.',
      },
      socialTitle: {
        value: 'Ceļojumi ar autobusu uz mūsu galamērķiem',
      },
      socialDescription: {
        value:
          'Mūsu galamērķi. Ceļo uz Eiropu ar Lux Express ērtajiem autobusiem.',
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value:
          '/images/thumbnails/all-destinations/Lux_Express_all_destinations_lv.jpg',
      },
    },
    ru: {
      title: {
        value: 'Международные автобусы для путешествия по Европе',
      },
      description: {
        value:
          'Путешествуйте международными автобусами по Европе и откройте европейские города. Купить билеты на автобус онлайн.',
      },
      socialTitle: {
        value: 'Международные автобусы для путешествия по Европе',
      },
      socialDescription: {
        value:
          'Путешествуйте международными автобусами по Европе и откройте европейские города. Купить билеты на автобус онлайн.',
      },
      pageKeywords: {
        value: '',
      },
      slug: {
        value: slug,
      },
      thumbnail: {
        value:
          '/images/thumbnails/all-destinations/Lux_Express_all_destinations_ru.jpg',
      },
    },
  };

  seo = seo[language];

  const slugs = {
    en: {
      value: slug,
    },
    et: {
      value: slug,
    },
    lv: {
      value: slug,
    },
    lt: {
      value: slug,
    },
    ru: {
      value: slug,
    },
  };

  return (
    <div className={cn(className, s.allDestinations)}>
      <Helmet {...meta({ seo, lang: language, slugs })} />
      <Container className={s.bannerContainer}>
        <Container full={false}>
          <section className={s.banner}>
            <h1>{t('all destinations')}</h1>
            <Search />
          </section>
        </Container>
      </Container>
      <Divider />
      <Container className={s.connections}>
        <div className={cn(s.greyBlock, s.top)} />
        <div className={cn(s.greyBlock, s.bottom)} />
        <Container full={false}>
          <section>
            {/* {isMob ? (
              coutries.map(({ country, count, key }) => key <= 3 ? (
                <Country key={country} {...{ country, count }} />
              ) : (
                <Collapse isOpened={false}>
              <Country {...{ country, count }}/>
                </Collapse>
              ))
              ) : (
              coutries.map(({ country, count }) => (
                <Country key={country} {...{ country, count }}/>
              ))
            )} */}

            <Query
              query={GET_DESTINATIONS_GROUPED}
              variables={{ lang: language, country: region }}
              ssr={false}
            >
              {({ data = {}, loading }) => {
                if (loading) return <Loading />;
                const destinations = data?.destinationsGrouped;
                return (
                  !!destinations?.length &&
                  destinations.map((country, key) => (
                    <Country {...{ country, isMob, t }} key={key} />
                  ))
                );
              }}
            </Query>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(AllDestinations);
