import React from 'react';
import cn from 'classnames';
import { Divider } from '@lux/ui';
import s from './modal.module.scss';

const ModalContent = ({ className, children, withoutHeaderHeight }) => {
  return (
    <div
      className={cn(
        s.content,
        withoutHeaderHeight && s.withoutHeaderHeight,
        className
      )}
    >
      {children}
    </div>
  );
};

export default ModalContent;
