import React from 'react';
import { withRouter } from 'react-router';
import { withTranslation, Trans } from 'react-i18next';
import { Modal, ModalContent, Button, Notice, Link } from '@lux/ui';
import s from './taxi-transfer-warning.module.scss';

const TaxiTransferWarning = ({ t, i18n, isOpen, toggleModal, history }) => {
  const { language } = i18n;

  const handleContinue = () => {
    history.push(`/${language}/change-tickets/search/`);
  };

  return (
    <Modal className={s.modal} {...{ isOpen, toggleModal }}>
      <ModalContent>
        <h1>{t('taxi transfer')}</h1>
        <Notice className={s.notice}>
          <p>{t('taxi.transfer.warning.p1')}</p>
          <p>{t('taxi.transfer.warning.p2')}</p>
          <p>
            <Trans i18nKey="if you have requested a taxi transfer on this trip, please contact us">
              If you have requested a taxi transfer on this trip, please
              <Link className={s.link} to="/contacts/">
                contact us
              </Link>
            </Trans>
          </p>
        </Notice>
        <div className={s.controls}>
          <Button
            className={s.button}
            text={t('i agree, continue')}
            onClick={handleContinue}
          />
          <div className={s.cancel} onClick={toggleModal}>
            {t('cancel')}
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default withRouter(withTranslation()(TaxiTransferWarning));
