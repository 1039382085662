import React, { useState } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { Query } from 'react-apollo';
import { toast } from 'react-toastify';
import { GET_PAYMENT_METHODS } from '@lux/spa/apollo/queries';
import { REMOVE_PAYMENT_METHOD } from '@lux/spa/apollo/mutations';
import * as Yup from 'yup';
import {
  Container,
  CardInput,
  Select,
  SelectOption,
  HiddenInput,
  TicketsInput,
  Notice,
  Button,
  Loading,
  Notify,
} from '@lux/ui';
import { ProfileMenu } from '@lux/spa/components';
import i from '@lux/spa/styles/icons.module.scss';
import s from './payment-methods.module.scss';

const cardNumberMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const Card = ({ id, name, lastFour, t, className, removeCard }) => {
  const [isConfirmRemove, setIsConfirmRemove] = useState(false);
  const toggleConfirmRemove = () => {
    setIsConfirmRemove(!isConfirmRemove);
  };

  let logo = '';

  switch (name.toLowerCase()) {
    case 'mastercard':
      logo = 'mastercard';
      break;
    case 'visa':
      logo = 'visa';
      break;
    default:
      logo = 'unknown-card';
  }

  return (
    <div className={s.card}>
      <div className={s.catdTitle}>
        {/* <div className={cn(s.check, s.checked)} /> */}
        <div className={s.logo}>
          <img src={`/images/svg/${logo}.svg`} alt="" />
        </div>
        <div className={s.name}>{name}</div>
        <div className={s.lastFour}>{`••••  ${lastFour}`}</div>
        <div className={cn(s.controls, isConfirmRemove && s.confirmRemove)}>
          {isConfirmRemove ? (
            <>
              <i
                className={cn(i.basket_v2, s.remove)}
                onClick={() => {
                  removeCard({
                    variables: {
                      id,
                    },
                  });
                  toggleConfirmRemove();
                }}
              />
              <i
                className={cn(i.close, s.cancel)}
                onClick={toggleConfirmRemove}
              />
            </>
          ) : (
            <i
              className={cn(i.basket_v2, s.remove)}
              onClick={toggleConfirmRemove}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const PaymentMethods = ({ t, className }) => {
  const [removeCard] = useMutation(REMOVE_PAYMENT_METHOD, {
    refetchQueries: [
      {
        query: GET_PAYMENT_METHODS,
      },
    ],
    onError: () => {
      toast.error(t('something wrong'));
    },
    onCompleted: data => {
      if (data.removeUserStoredPaymentMethod.error) {
        toast.error(t(data.removeUserStoredPaymentMethod.error.ErrorReason));
      }
      if (data.removeUserStoredPaymentMethod.success) {
        toast.success(t('card was successfully removed'));
      }
    },
  });

  return (
    <div className={cn(className, s.paymentMethods)}>
      <Container>
        <Container full={false}>
          <section>
            <h2>{t('my account')}</h2>
            <div className={s.block}>
              <ProfileMenu />
              <div className={s.content}>
                <div className={s.header}>
                  <h5 className={s.title}>{t('payment methods')}</h5>
                  {/* <div className={s.action}>{t('save')}</div> */}
                </div>
                <div className={s.info}>
                  <div className={s.cards}>
                    <Query
                      query={GET_PAYMENT_METHODS}
                      fetchPolicy="network-only"
                    >
                      {({ data, loading, error }) => {
                        if (loading) return <Loading />;
                        const userPaymentsMethods =
                          data?.userPaymentsMethods || [];
                        return userPaymentsMethods.length ? (
                          userPaymentsMethods.map(card => (
                            <Card {...card} removeCard={removeCard} />
                          ))
                        ) : (
                          <div className={s.noCards}>
                            <img src="/images/svg/no-cards.svg" alt="" />
                            <h3>{t('no cards')}</h3>
                            <p>
                              {t(
                                'the card will be added automatically after you make a purchase'
                              )}
                            </p>
                          </div>
                        );
                      }}
                    </Query>
                    <Notice className={s.notice}>
                      {t(
                        'the new payment method will be added automatically after the purchase'
                      )}
                    </Notice>
                  </div>

                  {/* <Collapse isOpened={isOpenForm}>
                    <Formik
                      validateOnMount
                      initialValues={{
                        cardNumber: '4917610000000000',
                        securityCode: '737',
                        expiryMonth: '3',
                        expiryYear: '2030',
                        holderName: '',
                      }}
                      validationSchema={Yup.object().shape({
                        cardNumber: Yup.string()
                          .min(16)
                          .required('required'),
                        securityCode: Yup.string().required(
                          t('validation.error.require', {
                            field: t('security code'),
                          })
                        ),
                        expiryMonth: Yup.string().required(
                          t('validation.error.require', {
                            field: t('month'),
                          })
                        ),
                        expiryYear: Yup.string().required(
                          t('validation.error.require', {
                            field: t('year'),
                          })
                        ),
                        holderName: Yup.string(),
                      })}
                      onSubmit={async values => {}}
                    >
                      {props => {
                        const {
                          values,
                          touched,
                          errors,
                          isSubmitting,
                          handleReset,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                        } = props;
                        const isTochedForm = !!Object.keys(touched).length;
                        const isValidForm = !Object.keys(errors).length;
                        const isDisabled = !isValidForm || isSubmitting;
                        return (
                          <form onSubmit={handleSubmit} className={s.form}>
                            <div className={cn(s.input)}>
                              <CardInput
                                guide={false}
                                name="cardNumber"
                                placeholder="••••  ••••  ••••  ••••"
                                mask={cardNumberMask}
                                label={t('Card number')}
                                required
                                value={values.cardNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errors={touched.cardNumber && errors.cardNumber}
                              />
                            </div>
                            <div className={cn(s.input, s.date)}>
                              <Select
                                name="expiryMonth"
                                label={t('month')}
                                value={values.expiryMonth}
                                className={s.select}
                                required
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errors={
                                  touched.expiryMonth && errors.expiryMonth
                                }
                              >
                                <SelectOption
                                  value=""
                                  text="MM"
                                  disabled
                                  selected
                                />
                                {Array(12)
                                  .fill()
                                  .map((day, key) => (
                                    <SelectOption
                                      key={key}
                                      value={key + 1}
                                      text={key + 1}
                                    />
                                  ))}
                              </Select>
                              <Select
                                name="expiryYear"
                                label={t('year')}
                                value={values.expiryYear}
                                className={s.select}
                                required
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errors={touched.expiryYear && errors.expiryYear}
                              >
                                <SelectOption
                                  value=""
                                  text="YYYY"
                                  disabled
                                  selected
                                />
                                {Array(10)
                                  .fill()
                                  .map((_, idx) => (
                                    <SelectOption
                                      key={idx}
                                      value={new Date().getFullYear() + idx}
                                      text={new Date().getFullYear() + idx}
                                    />
                                  ))}
                              </Select>
                            </div>
                            <div className={cn(s.input)}>
                              <HiddenInput
                                name="securityCode"
                                placeholder="•••"
                                label={t('security code')}
                                required
                                value={values.securityCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errors={
                                  touched.securityCode && errors.securityCode
                                }
                              />
                            </div>
                            <div className={cn(s.input)}>
                              <TicketsInput
                                name="holderName"
                                placeholder="e.g. Oliver Brown"
                                type="text"
                                label={t('name on card')}
                                required
                                value={values.holderName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errors={touched.holderName && errors.holderName}
                              />
                            </div>
                            <div className={s.controls}>
                              <Button
                                text="Add"
                                className={s.button}
                                onClick={() => {
                                  setIsOpenForm(isOpenForm => !isOpenForm);
                                }}
                              />
                              <Button
                                text="cancel"
                                flat
                                className={s.button}
                                onClick={() => {
                                  handleReset();
                                  setIsOpenForm(isOpenForm => !isOpenForm);
                                }}
                              />
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </Collapse> */}
                  {/* {!isOpenForm && (
                    <div
                      className={s.addNewCard}
                      onClick={() => setIsOpenForm(isOpenForm => !isOpenForm)}
                    >
                      + Add payment method
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(PaymentMethods);
