import React, { Fragment, useState, useContext, useEffect } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { DEFAULT_LANG } from '@lux/spa/config';
import { Query } from 'react-apollo';
import { Search, EmptySlider, meta, Traveling } from '@lux/spa/components';
import {
  Container,
  Button,
  Tabs,
  Tab,
  TabsPanel,
  TabContent,
  TabContainer,
  Divider,
  Slider,
  Link,
  SliderSlides,
  SliderControls,
  Text,
  Loading,
  PageLoading,
  UploadImages,
  EditSlider,
  LazyLoadImage,
  EditOrderWidget,
} from '@lux/ui';
import { NotFound } from '@lux/spa/routes';
import {
  ORDER_WIDGET,
  GET_STATIC_PAGE,
  GET_STATIC_PAGE_ADMIN,
  GET_ROUTES_GROUPED,
} from '@lux/spa/apollo/queries';
import {
  UPDATE_ORDER_WIDGET,
  CREATE_STATIC_PAGE,
  UPDATE_STATIC_PAGE,
  UPDATE_STATIC_WIDGET,
} from '@lux/spa/apollo/mutations';
import i from '@lux/spa/styles/icons.module.scss';
import { emptyData, defaultData } from './constants';
import {
  PeopleSlide,
  LoungeSlide,
  PopularRoute,
  PopularDestination,
  emptyPeople,
  emptyLounge,
  emptyPopularRoute,
  emptyPopularDestination,
} from './components';
import s from './home.module.scss';

const HomeContent = ({
  t,
  region,
  language,
  updatePage,
  content,
  lang,
  isEditMode,
  isStaff,
  className,
  location,
}) => {
  const { isMob } = useContext(WidthContext);
  const [isImagesModalOpen, setIsImagesModalOpen] = useState(false);
  const [isPeoplesSlider, setIsPeoplesSlider] = useState(false);
  const [isLoungeSlider, setIsLoungeSlider] = useState(false);
  // const [isBannerWidget, setIsBannerWidget] = useState(false);
  const [isRouteSlider, setIsRouteSlider] = useState(false);
  const [isDestinationSlider, setIsDestinationSlider] = useState(false);

  const toggleRouteSlider = () => {
    setIsRouteSlider(!isRouteSlider);
  };

  const toggleDestinationSlider = () => {
    setIsDestinationSlider(!isDestinationSlider);
  };

  // const toggleBannerWidget = () => {
  //   setIsBannerWidget(!isBannerWidget);
  // };

  const toggleModal = () => {
    setIsImagesModalOpen(!isImagesModalOpen);
  };

  const togglePeoplesSlider = () => {
    setIsPeoplesSlider(!isPeoplesSlider);
  };

  const toggleLoungeSlider = () => {
    setIsLoungeSlider(!isLoungeSlider);
  };

  const [updateWidget] = useMutation(UPDATE_STATIC_WIDGET);

  const { pageData = {}, seo = {} } = content;
  const {
    title,
    subTitle,
    banner,
    lounge,
    peoples,
    // heading1, exists but not used
    heading2,
    heading3,
    heading4,
    heading5,
    text1,
  } = pageData;

  const backgroundImage = banner.value
    ? `linear-gradient(90deg, #000000d9, transparent), url(${banner.value})`
    : `linear-gradient(90deg, #000000d9, transparent)`;

  // const [createStaticPage, { data, loading }] = useMutation(CREATE_STATIC_PAGE)

  // NOTE: for create Static Page
  // 1. set one country in constants.js for this page => emptyData.countries: ['ee']
  // 2. set same country in field country
  // 3. set slug

  const defaultLang = 'en';

  const handleCreateStaticPage = () => {
    createStaticPage({
      variables: {
        input: {
          isPublished: true,
          slug: 'home',
          country: 'fi',
          [defaultLang]: {
            ...emptyData,
          },
        },
      },
    });
  };

  const [createWidget] = useMutation(UPDATE_ORDER_WIDGET);
  // en ru et
  const createWidgetButton = () => {
    createWidget({
      variables: {
        input: {
          lang: 'lt',
          origin: 'popular-destinations',
          type: 'Destination',
          ids: '',
        },
      },
    });
  };

  const slugs = {
    en: {
      value: '/',
    },
    et: {
      value: '/',
    },
    lv: {
      value: '/',
    },
    lt: {
      value: '/',
    },
    ru: {
      value: '/',
    },
  };

  let canonicalPath = '';

  if (location.pathname === `/${language}`) {
    canonicalPath = `${DEFAULT_LANG === language ? '' : `/${language}`}/`;
  }

  return (
    <div className={cn(className, s.home)}>
      <Helmet
        {...meta({ seo, lang: language, slugs, meta, location, canonicalPath })}
      />
      {/* {isBannerWidget && (
        <EditWidget
          isOpen={isBannerWidget}
          toggleModal={toggleBannerWidget}
          widget={widget}
          emptyItem={emptyPopularDestination}
          updateWidget={updateBanner}
        >
          <BannerItem />
        </EditWidget>
      )} */}
      {isImagesModalOpen && (
        <UploadImages
          isOpen={isImagesModalOpen}
          toggleModal={toggleModal}
          updatePage={updatePage}
          image={banner}
        />
      )}
      {isPeoplesSlider && (
        <EditSlider
          emptySlide={emptyPeople}
          slider={peoples}
          isOpen={isPeoplesSlider}
          updatePage={updatePage}
          toggleModal={togglePeoplesSlider}
        >
          <PeopleSlide />
        </EditSlider>
      )}
      {isLoungeSlider && (
        <EditSlider
          emptySlide={emptyLounge}
          slider={lounge}
          isOpen={isLoungeSlider}
          updatePage={updatePage}
          toggleModal={toggleLoungeSlider}
        >
          <LoungeSlide />
        </EditSlider>
      )}
      <Container
        className={s.bannerContainer}
        style={{
          backgroundImage,
        }}
      >
        <Container full={false}>
          <section className={s.banner}>
            <div className={s.content}>
              <h1>
                <Text onChange={updatePage} field={title} />
              </h1>
              <h1 data-v2>
                <Text onChange={updatePage} field={subTitle} />
              </h1>
              {/* <Button text="Create Order Widget" onClick={createWidgetButton} /> */}
              {/* <Button
                text={`Create Static defaultLang: ${defaultLang} for countries: ${emptyData.countries.join()}`}
                onClick={handleCreateStaticPage}
              /> */}
              {isEditMode && (
                <Button
                  className={s.uploadBanner}
                  text={banner.value ? t('edit photo') : t('upload photo')}
                  onClick={toggleModal}
                />
              )}
              <Search />
            </div>
          </section>
        </Container>
      </Container>
      <Divider />
      <Container className={s.sliderDestination}>
        <div className={s.greyBlock} />
        <section>
          <Query
            query={ORDER_WIDGET}
            variables={{
              lang: language,
              origin: 'popular-routes',
            }}
          >
            {({ data = {}, loading }) => {
              if (loading) return <Loading />;
              const widget = data?.orderWidget;
              const routes = widget?.routes || [];
              return (
                <>
                  {isRouteSlider && (
                    <EditOrderWidget
                      emptySlide={emptyPopularRoute}
                      showField="departure"
                      showSecondField="arrival"
                      slider={{
                        id: widget.id,
                        slides: widget.routes,
                        type: 'Route',
                        origin: 'popular-routes',
                      }}
                      isOpen={isRouteSlider}
                      updatePage={updatePage}
                      toggleModal={toggleRouteSlider}
                    >
                      <PopularRoute />
                    </EditOrderWidget>
                  )}
                  {routes?.length ? (
                    <Slider
                      className={s.slider}
                      slidesCount={routes.length}
                      render={sliderProps => (
                        <>
                          {isEditMode && (
                            <Button
                              className={s.editSlider}
                              text={t('edit routes')}
                              onClick={toggleRouteSlider}
                            />
                          )}
                          <SliderSlides {...sliderProps} className={s.slides}>
                            {routes.map((route, key) => {
                              const { slug } = route.data;
                              const img = route.data.seo.thumbnail.value;
                              const arrival = route.arrival?.name;
                              const departure = route.departure?.name;
                              return (
                                <Link
                                  to={`/routes/${slug}/`}
                                  key={key}
                                  className={s.slide}
                                >
                                  <div
                                    className={s.image}
                                    style={{ backgroundImage: `url(${img})` }}
                                  />
                                  <div className={s.directions}>
                                    {`${t(departure)} - ${t(arrival)}`}
                                  </div>
                                  <div className={s.tickets}>
                                    {t('check tickets')}
                                  </div>
                                </Link>
                              );
                            })}
                          </SliderSlides>
                          <SliderControls
                            className={s.controls}
                            {...sliderProps}
                          />
                        </>
                      )}
                    />
                  ) : isEditMode ? (
                    <EmptySlider toggleEditSlider={toggleRouteSlider} />
                  ) : null}
                </>
              );
            }}
          </Query>
        </section>
      </Container>
      <Traveling home />
      <Container className={s.destinations}>
        <Container full={false}>
          <div className={cn(s.map, s.notMob)}>
            <LazyLoadImage src={`/images/home/maps/${language}.png`} />
          </div>
          <section>
            <h2 className={cn(s.title, s.titleDestinations)}>
              <Text onChange={updatePage} field={heading2} />
            </h2>
            <Query
              query={ORDER_WIDGET}
              variables={{
                lang: language,
                origin: 'popular-destinations',
              }}
            >
              {({ data = {}, loading }) => {
                if (loading) return <Loading />;
                const widget = data?.orderWidget;
                const destinations = widget?.destinations;
                return (
                  <>
                    {isDestinationSlider && (
                      <EditOrderWidget
                        emptySlide={emptyPopularDestination}
                        showField="city"
                        slider={{
                          id: widget.id,
                          slides: destinations,
                          type: 'Destination',
                          origin: 'popular-destinations',
                        }}
                        isOpen={isDestinationSlider}
                        updatePage={updatePage}
                        toggleModal={toggleDestinationSlider}
                      >
                        <PopularDestination />
                      </EditOrderWidget>
                    )}
                    {destinations?.length ? (
                      <Slider
                        className={s.slider}
                        withPadding
                        slidesCount={destinations.length}
                        render={sliderProps => (
                          <>
                            {isEditMode && (
                              <Button
                                className={s.editSlider}
                                text={t('edit destinations')}
                                onClick={toggleDestinationSlider}
                              />
                            )}
                            <SliderSlides {...sliderProps} className={s.slides}>
                              {destinations.map((destination, key) => {
                                const { slug } = destination.data;
                                const img =
                                  destination.data.seo.thumbnail.value;
                                const city = destination.city?.name;

                                return (
                                  <Link
                                    to={`/destinations/${slug}/`}
                                    key={key}
                                    className={s.slide}
                                  >
                                    {/* <LazyLoadImage src={img} alt={city} /> */}
                                    <div
                                      className={s.image}
                                      style={{ backgroundImage: `url(${img})` }}
                                    />
                                    <div className={s.direction}>{t(city)}</div>
                                    <div className={s.servises}>
                                      {`${t('sightseengs')}, ${t(
                                        'restaurants'
                                      )}, ${t('hotels')}`}
                                    </div>
                                    <div className={s.discover}>
                                      {t('discover')}
                                    </div>
                                  </Link>
                                );
                              })}
                            </SliderSlides>
                            <div className={s.checkDestinations}>
                              <SliderControls
                                className={s.controls}
                                {...sliderProps}
                              />
                              <Link
                                to="/all-destinations/"
                                className={s.checkDestinationsLink}
                              >
                                {t('check all destinations')}
                                <i className={i.arrowRight_v2} />
                              </Link>
                            </div>
                          </>
                        )}
                      />
                    ) : isEditMode ? (
                      <EmptySlider toggleEditSlider={toggleDestinationSlider} />
                    ) : null}
                  </>
                );
              }}
            </Query>
            <div className={cn(s.description, s.descriptionOperates)}>
              <h3>
                <Text onChange={updatePage} field={heading3} />
              </h3>
              <p>
                <Text onChange={updatePage} field={text1} />
              </p>
            </div>
            <br />
            <Query
              query={GET_ROUTES_GROUPED}
              variables={{
                lang: language,
                country: region,
              }}
            >
              {({ data = {}, loading }) => {
                if (loading) return <Loading />;
                const routes = data?.routesGrouped;
                return (
                  !!routes?.length && (
                    <Tabs className={s.tabsDirection}>
                      <TabsPanel className={s.tabsPanel}>
                        {routes.map(({ countryName }) => (
                          <Tab
                            className={s.tab}
                            text={t(countryName)}
                            key={countryName}
                          />
                        ))}
                      </TabsPanel>
                      <TabContainer>
                        {routes.map(country => {
                          return (
                            <TabContent
                              className={s.tabContent}
                              key={country.countryName}
                            >
                              <div className={s.roadConnections}>
                                {country.routes.map((route, key) => {
                                  return (
                                    <Link
                                      to={`/routes/${route?.data?.slug}/`}
                                      className={s.direction}
                                      key={key}
                                    >
                                      <div
                                        className={s.point}
                                        title={route.departure.name}
                                      >
                                        {t(route.departure.name)}
                                      </div>
                                      <img
                                        src="/images/svg/road-connection.svg"
                                        alt=""
                                      />
                                      <div
                                        className={cn(s.point, s.to)}
                                        title={route.arrival.name}
                                      >
                                        {t(route.arrival.name)}
                                      </div>
                                    </Link>
                                  );
                                })}
                              </div>
                            </TabContent>
                          );
                        })}
                      </TabContainer>
                    </Tabs>
                  )
                );
              }}
            </Query>
          </section>
        </Container>
      </Container>
      <Container className={s.lounge}>
        <Container full={false}>
          <section>
            <h2 className={s.title}>
              <Text onChange={updatePage} field={heading4} />
            </h2>
            {lounge.slides.length ? (
              <Slider
                slidesCount={lounge.slides.length}
                withPadding={isMob}
                className={s.sliderLounge}
                render={sliderProps => (
                  <>
                    {isEditMode && (
                      <Button
                        className={s.editSlider}
                        text={t('edit slider')}
                        onClick={toggleLoungeSlider}
                      />
                    )}
                    <div className={s.greyBlock} />
                    <div className={s.descriptions}>
                      {lounge.slides.map(
                        (slide, key) =>
                          key === sliderProps.currentSlide && (
                            <div className={s.description} key={key}>
                              <div className={s.subTitle}>
                                {slide.title.value}
                              </div>
                              <div className={s.text}>
                                {slide.subTitle.value}
                              </div>
                            </div>
                          )
                      )}
                      {!isMob && (
                        <SliderControls
                          className={s.controls}
                          {...sliderProps}
                        />
                      )}
                    </div>
                    <SliderSlides {...sliderProps} className={s.slides}>
                      {lounge.slides.map((slide, key) => (
                        <div key={key} className={s.slide}>
                          <LazyLoadImage
                            src={slide.img.value}
                            alt={slide.alt.value}
                          />
                        </div>
                      ))}
                    </SliderSlides>
                    {isMob && (
                      <SliderControls className={s.controls} {...sliderProps} />
                    )}
                  </>
                )}
              />
            ) : isEditMode ? (
              <EmptySlider toggleEditSlider={toggleLoungeSlider} />
            ) : null}
          </section>
        </Container>
      </Container>
      <Container className={s.forLuxexpress}>
        <div className={s.greyBlock} />
        <Container full={false}>
          <section>
            <h2 className={s.title}>
              <Text onChange={updatePage} field={heading5} />
            </h2>
            {peoples.slides.length ? (
              <Slider
                slidesCount={peoples.slides.length}
                className={s.sliderPeople}
                render={sliderProps => (
                  <>
                    {isEditMode && (
                      <Button
                        className={s.editSlider}
                        text={t('edit slider')}
                        onClick={togglePeoplesSlider}
                      />
                    )}
                    <SliderSlides
                      {...sliderProps}
                      withDivider
                      dividerProps={{
                        height: '3px',
                        absolute: true,
                        className: s.divider,
                      }}
                      className={s.slides}
                    >
                      {peoples.slides.map((slide, key) => (
                        <div key={key} className={s.slide}>
                          <LazyLoadImage
                            src={slide.img.value}
                            alt={slide.alt.value}
                          />
                        </div>
                      ))}
                      {/* <Divider absolute height={3} /> */}
                    </SliderSlides>

                    <div className={cn(s.container, s.containerSlidesControl)}>
                      <SliderControls className={s.controls} {...sliderProps} />
                      <div className={cn(s.content, s.contentSLideContainer)}>
                        {peoples.slides.map(
                          (slide, key) =>
                            key === sliderProps.currentSlide && (
                              <Fragment key={key}>
                                <div className={s.info}>
                                  <div className={s.description}>
                                    <div className={s.subTitle}>
                                      {slide.firstName.value}
                                      <br />
                                      {slide.secondName.value}
                                    </div>
                                    <p className={s.text}>
                                      {slide.description.value}
                                    </p>
                                  </div>
                                </div>
                                <div className={s.quote}>
                                  <i className={i.quote} />
                                  <h4>{slide.quote.value}</h4>
                                </div>
                              </Fragment>
                            )
                        )}
                      </div>
                    </div>
                  </>
                )}
              />
            ) : isEditMode ? (
              <EmptySlider toggleEditSlider={togglePeoplesSlider} />
            ) : null}
          </section>
        </Container>
      </Container>
    </div>
  );
};

const Home = ({
  t,
  lang,
  match,
  i18n,
  isStaff,
  region,
  isEditMode,
  page = { en: emptyData },
  dispatch = () => {},
  ...props
}) => {
  const slug = 'home';
  const { language } = i18n;
  const QUERY = isStaff ? GET_STATIC_PAGE_ADMIN : GET_STATIC_PAGE;

  const variables = {
    slug,
    ...(isStaff
      ? {}
      : {
          lang: language,
        }),
    country: region,
  };

  const { data, loading } = useQuery(QUERY, { variables });

  const updatePage = field => {
    dispatch({
      type: 'UPDATE_DATA',
      payload: {
        field,
      },
    });
  };

  useEffect(() => {
    if (isStaff) {
      dispatch({
        type: 'UPDATE',
        payload: {
          defaultData,
          emptyData,
          CREATE_PAGE: CREATE_STATIC_PAGE,
          UPDATE_PAGE: UPDATE_STATIC_PAGE,
          originalPage: data?.adminStaticPage,
          page: data?.adminStaticPage,
        },
      });
    }
  }, [data]);

  // useEffect(() => {
  //   dispatch({
  //     type: 'UPDATE',
  //     payload: {
  //       loading
  //     }
  //   })
  // }, [loading])

  useEffect(() => {
    const isExistLang = page?.[lang];

    if (isEditMode && !isExistLang) {
      dispatch({
        type: 'UPDATE_PAGE',
        payload: {
          [lang]: {
            ...emptyData,
            lang,
            countries: [region],
          },
        },
      });
    }
  }, [isEditMode]);

  // const content = {
  //   ...(isStaff ? {
  //     ...emptyData,
  //     ...page?.[lang]
  //   } : {
  //     ...
  //   })
  // } : {
  //   lang: language,
  //   slug,
  //   ...data.staticPage?.data
  // })
  // }

  const isExistLangForPage = !!page?.[lang];

  const content = isStaff
    ? {
        ...emptyData,
        ...page?.[lang],
      }
    : data?.staticPage?.data;

  // useEffect(() => {
  //   if (isExistLangForPage && isStaff) {
  //     toast.warn(`there is no translation in ${lang} for ${countries[region]}`)
  //   }
  // }, [isExistLangForPage])

  // return loading ? <PageLoading /> : <NotFound />;
  return loading ? (
    <PageLoading />
  ) : content || isStaff ? (
    <HomeContent
      content={{
        ...content,
        pageData: {
          ...emptyData.pageData,
          ...content.pageData,
        },
      }}
      {...{ updatePage, language, isEditMode, isStaff, region, t }}
      {...props}
    />
  ) : (
    <NotFound />
  );
};

export default withTranslation()(Home);
