import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { MODIFICATION_ADYEN_PAYMENT_DETAILS } from '@lux/spa/apollo/mutations';
import { StoreContext } from '@lux/spa/store';
import ThanksLayoutSuccess from '../../thanks-layout-success.jsx';
import ThanksLayoutPending from '../../thanks-layout-pending.jsx';
import ThanksLayoutError from '../../thanks-layout-error.jsx';

const Adyen = ({ getTicketsFileUrl, fileLoading, urlParams }) => {
  const [adyenPaymentDetails, { loading = true }] = useMutation(
    MODIFICATION_ADYEN_PAYMENT_DETAILS
  );
  const [isError, setIsError] = useState(false);

  const [state, dispatch] = useContext(StoreContext);

  const { totalPrice, forAnalitics, GTM, trip } = state.changeTickets;
  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';
  const { from, to, returnDate, promocode } = state.changeTickets.search;
  const { GTMLoaded } = state.app;

  useEffect(() => {
    const shopperOrder = trip.ShoppingBasketId;
    const requiredFields = JSON.parse(localStorage.getItem('details'));

    if (requiredFields) {
      const postParams = JSON.parse(localStorage.getItem('params'));
      const input = {
        orderId: shopperOrder,
      };

      const details = {};

      requiredFields.map(item => {
        if (postParams) {
          details[item.key] = postParams?.[item.key];
        } else {
          details[item.key] = urlParams?.[item.key];
        }
      });

      input.details = {
        ...details,
      };

      if (shopperOrder) {
        adyenPaymentDetails({
          variables: {
            input,
          },
        })
          .then(data => {
            if (data?.data?.modificationAdyenPaymentDetails?.error) {
              setIsError(true);
            }

            if (data?.data?.modificationAdyenPaymentDetails?.success) {
              const quantity = data?.data?.modificationAdyenPaymentDetails.objects[0].Passengers.reduce(
                (sum, pass) => (sum += pass.Tickets.length),
                0
              );
              const Id =
                data?.modificationAdyenPaymentDetails.objects[0]
                  .ShoppingBasketCode;

              if (GTMLoaded && !GTM.thanksStep) {
                // dataLayer.push({
                //   tripType: returnDate ? 'return-trip' : 'one-way',
                //   departureStopId: from.BusStopId,
                //   destinationStopId: to.BusStopId,
                //   currencyCode: currencyName,
                //   route: `${from.CityName}-${to.CityName}`,
                //   price: totalPrice,
                //   promocode: promocode || null,
                //   departureDestination: `${from.CountryName}-${to.CountryName}`,
                //   stage: 'purchase',
                //   event: 'payment-success',
                //   Id,
                //   quantity,
                // });

                dispatch({
                  type: 'UPDATE_CHANGE_TICKETS',
                  payload: {
                    GTM: {
                      ...GTM,
                      thanksStep: true,
                    },
                  },
                });
              }

              getTicketsFileUrl();
            }

            localStorage.removeItem('params');
          })
          .catch(() => {
            setIsError(true);
          });
      }
    } else {
      if (GTMLoaded && !GTM.thanksStep) {
        // dataLayer.push({
        //   tripType: returnDate ? 'return-trip' : 'one-way',
        //   departureStopId: from.BusStopId,
        //   destinationStopId: to.BusStopId,
        //   currencyCode: currencyName,
        //   route: `${from.CityName}-${to.CityName}`,
        //   price: totalPrice,
        //   promocode: promocode || null,
        //   departureDestination: `${from.CountryName}-${to.CountryName}`,
        //   stage: 'purchase',
        //   event: 'payment-success',
        //   ...(forAnalitics?.id
        //     ? {
        //         Id: forAnalitics.id,
        //         quantity: forAnalitics.quantity,
        //       }
        //     : {}),
        // });

        dispatch({
          type: 'UPDATE_CHANGE_TICKETS',
          payload: {
            GTM: {
              ...GTM,
              thanksStep: true,
            },
          },
        });
      }

      getTicketsFileUrl();
    }
  }, []);

  return loading ? (
    <ThanksLayoutPending />
  ) : isError ? (
    <ThanksLayoutError renderCancel />
  ) : (
    <ThanksLayoutSuccess
      type="card"
      fileLoading={fileLoading}
      getTicketsFileUrl={getTicketsFileUrl}
    />
  );
};

export default Adyen;
