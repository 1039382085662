import React from 'react';
import cn from 'classnames';

import s from './divider.module.scss';

const Divider = ({ className, height, absolute, vertical }) => {
  const classes = cn(
    className,
    s.divider,
    absolute && s.absolute,
    vertical && s.vertical
  );
  return (
    <div
      className={classes}
      style={{
        ...(!vertical && height
          ? {
              height: height || '6px',
            }
          : {}),
        ...(vertical && height
          ? {
              width: height || '6px',
            }
          : {}),
      }}
    >
      <div className={s.part} />
      <div className={s.part} />
    </div>
  );
};

export default Divider;
