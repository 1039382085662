import React from 'react';

import { Container, LazyLoadImage } from '@lux/ui';
import s from './page-loading.module.scss';

const PageLoading = () => (
  <Container className={s.loading}>
    <Container full={false}>
      <section>
        <div className={s.image}>
          <LazyLoadImage src="/images/svg/loading.svg" alt="" />
        </div>
      </section>
    </Container>
  </Container>
);

export default PageLoading;
