import React, { useRef, useState, useEffect, useContext } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { StoreContext } from '@lux/spa/store';
// import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Loading } from '@lux/ui';
// import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder'
// import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import s from './editor.module.scss';

const Editor = ({
  t,
  i18n,
  html,
  className,
  placeholder,
  onChange,
  isDocument = false,
  ...props
}) => {
  const { language } = i18n;
  const [state] = useContext(StoreContext);
  const { app } = state;
  const { isEditMode } = app;

  const [editorLoaded, setEditorLoaded] = useState(false);
  // const [uploaderLoaded, setUploaderLoaded] = useState(false)

  const editorRef = useRef();
  // const uploadRef = useRef()

  const { CKEditor, Editor } = editorRef.current || {};

  // const { Base64UploadAdapter } = uploadRef.current || {}

  useEffect(() => {
    editorRef.current = {
      // InlineEditor: require('./ckeditor5/build/ckeditor.js'),
      // InlineEditor: require('@ckeditor/ckeditor5-build-inline'),
      // TableCellProperties: require('@ckeditor/ckeditor5-table/src/tablecellproperties')
      // Base64UploadAdapter: require('@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter')

      // Finder: require('@ckeditor/ckeditor5-ckfinder/src/ckfinder'),

      // DocumentEditor: require('@ckeditor/ckeditor5-build-decoupled-document')

      CKEditor: require('@ckeditor/ckeditor5-react'),
      // CKFinder: require('./ckfinder/ckfinder.js'),
      Editor: require('./ckeditor.js'),
    };

    setEditorLoaded(true);
  }, []);

  return isEditMode ? (
    editorLoaded ? (
      <div className={cn(s.editor, !html && s.empty, className)}>
        <CKEditor
          editor={Editor}
          data={html || placeholder}
          disabled={!isEditMode}
          onChange={onChange}
          config={{
            // plugins: [CKFinder],
            mediaEmbed: { previewsInData: true },
            ckfinder: {
              uploadUrl:
                'https://ckfinder-prod.luxexpress.eu/connector.php?command=QuickUpload&type=Files&responseType=json',
              options: {
                resourceType: 'Images',
                connectorPath:
                  'https://ckfinder-prod.luxexpress.eu/connector.php',
              },
            },
            baseFloatZInde: 100001,
            fontSize: {
              options: [12, 16, 20, 32, 42, 54],
            },
            alignment: {
              options: ['left', 'right', 'center', 'justify'],
            },
            toolbar: {
              items: [
                'heading',
                '|',
                'alignment',
                'fontColor',
                'fontSize',
                'bold',
                'italic',
                'link',
                'bulletedList',
                'numberedList',
                '|',
                'indent',
                'outdent',
                '|',
                // 'CKFinder',
                'imageUpload',
                'insertTable',
                'mediaEmbed',
                'blockQuote',
                'horizontalLine',
                'specialCharacters',
                'removeFormat',
                '|',
                'undo',
                'redo',
              ],
            },
            language,
            image: {
              toolbar: [
                'imageTextAlternative',
                'imageStyle:full',
                'imageStyle:side',
              ],
            },
            table: {
              contentToolbar: [
                'tableColumn',
                'tableRow',
                'mergeTableCells',
                'tableCellProperties',
                'tableProperties',
              ],
            },
            licenseKey: '',
          }}
          // onInit={editor => {}}
          // onBlur={(event, editor) => {}}
          // onFocus={(event, editor) => {}}
          {...props}
        />
      </div>
    ) : (
      <Loading />
    )
  ) : (
    <div
      className={cn(s.editor, className)}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );

  // return editorLoaded ? (
  //   <div className={cn(s.editor, className)}>
  //     <CKEditor
  //       // editor={isDocument ? DocumentEditor : InlineEditor}
  //       editor={Editor}
  //       data={html || placeholder}
  //       disabled={!isEditMode}
  //       onChange={onChange}
  //       config={{
  //         // plugins: [CKFinder],
  //         ckfinder: {
  //           uploadUrl: 'https://ckfinder-prod.luxexpress.eu/connector.php?command=QuickUpload&type=Files&responseType=json',
  //           options: {
  //             resourceType: 'Images',
  //             connectorPath: 'https://ckfinder-prod.luxexpress.eu/connector.php'
  //           }
  //         },
  //         toolbar: {
  // 					items: [
  // 						'heading',
  // 						'|',
  // 						'fontColor',
  // 						'bold',
  // 						'italic',
  // 						'link',
  // 						'bulletedList',
  // 						'numberedList',
  // 						'|',
  // 						'indent',
  // 						'outdent',
  // 						'|',
  // 						// 'CKFinder',
  // 						'imageUpload',
  // 						'insertTable',
  // 						'mediaEmbed',
  // 						'blockQuote',
  // 						'horizontalLine',
  // 						'specialCharacters',
  // 						'removeFormat',
  // 						'|',
  // 						'undo',
  // 						'redo'
  // 					]
  // 				},
  // 				language,
  //         image: {
  // 					toolbar: [
  // 						'imageTextAlternative',
  // 						'imageStyle:full',
  // 						'imageStyle:side'
  // 					]
  // 				},
  // 				table: {
  // 					contentToolbar: [
  // 						'tableColumn',
  // 						'tableRow',
  // 						'mergeTableCells',
  // 						'tableCellProperties',
  // 						'tableProperties'
  // 					]
  // 				},
  // 				licenseKey: '',
  //       }}
  //       // onInit={editor => {}}
  //       // onBlur={(event, editor) => {}}
  //       // onFocus={(event, editor) => {}}
  //       {...props}
  //     />
  //   </div>
  // ) : (
  //   <Loading />
  // )
};

export default withTranslation()(Editor);
