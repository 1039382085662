import React, { useState, cloneElement, useReducer } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { GET_WIDGET_ADMIN } from '@lux/spa/apollo/queries';
import { Button, Modal, ModalHeader, ModalContent, DragAndDrop } from '@lux/ui';
import { langs } from '@lux/spa/i18n';
import i from '@lux/spa/styles/icons.module.scss';
import EditWidgetItem from './edit-widget-item';
import s from './edit-widget.module.scss';

const widgetReducer = (state, action) => {
  const { lang } = state;

  switch (action.type) {
    case 'CHANGE_LANG':
      return {
        ...state,
        lang: action.payload.lang,
      };
    case 'ADD_ITEM':
      return {
        ...state,
        [lang]: {
          ...state[lang],
          lang,
          countries: ['ee'],
          widgetData: {
            items: state?.[lang]
              ? [
                  ...state[lang].widgetData.items,
                  {
                    ...action.payload.item,
                    order:
                      Math.max(
                        ...state[lang].widgetData.items.map(item => item.order)
                      ) + 1,
                  },
                ]
              : [
                  {
                    ...action.payload.item,
                    order: 1,
                  },
                ],
          },
        },
        newItem: {
          ...state.newItem,
          order: state?.[lang]
            ? Math.max(
                ...state[lang].widgetData.items.map(item => item.order)
              ) + 1
            : 1,
        },
      };
    case 'UPDATE_ITEM':
      return {
        ...state,
        [lang]: {
          ...state[lang],
          widgetData: {
            items: state?.[lang]
              ? state[lang].widgetData.items.map(item =>
                  item.order === action.payload.item.order
                    ? {
                        ...item,
                        ...action.payload.item,
                      }
                    : {
                        ...item,
                      }
                )
              : [action.payload.item],
          },
        },
        newItem: {
          ...state.newItem,
          order:
            Math.max(...state[lang].widgetData.items.map(item => item.order)) +
            1,
        },
      };
    case 'TOGGLE_PUBLISHED':
      return {
        ...state,
        [lang]: {
          ...state[lang],
          widgetData: {
            items: state?.[lang]
              ? state[lang].widgetData.items.map(item =>
                  item.order === action.payload.item.order
                    ? {
                        ...item,
                        isPublished: !(
                          item.isPublished === undefined || item.isPublished
                        ),
                      }
                    : {
                        ...item,
                      }
                )
              : [action.payload.item],
          },
        },
      };
    case 'REMOVE_ITEM':
      return {
        ...state,
        [lang]: {
          ...state[lang],
          widgetData: {
            items: state[lang].widgetData.items.filter(
              item => item.order !== action.payload.item.order
            ),
          },
        },
        newItem: {
          ...state.newItem,
          order:
            Math.max(...state[lang].widgetData.items.map(item => item.order)) +
            1,
        },
      };
    case 'UPDATE_ITEMS':
      return {
        ...state,
        [lang]: {
          ...state[lang],
          widgetData: {
            items: action.payload.items,
          },
        },
      };
    default:
      throw new Error();
  }
};

const EditWidgetNew = ({
  t,
  i18n,
  isOpen,
  toggleModal,
  children,
  widget = {},
  updateWidget,
  showField,
  showSecondField,
  emptyItem,
  className,
  contentClassName,
  ...props
}) => {
  const { language } = i18n;

  const initialWidget = {
    lang: language,
    newItem: {
      ...emptyItem,
      order: widget?.[language]
        ? widget[language].widgetData.items.length + 1
        : 1,
    },
    ...widget,
  };

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, widgetDispatch] = useReducer(widgetReducer, initialWidget);

  const { newItem, lang, name } = state;

  const items = state?.[lang]?.widgetData?.items || [];

  const [item, setItem] = useState(newItem);
  const [isEditItem, setIsEditItem] = useState(false);

  const updateItems = items => {
    widgetDispatch({
      type: 'UPDATE_ITEMS',
      payload: {
        items,
      },
    });
  };

  const toggleEditItem = item => {
    setIsEditItem(!isEditItem);
    setItem(item);
  };

  const handleSaveWidget = () => {
    const newState = state;
    delete newState.lang;
    delete newState.newItem;

    updateWidget({
      variables: {
        input: {
          ...newState,
        },
      },
      refetchQueries: [
        {
          query: GET_WIDGET_ADMIN,
          variables: {
            lang: language,
            region: 'ee',
            name,
          },
        },
      ],
    });
    toggleModal();
  };

  const removeItem = item => {
    widgetDispatch({
      type: 'REMOVE_ITEM',
      payload: {
        item,
      },
    });
  };

  const changeLang = lang => {
    widgetDispatch({
      type: 'CHANGE_LANG',
      payload: {
        lang,
      },
    });
  };

  const togglePublished = item => {
    widgetDispatch({
      type: 'TOGGLE_PUBLISHED',
      payload: {
        item,
      },
    });
  };

  return (
    <Modal className={s.modal} {...{ isOpen, toggleModal }}>
      <ModalHeader text="Edit Widget" />
      <ModalContent className={cn(s.content, contentClassName)}>
        {isEditItem ? (
          cloneElement(children, {
            item: {
              ...emptyItem,
              ...item,
            },
            lang,
            items,
            widgetDispatch,
            toggleEditItem,
            ...props,
          })
        ) : (
          <div className={s.editWidget}>
            <div className={s.panel}>
              {langs.map(l => (
                <div
                  key={l}
                  className={cn(s.tab, l === lang && s.active)}
                  onClick={() => changeLang(l)}
                  children={l}
                />
              ))}
            </div>
            <div className={cn(s.items, !items.length && s.empty)}>
              {items.length ? (
                <DragAndDrop
                  updateItems={updateItems}
                  lang={lang}
                  itemProps={{
                    showField,
                    showSecondField,
                    toggleEditItem,
                    togglePublished,
                    removeItem,
                  }}
                  ItemLayout={EditWidgetItem}
                  items={items}
                />
              ) : (
                <div className={s.emptyText}>{t('items')}</div>
              )}
            </div>
            <div className={s.controls}>
              <Button
                text={`+ ${t('add item')}`}
                className={s.addItem}
                onClick={() => toggleEditItem(newItem)}
              />
              <Button text="Save" onClick={handleSaveWidget} />
              <span onClick={toggleModal}>Cancel</span>
            </div>
          </div>
        )}
      </ModalContent>
    </Modal>
  );
};

export default withTranslation()(EditWidgetNew);
