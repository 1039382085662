import { currencies } from '@lux/spa/config';

export const initialStateApp = {
  language: 'en',
  isEditMode: false,
  isShowSeo: false,
  copyFrom: 'empty',
  activeLang: 'et',
  ticketsUi: false,
  GTMLoaded: false,
  currenciesLoading: true,
  currency: 'CURRENCY.RUB',
  currencyName: 'RUB',
  symbol: '₽',
  mainAlert: {
    enabled: false,
    text: '',
  },
  currencies,
  isOpenLoginModal: false,
};

const appReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_APP': {
      return {
        ...state,
        ...action.payload,
      };
    }

    case 'UPDATE_LANGUAGE':
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
