import React, { useContext, useState, useEffect } from 'react';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import { StoreContext } from '@lux/spa/store';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { CHANGE_SEAT_NUMBER } from '@lux/spa/apollo/mutations';
import { SEAT_PLAN, BASKET_PRODUCT_GROUPS } from '@lux/spa/apollo/queries';
import {
  updatePrices,
  updatePassengersPrices,
  updatePassengersPricesV2,
} from 'utils';
import i from '@lux/spa/styles/icons.module.scss';
import styles from './bus.module.scss';

const Bus = ({
  t,
  i18n,
  className,
  leg = {},
  setIsDisabledContinue = () => {},
}) => {
  const { language } = i18n;
  const [state, dispatch] = useContext(StoreContext);
  const {
    activePassenger,
    products,
    currency,
    currentStepDisabled,
    promocode,
  } = state.tickets;

  const { SeatInfo } = leg;
  const { TicketNumber, TripBusProfileId, SeatFloorNumber } = SeatInfo[
    activePassenger - 1
  ];

  const productsIds = products?.OutboundJourneys?.[0]?.Products?.map(
    item => item.ProductId
  );

  const { Buses } = leg.SeatPlan;
  const seatRows = Buses[0].Floors[0].SeatRows;
  const [newSeat, setNewSeat] = useState({});

  const [getBasketGroup, { loading: loadingBasket }] = useLazyQuery(
    BASKET_PRODUCT_GROUPS,
    {
      fetchPolicy: 'network-only',
      variables: {
        lang: language,
      },
      onError: () => {
        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            isSomethingWrong: true,
          },
        });
      },
      onCompleted: data => {
        const { basketProductGroups = null } = data;
        if (!basketProductGroups) {
          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              isSomethingWrong: true,
            },
          });
          return;
        }

        const inboundPassengers = basketProductGroups.InboundJourneys;
        const outboundPassengers = basketProductGroups.OutboundJourneys;
        const { passengerPrices, passengersFee } = updatePassengersPricesV2(
          products,
          outboundPassengers,
          inboundPassengers
        );

        const { TotalPrice } = basketProductGroups;

        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            totalPrice: TotalPrice,
            passengerPrices,
            passengersFee,
          },
        });
      },
    }
  );

  const [updateSeats] = useLazyQuery(SEAT_PLAN, {
    fetchPolicy: 'network-only',
    variables: {
      products: productsIds,
      currency,
    },
    onCompleted: data => {
      const newLegs = data.seatPlan.Legs;
      const updatedProducts = {
        ...products,
      };

      newLegs.forEach(leg => {
        if (leg.IsOutbound) {
          updatedProducts.OutboundJourneys[0].Legs[
            leg.OrderNumber - 1
          ].SeatPlan = leg;
        } else {
          updatedProducts.InboundJourneys[0].Legs[
            leg.OrderNumber - 1
          ].SeatPlan = leg;
        }
      });

      dispatch({
        type: 'UPDATE_TICKETS',
        payload: {
          products: updatedProducts,
        },
      });
    },
  });

  const [updateSeat, { loading }] = useMutation(CHANGE_SEAT_NUMBER, {
    onCompleted: data => {
      if (data?.changeSeatNumber?.error) {
        const error = data?.changeSeatNumber?.error;

        if (error) {
          if (error.ErrorCode === 'seat_already_taken') {
            dispatch({
              type: 'UPDATE_TICKETS',
              payload: {
                isSomethingWrongMessage: t('warn.seat.already.taken'),
                isSomethingWrongTitle: t('seat is already taken'),
              },
            });

            updateSeats();
            return;
          }

          if (error.ErrorCode === 'basket_expired') {
            dispatch({
              type: 'UPDATE_TICKETS',
              payload: {
                isBasketExrpired: true,
              },
            });
          } else {
            dispatch({
              type: 'UPDATE_TICKETS',
              payload: {
                isSomethingWrong: true,
              },
            });
          }
        }
        return;
      }

      if (data?.changeSeatNumber?.success) {
        const updatedProducts = {
          ...products,
        };
        const passanger = leg.SeatInfo[activePassenger - 1];

        passanger.SeatNumber = newSeat.SeatNumber;

        if (newSeat.SeatClassCategory === 'SEAT_CLASS_CATEGORY.BUSINESS') {
          passanger.IsBusinessClass = true;
        } else {
          passanger.IsBusinessClass = false;
        }

        const prices = passanger.Prices.filter(
          price => passanger.IsBusinessClass === price.IsBusinessClass
        );

        const hasCampaign = prices.some(price => price.IsCampaign);

        passanger.HasCampaign = hasCampaign;
        passanger.IsCampaign = hasCampaign;

        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            products: updatedProducts,
          },
        });

        updateSeats();
        // getBasket();
        getBasketGroup();
      }
    },
  });

  const selectedSeats = SeatInfo.map(seat => seat.SeatNumber);

  const selectSeat = seat => {
    setNewSeat(seat);

    const input = {
      TripBusProfiles: [
        {
          TripBusProfileId: `${TripBusProfileId}`,
          SeatInfo: [
            {
              TicketNumber,
              SeatFloorNumber,
              SeatNumber: seat.SeatNumber,
              SeatNumberLetter: seat.SeatNumberLetter,
            },
          ],
        },
      ],
    };

    // let isBusinessCampaign = false;
    // const isBusinesClass = seat.SeatClass === 'SEAT_CLASS.A';

    // if (isBusinesClass) {
    //   isBusinessCampaign = SeatInfo[0].Prices.some(
    //     price => price.IsBusinessClass && price.IsCampaign
    //   );
    // }

    updateSeat({
      variables: {
        input: {
          ...input,
          CurrencyXc: currency,
          // ...(isBusinesClass && !isBusinessCampaign
          //   ? {}
          //   : {
          //       CampaginCode: promocode,
          //     }),
        },
      },
    });
  };

  useEffect(() => {
    setIsDisabledContinue(loading || loadingBasket);
    dispatch({
      type: 'UPDATE_TICKETS',
      payload: {
        currentStepDisabled: loading || loadingBasket,
      },
    });
  }, [loading, loadingBasket]);

  const classes = cx(styles.bus, className);

  return (
    <div className={classes}>
      <div className={cx(styles.decorate, styles.left)} />
      <div className={cx(styles.decorate, styles.right)} />
      <div className={styles.steeringWheel}>
        <i className={i.steeringWheel} />
      </div>
      <div className={styles.seats}>
        {!!seatRows.length &&
          seatRows.map((row, key) => {
            const orderedSeats = row?.Seats.sort(
              (a, b) => a.ColumnNumber - b.ColumnNumber
            );
            return (
              <div className={styles.row} key={key}>
                {orderedSeats.map((seat, key) => {
                  // const isDefault = defaultSelectedSeats.includes(
                  //   seat.SeatNumber
                  // );
                  const isSelected = selectedSeats.includes(seat.SeatNumber);
                  const classes = cx(
                    styles.seat,
                    seat.SeatClass === 'SEAT_CLASS.B'
                      ? styles.regular
                      : styles.lounge,
                    isSelected && styles.selected,
                    // !isDefault && !seat.IsAvailable && styles.occupied
                    !isSelected && !seat.IsAvailable && styles.occupied,
                    loading &&
                      seat.SeatNumber === newSeat.SeatNumber &&
                      styles.loading
                  );

                  return seat.IsEmptyArea ? (
                    <div key={key} className={styles.seat} />
                  ) : seat.SeatClass === 'SEAT_CLASS.B' ? (
                    <div
                      className={classes}
                      key={key}
                      onClick={() => {
                        if (
                          !currentStepDisabled &&
                          !isSelected &&
                          seat.IsAvailable
                        ) {
                          selectSeat(seat);
                        }
                      }}
                    >
                      <div className={styles.icon}>
                        {isSelected
                          ? `P${selectedSeats.indexOf(seat.SeatNumber) + 1}`
                          : seat.SeatNumber}
                      </div>
                    </div>
                  ) : (
                    <div
                      data-title={t('tickets.notice2')}
                      data-title-center
                      className={classes}
                      key={key}
                      onClick={() => {
                        if (
                          !currentStepDisabled &&
                          !isSelected &&
                          seat.IsAvailable
                        ) {
                          selectSeat(seat);
                        }
                      }}
                    >
                      <div className={styles.icon}>
                        {isSelected
                          ? `P${selectedSeats.indexOf(seat.SeatNumber) + 1}`
                          : seat.SeatNumber}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default withTranslation()(Bus);
