import React, { useContext } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { StoreContext } from '@lux/spa/store';
import { DEFAULT_LANG } from '@lux/spa/config';
import { MODIFICATION_VOUCHER_PAYMENT } from '@lux/spa/apollo/mutations';
import { Modal, ModalContent, Button, Link, Notice } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './redeem-change-tickets-voucher.module.scss';

const RedeemChangeTicketsVoucher = ({
  t,
  i18n,
  history,
  isOpen,
  toggleModal,
  voucherCode,
  voucherValue,
  toggleVoucher,
  setVoucher,
  callback = null,
}) => {
  const { language } = i18n;
  const [state, dispatch] = useContext(StoreContext);
  const { vouchers, totalPrice, symbol, payment } = state.changeTickets;

  const [payWithVoucher, { loading }] = useMutation(
    MODIFICATION_VOUCHER_PAYMENT,
    {
      onError: () => {
        toast.error(t('something wrong'));
      },
      onCompleted: data => {
        const { error } = data.modificationVoucherPayment;

        if (error) {
          toast.error(t('something wrong'));
        } else {
          const { ModificationCost } = data.modificationVoucherPayment.details;

          const PaidAmount =
            ModificationCost <= voucherValue ? ModificationCost : voucherValue;

          if (data.modificationVoucherPayment.basketPayed) {
            dispatch({
              type: 'UPDATE_CHANGE_TICKETS',
              payload: {
                vouchers: {
                  ...vouchers,
                  showVoucherInput: false,
                  fullyСoversСost:
                    totalPrice <= vouchers.totalVouchersValue + PaidAmount,
                  totalVouchersValue: vouchers.totalVouchersValue + PaidAmount,
                  approved: [
                    ...vouchers.approved,
                    {
                      code: voucherCode,
                      value: PaidAmount,
                    },
                  ],
                },
                payment: {
                  ...payment,
                  status: 'success',
                  type: 'voucher',
                  objects: data?.modificationVoucherPayment?.objects,
                },
              },
            });
            setVoucher('');
            toggleModal();

            history.push(
              `/${
                DEFAULT_LANG === language ? '' : `${language}/`
              }change-tickets/thanks/`
            );
          } else {
            dispatch({
              type: 'UPDATE_CHANGE_TICKETS',
              payload: {
                vouchers: {
                  ...vouchers,
                  showVoucherInput: false,
                  fullyСoversСost:
                    totalPrice <= vouchers.totalVouchersValue + PaidAmount,
                  totalVouchersValue: vouchers.totalVouchersValue + PaidAmount,
                  approved: [
                    ...vouchers.approved,
                    {
                      code: voucherCode,
                      value: PaidAmount,
                    },
                  ],
                },
              },
            });
            setVoucher('');

            if (callback) callback();

            toggleModal();
          }
        }
      },
    }
  );

  const leftToPay = (
    totalPrice -
    vouchers.totalVouchersValue -
    voucherValue
  ).toFixed(2);
  const isFullyСoversСost = leftToPay <= 0;

  return (
    <Modal className={s.modal} {...{ isOpen, toggleModal }}>
      <ModalContent className={s.content}>
        <h2>{t('redeem by voucher')}</h2>
        <div className={s.priceBlock}>
          <div className={s.priceLine}>
            {t('tickets price')}
            <div className={s.price}>
              {totalPrice - vouchers.totalVouchersValue} {symbol}
            </div>
          </div>
          <div className={s.priceLine}>
            {t('voucher')}
            <div className={s.price}>
              -
              {isFullyСoversСost
                ? totalPrice - vouchers.totalVouchersValue
                : voucherValue}
              {` ${symbol}`}
            </div>
          </div>
          <div className={cn(s.priceLine, s.total)}>
            {t('total price')}
            <div className={s.price}>
              {isFullyСoversСost ? 0 : leftToPay} {symbol}
            </div>
          </div>
        </div>
        <Notice className={s.notice}>
          {`${t('by completing this booking I agree with')} `}
          <Link className={s.link} to="/ticket-sales-rules/">
            {t('Lux Express ticket selling terms')}
          </Link>
          .
        </Notice>
        <div className={s.controls}>
          <Button
            className={s.button}
            disabled={loading}
            text={
              loading
                ? t('loading')
                : isFullyСoversСost
                ? t('pay by voucher')
                : t('approve redeeming')
            }
            onClick={payWithVoucher}
          />
          <div className={s.cancel} onClick={toggleModal}>
            {t('cancel')}
          </div>
        </div>
      </ModalContent>
      <div className={s.footer}>
        {isFullyСoversСost ? (
          <div className={s.notice}>
            <i className={i.ticketAlt} />
            <div className={s.text}>
              {t('voucher.redeeming.full.cost', {
                voucherCode,
                symbol,
                voucherValue: (
                  voucherValue -
                  (totalPrice - vouchers.totalVouchersValue)
                ).toFixed(2),
              })}
            </div>
          </div>
        ) : (
          <Notice>
            {t('voucher.redeeming', {
              voucherCode,
              symbol,
              voucherValue: (
                totalPrice -
                vouchers.totalVouchersValue -
                voucherValue
              ).toFixed(2),
            })}
          </Notice>
        )}
      </div>
    </Modal>
  );
};

export default withRouter(withTranslation()(RedeemChangeTicketsVoucher));
