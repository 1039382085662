import React, { useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import {
  // CREATE_ROUTE,
  UPDATE_ROUTE,
} from '@lux/spa/apollo/mutations';
import { GET_ROUTE_ADMIN, GET_ROUTE } from '@lux/spa/apollo/queries';
import { StoreContext } from '@lux/spa/store';
import { PageLoading } from '@lux/ui';
import { NotFound } from '@lux/spa/routes';
import RouteContent from './route-content-new';
import { emptyData, defaultData } from './constants';

const Route = ({
  region,
  history,
  lang,
  match,
  location,
  i18n,
  isStaff,
  isEditMode,
  page = { en: emptyData },
  dispatch = () => {},
  ...props
}) => {
  const [mainState, mainDispatch] = useContext(StoreContext);
  const { from = {}, to = {} } = mainState.search;
  const { route } = match.params;
  const { language } = i18n;
  const QUERY = isStaff ? GET_ROUTE_ADMIN : GET_ROUTE;

  const { data, loading = true, errors } = useQuery(QUERY, {
    errorPolicy: 'ignore',
    variables: {
      lang: language,
      slug: route,
    },
  });

  const updatePage = field => {
    dispatch({
      type: 'UPDATE_DATA',
      payload: {
        field,
      },
    });
  };

  useEffect(() => {
    if (isStaff && data) {
      dispatch({
        type: 'UPDATE',
        payload: {
          defaultData,
          emptyData,
          UPDATE_PAGE: UPDATE_ROUTE,
          // CREATE_PAGE: CREATE_ROUTE,
          originalPage: data?.adminRoute,
          page: data?.adminRoute,
        },
      });
    }
  }, [data]);

  const content = isStaff ? page?.[lang] : data?.route?.data;

  const departure = isStaff ? page?.departure : data?.route?.departure;
  const arrival = isStaff ? page?.arrival : data?.route?.arrival;

  let slugs = {};
  const type = 'routes';

  if (!isStaff) {
    slugs = data?.route?.slugs;
  } else if (data?.adminRoute) {
    Object.entries(data?.adminRoute).map(([key, value]) => {
      if (key.length == 2 && typeof value == 'object') {
        slugs[key] = value?.slug ? value.slug : null;
      }
    });
  }

  if (slugs && typeof localStorage !== 'undefined') {
    localStorage.setItem(
      'location',
      JSON.stringify({
        type,
        slugs,
      })
    );
  }

  useEffect(() => {
    if (!loading && content) {
      const { slides = [] } = content.pageData.stops;

      if (slides.length > 1) {
        const fromId = slides[0].stopId.value;
        const fromName = slides[0].stopName.value;
        const toId = slides[slides.length - 1].stopId.value;
        const toName = slides[slides.length - 1].stopName.value;

        mainDispatch({
          type: 'UPDATE_SEARCH',
          payload: {
            from: {
              ...from,
              BusStopId: fromId,
              search: fromName,
            },
            to: {
              ...to,
              BusStopId: toId,
              search: toName,
            },
          },
        });
      }
    }
  }, [loading, content]);

  return loading ? (
    <PageLoading />
  ) : content ? (
    <RouteContent
      slugs={slugs}
      type={type}
      content={{
        ...content,
        seo: {
          ...content.seo,
          slug: {
            ...content.seo.slug,
            value: `${type}/${content.seo.slug.value}`,
          },
        },
        pageData: {
          ...emptyData.pageData,
          ...content.pageData,
        },
      }}
      updatePage={updatePage}
      language={language}
      isEditMode={isEditMode}
      isStaff={isStaff}
      region={region}
      departure={departure}
      arrival={arrival}
      dispatch={mainDispatch}
      state={mainState}
      {...props}
    />
  ) : (
    <NotFound />
  );
};

export default withRouter(withTranslation()(Route));
