import React from 'react';
import cn from 'classnames';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { DEFAULT_LANG } from 'config';
import { withTranslation } from 'react-i18next';
import s from './nav-link.module.scss';

const NavLink = ({
  disabled,
  style,
  onClick,
  className,
  activeClassName,
  children,
  i18n,
  to,
}) => {
  // const region = ulrPath.slice(4, 7).includes('/') ? ulrPath.slice(4, 6) : 'ee'
  const { language } = i18n;
  return (
    <RouterNavLink
      to={language === DEFAULT_LANG ? to : `/${language}${to}`}
      onClick={onClick}
      style={style}
      disabled={disabled}
      className={cn(s.navLink, className)}
      activeClassName={cn(s.active, activeClassName)}
      children={children}
    />
  );
};

export default withTranslation()(NavLink);
