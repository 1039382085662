import React, {
  useState,
  useReducer,
  useEffect,
  useContext,
  useRef,
} from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Query } from 'react-apollo';
import { GET_SUGGESTIONS } from '@lux/spa/apollo/queries';
import { Loading } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './suggestions.module.scss';

const Suggestions = ({
  t,
  i18n,
  search,
  onlyCity = false,
  selectItem,
  suggestionsRef,
}) => {
  const { language } = i18n;
  return (
    <Query
      query={GET_SUGGESTIONS}
      variables={{
        cityName: search,
        lang: language,
      }}
      skip={search.length < 3}
    >
      {({ loading, error, data = { cities: [] }, refetch }) => {
        if (loading)
          return (
            <div className={s.suggestions} ref={suggestionsRef}>
              <Loading className={s.loading} />
            </div>
          );
        return (
          <div
            className={cn(s.suggestions, onlyCity && s.onlyCity)}
            ref={suggestionsRef}
          >
            <div className={s.wrap}>
              <div className={s.triangle} />
            </div>
            {data.cities.length ? (
              data.cities.map(({ CityId, CityName, BusStops }) => (
                <>
                  <div
                    className={s.townCountry}
                    key={CityId}
                    onClick={
                      onlyCity
                        ? () => selectItem({ CityId, CityName })
                        : () => {}
                    }
                  >
                    <i className={i.point} />
                    <span>{CityName}</span>
                  </div>
                  {!onlyCity &&
                    BusStops.map(({ BusStopId, BusStopName }) => (
                      <div
                        key={BusStopId}
                        className={s.item}
                        onClick={() =>
                          selectItem({ BusStopId, BusStopName }, data.cities)
                        }
                      >
                        <i
                          className={
                            i[
                              BusStopName.includes('airplane')
                                ? 'airplane'
                                : 'bus'
                            ]
                          }
                        />
                        <span>{BusStopName}</span>
                      </div>
                    ))}
                </>
              ))
            ) : (
              <span>
                {t('so matches for')} «<b>{search}</b>»
              </span>
            )}
          </div>
        );
      }}
    </Query>
  );
};

export default withTranslation()(Suggestions);
