import React from 'react';
import cn from 'classnames';
import { Container } from '@lux/ui';
import s from './payment-card.module.scss';

const PaymentCard = ({ className }) => {
  return (
    <div className={cn(s.paymentCard, className)}>
      <Container>
        <Container full={false}>
          <section>
            <div>Payment Card page</div>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default PaymentCard;
