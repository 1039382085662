import React from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { DEFAULT_LANG } from 'config';
import s from './link.module.scss';

const Link = ({
  tReady,
  t,
  to,
  className,
  i18n,
  location,
  target,
  children,
  style,
  disabled,
  onClick,
  linkRef,
  ...props
}) => {
  const { pathname: ulrPath } = location;
  // const region = ulrPath.slice(4, 7).includes('/') ? ulrPath.slice(4, 6) : 'ee'
  const { language } = i18n;
  const isToObject = typeof to == 'object';
  const pathname = isToObject ? to.pathname : to;
  const isOther = /http/.test(pathname) || /luxexpress:\/\//.test(pathname);
  const Link = isOther ? 'a' : RouterLink;
  // const isUpdateTo = /^\/[a-z]{2}\//.test(pathname);
  return (
    <Link
      ref={linkRef}
      disabled={disabled}
      onClick={onClick}
      href={to}
      {...(isOther ? { rel: 'noreferrer' } : {})}
      {...(!isOther
        ? {
            to: {
              ...to,
              // pathname: isUpdateTo ? pathname : `/${language}${pathname}`,
              pathname:
                language === DEFAULT_LANG
                  ? pathname
                  : `/${language}${pathname.replace(`/${language}`, '')}`,
            },
          }
        : {})}
      // to={{
      //   ...to,
      //   // pathname: isUpdateTo ? pathname : `/${language}-${region}${pathname}`
      //   pathname: isUpdateTo ? pathname : `/${language}${pathname}`,
      // }}
      className={cn(s.link, className)}
      style={style}
      target={target || isOther ? '_blank' : ''}
      children={children}
      // {...props}
    />
  );
};

export default withRouter(withTranslation()(Link));
