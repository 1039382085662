import React, { useContext } from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { StoreContext } from '@lux/spa/store';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { SearchInput, DatePicker } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './search.module.scss';

const Search = ({ t, className, ButtonSearch, inputsClassName }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { isMob } = useContext(WidthContext);
  const { changeTickets } = state;
  const {
    from,
    to,
    departDate,
    passengers,
    promocode,
  } = state.changeTickets.search;
  const { GTMLoaded } = state.app;

  const updateDate = date => {
    dispatch({
      type: 'UPDATE_CHANGE_TICKETS',
      payload: {
        search: {
          ...changeTickets.search,
          departDate: date,
        },
      },
    });
  };

  return (
    <div className={cx(s.search, className)}>
      <div className={cx(s.inputs, inputsClassName)}>
        <div className={cx(s.input, s.direction, s.disabled)}>
          <SearchInput
            data-title={!isMob ? from.search : ''}
            value={from.search}
            label={t('from')}
          />
          <div className={s.swap}>
            <i className={i.exchange} />
          </div>
        </div>
        <div className={cx(s.input, s.direction, s.disabled)}>
          <SearchInput
            data-title={!isMob ? to.search : ''}
            value={to.search}
            label={t('to')}
          />
        </div>
        <div className={cx(s.input, s.datePicker)}>
          <DatePicker
            value={new Date(departDate)}
            placeholder={t('pick the date')}
            label={t('depart')}
            format="d MMMM, eee"
            onDayChange={departDate => updateDate(departDate)}
            selectedDays={[departDate]}
            disabledDays={[
              {
                before: new Date(),
              },
            ]}
          />
        </div>
        {/* <div className={cx(s.input, s.datePicker, s.disabled)}>
          <DatePicker
            value={returnDate}
            label={t('return')}
            format="d MMMM, eee"
            posRight
          />
        </div> */}
        <div className={cx(s.input, s.passengers, s.disabled)}>
          <SearchInput
            label={t('passengers')}
            icon="passenger"
            readOnly="readonly"
            placeholder={t('add')}
            value={passengers.toString()}
          />
        </div>
        <div className={cx(s.input, s.promocode)} data-title={promocode}>
          <SearchInput
            value={promocode}
            label={t('promocode')}
            placeholder={t('enter code')}
            onChange={e =>
              dispatch({
                type: 'UPDATE_CHANGE_TICKETS',
                payload: {
                  search: {
                    ...state.changeTickets.search,
                    promocode: e.target.value,
                  },
                },
              })
            }
          />
        </div>
      </div>
      <ButtonSearch
        // pushAnaliticsToGTM={pushAnaliticsToGTM}
        pushAnaliticsToGTM={() => {}}
      />
    </div>
  );
};

export default withRouter(withTranslation()(Search));
