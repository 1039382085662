import React, { useEffect, useContext, useState } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { StoreContext } from '@lux/spa/store';
import { Button, SendVoucher } from '@lux/ui';
import { Info } from '../components';
import s from '../refund.module.scss';

const Status = ({ t, i18n, setStep, dataRefund, history, isPublic }) => {
  const [state, dispatch] = useContext(StoreContext);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { symbol } = state.tickets;

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const voucherCode = dataRefund.commitReturn?.returnInfo?.VoucherCode;
  const returnedAmount = dataRefund.commitReturn?.returnInfo?.ReturnedAmount;
  const refundPaymentMethod =
    dataRefund.commitReturn?.returnInfo?.RefundPaymentMethod;
  const { voucherFileUrl = '' } = dataRefund.commitReturn;

  const { language } = i18n;
  const { isError, isSuccess } = dataRefund;

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setStep(1);
      }, 3000);
    }
    if (isSuccess) {
      // setTimeout(() => {
      //   if (isPublic) {
      //     history.push(
      //       language === DEFAULT_LANG
      //         ? '/manage-booking/trips'
      //         : `/${language}/manage-booking//trips/`
      //     );
      //   } else {
      //     history.push(
      //       language === DEFAULT_LANG
      //         ? '/profile/trips'
      //         : `/${language}/profile/trips/`
      //     );
      //   }
      // }, 3000);
    }
  }, [isError, isSuccess]);

  return (
    <div className={cn(s.step, s.status)}>
      <SendVoucher
        voucherCode={voucherCode}
        toggleModal={toggleModal}
        isOpen={isOpenModal}
        closeModal={() => setIsOpen(false)}
      />
      <div className={s.content}>
        <form className={s.form}>
          {isError && (
            <div className={s.status}>
              <img src="/images/svg/ticket-refund-failed.svg" alt="" />
              <h4>{t('refund failed')}!</h4>
              <p>{t('your ticket was not refunded.')}</p>
              <span>{t('this notification will be closed in 3')}</span>
            </div>
          )}
          {isSuccess && (
            <>
              {refundPaymentMethod === 'zero payment' && (
                <div className={s.status}>
                  <img src="/images/svg/ticket-refund-success.svg" alt="" />
                  <h4>{t('tickets are refunded')}!</h4>
                  <p>{t('refund.voucher.success.zero.payment')}</p>
                  <Button
                    classNaem={s.button}
                    text={isPublic ? t('home') : t('to my trips')}
                    to={
                      isPublic ? `/${language}/` : `/${language}/profile/trips`
                    }
                  />
                </div>
              )}

              {refundPaymentMethod === 'voucher' && (
                <div className={s.status}>
                  <img src="/images/svg/ticket-refund-success.svg" alt="" />
                  <h4>{t('tickets are refunded')}!</h4>
                  <p>
                    {t('refund.voucher.success.voucher', {
                      voucherNumber: voucherCode,
                      price: `${returnedAmount} ${symbol}`,
                    })}
                  </p>
                  <Button
                    classNaem={s.button}
                    text={t('download voucher')}
                    to={voucherFileUrl}
                    target="_blank"
                  />
                  <div className={s.sendVoucher} onClick={toggleModal}>
                    {t('send voucher')}
                  </div>
                </div>
              )}

              {refundPaymentMethod === 'client card' && (
                <div className={s.status}>
                  <img src="/images/svg/ticket-refund-success.svg" alt="" />
                  <h4>{t('tickets are refunded')}!</h4>
                  <p>{t('refund.voucher.success.client.contract')}</p>
                  <Button
                    classNaem={s.button}
                    text={isPublic ? t('home') : t('to my trips')}
                    to={
                      isPublic ? `/${language}/` : `/${language}/profile/trips`
                    }
                  />
                </div>
              )}

              {refundPaymentMethod === 'credit card' && (
                <div className={s.status}>
                  <img src="/images/svg/ticket-refund-success.svg" alt="" />
                  <h4>{t('tickets are refunded')}!</h4>
                  <p>{t('refund.voucher.success.money')}</p>
                  <Button
                    classNaem={s.button}
                    text={isPublic ? t('home') : t('to my trips')}
                    to={
                      isPublic ? `/${language}/` : `/${language}/profile/trips`
                    }
                  />
                </div>
              )}

              {refundPaymentMethod === 'internet bank' && (
                <div className={s.status}>
                  <img src="/images/svg/ticket-refund-success.svg" alt="" />
                  <h4>{t('tickets are refunded')}!</h4>
                  <p>{t('refund.voucher.success.money')}</p>
                  <Button
                    classNaem={s.button}
                    text={isPublic ? t('home') : t('to my trips')}
                    to={
                      isPublic ? `/${language}/` : `/${language}/profile/trips`
                    }
                  />
                </div>
              )}
            </>
          )}
        </form>
      </div>
      <Info dataRefund={dataRefund} />
    </div>
  );
};

export default withRouter(withTranslation()(Status));
