import React, { useEffect, useRef, useContext } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { Divider } from '@lux/ui';
import { WidthContext } from '@lux/spa/contexts/width-context';
import i from '@lux/spa/styles/icons.module.scss';
import s from './modal.module.scss';

const Modal = ({ toggleModal, classNameWrap, className, children, isOpen }) => {
  const { isMob } = useContext(WidthContext);
  const wrapRef = useRef(null);

  const closeModal = ({ target }) => {
    if (wrapRef.current === target) {
      toggleModal();
    }
  };

  const handlePressEsc = ({ keyCode }) => {
    if (keyCode === 27) {
      toggleModal();
    }
  };

  useEffect(() => {
    if (isOpen) {
      // TODO: check this
      document.body.style.height = '100%';
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.height = 'inherit';
      document.body.style.overflow = 'inherit';
    }

    if (!isMob) {
      document.body.style.height = 'inherit';
      document.body.style.overflow = 'inherit';
    }

    if (isOpen) {
      const modalWrap = wrapRef.current;

      modalWrap?.addEventListener('click', closeModal, false);
      document.addEventListener('keydown', handlePressEsc, false);

      return () => {
        modalWrap?.removeEventListener('click', closeModal, false);
        document.removeEventListener('keydown', handlePressEsc, false);
      };
    }

    // if (isClient) {
    //   document.body.style.overflow = 'auto'
    //   document.body.style.position = 'static'
    // }
  }, [isMob, isOpen]);

  const classes = cn(s.modalWrap, classNameWrap, isOpen && s.open);

  return (
    isOpen &&
    createPortal(
      <div className={classes} ref={wrapRef}>
        <div className={cn(s.modal, className)}>
          <i className={cn(i.close, s.close)} onClick={toggleModal} />
          {children}
          <Divider height={3} absolute />
        </div>
      </div>,
      document.getElementById('modal-root')
    )
  );
};

export default Modal;
