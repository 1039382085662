export const emptyStop = {
  stopId: {
    value: '',
    fieldName: 'stopId',
    placeholder: 'Stop',
    maxLength: 500,
  },
  stopName: {
    value: '',
    fieldName: 'stopName',
    placeholder: 'Stop name',
    maxLength: 500,
  },
};
