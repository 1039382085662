/* eslint-disable */
import React, { useContext, useEffect } from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { DEFAULT_LANG } from '@lux/spa/config';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import { MODIFICATION_JOURNEY_CONFIRM } from '@lux/spa/apollo/mutations';
import { StoreContext } from '@lux/spa/store';
import { TripDetailsChange } from '@lux/spa/components';
import { Bus, Transfer, SeatTypes } from './components';
import { Container, Loading, Notice, Link } from '@lux/ui';
import styles from './seat-details.module.scss';
import i from '@lux/spa/styles/icons.module.scss';

const SeatDetails = ({ t, i18n, className, history }) => {
  const { isMob, isTabDown } = useContext(WidthContext);
  const [state, dispatch] = useContext(StoreContext);
  const { changeTickets, app } = state;

  const { GTMLoaded } = app;
  const { language } = i18n;

  const { from = {}, to = {} } = changeTickets.search;

  const {
    activeBus,
    isOpenDropDown,
    promocode,
    totalPrice,
    returnDate,
    GTM,
    priceClass,
    journey,
    search,
    seatPlan,
    trip,
    currentStepDisabled,
    currentStepLoading,
    details = null,
  } = state.changeTickets;

  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';

  const handleNextStep = () => {
    // exponea.track('seats_confirmed', {
    //   timestamp: Date.now(),
    //   count_passengers: passengersCount,
    //   total_price: totalPrice,
    //   currency: currencyName,
    //   Origin_stop_ID: from.BusStopId,
    //   Destination_stop_ID: to.BusStopId,
    // });

    // if (GTMLoaded) {
    //   dataLayer.push({
    //     tripType: returnDate ? 'return-trip' : 'one-way',
    //     departureStopId: from.BusStopId,
    //     destinationStopId: to.BusStopId,
    //     currencyCode: currencyName,
    //     route: `${from.CityName}-${to.CityName}`,
    //     price: totalPrice,
    //     promocode: promocode || null,
    //     departureDestination: `${from.CountryName}-${to.CountryName}`,
    //     stage: 'departure',
    //   });
    // }

    history.push(
      language === DEFAULT_LANG
        ? `/change-tickets/payment/`
        : `/${language}/change-tickets/payment/`
    );
  };

  const [modificationJourneyConfirm, { loading }] = useMutation(
    MODIFICATION_JOURNEY_CONFIRM,
    {
      variables: {
        IsOutbound: search.isOutbound,
        JourneyId: journey.JourneyId,
        PriceClass: priceClass,
        lang: language,
      },
      onCompleted: data => {
        const {
          error,
          success,
          details,
          seatPlan,
        } = data.modificationJourneyConfirm;

        if (error) {
          toast.error(error.ErrorReason);
          return;
        }

        if (success) {
          const selectedSeats = {};

          details.ChangedSeats.forEach(leg => {
            selectedSeats[`bus${leg.LegOrderNumber}`] = {
              ...selectedSeats[`bus${leg.LegOrderNumber}`],
              [leg.ProductCode]: leg.SeatInfo.SeatNumber,
            };
          });

          dispatch({
            type: 'UPDATE_CHANGE_TICKETS',
            payload: {
              seatPlan,
              details,
              selectedSeats,
              totalPrice: details.ModificationCost,
            },
          });
          return;
        }
      },
      onError: data => {
        console.error('MODIFICATION_JOURNEY_CONFIRM onError data', data);
      },
    }
  );

  let showLounge = false;

  if (seatPlan.Legs[activeBus - 1]?.Buses?.[0]?.Floors?.[0]) {
    showLounge = seatPlan.Legs[activeBus - 1].Buses[0].Floors[0].SeatRows.some(
      row => {
        return row.Seats.some(seat => seat.SeatClass === 'SEAT_CLASS.A');
      }
    );
  } else {
    showLounge = false;
  }

  useEffect(() => {
    // const rightNumberOfSeats = journey.Legs.length * passengers;

    // const countOfSeletedSeats = Object.values(selectedSeats).reduce(
    //   (sum, elem) => sum + Object.keys(elem).length,
    //   0
    // );

    // const isAllSeatsSelected = rightNumberOfSeats === countOfSeletedSeats;

    dispatch({
      type: 'UPDATE_CHANGE_TICKETS',
      payload: {
        currentStepLoading:
          // loading || updatePriceLoading || getAvailableLoading || basketLoading,
          loading,
      },
    });
  }, [loading]);

  useEffect(() => {
    if (!details) modificationJourneyConfirm();
  }, [details]);

  useEffect(() => {
    if (!GTM.selectSeatsStep && totalPrice) {
      if (GTMLoaded) {
        // dataLayer.push({
        //   tripType: returnDate ? 'return-trip' : 'one-way',
        //   departureStopId: from.BusStopId,
        //   destinationStopId: to.BusStopId,
        //   currencyCode: currencyName,
        //   route: `${from.CityName}-${to.CityName}`,
        //   price: totalPrice,
        //   promocode: promocode || null,
        //   departureDestination: `${from.CountryName}-${to.CountryName}`,
        //   stage: 'departure',
        //   event: 'seat-select',
        // });

        dispatch({
          type: 'UPDATE_CHANGE_TICKETS',
          payload: {
            GTM: {
              ...GTM,
              selectSeatsStep: true,
            },
          },
        });
      }
    }
  }, [totalPrice]);

  return (
    <div className={cx(styles.seatDetails, className)}>
      <Container className={styles.breadcrumbs}>
        <Container full={false}>
          <section>
            <Link to="/change-tickets/search/" className={styles.item}>
              <span>{t('search')}</span>
            </Link>
            <div className={cx(styles.item, styles.active)}>
              <i className={i.arrowBottom} />
              1.&nbsp;<span>{t('seat selection')}</span>
            </div>
            <div className={styles.item}>
              <i className={i.arrowBottom} />
              2.&nbsp;
              <span>{t('payment details')}</span>
            </div>
          </section>
        </Container>
      </Container>
      <Container className={styles.passangers}>
        <Container full={false}>
          <section>
            <div
              className={cx(
                styles.tabs,
                styles.activeOne,
                isOpenDropDown && styles.openDropDown
              )}
            >
              <div className={styles.tabPanel}>
                <div className={styles.line} />
                <div
                  // onClick={() => changeTab(1)}
                  className={cx(styles.tab, styles.active)}
                >
                  <span>
                    {t(from.CityName)}, {t(from.CountryName)}
                  </span>
                  <i className={i.arrowRight_v3} />
                  <span>
                    {t(to.CityName)}, {t(to.CountryName)}
                  </span>
                </div>
                {/* {tickets.inbound.selected && (
                  <div
                    onClick={() => changeTab(2)}
                    className={cx(styles.tab, activeTab === 2 && styles.active)}
                  >
                    <span>
                      {t(to.CityName)}, {t(to.CountryName)}
                    </span>
                    <i className={i.arrowRight_v3} />
                    <span>
                      {t(from.CityName)}, {t(from.CountryName)}
                    </span>
                  </div>
                )} */}
              </div>
              <div className={styles.tabsContainer}>
                <div className={styles.tabsContents}>
                  <div className={styles.tabsContent}>
                    <SeatTypes
                      showLounge={showLounge}
                      className={cx(styles.seatTypes, !isMob && styles.show)}
                    />
                    <div className={styles.info}>
                      {loading ? (
                        <Loading className={styles.loading} />
                      ) : (
                        <>
                          <div className={styles.autobus}>
                            {!isMob &&
                              !!seatPlan?.Legs?.length &&
                              seatPlan?.Legs.sort(
                                (a, b) => a.OrderNumber - b.OrderNumber
                              ).map((leg, key) => {
                                const { CanChooseSeatNumber } = leg.Buses[0];
                                const isShowBus = key + 1 === activeBus;
                                return (
                                  isShowBus &&
                                  (CanChooseSeatNumber ? (
                                    <Bus leg={leg} key={key} />
                                  ) : (
                                    <div className={styles.noSeatsBus}>
                                      <div className={styles.texts}>
                                        <div className={styles.title}>
                                          {t('seats selection')}
                                        </div>
                                        <div className={styles.subTitle}>
                                          {t(
                                            'seats selection is not available.'
                                          )}
                                        </div>
                                      </div>
                                      <img
                                        src="/images/svg/no-seats-bus.svg"
                                        alt=""
                                      />
                                    </div>
                                  ))
                                );
                              })}
                          </div>
                          <div className={styles.ticketInfo}>
                            <Notice className={styles.notice}>
                              {t('tickets.notice1')}
                              <br />
                              {t('tickets.notice2')}
                            </Notice>
                            {!!seatPlan?.Legs?.length &&
                              seatPlan?.Legs.sort(
                                (a, b) => a.OrderNumber - b.OrderNumber
                              ).map((leg, key) => {
                                const { CanChooseSeatNumber } = leg.Buses[0];
                                return (
                                  <Transfer
                                    canChooseSeatNumber={CanChooseSeatNumber}
                                    legsCount={journey.Legs.length}
                                    orderNumber={leg.OrderNumber}
                                    products={trip.Passengers}
                                    isTransfer={journey.Legs.length > 1}
                                    leg={leg}
                                    key={key}
                                    active={key + 1 === activeBus}
                                  />
                                );
                              })}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isTabDown ? (
              <TripDetailsChange
                // nextStep="payment"
                loading={currentStepLoading}
                onContinueClick={handleNextStep}
                disabledNextStep={
                  // loading ||
                  // updatePriceLoading ||
                  // getAvailableLoading ||
                  // !isAllValid ||
                  currentStepDisabled
                }
              />
            ) : (
              <div className={styles.tripDetails}>
                <TripDetailsChange
                  // nextStep="payment"
                  loading={currentStepLoading}
                  onContinueClick={handleNextStep}
                  disabledNextStep={
                    // loading ||
                    // updatePriceLoading ||
                    // getAvailableLoading ||
                    // !isAllValid ||
                    currentStepDisabled
                  }
                />
              </div>
            )}
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withRouter(withTranslation()(SeatDetails));
