import React, { useState, memo } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { getCookie } from 'utils';
import { Link, LazyLoadImage } from 'ui-kit';
import Marquee from 'react-double-marquee';
import i from '@lux/spa/styles/icons.module.scss';
import s from './operational-notifications.module.scss';

const OperationalNotifications = ({ t, text }) => {
  const cookieName = 'closeNotification';
  const [hideNotification, setHideNotification] = useState(false);
  const [isCloseNotification, setIsCloseNotification] = useState(
    getCookie(cookieName) === 'yes'
  );
  const config = {
    scrollWhen: 'overflow',
    direction: 'left',
    delay: 200,
    speed: 0.04,
    // to see all options go to https://www.npmjs.com/package/react-double-marquee
  };
  const closeNotification = () => {
    const maxAgeCookie = 10080000; // 2147483647 ~ 7 days
    document.cookie = `${cookieName}=yes;path=/;max-age=${maxAgeCookie};`;
    setHideNotification(true);
    setTimeout(() => {
      setIsCloseNotification(true);
    }, 500);
  };
  return (
    <div className={s.operationalNotifications}>
      <div className={s.marquee}>
        <Marquee {...config}>
          <strong>ⓘ</strong>&nbsp;&nbsp;&nbsp;
          {text}
        </Marquee>
      </div>
      <Link className={s.readMore} to="/">
        {t('read more')}
      </Link>
      {!isCloseNotification && (
        <div className={cn(s.notification, hideNotification && s.hide)}>
          <div className={s.header}>
            COVID restrictions during travelling
            <div className={s.close} onClick={closeNotification}>
              <i className={i.close} />
            </div>
          </div>
          <div className={s.content}>
            <div className={s.text}>{text}</div>
          </div>
          <div className={s.footer}>
            <LazyLoadImage
              className={s.logo}
              src="/images/svg/logo-grey.svg"
              alt="luxexpress, site logo"
            />
            <div className={s.readMore}>{t('read more')}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(memo(OperationalNotifications));
