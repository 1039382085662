import React from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { DELETE_BASKET } from '@lux/spa/apollo/mutations';
import { Modal, ModalContent, Button, Loading } from '@lux/ui';
import { DEFAULT_LANG } from '@lux/spa/config';
import s from './discarded-basket.module.scss';

const DiscardedBasket = ({ history, t, i18n, isOpen, toggleModal, url }) => {
  const { language } = i18n;

  const [deleteBasket, { loading }] = useMutation(DELETE_BASKET, {
    onCompleted: data => {
      // if (data?.deleteBasket?.success) {
      toggleModal();
      history.push(`${DEFAULT_LANG === language ? '' : `/${language}`}${url}`);
      // }
    },
  });

  return (
    <Modal
      className={s.modal}
      {...{
        isOpen,
        toggleModal,
      }}
    >
      <ModalContent className={s.content}>
        <div className={s.controls}>
          <img src="/images/svg/tickets-not-found.svg" alt="" />
          <h1>{t('confirm quit')}</h1>
          <p>
            {t(
              'you will quit the ticket purchase flow and your basket will be deleted'
            )}
          </p>
          <Button
            disabled={loading}
            className={s.button}
            text={loading ? <Loading /> : t('confirm')}
            onClick={deleteBasket}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default withRouter(withTranslation()(DiscardedBasket));
