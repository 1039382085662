import React from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, ModalContent, Button, Link } from '@lux/ui';
import s from './results-out-of-date.module.scss';

const ResultsOutOfDate = ({
  t,
  isOpen,
  toggleModal,
  getTickets,
  variables,
}) => {
  const handleSearchAgain = () => {
    toggleModal();
    getTickets({
      variables,
    });
  };

  return (
    <Modal className={s.modal} {...{ isOpen, toggleModal }}>
      <ModalContent className={s.content}>
        <div className={s.controls}>
          <img src="/images/svg/tickets-not-found.svg" alt="" />
          <h1>{t('tickets.results.out.of.date.title')}</h1>
          <p>{t('tickets.results.out.of.date.text')}</p>
          <Button
            className={s.button}
            text={t('update')}
            onClick={handleSearchAgain}
          />
          <Link className={s.link} to="/">
            {t('go back to home page')}
          </Link>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default withTranslation()(ResultsOutOfDate);
