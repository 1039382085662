import gql from 'graphql-tag';

export const GET_ALL_PROMO = gql`
  query(
    $lang: String!
    $country: String!
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    promoPages(
      lang: $lang
      country: $country
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          publishDate
          isPublished
          data {
            seo
            lang
            slug
            countries
            pageData
            isPublished
          }
        }
        cursor
      }
      count
    }
  }
`;

export const GET_ALL_PROMO_ADMIN = gql`
  query(
    $lang: String!
    $country: String!
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    adminPromoPages(
      lang: $lang
      country: $country
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          publishDate
          isPublished
          data {
            seo
            lang
            slug
            countries
            pageData
            isPublished
          }
        }
        cursor
      }
      count
    }
  }
`;
