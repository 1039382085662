import React, {
  useState,
  useReducer,
  useEffect,
  useContext,
  useRef,
} from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';

import { Query } from 'react-apollo';
import { GET_SUGGESTIONS } from '@lux/spa/apollo/queries';
import { Loading } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './suggestions.module.scss';

const ListSuggestions = ({ search, list, selectItem, suggestionsRef }) => (
  <div className={s.listSuggestions} ref={suggestionsRef}>
    <div className={s.wrap}>
      <div className={s.triangle} />
    </div>
    {list.length ? (
      list.map((item, key) => (
        <div key={key} className={s.item} onClick={() => selectItem(item)}>
          <span>{item.name}</span>
        </div>
      ))
    ) : (
      <span>
        {t('so matches for')} «<b>{search}</b>»
      </span>
    )}
  </div>
);

export default withTranslation()(ListSuggestions);
