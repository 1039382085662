import React, { useState, useContext, useEffect, useRef } from 'react';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { StoreContext } from '@lux/spa/store';
import { WidthContext } from '@lux/spa/contexts/width-context';
import {
  GET_AVAILABLE_PRICE_FOR_PRODUCTS,
  UPDATE_PRODUCT_PRICE,
} from '@lux/spa/apollo/mutations';
import { BASKET_PRODUCT_GROUPS } from '@lux/spa/apollo/queries';
import {
  updatePrices,
  updatePassengersPrices,
  updatePassengersPricesV2,
} from 'utils';
import { Dropdown, DropdownOption, BusModal } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import Bus from '../bus';
import SeatTypes from '../seat-types';
import s from './passenger.module.scss';

const CustomDropdown = ({
  t,
  product = {},
  symbol,
  price,
  seat,
  isBusinessClass,
  onChange,
  isCampaign,
  currentStepDisabled,
}) => {
  const { Bonuses = [] } = seat;

  const fareClassName = Bonuses.filter(
    bonus => bonus.FareClass === product.RequestedFareClass
  )?.[0]?.FareClassName;

  return (
    <Dropdown
      Target={({ onClick, isOpen, ...props }) => {
        return (
          <div
            {...props}
            onClick={
              currentStepDisabled || isBusinessClass || Bonuses.length === 1
                ? null
                : onClick
            }
          >
            <div className={cx(s.target, isOpen && s.open)}>
              {/* {seat.SeatNumber ? ( */}
              <>
                <div className={s.info}>
                  {isBusinessClass ? (
                    <div className={s.seatType}>{t('lounge')}</div>
                  ) : (
                    <>
                      {/* <div className={s.seatType}>{t('regular')}</div> */}
                      <div className={s.fareClass}>
                        {t(fareClassName?.toLowerCase()) || t('adult')}
                      </div>
                    </>
                  )}
                </div>
                {!isBusinessClass && Bonuses.length !== 1 && (
                  <i className={cx(i.arrowBottom, s.arrow)} />
                )}
                <div className={cx(s.price, isCampaign && s.crossedOut)}>
                  {`${price} ${symbol}`}
                </div>
              </>
              {/* ) : (
                <div>{t('adult')}</div>
              )} */}
            </div>
          </div>
        );
      }}
    >
      {/* <DropdownOption
        className={cx(s.dropdownOption, s.withDivider)}
        callback={() => {}}
      >
        <div className={s.text}>
          <div className={s.text}>Campaign ticket</div>
          <div className={s.price}>{`${4} ${symbol}`}</div>
        </div>
        <span>(not refundable)</span>
        <div className={s.divider}>
          <div>or</div>
        </div>
      </DropdownOption> */}
      {Bonuses.map((item, key) => {
        return (
          <DropdownOption
            className={s.dropdownOption}
            callback={() => {
              onChange({
                ...item,
                RequestedFareClass: item.FareClass,
              });
            }}
            key={key}
          >
            <div className={s.text}>
              <div className={s.text}>
                {t(item.FareClassName.toLowerCase())}
              </div>
              {/* <div className={s.price}>{`${6} ${symbol}`}</div> */}
            </div>
            <span>{t(item.BonusSchemeName)}</span>
          </DropdownOption>
        );
      })}
    </Dropdown>
  );
};

const Passenger = ({
  t,
  className,
  seat = {},
  number,
  leg,
  language,
  product,
  legsCount,
  orderNumber,
  canChooseSeatNumber,
  seatNumber,
}) => {
  const [state, dispatch] = useContext(StoreContext);
  const { isMob } = useContext(WidthContext);
  const { changeTickets } = state;
  const {
    activePassenger,
    activeBus,
    isOutbound,
    products = {},
    symbol,
    currentStepDisabled,
    promocode,
    selectedSeats,
  } = changeTickets;

  const initialRender = useRef(true);

  const [getBasketGroup, { loading: basketLoading }] = useLazyQuery(
    BASKET_PRODUCT_GROUPS,
    {
      fetchPolicy: 'network-only',
      variables: {
        lang: language,
      },
      onError: () => {
        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            isSomethingWrong: true,
          },
        });
      },
      onCompleted: data => {
        const { basketProductGroups = null } = data;
        if (!basketProductGroups) {
          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              isSomethingWrong: true,
            },
          });
          return;
        }

        const inboundPassengers = basketProductGroups.InboundJourneys;
        const outboundPassengers = basketProductGroups.OutboundJourneys;
        const { passengerPrices, passengersFee } = updatePassengersPricesV2(
          products,
          outboundPassengers,
          inboundPassengers
        );

        const { TotalPrice } = basketProductGroups;

        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            totalPrice: TotalPrice,
            passengerPrices,
            passengersFee,
          },
        });
      },
    }
  );

  const outboundLegsCount = products.OutboundJourneys?.[0]?.Legs?.length;
  const inboundLegsCount = products.InboundJourneys?.[0]?.Legs?.length;

  // const [fareClass, setFareClass] = useState({
  //   RequestedFareClass: product.RequestedFareClass,
  //   FareClassName: product.FareClassName,
  //   BonusSchemeId:
  //     seat.Prices[0].BonusSchemeId || seat.Prices[0].BasicBonusSchemeId,
  // });

  const { IsBusinessClass, Prices, IsCampaign, HasCampaign } = seat;

  // const prices = Prices.filter(
  //   price => IsBusinessClass === price.IsBusinessClass
  // );

  // let campaignPrice = false;

  // if (HasCampaign) {
  //   campaignPrice = prices.filter(price => price.IsCampaign)[0].Price;
  // }

  // const price = prices.filter(price => !price.IsCampaign)[0].Price;

  // useEffect(() => {
  //   setFareClass({
  //     RequestedFareClass: product.RequestedFareClass,
  //     FareClassName: product.FareClassName,
  //     BonusSchemeId:
  //       seat.Prices[0].BonusSchemeId || seat.Prices[0].BasicBonusSchemeId,
  //   });
  // }, [product.RequestedFareClass]);

  // const [
  //   updateProductPrices,
  //   { data: updateProduct = {}, loading: updatePriceLoading = true },
  // ] = useMutation(UPDATE_PRODUCT_PRICE, {
  //   onCompleted: data => {
  //     const error = data?.updateProductPrices?.error;

  //     if (error) {
  //       if (error.ErrorCode === 'basket_expired') {
  //         dispatch({
  //           type: 'UPDATE_TICKETS',
  //           payload: {
  //             isBasketExrpired: true,
  //           },
  //         });
  //       } else {
  //         dispatch({
  //           type: 'UPDATE_TICKETS',
  //           payload: {
  //             isSomethingWrong: true,
  //           },
  //         });
  //       }
  //       return;
  //     }

  //     if (data.updateProductPrices.success) {
  //       const updatedProducts = { ...products };
  //       updatedProducts.OutboundJourneys[0].Products = updatedProducts.OutboundJourneys[0].Products.map(
  //         item => {
  //           if (item.ProductId === product.ProductId) {
  //             return {
  //               ...item,
  //               RequestedFareClass: fareClass.RequestedFareClass,
  //               FareClassName: fareClass.FareClassName,
  //             };
  //           }

  //           return item;
  //         }
  //       );

  //       if (updatedProducts.InboundJourneys.length) {
  //         updatedProducts.InboundJourneys[0].Products = updatedProducts.InboundJourneys[0].Products.map(
  //           item => {
  //             if (item.ProductId === product.ProductId) {
  //               return {
  //                 ...item,
  //                 RequestedFareClass: fareClass.RequestedFareClass,
  //                 FareClassName: fareClass.FareClassName,
  //               };
  //             }

  //             return item;
  //           }
  //         );
  //       }

  //       dispatch({
  //         type: 'UPDATE_TICKETS',
  //         payload: {
  //           products: updatedProducts,
  //           currentStepDisabled: false,
  //         },
  //       });

  //       // getBasket();
  //       getBasketGroup();
  //     }
  //   },
  // });

  // const [
  //   getAvailablePrices,
  //   { data = {}, loading: getAvailableLoading = true },
  // ] = useMutation(GET_AVAILABLE_PRICE_FOR_PRODUCTS, {
  //   variables: {
  //     input: {
  //       CampaignCode: promocode,
  //       Products: [
  //         {
  //           ProductId: product.ProductId,
  //           RequestedFareClass: fareClass.RequestedFareClass,
  //           OutboundLegs: [
  //             ...Array(outboundLegsCount)
  //               .fill()
  //               .map((_, idx) => ({
  //                 OrderNumber: ++idx,
  //                 BonusSchemeId: fareClass.BonusSchemeId,
  //               })),
  //           ],
  //           ...(products.InboundJourneys?.length
  //             ? {
  //                 InboundLegs: [
  //                   ...Array(inboundLegsCount)
  //                     .fill()
  //                     .map((_, idx) => ({
  //                       OrderNumber: ++idx,
  //                       BonusSchemeId: fareClass.BonusSchemeId,
  //                     })),
  //                 ],
  //               }
  //             : {}),
  //         },
  //       ],
  //     },
  //   },
  //   onCompleted: data => {
  //     const error = data?.getAvailablePricesForProducts?.error;
  //     if (error) {
  //       if (error.ErrorCode === 'basket_expired') {
  //         dispatch({
  //           type: 'UPDATE_TICKETS',
  //           payload: {
  //             isBasketExrpired: true,
  //           },
  //         });
  //       } else {
  //         dispatch({
  //           type: 'UPDATE_TICKETS',
  //           payload: {
  //             isSomethingWrong: true,
  //           },
  //         });
  //       }
  //     }

  //     if (data.getAvailablePricesForProducts?.success) {
  //       const {
  //         InboundLegs = [],
  //         OutboundLegs = [],
  //       } = data.getAvailablePricesForProducts.prices[0];

  //       const availablePrices = data.getAvailablePricesForProducts.prices;
  //       const updatedProducts = updatePrices(availablePrices, products);

  //       updateProductPrices({
  //         variables: {
  //           input: {
  //             Products: [
  //               {
  //                 ProductId: product.ProductId,
  //                 OutboundJourney: OutboundLegs.map(item => {
  //                   const {
  //                     IsCampaign,
  //                     IsBusinessClass,
  //                     HasCampaign,
  //                   } = updatedProducts.OutboundJourneys[0].Legs[
  //                     item.OrderNumber - 1
  //                   ].SeatInfo.filter(
  //                     pass => product.ProductId === pass.ProductId
  //                   )[0];

  //                   let LegPriceId = item.Prices.filter(price => {
  //                     return HasCampaign
  //                       ? IsCampaign === price.IsCampaign &&
  //                           price.IsBusinessClass === IsBusinessClass
  //                       : !price.IsCampaign &&
  //                           price.IsBusinessClass === IsBusinessClass;
  //                   })[0]?.PriceId;

  //                   if (!LegPriceId) {
  //                     LegPriceId = item.Prices.filter(
  //                       price =>
  //                         price.IsCampaign === false &&
  //                         price.IsBusinessClass === IsBusinessClass
  //                     )[0]?.PriceId;
  //                   }
  //                   return {
  //                     LegOrderNumber: item.OrderNumber,
  //                     LegPriceId,
  //                   };
  //                 }),
  //                 ...(updatedProducts.InboundJourneys?.length
  //                   ? {
  //                       InboundJourney: InboundLegs.map(item => {
  //                         const {
  //                           IsCampaign,
  //                           IsBusinessClass,
  //                           HasCampaign,
  //                         } = updatedProducts.InboundJourneys[0].Legs[
  //                           item.OrderNumber - 1
  //                         ].SeatInfo.filter(
  //                           pass => product.ProductId === pass.ProductId
  //                         )[0];

  //                         let LegPriceId = item.Prices.filter(price =>
  //                           HasCampaign
  //                             ? IsCampaign === price.IsCampaign &&
  //                               price.IsBusinessClass === IsBusinessClass
  //                             : !price.IsCampaign &&
  //                               price.IsBusinessClass === IsBusinessClass
  //                         )[0]?.PriceId;

  //                         if (!LegPriceId) {
  //                           LegPriceId = item.Prices.filter(
  //                             price =>
  //                               price.IsCampaign === false &&
  //                               price.IsBusinessClass === IsBusinessClass
  //                           )[0]?.PriceId;
  //                         }

  //                         return {
  //                           LegOrderNumber: item.OrderNumber,
  //                           LegPriceId,
  //                         };
  //                       }),
  //                     }
  //                   : {}),
  //               },
  //             ],
  //           },
  //         },
  //       });

  //       // updateProductPrices({
  //       //   variables: {
  //       //     input: {
  //       //       Products: [
  //       //         {
  //       //           ProductId: product.ProductId,
  //       //           OutboundJourney: OutboundLegs.map((item, key) => ({
  //       //             LegOrderNumber: item.OrderNumber,
  //       //             LegPriceId: item.Prices.filter(price => {
  //       //               // return HasCampaign
  //       //               //   ? IsCampaign === price.IsCampaign
  //       //               //   : true && price.IsBusinessClass === IsBusinessClass;
  //       //               return price.IsBusinessClass === IsBusinessClass;
  //       //             })[0].PriceId,
  //       //           })),
  //       //           ...(products.InboundJourneys?.length
  //       //             ? {
  //       //                 InboundJourney: InboundLegs.map(item => ({
  //       //                   LegOrderNumber: item.OrderNumber,
  //       //                   LegPriceId: item.Prices.filter(price => {
  //       //                     // return HasCampaign
  //       //                     //   ? IsCampaign === price.IsCampaign
  //       //                     //   : true &&
  //       //                     //       price.IsBusinessClass === IsBusinessClass;
  //       //                     return price.IsBusinessClass === IsBusinessClass;
  //       //                   })[0].PriceId,
  //       //                 })),
  //       //               }
  //       //             : {}),
  //       //         },
  //       //       ],
  //       //     },
  //       //   },
  //       // });
  //     }
  //   },
  // });

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const selectPassenger = number => {
    if (isMob && canChooseSeatNumber) {
      toggleModal();
    }

    dispatch({
      type: 'UPDATE_CHANGE_TICKETS',
      payload: {
        activePassenger: number,
      },
    });
  };

  let fareClassNameText = '';

  switch (product.PassengerCategory) {
    case 'BONUS_SCHEME_GROUP.ADULT':
      fareClassNameText = 'adult';
      break;
    case 'BONUS_SCHEME_GROUP.CHILDREN':
      fareClassNameText = 'child';
      break;
    case 'BONUS_SCHEME_GROUP.THE_YOUNG':
      fareClassNameText = 'youth';
      break;
    case 'BONUS_SCHEME_GROUP.THE_ELDERLY':
      fareClassNameText = 'senior';
      break;
    default: {
      fareClassNameText = 'adult';
    }
  }

  // const onChange = bonus => {
  //   const promsie = new Promise(res => res());

  //   promsie
  //     .then(() => {
  //       setFareClass({
  //         ...bonus,
  //         productId: product.ProductId,
  //       });
  //     })
  //     .then(() => {
  //       getAvailablePrices();
  //     });
  // };

  // const toggleCampaign = () => {
  //   const promsie = new Promise(res => res());

  //   promsie
  //     .then(() => {
  //       const updatedProducts = { ...products };

  //       updatedProducts[
  //         isOutbound ? 'OutboundJourneys' : 'InboundJourneys'
  //       ][0].Legs[orderNumber - 1].SeatInfo[
  //         number - 1
  //       ].IsCampaign = !IsCampaign;

  //       dispatch({
  //         type: 'UPDATE_TICKETS',
  //         payload: {
  //           products: updatedProducts,
  //         },
  //       });
  //     })
  //     .then(() => {
  //       getAvailablePrices();
  //     });
  // };

  // useEffect(() => {
  //   if (initialRender.current) {
  //     initialRender.current = false;
  //   } else {
  //     getAvailablePrices();
  //   }
  // }, [seat.SeatNumber]);

  // useEffect(() => {
  //   if (getAvailableLoading || updatePriceLoading) {
  //     dispatch({
  //       type: 'UPDATE_TICKETS',
  //       payload: {
  //         currentStepDisabled: true,
  //       },
  //     });
  //   }
  // }, [getAvailableLoading, updatePriceLoading]);

  return (
    <div
      className={cx(
        s.passenger,
        activePassenger === number && s.active,
        className
      )}
    >
      <BusModal
        leg={leg}
        Bus={Bus}
        SeatTypes={SeatTypes}
        passenger={number}
        toggleModal={toggleModal}
        isOpen={isOpen}
      />
      <div className={s.line} />
      <div className={s.heading} onClick={() => selectPassenger(number)}>
        <i className={i.userCircle} />
        <div className={s.text}>
          {product?.PassengerName
            ? product.PassengerName
            : `${t('passenger')} ${number}`}
        </div>
        {canChooseSeatNumber && (
          <div className={s.edit}>
            {seatNumber ? `${t('seat')} ${seatNumber}` : t('choose seat')}
            <i className={i.edit} />
          </div>
        )}
      </div>
      <div className={s.selectSeat}>
        <div className={s.caption}>{t('ticket option')}</div>
        <div className={s.fareClass}>{t(fareClassNameText)}</div>
        {/* <CustomDropdown
          onChange={onChange}
          t={t}
          fareClass={fareClass}
          price={price}
          isBusinessClass={IsBusinessClass}
          symbol={symbol}
          product={product}
          seat={seat}
          activeBus={activeBus}
          activePassenger={activePassenger}
          isCampaign={HasCampaign && IsCampaign}
          currentStepDisabled={currentStepDisabled}
        /> */}
        {/* {HasCampaign && (
          <div
            className={cx(
              s.campaign,
              IsCampaign && s.active,
              currentStepDisabled && s.disabled
            )}
          >
            <div
              className={s.check}
              onClick={currentStepDisabled ? null : toggleCampaign}
            />
            <div className={s.campaignInfo}>
              <div className={s.title}>{t('campaign ticket')}</div>
              <div className={s.text}>{t('not refundable')}</div>
            </div>
            <div className={s.price}>
              {campaignPrice} {symbol}
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default withTranslation()(Passenger);
