import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalContent } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './search-passengers-mobile.module.scss';

const passengersInfoType = {
  // child: 'Children of pre-school age (up to 7 yo, 7 incl.)',
  // youth: 'Children up to 16 y. (incl.)',
  // senior: 'Seniors above 60 y. (60 incl.)',
  passengers: 'passengers',
};

const SearchPassengersMobile = ({
  t,
  isOpen,
  toggleModal,
  passengers,
  updatePassengers,
  passengersCount,
}) => {
  return (
    <Modal className={s.modal} {...{ isOpen, toggleModal }}>
      <ModalHeader text={t('passengers')} />
      <ModalContent>
        <div className={s.passengers}>
          {Object.keys(passengers).map((type, key) => (
            <div className={s.type} key={type}>
              <div className={s.name}>
                <div className={s.text}>{t(type)}</div>
                <div className={s.info}>{t(passengersInfoType[type])}</div>
              </div>
              <div className={s.controls}>
                <div
                  onClick={() => updatePassengers('-', type)}
                  className={cn(
                    s.button,
                    s.minus,
                    (passengersCount === 1 || passengers[type] === 0) &&
                      s.disabled
                  )}
                >
                  <i className={i.minus} />
                </div>
                <div className={s.count}>{passengers[type]}</div>
                <div
                  className={cn(s.button, 'plus')}
                  onClick={() => updatePassengers('+', type)}
                >
                  <i className={i.plus_v2} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </ModalContent>
    </Modal>
  );
};

export default withTranslation()(SearchPassengersMobile);
