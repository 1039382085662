import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import styles from './seat-types.module.scss';

const SeatTypes = ({ t, className, showLounge }) => {
  return (
    <div className={cn(styles.seatTypes, className)}>
      <div className={cn(styles.item, styles.regular)}>
        <div className={styles.icon} />
        <div className={styles.text}>{t('regular')}</div>
      </div>
      {showLounge && (
        <div className={cn(styles.item, styles.lounge)}>
          <div className={styles.icon} />
          <div className={styles.text}>{t('lounge')}</div>
        </div>
      )}
      <div className={cn(styles.item, styles.selected)}>
        <div className={styles.icon} />
        <div className={styles.text}>{t('selected')}</div>
      </div>
      <div className={cn(styles.item, styles.occupied)}>
        <div className={styles.icon} />
        <div className={styles.text}>{t('occupied')}</div>
      </div>
    </div>
  );
};

export default withTranslation()(SeatTypes);
