import gql from 'graphql-tag';

export const SEARCH = gql`
  query(
    $departureDate: Date!
    $originBusStopId: Int
    $destinationBusStopId: Int
    $lang: String
    $currency: String
    $fareClasses: [SearchFareClassInput]
    $promoCode: String
    $isPartOfRoundtrip: Boolean
    $onlyActive: Boolean
  ) {
    search(
      departureDate: $departureDate
      originBusStopId: $originBusStopId
      destinationBusStopId: $destinationBusStopId
      lang: $lang
      currency: $currency
      fareClasses: $fareClasses
      promoCode: $promoCode
      isPartOfRoundtrip: $isPartOfRoundtrip
      onlyActive: $onlyActive
    ) {
      JourneyId
      DepartureDateTimeTimeZone
      ArrivalDateTimeTimeZone
      DepartureDateTime
      ArrivalDateTime
      PlannedDepartureDateTime
      PlannedArrivalDateTime
      Duration
      OriginStopName
      OriginStopId
      DestinationStopName
      DestinationStopId
      Currency
      AvailableRegularSeats
      SoldLaterRegularSeats
      RegularPrice
      AvailableBusinessClassSeats
      SoldLaterBusinessClassSeats
      BusinessClassPrice
      AvailableCampaignSeats
      SoldLaterCampaignSeats
      CampaignPrice
      AvailableBusinessCampaignSeats
      SoldLaterBusinessCampaignSeats
      BusinessCampaignPrice
      RegularBusPrice
      BusinessBusPrice
      IsForSale
      NotForSaleReasonType
      PriceClasses {
        PriceClassName
        SeatClassCategory
        NumberOfSeats
        Price
      }
      IsChangeable
      IsRefundable
      IsPetOnBus
      IsAllergicPassengerOnBus
      IsWheelchairAreaAvailable
      Legs {
        OrderNumber
        BusCompanyName
        BrandName
        BrandShortName
        LineIdentifier1 {
          Name
          Value
        }
        LineIdentifier2 {
          Name
          Value
        }
        LineIdentifier3 {
          Name
          Value
        }
        AvailableEquipment {
          EquipmentCode
          EquipmentName
          EquipmentType
        }
        Passengers {
          UnitPrice
          BasicDiscountPrice
          BasicDiscountName
          FinalPrice
          VatPercentage
          RequestedFareClass
          SeatPricings {
            FinalPrice
            UnitPrice
            BasicDiscountPrice
            VatPercentage
            IsBusinessClass
            IsCampaignPrice
            IsCampaignTicketChangeable
            IsCampaignTicketRefundable
            IsBusPrice
            SeatClassCategory
            SeatClassName
          }
        }
      }
      SalesFees {
        TotalBusinessClassSalesFee
        TotalRegularSalesFee
        TotalCampaignSalesFee
        TotalBusinessClassCampaignFee
      }
      Notifications {
        NotificationMessage
      }
    }
  }
`;

export const POPULAR_DESTINATIONS = gql`
  query($lang: String) {
    popularDestinations(lang: $lang) {
      CityId
      CityName
      BusStops {
        BusStopId
        BusStopName
        BusStopCode
        BusStopExternalCode
        CountyName
        CountryName
        HasPriority
        IsAirportBusStop
        Comment
      }
    }
  }
`;
