import React, { useContext } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { DEFAULT_LANG } from '@lux/spa/config';
import { StoreContext } from '@lux/spa/store';
import { Container, Button } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './thanks-layout.module.scss';

const ThanksContentError = ({ className, t, i18n, renderCancel = false }) => {
  const { language } = i18n;
  const [state, dispatch] = useContext(StoreContext);

  return (
    <div className={cn(s.thanksLayout, className)}>
      <Container className={s.container}>
        <div className={s.greyBlock} />
        <Container full={false}>
          <section>
            <h1>{t('thank you for choosing Lux Express')}</h1>
            {/* <div className={s.orderNumber}>
              {t('order number')}: <span>{orderNumber}</span>
            </div> */}
            <div className={s.content}>
              <img className={s.icon} src="/images/svg/failed.svg" alt="" />
              <h2>{t('Tickets were not purchased')}</h2>
              <p>
                {t(
                  'tickets for some reason were not purchased. Try to buy them again.'
                )}
              </p>
              {renderCancel && (
                <div className={s.controls}>
                  <Button
                    className={s.button}
                    text={t('back to payment')}
                    to={`${
                      language === DEFAULT_LANG ? '' : `/${language}`
                    }/tickets/payment/banks`}
                  />
                </div>
              )}
            </div>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(ThanksContentError);
