import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalContent, Notice, Button } from '@lux/ui';
import s from './bus-modal.module.scss';

const BusModal = ({
  t,
  isOpen,
  toggleModal,
  Bus = null,
  SeatTypes = null,
  leg,
  passenger = 1,
}) => {
  const onConfirm = () => {
    toggleModal();
  };
  const [isDisabledContinue, setIsDisabledContinue] = useState(false);

  return (
    <Modal
      className={s.modal}
      isOpen={isOpen}
      toggleModal={isDisabledContinue ? false : toggleModal}
    >
      <ModalHeader text={`${t('passenger')} ${passenger}`} />
      <ModalContent className={s.content}>
        <div className={s.main}>
          <SeatTypes />
          <Notice text="Campaign price only related to regular seats. Discounts are not aplicable to lounge seats" />
          <Bus
            className={s.bus}
            leg={leg}
            setIsDisabledContinue={setIsDisabledContinue}
          />
        </div>

        <div className={s.confirm}>
          <Button
            text={isDisabledContinue ? t('locading') : t('continue')}
            onClick={onConfirm}
            disabled={isDisabledContinue}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default withTranslation()(BusModal);
