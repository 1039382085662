import React, { useEffect, useState, useReducer, useRef } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Query } from 'react-apollo';
import { GET_ALL_ROUTES_ADMIN } from '@lux/spa/apollo/queries';
import { Button, Input, Loading } from '@lux/ui';
import s from './route.module.scss';

const ListSuggestions = ({ t, search, routes, selectItem, suggestionsRef }) => {
  const filtered = routes.filter(route => {
    const title = `${route.departure?.name} - ${route.arrival?.name}`.toLowerCase();
    return title.includes(search.toLowerCase());
  });
  return (
    <div className={s.listSuggestions} ref={suggestionsRef}>
      <div className={s.wrap}>
        <div className={s.triangle} />
      </div>
      {filtered.length ? (
        filtered.map((route, key) => {
          const title = `${route.departure?.name} - ${route.arrival?.name}`;
          const selectRoute = {
            title,
            slug: route.data.slug,
          };
          return (
            <div
              key={key}
              className={s.item}
              onClick={() => selectItem(selectRoute)}
            >
              <span>{title}</span>
            </div>
          );
        })
      ) : (
        <span>
          {t('so matches for')} «<b>{search}</b>»
        </span>
      )}
    </div>
  );
};

const routeReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_ROUTE':
      return {
        ...state,
        ...action.payload,
      };
  }
};

const Route = ({
  t,
  className,
  toggleEditSlide,
  slide = {},
  slides,
  sliderDispatch,
  i18n,
  ...props
}) => {
  const { language } = i18n;

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, routeDispatch] = useReducer(routeReducer, slide);

  const { title = {}, slug = {}, order } = state;

  const isNew = order > slides.length;
  const type = isNew ? 'ADD_SLIDE' : 'UPDATE_SLIDE';

  const [isOpenSugg, setIsOpenSugg] = useState(false);
  const [inputValue, setInputValue] = useState(title.value);

  const suggestionsRef = useRef(null);

  const selectItem = route => {
    routeDispatch({
      type: 'UPDATE_ROUTE',
      payload: {
        title: {
          ...title,
          value: route.title,
        },
        slug: {
          ...slug,
          value: route.slug,
        },
      },
    });
    setIsOpenSugg(false);
    setInputValue(route.title);
  };

  const hideSuggestions = e => {
    if (!e.path.includes(suggestionsRef.current)) {
      setIsOpenSugg(false);
    }
  };

  const saveSlide = () => {
    sliderDispatch({
      type,
      payload: {
        slide: state,
      },
    });
    toggleEditSlide();
  };

  useEffect(() => {
    if (isClient && !('path' in Event.prototype)) {
      Object.defineProperty(Event.prototype, 'path', {
        get() {
          const path = [];
          let currentElem = this.target;
          while (currentElem) {
            path.push(currentElem);
            currentElem = currentElem.parentElement;
          }

          if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
            path.push(document);
          }

          if (path.indexOf(window) === -1) {
            path.push(window);
          }

          return path;
        },
      });
    }

    if (isClient) {
      if (isOpenSugg) {
        document.addEventListener('click', hideSuggestions, false);

        return () => {
          document.removeEventListener('click', hideSuggestions, false);
        };
      }
    }
  }, [isOpenSugg]);

  useEffect(() => {
    if (inputValue) {
      setIsOpenSugg(true);
    } else {
      setIsOpenSugg(false);
    }
  }, [inputValue]);

  return (
    <div className={cn(s.route, className)}>
      <Query
        query={GET_ALL_ROUTES_ADMIN}
        variables={{ lang: language, country: 'ee' }}
      >
        {({ data = {}, loading }) => {
          if (loading) return <Loading />;
          const routes = data?.adminRoutes;
          return (
            !!routes?.length && (
              <div className={s.input}>
                <Input
                  autoFocus
                  label={t('route')}
                  value={inputValue}
                  onChange={e => setInputValue(e.target.value)}
                />
                {isOpenSugg && (
                  <ListSuggestions
                    t={t}
                    routes={routes}
                    search={inputValue}
                    selectItem={selectItem}
                    suggestionsRef={suggestionsRef}
                  />
                )}
              </div>
            )
          );
        }}
      </Query>
      <div className={s.controls}>
        <Button text="Save" onClick={saveSlide} />
        <Button text="Cancel" onClick={toggleEditSlide} />
      </div>
    </div>
  );
};

export default withTranslation()(Route);
