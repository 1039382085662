import gql from 'graphql-tag';

export const COMPANY_LOGO = gql`
  query($name: [String]) {
    companyLogo(name: $name) {
      name
      file
    }
  }
`;
