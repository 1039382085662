import React, {
  useState,
  useReducer,
  useRef,
  useEffect,
  useContext,
} from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { Suggestions } from '@lux/spa/components';
import {
  Input,
  Button,
  Select,
  SelectOption,
  UploadImages,
  SearchDestinationMobile,
} from '@lux/ui';
import s from './route-fields.module.scss';

const routeFieldsReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE':
      return {
        ...state,
        ...action.payload,
      };
    case 'TOGGLE_SUGGESTIONS_MODAL':
      return {
        ...state,
        ...action.payload,
        isOpenSuggMob: !state.isOpenSuggMob,
      };
    case 'HIDE_SUGGESTIONS':
      return {
        ...state,
        isOpenSugg: false,
      };
    case 'SHOW_SUGGESTIONS':
      return {
        ...state,
        isOpenSugg: !!state[action.payload.field].search,
        ...action.payload,
      };
    // case 'SELECT_ITEM':
    //   return {
    //     ...state,
    //     search: action.payload.search,
    //     isOpenSugg: false,
    //     [state.field]: {
    //       ...state[state.field],
    //       id: action.payload.id
    //     }
    //   }
    case 'UPDATE_SEARCH':
      const { search } = action.payload;
      return {
        ...state,
        ...action.payload,
        isOpenSugg: !!search,
        // [state.field]: search
        [state.field]: {
          ...state[state.field],
          search,
        },
      };
    default:
      throw new Error();
  }
};

const RouteFields = ({
  t,
  updateSeo,
  state: pageState,
  dispatch: pageDispatch,
  existingsTranslates,
}) => {
  const { isMob } = useContext(WidthContext);

  const arrival = pageState.page?.arrival || {
    id: '',
    name: '',
  };
  const departure = pageState.page?.departure || {
    id: '',
    name: '',
  };

  const initialSearch = {
    copyStopsFrom: '',
    isOpenSugg: false,
    isOpenSuggMob: false,
    field: '',
    search: '',
    from: {
      id: null,
      search: departure.name,
    },
    to: {
      id: null,
      search: arrival.name,
    },
  };

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, dispatch] = useReducer(routeFieldsReducer, initialSearch);

  const [isThumbnail, setIsThumbnail] = useState(false);

  const toggleThumbnail = () => {
    setIsThumbnail(!isThumbnail);
  };

  const { page, lang } = pageState;

  const thumbnail = page?.[lang]?.seo?.thumbnail;

  const {
    to,
    from,
    search,
    field,
    isOpenSugg,
    isOpenSuggMob,
    copyStopsFrom,
  } = state;

  const suggestionsRef = useRef(null);
  const fromRef = useRef(null);
  const toRef = useRef(null);

  // const updateSeo = field => {
  //   pageDispatch({
  //     type: 'UPDATE_SEO',
  //     payload: {
  //       field,
  //     }
  //   })
  // }

  const updateSearch = e => {
    dispatch({
      type: 'UPDATE_SEARCH',
      payload: {
        search: e.target.value,
      },
    });
  };

  const toggleSuggModal = field => {
    dispatch({
      type: 'TOGGLE_SUGGESTIONS_MODAL',
      payload: {
        // INFO: in this case field some time is event object and need if below
        ...(field == 'to' || field == 'from' ? { field } : {}),
      },
    });
  };

  const showSuggestion = field => {
    dispatch({
      type: 'SHOW_SUGGESTIONS',
      payload: {
        field,
      },
    });
  };

  const hideSuggestions = e => {
    if (
      !e.path.includes(suggestionsRef.current) &&
      !e.path.includes(toRef.current) &&
      !e.path.includes(fromRef.current)
    ) {
      dispatch({ type: 'HIDE_SUGGESTIONS' });
    }
  };

  const applyCopyStops = () => {
    const data = page[copyStopsFrom].pageData.stops;

    pageDispatch({
      type: 'UPDATE_STOPS',
      payload: {
        data,
      },
    });
  };

  const selectItem = search => {
    // dispatch({
    //   type: 'SELECT_ITEM',
    //   payload: {
    //     search: search.BusStopName,
    //     id: search.BusStopId
    //   }
    // })

    pageDispatch({
      type: 'UPDATE_PAGE',
      payload: {
        ...(field == 'from'
          ? {
              departure: {
                id: search.CityId,
                name: search.CityName,
              },
            }
          : {
              arrival: {
                id: search.CityId,
                name: search.CityName,
              },
            }),
      },
    });

    // updateSeo({
    //   title: {
    //     ...page[lang].seo.title,
    //     value: `${departure?.name}-${arrival?.name}`
    //   }
    // })
  };

  useEffect(() => {
    if (isClient && !('path' in Event.prototype)) {
      Object.defineProperty(Event.prototype, 'path', {
        get() {
          const path = [];
          let currentElem = this.target;
          while (currentElem) {
            path.push(currentElem);
            currentElem = currentElem.parentElement;
          }

          if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
            path.push(document);
          }

          if (path.indexOf(window) === -1) {
            path.push(window);
          }

          return path;
        },
      });
    }

    if (isClient) {
      if (isOpenSugg) {
        document.addEventListener('click', hideSuggestions, false);

        return () => {
          document.removeEventListener('click', hideSuggestions, false);
        };
      }
    }
  }, [isOpenSugg]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE',
      payload: {
        copyStopsFrom: existingsTranslates.filter(item => item != lang)[0],
      },
    });
  }, []);

  return (
    <div className={s.routeFields}>
      <UploadImages
        isOpen={isThumbnail}
        toggleModal={toggleThumbnail}
        updatePage={updateSeo}
        image={thumbnail}
      />
      <SearchDestinationMobile
        modalTitle={field}
        search={search}
        isOpen={isOpenSuggMob}
        toggleModal={toggleSuggModal}
        {...{ selectItem, updateSearch }}
      />
      <div className={s.inputs}>
        <div className={s.input} ref={fromRef}>
          <Input
            label={t('from')}
            value={from.search}
            onClick={
              isMob
                ? () => toggleSuggModal('from')
                : () => showSuggestion('from')
            }
            onChange={e => updateSearch(e)}
          />
          {!isMob && isOpenSugg && field == 'from' && (
            <Suggestions
              search={from.search}
              onlyCity
              {...{
                selectItem,
                suggestionsRef,
              }}
            />
          )}
        </div>
        <div className={s.input} ref={toRef}>
          <Input
            label={t('to')}
            value={to.search}
            onClick={
              isMob ? () => toggleSuggModal('to') : () => showSuggestion('to')
            }
            onChange={e => updateSearch(e)}
          />
          {!isMob && isOpenSugg && field == 'to' && (
            <Suggestions
              search={to.search}
              onlyCity
              {...{
                selectItem,
                suggestionsRef,
              }}
            />
          )}
        </div>
      </div>
      <div className={s.title}>{t('thumbnail')}</div>
      <div
        className={cn(s.thumbnail, thumbnail.value && s.load)}
        style={{
          backgroundImage: `url(${thumbnail.value})`,
        }}
      >
        <Button text={t('upload thumbnail')} onClick={toggleThumbnail} />
      </div>
      {copyStopsFrom && (
        <>
          <div className={s.title}>{t('copy stops')}</div>
          <div className={s.copyStops}>
            {t('from')}
            <Select
              value={copyStopsFrom}
              className={s.select}
              onChange={e => selectCopyFrom(e.target.value)}
            >
              {existingsTranslates.map(
                item =>
                  item != lang && (
                    <SelectOption key={item} value={item} text={item} />
                  )
              )}
            </Select>
            <Button
              className={s.button}
              onClick={() => applyCopyStops()}
              children={t('copy')}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default withTranslation()(RouteFields);
