import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  createRef,
} from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { Formik } from 'formik';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { StoreContext } from '@lux/spa/store';
import { useMutation } from '@apollo/react-hooks';
import { regExpForNames } from 'utils';
import PhoneNumber from 'awesome-phonenumber';
import {
  SIGN_UP,
  PROFILE_EXISTS,
  VERIFY_EMAIL_CODE,
  SEND_EMAIL,
} from '@lux/spa/apollo/mutations';
import {
  Container,
  Button,
  TicketsInput,
  HiddenInput,
  Link,
  PhoneInput,
  Notice,
} from '@lux/ui';
import { validToUseExponea } from '@lux/spa/config';
import s from './registration.module.scss';

const Registration = ({ t, i18n, className, history }) => {
  const { language } = i18n;
  const [state, dispatch] = useContext(StoreContext);
  const { user } = state;
  const { phone, email, firstName, lastName } = user;

  const [isProfileExists, { profileExistsLoading }] = useMutation(
    PROFILE_EXISTS
  );

  const [
    sendEmail,
    { data: sendEmailData, loading: sendEmailLoading },
  ] = useMutation(SEND_EMAIL);

  const [
    signUp,
    { loading: signUpLoading, data: signUpData = {}, called: signUpCalled },
  ] = useMutation(SIGN_UP);

  const [
    verifyEmailCode,
    { data: verifyEmailCodeData, loading, called: calledVerifyEmailCode },
  ] = useMutation(VERIFY_EMAIL_CODE);

  const [step, setStep] = useState(1);
  const [code, setCode] = useState({
    1: '',
    2: '',
    3: '',
    4: '',
  });

  const codeInput1 = useRef();
  const codeInput2 = useRef();
  const codeInput3 = useRef();
  const codeInput4 = useRef();

  const phoneNumberInput = createRef();
  const inputsRefs = {
    1: codeInput1,
    2: codeInput2,
    3: codeInput3,
    4: codeInput4,
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleChangeCode = e => {
    const inputNumber = +e.target.dataset.number;
    const { value = '' } = e.target;

    if (value.length === 1) {
      setCode(code => ({
        ...code,
        [inputNumber]: value,
      }));
      if (inputsRefs[inputNumber + 1]?.current) {
        inputsRefs[inputNumber + 1].current.focus();
        inputsRefs[inputNumber + 1].current.select();
      }
    } else if (value.length === 0) {
      setCode(code => ({
        ...code,
        [inputNumber]: value,
      }));
    }
  };

  const handleFocus = e => {
    const inputNumber = +e.target.dataset.number;
    if (inputsRefs[inputNumber]?.current) {
      inputsRefs[inputNumber].current.select();
    }
  };

  useEffect(() => {
    const errors = verifyEmailCodeData?.verifyEmailCode?.errors;
    // if (errors?.length) {
    //   // toast.error(
    //   //   `Errors: ${errors.map(item => item.messages.join(', ')).join(', ')}`
    //   // );
    //   return;
    // }
    if (calledVerifyEmailCode && !loading && !errors?.length) {
      nextStep();
    }
  }, [loading, calledVerifyEmailCode]);

  useEffect(() => {
    const token = signUpData?.signUp?.token;
    const profile = signUpData?.signUp?.profile;
    if (!signUpLoading && signUpCalled) {
      if (token) {
        // localStorage.setItem('token', token);
        const maxAgeCookie = 2147483647; // 2147483647 ~ 68 years
        document.cookie = `token=${token};path=/;max-age=${maxAgeCookie};`;

        const areaCode = new PhoneNumber(profile.phone).getCountryCode();
        const countryCode = new PhoneNumber(profile.phone).getRegionCode();

        if (validToUseExponea()) {
          exponea.identify({ registered: profile.email });

          exponea.update({
            email: profile.email,
            phone_number: profile.phone,
            first_name: profile.firstName,
            last_name: profile.lastName,
          });

          exponea.track('customer_registration', {
            email: profile.email,
            phone: profile.phone,
            area_code: areaCode,
            country: countryCode,
            registered: true,
          });
        }

        dispatch({
          type: 'UPDATE_USER',
          payload: {
            ...profile,
          },
        });

        history.push(`/${language}/`);
      }
    }
  }, [signUpLoading, signUpCalled]);

  return (
    <div className={cn(className, s.registration)}>
      <Container>
        <Container full={false}>
          <section>
            <div className={s.image}>
              <img src="/images/svg/registration.svg" alt="" />
            </div>
            <Formik
              // innerRef={formRef}
              validateOnMount
              enableReinitialize
              initialValues={{
                phoneNumber: phone,
                firstName,
                lastName,
                email,
                password: '',
                confirmPassword: '',
                confirmPrivacyPolicy: false,
              }}
              validationSchema={Yup.object().shape({
                phoneNumber: Yup.string()
                  .max(
                    20,
                    t('validation.error.max', {
                      field: t('phone number'),
                      max: 20,
                    })
                  )
                  .min(
                    10,
                    t('validation.error.min', {
                      field: t('phone number'),
                      min: 10,
                    })
                  )
                  .required(
                    t('validation.error.require', {
                      field: t('phone number'),
                    })
                  ),
                email: Yup.string()
                  .email(
                    t('validation.error.email', {
                      field: t('email'),
                    })
                  )
                  .required(
                    t('validation.error.require', {
                      field: t('email'),
                    })
                  ),
                firstName: Yup.string()
                  .required(
                    t('validation.error.require', {
                      field: t('first name'),
                    })
                  )
                  .matches(
                    regExpForNames,
                    t('validation.error.names', {
                      field: t('first name'),
                    })
                  ),
                lastName: Yup.string()
                  .required(
                    t('validation.error.require', {
                      field: t('last name'),
                    })
                  )
                  .matches(
                    regExpForNames,
                    t('validation.error.names', {
                      field: t('last name'),
                    })
                  ),
                password: Yup.string()
                  .required(
                    t('validation.error.require', {
                      field: t('password'),
                    })
                  )
                  .min(
                    6,
                    t('validation.error.min', {
                      field: t('password'),
                      min: 6,
                    })
                  ),
                confirmPassword: Yup.string()
                  .required(
                    t('validation.error.require', {
                      field: t('confirm password'),
                    })
                  )
                  .oneOf(
                    [Yup.ref('password'), null],
                    t('passwords must match')
                  ),
                confirmPrivacyPolicy: Yup.boolean()
                  .required(
                    t('validation.error.require', {
                      field: t('privacy policy'),
                    })
                  )
                  .oneOf(
                    [true],
                    t('validation.error.require', {
                      field: t('privacy policy'),
                    })
                  ),
              })}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  setFieldError,
                } = props;

                return (
                  <form className={s.form}>
                    {step === 1 && (
                      <div className={cn(s.step, s.step1)}>
                        <h2 className={s.title}>{t('registration')}</h2>
                        <h5>{t('Email and Phone')}</h5>
                        <p className={s.text}>
                          {t('Please, indicate your Email and Phone number')}
                        </p>
                        <div className={s.input}>
                          <TicketsInput
                            autoFocus
                            name="email"
                            label={t('email')}
                            type="text"
                            required
                            value={values.email}
                            onChange={e => {
                              e.target.value = e.target.value.trim();
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            errors={touched.email && errors.email}
                          />
                        </div>
                        <div className={s.input}>
                          <PhoneInput
                            autoFocus
                            ref={phoneNumberInput}
                            inputClassName={s.phone}
                            name="phoneNumber"
                            label={t('phone number')}
                            placeholder={t('enter phone number')}
                            value={values.phoneNumber}
                            required
                            onChange={e => {
                              setFieldValue('phoneNumber', e);
                            }}
                            onBlur={handleBlur}
                            errors={touched.phoneNumber && errors.phoneNumber}
                          />
                        </div>
                        {!!sendEmailData?.sendEmail?.errors?.length && (
                          <Notice
                            color="red"
                            className={s.error}
                            text={sendEmailData.sendEmail.errors[0].messages[0]}
                          />
                        )}
                        <Button
                          disabled={
                            sendEmailLoading ||
                            profileExistsLoading ||
                            errors.phoneNumber ||
                            errors.email
                          }
                          className={s.button}
                          text={
                            sendEmailLoading || profileExistsLoading
                              ? t('loading')
                              : t('continue')
                          }
                          onClick={() => {
                            isProfileExists({
                              variables: {
                                email: values.email,
                              },
                            }).then(data => {
                              if (data?.data?.profileExists?.error) {
                                setFieldError(
                                  'email',
                                  data?.data?.profileExists?.error.ErorReason
                                );
                                return;
                              }

                              if (data?.data?.profileExists?.exists) {
                                setFieldError(
                                  'email',
                                  t('this email already exists')
                                );
                              } else {
                                sendEmail({
                                  variables: {
                                    input: {
                                      email: values.email,
                                    },
                                  },
                                }).then(data => {
                                  if (!data.data.sendEmail.errors.length) {
                                    nextStep();
                                  }
                                });
                              }
                            });
                          }}
                        />
                      </div>
                    )}

                    {step === 2 && (
                      <div className={cn(s.step, s.step2)}>
                        <h2 className={s.title}>{t('registration')}</h2>
                        <h5>{t('email verification')}</h5>
                        <p className={s.text}>
                          {t('enter the code send to')}:{' '}
                          <span>{values.email}</span>
                        </p>
                        <div className={s.code}>
                          <div className={s.input}>
                            <TicketsInput
                              autoComplete
                              autoFocus
                              inputRef={codeInput1}
                              name="code-1"
                              tabIndex={-1}
                              data-number={1}
                              placeholder="—"
                              type="tel"
                              required
                              className={s.codeInput}
                              value={code[1]}
                              // onKeyPress={onKeyPress}
                              // onClick={handleFocus}
                              onChange={handleChangeCode}
                              onFocus={handleFocus}
                            />
                          </div>
                          <div className={s.input}>
                            <TicketsInput
                              autoComplete
                              inputRef={codeInput2}
                              name="code-2"
                              tabIndex={-2}
                              data-number={2}
                              placeholder="—"
                              type="tel"
                              required
                              className={s.codeInput}
                              value={code[2]}
                              // onKeyPress={onKeyPress}
                              // onClick={handleFocus}
                              onChange={handleChangeCode}
                              onFocus={handleFocus}
                            />
                          </div>
                          <div className={s.input}>
                            <TicketsInput
                              autoComplete
                              inputRef={codeInput3}
                              name="code-3"
                              tabIndex={-3}
                              data-number={3}
                              placeholder="—"
                              type="tel"
                              required
                              className={s.codeInput}
                              value={code[3]}
                              // onKeyPress={onKeyPress}
                              // onClick={handleFocus}
                              onChange={handleChangeCode}
                              onFocus={handleFocus}
                            />
                          </div>
                          <div className={s.input}>
                            <TicketsInput
                              autoComplete
                              inputRef={codeInput4}
                              name="code-4"
                              tabIndex={-4}
                              data-number={4}
                              placeholder="—"
                              type="tel"
                              required
                              className={s.codeInput}
                              value={code[4]}
                              // onKeyPress={onKeyPress}
                              // onClick={handleFocus}
                              onChange={handleChangeCode}
                              onFocus={handleFocus}
                            />
                          </div>
                        </div>

                        {!!verifyEmailCodeData?.verifyEmailCode?.errors
                          ?.length && (
                          <Notice
                            color="red"
                            text={
                              verifyEmailCodeData?.verifyEmailCode?.errors[0]
                                .messages[0]
                            }
                          />
                          // <div className={s.error}>
                          //   {
                          //     verifyEmailCodeData?.verifyEmailCode?.errors[0]
                          //       .messages[0]
                          //   }
                          // </div>
                        )}
                        <Button
                          className={s.button}
                          text={t('send code')}
                          disabled={Object.values(code).join('').length !== 4}
                          onClick={() =>
                            verifyEmailCode({
                              variables: {
                                input: {
                                  email: values.email,
                                  code: Object.values(code).join(''),
                                },
                              },
                            }).then(data => {
                              if (data?.data?.signIn?.error) {
                                return;
                              }

                              const token = data?.data?.signIn?.token;
                              const profile = data?.data?.signIn?.profile;

                              if (token) {
                                // localStorage.removeItem('token', token);
                                // localStorage.setItem('token', token);
                                const maxAgeCookie = 2147483647; // 2147483647 ~ 68 years
                                document.cookie = `token=${token};path=/;max-age=${maxAgeCookie};`;

                                dispatch({
                                  type: 'UPDATE_USER',
                                  payload: {
                                    ...profile,
                                  },
                                });

                                history.push(`/${language}/`);
                              }
                            })
                          }
                        />
                      </div>
                    )}

                    {step === 3 && (
                      <div className={cn(s.step, s.step3)}>
                        <h2 className={s.title}>{t('registration')}</h2>
                        <h5>{t('First and Last name')}</h5>
                        <p className={s.text}>
                          {t('please, enter your first and last name')}
                        </p>
                        <div className={s.input}>
                          <TicketsInput
                            autoFocus
                            name="firstName"
                            type="text"
                            required
                            label={t('first name')}
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errors={touched.firstName && errors.firstName}
                          />
                        </div>
                        <div className={s.input}>
                          <TicketsInput
                            name="lastName"
                            type="text"
                            label={t('last name')}
                            required
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errors={touched.lastName && errors.lastName}
                          />
                        </div>
                        <Button
                          disabled={
                            errors.firstName || errors.lastName || errors.email
                          }
                          className={s.button}
                          text={t('continue')}
                          onClick={nextStep}
                        />
                      </div>
                    )}

                    {step === 4 && (
                      <div className={cn(s.step, s.step4)}>
                        <h2 className={s.title}>{t('registration')}</h2>
                        <h5>{t('pick your password')}</h5>
                        <p className={s.text}>
                          {t('it must consist at least 6 characters')}
                        </p>
                        <div className={s.input}>
                          <HiddenInput
                            autoFocus
                            name="password"
                            placeholder="••••••"
                            label={t('password')}
                            required
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errors={touched.password && errors.password}
                          />
                        </div>
                        <div className={s.input}>
                          <HiddenInput
                            name="confirmPassword"
                            placeholder="••••••"
                            label={t('confirm password')}
                            required
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errors={
                              touched.confirmPassword && errors.confirmPassword
                            }
                          />
                        </div>
                        <div className={s.input}>
                          <div className={s.checkbox}>
                            <div
                              className={cn(
                                s.check,
                                values.confirmPrivacyPolicy && s.active,
                                errors.confirmPrivacyPolicy && s.checkError
                              )}
                              onClick={() =>
                                setFieldValue(
                                  'confirmPrivacyPolicy',
                                  !values.confirmPrivacyPolicy
                                )
                              }
                            />
                            {t('i read and agree with Lux Express')}
                            <Link className={s.link} to="/privacy-policy/">
                              {t('privacy policy')}.
                            </Link>
                          </div>
                        </div>
                        <Button
                          disabled={
                            errors.password ||
                            errors.confirmPassword ||
                            !values.confirmPrivacyPolicy
                          }
                          className={s.button}
                          text="Register"
                          onClick={() =>
                            signUp({
                              variables: {
                                input: {
                                  phone: values.phoneNumber,
                                  firstName: values.firstName,
                                  lastName: values.lastName,
                                  password: values.password,
                                  email: values.email,
                                },
                              },
                            })
                          }
                        />
                      </div>
                    )}
                  </form>
                );
              }}
            </Formik>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withRouter(withTranslation()(Registration));
