import React, { useState, useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withRouter } from 'react-router';
import { withTranslation, Trans } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { StoreContext } from '@lux/spa/store';
import { DEFAULT_LANG } from '@lux/spa/config';
import { toast } from 'react-toastify';
import { MODIFICATION_CORPORATE_CLIENT_PAY } from '@lux/spa/apollo/mutations';
import { TicketsInput, Button, HiddenInput, Notice } from '@lux/ui';
import s from './corporate-client.module.scss';

const CorporeteClient = ({ t, i18n, history }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { language } = i18n;
  const { changeTickets } = state;
  const { totalPrice, search, trip } = changeTickets;
  const { from = {}, to = {} } = search;
  const { ShoppingBasketId = '' } = trip;
  const [clientError, setClientError] = useState('');
  const [corporateClient] = useMutation(MODIFICATION_CORPORATE_CLIENT_PAY);

  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{
        userName: '',
        password: '',
      }}
      validationSchema={Yup.object().shape({
        userName: Yup.string().required(
          t('validation.error.require', {
            field: t('user name'),
          })
        ),
        password: Yup.string()
          .required(
            t('validation.error.require', {
              field: t('password'),
            })
          )
          .min(
            6,
            t('validation.error.min', {
              field: t('password'),
              min: 6,
            })
          ),
      })}
      onSubmit={async (values, { setFieldError }) => {
        const { userName, password } = values;
        corporateClient({
          variables: {
            input: {
              Username: userName,
              Password: password,
            },
          },
        })
          .then(data => {
            const error = data?.data?.modificationCorporateClientPay?.error;
            const success = data?.data?.modificationCorporateClientPay?.success;

            if (error) {
              if (error.ErrorCode === 'wrong_password') {
                setFieldError('password', error.ErrorReason);
                return;
              }

              if (error.ErrorCode === 'basket_expired') {
                dispatch({
                  type: 'UPDATE_CHANGE_TICKETS',
                  payload: {
                    isBasketExrpired: true,
                  },
                });
                return;
              }

              setClientError(error.ErrorReason);
            }

            if (success) {
              // exponea.track('payment_selected', {
              //   timestamp: Date.now(),
              //   payment_method: 'corporate',
              //   total_price: totalPrice,
              //   currency: currencyName,
              //   Origin_stop_ID: from.BusStopId,
              //   Destination_stop_ID: to.BusStopId,
              // });

              const promise = new Promise(res => res());

              promise
                .then(() => {
                  if (data?.data?.modificationCorporateClientPay?.objects) {
                    dispatch({
                      type: 'UPDATE_CHANGE_TICKETS',
                      payload: {
                        forAnalitics: {
                          id:
                            data.data.modificationCorporateClientPay.objects[0]
                              .ShoppingBasketCode,
                          quantity: data.data.modificationCorporateClientPay.objects[0].Passengers.reduce(
                            (sum, pass) => (sum += pass.Tickets.length),
                            0
                          ),
                        },
                      },
                    });
                  }
                })
                .then(() => {
                  history.push(
                    language === DEFAULT_LANG
                      ? `/change-tickets/thanks?type=corporate-client&shopperOrder=${ShoppingBasketId}`
                      : `/${language}/change-tickets/thanks?type=corporate-client&shopperOrder=${ShoppingBasketId}`
                  );
                });
            }
          })
          .catch(() => {
            dispatch({
              type: 'UPDATE_CHANGE_TICKETS',
              payload: {
                isSomethingWrong: true,
              },
            });
          });
      }}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          setFieldError,
        } = props;
        const isTochedForm = !!Object.keys(touched).length;
        const isValidForm = !Object.keys(errors).length;
        return (
          <>
            <div className={s.corporateClient}>
              <form className={s.form}>
                {clientError && <Notice color="red" text={clientError} />}
                <TicketsInput
                  name="userName"
                  placeholder={t('enter username')}
                  type="text"
                  label={t('username')}
                  required
                  value={values.userName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={touched.userName && errors.userName}
                />
                <HiddenInput
                  tooltipText={t(
                    'by completing this booking, I confirm that I have read and agree to LuxExpress Terms and Privacy Policy'
                  )}
                  name="password"
                  placeholder="••••••"
                  label={t('password')}
                  required
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={touched.password && errors.password}
                />
                <Button
                  // type="submit"
                  disabled={!(isTochedForm && isValidForm) || isSubmitting}
                  text={t('submit')}
                  onClick={() => {
                    corporateClient({
                      variables: {
                        input: {
                          Username: values.userName,
                          Password: values.password,
                        },
                      },
                    })
                      .then(data => {
                        const error =
                          data?.data?.modificationCorporateClientPay?.error;
                        const success =
                          data?.data?.modificationCorporateClientPay?.success;

                        if (error) {
                          if (error.ErrorCode === 'wrong_password') {
                            setFieldError('password', error.ErrorReason);
                            return;
                          }

                          if (error.ErrorCode === 'basket_expired') {
                            dispatch({
                              type: 'UPDATE_CHANGE_TICKETS',
                              payload: {
                                isBasketExrpired: true,
                              },
                            });
                            return;
                          }

                          setClientError(error.ErrorReason);
                        }

                        if (success) {
                          if (
                            data?.data?.modificationCorporateClientPay?.objects
                          ) {
                            dispatch({
                              type: 'UPDATE_CHANGE_TICKETS',
                              payload: {
                                forAnalitics: {
                                  id:
                                    data.data.modificationCorporateClientPay
                                      .objects[0].ShoppingBasketCode,
                                  quantity: data.data.modificationCorporateClientPay.objects[0].Passengers.reduce(
                                    (sum, pass) => (sum += pass.Tickets.length),
                                    0
                                  ),
                                },
                              },
                            });
                          }

                          history.push(
                            language === DEFAULT_LANG
                              ? `/change-tickets/thanks?type=corporate-client&shopperOrder=${ShoppingBasketId}`
                              : `/${language}/change-tickets/thanks?type=corporate-client&shopperOrder=${ShoppingBasketId}`
                          );
                        }
                      })
                      .catch(() => {
                        toast.error(t('Something wrong'));
                      });
                  }}
                />
              </form>
              <Notice className={s.notice}>
                <Trans i18nKey="if you want to become a corporate client, please contact us">
                  if you want to become a corporate client, please
                  <a href="mailto:ariklient@luxexpress.eu">contact us</a>
                </Trans>
              </Notice>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default withRouter(withTranslation()(CorporeteClient));
