import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import { Query } from 'react-apollo';
import { NavLink, Divider, Loading, Login } from '@lux/ui';
import { ORDER_WIDGET } from '@lux/spa/apollo/queries';
import { DEFAULT_LANG } from '@lux/spa/config';
import { removeCookie } from 'utils';
import { langs } from '@lux/spa/i18n';
import i from '@lux/spa/styles/icons.module.scss';
import s from './header-mobile.module.scss';

const SubMenu = ({ children = null, className, title, selected, asLink }) => {
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);
  const toggleSubMenu = () => {
    setIsOpenSubMenu(!isOpenSubMenu);
  };
  return asLink ? (
    <div className={cx(s.item, s.asLink, className)}>{children}</div>
  ) : (
    <div className={cx(s.item, className)}>
      <div
        className={cx(s.title, selected && s.withSelected)}
        onClick={children && toggleSubMenu}
      >
        {title}
        {selected && <span className={s.selected}>{selected}</span>}
        {children && (
          <i className={cx(i.arrowBottom, isOpenSubMenu && s.open)} />
        )}
      </div>
      <div
        className={cx(s.list, isOpenSubMenu && s.open)}
        onClick={setIsOpenSubMenu}
        children={children}
      />
    </div>
  );
};

const MobileHeader = ({
  t,
  i18n,
  isMob,
  isOpen,
  toggleMenu,
  onChangeLang,
  user = {},
  dispatch,
  isTicketPages,
  currencyName,
  travelInfoLink,
  history,
  showFaq,
}) => {
  const { language } = i18n;
  const { email, avatar, firstName, lastName, phone } = user;

  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);

  const toggleLoginModal = () => {
    setIsOpenLoginModal(!isOpenLoginModal);
  };

  const logout = () => {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem('token');
      removeCookie('token');

      dispatch({
        type: 'LOG_OUT',
      });

      history.push(`/${DEFAULT_LANG === language ? '' : language}/`);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  return (
    <div
      className={cx(s.nav, isTicketPages && s.ticketPages, isOpen && s.open)}
    >
      <div className={s.menu}>
        <div className={cx(s.block, s.routes)}>
          {!isTicketPages ? (
            <>
              {/* <Query
                query={isStaff ? GET_ALL_PROMO_ADMIN : GET_ALL_PROMO}
                variables={{
                  lang: language,
                  country: region,
                }}
              >
                {({ data = {}, loading }) => {
                  if (loading) return '';
                  const promos = isStaff
                    ? data?.adminPromoPages?.edges
                    : data?.promoPages?.edges;
                  return (
                    (isStaff || Boolean(promos?.length)) && (
                      <SubMenu title={t('promo')}>
                        {promos.map(route => {
                          const title = route.node.data.seo.title.value;
                          const { slug } = route.node.data;
                          return (
                            <NavLink
                              key={route.node.id}
                              onClick={toggleMenu}
                              to={`/promo/${slug}/`}
                              className={s.link}
                              children={title}
                            />
                          );
                        })}
                      </SubMenu>
                    )
                  );
                }}
              </Query> */}
              <SubMenu
                className={s.user}
                asLink={!email}
                title={
                  <div className={s.userTitle}>
                    {avatar ? (
                      <div
                        className={s.avatar}
                        style={{ backgroundImage: `url(${avatar})` }}
                      />
                    ) : (
                      <div className={s.avatar}>{firstName.slice(0, 2)}</div>
                    )}
                    <div className={s.userInfo}>
                      <div className={s.name}>{`${firstName} ${lastName}`}</div>
                      <div className={s.phone}>{phone}</div>
                    </div>
                  </div>
                }
              >
                {email ? (
                  <>
                    {/* <NavLink
                      to="/profile/customer-program/"
                      className={s.link}
                      onClick={toggleMenu}
                    >
                      {t('customer program')}
                    </NavLink>
                    <NavLink
                      to="/profile/personal-info/"
                      className={s.link}
                      onClick={toggleMenu}
                    >
                      {t('personal info')}
                    </NavLink>
                    <NavLink
                      to="/profile/trips/"
                      className={s.link}
                      onClick={toggleMenu}
                    >
                      {t('trips')}
                    </NavLink>
                    <NavLink
                      to="/profile/payment-methods/"
                      className={s.link}
                      onClick={toggleMenu}
                    >
                      {t('payment methods')}
                    </NavLink>
                    <NavLink
                      to="/profile/security/"
                      className={s.link}
                      onClick={toggleMenu}
                    >
                      {t('security')}
                    </NavLink>
                    <NavLink
                      to="/profile/settings/"
                      className={s.link}
                      onClick={toggleMenu}
                    >
                      {t('settings')}
                    </NavLink> */}
                    <div
                      className={s.link}
                      children={t('logout')}
                      onClick={() => {
                        logout();
                        toggleMenu();
                      }}
                    />
                  </>
                ) : null
                // <div
                //   onClick={() => {
                //     toggleMenu();
                //     toggleLoginModal();
                //   }}
                //   className={s.link}
                //   children={t('login')}
                // />
                }
              </SubMenu>
              <SubMenu asLink>
                <NavLink
                  to={travelInfoLink}
                  className={cx(s.link, s.important)}
                  onClick={toggleMenu}
                  children={t('travel info')}
                />
              </SubMenu>

              {/* <div className={s.item}>
                <Link to={travelInfoLink} className={cx(s.link, s.important)}>
                  {t('travel info')}
                </Link>
              </div> */}
              <SubMenu title={t('routes')}>
                <Query
                  query={ORDER_WIDGET}
                  variables={{
                    lang: language,
                    origin: 'popular-routes',
                  }}
                >
                  {({ data = {}, loading }) => {
                    if (loading) return <Loading />;
                    const widget = data.orderWidget;
                    const routes = widget?.routes || [];
                    return (
                      Boolean(routes?.length) &&
                      routes.map((route, key) => {
                        const departure = route.departure?.name;
                        const arrival = route.arrival?.name;
                        const { slug } = route.data;
                        return (
                          <NavLink
                            key={key}
                            onClick={toggleMenu}
                            to={`/routes/${slug}/`}
                            className={s.link}
                            children={`${t(departure)} - ${t(arrival)}`}
                          />
                        );
                      })
                    );
                  }}
                </Query>
                <NavLink
                  onClick={toggleMenu}
                  to="/all-routes/"
                  className={s.link}
                  children={t('all routes')}
                />
              </SubMenu>
              <SubMenu title={t('booking')}>
                <NavLink
                  onClick={toggleMenu}
                  to="/manage-booking/"
                  className={s.link}
                  children={t('manage booking')}
                />
              </SubMenu>
              {/* <SubMenu title={t('destinations')}>
                <Query
                  query={ORDER_WIDGET}
                  variables={{
                    lang: language,
                    origin: 'popular-destinations',
                  }}
                >
                  {({ data = {}, loading }) => {
                    if (loading) return <Loading />;
                    const widget = data.orderWidget;
                    const destinations = widget?.destinations;
                    return (
                      Boolean(destinations?.length) &&
                      destinations.map((destination, key) => {
                        const city = destination.city?.name;
                        const { slug } = destination.data;
                        return (
                          <NavLink
                            key={key}
                            onClick={toggleMenu}
                            to={`/destinations/${slug}/`}
                            className={s.link}
                            children={t(city)}
                          />
                        );
                      })
                    );
                  }}
                </Query>
                <NavLink
                  onClick={toggleMenu}
                  to="/all-destinations/"
                  className={s.link}
                  children={t('all destinations')}
                />
              </SubMenu> */}
              <SubMenu title={t('for passengers')}>
                <NavLink
                  onClick={toggleMenu}
                  to="/seats/"
                  className={s.link}
                  children={t('seating options')}
                />
                {/* <NavLink
                  onClick={toggleMenu}
                  to="/info/"
                  className={s.link}
                  children={t('pins program')}
                /> */}
                <NavLink
                  onClick={toggleMenu}
                  to="/discounts/"
                  className={s.link}
                  children={t('discounts')}
                />
                <NavLink
                  onClick={toggleMenu}
                  to="/ticket-sales-rules/"
                  className={s.link}
                  children={t('ticket sales rules')}
                />
                <NavLink
                  onClick={toggleMenu}
                  to="/transportation-rules/"
                  className={s.link}
                  children={t('transportation rules')}
                />
                <NavLink
                  onClick={toggleMenu}
                  to="/manage-booking/"
                  className={s.link}
                  children={t('manage booking')}
                />
                {language === 'ru' && (
                  <NavLink
                    onClick={toggleMenu}
                    to="/news/programma-loyalnosti-lux-express/"
                    className={s.link}
                    children={t('frequent traveler')}
                  />
                )}
                {language === 'en' && (
                  <NavLink
                    onClick={toggleMenu}
                    to="/news/lux-express-customer-program/"
                    className={s.link}
                    children={t('frequent traveler')}
                  />
                )}
                {language === 'et' && (
                  <NavLink
                    onClick={toggleMenu}
                    to="/news/lux-expressi-kliendiprogramm/"
                    className={s.link}
                    children={t('frequent traveler')}
                  />
                )}
              </SubMenu>
              <SubMenu title={t('about us')}>
                <NavLink
                  onClick={toggleMenu}
                  to="/blog/"
                  className={s.link}
                  children={t('blog')}
                />
                <NavLink
                  onClick={toggleMenu}
                  to="/about-us/"
                  className={s.link}
                  children={t('about lux express')}
                />
                <NavLink
                  onClick={toggleMenu}
                  to="/all-destinations/"
                  className={s.link}
                  children={t('all destinations')}
                />
                {/* <NavLink
                  onClick={toggleMenu}
                  to="/career/"
                  className={s.link}
                  children={t('careers')}
                /> */}
              </SubMenu>
              <SubMenu title={t('info')}>
                <NavLink
                  onClick={toggleMenu}
                  to="/contacts/"
                  className={s.link}
                  children={t('contacts')}
                />
                {showFaq && (
                  <NavLink
                    onClick={toggleMenu}
                    to="/faq/"
                    className={s.link}
                    children={t('guides and faq')}
                  />
                )}

                {/* <NavLink
                  onClick={toggleMenu}
                  to="/customs-regulations/"
                  className={s.link}
                  children={t('customs regulations')}
                  />
                  <NavLink
                  onClick={toggleMenu}
                  to="/lost-item/"
                  className={s.link}
                  children={t('report a lost item')}
                /> */}
                {/* <NavLink
                  onClick={toggleMenu}
                  to="/ticket-refund/"
                  className={s.link}
                  children={t('return tickets')}
                /> */}
              </SubMenu>

              {/* <SubMenu title="Services">
                <div
                onClick={toggleMenu}
                className={s.link}
                children={t('corporate client program')}
                disabled={true}
                />
                <div
                onClick={toggleMenu}
                className={s.link}
                children={t('taxi')}
                disabled={true}
                />
              </SubMenu> */}
              {/* <SubMenu title={t('info')}>
                <NavLink
                onClick={toggleMenu}
                to="/faq/"
                className={s.link}
                children={t('faq')}
                />
                <NavLink
                onClick={toggleMenu}
                to="/lost-item/"
                className={s.link}
                children={t('reporting a lost item')}
                />
                <NavLink
                onClick={toggleMenu}
                to="/career/"
                className={s.link}
                children={t('career')}
                />
                <NavLink
                onClick={toggleMenu}
                to="/info/"
                className={s.link}
                children={t('pins program')}
                />
                <div
                onClick={toggleMenu}
                className={s.link}
                children={t('discounts')}
                disabled={true}
                />
              </SubMenu> */}
              {/* <Button
                to="/"
                className={s.returnTicket}
                onClick={toggleMenu}
                children={t('find tickets')}
              /> */}
            </>
          ) : (
            <>
              <SubMenu
                className={s.user}
                asLink={!email}
                title={
                  <div className={s.userTitle}>
                    {avatar ? (
                      <div
                        className={s.avatar}
                        style={{ backgroundImage: `url(${avatar})` }}
                      />
                    ) : (
                      <div className={s.avatar}>{firstName.slice(0, 2)}</div>
                    )}
                    <div className={s.userInfo}>
                      <div className={s.name}>{`${firstName} ${lastName}`}</div>
                      <div className={s.phone}>{phone}</div>
                    </div>
                  </div>
                }
              >
                {email ? (
                  <>
                    {/* <Link
                  to="/profile/personal-info/"
                  onClick={toggleMenu}
                  className={s.link}
                >
                  {t('account')}
                </Link> */}
                    {/* <NavLink
                      to="/profile/customer-program/"
                      className={s.link}
                      onClick={toggleMenu}
                    >
                      {t('customer program')}
                    </NavLink>
                    <NavLink
                      to="/profile/personal-info/"
                      className={s.link}
                      onClick={toggleMenu}
                    >
                      {t('personal info')}
                    </NavLink>
                    <NavLink
                      to="/profile/trips/"
                      className={s.link}
                      onClick={toggleMenu}
                    >
                      {t('trips')}
                    </NavLink>
                    <NavLink
                      to="/profile/payment-methods/"
                      className={s.link}
                      onClick={toggleMenu}
                    >
                      {t('payment methods')}
                    </NavLink>
                    <NavLink
                      to="/profile/security/"
                      className={s.link}
                      onClick={toggleMenu}
                    >
                      {t('security')}
                    </NavLink> */}
                    <div
                      className={s.link}
                      children={t('logout')}
                      onClick={() => {
                        logout();
                        toggleMenu();
                      }}
                    />
                  </>
                ) : null
                // <div
                //   onClick={() => {
                //     toggleMenu();
                //     toggleLoginModal();
                //   }}
                //   className={s.link}
                //   children={t('login')}
                // />
                }
              </SubMenu>
            </>
          )}
          {isMob && (
            <>
              <SubMenu title={t('language')} selected={language}>
                {langs
                  .filter(lang => lang !== language)
                  .map(lang => (
                    <div
                      key={lang}
                      className={s.link}
                      onClick={() => {
                        onChangeLang(lang);
                        toggleMenu();
                      }}
                      children={lang}
                    />
                  ))}
              </SubMenu>
              {/* <SubMenu title={t('currency')} selected={currencyName} /> */}
            </>
          )}
        </div>
        <Divider absolute />
      </div>

      <div className={s.section} onClick={toggleMenu}>
        <i
          className={cx(s.icon, isOpen ? i.close : i.menu, isOpen && s.close)}
        />
      </div>

      {!isTicketPages && (
        <>
          <div className={cx(s.section, s.subMenu)}>
            <div className={s.item}>
              {language}
              <div className={s.wrapList}>
                <div className={s.list}>
                  <div className={s.wrap}>
                    <div className={s.triangle} />
                  </div>
                  {langs
                    .filter(lang => lang !== language)
                    .map(lang => (
                      <div
                        key={lang}
                        className={s.link}
                        onClick={() => {
                          onChangeLang(lang);
                          if (isMob) {
                            toggleMenu();
                          }
                        }}
                        children={lang}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>

          {/* <div className={cx(s.section, s.subMenu)}>
            <div className={s.item}>{currencyName}</div>
          </div> */}
        </>
      )}
    </div>
  );
};

export default withTranslation()(MobileHeader);
