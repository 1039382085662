import gql from 'graphql-tag';

export const CREATE_STATIC_PAGE = gql`
  mutation($input: StaticPageInput) {
    createStaticPage(input: $input) {
      staticPage {
        id
        isPublished
        slug
        country
        en {
          seo
          lang
          countries
          pageData
          isPublished
        }
        et {
          seo
          lang
          countries
          pageData
          isPublished
        }
        ru {
          seo
          lang
          countries
          pageData
          isPublished
        }
        fi {
          seo
          lang
          countries
          pageData
          isPublished
        }
        lv {
          seo
          lang
          countries
          pageData
          isPublished
        }
        lt {
          seo
          lang
          countries
          pageData
          isPublished
        }
        pl {
          seo
          lang
          countries
          pageData
          isPublished
        }
      }
    }
  }
`;

export const UPDATE_STATIC_PAGE = gql`
  mutation($input: StaticPageInput) {
    updateStaticPage(input: $input) {
      staticPage {
        id
        isPublished
        slug
        country
        en {
          seo
          lang
          countries
          pageData
          isPublished
        }
        et {
          seo
          lang
          countries
          pageData
          isPublished
        }
        ru {
          seo
          lang
          countries
          pageData
          isPublished
        }
        fi {
          seo
          lang
          countries
          pageData
          isPublished
        }
        lv {
          seo
          lang
          countries
          pageData
          isPublished
        }
        lt {
          seo
          lang
          countries
          pageData
          isPublished
        }
        pl {
          seo
          lang
          countries
          pageData
          isPublished
        }
      }
    }
  }
`;

export const REMOVE_STAIC_PAGE = gql`
  mutation($id: ID!) {
    deleteStaticPage(id: $id) {
      success
    }
  }
`;
