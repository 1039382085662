import gql from 'graphql-tag';

export const BASKET_PRODUCT_GROUPS = gql`
  query($lang: String) {
    basketProductGroups(lang: $lang) {
      ShoppingBasketId
      Basket {
        ShoppingBasketId
        ShoppingBasketNumber
        ValidUntil
        ValidUntilInUtc
        CanRenew
      }
      TravelZone
      HasTransfers
      Currency
      TotalPrice
      OutboundJourneys {
        OriginStopName
        DestinationStopName
        TotalPrice
        Products {
          ProductId
          ProductCode
          PassengerName
          RequestedFareClass
          AssignedFareClass
          TravelInsurance {
            Price
            VatRate
            Currency
            Name
          }
          ModificationTravelInsurance {
            Price
            VatRate
            Name
          }
          CancellationTravelInsurance {
            Price
            VatRate
            Name
          }
          TotalPrice
          SalesFee {
            Price
            VatRate
          }
          AddOnDetails {
            ServiceName
            Id
            Quantity
            UnitPrice
            FinalPrice
            JourneyLegOrderNumber
          }
        }
      }
      InboundJourneys {
        OriginStopName
        DestinationStopName
        TotalPrice
        Products {
          ProductId
          ProductCode
          PassengerName
          RequestedFareClass
          AssignedFareClass
          TravelInsurance {
            Price
            VatRate
            Currency
            Name
          }
          ModificationTravelInsurance {
            Price
            VatRate
            Name
          }
          CancellationTravelInsurance {
            Price
            VatRate
            Name
          }
          TotalPrice
          SalesFee {
            Price
            VatRate
          }
          AddOnDetails {
            ServiceName
            Id
            Quantity
            UnitPrice
            FinalPrice
            JourneyLegOrderNumber
          }
        }
      }
      BasketLevelAddons {
        ServiceName
        Id
        Quantity
        UnitPrice
        FinalPrice
        IsSMSService
      }
      TravelPass {
        Name
        Description
        UnitPrice
        Price
        VatRate
      }
      ParkingPasses {
        ProductId
        ProductCode
        ParkingLotName
        StartDate
        EndDate
        ParkingLotTimeZone
        Price
        VatRate
        PassengerProductId
      }
      Transfers {
        ProductId
        TransferName
        TransferStopName
        TransferDepartureTime
        TransferDepartureTimeZone
        Price
        VatRate
      }
      PaymentMethodFees {
        PaymentMethodId
        Price
        VatRate
      }
      BasketSalesFee {
        Price
        VatRate
      }
    }
  }
`;

export const JOURNEYS_HISTORY = gql`
  query($limit: Int, $displayPassed: Boolean, $lang: String) {
    travelAccountJourneys(
      limit: $limit
      displayPassed: $displayPassed
      lang: $lang
    ) {
      IsOutbound
      DepartureTime
      DepartureTimeZone
      ArrivalTime
      ArrivalTimeZone
      DepartureStopName
      DepartureStopId
      DepartureCityName
      DepartureCityId
      DestinationStopName
      DestinationStopId
      DestinationCityName
      DestinationCityId
      ShoppingBasketCode
      ShoppingBasketId
      ActiveModificationSessionId
      ShoppingBasketExportCode
      IsCorporateClientContractPurchase
      BasketAddons {
        AddonName
        Description
        Price
        Currency
        Quantity
      }
      Legs {
        OrderNumber
        DepartureTime
        DepartureTimeZone
        ArrivalTime
        ArrivalTimeZone
        PlannedDepartureTime
        PlannedDepartureTimeZone
        PlannedArrivalTime
        PlannedArrivalTimeZone
        DepartureStopName
        DepartureCityName
        DeparturePlatforms
        DestinationStopName
        DestinationCityName
        BrandName
        ServiceCode
        LineNumber
        TripName
        SubcontractorName
      }
      Passengers {
        ProductCode
        ExternalTicketNumber
        IsPartOfRoundTrip
        PassengerName
        PassengerCategory
        IsFreeModificationAllowed
        IsModificationAllowed
        Tickets {
          CouponNumber
          LegOrderNumber
          Price
          Currency
          QrCode
        }
        Seats {
          BusNameTag
          LegOrderNumber
          SeatOrderNumber
          SeatClass
          SeatNumber
          SeatChangeStop
          SeatFloorNumber
          HasSeatBeenReleased
          Price
          Currency
        }
      }
    }
  }
`;

export const TICKETS_FILE_URL = gql`
  query($basketId: String!, $lang: String) {
    ticketsFileUrl(basketId: $basketId, lang: $lang)
  }
`;

export const BASKET = gql`
  query BasketType {
    basket {
      ShoppingBasketId
      ShoppingBasketNumber
      ValidUntil
      ValidUntilInUtc
    }
  }
`;

export const DOCUMENT_TYPES = gql`
  query($lang: String) {
    documentTypes(lang: $lang) {
      DocumentType
      DocumentName
    }
  }
`;

export const CITIZENSHIPS = gql`
  query($lang: String) {
    citizenships(lang: $lang) {
      Id
      Name
    }
  }
`;

export const ADYEN_PAYMWNTS_METHODS = gql`
  query($countryCode: String) {
    adyenPaymentMethods(channel: "Web", countryCode: $countryCode) {
      paymentMethods {
        name
        type
        details {
          key
          type
        }
      }
      groups {
        name
        types
      }
    }
  }
`;

export const FARE_CLASSES = gql`
  query($lang: String) {
    fareClasses(lang: $lang) {
      Id
      Name
      Comment
    }
  }
`;

export const PASSENGERS_TYPES = gql`
  query($lang: String) {
    passengerTypes(lang: $lang) {
      PassengerType
      FareClasses {
        FareClass
        FareClassOwner
      }
    }
  }
`;

export const SEAT_PLAN = gql`
  query($products: [String]!, $currency: String) {
    seatPlan(products: $products, currency: $currency) {
      Legs {
        IsOutbound
        OrderNumber
        Buses {
          TripBusProfileId
          Name
          AvailableSeatCount
          CanChooseSeatNumber
          RegistrationNumber
          SteeringWheelLocation
          Floors {
            FloorNumber
            SeatRows {
              RowNumber
              Seats {
                ColumnNumber
                SeatNumber
                SeatNumberLetter
                SeatClassCategory
                SeatClassCategoryName
                SeatClass
                SeatClassName
                SeatRank
                IsWheelchairSeat
                IsPetSeat
                IsChildSeat
                IsForwardSeating
                HasElectricity
                IsToilet
                IsStairs
                IsEmptyArea
                IsAvailable
                IsSoldLater
                SeatPrice {
                  Amount
                  Currency
                }
                TicketPrice {
                  Amount
                  Currency
                }
                AdditionalSeat {
                  ChangeStopId
                  SeatNumber
                  SeatNumberLetter
                }
                ProductGuid
              }
            }
          }
        }
      }
    }
  }
`;

export const NEO_PAY_BANKS = gql`
  query NeoPayBanksType {
    neoPayBanks {
      name
      code
      defaultLanguage
      languages
      aspsps {
        code
        bic
        isOperating
        logo
        name
        bankLink
      }
    }
  }
`;

export const BASKET_STATE = gql`
  query($basketId: String!) {
    basketState(basketId: $basketId) {
      State
      IsRelatedToTravelPass
      error {
        ErrorCode
        ErrorReason
      }
      objects {
        IsOutbound
        DepartureTime
        DepartureTimeZone
        ArrivalTime
        ArrivalTimeZone
        DepartureStopName
        DepartureStopId
        DepartureCityName
        DepartureCityId
        DestinationStopName
        DestinationStopId
        DestinationCityName
        DestinationCityId
        ShoppingBasketCode
        ShoppingBasketId
        ActiveModificationSessionId
        ShoppingBasketExportCode
        IsCorporateClientContractPurchase
        Legs {
          OrderNumber
          DepartureTime
          DepartureTimeZone
          ArrivalTime
          ArrivalTimeZone
          PlannedDepartureTime
          PlannedDepartureTimeZone
          PlannedArrivalTime
          PlannedArrivalTimeZone
          DepartureStopName
          DepartureCityName
          DeparturePlatforms
          DestinationStopName
          DestinationCityName
          BrandName
          ServiceCode
          LineNumber
          TripName
          SubcontractorName
        }
        Passengers {
          ProductCode
          ExternalTicketNumber
          IsPartOfRoundTrip
          PassengerName
          PassengerCategory
          Tickets {
            CouponNumber
            LegOrderNumber
            Price
            Currency
            QrCode
          }
          Seats {
            BusNameTag
            LegOrderNumber
            SeatOrderNumber
            SeatClass
            SeatNumber
            SeatChangeStop
            SeatFloorNumber
            HasSeatBeenReleased
            Price
            Currency
          }
        }
      }
    }
  }
`;

export const VERIFY_VOUCHER_BASKET = gql`
  query($VoucherCode: String!) {
    verifyVoucherBasket(VoucherCode: $VoucherCode) {
      VoucherRemainingValue
      error {
        ErrorCode
        ErrorReason
      }
    }
  }
`;

export const TRAVEL_ACCOUNT_VOUCHERS = gql`
  query($lang: String) {
    travelAccountVouchers(lang: $lang) {
      Voucher {
        Type
        InitialAmount
        Amount
        Price
        ValidUntil
        Code
        Pin
        CreatedOn
      }
      Currency
      error {
        ErrorCode
        ErrorReason
      }
    }
  }
`;

export const YOO_MONEY_PAYMENT = gql`
  query($RedirectUrl: String!) {
    yooMoneyPayment(RedirectUrl: $RedirectUrl) {
      success
      error {
        ErrorCode
        ErrorReason
      }
      confirmationUrl
    }
  }
`;
