import React, { useState, useContext } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { StoreContext } from '@lux/spa/store';
import { Container } from '@lux/ui';
import { NotFound } from '@lux/spa/routes';
import {
  ProfileMenu,
  Email,
  ChoosePassengers,
  ChooseTrip,
  Summary,
  Status,
} from '@lux/spa/components';
import s from './refund.module.scss';

const Refund = ({ t, className }) => {
  const initilaRefundState = {
    returnInfoVoucher: null,
  };
  const [state, dispatch] = useContext(StoreContext);
  const { isPublic, ticketNumber } = state.tickets.refund;
  const [step, setStep] = useState(1);
  const [dataRefund, setDataRefund] = useState(initilaRefundState);
  const nextStep = () => setStep(prev => prev + 1);
  const prevStep = () => setStep(prev => prev - 1);
  const goToStep = step => {
    if (step == 1) setDataRefund(initilaRefundState);

    setStep(step);
  };

  return ticketNumber ? (
    <div className={cn(className, s.refund)}>
      <Container>
        <Container full={false}>
          <section>
            <h2>{t('my account')}</h2>
            <div className={s.block}>
              <ProfileMenu />
              <div className={s.content}>
                {step === 1 && (
                  <Email
                    nextStep={nextStep}
                    prevStep={prevStep}
                    goToStep={goToStep}
                    dataRefund={dataRefund}
                    setDataRefund={setDataRefund}
                  />
                )}
                {step === 2 && (
                  <ChoosePassengers
                    nextStep={nextStep}
                    prevStep={prevStep}
                    goToStep={goToStep}
                    dataRefund={dataRefund}
                    setDataRefund={setDataRefund}
                  />
                )}
                {step === 3 && (
                  <ChooseTrip
                    nextStep={nextStep}
                    prevStep={prevStep}
                    goToStep={goToStep}
                    dataRefund={dataRefund}
                    setDataRefund={setDataRefund}
                  />
                )}
                {step === 4 && (
                  <Summary
                    nextStep={nextStep}
                    prevStep={prevStep}
                    goToStep={goToStep}
                    dataRefund={dataRefund}
                    setDataRefund={setDataRefund}
                  />
                )}
                {step === 5 && (
                  <Status
                    isPublic={isPublic}
                    setStep={setStep}
                    dataRefund={dataRefund}
                  />
                )}
              </div>
            </div>
          </section>
        </Container>
      </Container>
    </div>
  ) : (
    <NotFound />
  );
};

export default withTranslation()(Refund);
