import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import i from '@lux/spa/styles/icons.module.scss';
import s from './passengers.module.scss';

const passengersInfoType = {
  child: 'Children of pre-school age (up to 7 yo, 7 incl.)',
  youth: 'Children up to 16 y. (incl.)',
  senior: 'Seniors above 60 y. (60 incl.)',
  passengers: 'Passengers',
};

const Passengers = ({
  t,
  className,
  passRef,
  passengers,
  updatePassengers,
  passengersCount,
  ...props
}) => {
  return (
    <div className={cn(s.passengers, className)} ref={passRef} {...props}>
      <div className={s.wrap}>
        <div className={s.triangle} />
      </div>
      {Object.keys(passengers).map(type => (
        <div className={s.type} key={type}>
          <div className={s.name}>
            <div className={s.text}>{t(type)}</div>
            <div className={s.info}>{t(passengersInfoType[type])}</div>
          </div>
          <div className={s.controls}>
            <div
              onClick={() => updatePassengers('-', type)}
              className={cn(
                s.button,
                s.minus,
                (passengersCount === 1 || passengers[type] === 0) && s.disabled
              )}
            >
              <i className={i.minus} />
            </div>
            <div className={s.count}>{passengers[type]}</div>
            <div
              className={s.button}
              onClick={() => updatePassengers('+', type)}
            >
              <i className={i.plus_v2} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default withTranslation()(Passengers);
