import React, { useState } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import dateFormat from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isEqual from 'date-fns/isEqual';
import isPast from 'date-fns/isPast';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import { Query } from 'react-apollo';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Traveling, meta, BuyTicketPromo } from '@lux/spa/components';
import {
  Container,
  Divider,
  Link,
  Button,
  Tags,
  Tag,
  Text,
  DatePicker,
  Countdown,
  UploadImages,
  CampaignRules,
  EditSlider,
} from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import { Route, emptyRoute } from './components';
import s from './promo.module.scss';

const PromoContent = ({
  t,
  slugs,
  type,
  region,
  isEditMode,
  isStaff,
  content,
  language,
  className,
  updatePage,
}) => {
  const [isBannerImg, setIsBannerImg] = useState(false);
  const [isRoutes, setIsRoutes] = useState(false);
  const [isOpenRules, seIsOpenRules] = useState(false);

  const toggleModal = () => {
    setIsBannerImg(!isBannerImg);
  };

  const toggleRoutes = () => {
    setIsRoutes(!isRoutes);
  };

  const toggleModalRules = () => {
    seIsOpenRules(!isOpenRules);
  };

  const { pageData = {}, seo = {}, countries } = content;

  const {
    title,
    subTitle,
    banner,
    heading1,
    // heading2,
    text1,
    // text2,
    campaignCode,
    salesPeriodFrom,
    salesPeriodTo,
    travelPeriodFrom,
    travelPeriodTo,
    discount,
    routes,
    campaignRules,
  } = pageData;

  const backgroundImage = banner.value
    ? `linear-gradient(90deg, #000000d9, transparent), url(${banner.value})`
    : `linear-gradient(90deg, #000000d9, transparent)`;

  const doNotRenderCountdown =
    isAfter(new Date(salesPeriodFrom), new Date(salesPeriodTo)) ||
    isPast(endOfDay(new Date(salesPeriodTo)));

  return (
    <div className={cn(className, s.promo)}>
      <Helmet {...meta({ seo, lang: language, countries, slugs, type })} />
      {isBannerImg && (
        <UploadImages
          isOpen={isBannerImg}
          toggleModal={toggleModal}
          updatePage={updatePage}
          image={banner}
        />
      )}
      {isOpenRules && (
        <CampaignRules
          toggleModal={toggleModalRules}
          isOpen={isOpenRules}
          campaignRules={campaignRules}
          updatePage={updatePage}
        />
      )}
      {isRoutes && (
        <EditSlider
          contentClassName={s.editSliderRoutes}
          showField="title"
          emptySlide={emptyRoute}
          slider={routes}
          isOpen={isRoutes}
          updatePage={updatePage}
          toggleModal={toggleRoutes}
        >
          <Route />
        </EditSlider>
      )}
      <Container
        className={s.bannerContainer}
        style={{
          backgroundImage,
        }}
      >
        <Container full={false}>
          <section className={s.banner}>
            {isEditMode && (
              <Button
                className={s.uploadBanner}
                text={banner.value ? t('edit photo') : t('upload photo')}
                onClick={toggleModal}
              />
            )}
            <h1>
              <Text onChange={updatePage} field={title} />
            </h1>
            <h1 data-v2>
              <Text onChange={updatePage} field={subTitle} />
            </h1>
          </section>
        </Container>
      </Container>
      <Divider />
      <Container className={s.information}>
        <Container full={false}>
          <section>
            <div className={s.texts}>
              <h5>
                <Text onChange={updatePage} field={heading1} />
              </h5>
              <p className={s.description}>
                <Text onChange={updatePage} field={text1} />
              </p>
              {!doNotRenderCountdown &&
                (isAfter(new Date(), new Date(salesPeriodFrom)) ||
                isEqual(new Date(), new Date(salesPeriodFrom)) ? (
                  <>
                    <h5>{t('campaign ends in')}</h5>
                    <Countdown dateTo={endOfDay(new Date(salesPeriodTo))} />
                  </>
                ) : (
                  <>
                    <h5>{t('campaign starts in')}</h5>
                    <Countdown dateTo={startOfDay(new Date(salesPeriodFrom))} />
                  </>
                ))}
            </div>
            <div className={s.info}>
              <div className={s.data}>
                <div className={s.item}>
                  <div className={s.icon}>
                    <i className={i.calendar} />
                  </div>
                  <div className={s.text}>
                    <div className={s.key}>{t('sales period')}</div>
                    <div className={s.value}>
                      {isEditMode ? (
                        <>
                          <DatePicker
                            clean
                            value={new Date(salesPeriodFrom)}
                            onDayChange={date => {
                              updatePage({
                                salesPeriodFrom: date,
                              });
                            }}
                            disabledDays={[
                              {
                                before: new Date(),
                              },
                            ]}
                            selectedDays={{
                              from: new Date(salesPeriodFrom),
                              to: new Date(salesPeriodTo),
                            }}
                          />
                          &nbsp;-&nbsp;
                          <DatePicker
                            clean
                            value={new Date(salesPeriodTo)}
                            onDayChange={date => {
                              updatePage({
                                salesPeriodTo: date,
                              });
                            }}
                            disabledDays={[
                              {
                                before: new Date(),
                              },
                            ]}
                            selectedDays={{
                              from: new Date(salesPeriodFrom),
                              to: new Date(salesPeriodTo),
                            }}
                          />
                        </>
                      ) : (
                        <>
                          {dateFormat(new Date(salesPeriodFrom), 'dd.MM.yyyy')}
                          &nbsp;—&nbsp;
                          {dateFormat(new Date(salesPeriodTo), 'dd.MM.yyyy')}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className={s.item}>
                  <div className={s.icon}>
                    <i className={i.tag} />
                  </div>
                  <div className={s.text}>
                    <div className={s.key}>{t('campaign code')}</div>
                    <div className={s.value}>
                      <Text onChange={updatePage} field={campaignCode} />
                    </div>
                  </div>
                </div>
                <div className={s.item}>
                  <div className={s.icon}>
                    <i className={i.calendar} />
                  </div>
                  <div className={s.text}>
                    <div className={s.key}>{t('travel period')}</div>
                    <div className={s.value}>
                      {isEditMode ? (
                        <>
                          <DatePicker
                            clean
                            value={new Date(travelPeriodFrom)}
                            onDayChange={date => {
                              updatePage({
                                travelPeriodFrom: date,
                              });
                            }}
                            disabledDays={[
                              {
                                before: new Date(),
                              },
                            ]}
                            selectedDays={{
                              from: new Date(travelPeriodFrom),
                              to: new Date(travelPeriodTo),
                            }}
                          />
                          &nbsp;-&nbsp;
                          <DatePicker
                            clean
                            value={new Date(travelPeriodTo)}
                            onDayChange={date => {
                              updatePage({
                                travelPeriodTo: date,
                              });
                            }}
                            disabledDays={[
                              {
                                before: new Date(),
                              },
                            ]}
                            selectedDays={{
                              from: new Date(travelPeriodFrom),
                              to: new Date(travelPeriodTo),
                            }}
                          />
                        </>
                      ) : (
                        <>
                          {dateFormat(new Date(travelPeriodFrom), 'dd.MM.yyyy')}
                          &nbsp;—&nbsp;
                          {dateFormat(new Date(travelPeriodTo), 'dd.MM.yyyy')}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className={s.item}>
                  <div className={s.icon}>
                    <i className={i.badgePercent} />
                  </div>
                  <div className={s.text}>
                    <div className={s.key}>{t('discount')}</div>
                    <div className={s.value}>
                      <Text onChange={updatePage} field={discount} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={s.routes}>
                <h5>{t('routes')}</h5>
                {routes.slides.length ? (
                  <Tags>
                    {routes.slides.map((route, key) => {
                      const cities = route.title.value
                        .split(' - ')
                        .map(city => {
                          return t(city);
                        })
                        .join(' - ');
                      return (
                        <Tag
                          text={cities}
                          to={`/routes/${route.slug.value}/`}
                          key={key}
                        />
                      );
                    })}
                    {isEditMode && (
                      <Tag
                        className={s.addNew}
                        text={`+ ${t('add')}`}
                        onClick={toggleRoutes}
                      />
                    )}
                  </Tags>
                ) : (
                  <div className={s.noRoutes}>
                    <div className={s.text}>{t('no routes')}</div>
                    {isEditMode && (
                      <Tags>
                        <Tag
                          className={s.addNew}
                          text={`+ ${t('add')}`}
                          onClick={toggleRoutes}
                        />
                      </Tags>
                    )}
                  </div>
                )}
              </div>
              <div className={s.controls}>
                <Button className={s.button} to="/" text={t('buy now')} />
                <div className={s.campaingRules} onClick={toggleModalRules}>
                  <span>{t('campaign rules')}</span>
                  <i className={i.arrowRight_v2} />
                </div>
              </div>
            </div>
          </section>
        </Container>
      </Container>
      <Traveling />
      <BuyTicketPromo className={s.services} />
      {/* <Container className={s.services}>
        <Container full={false}>
          <section>
        <div className={s.map}>
        <LazyLoadImage src="/images/promo/map.png" alt="" />
        </div>
        <h2 className={s.title}>{t("countries.title")}</h2>
        <p>{t("countries.description")}</p>
        <Button text={t("buy tickets")} to="/" />
          </section>
        </Container>
      </Container> */}
    </div>
  );
};

export default withTranslation()(PromoContent);
