import React, { useEffect, useState, useReducer, useRef } from 'react';
import cn from 'classnames';
import { Query } from 'react-apollo';
import { useMutation } from '@apollo/react-hooks';
import { withTranslation } from 'react-i18next';
import {
  GET_ALL_DESTINATIONS_ADMIN,
  ORDER_WIDGET,
} from '@lux/spa/apollo/queries';
import { UPDATE_ORDER_WIDGET } from '@lux/spa/apollo/mutations';
import { Button, Input, Loading, Textarea } from '@lux/ui';
import s from './popular-destination.module.scss';

const ListSuggestions = ({
  t,
  search,
  destinations,
  selectItem,
  suggestionsRef,
}) => {
  const filtered = destinations.filter(destination => {
    const title = destination.city?.name.toLowerCase();
    return title.includes(search.toLowerCase());
  });
  return (
    <div className={s.listSuggestions} ref={suggestionsRef}>
      <div className={s.wrap}>
        <div className={s.triangle} />
      </div>
      {filtered.length ? (
        filtered.map((destination, key) => {
          const { city } = destination;
          const { id } = destination;
          return (
            <div
              key={key}
              className={s.item}
              onClick={() => {
                selectItem({
                  id,
                  city,
                });
              }}
            >
              <span>{city.name}</span>
            </div>
          );
        })
      ) : (
        <span>
          {t('so matches for')} «<b>{search}</b>»
        </span>
      )}
    </div>
  );
};

const itemReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_ITEM':
      return {
        ...state,
        ...action.payload,
      };
  }
};

const PopularDestination = ({
  t,
  i18n,
  className,
  toggleEditItem,
  item = {},
  items,
  widgetDispatch,
  ...props
}) => {
  const { language } = i18n;

  const [updateWidget] = useMutation(UPDATE_ORDER_WIDGET);

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, itemDispatch] = useReducer(itemReducer, {
    ...item,
    prevId: item.id,
  });

  const { city = {}, id, isNew = true, prevId } = state;

  const type = isNew ? 'ADD_ITEM' : 'UPDATE_ITEM';

  const [isOpenSugg, setIsOpenSugg] = useState(false);
  const [inputValue, setInputValue] = useState(city.name ? city.name : '');

  const suggestionsRef = useRef(null);

  const selectItem = destination => {
    itemDispatch({
      type: 'UPDATE_ITEM',
      payload: destination,
    });
    setIsOpenSugg(false);
    setInputValue(destination.city.name);
  };

  const hideSuggestions = e => {
    if (!e.path.includes(suggestionsRef.current)) {
      setIsOpenSugg(false);
    }
  };

  const updateItem = field => {
    itemDispatch({
      type: 'UPDATE_ITEM',
      payload: {
        field,
      },
    });
  };

  const saveItem = () => {
    widgetDispatch({
      type,
      payload: isNew
        ? state
        : {
            ...state,
            prevId,
          },
    });
    toggleEditItem();
  };

  useEffect(() => {
    if (isClient && !('path' in Event.prototype)) {
      Object.defineProperty(Event.prototype, 'path', {
        get() {
          const path = [];
          let currentElem = this.target;
          while (currentElem) {
            path.push(currentElem);
            currentElem = currentElem.parentElement;
          }

          if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
            path.push(document);
          }

          if (path.indexOf(window) === -1) {
            path.push(window);
          }

          return path;
        },
      });
    }

    if (isClient) {
      if (isOpenSugg) {
        document.addEventListener('click', hideSuggestions, false);

        return () => {
          document.removeEventListener('click', hideSuggestions, false);
        };
      }
    }
  }, [isOpenSugg]);

  useEffect(() => {
    if (inputValue) {
      setIsOpenSugg(true);
    } else {
      setIsOpenSugg(false);
    }
  }, [inputValue]);

  return (
    <div className={cn(s.popularDestination, className)}>
      <Query
        query={GET_ALL_DESTINATIONS_ADMIN}
        variables={{ lang: language, country: 'ee' }}
      >
        {({ data = {}, loading }) => {
          if (loading) return <Loading />;
          const destinations = data?.adminDestinations;
          return (
            !!destinations?.length && (
              <div className={s.input}>
                <Input
                  autoFocus
                  label={t('destination')}
                  value={inputValue}
                  onChange={e => setInputValue(e.target.value)}
                />
                {isOpenSugg && (
                  <ListSuggestions
                    t={t}
                    destinations={destinations}
                    search={inputValue}
                    selectItem={selectItem}
                    suggestionsRef={suggestionsRef}
                  />
                )}
              </div>
            )
          );
        }}
      </Query>
      <div className={s.controls}>
        <Button text="Save" onClick={saveItem} />
        <Button text="Cancel" onClick={toggleEditItem} />
      </div>
    </div>
  );
};

export default withTranslation()(PopularDestination);
