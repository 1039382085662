import gql from 'graphql-tag';

export const CREATE_ROUTE = gql`
  mutation($input: RouteInput) {
    createRoute(input: $input) {
      route {
        id
        departure {
          id
          name
        }
        arrival {
          id
          name
        }
        isPublished
        en {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        fi {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        et {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        ru {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        lv {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        lt {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
      }
    }
  }
`;

export const UPDATE_ROUTE = gql`
  mutation($input: RouteInput) {
    updateRoute(input: $input) {
      route {
        id
        departure {
          id
          name
        }
        arrival {
          id
          name
        }
        isPublished
        en {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        fi {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        et {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        ru {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        lv {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
        lt {
          seo
          lang
          slug
          countries
          pageData
          isPublished
        }
      }
    }
  }
`;

export const REMOVE_ROUTE = gql`
  mutation($id: ID!) {
    deleteRoute(id: $id) {
      success
    }
  }
`;

// export const GET_DESTINATION_ADMIN = gql`
//   query($lang: String!, $slug: String!, $country: String) {
//     adminDestination(lang: $lang, slug: $slug, country: $country) {
//       id
//       publishDate
//       isPublished
//       # slugs
//       en {
//         seo
//         lang
//         slug
//         countries
//         pageData
//         isPublished
//       }
//       fi {
//         seo
//         lang
//         slug
//         countries
//         pageData
//         isPublished
//       }
//       et {
//         seo
//         lang
//         slug
//         countries
//         pageData
//         isPublished
//       }
//       ru {
//         seo
//         lang
//         slug
//         countries
//         pageData
//         isPublished
//       }
//       lv {
//         seo
//         lang
//         slug
//         countries
//         pageData
//         isPublished
//       }
//       lt {
//         seo
//         lang
//         slug
//         countries
//         pageData
//         isPublished
//       }
//     }
//   }
// `
//
// export const GET_DESTINATION = gql`
//   query($lang: String!, $slug: String!, $country: String) {
//     destination(lang: $lang, slug: $slug, country: $country) {
//       id
//       publishDate
//       isPublished
//       # slugs
//       data {
//         seo
//         lang
//         slug
//         countries
//         pageData
//         isPublished
//       }
//     }
//   }
// `
