import React, { useState, useContext, useEffect } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet-async';
import dateFormat from 'date-fns/format';
import { withTranslation } from 'react-i18next';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { Query } from 'react-apollo';
import { Search, meta, Traveling, EmptySlider } from '@lux/spa/components';
import { localizedDate } from '@lux/spa/utils';
import {
  Container,
  Button,
  Controls,
  Switch,
  Divider,
  Editor,
  Link,
  Tags,
  Tag,
  Loading,
  Text,
  UploadImages,
  TooltipInput,
  EditSlider,
  LazyLoadImage,
} from '@lux/ui';
import {
  SEARCH,
  BUS_STOPS,
  GET_ALL_ROUTES,
  GET_ALL_ROUTES_ADMIN,
  GET_WIDGET_ADMIN,
  GET_WIDGET,
} from '@lux/spa/apollo/queries';
import i from '@lux/spa/styles/icons.module.scss';
import { Stop, emptyStop, Calendar } from './components';
import s from './route.module.scss';

const RouteContent = ({
  t,
  slugs,
  type,
  region,
  isEditMode,
  isStaff,
  content,
  language,
  className,
  updatePage,
  departure = {
    id: null,
    name: '',
  },
  arrival = {
    id: null,
    name: '',
  },
  dispatch,
  state,
}) => {
  const { isMob } = useContext(WidthContext);
  const [isStops, setIsStops] = useState(false);
  const [isBannerImg, setIsBannerImg] = useState(false);
  const [isTransportationImg, setIsTransportationImg] = useState(false);
  const [isTaxiImg, setIsTaxiImg] = useState(false);
  const [date, setDate] = useState(new Date());
  const [schedulePage, setSchedulePage] = useState(1);

  const toggleStops = () => {
    setIsStops(!isStops);
  };

  const toggleModal = () => {
    setIsBannerImg(!isBannerImg);
  };

  const toggleTransportationImg = () => {
    setIsTransportationImg(!isTransportationImg);
  };

  const toggleTaxiImg = () => {
    setIsTaxiImg(!isTaxiImg);
  };

  const { pageData = {}, seo = {}, countries } = content;

  const {
    title,
    subTitle,
    banner,
    heading1,
    // heading2, exist but not used
    // heading3, exist but not used
    heading4,
    heading5,
    // text1, exist but not used
    // text2, exist but not used
    text3,
    taxiEditor,
    transferingEditor,
    transportation,
    taxi,
    dailyDepartures,
    travelTime,
    stops,
    showTaxi,
    showSeats,
    learnMoreLink,
  } = pageData;

  const backgroundImage = banner.value
    ? `url(${banner.value})`
    : `linear-gradient(0, #484d55, #484d55)`;

  return (
    <div className={cn(className, s.route, isEditMode && s.editMode)}>
      <Helmet {...meta({ seo, lang: language, countries, slugs, type })} />
      {isBannerImg && (
        <UploadImages
          isOpen={isBannerImg}
          toggleModal={toggleModal}
          updatePage={updatePage}
          image={banner}
        />
      )}
      {isTransportationImg && (
        <UploadImages
          isOpen={isTransportationImg}
          toggleModal={toggleTransportationImg}
          updatePage={updatePage}
          image={transportation}
        />
      )}
      {isTaxiImg && (
        <UploadImages
          isOpen={isTaxiImg}
          toggleModal={toggleTaxiImg}
          updatePage={updatePage}
          image={taxi}
        />
      )}
      {isStops && (
        <EditSlider
          contentClassName={s.editSliderModalContent}
          showField="stopName"
          emptySlide={emptyStop}
          slider={stops}
          isOpen={isStops}
          updatePage={updatePage}
          toggleModal={toggleStops}
        >
          <Stop />
        </EditSlider>
      )}
      <Container className={s.bannerContainer} style={{ backgroundImage }}>
        <Container full={false}>
          <section className={s.banner}>
            <div className={s.info}>
              {isEditMode && (
                <Button
                  className={s.uploadBanner}
                  text={banner.value ? t('edit photo') : t('upload photo')}
                  onClick={toggleModal}
                />
              )}
              <h1>
                <Text onChange={updatePage} field={title} />
              </h1>
              <div className={s.details}>
                <p>
                  <Text onChange={updatePage} field={subTitle} />
                </p>
                <div className={s.dateTime}>
                  <div className={s.key}>
                    <Text onChange={updatePage} field={dailyDepartures} />
                  </div>
                  <div>{t('daily departures')}</div>
                </div>
                <div className={s.dateTime}>
                  <div className={s.key}>
                    <Text onChange={updatePage} field={travelTime} />
                  </div>
                  <div>{t('travel time')}</div>
                </div>
              </div>
            </div>
            <Search />
          </section>
        </Container>
      </Container>
      <Container>
        <Container full={false}>
          <section>
            <h2 className={s.title}>
              <Text onChange={updatePage} field={heading1} />
            </h2>
          </section>
        </Container>
      </Container>
      <Container className={s.mainStops}>
        {/* <Container full={false}> */}
        <section>
          <div className={cn(s.shadow, s.left)} />
          <div className={cn(s.shadow, s.right)} />
          {stops.slides.length ? (
            <div className={s.stops}>
              {isEditMode && (
                <Button
                  className={s.editSlider}
                  text={t('edit stops')}
                  onClick={toggleStops}
                />
              )}
              <Query
                query={BUS_STOPS}
                variables={{
                  ids: stops.slides.map(s => s.stopId.value),
                  lang: language,
                }}
                fetchPolicy="network-only"
                ssr={false}
                onCompleted={data => {
                  if (
                    data?.busStops?.[0] &&
                    data?.busStops?.[data?.busStops?.length - 1]
                  ) {
                    const { from = {}, to = {} } = state?.search;
                    const fromToData = {
                      from: {
                        ...from,
                        CountryName: data.busStops[0].CountryName,
                        CityName: data.busStops[0].Address.CityName,
                        search: data.busStops[0].BusStopName,
                      },
                      to: {
                        ...to,
                        CountryName:
                          data.busStops[data?.busStops?.length - 1].CountryName,
                        CityName:
                          data.busStops[data?.busStops?.length - 1].Address
                            .CityName,
                        search:
                          data.busStops[data?.busStops?.length - 1].BusStopName,
                      },
                    };
                    dispatch({
                      type: 'UPDATE_SEARCH',
                      payload: {
                        ...fromToData,
                      },
                    });
                  }
                }}
              >
                {({ data = {}, loading }) => {
                  if (loading) return <Loading />;
                  return data?.busStops?.map(
                    (
                      {
                        BusStopId,
                        Address = {
                          Street: '',
                          ZipCode: '',
                          CityName: '',
                        },
                        Coordinates = {
                          Latitude: '',
                          Longitude: '',
                        },
                        BusStopName,
                        HasPriority = false,
                      },
                      key
                    ) => {
                      const {
                        Street = '',
                        ZipCode = '',
                        CityName = '',
                      } = Address;
                      const { Latitude = '', Longitude = '' } = Coordinates;
                      return (
                        <div
                          key={BusStopId}
                          className={cn(
                            s.stop,
                            (key === 0 || key + 1 === stops.slides.length) &&
                              s.firstLast
                          )}
                        >
                          <div className={s.line}>
                            <div className={s.dot} />
                            {key + 1 !== stops.slides.length && (
                              <div className={s.l} />
                            )}
                          </div>
                          <h5>{BusStopName}</h5>
                          <Link
                            to={`https://www.google.com/maps/search/?api=1&query=${Latitude},${Longitude}`}
                            className={s.onMap}
                            children={t('open in google maps')}
                          />
                          {!!Street && (
                            <div className={s.address}>
                              {Street}
                              {!!ZipCode && `, ${ZipCode}`}
                              {!!CityName && `, ${CityName}`}
                            </div>
                          )}
                          {/* {!HasPriority && (
                            <div className={s.dropOff}>
                              {t('drop off-on request')}
                            </div>
                          )} */}
                        </div>
                      );
                    }
                  );
                }}
              </Query>
            </div>
          ) : isEditMode ? (
            <EmptySlider
              toggleEditSlider={toggleStops}
              text={t('Main stops')}
            />
          ) : null}
        </section>
        {/* </Container> */}
      </Container>
      <Container className={s.calendar}>
        <Container full={false}>
          <section>
            {!isProd && !isStage && (
              <div className={s.mainInfo}>
                <Calendar
                  className={s.calendar}
                  setDate={setDate}
                  date={date}
                />

                {/* https://ticket.luxexpress.eu/booking/time?departureDate=2020-05-01&returnDate=&currency=EUR&lang=et&promo=&originBusStopId=17028&destinationBusStopId=17058&passengers=1 */}

                {/* https://ticket.luxexpress.eu/booking/time?
                  departureDate=2020-05-01&
                  returnDate=&
                  currency=EUR&
                  lang=et&
                  promo=&
                  originBusStopId=17028&
                  destinationBusStopId=17058&
                passengers=1 */}
                <div className={s.schedule}>
                  <div className={s.header}>
                    <h3>{t('schedule')}</h3>
                    <h5 className={s.date}>{localizedDate(date, 'MM.dd')}</h5>
                  </div>
                  <Query
                    query={SEARCH}
                    variables={{
                      departureDate: localizedDate(date, 'yyyy-MM-dd'),
                      originCityId: departure.id,
                      destinationCityId: arrival.id,
                      // originCityId: 121, // riga
                      // destinationCityId: 1, // tallin
                      currency: 'RUB',
                      lang: language,
                      passengers: 1,
                    }}
                    skip={!departure.id}
                  >
                    {({
                      data = {},
                      loading,
                      error,
                      called,
                      stopPolling,
                      ...props
                    }) => {
                      if (loading) return <Loading />;
                      const QUANTITY_SHOWN = 4;
                      const count = data?.search?.length || 0;
                      const schedulePages =
                        Math.ceil(data?.search?.length / QUANTITY_SHOWN) || 1;
                      const showCount =
                        count > QUANTITY_SHOWN * schedulePage
                          ? QUANTITY_SHOWN
                          : count - QUANTITY_SHOWN * (schedulePage - 1);

                      const results = data?.search?.slice(
                        QUANTITY_SHOWN * (schedulePage - 1),
                        QUANTITY_SHOWN * (schedulePage - 1) + showCount
                      );
                      return (
                        !!data?.search?.length && (
                          <>
                            {results.map((item, index) => {
                              const showTime = time => {
                                return time
                                  .split('T')[1]
                                  .split(':')
                                  .slice(0, 2)
                                  .join(':');
                              };
                              const duration = item.Duration.split(':').slice(
                                0,
                                2
                              );
                              return (
                                <div className={s.item} key={index}>
                                  <div className={cn(s.point, s.from)}>
                                    <div className={s.name}>
                                      {item.OriginStopName}
                                    </div>
                                    <h5>{showTime(item.DepartureDateTime)}</h5>
                                  </div>
                                  <div className={s.duration}>
                                    <div>
                                      {`${+duration[0]}H ${duration[1]}M`}
                                    </div>
                                    {!isMob && (
                                      <div className={s.text}>
                                        {t('luxexpress special')}
                                      </div>
                                    )}
                                    {isMob && (
                                      <div className={s.text}>
                                        <div>{t('luxexpress')}</div>
                                        <div>{t('special')}</div>
                                      </div>
                                    )}
                                  </div>
                                  <div className={cn(s.point, s.to)}>
                                    <div className={s.name}>
                                      {item.DestinationStopName}
                                    </div>
                                    <h5>{showTime(item.ArrivalDateTime)}</h5>
                                  </div>
                                </div>
                              );
                            })}
                            <Controls
                              handlePrev={() => {
                                if (schedulePage > 1) {
                                  setSchedulePage(schedulePage - 1);
                                }
                              }}
                              handleNext={() => {
                                if (schedulePages > schedulePage) {
                                  setSchedulePage(schedulePage + 1);
                                }
                              }}
                              className={s.controls}
                              current={schedulePage}
                              count={schedulePages}
                            />
                            <Divider absolute height={3} />
                          </>
                        )
                      );
                    }}
                  </Query>
                </div>
              </div>
            )}
          </section>
        </Container>
      </Container>
      {isEditMode && (
        <Container>
          <Container full={false}>
            <section>
              <div className={s.visibility}>
                <span>{t('visibility')}:</span>
                <Switch
                  className={s.switch}
                  checked={showSeats}
                  onChange={() =>
                    updatePage({
                      showSeats: !showSeats,
                    })
                  }
                />
              </div>
            </section>
          </Container>
        </Container>
      )}
      {(isEditMode || showSeats) && (
        <Container className={s.seats}>
          <Container full={false}>
            <section>
              <div className={s.infoOptions}>
                <h2 className={s.title}>{t('roure.seats.title')}</h2>
                <p>{t('roure.seats.subTitle')}</p>
                <Button text={t('learn more')} to="/seats" />
              </div>
              <div className={s.seats}>
                <div className={s.seat}>
                  <LazyLoadImage src="/images/route/seats/seat-1.png" alt="" />
                  <h5>{t('lux express lounge')}</h5>
                </div>
                <div className={s.seat}>
                  <LazyLoadImage src="/images/route/seats/seat-2.png" alt="" />
                  <h5>{t('lux express standard')}</h5>
                </div>
              </div>
            </section>
          </Container>
        </Container>
      )}
      <Traveling />
      <Container className={s.transportation}>
        <Container full={false}>
          <section>
            <div className={cn(s.image, !transportation.value && s.empty)}>
              <LazyLoadImage src={transportation.value} alt="" />
              {isEditMode && (
                <Button
                  className={s.uploadBanner}
                  text={
                    transportation.value ? t('edit photo') : t('upload photo')
                  }
                  onClick={toggleTransportationImg}
                />
              )}
            </div>
            <div className={s.info}>
              <h2 className={s.title}>
                <Text onChange={updatePage} field={heading4} />
              </h2>
              <div className={s.texts}>
                <Editor
                  placeholder={t('description')}
                  html={transferingEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    updatePage({
                      transferingEditor: data,
                    });
                  }}
                />
                {/* <p>
                  <Text
                    onChange={updatePage}
                    field={text2}
                /> */}
                {/* Passenger is allowed to take on board one piece of hand luggage weighing <b>not more than 5 kg</b>, which does not exceed the size of <b>45 × 35 × 20 cm</b> (length – width – height). Passenger is allowed to take one piece of luggage (for example a bag or a case) free of charge weighing <b>no more than 30 kg</b> which does not exceed the size of <b>70 × 30 × 55 cm</b> (length – width – height), which is accommodated in a luggage compartment of the coach. */}
                {/* </p> */}
                {/* <p>
                  Luggage is accommodated in a luggage compartment and then handed back to the passenger by the coach crew.
                </p> */}
              </div>
            </div>
          </section>
        </Container>
      </Container>
      {(isEditMode || showTaxi) && (
        <Container className={s.transfer}>
          <div className={s.greyBlock} />
          <Container full={false}>
            <section>
              {isEditMode && (
                <div className={s.visibility}>
                  <span>{t('visibility')}:</span>
                  <Switch
                    className={s.switch}
                    checked={showTaxi}
                    onChange={() =>
                      updatePage({
                        showTaxi: !showTaxi,
                      })
                    }
                  />
                </div>
              )}
              <div className={s.content}>
                <div className={s.info}>
                  <h2 className={s.title}>
                    <Text onChange={updatePage} field={heading5} />
                  </h2>
                  <h5>
                    <Text onChange={updatePage} field={text3} />
                  </h5>
                  <TooltipInput
                    onChange={e =>
                      updatePage({
                        learnMoreLink: {
                          ...learnMoreLink,
                          value: e.target.value,
                        },
                      })
                    }
                    field={learnMoreLink}
                    target={
                      isEditMode ? (
                        <div className={s.linkButton}>
                          {t('learn more')}
                          <i className={i.arrowRight_v2} />
                        </div>
                      ) : (
                        <Link to={learnMoreLink.value} className={s.linkButton}>
                          {t('learn more')}
                          <i className={i.arrowRight_v2} />
                        </Link>
                      )
                    }
                  />
                </div>
                <div className={s.list}>
                  <Editor
                    placeholder={t('description')}
                    html={taxiEditor}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      updatePage({
                        taxiEditor: data,
                      });
                    }}
                  />
                  {/* <div className={s.item}> */}

                  {/* <Text
                      onChange={updatePage}
                      field={text4}
                  /> */}
                  {/* </div> */}
                  {/* <div className={s.item}>
                    <div className={s.circle} />
                    Riga is divided into three zones and the final price of the service depends on the distance to the destination
                    </div>
                    <div className={s.item}>
                    <div className={s.circle} />
                    One voucher is valid for one taxi and up to 4 passengers
                    </div>
                    <div className={s.item}>
                    <div className={s.circle} />
                    Taxi voucher can be bought online together with Lux Express bus tickets
                  </div> */}
                </div>
                <div className={cn(s.image, !taxi.value && s.empty)}>
                  <LazyLoadImage src={taxi.value} alt="" />
                  {isEditMode && (
                    <Button
                      className={s.uploadBanner}
                      text={taxi.value ? t('edit photo') : t('upload photo')}
                      onClick={toggleTaxiImg}
                    />
                  )}
                  <Divider absolut height={3} />
                </div>
              </div>
            </section>
          </Container>
        </Container>
      )}
      <Container className={s.otherRoutes}>
        <Container full={false}>
          <section>
            <h3>{t('other popular routes')}</h3>
            <Tags>
              <Query
                query={isStaff ? GET_WIDGET_ADMIN : GET_WIDGET}
                variables={{
                  lang: language,
                  region,
                  name: 'routes',
                }}
              >
                {({ data = {}, loading }) => {
                  if (loading) return <Loading />;

                  const routes = isStaff
                    ? data?.adminStaticWidget?.[language]?.widgetData?.items
                    : data?.staticWidget?.data?.widgetData?.items;

                  return (
                    !!routes?.length &&
                    routes.map((route, key) => {
                      const departure = route.departure.value;
                      const arrival = route.arrival.value;
                      const slug = route.slug.value;
                      return (
                        seo.slug.value.toLowerCase() != slug.toLowerCase() && (
                          <Tag
                            to={`/routes/${slug}/`}
                            text={`${t(departure)} - ${t(arrival)}`}
                            key={key}
                          />
                        )
                      );
                    })
                  );
                }}
              </Query>
            </Tags>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(RouteContent);
