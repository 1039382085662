import React, { useState, useContext } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { withTranslation, Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import { StoreContext } from '@lux/spa/store';
import { COMMIT_RETURN } from '@lux/spa/apollo/mutations';
import { useMutation } from '@apollo/react-hooks';
import { Collapse } from 'react-collapse';
import { Button, Notice, Link } from '@lux/ui';
import { Info } from '../components';
import s from '../refund.module.scss';

const ZeroPayment = ({ t }) => (
  <Notice className={s.notice}>
    <Trans i18nKey="by completing this ticket refunding I consent with the ticket refunding rules as stipulated in Lux Express ticket selling terms">
      By completing this ticket refunding I consent with the ticket refunding
      rules as stipulated in
      <Link className={s.link} to="/ticket-sales-rules/">
        {` ${t('Lux Express ticket selling terms')}`}
      </Link>
    </Trans>
  </Notice>
);

const PriceBlock = ({ data, symbol, t, inside, type, last }) => {
  let typeText = '';
  switch (type) {
    case 'voucher':
      typeText = t('voucher issued to you');
      break;

    case 'default':
      typeText = t('returned to your account');
      break;

    case 'contract':
      typeText = t('to be returned to client contract');
      break;

    default:
      break;
  }

  return (
    <div className={cn(s.priceBlock, inside && s.inside, last && s.last)}>
      <div className={s.priceLine}>
        {t('ticket price')}
        <div className={s.price}>
          {data.totalPrice} {symbol}
        </div>
      </div>
      <div className={s.priceLine}>
        {t('cancellation fee')}
        <div className={s.price}>
          {data.totalRefundAmountWithServiceFee - data.totalPrice} {symbol}
        </div>
      </div>
      <div className={s.priceLine}>
        {t('return fee')}
        <div className={s.price}>
          {-data.totalReturnFee} {symbol}
        </div>
      </div>
      <div className={cn(s.priceLine, s.total)}>
        {typeText}
        <div className={s.price}>
          {data.totalRefundableAmount} {symbol}
        </div>
      </div>
      {type === 'contract' && (
        <Notice className={s.notice}>
          {t('refund.voucher.summary.payment.booking.next.tickets')}
          <Trans i18nKey="by completing this ticket refunding I consent with the ticket refunding rules as stipulated in Lux Express ticket selling terms">
            By completing this ticket refunding I consent with the ticket
            refunding rules as stipulated in
            <Link className={s.link} to="/ticket-sales-rules/">
              {` ${t('Lux Express ticket selling terms')}`}
            </Link>
          </Trans>
        </Notice>
      )}
      {type === 'voucher' && (
        <Notice className={s.notice}>
          {t('refund.voucher.summary.voucher')}
          <Trans i18nKey="by completing this ticket refunding I consent with the ticket refunding rules as stipulated in Lux Express ticket selling terms">
            By completing this ticket refunding I consent with the ticket
            refunding rules as stipulated in
            <Link className={s.link} to="/ticket-sales-rules/">
              {` ${t('Lux Express ticket selling terms')}`}
            </Link>
          </Trans>
        </Notice>
      )}
      {type === 'default' && (
        <Notice className={s.notice}>
          {t('refund.voucher.summary.money.to.bank')}
          <Trans i18nKey="by completing this ticket refunding I consent with the ticket refunding rules as stipulated in Lux Express ticket selling terms">
            By completing this ticket refunding I consent with the ticket
            refunding rules as stipulated in
            <Link className={s.link} to="/ticket-sales-rules/">
              {` ${t('Lux Express ticket selling terms')}`}
            </Link>
          </Trans>
        </Notice>
      )}
    </div>
  );
};

const Summary = ({
  t,
  i18n,
  nextStep,
  goToStep,
  dataRefund,
  setDataRefund,
  history,
}) => {
  const {
    isOnlyOnePass,
    isOnlyOneWay,
    selectedWay,
    returnInfoVoucher,
    returnInfoDefault,
  } = dataRefund;
  const [state, dispatch] = useContext(StoreContext);
  const { language } = i18n;
  const { symbol } = state.tickets;
  const { shoppingBasketId, isPublic } = state.tickets.refund;
  const [isOpened, setIsOpened] = useState(false);

  const [commitReturn, { loading }] = useMutation(COMMIT_RETURN, {
    onError: e => {
      console.log('Error: ', e);
      toast.error('Something wrong');
    },
    onCompleted: data => {
      if (data.commitReturn.error) {
        toast.error(data.calculateReturn.error.ErrorReason);

        setDataRefund(prev => {
          return {
            ...prev,
            isSucces: false,
            isError: true,
          };
        });
      } else {
        setDataRefund(prev => {
          return {
            ...prev,
            isSuccess: true,
            isError: false,
            commitReturn: data.commitReturn,
          };
        });
      }

      nextStep();
    },
  });

  const calculatingDefault = {
    totalRefundAmountWithServiceFee: 0,
    totalReturnFee: 0,
    totalRefundableAmount: 0,
    totalPrice: 0,
  };

  const calculatingVoucher = { ...calculatingDefault };

  const selectedPassengersCodes = dataRefund.selectedPassengersCodes.map(
    item => item.ProductCode
  );

  returnInfoDefault.RefundableProducts.forEach(product => {
    if (selectedPassengersCodes.includes(product.ProductCode)) {
      product.RefundingScopes.forEach(scope => {
        if (scope.RefundingScopeXc === selectedWay.RefundingScopeXc) {
          calculatingDefault.totalPrice += scope.TotalPrice;
          calculatingDefault.totalRefundAmountWithServiceFee +=
            scope.RefundAmountWithServiceFee;
          calculatingDefault.totalReturnFee += scope.ServiceFee;
          calculatingDefault.totalRefundableAmount += scope.RefundableAmount;
        }
      });
    }
  });

  if (returnInfoVoucher) {
    returnInfoVoucher.RefundableProducts.forEach(product => {
      if (selectedPassengersCodes.includes(product.ProductCode)) {
        product.RefundingScopes.forEach(scope => {
          if (scope.RefundingScopeXc === selectedWay.RefundingScopeXc) {
            calculatingVoucher.totalPrice += scope.TotalPrice;
            calculatingVoucher.totalRefundAmountWithServiceFee +=
              scope.RefundAmountWithServiceFee;
            calculatingVoucher.totalReturnFee += scope.ServiceFee;
            calculatingVoucher.totalRefundableAmount += scope.RefundableAmount;
          }
        });
      }
    });
  }

  const showAccordion =
    calculatingVoucher &&
    calculatingDefault.totalRefundableAmount > 0 &&
    calculatingVoucher?.totalRefundableAmount > 0;

  return (
    <div className={cn(s.step, s.summary)}>
      <div className={s.content}>
        <h3>{t('refund tickets')}</h3>
        {showAccordion ? (
          <p>{t('please select prefered refund method')}</p>
        ) : calculatingDefault.totalRefundableAmount > 0 ? (
          <p>{t('please, check the summary and proceed refund')}</p>
        ) : (
          <p>{t('refund.no.money')}</p>
        )}
        <form className={s.form}>
          {!calculatingVoucher ? (
            calculatingDefault?.totalRefundableAmount > 0 ? (
              <PriceBlock
                data={calculatingDefault}
                type="contract"
                t={t}
                symbol={symbol}
              />
            ) : (
              <ZeroPayment t={t} />
            )
          ) : showAccordion ? (
            <>
              <div
                className={cn(s.calculatingBlock, isOpened && s.active)}
                onClick={() => setIsOpened(!isOpened)}
              >
                <div className={s.check} />
                <div className={s.title}>{t('refund by voucher')}</div>
              </div>
              <Collapse isOpened={isOpened}>
                <PriceBlock
                  inside
                  type="voucher"
                  data={calculatingVoucher}
                  t={t}
                  symbol={symbol}
                />
              </Collapse>
              <div
                className={cn(s.calculatingBlock, !isOpened && s.active)}
                onClick={() => setIsOpened(!isOpened)}
              >
                <div className={s.check} />
                <div className={s.title}>{t('refund money')}</div>
              </div>
              <Collapse isOpened={!isOpened}>
                <PriceBlock
                  inside
                  last
                  data={calculatingDefault}
                  type="default"
                  t={t}
                  symbol={symbol}
                />
              </Collapse>
            </>
          ) : calculatingDefault?.totalRefundableAmount === 0 &&
            calculatingVoucher?.totalRefundableAmount === 0 ? (
            <ZeroPayment t={t} />
          ) : (
            <>
              {calculatingDefault?.totalRefundableAmount > 0 && (
                <PriceBlock
                  data={calculatingDefault}
                  type="default"
                  t={t}
                  symbol={symbol}
                />
              )}
              {calculatingVoucher?.totalRefundableAmount > 0 && (
                <PriceBlock
                  data={calculatingVoucher}
                  type="voucher"
                  t={t}
                  symbol={symbol}
                />
              )}
            </>
          )}

          <div className={s.controls}>
            <Button
              className={s.button}
              disabled={loading}
              text={loading ? t('loading') : t('refund')}
              onClick={() => {
                const RefundableProducts = [];

                dataRefund.selectedPassengersCodes.forEach(item => {
                  RefundableProducts.push({
                    ProductCode: item.ProductCode,
                    ReturningScopeXc: selectedWay.RefundingScopeXc,
                  });
                });

                const Id = showAccordion
                  ? isOpened
                    ? returnInfoVoucher.Id
                    : returnInfoDefault.Id
                  : !!returnInfoVoucher &&
                    calculatingVoucher?.totalRefundableAmount > 0
                  ? returnInfoVoucher.Id
                  : calculatingDefault?.totalRefundableAmount > 0
                  ? returnInfoDefault.Id
                  : returnInfoDefault.Id;

                commitReturn({
                  variables: {
                    Id,
                    RefundableProducts,
                    ShoppingBasketId: shoppingBasketId,
                  },
                });
              }}
            />
            <div
              className={s.cancel}
              onClick={
                isPublic
                  ? () => history.push(`/${language}/manage-booking/trips/`)
                  : isOnlyOnePass && isOnlyOneWay
                  ? () => goToStep(1)
                  : !isOnlyOnePass && isOnlyOneWay
                  ? () => goToStep(2)
                  : () => goToStep(3)
              }
            >
              {t('cancel')}
            </div>
          </div>
        </form>
      </div>
      <Info dataRefund={dataRefund} />
    </div>
  );
};

export default withRouter(withTranslation()(Summary));
