import gql from 'graphql-tag';

export const REMOVE_PAYMENT_METHOD = gql`
  mutation($id: String!) {
    removeUserStoredPaymentMethod(id: $id) {
      success
      error {
        ErrorCode
        ErrorReason
      }
    }
  }
`;
