import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Modal, ModalContent, Button, TicketsInput, Loading } from '@lux/ui';
import { FORGOT_PASSWORD } from '@lux/spa/apollo/mutations';
import s from './forgot-password.module.scss';

const ForgotPassword = ({ t, i18n, isOpen, toggleModal, closeModal }) => {
  const { language } = i18n;
  const [success, setSuccess] = useState(false);

  const [forgotPassword, { called, loading }] = useMutation(FORGOT_PASSWORD);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
        closeModal();
      }, 3000);
    }
  }, [success]);

  return (
    <Modal className={s.modal} isOpen={isOpen} toggleModal={toggleModal}>
      <ModalContent>
        <div className={s.contols}>
          {success ? (
            <div className={s.success}>
              <img src="/images/svg/send-email-success.svg" alt="" />
              <h3>{t('success')}</h3>
              <h5>{t('the link has been sent to your email')}</h5>
              <div>{t('popup will be closed in 3 sec.')}</div>
            </div>
          ) : (
            <>
              <h3>{t('forgot password?')}</h3>
              <p>
                {t(
                  'enter your email and we send you the link to reset your password'
                )}
              </p>
              <Formik
                // innerRef={formRef}
                validateOnMount
                enableReinitialize
                initialValues={{
                  email: '',
                  password: '',
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email(
                      t('validation.error.email', {
                        field: t('email'),
                      })
                    )
                    .required(
                      t('validation.error.require', {
                        field: t('email'),
                      })
                    ),
                  password: Yup.string()
                    .required(
                      t('validation.error.require', {
                        field: t('password'),
                      })
                    )
                    .min(
                      6,
                      t('validation.error.min', {
                        field: t('password'),
                        min: 6,
                      })
                    ),
                })}
              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldError,
                  } = props;

                  return (
                    <form className={s.form}>
                      <div className={s.input}>
                        <TicketsInput
                          autoFocus
                          name="email"
                          label={t('email')}
                          placeholder={t('enter your email')}
                          type="text"
                          required
                          value={values.email}
                          onChange={e => {
                            e.target.value = e.target.value.trim();
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          errors={touched.email && errors.email}
                        />
                      </div>
                      <Button
                        disabled={loading || errors.email}
                        className={s.button}
                        text={loading ? <Loading /> : t('send')}
                        onClick={() => {
                          forgotPassword({
                            variables: {
                              email: values.email,
                              lang: language,
                            },
                          }).then(data => {
                            if (data.data.forgotPassword.error) {
                              setFieldError(
                                'email',
                                data.data.forgotPassword.error.ErrorReason
                              );
                            }

                            if (data.data.forgotPassword.success) {
                              setSuccess(true);
                            }
                          });
                        }}
                      />
                    </form>
                  );
                }}
              </Formik>
              {/* {called && success && (
            <Notice className={s.success} text={t('success')} color="green" />
          )} */}
            </>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
};

export default withTranslation()(ForgotPassword);
