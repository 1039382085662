import gql from 'graphql-tag';

export const GET_SUGGESTIONS = gql`
  query($cityName: String, $lang: String) {
    cities(cityName: $cityName, lang: $lang) {
      CityId
      CityName
      BusStops {
        BusStopId
        BusStopName
        CountryName
      }
    }
  }
`;
