import React, { createRef, memo } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { TicketsInput, PhoneInput, Radio, Select, SelectOption } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from '../../passenger-details.module.scss';

const CoreForm = ({
  t,
  number,
  handleBlur,
  handleChange,
  setFieldValue,
  touched,
  errors,
  values,
  isDateOfBirthError,
  legs,
  formSettings,
}) => {
  const phoneNumberInput = createRef();
  return (
    <div className={s.form}>
      {formSettings.fields.includes(`Gender${number}`) && (
        <div className={s.row}>
          <div className={s.radios}>
            <Radio
              className={s.radio}
              name={`Gender${number}`}
              value={t('male')}
              checked={values[`Gender${number}`] === 'SEX.MALE'}
              onChange={() => setFieldValue(`Gender${number}`, 'SEX.MALE')}
            />
            <Radio
              className={s.radio}
              name={`Gender${number}`}
              value={t('female')}
              checked={values[`Gender${number}`] === 'SEX.FEMALE'}
              onChange={() => setFieldValue(`Gender${number}`, 'SEX.FEMALE')}
            />
          </div>
        </div>
      )}
      <div className={s.row}>
        {formSettings.fields.includes(`PassengerFirstName${number}`) && (
          <div className={s.input}>
            <TicketsInput
              name={`PassengerFirstName${number}`}
              placeholder={formSettings.formFieldKeyNames.PassengerFirstName}
              label={formSettings.formFieldKeyNames.PassengerFirstName}
              type="text"
              required={formSettings.requiredFields.includes(
                `PassengerFirstName${number}`
              )}
              value={values[`PassengerFirstName${number}`]}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={
                touched[`PassengerFirstName${number}`] &&
                errors[`PassengerFirstName${number}`]
              }
            />
          </div>
        )}
        {formSettings.fields.includes(`PassengerLastName${number}`) && (
          <div className={s.input}>
            <TicketsInput
              name={`PassengerLastName${number}`}
              placeholder={formSettings.formFieldKeyNames.PassengerLastName}
              label={formSettings.formFieldKeyNames.PassengerLastName}
              type="text"
              required={formSettings.requiredFields.includes(
                `PassengerLastName${number}`
              )}
              value={values[`PassengerLastName${number}`]}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={
                touched[`PassengerLastName${number}`] &&
                errors[`PassengerLastName${number}`]
              }
            />
          </div>
        )}
      </div>
      <div className={s.row}>
        {formSettings.fields.includes(`PhoneNumber${number}`) && (
          <div className={s.input}>
            <PhoneInput
              ref={phoneNumberInput}
              name={`PhoneNumber${number}`}
              placeholder={formSettings.formFieldKeyNames.PhoneNumber}
              label={formSettings.formFieldKeyNames.PhoneNumber}
              value={values[`PhoneNumber${number}`]}
              required={formSettings.requiredFields.includes(
                `PhoneNumber${number}`
              )}
              onChange={e => setFieldValue(`PhoneNumber${number}`, e)}
              onBlur={handleBlur}
              errors={
                touched[`PhoneNumber${number}`] &&
                errors[`PhoneNumber${number}`]
              }
            />
          </div>
        )}
        {formSettings.fields.includes(`Email${number}`) && (
          <div className={s.input}>
            <TicketsInput
              name={`Email${number}`}
              placeholder={formSettings.formFieldKeyNames.Email}
              label={formSettings.formFieldKeyNames.Email}
              type="text"
              tooltipText={t(
                'VIP and level 1/2 cardholder discount is applied based on email address'
              )}
              required={formSettings.requiredFields.includes(`Email${number}`)}
              value={values[`Email${number}`]}
              onChange={e => {
                e.target.value = e.target.value.trim();
                handleChange(e);
              }}
              onBlur={handleBlur}
              errors={touched[`Email${number}`] && errors[`Email${number}`]}
            />
          </div>
        )}
      </div>
      <div className={s.row}>
        {formSettings.fields.includes(`PassengerFatherName${number}`) && (
          <div className={s.input}>
            <TicketsInput
              name={`PassengerFatherName${number}`}
              placeholder={formSettings.formFieldKeyNames.PassengerFatherName}
              label={formSettings.formFieldKeyNames.PassengerFatherName}
              type="text"
              required={formSettings.requiredFields.includes(
                `PassengerFatherName${number}`
              )}
              value={values[`PassengerFatherName${number}`]}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={
                touched[`PassengerFatherName${number}`] &&
                errors[`PassengerFatherName${number}`]
              }
            />
          </div>
        )}
        {formSettings.fields.includes(`LoyaltyCardNumber${number}`) && (
          <div className={s.input}>
            <TicketsInput
              name={`LoyaltyCardNumber${number}`}
              placeholder={formSettings.formFieldKeyNames.LoyaltyCardNumber}
              label={formSettings.formFieldKeyNames.LoyaltyCardNumber}
              type="text"
              required={formSettings.requiredFields.includes(
                `LoyaltyCardNumber${number}`
              )}
              value={values[`LoyaltyCardNumber${number}`]}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={
                touched[`LoyaltyCardNumber${number}`] &&
                errors[`LoyaltyCardNumber${number}`]
              }
            />
          </div>
        )}
      </div>
      <div className={s.row}>
        {formSettings.fields.includes(`DateOfBirth${number}`) && (
          <div
            className={cn(
              s.input,
              s.dateOfBirth,
              isDateOfBirthError && s.error
            )}
          >
            <label>{formSettings.formFieldKeyNames.DateOfBirth} *</label>
            <div className={s.selects}>
              <Select
                name={`Day${number}`}
                value={values[`Day${number}`]}
                className={s.select}
                required={formSettings.requiredFields.includes(
                  `DateOfBirth${number}`
                )}
                onChange={handleChange}
                onBlur={handleBlur}
                withoutMessage
                errorWithoutMessage={
                  touched[`Day${number}`] && !!errors[`Day${number}`]
                }
              >
                <SelectOption value="" text="DD" disabled selected />
                {Array(31)
                  .fill()
                  .map((day, key) => (
                    <SelectOption key={key} value={key + 1} text={key + 1} />
                  ))}
              </Select>
              <Select
                name={`Month${number}`}
                value={values[`Month${number}`]}
                className={s.select}
                required={formSettings.requiredFields.includes(
                  `DateOfBirth${number}`
                )}
                onChange={handleChange}
                onBlur={handleBlur}
                withoutMessage
                errorWithoutMessage={
                  touched[`Month${number}`] && !!errors[`Month${number}`]
                }
              >
                <SelectOption value="" text="MM" disabled selected />
                {Array(12)
                  .fill()
                  .map((day, key) => (
                    <SelectOption key={key} value={key + 1} text={key + 1} />
                  ))}
              </Select>
              <Select
                name={`Year${number}`}
                value={values[`Year${number}`]}
                className={s.select}
                required={formSettings.requiredFields.includes(
                  `DateOfBirth${number}`
                )}
                onChange={handleChange}
                onBlur={handleBlur}
                withoutMessage
                errorWithoutMessage={
                  touched[`Year${number}`] && !!errors[`Year${number}`]
                }
              >
                <SelectOption value="" text="YY" disabled selected />
                {Array(120)
                  .fill()
                  .map((_, idx) => (
                    <SelectOption
                      key={idx}
                      value={new Date().getFullYear() - 120 + idx + 1}
                      text={new Date().getFullYear() - 120 + idx + 1}
                    />
                  ))}
              </Select>
            </div>
            {isDateOfBirthError && (
              <div className={s.message}>{isDateOfBirthError}</div>
            )}
          </div>
        )}
      </div>
      <div className={s.row}>
        {formSettings.fields.includes(`CitizenshipId${number}`) && (
          <div className={s.input}>
            <Select
              name={`CitizenshipId${number}`}
              label={formSettings.formFieldKeyNames.CitizenshipId}
              value={values[`CitizenshipId${number}`]}
              className={s.select}
              required={formSettings.requiredFields.includes(
                `CitizenshipId${number}`
              )}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={
                touched[`CitizenshipId${number}`] &&
                errors[`CitizenshipId${number}`]
              }
            >
              <SelectOption value="" text="Select" />
              {formSettings.formFieldDropdownItems[
                `CitizenshipId${number}`
              ].map(citizenship => (
                <SelectOption
                  key={citizenship.Id}
                  value={citizenship.Id}
                  text={citizenship.Name}
                />
              ))}
            </Select>
          </div>
        )}
        {formSettings.fields.includes(`DocumentType${number}`) && (
          <div className={s.input}>
            <Select
              name={`DocumentType${number}`}
              label={formSettings.formFieldKeyNames.DocumentType}
              value={values[`DocumentType${number}`]}
              className={s.select}
              required={formSettings.requiredFields.includes(
                `DocumentType${number}`
              )}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={
                touched[`DocumentType${number}`] &&
                errors[`DocumentType${number}`]
              }
            >
              <SelectOption value="" text="Select" />
              {formSettings.formFieldDropdownItems[`DocumentType${number}`].map(
                type => (
                  <SelectOption
                    key={type.Id}
                    value={type.Id}
                    text={type.Name}
                  />
                )
              )}
            </Select>
          </div>
        )}

        {formSettings.fields.includes(`DocumentNumber${number}`) && (
          <div className={s.input}>
            <TicketsInput
              name={`DocumentNumber${number}`}
              type="text"
              label={formSettings.formFieldKeyNames.DocumentNumber}
              placeholder={formSettings.formFieldKeyNames.DocumentNumber}
              required={formSettings.requiredFields.includes(
                `DocumentNumber${number}`
              )}
              value={values[`DocumentNumber${number}`]}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={
                touched[`DocumentNumber${number}`] &&
                errors[`DocumentNumber${number}`]
              }
            />
          </div>
        )}
      </div>
      <div className={s.seats}>
        {legs.map(leg => {
          const isOutbound = leg.SeatPlan.IsOutbound;
          return (
            <div className={s.seat}>
              <div className={s.mainInfo}>
                <div className={s.type}>
                  {isOutbound ? t('outbound seat') : t('inbound seat')}
                </div>
                <div className={s.fromTo}>
                  {`(${leg.DepartureStopName} - ${leg.DestinationStopName})`}
                </div>
              </div>
              <div className={s.seatNuber}>
                <i className={i.seat} />
                {leg.SeatInfo[number - 1].SeatNumber}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withTranslation()(memo(CoreForm));
