import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { MODIFICATION_NEO_PAY_DETAILS } from '@lux/spa/apollo/mutations';
import { useMutation } from '@apollo/react-hooks';
import { StoreContext } from '@lux/spa/store';
import ThanksLayoutSuccess from '../../thanks-layout-success.jsx';
import ThanksLayoutPending from '../../thanks-layout-pending.jsx';
import ThanksLayoutError from '../../thanks-layout-error.jsx';

const Banks = ({ fileLoading, getTicketsFileUrl, urlParams }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { totalPrice, GTM } = state.changeTickets;
  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';
  const { from, to, returnDate, promocode } = state.changeTickets.search;
  const { GTMLoaded } = state.app;
  const { token, data, canceled, pending } = urlParams;
  const [isCountdown, setIsCountdown] = useState(false);
  const [enabledQuering, setEnabledQuering] = useState(true);
  const [isError, setIsError] = useState(+canceled === 1);
  const [isLoading, setIsLoading] = useState(+pending === 1);
  const [neoPayDetails, { data: detailsData, called }] = useMutation(
    MODIFICATION_NEO_PAY_DETAILS,
    {
      variables: {
        token: token || data,
      },
      onError: () => {
        setIsError(true);
        setIsLoading(false);
      },
      onCompleted: data => {
        const error = data?.modificationNeoPayDetails.error;

        if (error) {
          if (error.ErrorCode === 'order_not_paid' && enabledQuering) {
            setIsCountdown(true);
            setIsLoading(true);
          } else {
            setIsCountdown(false);
            setIsError(true);
            setIsLoading(false);
          }
        } else {
          setIsCountdown(false);
          setIsLoading(false);

          const quantity = data?.modificationNeoPayDetails.objects[0].Passengers.reduce(
            (sum, pass) => (sum += pass.Tickets.length),
            0
          );
          const Id =
            data?.modificationNeoPayDetails.objects[0].ShoppingBasketCode;

          if (GTMLoaded && !GTM.thanksStep) {
            // dataLayer.push({
            //   tripType: returnDate ? 'return-trip' : 'one-way',
            //   departureStopId: from.BusStopId,
            //   destinationStopId: to.BusStopId,
            //   currencyCode: currencyName,
            //   route: `${from.CityName}-${to.CityName}`,
            //   price: totalPrice,
            //   promocode: promocode || null,
            //   departureDestination: `${from.CountryName}-${to.CountryName}`,
            //   stage: 'purchase',
            //   event: 'payment-success',
            //   Id,
            //   quantity,
            // });

            dispatch({
              type: 'UPDATE_CHANGE_TICKETS',
              payload: {
                GTM: {
                  ...GTM,
                  thanksStep: true,
                },
              },
            });
          }

          getTicketsFileUrl();
        }
      },
    }
  );

  useEffect(() => {
    if (+canceled !== 1 && !called) {
      neoPayDetails();
    }
  }, [canceled]);

  useEffect(() => {
    const error = detailsData?.modificationNeoPayDetails.error;
    let timer;

    if (error) {
      if (error.ErrorCode === 'order_not_paid' && enabledQuering) {
        timer = setTimeout(() => {
          neoPayDetails();
        }, 10000);
      }
    }
    return () => {
      clearTimeout(timer);
    };
  }, [detailsData]);

  useEffect(() => {
    if (isCountdown) {
      setInterval(() => {
        setEnabledQuering(false);
      }, 60000);
    }
  }, [isCountdown]);

  // useEffect(() => {
  //   if (+canceled === 1) {
  //     // history.push('/tickets/payment/banks/');
  //     history.push(
  //       `${
  //         language === DEFAULT_LANG ? '' : `/${language}`
  //       }/tickets/payment/banks/`
  //     );
  //   }
  // }, [canceled]);

  return isLoading ? (
    <ThanksLayoutPending />
  ) : isError ? (
    <ThanksLayoutError renderCancel />
  ) : (
    <ThanksLayoutSuccess
      type="card"
      fileLoading={fileLoading}
      getTicketsFileUrl={getTicketsFileUrl}
    />
  );
};

export default withRouter(withTranslation()(Banks));
