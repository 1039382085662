import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { StoreContext } from '@lux/spa/store';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { SIGN_IN } from '@lux/spa/apollo/mutations';
import {
  Modal,
  ModalContent,
  Button,
  Link,
  TicketsInput,
  HiddenInput,
  Notice,
  ForgotPassword,
} from '@lux/ui';
import s from './login.module.scss';

const Login = ({ t, i18n, isOpen, toggleModal, history, location }) => {
  const { language } = i18n;
  const [state, dispatch] = useContext(StoreContext);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [signIn, { loading, data }] = useMutation(SIGN_IN);
  return (
    <Modal
      className={s.modal}
      isOpen={isOpen}
      toggleModal={() => {
        toggleModal();
        history.replace(
          `${location.pathname}${location.search.replace(/&?modal=login/, '')}`
        );
      }}
    >
      <ModalContent className={s.content}>
        <ForgotPassword
          toggleModal={() => setIsOpenModal(isOpenModal => !isOpenModal)}
          isOpen={isOpenModal}
          closeModal={() => setIsOpenModal(false)}
        />
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(
                t('validation.error.email', {
                  field: t('email'),
                })
              )
              .required(
                t('validation.error.require', {
                  field: t('email'),
                })
              ),
            password: Yup.string()
              .required(
                t('validation.error.require', {
                  field: t('password'),
                })
              )
              .min(
                6,
                t('validation.error.min', {
                  field: t('password'),
                  min: 6,
                })
              ),
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              setFieldError,
            } = props;
            return (
              <form className={s.form}>
                <h2 className={s.title}>{t('login')}</h2>
                <p className={s.text}>
                  {t('don’t have an account?')}
                  <Link
                    className={s.link}
                    to="/registration/"
                    onClick={toggleModal}
                  >
                    {t('register')}
                  </Link>
                </p>
                <div className={s.input}>
                  <TicketsInput
                    autoFocus
                    name="email"
                    label={t('email')}
                    type="text"
                    required
                    value={values.email}
                    onChange={e => {
                      e.target.value = e.target.value.trim();
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    errors={touched.email && errors.email}
                  />
                </div>
                <div className={s.input}>
                  <div
                    className={s.forgotPassword}
                    onClick={() => setIsOpenModal(true)}
                  >
                    {t('forgot password?')}
                  </div>
                  <HiddenInput
                    name="password"
                    placeholder="••••••"
                    label={t('password')}
                    required
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={touched.password && errors.password}
                  />
                </div>
                {data?.signIn?.error?.ErrorReason && (
                  <Notice
                    color="red"
                    text={t(data?.signIn?.error?.ErrorReason)}
                    className={s.error}
                  />
                )}
                <Button
                  disabled={loading || errors.email || errors.password}
                  className={s.button}
                  text={loading ? t('loading') : t('login button')}
                  onClick={() => {
                    signIn({
                      variables: {
                        email: values.email,
                        password: values.password,
                      },
                    }).then(data => {
                      // if (data?.data?.signIn?.error) {
                      //   setFieldError(
                      //     'password',
                      //     data?.data?.signIn?.error.ErrorReason
                      //   );
                      //   return;
                      // }

                      const token = data?.data?.signIn?.token;
                      const profile = data?.data?.signIn?.profile;

                      if (token) {
                        // localStorage.removeItem('token', token);
                        // localStorage.setItem('token', token);

                        const maxAgeCookie = 2147483647; // 2147483647 ~ 68 years
                        document.cookie = `token=${token};path=/;max-age=${maxAgeCookie};`;

                        dispatch({
                          type: 'UPDATE_USER',
                          payload: {
                            ...profile,
                          },
                        });

                        toggleModal();

                        history.replace(
                          `${location.pathname}${location.search.replace(
                            /&?modal=login/,
                            ''
                          )}`
                        );
                      }
                    });
                  }}
                />
              </form>
            );
          }}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default withRouter(withTranslation()(Login));
