import React, { useEffect, useState, useReducer, useRef } from 'react';
import cn from 'classnames';

import { Suggestions } from '@lux/spa/components';
import { Button, Input, Loading } from '@lux/ui';
import s from './stop.module.scss';

// const SearchField = () => {
//   return (
//     <div className={s.search}>
//       <Input
//         autoFocus={true}
//         // className={s.input}
//         label={stopName.placeholder}
//         // value={stopId.value}
//         value={inputValue}
//         onChange={e => setInputValue(e.target.value)}
//         // onChange={e => updateSlide({
//         //   setInputValue(e.target.value)
//         //   // [stopId.fieldName]: {
//         //   //   ...stopId,
//         //   //   value: e.target.value
//         //   // }
//         // })}
//       />
//       {isOpenSugg && (
//         <Suggestions
//           search={inputValue}
//           selectItem={selectItem}
//           suggestionsRef={suggestionsRef}
//         />
//       )}
//     </div>
//   )
// }

const stopReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_STOP':
      return {
        ...state,
        ...action.payload,
      };
  }
};

const Stop = ({
  className,
  toggleEditSlide,
  slide = {},
  slides,
  sliderDispatch,
  ...props
}) => {
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, stopDispatch] = useReducer(stopReducer, slide);

  const { stopId = {}, stopName = {}, order } = state;

  const isNew = order > slides.length;
  const type = isNew ? 'ADD_SLIDE' : 'UPDATE_SLIDE';

  const [isOpenSugg, setIsOpenSugg] = useState(false);
  const [inputValue, setInputValue] = useState(stopName.value);

  const suggestionsRef = useRef(null);

  const selectItem = ({ BusStopId, BusStopName }) => {
    stopDispatch({
      type: 'UPDATE_STOP',
      payload: {
        stopId: {
          ...stopId,
          value: BusStopId,
        },
        stopName: {
          ...stopName,
          value: BusStopName,
        },
      },
    });
    setIsOpenSugg(false);
    setInputValue(BusStopName);
  };

  const hideSuggestions = e => {
    if (!e.path.includes(suggestionsRef.current)) {
      setIsOpenSugg(false);
    }
  };

  const saveSlide = () => {
    sliderDispatch({
      type,
      payload: {
        slide: state,
      },
    });
    toggleEditSlide();
  };

  useEffect(() => {
    if (isClient && !('path' in Event.prototype)) {
      Object.defineProperty(Event.prototype, 'path', {
        get() {
          const path = [];
          let currentElem = this.target;
          while (currentElem) {
            path.push(currentElem);
            currentElem = currentElem.parentElement;
          }

          if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
            path.push(document);
          }

          if (path.indexOf(window) === -1) {
            path.push(window);
          }

          return path;
        },
      });
    }

    if (isClient) {
      if (isOpenSugg) {
        document.addEventListener('click', hideSuggestions, false);

        return () => {
          document.removeEventListener('click', hideSuggestions, false);
        };
      }
    }
  }, [isOpenSugg]);

  useEffect(() => {
    if (inputValue) {
      setIsOpenSugg(true);
    } else {
      setIsOpenSugg(false);
    }
  }, [inputValue]);

  return (
    <div className={cn(s.stop, className)}>
      <div className={s.input}>
        <Input
          autoFocus
          // className={s.input}
          label={stopName.placeholder}
          // value={stopId.value}
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          // onChange={e => updateSlide({
          //   setInputValue(e.target.value)
          //   // [stopId.fieldName]: {
          //   //   ...stopId,
          //   //   value: e.target.value
          //   // }
          // })}
        />
        {isOpenSugg && (
          <Suggestions
            search={inputValue}
            selectItem={selectItem}
            suggestionsRef={suggestionsRef}
          />
        )}
      </div>
      <div className={s.controls}>
        <Button text="Save" onClick={saveSlide} />
        <Button text="Cancel" onClick={toggleEditSlide} />
      </div>
    </div>
  );
};

export default Stop;
