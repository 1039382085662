import React from 'react';
import cn from 'classnames';

import s from './services.module.scss';

const Services = ({ className }) => {
  return <div className={cn(className, s.services)}>Page Services</div>;
};

export default Services;
