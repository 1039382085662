import React, { useContext, useState, useEffect } from 'react';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { StoreContext } from '@lux/spa/store';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import {
  MODIFICATION_CHANGE_SEAT,
  MODIFICATION_SEAT_PLAN,
} from '@lux/spa/apollo/mutations';
import {
  MODIFICATION_DETAILS,
  MODIFICATION_PAYABLE_AMOUNT,
} from '@lux/spa/apollo/queries';
import i from '@lux/spa/styles/icons.module.scss';
import styles from './bus.module.scss';

const Bus = ({
  t,
  i18n,
  className,
  leg = {},
  setIsDisabledContinue = () => {},
}) => {
  const { language } = i18n;
  const [state, dispatch] = useContext(StoreContext);
  const {
    activeBus,
    activePassenger,
    trip,
    search,
    journey,
    currentStepDisabled,
    currentStepLoading,
    selectedSeats,
  } = state.changeTickets;

  const { passengers } = state.changeTickets.search;

  const { Buses } = leg;
  const seatRows = Buses[0].Floors[0].SeatRows;
  const [newSeat, setNewSeat] = useState({});
  const loading = false;

  const [changeSeat, { loading: changeSeatLoading }] = useMutation(
    MODIFICATION_CHANGE_SEAT
  );
  const [updateBus] = useMutation(MODIFICATION_SEAT_PLAN, {
    onCompleted: data => {
      if (data.modificationSeatPlans.error) {
        toast.error(data.modificationSeatPlans.error.ErrorReason);
      } else {
        dispatch({
          type: 'UPDATE_CHANGE_TICKETS',
          payload: {
            seatPlan: data.modificationSeatPlans.seatPlan,
          },
        });
      }
    },
    onError: data => console.log('updateBus error', data),
  });

  const [modificationPayableAnount] = useLazyQuery(
    MODIFICATION_PAYABLE_AMOUNT,
    {
      fetchPolicy: 'network-only',
      onCompleted: data => {
        if (data.modificationPayableAmount) {
          dispatch({
            type: 'UPDATE_CHANGE_TICKETS',
            payload: {
              totalPrice: data.modificationPayableAmount.PayableAmount,
            },
          });
        }
      },
      onError: data => {
        console.error('error', data);
      },
    }
  );

  const [modificationDetails] = useLazyQuery(MODIFICATION_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data.modificationDetails) {
        dispatch({
          type: 'UPDATE_CHANGE_TICKETS',
          payload: {
            totalPrice: data.modificationDetails.ModificationCost,
          },
        });
      }
    },
    onError: data => {
      console.error('error', data);
    },
  });

  const selected = selectedSeats?.[`bus${activeBus}`]
    ? Object.entries(selectedSeats?.[`bus${activeBus}`]).map(
        ([key, val]) => val
      )
    : [];

  const isAllSeatsSeleted = (newSelectedSeats = null) => {
    const rightNumberOfSeats = journey.Legs.length * passengers;

    const countOfSeletedSeats = Object.values(
      newSelectedSeats || selectedSeats
    ).reduce((sum, elem) => sum + Object.keys(elem).length, 0);

    return rightNumberOfSeats === countOfSeletedSeats;
  };

  const selectSeat = seat => {
    setNewSeat(seat);

    const input = [
      {
        ProductCode: trip.Passengers[activePassenger - 1].ProductCode,
        Leg: {
          IsOutbound: trip.IsOutbound,
          OrderNumber: leg.OrderNumber,
        },
        TripBusProfileId: leg.Buses[0].TripBusProfileId,
        Seat: {
          SeatFloorNumber: Buses[0].Floors[0].FloorNumber,
          SeatClassCategory: seat.SeatClassCategory,
          SeatNumber: seat.SeatNumber,
          SeatNumberLetter: seat.SeatNumberLetter,
        },
      },
    ];

    changeSeat({
      variables: {
        input,
      },
    })
      .then(data => {
        const { error } = data.data.modificationChangeSeat;

        if (error) {
          toast.error(error.ErrorReason);
        } else {
          const {
            Leg,
            ProductCode,
          } = data.data.modificationChangeSeat.prices[0];

          const newSelectedSeats = { ...selectedSeats };
          newSelectedSeats[`bus${Leg.OrderNumber}`] = {
            ...newSelectedSeats[`bus${Leg.OrderNumber}`],
            [ProductCode]: seat.SeatNumber,
          };

          dispatch({
            type: 'UPDATE_CHANGE_TICKETS',
            payload: {
              selectedSeats: newSelectedSeats,
              currentStepDisabled: !isAllSeatsSeleted(newSelectedSeats),
            },
          });

          if (data?.data?.modificationChangeSeat?.prices?.[0]?.Prices?.length) {
            input[0].PriceItemId =
              data.data.modificationChangeSeat.prices[0].Prices[0].Id;
            changeSeat({
              variables: {
                input,
              },
            });
          }

          updateBus();

          // modificationPayableAnount();
          setTimeout(() => {
            modificationDetails();
          }, 100);
        }
      })
      .catch(data => {
        console.error('Error', data);
        toast.error(t('something wrong'));
      });
  };

  useEffect(() => {
    setIsDisabledContinue(loading);
    dispatch({
      type: 'UPDATE_CHANGE_TICKETS',
      payload: {
        currentStepLoading: loading,
        currentStepDisabled: !isAllSeatsSeleted(),
      },
    });
  }, [loading]);

  useEffect(() => {
    setIsDisabledContinue(changeSeatLoading);
  }, [changeSeatLoading]);

  const classes = cx(styles.bus, className);

  return (
    <div className={classes}>
      <div className={cx(styles.decorate, styles.left)} />
      <div className={cx(styles.decorate, styles.right)} />
      <div className={styles.steeringWheel}>
        <i className={i.steeringWheel} />
      </div>
      <div className={styles.seats}>
        {!!seatRows.length &&
          seatRows.map((row, key) => {
            const orderedSeats = row?.Seats.sort(
              (a, b) => a.ColumnNumber - b.ColumnNumber
            );
            return (
              <div className={styles.row} key={key}>
                {orderedSeats.map((seat, key) => {
                  // const isDefault = defaultSelectedSeats.includes(
                  //   seat.SeatNumber
                  // );
                  const isSelected = selected.includes(seat.SeatNumber);
                  const classes = cx(
                    styles.seat,
                    seat.SeatClass === 'SEAT_CLASS.B'
                      ? styles.regular
                      : styles.lounge,
                    isSelected && styles.selected,
                    // !isDefault && !seat.IsAvailable && styles.occupied
                    !isSelected && !seat.IsAvailable && styles.occupied,
                    (loading || changeSeatLoading) &&
                      seat.SeatNumber === newSeat.SeatNumber &&
                      styles.loading
                  );

                  return seat.IsEmptyArea ? (
                    <div key={key} className={styles.seat} />
                  ) : seat.SeatClass === 'SEAT_CLASS.B' ? (
                    <div
                      className={classes}
                      key={key}
                      onClick={() => {
                        if (
                          !currentStepLoading &&
                          !isSelected &&
                          seat.IsAvailable &&
                          !changeSeatLoading
                        ) {
                          selectSeat(seat);
                        }
                      }}
                    >
                      <div className={styles.icon}>
                        {isSelected
                          ? `P${selected.indexOf(seat.SeatNumber) + 1}`
                          : seat.SeatNumber}
                      </div>
                    </div>
                  ) : (
                    <div
                      data-title={t('tickets.notice2')}
                      data-title-center
                      className={classes}
                      key={key}
                      onClick={() => {
                        if (
                          !currentStepLoading &&
                          !isSelected &&
                          seat.IsAvailable &&
                          !changeSeatLoading
                        ) {
                          selectSeat(seat);
                        }
                      }}
                    >
                      <div className={styles.icon}>
                        {isSelected
                          ? `P${selected.indexOf(seat.SeatNumber) + 1}`
                          : seat.SeatNumber}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default withTranslation()(Bus);
