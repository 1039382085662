import gql from 'graphql-tag';

export const FRAGMENT_LEGS = gql`
  fragment ContentLegsFields on JourneyRouteType {
    Legs {
      OrderNumber
      ServiceCode
      BusCompanyName
      Subcontractor
      BusLineNumber
      TripName
      BrandName
      DepartureCityName
      DepartureStopName
      DepartureStopId
      DeparturePlatform
      DeparturePlatformCoordinates {
        Latitude
        Longitude
      }
      DepartureStopCode
      DepartureStopLocation {
        CityName
        CountyName
        CountryName
      }
      PlannedDepartureDateTime
      PlannedDepartureTimezone
      DepartureDatetime
      DepartureTimezone
      DestinationCityName
      DestinationStopName
      DestinationStopId
      DestinationStopCode
      DestinationStopLocation {
        CityName
        CountyName
        CountryName
      }
      PlannedArrivalDateTime
      PlannedArrivalTimezone
      ArrivalDatetime
      ArrivalTimezone
      Duration
      InterchangeDuration
      BusEquipment {
        Code
        DisplayName
      }
      IntermediateStops {
        StopName
        ArrivalDateTime
        ArrivalDateTimeZone
        DepartureDateTime
        DepartureDateTimeZone
        IsRequestPickUpStop
        IsRequestDropOffStop
        StopLocation {
          CityName
          CountyName
          CountryName
        }
      }
    }
  }
`;
