import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Container, Button, LazyLoadImage } from '@lux/ui';
import s from './buy-ticket-promo.module.scss';

const BuyTicketPromo = ({ t, className }) => {
  return (
    <Container className={cn(s.buyTicketPromo, className)}>
      <Container full={false}>
        <section>
          <div className={s.map}>
            <LazyLoadImage src="/images/promo/map.png" alt="" />
          </div>
          <h2 className={s.title}>{t('countries.title')}</h2>
          <p>{t('countries.description')}</p>
          <Button text={t('buy tickets')} to="/" />
        </section>
      </Container>
    </Container>
  );
};

export default withTranslation()(BuyTicketPromo);
