import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { NEO_PAY_DETAILS } from '@lux/spa/apollo/mutations';
import { useMutation } from '@apollo/react-hooks';
import { DEFAULT_LANG } from '@lux/spa/config';
import { StoreContext } from '@lux/spa/store';
import { createThanksSuccessAnaliticsData } from 'utils';
import ThanksLayoutSuccess from '../../thanks-layout-success.jsx';
import ThanksLayoutPending from '../../thanks-layout-pending.jsx';
import ThanksLayoutError from '../../thanks-layout-error.jsx';

const Banks = ({
  i18n,
  history,
  fileLoading,
  getTicketsFileUrl,
  urlParams,
  linkToPDFFile,
}) => {
  const [state, dispatch] = useContext(StoreContext);

  const {
    from,
    to,
    returnDate,
    departDate,
    promocode,
    totalPrice,
    GTM,
  } = state.tickets;
  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';
  const { GTMLoaded } = state.app;
  const { token, data, canceled, pending } = urlParams;
  const [isError, setIsError] = useState(+canceled === 1);
  const [isCountdown, setIsCountdown] = useState(false);
  const [enabledQuering, setEnabledQuering] = useState(true);
  const [isLoading, setIsLoading] = useState(+pending === 1);
  const [neoPayDetails, { data: detailsData, called }] = useMutation(
    NEO_PAY_DETAILS,
    {
      variables: {
        token: token || data,
      },
      onError: () => {
        setIsError(true);
        setIsLoading(false);
      },
      onCompleted: data => {
        const error = data?.neoPayDetails.error;

        if (error) {
          if (error.ErrorCode === 'order_not_paid' && enabledQuering) {
            setIsCountdown(true);
            setIsLoading(true);
          } else {
            setIsCountdown(false);
            setIsError(true);
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
          setIsCountdown(false);

          if (GTMLoaded && !GTM.thanksStep) {
            dataLayer.push({
              ...createThanksSuccessAnaliticsData({
                returnDate,
                departDate,
                from,
                to,
                currencyName,
                totalPrice,
                promocode,
                objects: data?.neoPayDetails?.objects,
              }),
            });

            dispatch({
              type: 'UPDATE_TICKETS',
              payload: {
                GTM: {
                  ...GTM,
                  thanksStep: true,
                },
              },
            });
          }

          getTicketsFileUrl();
        }

        // if (!error) getTicketsFileUrl();
      },
    }
  );

  useEffect(() => {
    if (+canceled !== 1 && !called) {
      neoPayDetails();
    }
  }, [canceled]);

  useEffect(() => {
    const error = detailsData?.neoPayDetails.error;
    let timer;

    if (error) {
      if (error.ErrorCode === 'order_not_paid' && enabledQuering) {
        timer = setTimeout(() => {
          neoPayDetails();
        }, 10000);
      }
    }
    return () => {
      clearTimeout(timer);
    };
  }, [detailsData]);

  useEffect(() => {
    if (isCountdown) {
      setInterval(() => {
        setEnabledQuering(false);
      }, 60000);
    }
  }, [isCountdown]);

  // useEffect(() => {
  //   if (+canceled === 1) {
  //     // history.push('/tickets/payment/banks/');
  //     history.push(
  //       `${
  //         language === DEFAULT_LANG ? '' : `/${language}`
  //       }/tickets/payment/banks/`
  //     );
  //   }
  // }, [canceled]);

  return isLoading ? (
    <ThanksLayoutPending />
  ) : isError ? (
    <ThanksLayoutError renderCancel />
  ) : (
    <ThanksLayoutSuccess
      type="card"
      fileLoading={fileLoading}
      getTicketsFileUrl={getTicketsFileUrl}
      linkToPDFFile={linkToPDFFile}
    />
  );
};

export default withRouter(withTranslation()(Banks));
