export const emptyData = {
  lang: 'en',
  isPublished: true,
  countries: ['ee', 'ru', 'fi', 'lv', 'lt'],
  seo: {
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 80,
    },
    slug: {
      value: '',
      fieldName: 'slug',
      placeholder: 'Slug / URL',
      maxLength: 120,
    },
    thumbnail: {
      value: '',
      fieldName: 'thumbnail',
      placeholder: 'Thumbnail',
      size: {
        height: 300,
        width: 520,
      },
    },
    description: {
      value: '',
      fieldName: 'description',
      placeholder: 'Page description',
      maxLength: 158,
    },
    socialTitle: {
      value: '',
      fieldName: 'socialTitle',
      placeholder: 'Social Title',
      maxLength: 160,
    },
    socialDescription: {
      value: '',
      fieldName: 'socialDescription',
      placeholder: 'Social description',
      maxLength: 160,
    },
    pageKeywords: {
      value: '',
      fieldName: 'pageKeywords',
      placeholder: 'Page keywords',
      maxLength: 40,
    },
    socialImage: '',
    articlePublishedTime: '',
    articleModifiedTime: '',
  },
  pageData: {
    showTaxi: true,
    showSeats: true,
    learnMoreLink: {
      value: '',
      fieldName: 'learnMoreLink',
      placeholder: 'Link',
      maxLength: 300,
    },
    dailyDepartures: {
      value: '',
      fieldName: 'dailyDepartures',
      placeholder: '10',
      maxLength: 100,
    },
    travelTime: {
      value: '',
      fieldName: 'travelTime',
      placeholder: '2 h 30 min',
      maxLength: 100,
    },
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 50,
    },
    subTitle: {
      value: '',
      fieldName: 'subTitle',
      placeholder: 'Page subtitle',
      maxLength: 200,
    },
    heading1: {
      value: '',
      fieldName: 'heading1',
      placeholder: 'Heading',
      maxLength: 100,
    },
    // heading2: {
    //   value: '',
    //   fieldName: 'heading2',
    //   placeholder: 'Heading',
    //   maxLength: 100
    // },
    // heading3: {
    //   value: '',
    //   fieldName: 'heading3',
    //   placeholder: 'Heading',
    //   maxLength: 100
    // },
    heading4: {
      value: '',
      fieldName: 'heading4',
      placeholder: 'Heading',
      maxLength: 100,
    },
    heading5: {
      value: '',
      fieldName: 'heading5',
      placeholder: 'Heading',
      maxLength: 100,
    },
    // text1: {
    //   value: '',
    //   fieldName: 'text1',
    //   placeholder: 'Text',
    //   maxLength: 300
    // },
    // text2: {
    //   value: '',
    //   fieldName: 'text2',
    //   placeholder: 'Text',
    //   maxLength: 1000
    // },
    text3: {
      value: '',
      fieldName: 'text3',
      placeholder: 'Text',
      maxLength: 500,
    },
    transferingEditor:
      '<p>For your own and other passengers safety and comfort only one piece of hand luggage is allowed on board. Larger bags or cases are placed in a luggage compartment of the coach. Luggage is placed in a luggage compartment and handed back to the passengers by the coach crew.</p>',
    taxiEditor: '',
    banner: {
      value: '',
      fieldName: 'banner',
      placeholder: 'Banner',
      size: {
        height: 1100,
        width: 2880,
      },
    },
    transportation: {
      value: 'images/route/hero-2.png',
      fieldName: 'transportation',
      placeholder: 'Transportation',
      size: {
        height: 1100,
        width: 1080,
      },
    },
    taxi: {
      value: '/images/route/hero-3.png',
      fieldName: 'taxi',
      placeholder: 'Taxi',
      size: {
        height: 640,
        width: 1080,
      },
    },
    stops: {
      slides: [],
      fieldName: 'stops',
    },
  },
};

export const defaultData = {
  lang: 'en',
  slug: 'test',
  isPublished: true,
  countries: ['ee', 'ru', 'fi', 'lv', 'lt'],
  seo: {
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 80,
    },
    slug: {
      value: '',
      fieldName: 'slug',
      placeholder: 'Slug / URL',
      maxLength: 120,
    },
    description: {
      value: 'Page description',
      fieldName: 'description',
      placeholder: 'Page description',
      maxLength: 158,
    },
    socialTitle: {
      value: 'Social Title',
      fieldName: 'socialTitle',
      placeholder: 'Social Title',
      maxLength: 160,
    },
    socialDescription: {
      value: 'Social description',
      fieldName: 'socialDescription',
      placeholder: 'Social description',
      maxLength: 160,
    },
    pageKeywords: {
      value: 'Page keywords',
      fieldName: 'pageKeywords',
      placeholder: 'Page keywords',
      maxLength: 40,
    },
    thumbnail: {
      value: '',
      fieldName: 'thumbnail',
      placeholder: 'Thumbnail',
      size: {
        height: 300,
        width: 520,
      },
    },
    socialImage: '',
  },
  pageData: {
    showTaxi: true,
    showSeats: true,
    learnMoreLink: {
      value: '',
      fieldName: 'learnMoreLink',
      placeholder: 'Link',
      maxLength: 300,
    },
    dailyDepartures: {
      value: '',
      fieldName: 'dailyDepartures',
      placeholder: '10',
      maxLength: 100,
    },
    travelTime: {
      value: '',
      fieldName: 'travelTime',
      placeholder: '2 h 30 min',
      maxLength: 100,
    },
    title: {
      value: 'Route [from] [to]',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 50,
    },
    subTitle: {
      value: 'Route sub title',
      fieldName: 'subTitle',
      placeholder: 'Page subtitle',
      maxLength: 200,
    },
    heading1: {
      value: 'Main stops',
      fieldName: 'heading1',
      placeholder: 'Heading',
      maxLength: 100,
    },
    // heading2: {
    //   value: 'Choose your seat option',
    //   fieldName: 'heading2',
    //   placeholder: 'Heading',
    //   maxLength: 100
    // },
    // heading3: {
    //   value: 'Traveling with Lux Express',
    //   fieldName: 'heading3',
    //   placeholder: 'Heading',
    //   maxLength: 100
    // },
    heading4: {
      value: 'Carry everything you need with no additional fee for baggage',
      fieldName: 'heading4',
      placeholder: 'Heading',
      maxLength: 100,
    },
    heading5: {
      value: 'Taxi transferin',
      fieldName: 'heading5',
      placeholder: 'Heading',
      maxLength: 100,
    },
    // text1: {
    //   value: 'In our Lux Lounge buses you can choose between Lounge seat or Express seat',
    //   fieldName: 'text1',
    //   placeholder: 'Text',
    //   maxLength: 300
    // },
    // text2: {
    //   value: 'For your own and other passengers safety and comfort only one piece of hand luggage is allowed on board. Larger bags or cases are placed in a luggage compartment of the coach. Luggage is placed in a luggage compartment and handed back to the passengers by the coach crew.',
    //   fieldName: 'text2',
    //   placeholder: 'Text',
    //   maxLength: 1000
    // },
    text3: {
      value:
        'Save yourself time - book your ride instantly and taxi will be waiting for you upon arrival!',
      fieldName: 'text3',
      placeholder: 'Text',
      maxLength: 100,
    },
    transferingEditor:
      '<p>For your own and other passengers safety and comfort only one piece of hand luggage is allowed on board. Larger bags or cases are placed in a luggage compartment of the coach. Luggage is placed in a luggage compartment and handed back to the passengers by the coach crew.</p>',
    taxiEditor:
      '<p>Riga is divided into three zones and the final price of the service depends on the distance to the destination One voucher is valid for one taxi and up to 4 passengers Taxi voucher can be bought online together with Lux Express bus tickets</p>',
    banner: {
      value: '',
      fieldName: 'banner',
      placeholder: 'Banner',
      size: {
        height: 1100,
        width: 2880,
      },
    },
    transportation: {
      value: '/images/route/hero-2.png',
      fieldName: 'transportation',
      placeholder: 'Transportation',
      size: {
        height: 1100,
        width: 1080,
      },
    },
    taxi: {
      value: '/images/route/hero-3.png',
      fieldName: 'taxi',
      placeholder: 'Taxi',
      size: {
        height: 640,
        width: 1080,
      },
    },
    stops: {
      slides: [],
      fieldName: 'stops',
    },
  },
};
