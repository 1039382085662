import React from 'react';
import { withTranslation } from 'react-i18next';
import s from './faq-search.module.scss';

const FaqSearchResultsItem = props => {
  const { id, originalTitle, title, sectionName, sectionId, i18n } = props;
  const { language } = i18n;
  return (
    <li className={s.item} key={id}>
      <a
        className={s.link}
        href={`/${language}/faq/${sectionId}-${sectionName}/${id}-${originalTitle}`}
        aria-label="link"
        dangerouslySetInnerHTML={{
          __html: `${title}<span> - ${sectionName}</span>`,
        }}
      />
    </li>
  );
};

export default withTranslation()(FaqSearchResultsItem);
