import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { StoreContext } from '@lux/spa/store';
import { Modal, ModalContent, Button } from '@lux/ui';
import s from './information.module.scss';

const Information = ({ t, isOpen, toggleModal }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { isSomethingWrongMessage, isSomethingWrongTitle } = state.tickets;
  return (
    <Modal className={s.modal} {...{ isOpen, toggleModal }}>
      <ModalContent>
        <div className={s.contols}>
          <img src="/images/svg/tickets-not-found.svg" alt="" />
          <h1>{isSomethingWrongTitle}</h1>
          <p>{isSomethingWrongMessage}</p>
          <Button text={t('close')} onClick={toggleModal} />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default withTranslation()(Information);
