import React, { PureComponent } from 'react';

import { ReactComponent as WarningIcon } from '@lux/spa/assets/icons/warning.svg';

import Styles from './ErrorBoundary.module.scss';

interface ErrorBoundaryProps {
  chidlren: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends PureComponent<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = {
    hasError: false,
  };

  componentDidCatch() {
    this.setState({
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        // eslint-disable-next-line react/jsx-filename-extension
        <div className={Styles.errorBoundary}>
          <WarningIcon className={Styles.icon} />
          <h2>Oops something went wrong...</h2>
        </div>
      );
    }

    return this.props.children;
  }
}
