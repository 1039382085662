import React from 'react';
import { withTranslation } from 'react-i18next';
import FaqSearchResultsItem from '@lux/spa/routes/faq/components/faq-search-results-item';
import s from './faq-search.module.scss';

const FaqSearchResults = ({ t, data }) => (
  <ul className={s.list}>
    {data.map(item => (
      <FaqSearchResultsItem {...item} />
    ))}
  </ul>
);

export default withTranslation()(FaqSearchResults);
