import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Link } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from '../search.module.scss';

const SearchResultsItem = props => {
  const { id, title, sectionName, sectionId, i18n } = props;
  const { language } = i18n;
  return (
    <li className={s.item} key={id}>
      <Link
        to={`/${language}/faq/${sectionId}-${sectionName}/${id}-${title}/`}
        className={s.link}
      >
        <span className={s.category_name}>
          {sectionName} <i className={cn(i.arrowRight_v2, s.icon)} />
        </span>
        <span className={s.article_name}>{title}</span>
      </Link>
    </li>
  );
};

export default withTranslation()(SearchResultsItem);
