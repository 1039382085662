import React, { useState, useContext } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { StoreContext } from '@lux/spa/store';
import { useMutation } from '@apollo/react-hooks';
import { SIGN_IN } from '@lux/spa/apollo/mutations';
import {
  Container,
  Button,
  TicketsInput,
  HiddenInput,
  Link,
  ForgotPassword,
  Notice,
} from '@lux/ui';
import { validToUseExponea } from '@lux/spa/config';
import s from './login.module.scss';

const Login = ({ t, i18n, className, history }) => {
  const { language } = i18n;
  const [state, dispatch] = useContext(StoreContext);
  const [isOpen, setIsOpen] = useState(false);
  const [signIn, { loading, data }] = useMutation(SIGN_IN);

  return (
    <div className={cn(className, s.login)}>
      <ForgotPassword
        toggleModal={() => setIsOpen(isOpen => !isOpen)}
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
      />
      <Container>
        <Container full={false}>
          <section>
            <div className={s.image}>
              <img src="/images/svg/registration.svg" alt="" />
            </div>
            <Formik
              validateOnMount
              enableReinitialize
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email(
                    t('validation.error.email', {
                      field: t('email'),
                    })
                  )
                  .required(
                    t('validation.error.require', {
                      field: t('email'),
                    })
                  ),
                password: Yup.string()
                  .required(
                    t('validation.error.require', {
                      field: t('password'),
                    })
                  )
                  .min(
                    6,
                    t('validation.error.min', {
                      field: t('password'),
                      min: 6,
                    })
                  ),
              })}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  setFieldError,
                } = props;
                return (
                  <form className={s.form}>
                    <h2 className={s.title}>{t('login')}</h2>
                    <h5>{t('enter your email and password')}</h5>
                    <p className={s.text}>
                      {t('don’t have an account?')}
                      <Link className={s.link} to="/registration/">
                        {t('register')}
                      </Link>
                    </p>
                    <div className={s.input}>
                      <TicketsInput
                        autoFocus
                        name="email"
                        label={t('email')}
                        type="text"
                        required
                        value={values.email}
                        onChange={e => {
                          e.target.value = e.target.value.trim();
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        errors={touched.email && errors.email}
                      />
                    </div>
                    <div className={s.input}>
                      <div
                        className={s.forgotPassword}
                        onClick={() => setIsOpen(true)}
                      >
                        {t('forgot password?')}
                      </div>
                      <HiddenInput
                        name="password"
                        placeholder="••••••"
                        label={t('password')}
                        required
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={touched.password && errors.password}
                      />
                    </div>
                    {data?.signIn?.error?.ErrorReason && (
                      <Notice
                        color="red"
                        text={data?.signIn?.error?.ErrorReason}
                        className={s.error}
                      />
                    )}
                    <Button
                      disabled={loading || errors.email || errors.password}
                      className={s.button}
                      text={loading ? t('loading') : t('login button')}
                      onClick={() => {
                        signIn({
                          variables: {
                            email: values.email,
                            password: values.password,
                          },
                        }).then(data => {
                          // if (data?.data?.signIn?.error) {
                          //   setFieldError(
                          //     'password',
                          //     data?.data?.signIn?.error.ErrorReason
                          //   );
                          //   return;
                          // }

                          const token = data?.data?.signIn?.token;
                          const profile = data?.data?.signIn?.profile;

                          if (token) {
                            localStorage.removeItem('token', token);
                            localStorage.setItem('token', token);

                            if (validToUseExponea()) {
                              exponea.identify({ registered: profile.email });
                            }

                            dispatch({
                              type: 'UPDATE_USER',
                              payload: {
                                ...profile,
                              },
                            });

                            history.push(`/${language}/`);
                          }
                        });
                      }}
                    />
                  </form>
                );
              }}
            </Formik>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withRouter(withTranslation()(Login));
