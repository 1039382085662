import React, { useState, useContext } from 'react';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import { Collapse } from 'react-collapse';
import { StoreContext } from '@lux/spa/store';
import i from '@lux/spa/styles/icons.module.scss';
import Passenger from '../passenger';
import styles from './transfer.module.scss';

const Transfer = ({
  t,
  i18n,
  leg,
  legsCount,
  orderNumber,
  isTransfer,
  active,
  products,
  canChooseSeatNumber,
}) => {
  const { language } = i18n;
  const [state, dispatch] = useContext(StoreContext);

  const { activeBus, selectedSeats, details } = state.changeTickets;

  const [isOpen, setIsOpen] = useState(active);

  const toggleOpen = orderNumber => {
    if (orderNumber === activeBus) {
      setIsOpen(!isOpen);
    } else {
      dispatch({
        type: 'UPDATE_CHANGE_TICKETS',
        payload: {
          activeBus: orderNumber,
          activePassenger: 1,
        },
      });
    }
  };

  const classes = cx(
    styles.fromTo,
    isTransfer && styles.transfer,
    active && styles.open
  );

  return (
    <div className={classes}>
      <div className={styles.heading} onClick={() => toggleOpen(orderNumber)}>
        <div>
          {details?.Ways1?.[0]?.Legs?.[orderNumber - 1]?.DepartureStopName}
        </div>
        <i className={i.arrowRight_v3} />
        <div>
          {details?.Ways?.[0]?.Legs?.[orderNumber - 1]?.DestinationStopName}
        </div>
        <i className={cx(i.edit, styles.edit)} />
      </div>
      {isTransfer ? (
        <Collapse isOpened={active}>
          <div className={styles.selectSeats}>
            {products.map((products, key) => (
              <Passenger
                number={key + 1}
                key={key}
                leg={leg}
                legsCount={legsCount}
                orderNumber={orderNumber}
                product={products}
                language={language}
                seatNumber={
                  selectedSeats?.[`bus${activeBus}`]?.[products.ProductCode]
                }
                canChooseSeatNumber={canChooseSeatNumber}
              />
            ))}
          </div>
        </Collapse>
      ) : (
        <div className={styles.selectSeats}>
          {products.map((products, key) => (
            <Passenger
              number={key + 1}
              key={key}
              leg={leg}
              legsCount={legsCount}
              orderNumber={orderNumber}
              product={products}
              language={language}
              seatNumber={
                selectedSeats?.[`bus${activeBus}`]?.[products.ProductCode]
              }
              canChooseSeatNumber={canChooseSeatNumber}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(Transfer);
