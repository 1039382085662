import React from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { addBlankAttrToHtml } from '@lux/spa/utils';
import { useQuery } from '@apollo/react-hooks';
import { GET_FAQ_ARTICLE } from '@lux/spa/apollo/queries';
import { NotFound } from '@lux/spa/routes';
import { Container, PageLoading } from '@lux/ui';
import { meta } from '@lux/spa/components';
import s from './customs-regulations.module.scss';

const CustomsRegulations = ({ t, i18n, className }) => {
  const { language } = i18n;
  const { data = {}, loading } = useQuery(GET_FAQ_ARTICLE, {
    variables: {
      lang: language,
      id: 360013870399,
    },
  });

  const { article } = data;

  const seo = {
    title: {
      value: article?.title ? article?.title : '',
    },
    description: '',
    pageKeywords: '',
    socialTitle: '',
    slug: '',
    socialDescription: '',
    articlePublishedTime: article?.createdAt ? article?.createdAt : '',
    articleModifiedTime: article?.editedAt ? article?.editedAt : '',
  };

  return loading ? (
    <PageLoading />
  ) : article ? (
    <div className={cn(className, s.customsRegulations)}>
      <Helmet {...meta({ seo, lang: language })} />
      <Container>
        <div className={s.greyBlock} />
        <Container full={false}>
          <section>
            <h1 className={s.title}>{article.title}</h1>
            {article.body && (
              <div
                className={s.article}
                dangerouslySetInnerHTML={{
                  __html: addBlankAttrToHtml(article.body),
                }}
              />
            )}
          </section>
        </Container>
      </Container>
    </div>
  ) : (
    <NotFound />
  );
};

export default withTranslation()(CustomsRegulations);
