import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';

import { Button } from '@lux/ui';
import s from './empty-slider.module.scss';

const EmptySlider = ({ t, className, toggleEditSlider, text }) => {
  return (
    <div className={cn(s.emptySlider, className)}>
      <div className={s.title}>{text || t('slider')}</div>
      <Button text={t('edit slider')} onClick={toggleEditSlider} />
    </div>
  );
};

export default withTranslation()(EmptySlider);
