import React from 'react';
import { withTranslation } from 'react-i18next';
import { LazyLoadImage } from '@lux/ui';
import s from './features.module.scss';

const Features = ({ t }) => {
  return (
    <div className={s.features}>
      <div className={s.item}>
        <div className={s.circle}>
          <LazyLoadImage src="/images/svg/sanitizer.svg" alt="" />
        </div>
        <span>{t('hand sanitizers')}</span>
      </div>
      <div className={s.item}>
        <div className={s.circle}>
          <LazyLoadImage src="/images/svg/wi-fi.svg" alt="" />
        </div>
        <span>{t('free wifi')}</span>
      </div>
      <div className={s.item}>
        <div className={s.circle}>
          <LazyLoadImage src="/images/svg/e-ticket.svg" alt="" />
        </div>
        <span>{t('e-ticket')}</span>
      </div>
      <div className={s.item}>
        <div className={s.circle}>
          <LazyLoadImage src="/images/svg/seatbelt.svg" alt="" />
        </div>
        <span>{t('seatbelt')}</span>
      </div>
      <div className={s.item}>
        <div className={s.circle}>
          <LazyLoadImage src="/images/svg/free-mask.svg" alt="" />
        </div>
        <span>{t('free mask')}</span>
      </div>
      <div className={s.item}>
        <div className={s.circle}>
          <LazyLoadImage src="/images/svg/wc.svg" alt="" />
        </div>
        <span>{t('restroom')}</span>
      </div>
    </div>
  );
};

export default withTranslation()(Features);
