import gql from 'graphql-tag';
import { FRAGMENT_LEGS } from '../gql-fragments';

export const JOURNEY_ROUTE = gql`
  query(
    $journeyId: String!
    $includeIntermediateStops: Boolean
    $lang: String
  ) {
    journeyRoute(
      journeyId: $journeyId
      lang: $lang
      includeIntermediateStops: $includeIntermediateStops
    ) {
      ...ContentLegsFields
    }
  }
  ${FRAGMENT_LEGS}
`;
