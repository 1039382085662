export const emptyData = {
  lang: 'en',
  isPublished: true,
  countries: ['ee'],
  seo: {
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 80,
    },
    slug: {
      value: '',
      fieldName: 'slug',
      placeholder: 'Slug / URL',
      maxLength: 120,
    },
    description: {
      value: '',
      fieldName: 'description',
      placeholder: 'Page description',
      maxLength: 158,
    },
    socialTitle: {
      value: '',
      fieldName: 'socialTitle',
      placeholder: 'Social Title',
      maxLength: 160,
    },
    socialDescription: {
      value: '',
      fieldName: 'socialDescription',
      placeholder: 'Social description',
      maxLength: 160,
    },
    pageKeywords: {
      value: '',
      fieldName: 'pageKeywords',
      placeholder: 'Page keywords',
      maxLength: 40,
    },
    socialImage: '',
    articlePublishedTime: '',
    articleModifiedTime: '',
  },
  pageData: {
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 50,
    },
    subTitle: {
      value: '',
      fieldName: 'subTitle',
      placeholder: 'Page subtitle',
      maxLength: 200,
    },
    heading1: {
      value: '',
      fieldName: 'heading1',
      placeholder: 'Heading',
      maxLength: 100,
    },
    heading2: {
      value: '',
      fieldName: 'heading2',
      placeholder: 'Heading',
      maxLength: 100,
    },
    heading3: {
      value: '',
      fieldName: 'heading3',
      placeholder: 'Heading',
      maxLength: 100,
    },
    heading4: {
      value: '',
      fieldName: 'heading4',
      placeholder: 'Heading',
      maxLength: 100,
    },
    heading5: {
      value: '',
      fieldName: 'heading5',
      placeholder: 'Heading',
      maxLength: 100,
    },
    text1: {
      value: '',
      fieldName: 'text1',
      placeholder: 'Text',
      maxLength: 300,
    },
    banner: {
      value: '',
      fieldName: 'banner',
      placeholder: 'Banner',
      size: {
        height: 1100,
        width: 2880,
      },
    },
    peoples: {
      fieldName: 'peoples',
      slides: [],
    },
    lounge: {
      fieldName: 'lounge',
      slides: [],
    },
  },
};
export const defaultData = {
  lang: 'en',
  isPublished: true,
  countries: ['ee'],
  seo: {
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 80,
    },
    slug: {
      value: '',
      fieldName: 'slug',
      placeholder: 'Slug / URL',
      maxLength: 120,
    },
    description: {
      value: '',
      fieldName: 'description',
      placeholder: 'Page description',
      maxLength: 158,
    },
    socialTitle: {
      value: '',
      fieldName: 'socialTitle',
      placeholder: 'Social Title',
      maxLength: 160,
    },
    socialDescription: {
      value: '',
      fieldName: 'socialDescription',
      placeholder: 'Social description',
      maxLength: 160,
    },
    pageKeywords: {
      value: '',
      fieldName: 'pageKeywords',
      placeholder: 'Page keywords',
      maxLength: 40,
    },
    socialImage: '',
    articlePublishedTime: '',
    articleModifiedTime: '',
  },
  pageData: {
    title: {
      value: 'Page title',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 50,
    },
    subTitle: {
      value:
        'The pearl of the Baltics is just a few hours away. Relax comfortably in a Lux Express bus and be amazed by what Riga has to offer.',
      fieldName: 'subTitle',
      placeholder: 'Page subtitle',
      maxLength: 200,
    },
    banner: {
      value: '',
      fieldName: 'banner',
      placeholder: 'Banner',
      size: {
        height: 1100,
        width: 2880,
      },
    },
    peoples: {
      fieldName: 'peoples',
      slides: [],
    },
    lounge: {
      fieldName: 'lounge',
      slides: [],
    },
  },
};
