export const emptyData = {
  lang: 'en',
  isPublished: true,
  countries: ['ee', 'ru', 'fi', 'lv', 'lt'],
  seo: {
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 80,
    },
    slug: {
      value: '',
      fieldName: 'slug',
      placeholder: 'Slug / URL',
      maxLength: 120,
    },
    thumbnail: {
      value: '',
      fieldName: 'thumbnail',
      placeholder: 'Thumbnail',
      size: {
        height: 250,
        width: 250,
      },
    },
    description: {
      value: '',
      fieldName: 'description',
      placeholder: 'Page description',
      maxLength: 158,
    },
    socialTitle: {
      value: '',
      fieldName: 'socialTitle',
      placeholder: 'Social Title',
      maxLength: 160,
    },
    socialDescription: {
      value: '',
      fieldName: 'socialDescription',
      placeholder: 'Social description',
      maxLength: 160,
    },
    pageKeywords: {
      value: '',
      fieldName: 'pageKeywords',
      placeholder: 'Page keywords',
      maxLength: 40,
    },
    socialImage: '',
    articlePublishedTime: '',
    articleModifiedTime: '',
  },
  pageData: {
    banner: {
      value: '',
      fieldName: 'banner',
      placeholder: 'Banner',
      size: {
        height: 1100,
        width: 2880,
      },
    },
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 50,
    },
    ee_heading: {
      value: '',
      fieldName: 'ee_heading',
      placeholder: 'Heading',
      maxLength: 100,
    },
    ru_heading: {
      value: '',
      fieldName: 'ru_heading',
      placeholder: 'Heading',
      maxLength: 100,
    },
    lt__heading: {
      value: '',
      fieldName: 'lt__heading',
      placeholder: 'Heading',
      maxLength: 100,
    },
    lv_heading: {
      value: '',
      fieldName: 'lv_heading',
      placeholder: 'Heading',
      maxLength: 100,
    },
    fi_heading: {
      value: '',
      fieldName: 'fi_heading',
      placeholder: 'Heading',
      maxLength: 100,
    },
    by_heading: {
      value: '',
      fieldName: 'by_heading',
      placeholder: 'Heading',
      maxLength: 100,
    },
    ee_text1: {
      value: '',
      fieldName: 'ee_text1',
      placeholder: 'Text',
      maxLength: 100,
    },
    ru_text1: {
      value: '',
      fieldName: 'ru_text1',
      placeholder: 'Text',
      maxLength: 100,
    },
    lt_text1: {
      value: '',
      fieldName: 'lt_text1',
      placeholder: 'Text',
      maxLength: 100,
    },
    lv_text1: {
      value: '',
      fieldName: 'lv_text1',
      placeholder: 'Text',
      maxLength: 100,
    },
    fi_text1: {
      value: '',
      fieldName: 'fi_text1',
      placeholder: 'Text',
      maxLength: 100,
    },
    by_text1: {
      value: '',
      fieldName: 'by_text1',
      placeholder: 'Text',
      maxLength: 100,
    },
    ee_link1: {
      value: '',
      fieldName: 'ee_link1',
      placeholder: 'Link',
      maxLength: 100,
    },
    ee_link2: {
      value: '',
      fieldName: 'ee_link2',
      placeholder: 'Link',
      maxLength: 100,
    },
    ee_link3: {
      value: '',
      fieldName: 'ee_link3',
      placeholder: 'Link',
      maxLength: 100,
    },
    ee_link4: {
      value: '',
      fieldName: 'ee_link4',
      placeholder: 'Link',
      maxLength: 100,
    },
    ee_link5: {
      value: '',
      fieldName: 'ee_link5',
      placeholder: 'Link',
      maxLength: 100,
    },
    ee_link6: {
      value: '',
      fieldName: 'ee_link6',
      placeholder: 'Link',
      maxLength: 100,
    },
    ru_link1: {
      value: '',
      fieldName: 'ru_link1',
      placeholder: 'Link',
      maxLength: 100,
    },
    ru_link2: {
      value: '',
      fieldName: 'ru_link2',
      placeholder: 'Link',
      maxLength: 100,
    },
    ru_link3: {
      value: '',
      fieldName: 'ru_link3',
      placeholder: 'Link',
      maxLength: 100,
    },
    ru_link4: {
      value: '',
      fieldName: 'ru_link4',
      placeholder: 'Link',
      maxLength: 100,
    },
    ru_link5: {
      value: '',
      fieldName: 'ru_link5',
      placeholder: 'Link',
      maxLength: 100,
    },
    ru_link6: {
      value: '',
      fieldName: 'ru_link6',
      placeholder: 'Link',
      maxLength: 100,
    },
    lt_link1: {
      value: '',
      fieldName: 'lt_link1',
      placeholder: 'Link',
      maxLength: 100,
    },
    lt_link2: {
      value: '',
      fieldName: 'lt_link2',
      placeholder: 'Link',
      maxLength: 100,
    },
    lt_link3: {
      value: '',
      fieldName: 'lt_link3',
      placeholder: 'Link',
      maxLength: 100,
    },
    lt_link4: {
      value: '',
      fieldName: 'lt_link4',
      placeholder: 'Link',
      maxLength: 100,
    },
    lt_link5: {
      value: '',
      fieldName: 'lt_link5',
      placeholder: 'Link',
      maxLength: 100,
    },
    lt_link6: {
      value: '',
      fieldName: 'lt_link6',
      placeholder: 'Link',
      maxLength: 100,
    },
    lv_link1: {
      value: '',
      fieldName: 'lv_link1',
      placeholder: 'Link',
      maxLength: 100,
    },
    lv_link2: {
      value: '',
      fieldName: 'lv_link2',
      placeholder: 'Link',
      maxLength: 100,
    },
    lv_link3: {
      value: '',
      fieldName: 'lv_link3',
      placeholder: 'Link',
      maxLength: 100,
    },
    lv_link4: {
      value: '',
      fieldName: 'lv_link4',
      placeholder: 'Link',
      maxLength: 100,
    },
    lv_link5: {
      value: '',
      fieldName: 'lv_link5',
      placeholder: 'Link',
      maxLength: 100,
    },
    lv_link6: {
      value: '',
      fieldName: 'lv_link6',
      placeholder: 'Link',
      maxLength: 100,
    },
    fi_link1: {
      value: '',
      fieldName: 'fi_link1',
      placeholder: 'Link',
      maxLength: 100,
    },
    fi_link2: {
      value: '',
      fieldName: 'fi_link2',
      placeholder: 'Link',
      maxLength: 100,
    },
    fi_link3: {
      value: '',
      fieldName: 'fi_link3',
      placeholder: 'Link',
      maxLength: 100,
    },
    fi_link4: {
      value: '',
      fieldName: 'fi_link4',
      placeholder: 'Link',
      maxLength: 100,
    },
    fi_link5: {
      value: '',
      fieldName: 'fi_link5',
      placeholder: 'Link',
      maxLength: 100,
    },
    fi_link6: {
      value: '',
      fieldName: 'fi_link6',
      placeholder: 'Link',
      maxLength: 100,
    },
    by_link1: {
      value: '',
      fieldName: 'by_link1',
      placeholder: 'Link',
      maxLength: 100,
    },
    by_link2: {
      value: '',
      fieldName: 'by_link2',
      placeholder: 'Link',
      maxLength: 100,
    },
    by_link3: {
      value: '',
      fieldName: 'by_link3',
      placeholder: 'Link',
      maxLength: 100,
    },
    by_link4: {
      value: '',
      fieldName: 'by_link4',
      placeholder: 'Link',
      maxLength: 100,
    },
    by_link5: {
      value: '',
      fieldName: 'by_link5',
      placeholder: 'Link',
      maxLength: 100,
    },
    by_link6: {
      value: '',
      fieldName: 'by_link6',
      placeholder: 'Link',
      maxLength: 100,
    },
    ee_phone: {
      value: '',
      fieldName: 'ee_phone',
      placeholder: 'Phone',
      maxLength: 100,
    },
    ru_phone: {
      value: '',
      fieldName: 'ru_phone',
      placeholder: 'Phone',
      maxLength: 100,
    },
    lt_phone: {
      value: '',
      fieldName: 'lt_phone',
      placeholder: 'Phone',
      maxLength: 100,
    },
    lv_phone: {
      value: '',
      fieldName: 'lv_phone',
      placeholder: 'Phone',
      maxLength: 100,
    },
    fi_phone: {
      value: '',
      fieldName: 'fi_phone',
      placeholder: 'Phone',
      maxLength: 100,
    },
    by_phone: {
      value: '',
      fieldName: 'by_phone',
      placeholder: 'Phone',
      maxLength: 100,
    },
    ee_email: {
      value: '',
      fieldName: 'ee_email',
      placeholder: 'E-mail',
      maxLength: 100,
    },
    ru_email: {
      value: '',
      fieldName: 'ru_email',
      placeholder: 'E-mail',
      maxLength: 100,
    },
    lt_email: {
      value: '',
      fieldName: 'lt_email',
      placeholder: 'E-mail',
      maxLength: 100,
    },
    lv_email: {
      value: '',
      fieldName: 'lv_email',
      placeholder: 'E-mail',
      maxLength: 100,
    },
    fi_email: {
      value: '',
      fieldName: 'fi_email',
      placeholder: 'E-mail',
      maxLength: 100,
    },
    by_email: {
      value: '',
      fieldName: 'by_email',
      placeholder: 'E-mail',
      maxLength: 100,
    },
    ee_editor: '',
    ru_editor: '',
    lt_editor: '',
    lv_editor: '',
    fi_editor: '',
    by_editor: '',
    eeEditorTooltip: '',
    ruEditorTooltip: '',
    ltEditorTooltip: '',
    lvEditorTooltip: '',
    fiEditorTooltip: '',
    byEditorTooltip: '',
    // ee_text2: {
    //   value: '',
    //   fieldName: 'ee_text2',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // ru_text2: {
    //   value: '',
    //   fieldName: 'ru_text2',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // lt_text2: {
    //   value: '',
    //   fieldName: 'lt_text2',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // lv_text2: {
    //   value: '',
    //   fieldName: 'lv_text2',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // fi_text2: {
    //   value: '',
    //   fieldName: 'fi_text2',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // ee_text3: {
    //   value: '',
    //   fieldName: 'ee_text3',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // ru_text3: {
    //   value: '',
    //   fieldName: 'ru_text3',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // lt_text3: {
    //   value: '',
    //   fieldName: 'lt_text3',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // lv_text3: {
    //   value: '',
    //   fieldName: 'lv_text3',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // fi_text3: {
    //   value: '',
    //   fieldName: 'fi_text3',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // ee_text4: {
    //   value: '',
    //   fieldName: 'ee_text4',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // ru_text4: {
    //   value: '',
    //   fieldName: 'ru_text4',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // lt_text4: {
    //   value: '',
    //   fieldName: 'lt_text4',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // lv_text4: {
    //   value: '',
    //   fieldName: 'lv_text4',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // fi_text4: {
    //   value: '',
    //   fieldName: 'fi_text4',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
  },
};

export const defaultData = {
  lang: 'en',
  isPublished: true,
  countries: ['ee', 'ru', 'fi', 'lv', 'lt'],
  seo: {
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 80,
    },
    slug: {
      value: '',
      fieldName: 'slug',
      placeholder: 'Slug / URL',
      maxLength: 120,
    },
    description: {
      value: 'Page description',
      fieldName: 'description',
      placeholder: 'Page description',
      maxLength: 158,
    },
    socialTitle: {
      value: 'Social Title',
      fieldName: 'socialTitle',
      placeholder: 'Social Title',
      maxLength: 160,
    },
    socialDescription: {
      value: 'Social description',
      fieldName: 'socialDescription',
      placeholder: 'Social description',
      maxLength: 160,
    },
    pageKeywords: {
      value: 'Page keywords',
      fieldName: 'pageKeywords',
      placeholder: 'Page keywords',
      maxLength: 40,
    },
    thumbnail: {
      value: '',
      fieldName: 'thumbnail',
      placeholder: 'Thumbnail',
      size: {
        height: 250,
        width: 250,
      },
    },
    socialImage: '',
  },
  pageData: {
    banner: {
      value: '',
      fieldName: 'banner',
      placeholder: 'Banner',
      size: {
        height: 1100,
        width: 2880,
      },
    },
    title: {
      value: '',
      fieldName: 'title',
      placeholder: 'Page title',
      maxLength: 50,
    },
    ee_heading: {
      value: '',
      fieldName: 'ee_heading',
      placeholder: 'Heading',
      maxLength: 100,
    },
    ru_heading: {
      value: '',
      fieldName: 'ru_heading',
      placeholder: 'Heading',
      maxLength: 100,
    },
    lt_heading: {
      value: '',
      fieldName: 'lt_heading',
      placeholder: 'Heading',
      maxLength: 100,
    },
    lv_heading: {
      value: '',
      fieldName: 'lv_heading',
      placeholder: 'Heading',
      maxLength: 100,
    },
    fi_heading: {
      value: '',
      fieldName: 'fi_heading',
      placeholder: 'Heading',
      maxLength: 100,
    },
    by_heading: {
      value: '',
      fieldName: 'by_heading',
      placeholder: 'Heading',
      maxLength: 100,
    },
    ee_text1: {
      value: '',
      fieldName: 'ee_text1',
      placeholder: 'Text',
      maxLength: 100,
    },
    ru_text1: {
      value: '',
      fieldName: 'ru_text1',
      placeholder: 'Text',
      maxLength: 100,
    },
    lt_text1: {
      value: '',
      fieldName: 'lt_text1',
      placeholder: 'Text',
      maxLength: 100,
    },
    lv_text1: {
      value: '',
      fieldName: 'lv_text1',
      placeholder: 'Text',
      maxLength: 100,
    },
    fi_text1: {
      value: '',
      fieldName: 'fi_text1',
      placeholder: 'Text',
      maxLength: 100,
    },
    by_text1: {
      value: '',
      fieldName: 'by_text1',
      placeholder: 'Text',
      maxLength: 100,
    },
    ee_link1: {
      value: '',
      fieldName: 'ee_link1',
      placeholder: 'Link',
      maxLength: 100,
    },
    ee_link2: {
      value: '',
      fieldName: 'ee_link2',
      placeholder: 'Link',
      maxLength: 100,
    },
    ee_link3: {
      value: '',
      fieldName: 'ee_link3',
      placeholder: 'Link',
      maxLength: 100,
    },
    ee_link4: {
      value: '',
      fieldName: 'ee_link4',
      placeholder: 'Link',
      maxLength: 100,
    },
    ee_link5: {
      value: '',
      fieldName: 'ee_link5',
      placeholder: 'Link',
      maxLength: 100,
    },
    ee_link6: {
      value: '',
      fieldName: 'ee_link6',
      placeholder: 'Link',
      maxLength: 100,
    },
    ru_link1: {
      value: '',
      fieldName: 'ru_link1',
      placeholder: 'Link',
      maxLength: 100,
    },
    ru_link2: {
      value: '',
      fieldName: 'ru_link2',
      placeholder: 'Link',
      maxLength: 100,
    },
    ru_link3: {
      value: '',
      fieldName: 'ru_link3',
      placeholder: 'Link',
      maxLength: 100,
    },
    ru_link4: {
      value: '',
      fieldName: 'ru_link4',
      placeholder: 'Link',
      maxLength: 100,
    },
    ru_link5: {
      value: '',
      fieldName: 'ru_link5',
      placeholder: 'Link',
      maxLength: 100,
    },
    ru_link6: {
      value: '',
      fieldName: 'ru_link6',
      placeholder: 'Link',
      maxLength: 100,
    },
    lt_link1: {
      value: '',
      fieldName: 'lt_link1',
      placeholder: 'Link',
      maxLength: 100,
    },
    lt_link2: {
      value: '',
      fieldName: 'lt_link2',
      placeholder: 'Link',
      maxLength: 100,
    },
    lt_link3: {
      value: '',
      fieldName: 'lt_link3',
      placeholder: 'Link',
      maxLength: 100,
    },
    lt_link4: {
      value: '',
      fieldName: 'lt_link4',
      placeholder: 'Link',
      maxLength: 100,
    },
    lt_link5: {
      value: '',
      fieldName: 'lt_link5',
      placeholder: 'Link',
      maxLength: 100,
    },
    lt_link6: {
      value: '',
      fieldName: 'lt_link6',
      placeholder: 'Link',
      maxLength: 100,
    },
    lv_link1: {
      value: '',
      fieldName: 'lv_link1',
      placeholder: 'Link',
      maxLength: 100,
    },
    lv_link2: {
      value: '',
      fieldName: 'lv_link2',
      placeholder: 'Link',
      maxLength: 100,
    },
    lv_link3: {
      value: '',
      fieldName: 'lv_link3',
      placeholder: 'Link',
      maxLength: 100,
    },
    lv_link4: {
      value: '',
      fieldName: 'lv_link4',
      placeholder: 'Link',
      maxLength: 100,
    },
    lv_link5: {
      value: '',
      fieldName: 'lv_link5',
      placeholder: 'Link',
      maxLength: 100,
    },
    lv_link6: {
      value: '',
      fieldName: 'lv_link6',
      placeholder: 'Link',
      maxLength: 100,
    },
    fi_link1: {
      value: '',
      fieldName: 'fi_link1',
      placeholder: 'Link',
      maxLength: 100,
    },
    fi_link2: {
      value: '',
      fieldName: 'fi_link2',
      placeholder: 'Link',
      maxLength: 100,
    },
    fi_link3: {
      value: '',
      fieldName: 'fi_link3',
      placeholder: 'Link',
      maxLength: 100,
    },
    fi_link4: {
      value: '',
      fieldName: 'fi_link4',
      placeholder: 'Link',
      maxLength: 100,
    },
    fi_link5: {
      value: '',
      fieldName: 'fi_link5',
      placeholder: 'Link',
      maxLength: 100,
    },
    fi_link6: {
      value: '',
      fieldName: 'fi_link6',
      placeholder: 'Link',
      maxLength: 100,
    },
    by_link1: {
      value: '',
      fieldName: 'by_link1',
      placeholder: 'Link',
      maxLength: 100,
    },
    by_link2: {
      value: '',
      fieldName: 'by_link2',
      placeholder: 'Link',
      maxLength: 100,
    },
    by_link3: {
      value: '',
      fieldName: 'by_link3',
      placeholder: 'Link',
      maxLength: 100,
    },
    by_link4: {
      value: '',
      fieldName: 'by_link4',
      placeholder: 'Link',
      maxLength: 100,
    },
    by_link5: {
      value: '',
      fieldName: 'by_link5',
      placeholder: 'Link',
      maxLength: 100,
    },
    by_link6: {
      value: '',
      fieldName: 'by_link6',
      placeholder: 'Link',
      maxLength: 100,
    },
    ee_phone: {
      value: '',
      fieldName: 'ee_phone',
      placeholder: 'Phone',
      maxLength: 100,
    },
    ru_phone: {
      value: '',
      fieldName: 'ru_phone',
      placeholder: 'Phone',
      maxLength: 100,
    },
    lt_phone: {
      value: '',
      fieldName: 'lt_phone',
      placeholder: 'Phone',
      maxLength: 100,
    },
    lv_phone: {
      value: '',
      fieldName: 'lv_phone',
      placeholder: 'Phone',
      maxLength: 100,
    },
    fi_phone: {
      value: '',
      fieldName: 'fi_phone',
      placeholder: 'Phone',
      maxLength: 100,
    },
    by_phone: {
      value: '',
      fieldName: 'by_phone',
      placeholder: 'Phone',
      maxLength: 100,
    },
    ee_email: {
      value: '',
      fieldName: 'ee_email',
      placeholder: 'E-mail',
      maxLength: 100,
    },
    ru_email: {
      value: '',
      fieldName: 'ru_email',
      placeholder: 'E-mail',
      maxLength: 100,
    },
    lt_email: {
      value: '',
      fieldName: 'lt_email',
      placeholder: 'E-mail',
      maxLength: 100,
    },
    lv_email: {
      value: '',
      fieldName: 'lv_email',
      placeholder: 'E-mail',
      maxLength: 100,
    },
    fi_email: {
      value: '',
      fieldName: 'fi_email',
      placeholder: 'E-mail',
      maxLength: 100,
    },
    by_email: {
      value: '',
      fieldName: 'by_email',
      placeholder: 'E-mail',
      maxLength: 100,
    },
    ee_editor: '',
    ru_editor: '',
    lt_editor: '',
    lv_editor: '',
    fi_editor: '',
    by_editor: '',
    // ee_text2: {
    //   value: '',
    //   fieldName: 'ee_text2',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // ru_text2: {
    //   value: '',
    //   fieldName: 'ru_text2',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // lt_text2: {
    //   value: '',
    //   fieldName: 'lt_text2',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // lv_text2: {
    //   value: '',
    //   fieldName: 'lv_text2',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // fi_text2: {
    //   value: '',
    //   fieldName: 'fi_text2',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // ee_text3: {
    //   value: '',
    //   fieldName: 'ee_text3',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // ru_text3: {
    //   value: '',
    //   fieldName: 'ru_text3',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // lt_text3: {
    //   value: '',
    //   fieldName: 'lt_text3',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // lv_text3: {
    //   value: '',
    //   fieldName: 'lv_text3',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // fi_text3: {
    //   value: '',
    //   fieldName: 'fi_text3',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // ee_text4: {
    //   value: '',
    //   fieldName: 'ee_text4',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // ru_text4: {
    //   value: '',
    //   fieldName: 'ru_text4',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // lt_text4: {
    //   value: '',
    //   fieldName: 'lt_text4',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // lv_text4: {
    //   value: '',
    //   fieldName: 'lv_text4',
    //   placeholder: 'Text',
    //   maxLength: 100
    // },
    // fi_text4: {
    //   value: '',
    //   fieldName: 'fi_text4',
    //   placeholder: 'Text',
    //   maxLength: 100
    // }
  },
};
