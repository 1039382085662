export const emptyEvent = {
  alt: {
    value: '',
    fieldName: 'alt',
    placeholder: 'Alt',
    maxLength: 30,
  },
  img: {
    value: '',
    fieldName: 'img',
    placeholder: 'Event image',
    name: '',
    size: {
      height: 840,
      width: 1400,
    },
  },
  title: {
    value: '',
    fieldName: 'title',
    placeholder: 'Title',
    maxLength: 100,
  },
  subTitle: {
    value: '',
    fieldName: 'subTitle',
    placeholder: 'Sub title',
    maxLength: 500,
  },
  date: {
    value: new Date(),
    fieldName: 'date',
    placeholder: 'Date',
  },
  link: {
    value: '',
    fieldName: 'link',
    placeholder: 'Link',
  },
};
