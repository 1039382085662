import React from 'react';
import { Switch } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './edit-widget.module.scss';

const EditWidgetItem = ({
  dragHandleProps,
  showField,
  showSecondField,
  item,
  toggleEditItem,
  togglePublished,
  removeItem,
  itemFef,
  ...props
}) => {
  return (
    <div className={s.item} {...props} ref={itemFef}>
      <div className={s.dots} {...dragHandleProps}>
        <div className={s.dot} />
        <div className={s.dot} />
        <div className={s.dot} />
        <div className={s.dot} />
        <div className={s.dot} />
      </div>
      <div className={s.title}>
        {showField
          ? item?.[showField]?.value
          : item?.title?.value || item?.alt?.value}
        {showSecondField && ` - ${item?.[showSecondField]?.value}`}
      </div>
      <div className={s.panel}>
        <Switch
          className={s.switch}
          onChange={() => togglePublished(item)}
          checked={item.isPublished === undefined || item.isPublished}
        />
        <div className={s.control} onClick={() => toggleEditItem(item)}>
          <i className={i.edit} />
        </div>
        <div className={s.control} onClick={() => removeItem(item)}>
          <i className={i.basket} />
        </div>
      </div>
    </div>
  );
};

export default EditWidgetItem;
