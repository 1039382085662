import React, { useState, useEffect, useContext } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Query } from 'react-apollo';
import {
  Container,
  Loading,
  Button,
  SendTickets,
  TaxiTransferWarning,
} from '@lux/ui';
import { JOURNEYS_HISTORY } from '@lux/spa/apollo/queries';
import { StoreContext } from '@lux/spa/store';
import { ProfileMenu, Trip } from '@lux/spa/components';
import { Tickets } from './components';
import s from './trips.module.scss';

const Trips = ({ t, className, i18n }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { language } = i18n;
  const [activeTab, setActiveTab] = useState(1);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showTickets, setShowTickets] = useState(false);
  const [ticketsData, setTicketsData] = useState(null);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const toggleModalInfo = () => {
    setIsOpenModalInfo(!isOpenModalInfo);
  };

  useEffect(() => {
    dispatch({
      type: 'RESET_REFUND',
    });
  }, []);
  // const [tickets, setTickets] = useState({
  //   upcoming: {
  //     trips: [],
  //     page: 1,
  //   },
  //   past: {
  //     trips: [],
  //     page: 1,
  //   },
  //   canceled: {
  //     trips: [],
  //     page: 1,
  //   },
  // });

  // const { loading } = useQuery(JOURNEYS_HISTORY, {
  //   variables: {
  //     status: 1,
  //     page: 1,
  //     perPage: 10,
  //   },
  //   onCompleted: data => {
  //     console.log('data', data);
  //     const upcoming = data?.journeysHistory?.objects || [];
  //     console.log('upcoming', upcoming);
  //     setTickets(prev => ({
  //       ...prev,
  //       upcoming: {
  //         trips: [...prev.upcoming.trips, ...upcoming],
  //         page: prev.upcoming.page,
  //       },
  //     }));
  //   },
  // });

  // const handleLoadMore = type => {
  //   if (type === 1) {
  //     JOURNEYS_HISTORY({});
  //   }

  //   // setPages(prev => ({
  //   //   ...prev,
  //   //   type: prev[type] + 1,
  //   // }));
  // };

  return (
    <div className={cn(className, s.trips)}>
      <SendTickets
        basketId={ticketsData}
        toggleModal={toggleModal}
        isOpen={isOpenModal}
        closeModal={() => setIsOpen(false)}
      />
      {isOpenModalInfo && (
        <TaxiTransferWarning
          toggleModal={toggleModalInfo}
          isOpen={isOpenModalInfo}
        />
      )}
      <Container>
        <Container full={false}>
          <section>
            <h2>{t('my account')}</h2>
            <div className={s.block}>
              <ProfileMenu />
              <div className={s.content}>
                {showTickets ? (
                  <Tickets {...ticketsData} setShowTickets={setShowTickets} />
                ) : (
                  <div
                    className={cn(
                      s.tabs,
                      activeTab === 1 && s.one,
                      activeTab === 2 && s.two
                    )}
                  >
                    <div className={s.tabPanel}>
                      <div className={s.line} />
                      <div
                        onClick={() => setActiveTab(1)}
                        className={cn(s.tab, activeTab === 1 && s.active)}
                      >
                        {t('upcoming')}
                      </div>
                      <div
                        onClick={() => setActiveTab(2)}
                        className={cn(s.tab, activeTab === 2 && s.active)}
                      >
                        {t('past')}
                      </div>
                    </div>
                    <div className={s.tabsContainer}>
                      <div className={s.tabsContents}>
                        <div className={cn(s.tabsContent, s.upcoming)}>
                          <Query
                            query={JOURNEYS_HISTORY}
                            fetchPolicy="network-only"
                            skip={activeTab === 2}
                            variables={{
                              displayPassed: false,
                              limit: 40,
                              lang: language,
                            }}
                          >
                            {({ data, loading }) => {
                              if (loading) return <Loading />;

                              const trips = data?.travelAccountJourneys || [];

                              return trips.length ? (
                                trips.map((trip, key) => {
                                  const legs = trip.Legs || [];
                                  return (
                                    <Trip
                                      isModificationAllowed={
                                        trip.Passengers[0].IsModificationAllowed
                                      }
                                      isOpenModalInfo={isOpenModalInfo}
                                      toggleModalInfo={toggleModalInfo}
                                      key={key}
                                      // isCanceled={false}
                                      isPast={false}
                                      isPublic={false}
                                      className={s.touchedTicket}
                                      arrivalTime={trip.ArrivalTime}
                                      departureTime={trip.DepartureTime}
                                      destinationStopId={trip.DestinationStopId}
                                      departureStopId={trip.DepartureStopId}
                                      departureStopName={trip.DepartureStopName}
                                      departureCityName={trip.DepartureCityName}
                                      arrivalTimeZone={trip.ArrivalTimeZone}
                                      departureTimeZone={trip.DepartureTimeZone}
                                      destinationCityName={
                                        trip.DestinationCityName
                                      }
                                      destinationStopName={
                                        trip.DestinationStopName
                                      }
                                      legs={legs.length}
                                      shoppingBasketCode={
                                        trip.ShoppingBasketCode
                                      }
                                      passengers={trip.Passengers}
                                      shoppingBasketId={trip.ShoppingBasketId}
                                      toggleModal={toggleModal}
                                      showTickets={showTickets}
                                      trip={trip}
                                      setShowTickets={setShowTickets}
                                      setTicketsData={setTicketsData}
                                    />
                                  );
                                })
                              ) : (
                                <div className={s.noTickets}>
                                  <img src="/images/svg/tickets.svg" alt="" />
                                  <h5>{t('could not find tickets')}</h5>
                                  <p>
                                    {t(
                                      'the tickets you purchased will be displayed here'
                                    )}
                                  </p>
                                  <Button
                                    to="/tickets/search"
                                    text={t('search tickets')}
                                  />
                                </div>
                              );
                            }}
                          </Query>
                        </div>
                        <div className={cn(s.tabsContent, s.past)}>
                          <Query
                            query={JOURNEYS_HISTORY}
                            fetchPolicy="network-only"
                            skip={activeTab === 1}
                            variables={{
                              displayPassed: true,
                              limit: 40,
                              lang: language,
                            }}
                          >
                            {({ data, loading }) => {
                              if (loading) return <Loading />;

                              const trips = data?.travelAccountJourneys || [];

                              return trips.length ? (
                                trips.map((trip, key) => {
                                  const legs = trip.Legs || [];
                                  return (
                                    <Trip
                                      key={key}
                                      // isCanceled={false}
                                      isPast
                                      className={s.touchedTicket}
                                      arrivalTime={trip.ArrivalTime}
                                      departureTime={trip.DepartureTime}
                                      departureStopName={trip.DepartureStopName}
                                      destinationStopName={
                                        trip.DestinationStopName
                                      }
                                      passengers={trip.Passengers}
                                      destinationStopId={trip.DestinationStopId}
                                      departureStopId={trip.DepartureStopId}
                                      legs={legs.length}
                                      shoppingBasketCode={
                                        trip.ShoppingBasketCode
                                      }
                                      shoppingBasketId={trip.ShoppingBasketId}
                                      toggleModal={toggleModal}
                                      showTickets={showTickets}
                                      trip={trip}
                                      setShowTickets={setShowTickets}
                                      setTicketsData={setTicketsData}
                                    />
                                  );
                                })
                              ) : (
                                <div className={s.noTickets}>
                                  <img src="/images/svg/tickets.svg" alt="" />
                                  <h5>{t('could not find tickets')}</h5>
                                  <p>
                                    {t(
                                      'the tickets you purchased will be displayed here'
                                    )}
                                  </p>
                                  <Button
                                    to="/tickets/search"
                                    text={t('search tickets')}
                                  />
                                </div>
                              );
                            }}
                          </Query>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(Trips);
