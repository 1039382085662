import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import cx from 'classnames';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { NavLink, LazyLoadImage, DiscardedBasket, Login } from '@lux/ui';
import { hideFaqForLangs } from '@lux/spa/config';
import { StoreContext } from 'store';
import { getCookie } from 'utils';
import { langs } from '@lux/spa/i18n';
import DesktopHeader from './header-desktop';
import MobileHeader from './header-mobile';
import s from './header.module.scss';

const Header = ({
  i18n,
  onChangeLang,
  location = {
    pathname: '',
  },
  profileLoading,
  history,
  hasNotifications,
}) => {
  const { isMob } = useContext(WidthContext);

  const { pathname } = location;
  const [state, dispatch] = useContext(StoreContext);
  const { user, app, tickets, changeTickets } = state;
  const { paymentSuccessful } = tickets;
  const { ticketsUi, isOpenLoginModal } = app;
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { language } = i18n;

  const currencyName = 'RUB';

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const classes = cx(
    s.header,
    isOpen && s.open,
    ticketsUi && s.ticketPages,
    hasNotifications && s.hasNotifications
  );

  const travelInfoLink = {
    en: '/news/travel-info/',
    et: '/news/reisiinfo/',
    ru: '/news/vazhnaya-informaciya/',
    lv: '/news/celosanas-informacija/',
    lt: '/news/svarbi-informacija/',
  }[language];

  const showFaq = !hideFaqForLangs.includes(language);

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const showDiscardedBasketModal =
    !paymentSuccessful &&
    ticketsUi &&
    !pathname.includes('/search') &&
    !pathname.includes('change-tickets');
  const showDiscardedChangeTicketSessionModal =
    !changeTickets.paymentSuccessful &&
    ticketsUi &&
    pathname.includes('change-tickets');

  return (
    <header className={classes}>
      <DiscardedBasket isOpen={isOpenModal} toggleModal={toggleModal} url="/" />
      <Login
        isOpen={isOpenLoginModal}
        toggleModal={() => {
          dispatch({
            type: 'UPDATE_APP',
            payload: {
              isOpenLoginModal: !isOpenLoginModal,
            },
          });
        }}
      />
      <div className={s.logo}>
        {showDiscardedBasketModal || showDiscardedChangeTicketSessionModal ? (
          <>
            {showDiscardedBasketModal && (
              <div onClick={toggleModal}>
                <LazyLoadImage
                  src="/images/svg/logo-grey.svg"
                  alt="luxexpress, site logo"
                />
              </div>
            )}
            {showDiscardedChangeTicketSessionModal && (
              <div onClick={toggleModal}>
                <LazyLoadImage
                  src="/images/svg/logo-grey.svg"
                  alt="luxexpress, site logo"
                />
              </div>
            )}
          </>
        ) : (
          <NavLink to="/">
            <LazyLoadImage
              src="/images/svg/logo-grey.svg"
              alt="luxexpress, site logo"
            />
          </NavLink>
        )}
      </div>
      <MobileHeader
        showDiscardedBasketModal={showDiscardedBasketModal}
        showDiscardedChangeTicketSessionModal={
          showDiscardedChangeTicketSessionModal
        }
        travelInfoLink={travelInfoLink}
        isMob={isMob}
        isOpen={isOpen}
        langs={langs}
        user={user}
        dispatch={dispatch}
        currencyName={currencyName}
        isTicketPages={ticketsUi}
        toggleMenu={toggleMenu}
        onChangeLang={onChangeLang}
        profileLoading={profileLoading}
        history={history}
        showFaq={showFaq}
        location={location}
      />
      <DesktopHeader
        showDiscardedBasketModal={showDiscardedBasketModal}
        showDiscardedChangeTicketSessionModal={
          showDiscardedChangeTicketSessionModal
        }
        travelInfoLink={travelInfoLink}
        onChangeLang={onChangeLang}
        langs={langs}
        user={user}
        dispatch={dispatch}
        isTicketPages={ticketsUi}
        currencyName={currencyName}
        profileLoading={profileLoading}
        history={history}
        showFaq={showFaq}
        location={location}
      />
    </header>
  );
};

export default withRouter(withTranslation()(Header));
