import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { BASKET_STATE } from '@lux/spa/apollo/queries';
import { useLazyQuery } from '@apollo/react-hooks';
import { StoreContext } from '@lux/spa/store';
import ThanksLayoutSuccess from '../../thanks-layout-success.jsx';
import ThanksLayoutPending from '../../thanks-layout-pending.jsx';
import ThanksLayoutError from '../../thanks-layout-error.jsx';

const BankLink = ({
  i18n,
  history,
  fileLoading,
  getTicketsFileUrl,
  urlParams,
}) => {
  const [state, dispatch] = useContext(StoreContext);
  const { totalPrice, GTM } = state.changeTickets;
  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';
  const { from, to, returnDate, promocode } = state.changeTickets.search;
  const { ShoppingBasketId = '' } = urlParams;
  const { GTMLoaded } = state.app;
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [basketState] = useLazyQuery(BASKET_STATE, {
    fetchPolicy: 'network-only',
    skip: !!ShoppingBasketId,
    variables: {
      basketId: ShoppingBasketId,
    },
    onError: () => {
      setIsError(true);
      setIsLoading(false);
    },
    onCompleted: data => {
      const error = data?.basketState.error;

      if (error) {
        setIsError(true);
        setIsLoading(false);
      } else {
        if (data?.basketState.State === 'payment_started') {
          basketState();
        } else if (data?.basketState.State === 'paid') {
          setIsError(false);
          setIsLoading(false);
          getTicketsFileUrl();
        } else {
          setIsError(true);
          setIsLoading(false);
        }

        if (data?.basketState?.objects?.[0]) {
          const quantity = data?.basketState.objects[0].Passengers.reduce(
            (sum, pass) => (sum += pass.Tickets.length),
            0
          );
          const Id = data?.basketState.objects[0].ShoppingBasketCode;

          if (GTMLoaded && !GTM.thanksStep) {
            // dataLayer.push({
            //   tripType: returnDate ? 'return-trip' : 'one-way',
            //   departureStopId: from.BusStopId,
            //   destinationStopId: to.BusStopId,
            //   currencyCode: currencyName,
            //   route: `${from.CityName}-${to.CityName}`,
            //   price: totalPrice,
            //   promocode: promocode || null,
            //   departureDestination: `${from.CountryName}-${to.CountryName}`,
            //   stage: 'purchase',
            //   event: 'payment-success',
            //   Id,
            //   quantity,
            // });

            dispatch({
              type: 'UPDATE_CHANGE_TICKETS',
              payload: {
                GTM: {
                  ...GTM,
                  thanksStep: true,
                },
              },
            });
          }
        }
      }
    },
  });

  useEffect(() => {
    basketState();
  }, []);

  return isLoading ? (
    <ThanksLayoutPending />
  ) : isError ? (
    <ThanksLayoutError renderCancel />
  ) : (
    <ThanksLayoutSuccess
      type="card"
      fileLoading={fileLoading}
      getTicketsFileUrl={getTicketsFileUrl}
    />
  );
};

export default withRouter(withTranslation()(BankLink));
