import React, { memo } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Query } from 'react-apollo';
import { Tags, Tag, Loading } from '@lux/ui';
import { ORDER_WIDGET } from '@lux/spa/apollo/queries';
import s from './tickets-no-results-yet.module.scss';

const TicketsNoResultsYet = ({ t, i18n, className }) => {
  const { language } = i18n;
  return (
    <div className={cn(s.ticketsNoResultsYet, className)}>
      <img src="/images/svg/tickets-not-found.svg" alt="" />
      <h2 className={s.title}>{t('no results yet')} ...</h2>
      <div className={s.description}>
        {t(
          'to perform the search, specify the place of departure and destination'
        )}
      </div>
      <h3>{t('other popular destinations')}</h3>
      <Tags>
        <Query
          query={ORDER_WIDGET}
          variables={{
            lang: language,
            origin: 'popular-destinations',
          }}
        >
          {({ data = {}, loading }) => {
            if (loading) return <Loading />;
            const widget = data?.orderWidget;
            const destinations = widget?.destinations;
            return (
              !!destinations?.length &&
              destinations.map((destination, key) => {
                const city = destination.city?.name;
                const { slug } = destination.data;
                return (
                  <Tag to={`/destinations/${slug}/`} text={t(city)} key={key} />
                );
              })
            );
          }}
        </Query>
      </Tags>
    </div>
  );
};

export default withTranslation()(memo(TicketsNoResultsYet));
