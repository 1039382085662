import gql from 'graphql-tag';

export const IS_SLUG_UNIQUE = gql`
  query(
    $objectType: String!
    $lang: String!
    $slug: String!
    $country: String
    $id: Int
  ) {
    isUniqueSlug(
      objectType: $objectType
      lang: $lang
      slug: $slug
      country: $country
      id: $id
    )
  }
`;

export const GET_CURRENCIES = gql`
  query CurrencyType {
    currencies {
      Currency
      CurrencyName
      Symbol
    }
  }
`;
