import React, { cloneElement, Children, useEffect, useContext } from 'react';
import cx from 'classnames';
import { useComponentVisible } from '@lux/spa/components';
import { StoreContext } from '@lux/spa/store';
import styles from './dropdown.module.scss';

const Dropdown = ({ classname, classNameTarget, Target, children }) => {
  const [state, dispatch] = useContext(StoreContext);

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  const childrens = Children.toArray(children);

  const toggleMenu = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  useEffect(() => {
    dispatch({
      type: 'UPDATE_TICKETS',
      payload: {
        isOpenDropDown: isComponentVisible,
      },
    });
  }, [isComponentVisible]);

  return (
    <div
      className={cx(styles.dropdown, isComponentVisible && styles.open)}
      ref={ref}
    >
      <div className={styles.wrap}>
        <div className={styles.triangle} />
      </div>
      {/* <Target /> */}
      {/* {cloneElement(<Target />, {
        onClick: toggleMenu,
      })} */}
      {/* <Target onClick={toggleMenu} /> */}
      <Target
        isOpen={isComponentVisible}
        className={cx(styles.target, classNameTarget)}
        onClick={toggleMenu}
      />
      {/* <div className={styles.target} onClick={toggleMenu}>
        Choose a seat first
      </div> */}
      <div className={styles.menu}>
        {childrens.map(child =>
          cloneElement(child, {
            onClick: () => setIsComponentVisible(false),
            isOpen: isComponentVisible,
          })
        )}
      </div>
    </div>
  );
};

export default Dropdown;
