import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import SearchResultsItem from '@lux/spa/routes/search/components/search-results-item';
import i from '@lux/spa/styles/icons.module.scss';
import s from '../search.module.scss';

const SearchResultsList = ({ t, data, count = 2 }) => {
  const [listResults, setListResults] = useState(data.slice(0, count));
  const loadMore = e => {
    e.preventDefault();
    setListResults(data.slice(0, listResults.length + count));
  };
  const LoadButton = () => {
    if (data.length !== listResults.length) {
      return (
        <a href="#" className={s.more} onClick={loadMore}>
          {t('show more')} <i className={i.arrowRight_v2} />
        </a>
      );
    }
    return '';
  };
  return (
    <div className={s.wrapper}>
      <ul className={s.list}>
        {listResults.map(item => (
          <SearchResultsItem {...item} />
        ))}
      </ul>
      <div className={s.show_more}>
        {data.length > count ? <LoadButton /> : <div />}
      </div>
    </div>
  );
};

export default withTranslation()(SearchResultsList);
