import React from 'react';
import cn from 'classnames';
import * as uuid from 'uuid';
import s from './radio.module.scss';

const Radio = ({ className, ...props }) => {
  const id = uuid.v4();
  return (
    <div className={cn(s.radio, className)}>
      <input id={id} type="radio" {...props} />
      <label htmlFor={id}>
        <span />
        {props.value}
      </label>
    </div>
  );
};

export default Radio;
