import React, { useState, useEffect, useContext } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { StoreContext } from '@lux/spa/store';
import { MODIFICATION_VERIFY_VOUCHER } from '@lux/spa/apollo/mutations';
import { MODIFICATION_DETAILS } from '@lux/spa/apollo/queries';
import { TicketsInput, RedeemChangeTicketsVoucher } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './voucher.module.scss';

const ChangeVoucher = ({ className, t }) => {
  const MAX_VOUCHER_LENGTH = 12;
  const [state, dispatch] = useContext(StoreContext);
  const { vouchers } = state.changeTickets;
  const [voucher, setVoucher] = useState('');
  const [voucherValue, setVoucherValue] = useState(0);
  const [statusVaucher, setStatusVaucher] = useState(null);
  const [redeemVoucherModal, setRedeemVoucherModal] = useState(false);

  const toggleVoucher = () => {
    dispatch({
      type: 'UPDATE_CHANGE_TICKETS',
      payload: {
        vouchers: {
          ...vouchers,
          showVoucherInput: !vouchers.showVoucherInput,
        },
      },
    });
  };

  const toggleRedeemVoucherModal = () => {
    setRedeemVoucherModal(!redeemVoucherModal);
  };

  const [modificationDetails] = useLazyQuery(MODIFICATION_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data.modificationDetails) {
        dispatch({
          type: 'UPDATE_CHANGE_TICKETS',
          payload: {
            totalPrice: data.modificationDetails.ModificationCost,
          },
        });
      }
    },
    onError: data => {
      console.error('error', data);
    },
  });

  const [verifyVoucher, { loading: voucherLoading }] = useMutation(
    MODIFICATION_VERIFY_VOUCHER,

    {
      onCompleted: data => {
        if (data.modificationVerifyVoucher.error) {
          setStatusVaucher('fail');
        } else {
          setStatusVaucher('success');
          setVoucherValue(data.modificationVerifyVoucher.voucherInfo.Amount);
        }
      },
      onError: data => {
        setStatusVaucher('fail');
        toast.error(t('something wrong'));
      },
    }
  );

  useEffect(() => {
    if (voucher.length === MAX_VOUCHER_LENGTH) {
      verifyVoucher({
        variables: {
          VoucherCode: voucher,
        },
      });
    } else {
      setStatusVaucher(null);
    }
  }, [voucher]);

  return (
    <div
      className={cn(s.voucher, vouchers.showVoucherInput && s.open, className)}
    >
      <RedeemChangeTicketsVoucher
        voucherCode={voucher}
        isOpen={redeemVoucherModal}
        toggleModal={toggleRedeemVoucherModal}
        voucherValue={voucherValue}
        setVoucher={setVoucher}
        callback={modificationDetails}
      />
      {vouchers.showVoucherInput ? (
        <div className={s.voucherInput}>
          <TicketsInput
            autoFocus
            name="voucher"
            type="text"
            value={voucher}
            onChange={e =>
              setVoucher(e.target.value.substr(0, MAX_VOUCHER_LENGTH))
            }
            placeholder={t('enter voucher')}
            className={s.input}
          />
          {statusVaucher ? (
            statusVaucher === 'success' ? (
              <i className={cn(i.success, s.success)} />
            ) : (
              <i className={cn(i.warning_v2, s.warning_v2)} />
            )
          ) : (
            <i className={cn(i.subtract, s.subtract)} />
          )}
          <div
            className={cn(s.action, statusVaucher !== 'success' && s.disabled)}
          >
            {voucherLoading ? (
              <span className={s.loading}>{t('loading')}</span>
            ) : voucher.length ? (
              statusVaucher === 'success' ? (
                <span className={s.apply} onClick={toggleRedeemVoucherModal}>
                  {t('apply')}
                </span>
              ) : (
                <span className={s.clear} onClick={() => setVoucher('')}>
                  {t('clear')}
                </span>
              )
            ) : (
              <span className={s.close} onClick={toggleVoucher}>
                {t('close')}
              </span>
            )}
          </div>
        </div>
      ) : vouchers.approved.length ? (
        <div className={s.addVoucher} onClick={toggleVoucher}>
          + {t('add one more voucher')}
        </div>
      ) : (
        <div className={s.addVoucher} onClick={toggleVoucher}>
          + {t('add voucher')}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(ChangeVoucher);
