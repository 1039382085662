import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import {
  // CREATE_DESTINATION,
  UPDATE_DESTINATION,
} from '@lux/spa/apollo/mutations';
import {
  GET_DESTINATION_ADMIN,
  GET_DESTINATION,
} from '@lux/spa/apollo/queries';
import { NotFound } from '@lux/spa/routes';
import { PageLoading } from '@lux/ui';
import { emptyData, defaultData } from './constants';
import DestinationContent from './destination-content';

const Destination = ({
  history,
  lang = 'en',
  match,
  i18n,
  isStaff,
  isEditMode,
  page = { en: emptyData },
  dispatch = () => {},
  ...props
}) => {
  const { destination } = match.params;
  const { language } = i18n;
  const QUERY = isStaff ? GET_DESTINATION_ADMIN : GET_DESTINATION;

  const { data, loading = true } = useQuery(QUERY, {
    errorPolicy: 'ignore',
    variables: {
      lang: language,
      slug: destination,
    },
  });

  const updatePage = field => {
    dispatch({
      type: 'UPDATE_DATA',
      payload: {
        field,
      },
    });
  };

  useEffect(() => {
    if (isStaff && data) {
      dispatch({
        type: 'UPDATE',
        payload: {
          defaultData,
          emptyData,
          UPDATE_PAGE: UPDATE_DESTINATION,
          // CREATE_PAGE: CREATE_DESTINATION,
          originalPage: data?.adminDestination,
          page: data?.adminDestination,
        },
      });
    }
  }, [data]);

  const content = isStaff ? page?.[lang] : data?.destination?.data;

  let slugs = {};
  const type = 'destinations';

  if (!isStaff) {
    slugs = data?.destination?.slugs;
  } else if (data?.adminDestination) {
    Object.entries(data?.adminDestination).map(([key, value]) => {
      if (key.length == 2 && typeof value == 'object') {
        slugs[key] = value?.slug ? value.slug : null;
      }
    });
  }

  if (slugs && typeof localStorage !== 'undefined') {
    localStorage.setItem(
      'location',
      JSON.stringify({
        type,
        slugs,
      })
    );
  }

  return loading ? (
    <PageLoading />
  ) : content ? (
    <DestinationContent
      slugs={slugs}
      type={type}
      content={{
        ...content,
        seo: {
          ...content.seo,
          slug: {
            ...content.seo.slug,
            value: `${type}/${content.seo.slug.value}`,
          },
        },
        pageData: {
          ...emptyData.pageData,
          ...content.pageData,
        },
      }}
      {...{ updatePage, language, isEditMode, isStaff }}
      {...props}
    />
  ) : (
    <NotFound />
  );
};

export default withTranslation()(Destination);
