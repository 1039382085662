import React from 'react';
import { Query } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import { GET_SUGGESTIONS } from '@lux/spa/apollo/queries';
import { Loading } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './suggestions.module.scss';

const Suggestions = ({
  t,
  language,
  search = '',
  selectItem,
  suggestionsRef,
  popularDestinations = [],
  selectedStop,
}) => {
  return (
    <Query
      query={GET_SUGGESTIONS}
      variables={{
        cityName: search,
        lang: language,
      }}
      skip={search.length < 3}
    >
      {({ loading, error, data = { cities: [] } }) => {
        if (loading) {
          return (
            <div className={s.suggestions} ref={suggestionsRef}>
              <div className={s.list}>
                <Loading className={s.loading} />
              </div>
            </div>
          );
        }

        return (
          <div className={s.suggestions} ref={suggestionsRef}>
            {search.length >= 3 && (
              <div className={s.wrap}>
                <div className={s.triangle} />
              </div>
            )}
            {data.cities.length ? (
              <div className={s.list}>
                {data.cities.map(({ CityId, CityName, BusStops }) => {
                  const { CountryName = '' } = BusStops[0];
                  return (
                    <>
                      <div className={s.townCountry} key={CityId}>
                        <i className={i.point} />
                        {t(CityName)}
                        <span>, {t(CountryName)}</span>
                      </div>

                      {BusStops.map(
                        ({ BusStopId, BusStopName, CountryName }) => {
                          const icon = BusStopName.includes('airplane')
                            ? 'airplane'
                            : 'bus';
                          return (
                            <div
                              key={BusStopId}
                              className={s.item}
                              onClick={() =>
                                selectItem({
                                  BusStopId,
                                  BusStopName,
                                  CityName,
                                  CountryName,
                                })
                              }
                            >
                              <i className={i[icon]} />
                              <span>{BusStopName}</span>
                            </div>
                          );
                        }
                      )}
                    </>
                  );
                })}
              </div>
            ) : (
              <div className={s.list}>
                {search.length >= 3 && (
                  <>
                    <div className={s.townCountry}>{t('search results')}</div>
                    <span className={s.item}>
                      {t('no matches for')} «<b>{search}</b>»
                    </span>
                  </>
                )}
                <div className={s.townCountry}>{t('popular destinations')}</div>
                {popularDestinations.map(({ CityId, CityName, BusStops }) =>
                  BusStops.map(({ BusStopId, BusStopName, CountryName }) => {
                    const icon = BusStopName.includes('airplane')
                      ? 'airplane'
                      : 'bus';
                    return (
                      selectedStop !== BusStopId && (
                        <div
                          key={BusStopId}
                          className={s.item}
                          onClick={() =>
                            selectItem({
                              BusStopId,
                              BusStopName,
                              CityName,
                              CountryName,
                            })
                          }
                        >
                          <i className={i[icon]} />
                          <span>{BusStopName}</span>
                        </div>
                      )
                    );
                  })
                )}
              </div>
            )}
          </div>
        );
      }}
    </Query>
  );
};

export default withTranslation()(Suggestions);
