import React from 'react';

const Html = ({ children, state, lang, head }) => {
  return (
    <html lang={lang} prefix="og: http://ogp.me/ns#">
      <head>
        <script rel="preload" src="/bundle.js" defer />
        <script
          id="state"
          dangerouslySetInnerHTML={{
            // '__html': `window.__SSR_STATE__=${JSON.stringify(context)};`
            __html: `window.__APOLLO_STATE__=${JSON.stringify(state).replace(
              /</g,
              '\\u003c'
            )};`,
          }}
        />
        <meta charSet="utf-8" />
        {/* {head.title.toString().replace(/ data-react-helmet="true"/g, '')}
          {head.meta.toString().replace(/ data-react-helmet="true"/g, '')}
        {head.link.toString().replace(/ data-react-helmet="true"/g, '')} */}
        {head.title.toComponent()}
        {head.meta.toComponent()}
        {head.link.toComponent()}

        {/* this lines ↓ correct only for home page? */}
        {/* {langs.map((lang, index) => (
          <link key={index} rel="alternate" href={`${DOMAIN}/${lang}/`} hreflang={lang} />
        ))} */}
        {/* generics */}
        {/* <link rel="shortcut icon" href="/favicon.ico" /> */}

        <link rel="icon" href="/images/favicon/16.png" sizes="16x16" />
        <link rel="icon" href="/images/favicon/32.png" sizes="32x32" />
        <link rel="icon" href="/images/favicon/57.png" sizes="57x57" />
        {/* <link rel="icon" href="/images/favicon/76.png" sizes="76x76" /> */}
        <link rel="icon" href="/images/favicon/96.png" sizes="96x96" />
        <link rel="icon" href="/images/favicon/128.png" sizes="128x128" />
        <link rel="icon" href="/images/favicon/192.png" sizes="192x192" />
        <link rel="icon" href="/images/favicon/228.png" sizes="228x228" />

        {/* Android */}
        <link
          rel="shortcut icon"
          sizes="196x196"
          href="/images/favicon/196.png"
        />

        {/* iOS */}
        <link
          rel="apple-touch-icon"
          href="/images/favicon/120.png"
          sizes="120x120"
        />
        <link
          rel="apple-touch-icon"
          href="/images/favicon/152.png"
          sizes="152x152"
        />
        <link
          rel="apple-touch-icon"
          href="/images/favicon/180.png"
          sizes="180x180"
        />

        <meta
          name="viewport"
          content="initial-scale=1.0, width=device-width, maximum-scale=1, viewport-fit=cover"
        />

        {/* <link rel="manifest" href="/manifest.json" /> */}
        {/* <meta name="theme-color" content="#7ACCE5" /> */}
        {/* <link rel="icon" sizes="192x192" href="/images/mannifest/logo-pwa-192.png" /> */}
        {/* <link rel="apple-touch-icon" sizes="152x152" href="/images/mannifest/logo-pwa-152.png" /> */}
        {/* <link rel="apple-touch-icon" sizes="180x180" href="touch-icon-iphone-retina.png" />
        <link rel="apple-touch-icon" sizes="167x167" href="touch-icon-ipad-retina.png" /> */}
        {/* <link rel="preconnect" href={apiUrl} />
        <link rel="dns-prefetch" href={apiUrl} /> */}

        {/* prelod fonts */}
        {/* <link rel="preload" href="/fonts/icons/icomoon.ttf" as="font" crossOrigin="anonymous" /> */}
        {/* <link rel="preload" href="/fonts/manrope/web/Manrope-SemiBold.woff2" as="font" crossorigin="anonymous" /> */}
        <link
          rel="preload"
          href="/fonts/manrope/ttf/Manrope-SemiBold.ttf"
          as="font"
          crossOrigin="anonymous"
        />
        {/* <link rel="preload" href="/fonts/manrope/web/Manrope-Regular.woff2" as="font" crossorigin="anonymous" /> */}
        <link
          rel="preload"
          href="/fonts/manrope/ttf/Manrope-Regular.ttf"
          as="font"
          crossOrigin="anonymous"
        />

        {/* iPhone X, Xs (1125px x 2436px) */}
        {/* from: https://medium.com/appscope/adding-custom-ios-splash-screens-to-your-progressive-web-app-41a9b18bdca3 */}
        {/* <link
          rel="apple-touch-startup-image"
          media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
          href="/apple-launch-1125x2436.png"
          />
        <meta name="apple-mobile-web-app-capable" content="yes" /> */}

        {/* <meta name="robots" content="noindex" /> */}
      </head>
      <body>
        <div id="app">{children}</div>
        <div id="modal-root" />
      </body>
      <script src="/ckfinder/ckfinder.js" />
    </html>
  );
};

export default Html;
