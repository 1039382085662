import React, { useEffect, useState, useContext, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import {
  MODIFICATION_NEO_PAY_PAYMENT,
  BANK_LINK_PAYMENT,
} from '@lux/spa/apollo/mutations';
import { useMutation } from '@apollo/react-hooks';
import { StoreContext } from '@lux/spa/store';
import { DEFAULT_LANG } from '@lux/spa/config';
import { Select, SelectOption, Loading } from '@lux/ui';
import s from './banks.module.scss';

const Banks = ({ t, i18n, dataByCounty }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { totalPrice, trip, vouchers } = state.changeTickets;
  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';
  const { from, to } = state.changeTickets.search;
  const { ShoppingBasketId = '' } = trip;
  const { language } = i18n;
  const [token, setToken] = useState(false);
  const [bankData, setBankData] = useState(null);
  const [country, setCountry] = useState('Eesti');
  const refForm = useRef(null);
  const finalPrice =
    totalPrice > vouchers.totalVouchersValue
      ? totalPrice - vouchers.totalVouchersValue
      : 0;
  const [neoPayPayment, { loading }] = useMutation(
    MODIFICATION_NEO_PAY_PAYMENT
  );
  const [bankLinkPayment, { loading: bankLinkPaymentLoading }] = useMutation(
    BANK_LINK_PAYMENT
  );

  const paymentInitiation = bank => {
    const NEOWidgetHost = 'https://psd2.neopay.lt';

    // exponea.track('payment_selected', {
    //   timestamp: Date.now(),
    //   payment_method: 'card',
    //   total_price: totalPrice,
    //   currency: currencyName,
    //   Origin_stop_ID: from.BusStopId,
    //   Destination_stop_ID: to.BusStopId,
    // });

    if (bank.bankLink) {
      bankLinkPayment({
        variables: {
          IsModification: true,
          lang: language,
          BankName: bank.bic,
          FailRedirectUrl: `${window.location.origin}/${
            DEFAULT_LANG === language ? '' : `${language}/`
          }change-tickets/thanks?type=bankLink&ShoppingBasketId=${ShoppingBasketId}`,
          SuccessRedirectUrl: `${window.location.origin}/${
            DEFAULT_LANG === language ? '' : `${language}/`
          }change-tickets/thanks?type=bankLink&ShoppingBasketId=${ShoppingBasketId}`,
        },
      })
        .then(data => {
          if (data.data.bankLinkPayment.error) {
            dispatch({
              type: 'UPDATE_CHANGE_TICKETS',
              payload: {
                isSomethingWrong: true,
              },
            });
            return;
          }

          setBankData({
            ...data.data.bankLinkPayment,
          });
        })
        .catch(() => {
          dispatch({
            type: 'UPDATE_CHANGE_TICKETS',
            payload: {
              isSomethingWrong: true,
            },
          });
        });
    } else {
      NEOWidget.initialize(NEOWidgetHost, token, {
        // bank: bic,
        bank: isProd || isStage ? bank.bic : 'TESTLV123',
        // default_country: 'LT',
        default_language: language || 'EN',
        // payer_consent_neopay_rules: 1,
        // client_redirect_url: 'https://myproject.com/client_redirect/neopay',
        // client_redirect_url: `${window.location.origin}/${
        //   DEFAULT_LANG === language ? '' : `${language}/`
        // }change-tickets/thanks?type=banks`,

        client_redirect_url: `${window.location.origin}/${
          DEFAULT_LANG === language ? '' : `${language}/`
        }change-tickets/thanks?type=banks`,
      });
    }
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://psd2.neopay.lt/widget.js';
    script.id = 'neopay';
    script.async = true;

    script.onload = neoPayPayment().then(data => {
      if (data?.data?.modificationNeoPayPayment?.success) {
        const token = data?.data?.modificationNeoPayPayment.data;
        setToken(token);
      }
    });

    document.body.appendChild(script);
  }, []);

  const handleChangeCountry = country => {
    setCountry(country);
  };

  const langCountry = {
    lt: 'Lietuva',
    lv: 'Latvija',
  };

  const counrties = dataByCounty.map(item => item.name);

  const banks = dataByCounty.filter(item => item.name === country)[0].aspsps;

  useEffect(() => {
    if (bankData) {
      if (refForm.current) {
        refForm.current.submit();
      }
    }
  }, [bankData]);

  useEffect(() => {
    if (token) {
      neoPayPayment().then(data => {
        if (data?.data?.modificationNeoPayPayment?.success) {
          const token = data?.data?.modificationNeoPayPayment.data;
          setToken(token);
        }
      });
    }
  }, [finalPrice]);

  useEffect(() => {
    const initialCountry = langCountry[language] || 'Eesti';
    handleChangeCountry(initialCountry);
  }, [language]);

  return (
    <div className={s.banks}>
      {loading ? (
        <Loading className={s.loading} />
      ) : (
        <>
          {bankData && (
            <form action={bankData.bankRequestUrl} method="POST" ref={refForm}>
              {Object.keys(bankData.payload).map(item => {
                return (
                  <input
                    type="hidden"
                    name={item}
                    value={bankData.payload[item]}
                  />
                );
              })}
            </form>
          )}
          <Select
            withoutMessage
            value={country}
            className={s.select}
            onChange={e => handleChangeCountry(e.target.value)}
          >
            {counrties.map(item => (
              <SelectOption
                key={item}
                value={item}
                text={item}
                selected={langCountry.item}
              />
            ))}
          </Select>
          <div className={s.banksList}>
            {banks.map(bank => {
              return (
                <div onClick={() => paymentInitiation(bank)} className={s.bank}>
                  <img
                    src={
                      bank.logo ||
                      'https://image.flaticon.com/icons/png/128/584/584073.png'
                    }
                    alt=""
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default withTranslation()(Banks);
