/* eslint-disable */
import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Collapse } from 'react-collapse';
import { StopsNew } from '@lux/spa/components';
import { Loading, LazyLoadImage, Notice } from '@lux/ui';
import i from '@lux/spa/styles/icons.module.scss';
import s from './ticket.module.scss';

const TicketLayout = ({
  t,
  id,
  classes,
  type,
  isMob,
  showTime,
  departureDateTime,
  arrivalDateTime,
  logoType,
  originStopName,
  destinationStopName,
  durationDays,
  duration,
  transfers,
  toggleDetails,
  showDetails,
  loading,
  stops,
  availableEquipment,
  equipmentTypes,
  availableRegularSeats,
  availableBusinessClassSeats,
  isModifiableJourney,
  buttonGroupRef,
  selectTicket,
  minPrice,
  symbol,
  discountPrice,
  passengers,
  notifications,
  isSoldOut,
  legs = [],
}) => {
  const withLuxBuses = legs.some(leg => leg?.BrandName?.startsWith('Lux'));

  const hasLabel = type && type !== "regular";

  return (
    <div className={cn(classes, hasLabel && id === 0 && s.firstWithLabel)}>
      {hasLabel && (
        <div className={s.label}>
          {type === 'promo' ? t('promo ticket') : t('lounge bus')}
        </div>
      )}
      {!isMob && (
        <>
          <div className={cn(s.decorativeСircle, s.top)}>
            <div className={s.circle} />
          </div>
          <div className={cn(s.decorativeСircle, s.bottom)}>
            <div className={s.circle} />
          </div>
        </>
      )}
      <div className={s.ticketContent}>
        <div className={s.main}>
          {isMob && (
            <>
              <div className={cn(s.decorativeСircle, s.top)}>
                <div className={s.circle} />
              </div>
              <div className={cn(s.decorativeСircle, s.bottom)}>
                <div className={s.circle} />
              </div>
            </>
          )}
          <div className={s.timing}>
            <div className={cn(s.stop, s.from)}>
              <div className={s.time}>{showTime(departureDateTime)}</div>
            </div>
            <div className={s.divider}>
              <div className={cn(s.circle, s.black)} />
              <div className={s.line} />
              <div className={s.circle} />
              <div className={s.line} />
            </div>
            <div className={s.logo}>
              {withLuxBuses ? (
                <LazyLoadImage
                  src={`/images/svg/${logoType}.svg`}
                  alt="Lux express bus"
                />
              ) : (
                <LazyLoadImage
                  src="/images/svg/bus_v2.svg"
                  alt="Lux express partner bus"
                />
              )}
            </div>
            <div className={cn(s.divider, s.reverse)}>
              <div className={cn(s.circle, s.black)} />
              <div className={s.line} />
              <div className={s.circle} />
              <div className={s.line} />
            </div>
            <div className={cn(s.stop, s.to)}>
              <div className={s.time}>{showTime(arrivalDateTime)}</div>
            </div>
          </div>
          <div className={s.direction}>
            <div className={cn(s.stop, s.from)}>
              <div className={s.name}>{originStopName}</div>
            </div>
            <div className={cn(s.stop, s.to)}>
              <div className={s.name}>{destinationStopName}</div>
            </div>
          </div>
          <div className={s.info}>
            <div className={s.duration}>
              {durationDays && `${durationDays}${t('short.day')} `}
              {`${duration[0]}${t('short.hour')} ${duration[1]}${t(
                'short.minute'
              )}`}
            </div>
            <div className={s.transfers}>
              {transfers
                ? `${transfers} ${transfers > 1 ? t('transfers') : t('transfer')
                }`
                : t('direction')}
            </div>
            <div
              onClick={toggleDetails}
              className={cn(s.showDetails, showDetails && s.show)}
            >
              {showDetails ? (
                <span>{t('hide details')}</span>
              ) : (
                <span>{t('details')}</span>
              )}
              <LazyLoadImage src="/images/svg/arrowBottom.svg" alt="" />
            </div>
          </div>
        </div>
        {isMob && (
          <Collapse isOpened={showDetails}>
            <div className={s.details}>
              {loading ? (
                <Loading />
              ) : (
                <StopsNew stops={stops} className={s.stops} />
              )}
            </div>
          </Collapse>
        )}
        <div className={cn(s.controls, isSoldOut && s.soldOut)}>
          <div className={s.divider} />
          {isSoldOut ? (
            <>
              <div className={s.soldOut}>{t('trip sold out')}</div>
              <div className={s.hyphens}>- - -</div>
            </>
          ) : (
            <>
              <div className={s.seats}>
                {Boolean(availableRegularSeats) && (
                  <div
                    className={cn(
                      s.seat,
                      availableRegularSeats > 0 && s.active
                    )}
                  >
                    <i className={i.regular} />
                    {availableRegularSeats} {t('regular')}
                  </div>
                )}

                {Boolean(availableBusinessClassSeats) && (
                  <div
                    className={cn(
                      s.seat,
                      availableBusinessClassSeats > 0 && s.active
                    )}
                  >
                    <i className={i.lounge} />
                    {availableBusinessClassSeats} {t('lounge')}
                  </div>
                )}
              </div>
              {isModifiableJourney ? (
                <div className={s.currentTrip}>
                  <i className={i.success} />
                  <div>{t('your current trip')}</div>
                </div>
              ) : (
                <div
                  ref={buttonGroupRef}
                  className={s.buttonGroup}
                  onClick={selectTicket}
                >
                  <div className={s.part}>{`${minPrice} ${symbol}`}</div>
                  <div className={s.line} />
                  <div className={s.part}>{t('book')}</div>
                </div>
              )}
              {/* {Number(discountPrice) === discountPrice && ( */}
              {discountPrice && (
                <div className={s.discount}>
                  {t('campaign offering available')}
                </div>
              )}
              <div className={s.travelers}>
                {`${passengers} ${passengers > 1 ? t('travellers') : t('traveller')
                  }`}
              </div>
            </>
          )}
        </div>
      </div>
      {!isMob && (
        <Collapse isOpened={showDetails}>
          <div className={s.details}>
            {loading ? (
              <Loading />
            ) : (
              <StopsNew stops={stops} className={s.stops} />
            )}
          </div>
        </Collapse>
      )}
      {notifications?.length && (
        <div className={s.notificationsWrap}>
          <div className={s.notifications}>
            {notifications.map(item => {
              return (
                <Notice
                  text={item.NotificationMessage}
                  className={s.notification}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(TicketLayout);
