import React, { useState, useContext, useEffect } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  EmptySlider,
  meta,
  Traveling,
  BuyTicketPromo,
} from '@lux/spa/components';
import {
  Container,
  Button,
  Editor,
  Divider,
  Slider,
  SliderSlides,
  SliderControls,
  Text,
  PageLoading,
  UploadImages,
  EditSlider,
  LazyLoadImage,
} from '@lux/ui';
import { NotFound } from '@lux/spa/routes';
import {
  GET_STATIC_PAGE,
  GET_STATIC_PAGE_ADMIN,
} from '@lux/spa/apollo/queries';
import {
  CREATE_STATIC_PAGE,
  UPDATE_STATIC_PAGE,
} from '@lux/spa/apollo/mutations';
import { emptyData, defaultData } from './constants';
import {
  RegularSeat,
  LoungeSeat,
  emptyRegularSeat,
  emptyLoungeSeat,
} from './components';
import s from './seats.module.scss';

const SeatsContent = ({
  t,
  slugs,
  region,
  language,
  updatePage,
  content,
  lang,
  isEditMode,
  isStaff,
  className,
}) => {
  const { isMob } = useContext(WidthContext);
  const [isImagesModalOpen, setIsImagesModalOpen] = useState(false);
  const [isLoungeSeatsSlider, setIsLoungeSeatsSlider] = useState(false);
  const [isRegularSeatsSlider, setIsRegularSeatsSlider] = useState(false);

  const toggleModal = () => {
    setIsImagesModalOpen(!isImagesModalOpen);
  };

  const toggleLoungeSeatsSlider = () => {
    setIsLoungeSeatsSlider(!isLoungeSeatsSlider);
  };

  const toggleRegularSeatsSlider = () => {
    setIsRegularSeatsSlider(!isRegularSeatsSlider);
  };

  const { pageData = {}, seo = {} } = content;
  const {
    title,
    subTitle,
    banner,
    loungeSeats,
    regularSeats,
    heading1,
    heading2,
    heading3,
    heading4,
    heading5,
    // heading6,
    text1,
    text2,
    // text3,
    editor1,
    editor1_1: editor11,
    editor2,
    editor2_1: editor21,
  } = pageData;

  const backgroundImage = banner.value
    ? `linear-gradient(90deg, #000000d9, transparent), url(${banner.value})`
    : `linear-gradient(90deg, #000000d9, transparent)`;

  const [createStaticPage, { data, loading }] = useMutation(CREATE_STATIC_PAGE);

  // NOTE: for create Static Page
  // 1. set one country in constants.js for this page => emptyData.countries: ['ee']
  // 2. set same country in field country
  // 3. set slug

  const defaultLang = 'en';

  const handleCreateStaticPage = () => {
    createStaticPage({
      variables: {
        input: {
          isPublished: true,
          slug: 'seats',
          country: 'ee',
          [defaultLang]: {
            ...emptyData,
          },
        },
      },
    });
  };

  return (
    <div className={cn(className, s.seats)}>
      <Helmet {...meta({ seo, lang: language, slugs })} />
      {isImagesModalOpen && (
        <UploadImages
          isOpen={isImagesModalOpen}
          toggleModal={toggleModal}
          updatePage={updatePage}
          image={banner}
        />
      )}
      {isLoungeSeatsSlider && (
        <EditSlider
          emptySlide={emptyLoungeSeat}
          slider={loungeSeats}
          isOpen={isLoungeSeatsSlider}
          updatePage={updatePage}
          toggleModal={toggleLoungeSeatsSlider}
        >
          <LoungeSeat />
        </EditSlider>
      )}
      {isRegularSeatsSlider && (
        <EditSlider
          emptySlide={emptyRegularSeat}
          slider={regularSeats}
          isOpen={isRegularSeatsSlider}
          updatePage={updatePage}
          toggleModal={toggleRegularSeatsSlider}
        >
          <RegularSeat />
        </EditSlider>
      )}
      <Container className={s.bannerContainer} style={{ backgroundImage }}>
        <Container full={false}>
          <section className={s.banner}>
            <div className={s.content}>
              <h1>
                <Text onChange={updatePage} field={title} />
              </h1>
              <h1 data-v2>
                <Text onChange={updatePage} field={subTitle} />
              </h1>
              {/* <Button
                text={`Create Static defaultLang: ${defaultLang} for countries: ${emptyData.countries.join()}`}
                onClick={handleCreateStaticPage}
              /> */}
              {isEditMode && (
                <Button
                  className={s.uploadBanner}
                  text={banner.value ? t('edit photo') : t('upload photo')}
                  onClick={toggleModal}
                />
              )}
            </div>
          </section>
        </Container>
      </Container>
      <Divider />
      <Container className={s.descriptionLounge}>
        <div className={s.greyBlock} />
        <Container full={false} className={s.descriptionContainer}>
          <section>
            <h2 className={s.title}>
              <Text onChange={updatePage} field={heading1} />
            </h2>
            <div className={s.description}>
              <div className={s.texts}>
                {(isEditMode || editor1) && (
                  <Editor
                    className={s.text}
                    html={editor1}
                    placeholder={t('description')}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      updatePage({
                        editor1: data,
                      });
                    }}
                  />
                )}
                {(isEditMode || editor11) && (
                  <Editor
                    className={s.text}
                    html={editor11}
                    placeholder={t('description')}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      updatePage({
                        editor1_1: data,
                      });
                    }}
                  />
                )}

                {/* <p>
                  Lux Express Lounge is our most modern coach with an exclusive business lounge area at the back of the coach.
                  </p>
                  <p>
                  All Lux Express coaches are modern and equipped with a number of amenities so you can use your time exactly as you wish.
                  </p>
                  <p>
                  The coaches have Wi-Fi and sockets at each pair of seats, as well as personal multimedia screens for watching movies, listening to music or playing games.
                </p> */}
              </div>
              <div className={s.image}>
                <LazyLoadImage
                  src="/images/seats/lounge-bussiness-seats.png"
                  alt=""
                />
              </div>
            </div>
          </section>
        </Container>
      </Container>
      <Container className={s.loungeSeats}>
        <div className={s.greyBlock} />
        <Container full={false}>
          <section>
            <h2 className={cn(s.title, s.loungeSeatsTitle)}>
              <Text onChange={updatePage} field={heading2} />
            </h2>
            {loungeSeats.slides.length ? (
              <Slider
                slidesCount={loungeSeats.slides.length}
                withPadding={isMob}
                className={s.loungeSeats}
                render={sliderProps => (
                  <>
                    {isEditMode && (
                      <Button
                        className={s.editSlider}
                        text={t('edit slider')}
                        onClick={toggleLoungeSeatsSlider}
                      />
                    )}
                    <div className={s.descriptions}>
                      {loungeSeats.slides.map(
                        (slide, key) =>
                          key == sliderProps.currentSlide && (
                            <div className={s.description} key={key}>
                              <div className={s.subTitle}>
                                {slide.title.value}
                              </div>
                              <div className={s.text}>
                                {slide.subTitle.value}
                              </div>
                            </div>
                          )
                      )}
                      {!isMob && (
                        <SliderControls
                          className={s.controls}
                          {...sliderProps}
                        />
                      )}
                    </div>
                    <SliderSlides {...sliderProps} className={s.slides}>
                      {loungeSeats.slides.map((slide, key) => (
                        <div key={key} className={s.slide}>
                          <LazyLoadImage
                            src={slide.img.value}
                            alt={slide.alt.value}
                          />
                        </div>
                      ))}
                    </SliderSlides>
                    {isMob && (
                      <SliderControls className={s.controls} {...sliderProps} />
                    )}
                  </>
                )}
              />
            ) : isEditMode ? (
              <EmptySlider toggleEditSlider={toggleLoungeSeatsSlider} />
            ) : null}
          </section>
        </Container>
      </Container>
      <Container className={s.luxExpress}>
        <Container full={false}>
          <section>
            <div className={s.map}>
              <LazyLoadImage src="/images/promo/map-seats-big.png" alt="" />
            </div>
            <div className={s.description}>
              <h2 className={s.title}>
                <Text onChange={updatePage} field={heading3} />
              </h2>
              <h5>
                <Text onChange={updatePage} field={text1} />
                {/* Being the largest international coach */}
              </h5>
              <p>
                <Text onChange={updatePage} field={text2} />
                {/* Being the largest international coach operator in the Baltic region Lux Express offers bus services in */}
              </p>
            </div>
            <div className={s.details}>
              <div className={s.options}>
                <div className={s.option}>
                  <LazyLoadImage src="/images/svg/lounge-seats.svg" alt="" />
                  <div className={s.text}>{t('lounge seats')}</div>
                </div>
                <div className={s.option}>
                  <LazyLoadImage src="/images/svg/regular-seats.svg" alt="" />
                  <div className={s.text}>{t('standard seats')}</div>
                </div>
              </div>
              {isMob ? (
                <div className={s.image}>
                  <LazyLoadImage src="/images/seats/bus-vertical.png" alt="" />
                </div>
              ) : (
                <div className={s.image}>
                  <LazyLoadImage src="/images/seats/bus.png" alt="" />
                </div>
              )}
            </div>
          </section>
        </Container>
      </Container>
      <Container className={s.descriptionRegular}>
        <div className={s.greyBlock} />
        <Container full={false} className={s.descriptionContainer}>
          <section>
            <h2 className={s.title}>
              <Text onChange={updatePage} field={heading4} />
            </h2>
            <div className={s.description}>
              <div className={s.texts}>
                {(isEditMode || editor2) && (
                  <Editor
                    className={s.text}
                    html={editor2}
                    placeholder={t('description')}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      updatePage({
                        editor2: data,
                      });
                    }}
                  />
                )}
                {(isEditMode || editor21) && (
                  <Editor
                    className={s.text}
                    html={editor21}
                    placeholder={t('description')}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      updatePage({
                        editor2_1: data,
                      });
                    }}
                  />
                )}
                {/* <p>
                    Lux Express coaches are modern and equipped with a number of amenities so  you can use your time exactly  as you wish.
                    </p>
                    <p>
                    The coach has Wi-Fi and sockets for each pair of seats, as well as personal multimedia screens for watching movies, listening to music or playing games.
                </p> */}
              </div>
              <div className={s.image}>
                <LazyLoadImage src="/images/seats/seats-express.png" />
              </div>
            </div>
          </section>
        </Container>
      </Container>
      <Container className={s.regularSeats}>
        <div className={s.greyBlock} />
        <Container full={false}>
          <section>
            <h2 className={cn(s.title, s.regularSeatsTitle)}>
              <Text onChange={updatePage} field={heading5} />
            </h2>
            {regularSeats.slides.length ? (
              <Slider
                slidesCount={regularSeats.slides.length}
                withPadding={isMob}
                className={s.regularSeats}
                render={sliderProps => (
                  <>
                    {isEditMode && (
                      <Button
                        className={s.editSlider}
                        text={t('edit slider')}
                        onClick={toggleRegularSeatsSlider}
                      />
                    )}
                    <div className={s.descriptions}>
                      {regularSeats.slides.map(
                        (slide, key) =>
                          key == sliderProps.currentSlide && (
                            <div className={s.description} key={key}>
                              <div className={s.subTitle}>
                                {slide.title.value}
                              </div>
                              <div className={s.text}>
                                {slide.subTitle.value}
                              </div>
                            </div>
                          )
                      )}
                      {!isMob && (
                        <SliderControls
                          className={s.controls}
                          {...sliderProps}
                        />
                      )}
                    </div>
                    <SliderSlides {...sliderProps} className={s.slides}>
                      {regularSeats.slides.map((slide, key) => (
                        <div key={key} className={s.slide}>
                          <LazyLoadImage
                            src={slide.img.value}
                            alt={slide.alt.value}
                          />
                        </div>
                      ))}
                    </SliderSlides>
                    {isMob && (
                      <SliderControls className={s.controls} {...sliderProps} />
                    )}
                  </>
                )}
              />
            ) : isEditMode ? (
              <EmptySlider toggleEditSlider={toggleRegularSeatsSlider} />
            ) : null}
          </section>
        </Container>
      </Container>
      <div className={s.travelingBlock}>
        <Traveling />
      </div>
      <BuyTicketPromo className={s.services} />
    </div>
  );
};

const Seats = ({
  t,
  lang,
  match,
  i18n,
  isStaff,
  region,
  isEditMode,
  page = { en: emptyData },
  dispatch = () => {},
  ...props
}) => {
  const slug = 'seats';
  const { language } = i18n;
  const QUERY = isStaff ? GET_STATIC_PAGE_ADMIN : GET_STATIC_PAGE;

  const variables = {
    slug,
    ...(isStaff
      ? {}
      : {
          lang: language,
        }),
    country: region,
  };

  const { data, loading } = useQuery(QUERY, { variables });

  const updatePage = field => {
    dispatch({
      type: 'UPDATE_DATA',
      payload: {
        field,
      },
    });
  };

  useEffect(() => {
    if (isStaff) {
      dispatch({
        type: 'UPDATE',
        payload: {
          defaultData,
          emptyData,
          CREATE_PAGE: CREATE_STATIC_PAGE,
          UPDATE_PAGE: UPDATE_STATIC_PAGE,
          originalPage: data?.adminStaticPage,
          page: data?.adminStaticPage,
        },
      });
    }
  }, [data]);

  // useEffect(() => {
  //   dispatch({
  //     type: 'UPDATE',
  //     payload: {
  //       loading
  //     }
  //   })
  // }, [loading])

  useEffect(() => {
    const isExistLang = page?.[lang];

    if (isEditMode && !isExistLang) {
      dispatch({
        type: 'UPDATE_PAGE',
        payload: {
          [lang]: {
            ...emptyData,
            lang,
            countries: [region],
          },
        },
      });
    }
  }, [isEditMode]);

  // const content = {
  //   ...(isStaff ? {
  //     ...emptyData,
  //     ...page?.[lang]
  //   } : {
  //     ...
  //   })
  // } : {
  //   lang: language,
  //   slug,
  //   ...data.staticPage?.data
  // })
  // }

  const isExistLangForPage = !!page?.[lang];

  const content = isStaff
    ? {
        ...emptyData,
        ...page?.[lang],
      }
    : data?.staticPage?.data;

  // useEffect(() => {
  //   if (isExistLangForPage && isStaff) {
  //     toast.warn(`there is no translation in ${lang} for ${countries[region]}`)
  //   }
  // }, [isExistLangForPage])

  const slugs = {
    en: {
      value: slug,
    },
    et: {
      value: slug,
    },
    lv: {
      value: slug,
    },
    lt: {
      value: slug,
    },
    ru: {
      value: slug,
    },
  };

  return loading ? (
    <PageLoading />
  ) : content || isStaff ? (
    <SeatsContent
      slugs={slugs}
      content={{
        ...content,
        seo: {
          ...content.seo,
          slug: {
            ...content.seo.slug,
            value: slug,
          },
        },
        pageData: {
          ...emptyData.pageData,
          ...content.pageData,
        },
      }}
      {...{ updatePage, language, isEditMode, isStaff, region, t }}
      {...props}
    />
  ) : (
    <NotFound />
  );
};

export default withTranslation()(Seats);
