import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';

import i from '@lux/spa/styles/icons.module.scss';
import s from './slider.module.scss';

const SliderControls = ({
  t,
  hideNumbers = false,
  buttonPrevRef,
  buttonNextRef,
  className,
  slidesCount,
  currentSlide,
}) => {
  return (
    <div className={cn(s.controls, className)}>
      <div
        ref={buttonPrevRef}
        className={cn(s.button, s.prev)}
        disabled={currentSlide == 0}
      >
        <i className={i.arrowLeft} />
        {t('prev')}
      </div>
      {hideNumbers ? (
        <div className={s.count}>
          {currentSlide + 1} / {slidesCount}
        </div>
      ) : (
        <div className={s.divider} />
      )}

      <div
        ref={buttonNextRef}
        className={cn(s.button, s.next)}
        disabled={currentSlide + 1 == slidesCount}
      >
        {t('next')}
        <i className={i.arrowRight} />
      </div>
    </div>
  );
};

export default withTranslation()(SliderControls);
