import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';

import {
  Loading,
  Button,
  Dropzone,
  Modal,
  ModalHeader,
  ModalContent,
} from '@lux/ui';
import { useMutation } from '@apollo/react-hooks';
import { UPLOAD_IMAGES } from '@lux/spa/apollo/mutations';
import i from '@lux/spa/styles/icons.module.scss';
import s from './upload-images.module.scss';

const UploadImages = ({
  t,
  isOpen,
  toggleModal,
  updatePage,
  image = {
    value: '',
    fieldName: '',
    placeholder: '1440 x 550',
    name: '',
    size: {
      height: 0,
      width: 0,
    },
  },
}) => {
  const [preview, setPreview] = useState({
    ...image,
    value: image.value || '',
  });
  const [uploadImages, { data, loading, error }] = useMutation(UPLOAD_IMAGES);

  useEffect(() => {
    if (data?.uploadImages?.success) {
      updatePage({
        [preview.fieldName]: {
          ...preview,
          value: data?.uploadImages?.urls[0],
        },
      });
      toggleModal();
    }
  }, [data?.uploadImages?.success]);

  const removeImage = e => {
    e?.stopPropagation();

    setPreview({
      ...preview,
      value: '',
    });
  };

  const saveImages = () => {
    if (preview.value) {
      uploadImages({
        variables: {
          input: [
            {
              file: preview.value,
              name: preview.name,
            },
          ],
        },
      });
    } else {
      updatePage({
        [preview.fieldName]: {
          ...preview,
          value: '',
        },
      });
      toggleModal();
    }
  };

  return (
    <Modal className={s.modal} {...{ isOpen, toggleModal }}>
      <ModalHeader text={t('upload images')} />
      <ModalContent>
        <div className={s.uploadImages}>
          <Dropzone
            className={s.dropzone}
            onDrop={(file, height, width, base64, name) => {
              setPreview({
                ...preview,
                name,
                value: base64,
              });
            }}
            loading={loading}
            preview={preview}
            removeImage={removeImage}
            // placeholder={t(preview.placeholder.toLowerCase())}
          />
          <div className={s.controls}>
            <Button
              disabled={loading}
              // text={loading ? 'Saving' : 'Save'}
              onClick={saveImages}
            >
              {loading ? <Loading /> : t('save')}
            </Button>
            <span onClick={toggleModal} disabled={loading}>
              {t('cancel')}
            </span>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default withTranslation()(UploadImages);
