import React from 'react';
import { Modal, ModalContent } from '@lux/ui';
import s from './show-image.module.scss';

const ShowImage = ({ isOpen, toggleModal, imageUrl }) => {
  return (
    <Modal
      className={s.modal}
      {...{
        isOpen,
        toggleModal,
      }}
    >
      <ModalContent className={s.content}>
        <div className={s.controls}>
          <img src={imageUrl} alt="" />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ShowImage;
