import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { ADYEN_PAYMENT_DETAILS } from '@lux/spa/apollo/mutations';
import { StoreContext } from '@lux/spa/store';
import { createThanksSuccessAnaliticsData } from 'utils';
import ThanksLayoutSuccess from '../../thanks-layout-success.jsx';
import ThanksLayoutPending from '../../thanks-layout-pending.jsx';
import ThanksLayoutError from '../../thanks-layout-error.jsx';

const Adyen = ({
  getTicketsFileUrl,
  fileLoading,
  urlParams,
  linkToPDFFile,
}) => {
  const [adyenPaymentDetails, { loading = true }] = useMutation(
    ADYEN_PAYMENT_DETAILS
  );
  const [isError, setIsError] = useState(false);

  const [state, dispatch] = useContext(StoreContext);

  const {
    from,
    to,
    returnDate,
    departDate,
    promocode,
    totalPrice,

    forAnalitics,
    GTM,
  } = state.tickets;

  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';
  const { GTMLoaded } = state.app;

  useEffect(() => {
    const { tickets } = state;
    const shopperOrder = tickets?.products?.Basket?.ShoppingBasketId;
    const requiredFields = JSON.parse(localStorage.getItem('details'));

    if (requiredFields) {
      const postParams = JSON.parse(localStorage.getItem('params'));
      const input = {
        orderId: shopperOrder,
      };

      const details = {};

      requiredFields.map(item => {
        if (postParams) {
          details[item.key] = postParams?.[item.key];
        } else {
          details[item.key] = urlParams?.[item.key];
        }
      });

      input.details = {
        ...details,
      };

      if (shopperOrder) {
        adyenPaymentDetails({
          variables: {
            input,
          },
        })
          .then(data => {
            if (data?.data?.adyenPaymentDetails?.error) {
              setIsError(true);
            }

            if (data?.data?.adyenPaymentDetails?.success) {
              if (GTMLoaded && !GTM.thanksStep) {
                dataLayer.push({
                  ...createThanksSuccessAnaliticsData({
                    returnDate,
                    departDate,
                    from,
                    to,
                    currencyName,
                    totalPrice,
                    promocode,
                    objects: data?.adyenPaymentDetails?.objects,
                  }),
                });

                dispatch({
                  type: 'UPDATE_TICKETS',
                  payload: {
                    GTM: {
                      ...GTM,
                      thanksStep: true,
                    },
                  },
                });
              }

              getTicketsFileUrl();
            }

            localStorage.removeItem('params');
          })
          .catch(() => {
            setIsError(true);
          });
      }
    } else {
      if (GTMLoaded && !GTM.thanksStep) {
        dataLayer.push({
          ...createThanksSuccessAnaliticsData({
            returnDate,
            departDate,
            from,
            to,
            currencyName,
            totalPrice,
            promocode,
            objects: forAnalitics.objects,
          }),
        });

        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            GTM: {
              ...GTM,
              thanksStep: true,
            },
          },
        });
      }

      getTicketsFileUrl();
    }
  }, []);

  return loading ? (
    <ThanksLayoutPending />
  ) : isError ? (
    <ThanksLayoutError renderCancel />
  ) : (
    <ThanksLayoutSuccess
      type="card"
      linkToPDFFile={linkToPDFFile}
      fileLoading={fileLoading}
      getTicketsFileUrl={getTicketsFileUrl}
    />
  );
};

export default Adyen;
